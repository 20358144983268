var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column form-field-location"},[_vm._l((_vm.locations),function(location,index){return [_c('div',{key:("location-" + index + "-0")},[_c('FormFieldText',{ref:"formField",refInFor:true,class:{
          'form-field-location--show-error': _vm.isRequiredObjectName,
        },attrs:{"control":{
          uniqueId: ((_vm.control.uniqueId) + "_name"),
          name: ("name_" + index),
          label: _vm.control.label,
          placeholderText: _vm.control.placeholderText,
          subLabel: _vm.control.subLabel,
          inputType: 'text',
          isDisabled: _vm.control.isDisabled,
          isReadonly: _vm.control.isReadonly,
          validations: _vm.isRequiredObjectName ? [{ ruleType: 'required' }] : null,
        }},model:{value:(location.name),callback:function ($$v) {_vm.$set(location, "name", $$v)},expression:"location.name"}})],1),(!location.isDetailsHidden)?_c('div',{key:("location-" + index + "-1"),staticClass:"d-flex flex-column form-field-location__details"},[_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"form-field-location__details-spacer"}),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldText',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_region"),
              name: ("region_" + index),
              label: 'Region',
              placeholderText: 'Enter',
              subLabel: _vm.control.subLabel,
              inputType: 'text',
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(location.region),callback:function ($$v) {_vm.$set(location, "region", $$v)},expression:"location.region"}})],1),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldText',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_municipality"),
              name: ("municipality_" + index),
              label: 'Municipality',
              placeholderText: 'Enter',
              subLabel: _vm.control.subLabel,
              inputType: 'text',
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(location.municipality),callback:function ($$v) {_vm.$set(location, "municipality", $$v)},expression:"location.municipality"}})],1)]),_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"form-field-location__details-spacer"}),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldText',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_latitude"),
              name: ("latitude_" + index),
              label: 'Coordinates (latitude)',
              placeholderText: 'Enter',
              subLabel: _vm.control.subLabel,
              inputType: 'text',
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(location.latitude),callback:function ($$v) {_vm.$set(location, "latitude", $$v)},expression:"location.latitude"}})],1),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldText',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_longitude"),
              name: ("longitude_" + index),
              label: 'Coordinates (longitude)',
              placeholderText: 'Enter',
              subLabel: _vm.control.subLabel,
              inputType: 'text',
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(location.longitude),callback:function ($$v) {_vm.$set(location, "longitude", $$v)},expression:"location.longitude"}})],1)]),_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"flex-shrink-0 form-field-location__details-spacer"}),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldSelect',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_facility_id"),
              name: ("facility_id_" + index),
              label: 'Location for Facility',
              placeholderText: 'Select',
              endpointPayload: _vm.optionsEndpointPayload,
              endpointRoute: '',
              isMultiple: true,
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            },"value-container":_vm.$attrs['value-container']},model:{value:(location.facility_id),callback:function ($$v) {_vm.$set(location, "facility_id", $$v)},expression:"location.facility_id"}})],1)]),_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"flex-shrink-0 form-field-location__details-spacer"}),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldSelect',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_area_classification"),
              name: ("area_classification_" + index),
              label: 'Area Classification',
              placeholderText: 'Select',
              items: [
                {
                  value: 'sensitive',
                  text: 'Sensitive Area',
                } ],
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(location.area_classification),callback:function ($$v) {_vm.$set(location, "area_classification", $$v)},expression:"location.area_classification"}})],1)])]):_vm._e(),_c('div',{key:("location-" + index + "-2"),staticClass:"d-flex flex-column form-field-location__toggles"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"form-field-location__toggles-spacer"}),_c('div',{staticClass:"d-flex flex-grow-1 justify-start"},[_c('BaseButton',{staticClass:"form-field-location__toggle-details-button",attrs:{"icon":location.isDetailsHidden ? 'show' : 'hide',"type":"quin"},on:{"click":function($event){return _vm.toggleDetails(location, $event)}}},[_vm._v(" "+_vm._s(location.isDetailsHidden ? 'Show Details' : 'Hide Details')+" ")])],1),_c('div',{staticClass:"d-flex flex-grow-1 justify-end"},[(index > 0)?_c('BaseButton',{staticClass:"form-field-location__delete-group-button",attrs:{"disabled":_vm.control.isDisabled || _vm.control.isReadonly,"type":"quin","icon":"delete"},on:{"click":function($event){return _vm.removeGroup(index)}}},[_vm._v(" Delete Group ")]):_vm._e()],1)])]),(!_vm.control.isDisabled && !_vm.control.isReadonly)?_c('div',{key:("location-" + index + "-3")},[_c('div',{staticClass:"d-flex align-center form-field-location__add-group",class:{
          'form-field-location__add-group--is-sm': _vm.$vuetify.breakpoint.smAndDown,
        }},[_c('v-icon',{attrs:{"disabled":_vm.control.isDisabled || _vm.control.isReadonly},on:{"click":function($event){return _vm.addGroup(index)}}},[_vm._v(" $add-v2 ")])],1)]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }