

































































































































































































































































import { Component, Emit, Prop } from 'vue-property-decorator';
import ChapterItemCommentHighlight from './mixins/ChapterItemCommentHighlight.vue';
import { ChapterItem } from '@/app/shared/models/reports/ChapterItem';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import { ChapterItemMode } from '@/app/shared/types/chapter-item-mode.type';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//@ts-ignore
// import VueCropper from 'vue-cropperjs';
// import 'cropperjs/dist/cropper.css';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { AddImageResponse } from '@/app/shared/models/reports/AddImageResponse';
import api from '@/app/shared/api';
import { namespace } from 'vuex-class';
import _debounce from 'lodash.debounce';
import { UpdateChapterItemPayload, WS_DEBOUNCE_VALUE } from '@/app/shared/store/modules/reports.store-module';
import LoaderService from '@/app/shared/utils/loader.service';

const LoaderStoreModule = namespace('Loader');

@Component({
  components: { Cropper },
})
export default class ImageChapterItem extends ChapterItemCommentHighlight {
  @Prop() item: ChapterItem;
  @Prop() mode: ChapterItemMode;
  @Prop() isDeleteButtonAllowed: boolean;

  @LoaderStoreModule.Action
  disableHttpLoader!: () => void;
  @LoaderStoreModule.Action
  enableHttpLoader!: () => void;

  ChapterItemType: ChapterItemType;
  public activeTab: number = 1;
  public uploadedImageUrl: string = '';
  public editImageMode: 'none' | 'preview' | 'crop' | 'resize' = 'none';
  public fileType: string;
  public imageUrl: string = '';
  public resizeInitialX: number;
  public resizeInitialY: number;
  public initialImageHeight: number;
  public initialImageWidth: number;
  public maxResizeWidth: number;
  public keepAspectRatio: boolean = false;
  public minResizedImageSize: number = 50;

  @Emit('update')
  updateChapterItem(_payload: UpdateChapterItemPayload) {
    // just emiting event
  }

  @Emit('deleteChapterItem')
  deleteChapterItem(_payload: UpdateChapterItemPayload) {
    // just emiting event
  }

  @Emit('toggleExpand')
  toggleExpand() {
    // just emiting event
  }

  created() {
    if (this.item.image && this.item.image.id) {
      this.uploadedImageUrl = this.item.image.contents;
      this.editImageMode = 'preview';
    }
  }

  get isExpanded() {
    return this.item && this.item.isExpandedInContent;
  }

  onTitleInput = _debounce((event: string) => {
    this.updateChapterItem({
      uuid: this.item.uuid,
      type: ChapterItemType.IMAGE,
      propertyName: 'title',
      propertyValue: event,
    });
  }, WS_DEBOUNCE_VALUE);

  onLandscapeValueChange(value: boolean) {
    this.updateChapterItem({
      uuid: this.item.uuid,
      type: ChapterItemType.IMAGE,
      propertyName: 'landscape',
      propertyValue: value,
    });
  }

  openResizeImage() {
    this.editImageMode = 'resize';
    setTimeout(async () => {
      await this.canvasDrawImage();
      document.getElementById('image-resize-grabber').addEventListener('mousedown', this.initResize, false);
      setTimeout(() => {
        const canvas: any = document.getElementById('resize-image-canvas');
        this.initialImageHeight = canvas.clientHeight;
        this.initialImageWidth = canvas.clientWidth;
        const resizeElement: any = this.$refs.imageResizeGrabber;
        this.resizeInitialX = resizeElement.getBoundingClientRect().x;
        this.resizeInitialY = resizeElement.getBoundingClientRect().y;
        const resizeWrapperElement: any = this.$refs.resizeImageWrapper;
        this.maxResizeWidth = resizeWrapperElement.getBoundingClientRect().width;
      }, 10);
    }, 200);
  }

  canvasDrawImage(width: number = 0, height: number = 0): Promise<void> {
    return new Promise((resolve) => {
      const canvas: any = document.getElementById('resize-image-canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = this.uploadedImageUrl;
      const resizeGrabberElement: any = this.$refs.imageResizeGrabber;
      if (width == 0) {
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, img.width, img.height);
          resizeGrabberElement.style.left = `${canvas.clientWidth + 2}px`;
          resizeGrabberElement.style.top = `${canvas.clientHeight - 2}px`;
        };
        resolve();
      } else {
        img.onload = () => {
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          resizeGrabberElement.style.left = `${width + 2}px`;
          resizeGrabberElement.style.top = `${height - 2}px`;
          resolve();
        };
      }
    });
  }
  initResize() {
    window.addEventListener('mousemove', this.resize, false);
    window.addEventListener('mouseup', this.stopResize, false);
  }

  stopResize() {
    window.removeEventListener('mousemove', this.resize, false);
    window.removeEventListener('mouseup', this.stopResize, false);
  }
  resize(e: any) {
    const mouse: any = {};
    mouse.x = e.clientX;
    mouse.y = e.clientY;
    let newHeight = this.initialImageHeight - (this.resizeInitialY - mouse.y);
    let newWidth = this.initialImageWidth - (this.resizeInitialX - mouse.x);

    if (this.keepAspectRatio) {
      if (newWidth > this.initialImageWidth) {
        newWidth = this.initialImageWidth;
      }
      newHeight = (newWidth / this.initialImageWidth) * this.initialImageHeight;
    } else {
      if (newHeight > 400) {
        // newHeight = 400;
      }
      if (newHeight < this.minResizedImageSize) {
        newHeight = this.minResizedImageSize;
      }
      if (newWidth > this.maxResizeWidth) {
        newWidth = this.maxResizeWidth;
      }
      if (newWidth < this.minResizedImageSize) {
        newWidth = this.minResizedImageSize;
      }
    }

    this.canvasDrawImage(newWidth, newHeight);
  }

  finishResize() {
    // TODO Update image on beckend
    const canvas: any = document.getElementById('resize-image-canvas');
    this.uploadedImageUrl = canvas.toDataURL();
    this.uploadImage();
    this.editImageMode = 'preview';
  }

  toBase64(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async uploadImage() {
    try {
      LoaderService.disableHttpLoader();
      let res: { uuid: string; id: number };
      if (this.item.image && this.item.image.uuid) {
        // update image
        res = await api.reports.updateFile(this.uploadedImageUrl, this.item.image.uuid, this.fileType);
        return;
      } else {
        res = await api.reports.uploadFile(this.uploadedImageUrl, this.fileType, 'image');
      }
      this.updateChapterItem({
        type: ChapterItemType.IMAGE,
        uuid: this.item.uuid,
        propertyName: 'image',
        propertyValue: {
          id: res.id,
          uuid: res.uuid,
          contents: this.uploadedImageUrl,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  async updateImage(_file: File) {
    try {
      //
    } catch (error) {
      console.error(error);
    }
  }

  async onDrop(e: any) {
    e.stopPropagation();
    e.preventDefault();
    const files = e.dataTransfer.files;

    if (files[0].type != 'image/jpeg' && files[0].type != 'image/png') {
      // TODO Show error modal
      return;
    }
    // 2097152 is 2MB
    if (files[0].size > 2097152) {
      // TODO Show error modal
      return;
    }
    this.uploadedImageUrl = await this.toBase64(files[0]);
    this.fileType = files[0].type;
    await this.uploadImage();
    this.editImageMode = 'preview';
  }

  async onFileSelected(e: any) {
    this.uploadedImageUrl = await this.toBase64(e.target.files[0]);
    this.fileType = e.target.files[0].type;
    await this.uploadImage();
    this.editImageMode = 'preview';
  }

  pasteImage(event: any) {
    const items = event.clipboardData.items;
    let blob: any;
    for (let i = 0; i < items.length; i++) {
      // Skip content if not image
      if (items[i].type.indexOf('image') == -1) continue;
      // Retrieve image on clipboard as blob
      blob = items[i].getAsFile();
    }
    const reader = new FileReader();
    if (blob) {
      reader.readAsDataURL(blob);
    }
    reader.onload = async (event) => {
      this.uploadedImageUrl = event.target.result as string;
      this.fileType = blob.type;
      await this.uploadImage();
      this.editImageMode = 'preview';
    };
  }

  async pasteImageUrl(event: any) {
    const url = event.clipboardData.getData('text');
    if (url.match(/\.(jpeg|jpg|png)$/) == null) {
      // TODO Show error modal
      return;
    }

    const splitUrl = url.split('.');
    const type = splitUrl[splitUrl.length - 1];
    if (type == 'jpg' || type == 'jpeg') {
      this.fileType = 'image/jpeg';
    } else {
      this.fileType = 'image/png';
    }
    try {
      this.disableHttpLoader();
      const data: AddImageResponse = await api.reports.addImageFromUrl(url);
      this.enableHttpLoader();
      this.uploadedImageUrl = data.contents;
      this.uploadImage();
      this.editImageMode = 'preview';
    } catch (error) {
      // console.log(error);
    }
  }
  switchToEditMode() {
    if (this.mode == 'lock') {
      return;
    }
    this.switchMode('edit');
  }
  @Emit('switchMode')
  switchMode(mode: string) {
    return mode;
  }
  chooseAgain() {
    // TODO remove picture from backend
    this.editImageMode = 'none';
  }
  rotateImage() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    //@ts-ignore
    this.$refs.cropper.rotate(-90);
  }
  finishEditing() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    //@ts-ignore
    const { canvas } = this.$refs.cropper.getResult();
    this.uploadedImageUrl = canvas.toDataURL();
    this.uploadImage();
    this.editImageMode = 'preview';
  }
}
