



















import { Component, Prop, Vue } from 'vue-property-decorator';
import FactorInput from './formulate/factors/FactorInput.vue';
import Criteria from './Criteria.vue';
import { CheckTreeItemWithFactor } from '@/app/shared/store/modules/calculations.store-module';
import { UnitAlternativePhase } from '@/app/shared/models/calculations/UnitAlternative';

@Component({ components: { FactorInput, Criteria } })
export default class CriteriaGroup extends Vue {
  @Prop() private criteriaGroup: CheckTreeItemWithFactor;
  @Prop() unitUuid: string;
  @Prop() mode: 'assess' | 'formulate';
  @Prop() alternativePhase: UnitAlternativePhase;

  get label() {
    return this.criteriaGroup.title;
  }
}
