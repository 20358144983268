






































































import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import api from '@/app/shared/api';
import { namespace } from 'vuex-class';
import WorkflowFileUpload from '@/app/workflow/components/WorkflowFileUpload.vue';
const WorkflowStoreModule = namespace('Workflow');

@Component({
  components: { WorkflowFileUpload },
})
export default class WorkflowCreateMatrixModal extends Vue {
  @Prop() title!: string;
  @Prop() import!: boolean;

  formName: string = '';
  formSlug: string = '';
  formDescription: string = '';
  formAxisX: Array<any> = [];
  formAxisY: number = null;
  formResultType: string = '';

  errorMessage: string = '';

  @WorkflowStoreModule.Getter
  getChecktreesOptions!: any;

  @WorkflowStoreModule.Getter
  getCurrentNode!: Record<any, any>;

  @WorkflowStoreModule.Getter
  getCurrentFile!: any;

  @WorkflowStoreModule.Mutation
  addNewlyAddedMatrix!: (data: any) => void;

  @WorkflowStoreModule.Mutation
  setCurrentFile!: (data: any) => void;

  created() {
    this.setCurrentFile([]);
  }

  async save() {
    this.errorMessage = '';

    const data = {
      name: this.formName ? this.formName : null,
      slug: this.formSlug ? this.formSlug : null,
      description: this.formDescription ? this.formDescription : null,
      matrix_type: 'tagging',
      axis_xs_ids: this.formAxisX ? this.formAxisX : null,
      axis_y_id: this.formAxisY ? this.formAxisY : null,
      binding_direction: this.getCurrentNode.module.binding_direction,
      result_type: this.formResultType ? this.formResultType : null,
    };

    const response: any = await api.matrix.importMatrix(data, this.getCurrentFile);
    if (response.status == 'success') {
      this.addNewlyAddedMatrix(response.data);
      this.$emit('pre-select-newly-added-matrix', response.data);
      this.$emit('workflow-create-matrix-dialog-close');
    } else {
      this.errorMessage = response.message;
    }
  }

  @Emit()
  close() {
    this.$emit('workflow-create-matrix-dialog-close');
  }
}
