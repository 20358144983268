







































































































import { Component, Mixins, Vue } from 'vue-property-decorator';
import { BaseControlConfigSkeleton } from 'v-form-builder';

@Component
export default class FormConfigButton extends Mixins(Vue, BaseControlConfigSkeleton) {
  get buttonTypes() {
    return {
      submit: {
        val: 'submit',
        description: 'Submit',
      },
      button: {
        val: 'button',
        description: 'Button',
      },
      link: {
        val: 'link',
        description: 'Link',
      },
    };
  }

  get buttonColors() {
    return {
      primary: {
        val: 'primary',
        description: 'Primary',
      },
      secondary: {
        val: 'secondary',
        description: 'Secondary',
      },
      quaternary: {
        val: 'quaternary',
        description: 'Quaternary',
      },
    };
  }
}
