import { MatrixType } from '@/app/shared/enums/matrix-type.enum';
import { MatrixAxis } from '@/app/shared/models/MatrixAxis';
import { MatrixCheckItem } from '@/app/shared/models/MatrixCheckItem';
import MatrixRelationField from '@/app/shared/models/MatrixRelationField';

export default class MatrixRelationMatrix {
  public id: number;
  public uuid: string;
  public name: string;
  public slug: string;
  public items: Array<MatrixCheckItem>;
  public binding_word: string;
  public axis_xs: Array<MatrixRelationField>;
  public axis_ys: Array<MatrixRelationField>;
  public user: any;
  public study: any;
  public matrix_type: MatrixType = MatrixType.FIRST;
  public matrix: boolean[][] = [];
  // columns
  public axis_x: MatrixAxis;
  // rows
  public axis_y: MatrixAxis;
}
