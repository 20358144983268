import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import { WSMessageHandler } from './../enums/ws-message-handler.enum';
import { WebSocketMessageStatus } from './../enums/WebsocketMessageStatus.enum';
import { WSMessage } from './../models/WSMessage';
import { WSMessageResponse } from './../models/WSMessageResponse';
import store from '../store';

export class WebSocketService {
  private static instance: WebSocketService;
  private connection: WebSocket;
  reconnect: boolean = true;
  reconnecting: boolean = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  get shouldReconnect() {
    return this.reconnect;
  }

  set shouldReconnect(reconnect) {
    this.reconnect = reconnect;
  }

  get isReconnecting() {
    return this.reconnecting;
  }

  set isReconnecting(reconnecting) {
    this.reconnecting = reconnecting;
  }

  public static getInstance(): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }

    return WebSocketService.instance;
  }

  initializeConnection() {
    this.connection = new WebSocket(import.meta.env.WS_URL);
    this.connection.onopen = (event: Event) => {
      console.debug('--> ws - connection open: ', event);
      this.isReconnecting = false;
    };

    this.connection.onclose = (event: Event) => {
      console.debug('--> ws - connection close: ', event);

      // Return early if we should not reconnect or we are currently reconnecting.
      if (!this.shouldReconnect || this.isReconnecting) return;

      console.warn('--> ws - reconnecting...');
      this.isReconnecting = true;
      this.initializeConnection();
    };

    this.connection.onerror = (event: Event) => {
      console.debug('--> ws - connection error: ', event);

      // Return early if we should not reconnect or we are currently reconnecting.
      if (!this.shouldReconnect || this.isReconnecting) return;

      console.warn('--> ws - reconnecting...');
      this.isReconnecting = true;
      this.initializeConnection();
    };

    this.connection.onmessage = this.onMessage;

    window.addEventListener('beforeunload', () => {
      this.shouldReconnect = false;
      this.closeConnection();
    });
  }

  private onMessage(event: MessageEvent) {
    const response: WSMessageResponse = JSON.parse(event.data);
    console.log('--> ws - message received: ', response);

    // Session expired, redirect to login.
    if (response.status == WebSocketMessageStatus.UNAUTHORIZED) {
      eventBus.$emit(EventType.UNAUTHORIZED);
    }

    if (response.handler == WSMessageHandler.MATRIX_LOCKER) {
      eventBus.$emit(EventType.MATRIX_RECIEVE_MSG, response);
    } else if (
      response.handler === WSMessageHandler.BASE_LOCKER ||
      response.handler === WSMessageHandler.CHECKTREE_LOCKER
    ) {
      if (response.params.model === 'CheckTreeItem' || response.params.model === 'CheckTree') {
        eventBus.$emit(EventType.CHECKTREE_RECEIVE_MSG, response);
      }
    } else if (response.handler === WSMessageHandler.JOINED_LOCKER) {
      eventBus.$emit(EventType.REPORTS_RECEIVE_MSG, response);
    }
  }

  public sendMessage(message: WSMessage) {
    message.accessToken = store.state.User.accessToken;
    const msg = JSON.stringify(message);
    if (!this.connection || this.connection.readyState !== WebSocket.OPEN) {
      console.log('ws connection is broken, initializing');
      this.initializeConnection();
      this.connection.onopen = () => {
        console.debug('--> ws: reconnected');
        this.isReconnecting = false;
        this.connection.send(msg);
      };
    } else {
      this.connection.send(msg);
    }
    console.log('-------> SENT: ', message);
  }

  closeConnection() {
    if (this.connection && this.connection.readyState === 3) return;
    this.connection.close();
  }
}

export const initializeWebSocketConnection = () => {
  const ws = WebSocketService.getInstance();
  ws.initializeConnection();
};

export const wsInstance = WebSocketService.getInstance();
