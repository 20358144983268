export class AssessmentTeam {
  uuid?: string;
  responsibility: string;
  user_id: number[];

  constructor(responsibility: string = '', user_id: number[] = []) {
    this.responsibility = responsibility;
    this.user_id = user_id;
  }
}
