








































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import PageHeader from '@/app/shared/components/PageHeader.vue';
import { namespace } from 'vuex-class';
import { DashboardDataTableColumn } from '@/app/shared/models/DashboardDataTable';

const UiStore = namespace('UI');

@Component({
  components: { PageHeader },
})
export default class DashboardPageHeader extends Vue {
  @Prop() title: string;
  @Prop() icon: string;
  @Prop() tagsColumns: DashboardDataTableColumn[];
  @Prop() data: any[];
  @Prop() createButton: { icon: string; label: string; route: string };
  @Prop() isItemExpandable: (item: any) => boolean;
  columnsToInclude: DashboardDataTableColumn[] = [];
  isMenuVisible: boolean = false;

  @UiStore.Getter
  isLeftSidebarExpanded!: boolean;

  get xOffset() {
    return 56;
  }

  @Emit('columns-selected')
  columnsSelected(_event: DashboardDataTableColumn[]) {
    //
  }

  @Emit('expand-all')
  expandAll() {
    //
  }

  @Watch('tagsColumns')
  onTagsColumnsUpdate() {
    if (this.tagsColumns.length > 0) {
      this.initializeColumnsToInclude();
    }
  }

  get isExpanded() {
    if (!this.data) return false;
    return this.data
      .filter((item) => (typeof this.isItemExpandable === 'function' && !this.isItemExpandable(item) ? false : true))
      .every((item) => item.is_expanded);
  }

  created() {
    this.initializeColumnsToInclude();
  }

  initializeColumnsToInclude() {
    this.columnsToInclude = this.tagsColumns.slice().map((el) => {
      return { ...el };
    });
  }

  onMenuConfirm() {
    this.columnsSelected(this.columnsToInclude);
    this.isMenuVisible = false;
  }

  onMenuCancel() {
    this.isMenuVisible = false;
    this.initializeColumnsToInclude();
  }

  toggleExpandAll() {
    if (this.isExpanded) {
      this.data
        .filter((item) => (typeof this.isItemExpandable === 'function' && !this.isItemExpandable(item) ? false : true))
        .forEach((item) => {
          this.$set(item, 'is_expanded', false);
        });
    } else {
      this.data
        .filter((item) => (typeof this.isItemExpandable === 'function' && !this.isItemExpandable(item) ? false : true))
        .forEach((item) => {
          this.$set(item, 'is_expanded', true);
        });
      this.expandAll();
    }
  }
}
