var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loader)?_c('div',{attrs:{"id":"main-wrapper"}},[_c('MatrixRelationsPageHeader',{attrs:{"title":_vm.pageTitle,"matrixType":_vm.getMatrixType,"isAllExpanded":_vm.isAllExpanded},on:{"toggle-expand-all":_vm.toggleExpandAll,"resume":_vm.onResume}}),(!_vm.isSidebarHidden)?_c('MatrixRelationsFilterSidebar'):_vm._e(),_c('table',{ref:"table",staticClass:"MatrixTable",class:{ 'MatrixTable--CursorUnsupported': !_vm.isCursorSupported },attrs:{"id":"matrix-table"}},[_c('thead',[_c('tr',{ref:"tableHead"},[_c('th',{staticClass:"MatrixTable__HeaderColumn"}),_vm._l((_vm.getVisibleColumns),function(column,index){return _c('th',{key:column.id,ref:"tableHeaderColumn",refInFor:true,staticClass:"MatrixTable__HeaderColumn",class:[
            { 'single-column': index == 0 },
            {
              'parent-expanded-column':
                column.isParent && column.level == 0 && column.children[0] && column.children[0].expanded,
            },
            {
              'parent-collapsed':
                (column.isParent && column.level == 0 && column.children[0] && !column.children[0].expanded) ||
                (!column.isParent && column.level == 0),
            } ],style:([
            column.isParent && column.level == 0 && column.children[0] && column.children[0].expanded && column.color
              ? { background: ("#" + (column.color)), color: 'white' }
              : {} ]),attrs:{"id":column.id}},[_c('div',{staticClass:"d-flex flex-column-reverse align-center justify-space-between cell-header-wrapper"},[_c('span',{staticClass:"MatrixTable__HeaderColumn__RotatedText d-flex align-center justify-center",class:{ 'regular-font': column.level > 1 }},[((!column.isParent && column.level != 0) || (column.isParent && column.level != 0))?_c('v-icon',{staticClass:"add-whole-item-wrapper",class:[
                  { 'add-whole-item': !_vm.wholeColumnSelected(column.id) },
                  { 'add-whole-item-blue': _vm.wholeColumnSelected(column.id) } ],on:{"click":function($event){return _vm.selectColumn(column)}}},[_vm._v(_vm._s(_vm.matrixRelationsData.matrix_type == _vm.matrixType.FIRST ? '$add-thick' : _vm.wholeColumnSelected(column.id) ? '$radio-checked' : '$radio-unchecked'))]):_vm._e(),_c('v-tooltip',{attrs:{"content-class":"ellipsis-text","right":"","nudge-left":"16","nudge-top":"75","transition":"fade"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"column-title",style:({
                      width: (column.level !== 0 ? _vm.theadTextHeight : _vm.theadTextHeight - 18) + 'px',
                      'padding-left': column.level === 0 ? '0' : ((16 + column.level * 8) + "px"),
                    }),attrs:{"id":((column.id) + "-text")}},'span',attrs,false),on),[_vm._v(" "+_vm._s(column.title)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(column.title))])])],1),_c('div',{staticClass:"expand-btn-wrapper"},[((!column.isParent || (column.isParent && column.level != 0)) && column.color)?_c('span',{staticClass:"column-child-color",style:({ background: ("#" + (column.color)) })}):_vm._e(),(column.children.length > 0)?_c('BaseButton',{staticClass:"column-expand-btn",attrs:{"type":column.level === 0 ? 'icon-septim' : 'icon-light'},on:{"click":function($event){return _vm.toogleExpand(column)}}},[(column.children[0].expanded)?_c('v-icon',[_vm._v("$collapse")]):_c('v-icon',[_vm._v("$reveal")])],1):_vm._e()],1),(index === 0)?_c('div',{staticClass:"column-resize-grabber",on:{"mousedown":function($event){$event.preventDefault();return _vm.onColumnResize.apply(null, arguments)}}},[_c('v-icon',[_vm._v("$resize-grabber")])],1):_vm._e()])])}),_c('th',{staticClass:"MatrixTable__HeaderColumn MatrixTable__HeaderColumn--Spacer"})],2)]),_c('tbody',{ref:"tableBody"},[(_vm.isCursorVisible)?_c('div',{staticClass:"position-absolute MatrixTable__CursorX guide-lines",style:({
          left: (_vm.cursorXLeft + "px"),
          width: (_vm.cursorXWidth + "px"),
          top: (_vm.cursorXTop + "px"),
        })}):_vm._e(),(_vm.isCursorVisible)?_c('div',{staticClass:"position-absolute MatrixTable__CursorY guide-lines",style:({
          top: (_vm.cursorYTop + "px"),
          height: (_vm.cursorYHeight + "px"),
          left: (_vm.cursorYLeft + "px"),
        })}):_vm._e(),_vm._l((_vm.getVisibleRows),function(row,index){return _c('tr',{key:row.id,staticClass:"MatrixTable__BodyRow"},[(_vm.matrixRelationsData.matrix_type == _vm.matrixType.FIRST)?_c('MatrixRelationsRowHeaderFirstType',{attrs:{"index":index,"row":row,"rerender":_vm.rerender},on:{"toogle-expand":_vm.toogleExpand,"rerender-items":_vm.rerenderItems}}):_vm._e(),(_vm.matrixRelationsData.matrix_type == _vm.matrixType.SECOND)?_c('MatrixRelationsRowHeaderSecondType',{attrs:{"index":index,"row":row,"rerender":_vm.rerender},on:{"toogle-expand":_vm.toogleExpand,"rerender-items":_vm.rerenderItems}}):_vm._e(),_vm._l((_vm.getVisibleColumns),function(column){return _c('td',{key:column.id,staticClass:"position-relative MatrixTable__BodyColumn MatrixTable__BodyColumn--RowCell",class:[
            { 'color-two-parents': _vm.getNumberOfFirstLevelParents(row, column) == 2 },
            { 'color-one-parent': _vm.getNumberOfFirstLevelParents(row, column) == 1 },
            { 'color-no-parents': _vm.getNumberOfFirstLevelParents(row, column) == 0 } ],on:{"mouseover":function($event){return _vm.showCursor(row.id, column.id)},"mouseout":_vm.hideCursor}},[(_vm.matrixRelationsData.matrix_type == _vm.matrixType.FIRST)?_c('MatrixRelationsFieldItemFirstType',{attrs:{"i":row.id,"j":column.id,"columnItem":column,"rowItem":row,"rerender":_vm.rerender,"numOfParentItems":_vm.getNumberOfFirstLevelParents(row, column)},on:{"rerender-items":_vm.rerenderItems}},[_vm._v(" >")]):_vm._e(),(_vm.matrixRelationsData.matrix_type == _vm.matrixType.SECOND)?_c('MatrixRelationsFieldItemSecondType',{attrs:{"i":row.id,"j":column.id,"columnItem":column,"rowItem":row,"rerender":_vm.rerender,"numOfParentItems":_vm.getNumberOfFirstLevelParents(row, column)},on:{"rerender-items":_vm.rerenderItems}},[_vm._v(" >")]):_vm._e()],1)})],2)})],2),_c('tfoot',[_c('tr',{staticClass:"MatrixTable__BodyRow"},[_c('th',{ref:"tableRowSpacer",staticClass:"position-relative MatrixTable__BodyColumn MatrixTable__BodyColumn--RowLabel"})])])]),_c('PageFooter',{attrs:{"scrollListenerSelector":"#matrix-table"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }