import { PresentationsSidebarOptionsData } from './../../models/presentations/PresentationsSidebarOptionsData';
import { PresentationsSidebarOptionItemEnum } from '../../enums/presentations-sidebar-option-item.enum';
import { PresentationsSidebarOption } from '@/app/shared/models/presentations/PresentationsSidebarOption';
import { PresentationsSidebarOptionGroupEnum } from '../../enums/presentations-sidebar-option-group.enum';

const SIDEBAR_OPTIONS_TABLE_LEVEL_OF_DETAIL_SIMPLE: PresentationsSidebarOption = {
  id: 1,
  label: 'Level of detail',
  value: PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_ONLY_LIST,
  type: PresentationsSidebarOptionGroupEnum.TABLE_LEVEL_OF_DETAIL_SIMPLE,
  items: [
    {
      label: 'Only list',
      value: PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_ONLY_LIST,
    },
    {
      label: 'With comments',
      value: PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_WITH_COMMENTS,
    },
  ],
};

const SIDEBAR_OPTIONS_TABLE_LEVEL_OF_DETAIL_FULL: PresentationsSidebarOption = {
  id: 2,
  label: 'Level of detail',
  type: PresentationsSidebarOptionGroupEnum.TABLE_LEVEL_OF_DETAIL,
  value: PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_ONLY_LIST,
  items: [
    {
      label: 'Only list',
      value: PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_ONLY_LIST,
    },
    {
      label: 'With comments',
      value: PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_WITH_COMMENTS,
    },
    {
      label: 'With details',
      value: PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_WITH_DETAILS,
    },
    {
      label: 'With details and comments',
      value: PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_WITH_DETAILS_AND_COMMENTS,
    },
  ],
};

const SIDEBAR_OPTIONS_TABLE_SIZE: PresentationsSidebarOption = {
  id: 3,
  label: 'Size',
  type: PresentationsSidebarOptionGroupEnum.TABLE_SIZE,
  value: PresentationsSidebarOptionItemEnum.TABLE_SIZE_FULL,
  items: [
    {
      label: 'Full size',
      value: PresentationsSidebarOptionItemEnum.TABLE_SIZE_FULL,
    },
    {
      label: 'Half size',
      value: PresentationsSidebarOptionItemEnum.TABLE_SIZE_HALF,
      disabled: false,
    },
  ],
};

const SIDEBAR_OPTIONS_TABLE_ENUMERATE: PresentationsSidebarOption = {
  id: 13,
  label: 'Enumerate',
  type: PresentationsSidebarOptionGroupEnum.TABLE_ENUMERATE,
  value: PresentationsSidebarOptionItemEnum.TABLE_ENUMERATE_WITHOUT_NUMBERS,
  items: [
    {
      label: 'With numbers',
      value: PresentationsSidebarOptionItemEnum.TABLE_ENUMERATE_WITH_NUMBERS,
    },
    {
      label: 'Without numbers',
      value: PresentationsSidebarOptionItemEnum.TABLE_ENUMERATE_WITHOUT_NUMBERS,
    },
  ],
};

const SIDEBAR_OPTIONS_STATS_SIZE: PresentationsSidebarOption = {
  id: 4,
  label: 'Size',
  type: PresentationsSidebarOptionGroupEnum.STATS_SIZE,
  value: PresentationsSidebarOptionItemEnum.STATS_SIZE_FULL,
  items: [
    {
      label: 'Full size',
      value: PresentationsSidebarOptionItemEnum.STATS_SIZE_FULL,
    },
    {
      label: 'Half size',
      value: PresentationsSidebarOptionItemEnum.STATS_SIZE_HALF,
    },
  ],
};

const SIDEBAR_OPTIONS_STATS_ROW: PresentationsSidebarOption = {
  id: 41,
  label: 'Row',
  type: PresentationsSidebarOptionGroupEnum.STATS_ROW,
  value: null,
  items: [
    {
      label: 'Receptors',
      value: PresentationsSidebarOptionItemEnum.STATS_RECEPTORS,
      disabled: false,
    },
    {
      label: 'Development phases',
      value: PresentationsSidebarOptionItemEnum.STATS_DEV_PHASES,
      disabled: false,
    },
    {
      label: 'Alternatives',
      value: PresentationsSidebarOptionItemEnum.STATS_ALTERNATIVES,
      disabled: false,
    },
  ],
};

const SIDEBAR_OPTIONS_STATS_COLUMN: PresentationsSidebarOption = {
  id: 42,
  label: 'Column',
  type: PresentationsSidebarOptionGroupEnum.STATS_COLUMN,
  value: null,
  items: [
    {
      label: 'Receptors',
      value: PresentationsSidebarOptionItemEnum.STATS_RECEPTORS,
      disabled: true,
    },
    {
      label: 'Development phases',
      value: PresentationsSidebarOptionItemEnum.STATS_DEV_PHASES,
      disabled: true,
    },
    {
      label: 'Alternatives',
      value: PresentationsSidebarOptionItemEnum.STATS_ALTERNATIVES,
      disabled: true,
    },
  ],
};

const SIDEBAR_OPTIONS_CHARTS_NORMAL: PresentationsSidebarOption = {
  id: 5,
  label: 'Normalisation',
  type: PresentationsSidebarOptionGroupEnum.CHARTS_NORMALIZATION,
  value: PresentationsSidebarOptionItemEnum.CHARTS_NORMAL_WITHOUT_NUMBERS,
  items: [
    {
      label: 'Without (numbers)',
      value: PresentationsSidebarOptionItemEnum.CHARTS_NORMAL_WITHOUT_NUMBERS,
    },
    {
      label: 'Per number of selected categories (percentage)',
      value: PresentationsSidebarOptionItemEnum.CHARTS_NORMAL_PER_NUMBER_OF_SELECTED_CATEGORIES,
    },
    {
      label: 'Per total number (percentage)',
      value: PresentationsSidebarOptionItemEnum.CHARTS_NORMAL_PER_TOTAL_NUMBER,
    },
  ],
};

const SIDEBAR_OPTIONS_CHARTS_GROUPED_FOR: PresentationsSidebarOption = {
  id: 6,
  label: 'Grouped for',
  type: PresentationsSidebarOptionGroupEnum.CHARTS_GROUPED_FOR,
  value: PresentationsSidebarOptionItemEnum.NONE,
  items: [
    {
      label: 'None',
      value: PresentationsSidebarOptionItemEnum.NONE,
    },
    {
      label: 'Receptors',
      value: PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_RECEPTORS,
    },
    {
      label: 'Development phases',
      value: PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_DEV_PHASES,
    },
    {
      label: 'Alternatives',
      value: PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_ALTERNATIVES,
    },
  ],
};

const SIDEBAR_OPTIONS_CHARTS_STACKED_FOR: PresentationsSidebarOption = {
  id: 7,
  label: 'Stacked for',
  type: PresentationsSidebarOptionGroupEnum.CHARTS_STACKED_FOR,
  value: PresentationsSidebarOptionItemEnum.NONE,
  items: [
    {
      label: 'None',
      value: PresentationsSidebarOptionItemEnum.NONE,
    },
    {
      label: 'Receptors',
      value: PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_RECEPTORS,
    },
    {
      label: 'Development phases',
      value: PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_DEV_PHASES,
    },
    {
      label: 'Alternatives',
      value: PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_ALTERNATIVES,
    },
  ],
};

export const PRESENTATIONS_SIDEBAR_OPTIONS: PresentationsSidebarOptionsData = {
  tables: {
    screening: [
      SIDEBAR_OPTIONS_TABLE_LEVEL_OF_DETAIL_SIMPLE,
      SIDEBAR_OPTIONS_TABLE_SIZE,
      SIDEBAR_OPTIONS_TABLE_ENUMERATE,
    ],
    scoping: [SIDEBAR_OPTIONS_TABLE_LEVEL_OF_DETAIL_FULL, SIDEBAR_OPTIONS_TABLE_SIZE],
    assessing: [SIDEBAR_OPTIONS_TABLE_LEVEL_OF_DETAIL_FULL, SIDEBAR_OPTIONS_TABLE_SIZE],
    question: [],
  },
  statistics: [SIDEBAR_OPTIONS_STATS_ROW, SIDEBAR_OPTIONS_STATS_COLUMN, SIDEBAR_OPTIONS_STATS_SIZE],
  charts: [SIDEBAR_OPTIONS_CHARTS_NORMAL, SIDEBAR_OPTIONS_CHARTS_GROUPED_FOR, SIDEBAR_OPTIONS_CHARTS_STACKED_FOR],
  impacts: [],
};

export const GRAPHIK_FONT_FAMILY_FOR_HTML_STYLE = `
@font-face {
font-family: 'Graphik';
src: url('assets/fonts/Graphik-Thin.woff2') format('woff2'), url('assets/fonts/Graphik-Thin.woff') format('woff'),
url('assets/fonts/Graphik-Thin.ttf') format('truetype');
font-weight: 100;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'Graphik';
src: url('assets/fonts/Graphik-ThinItalic.woff2') format('woff2'),
url('assets/fonts/Graphik-ThinItalic.woff') format('woff'),
url('assets/fonts/Graphik-ThinItalic.ttf') format('truetype');
font-weight: 100;
font-style: italic;
font-display: swap;
}

@font-face {
font-family: 'Graphik';
src: url('assets/fonts/Graphik-LightItalic.woff2') format('woff2'),
url('assets/fonts/Graphik-LightItalic.woff') format('woff'),
url('assets/fonts/Graphik-LightItalic.ttf') format('truetype');
font-weight: 300;
font-style: italic;
font-display: swap;
}

@font-face {
font-family: 'Graphik';
src: url('assets/fonts/Graphik-Regular.woff2') format('woff2'),
url('assets/fonts/Graphik-Regular.woff') format('woff'), url('assets/fonts/Graphik-Regular.ttf') format('truetype');
font-weight: normal;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'Graphik';
src: url('assets/fonts/Graphik-Light.woff2') format('woff2'), url('assets/fonts/Graphik-Light.woff') format('woff'),
url('assets/fonts/Graphik-Light.ttf') format('truetype');
font-weight: 300;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'Graphik';
src: url('assets/fonts/Graphik-Extralight.woff2') format('woff2'),
url('assets/fonts/Graphik-Extralight.woff') format('woff'),
url('assets/fonts/Graphik-Extralight.ttf') format('truetype');
font-weight: 200;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'Graphik';
src: url('assets/fonts/Graphik-Semibold.woff2') format('woff2'),
url('assets/fonts/Graphik-Semibold.woff') format('woff'),
url('assets/fonts/Graphik-Semibold.ttf') format('truetype');
font-weight: 600;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'Graphik';
src: url('assets/fonts/Graphik-Medium.woff2') format('woff2'), url('assets/fonts/Graphik-Medium.woff') format('woff'),
url('assets/fonts/Graphik-Medium.ttf') format('truetype');
font-weight: 500;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'Graphik';
src: url('assets/fonts/Graphik-RegularItalic.woff2') format('woff2'),
url('assets/fonts/Graphik-RegularItalic.woff') format('woff'),
url('assets/fonts/Graphik-RegularItalic.ttf') format('truetype');
font-weight: normal;
font-style: italic;
font-display: swap;
}

@font-face {
font-family: 'Graphik';
src: url('assets/fonts/Graphik-SemiboldItalic.woff2') format('woff2'),
url('assets/fonts/Graphik-SemiboldItalic.woff') format('woff'),
url('assets/fonts/Graphik-SemiboldItalic.ttf') format('truetype');
font-weight: 600;
font-style: italic;
font-display: swap;
}

@font-face {
font-family: 'Graphik';
src: url('assets/fonts/Graphik-MediumItalic.woff2') format('woff2'),
url('assets/fonts/Graphik-MediumItalic.woff') format('woff'),
url('assets/fonts/Graphik-MediumItalic.ttf') format('truetype');
font-weight: 500;
font-style: italic;
font-display: swap;
}

@font-face {
font-family: 'Graphik';
src: url('assets/fonts/Graphik-BlackItalic.woff2') format('woff2'),
url('assets/fonts/Graphik-BlackItalic.woff') format('woff'),
url('assets/fonts/Graphik-BlackItalic.ttf') format('truetype');
font-weight: 900;
font-style: italic;
font-display: swap;
}

@font-face {
font-family: 'Graphik';
src: url('assets/fonts/Graphik-BoldItalic.woff2') format('woff2'),
url('assets/fonts/Graphik-BoldItalic.woff') format('woff'),
url('assets/fonts/Graphik-BoldItalic.ttf') format('truetype');
font-weight: bold;
font-style: italic;
font-display: swap;
}

@font-face {
font-family: 'Graphik';
src: url('assets/fonts/Graphik-Bold.woff2') format('woff2'), url('assets/fonts/Graphik-Bold.woff') format('woff'),
url('assets/fonts/Graphik-Bold.ttf') format('truetype');
font-weight: bold;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'Graphik';
src: url('assets/fonts/Graphik-Black.woff2') format('woff2'), url('assets/fonts/Graphik-Black.woff') format('woff'),
url('assets/fonts/Graphik-Black.ttf') format('truetype');
font-weight: 900;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'Graphik Super';
src: url('assets/fonts/Graphik-Super.woff2') format('woff2'), url('assets/fonts/Graphik-Super.woff') format('woff'),
url('assets/fonts/Graphik-Super.ttf') format('truetype');
font-weight: 900;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'Graphik Super';
src: url('assets/fonts/Graphik-SuperItalic.woff2') format('woff2'),
url('assets/fonts/Graphik-SuperItalic.woff') format('woff'),
url('assets/fonts/Graphik-SuperItalic.ttf') format('truetype');
font-weight: 900;
font-style: italic;
font-display: swap;
}`;
