


















































































import { Component, Mixins, Vue } from 'vue-property-decorator';
import { BaseControlConfigSkeleton } from 'v-form-builder';

@Component
export default class FormConfigText extends Mixins(Vue, BaseControlConfigSkeleton) {
  get inputTypes() {
    return {
      password: {
        val: 'password',
        description: 'Password',
      },
      text: {
        val: 'text',
        description: 'Text',
      },

      // NB: Vuetify numeric field behaves in an unexpected way, by not providing ANY value if the input is non-numeric.
      //   Consider using `text` field and setting `validNumber` validator on it.
      number: {
        val: 'number',
        description: 'Number',
      },
    };
  }
}
