







import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseRadioButton extends Vue {
  @Prop() label: string;
  @Prop() value: any;
  @Prop() checked: boolean;
  @Prop() disabled?: boolean;
  @Prop() deselectEnabled?: boolean;

  @Emit('input')
  input(_event: any) {
    // just emiting event
  }

  onChange(_event: any) {
    if (this.deselectEnabled && this.checked) {
      this.$parent.$emit('input', null);
    } else {
      this.$parent.$emit('input', this.value);
    }
  }
}
