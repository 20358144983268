






















import { Tree } from 'he-tree-vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import api from '../api';
import { eventBus, EventType } from '../event-bus/eventBus';
import DialogService from '../utils/dialog.service';
import LibraryItemPreviewModal from './LibraryItemPreviewModal.vue';
import type LibraryItemType from '@/app/shared/models/library/LibraryItem';
import LoaderService from '@/app/shared/utils/loader.service';

const PresentationsStore = namespace('Presentations');

@Component({ components: { LibraryItemPreviewModal } })
export default class LibraryItem extends Vue {
  @Prop() node: any;
  @Prop() path: number[];
  @Prop() tree: Tree;
  @Prop() index: number;
  @Prop({ default: false }) hideEyeIcon: boolean;
  libraryItem: LibraryItemType = null;
  libraryModalVisible = false;

  // @PresentationsStore.Getter
  // tabs: Array<any>;

  @PresentationsStore.Action
  setSidebarDataForEditParagraph: (item: LibraryItemType) => Promise<any>;

  // @PresentationsStore.Action
  // initializeSidebarFilters: (payload: { studyUuid: string; moduleUuid: string }) => Promise<void>;

  // @PresentationsStore.Action
  // initializePresentation: (payload: { studyUuid: string; moduleUuid: string }) => Promise<void>;

  // @PresentationsStore.Action
  // initializeActiveTabs: () => Promise<void>;

  async getLibraryItem() {
    try {
      this.libraryItem = await api.library.getLibraryItem(this.node.uuid);
    } catch (error) {
      console.warn(error);
    }
  }

  showPreview() {
    this.libraryModalVisible = true;
    this.getLibraryItem();
  }

  async editParagraph() {
    try {
      await this.getLibraryItem().then(() => {
        this.$router.push({
          name: 'Presentations',
          params: {
            studyUuid: this.libraryItem.payload.module.study_uuid,
            moduleUuid: this.libraryItem.payload.module.module_uuid
          },
          query: {
            nodeUuid: this.node.uuid,
            reload: this.$route.name === 'Reports' ? 'false' : 'true'
          }
        }).then(() => {
          if (this.$route.query.reload == 'true') {

            // const payload = {
            //   studyUuid: this.libraryItem.payload.module.study_uuid,
            //   moduleUuid: this.libraryItem.payload.module.module_uuid,
            // };

            // this.initializeSidebarFilters(payload);
            // this.initializePresentation(payload);
            // this.initializeActiveTabs();

            this.setSidebarDataForEditParagraph(this.libraryItem);
            LoaderService.hideLoader();
          }
        });
      });
    } catch (error) {
      console.warn(error);
    }
  }

  async deleteParagraph() {
    try {
      await api.library.deleteLibraryItem(this.node.uuid);
      eventBus.$emit(EventType.LIBRARY_UPDATE);
    } catch (error) {
      console.warn(error);
    }
  }

  showDeleteDialog() {
    DialogService.presentDialog('Are you sure?', 'Lorem ipsum dolor sit amet.', 'Yes', 'Cancel', () => {
      this.deleteParagraph();
    });
  }
}
