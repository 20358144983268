
































import { Component, Vue, Prop } from 'vue-property-decorator';
import ScoreRange from './ScoreRange.vue';
import { UnitScore } from '@/app/shared/models/calculations/UnitScore';

@Component({
  components: { ScoreRange },
})
export default class ScoresTab extends Vue {
  @Prop() unitUuid: string;
  @Prop() scoreRanges: UnitScore[];
}
