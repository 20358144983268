export class SheetToHtmlParser {
  static parse(tableData: any) {
    return new SheetToHtmlParser().sheetToHtml(tableData);
  }

  sheetToHtml(tableData: any) {
    let htmlOutput = "<table class='table-exported' style='border-collapse: collapse'>";
    const rows: any = Object.entries(tableData.rows);
    const cols: any = tableData.cols;
    const styles: any = tableData.styles;
    const startRow: any = Number(rows[0][0]);
    const tableRows: any = [];
    let i = 0;
    let rowsLength = rows.length;
    if (rows[rows.length - 1][0] == 'len') {
      rowsLength = rows.length - 1;
    }
    for (let j = 0; j < rowsLength; j++) {
      while (Number(rows[j][0]) > i) {
        tableRows[i] = { cells: [], html: '', height: 25 };
        i++;
      }
      tableRows[i] = {
        cells: this.assignCells(Object.entries(rows[j][1]['cells'])),
        html: '',
        height: rows[j][1]['height'] ? rows[j][1]['height'] : 25,
      };
      i++;
    }
    const columnsRange = this.getColumnsRange(tableRows);
    const columnsRangeAlphabet = this.getColumnsNames(columnsRange[1]);

    for (let i = 0; i < tableRows.length; i++) {
      for (let j = 0; j <= columnsRange[1]; j++) {
        if (tableRows[i].cells.length == 0 || !tableRows[i].cells[j]) {
          tableRows[i].cells[j] = { value: {}, html: '', merged: false };
        }
      }
    }
    // assign column widths
    htmlOutput += '<colgroup>';
    for (let c = columnsRange[0]; c <= columnsRange[1]; c++) {
      let columnHeader = '<col ';
      if (cols[c] && cols[c]['width']) {
        columnHeader += 'width="' + cols[c]['width'] + '">';
      } else {
        columnHeader += 'width="' + 100 + '">';
      }
      htmlOutput += columnHeader;
    }
    htmlOutput += '</colgroup>';
    let theadRows = 1;
    // populate rows
    for (let i = startRow; i < tableRows.length; i++) {
      if (i == startRow) {
        tableRows[i].html = '<thead><tr height="' + tableRows[i].height + '">';
      } else {
        tableRows[i].html = '<tr height="' + tableRows[i].height + '">';
      }
      for (let k = columnsRange[0]; k <= columnsRange[1]; k++) {
        tableRows[i].cells[k].html = '<td';
        const endCellStyles = [];
        if (tableRows[i].cells[k] && !tableRows[i].cells[k].merged) {
          if (tableRows[i].cells[k].value.merge) {
            // if there is colspan check for rowspan also
            if (tableRows[i].cells[k].value.merge[1] > 0) {
              tableRows[i].cells[k].html += ' colspan="' + (tableRows[i].cells[k].value.merge[1] + 1) + '"';
              if (tableRows[i].cells[k].value.merge[0] > 0) {
                tableRows[i].cells[k].html += ' rowspan="' + (tableRows[i].cells[k].value.merge[0] + 1) + '"';
                if (i == startRow) {
                  theadRows = tableRows[i].cells[k].value.merge[0] + 1;
                }
                for (let l = 1; l <= tableRows[i].cells[k].value.merge[0]; l++) {
                  for (let m = 0; m <= tableRows[i].cells[k].value.merge[1]; m++) {
                    if (!tableRows[i + l]) {
                      tableRows[i + l] = { cells: [], html: '', height: 25 };
                      for (let c = 0; c <= columnsRange[1]; c++) {
                        tableRows[i + l].cells[c] = { value: {}, html: '', merged: false };
                      }
                    }
                    tableRows[i + l].cells[k + m].html = '';
                    tableRows[i + l].cells[k + m].merged = true;
                    if (
                      l == tableRows[i].cells[k].value.merge[0] &&
                      tableRows[i + l].cells[k + m].value.style !== null
                    ) {
                      endCellStyles[endCellStyles.length] = tableRows[i + l].cells[k + m].value.style;
                    }
                  }
                  if (tableRows[i + l].cells[k + tableRows[i].cells[k].value.merge[1]].value.style !== null) {
                    endCellStyles[endCellStyles.length] =
                      tableRows[i].cells[k + tableRows[i].cells[k].value.merge[1]].value.style;
                  }
                }
              } else {
                for (let m = 1; m <= tableRows[i].cells[k].value.merge[1]; m++) {
                  if (typeof tableRows[i].cells[k + m] != 'undefined') {
                    tableRows[i].cells[k + m].html = '';
                    tableRows[i].cells[k + m].merged = true;
                  }
                }
                if (
                  typeof tableRows[i].cells[k + tableRows[i].cells[k].value.merge[1]] != 'undefined' &&
                  tableRows[i].cells[k + tableRows[i].cells[k].value.merge[1]].value.style !== null
                ) {
                  endCellStyles[endCellStyles.length] =
                    tableRows[i].cells[k + tableRows[i].cells[k].value.merge[1]].value.style;
                }
              }
            }
            // there is only rowspan
            else if (tableRows[i].cells[k].value.merge[0] > 0) {
              tableRows[i].cells[k].html += ' rowspan="' + (tableRows[i].cells[k].value.merge[0] + 1) + '"';
              if (i == startRow) {
                theadRows = tableRows[i].cells[k].value.merge[0] + 1;
              }
              for (let l = 1; l <= tableRows[i].cells[k].value.merge[0]; l++) {
                if (!tableRows[i + l]) {
                  tableRows[i + l] = { cells: [], html: '', height: 25 };
                  for (let c = 0; c <= columnsRange[1]; c++) {
                    tableRows[i + l].cells[c] = { value: {}, html: '', merged: false };
                  }
                }
                tableRows[i + l].cells[k].html = '';
                tableRows[i + l].cells[k].merged = true;
                if (l == tableRows[i].cells[k].value.merge[0] && tableRows[i + l].cells[k].value.style !== null) {
                  endCellStyles[endCellStyles.length] = tableRows[i + l].cells[k].value.style;
                }
              }
            }
          }
        }
        let cellText = '&nbsp;';
        if (tableRows[i].cells[k].value.text) {
          tableRows[i].cells[k].value.text = this.checkCellText(
            tableRows[i].cells[k].value.text,
            columnsRangeAlphabet,
            tableRows,
          );
          cellText = tableRows[i].cells[k].value.text;
        }
        let cellStyle = null;
        if (tableRows[i].cells[k].value.style !== null) {
          cellStyle = tableRows[i].cells[k].value.style;
        }
        cellText = this.setTextFormat(cellText, cellStyle, styles);
        tableRows[i].cells[k].html += this.assignCellStyles(cellStyle, styles, endCellStyles);
        tableRows[i].cells[k].html += '>' + cellText + '</td>';
        if (!tableRows[i].cells[k].merged) {
          tableRows[i].html += tableRows[i].cells[k].html;
        }
        if (tableRows[i].cells[k].value.merge) {
          if (tableRows[i].cells[k].value.merge[1] > 0) {
            k += tableRows[i].cells[k].value.merge[1];
            if (k >= tableRows[i].cells.length) {
              break;
            }
          }
        }
      }
      if (i == startRow && theadRows == 1) {
        tableRows[i].html += '</tr></thead><tbody>';
      } else if (theadRows == i - startRow + 1) {
        tableRows[i].html += '</tr></thead><tbody>';
      } else {
        tableRows[i].html += '</tr>';
      }
      htmlOutput += tableRows[i].html;
    }
    htmlOutput += '</tbody></table>';
    return htmlOutput;
  }

  private assignCells(cells: any) {
    const resultCells: any[] = [];
    let i = 0;
    for (let j = 0; j < cells.length; j++) {
      while (Number(cells[j][0]) > i) {
        resultCells[i] = { value: {}, html: '<td></td>', merged: false };
        i++;
      }
      resultCells[i] = { value: cells[j][1] ? cells[j][1] : {}, html: '', merged: false };
      i++;
    }
    return resultCells;
  }

  private getColumnsRange(data: any) {
    let min = 0;
    let max = 0;
    let mergeMax = max;
    for (let i = 0; i < data.length; i++) {
      if (data[i].cells.length > 0) {
        for (let j = 0; j < data[i].cells.length; j++) {
          if (data[i].cells[j].value && (data[i].cells[j].value.text || data[i].cells[j].value.style)) {
            min = j;
            break;
          }
        }
      }
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].cells.length > 0) {
        for (let j = 0; j < data[i].cells.length; j++) {
          if (data[i].cells[j].value && (data[i].cells[j].value.text || data[i].cells[j].value.style)) {
            if (min >= j) {
              min = j;
              // check if it has col merge
              if (data[i].cells[j].value.merge && data[i].cells[j].value.merge[1] > 0) {
                mergeMax = Math.max(mergeMax, max + data[i].cells[j].value.merge[1]);
              }
            }
            if (max <= j) {
              max = j;
            }
          }
        }
      }
    }
    max = Math.max(max, mergeMax);
    return [min, max];
  }

  private assignCellStyles(styleNumber: any, styles: any, endCellStyles: any) {
    let output = '';
    if (endCellStyles.length > 0) {
      for (let i = 0; i < endCellStyles.length; i++) {
        if (styles[endCellStyles[i]] && styles[endCellStyles[i]].border) {
          if (styleNumber === null) {
            styleNumber = endCellStyles[i];
          } else if (styles[styleNumber] && !styles[styleNumber].border) {
            styles[styleNumber].border = styles[endCellStyles[i]].border;
          } else if (styles[styleNumber] && styles[styleNumber].border) {
            for (const border in styles[endCellStyles[i]].border) {
              if (styles[endCellStyles[i]].border.hasOwnProperty(border)) {
                if (!styles[styleNumber].border[border]) {
                  styles[styleNumber].border[border] = styles[endCellStyles[i]].border[border];
                }
              }
            }
          }
        }
      }
    }
    if (styleNumber !== null && styles[styleNumber]) {
      output += ' style="padding: 0 3px;';
      let textDecoration = '';
      for (const style in styles[styleNumber]) {
        if (styles[styleNumber].hasOwnProperty(style)) {
          switch (style) {
            case 'underline':
              if (styles[styleNumber][style] == true) {
                textDecoration = 'text-decoration: underline';
              }
              break;
            case 'strike':
              if (styles[styleNumber][style] == true) {
                textDecoration = 'text-decoration: line-through';
                if (styles[styleNumber]['underline'] == true) {
                  textDecoration += ' underline';
                }
              }
              break;
            case 'textwrap':
              if (styles[styleNumber][style] == true) {
                output += 'overflow-wrap: anywhere;';
              }
              break;
            case 'align':
              output += 'text-align: ' + styles[styleNumber][style] + ';';
              break;
            case 'valign':
              output += 'vertical-align: ' + styles[styleNumber][style] + ';';
              break;
            case 'bgcolor':
              output += 'background-color: ' + styles[styleNumber][style] + ';';
              break;
            case 'color':
              output += 'color: ' + styles[styleNumber][style] + ';';
              break;
            case 'font':
              if (styles[styleNumber][style]['name']) {
                output += 'font-family: ' + styles[styleNumber][style]['name'] + ';';
              } else {
                output += 'font-family: Arial;';
              }
              if (styles[styleNumber][style]['size']) {
                output += 'font-size: ' + styles[styleNumber][style]['size'] + 'pt;';
              } else {
                output += 'font-size: 10pt;';
              }
              if (styles[styleNumber][style]['italic'] == true) {
                output += 'font-style: italic;';
              }
              if (styles[styleNumber][style]['bold'] == true) {
                output += 'font-weight: bold;';
              }
              break;
            case 'border':
              if (styles[styleNumber][style]['top']) {
                let borderType = 'solid';
                let borderSize = '1px';
                switch (styles[styleNumber][style]['top'][0]) {
                  case 'medium':
                    borderSize = '2px';
                    break;
                  case 'thick':
                    borderSize = '3px';
                    break;
                  case 'dashed':
                    borderType = 'dashed';
                    break;
                  case 'dotted':
                    borderType = 'dotted';
                    break;
                }
                output +=
                  'border-top: ' + borderSize + ' ' + borderType + ' ' + styles[styleNumber][style]['top'][1] + ';';
              }
              if (styles[styleNumber][style]['right']) {
                let borderType = 'solid';
                let borderSize = '1px';
                switch (styles[styleNumber][style]['right'][0]) {
                  case 'medium':
                    borderSize = '2px';
                    break;
                  case 'thick':
                    borderSize = '3px';
                    break;
                  case 'dashed':
                    borderType = 'dashed';
                    break;
                  case 'dotted':
                    borderType = 'dotted';
                    break;
                }
                output +=
                  'border-right: ' + borderSize + ' ' + borderType + ' ' + styles[styleNumber][style]['right'][1] + ';';
              }
              if (styles[styleNumber][style]['bottom']) {
                let borderType = 'solid';
                let borderSize = '1px';
                switch (styles[styleNumber][style]['bottom'][0]) {
                  case 'medium':
                    borderSize = '2px';
                    break;
                  case 'thick':
                    borderSize = '3px';
                    break;
                  case 'dashed':
                    borderType = 'dashed';
                    break;
                  case 'dotted':
                    borderType = 'dotted';
                    break;
                }
                output +=
                  'border-bottom: ' +
                  borderSize +
                  ' ' +
                  borderType +
                  ' ' +
                  styles[styleNumber][style]['bottom'][1] +
                  ';';
              }
              if (styles[styleNumber][style]['left']) {
                let borderType = 'solid';
                let borderSize = '1px';
                switch (styles[styleNumber][style]['left'][0]) {
                  case 'medium':
                    borderSize = '2px';
                    break;
                  case 'thick':
                    borderSize = '3px';
                    break;
                  case 'dashed':
                    borderType = 'dashed';
                    break;
                  case 'dotted':
                    borderType = 'dotted';
                    break;
                }
                output +=
                  'border-left: ' + borderSize + ' ' + borderType + ' ' + styles[styleNumber][style]['left'][1] + ';';
              }
              break;
            default:
              output += '';
          }
          if (!styles[styleNumber]['font']) {
            output += 'font-family: Arial;';
            output += 'font-size: 10pt;';
          }
          if (textDecoration != '') {
            output += textDecoration + ';';
          }
        }
      }
      output += '"';
    } else {
      output += ' style="padding: 0 3px;font-family: Arial;font-size: 10pt;"';
    }
    return output;
  }

  private setTextFormat(text: any, styleNumber: any, styles: any) {
    if (text !== null && styleNumber !== null && styles[styleNumber] && styles[styleNumber].format != null) {
      switch (styles[styleNumber].format) {
        // @todo format strings for dates.
        case 'normal':
        case 'text':
        case 'date':
        case 'time':
        case 'datetime':
        case 'duration':
          return text;
        case 'number':
          return this.formatNumber(text);
        case 'percent':
          return this.formatNumber(text) + '%';
        case 'rmb':
          return '￥' + this.formatNumber(text);
        case 'usd':
          return '$' + this.formatNumber(text);
      }
    }
    return text;
  }

  private formatNumber(number: any) {
    if (/^(-?\d*.?\d*)$/.test(number)) {
      number = Number(number)
        .toFixed(2)
        .toString();
    }
    return number;
  }

  private getColumnsNames(colNum: number) {
    const a = 65;
    const columns: any = {};
    if (colNum !== null) {
      for (let i = 0; i <= colNum; i++) {
        columns[String.fromCharCode(a + i)] = i;
      }
    }
    return columns;
  }

  private checkCellText(text: any, columns: any, data: any) {
    if (text !== null) {
      if (
        text.startsWith('=SUM(') ||
        text.startsWith('=AVERAGE(') ||
        text.startsWith('=MIN(') ||
        text.startsWith('=MAX(') ||
        text.startsWith('=CONCAT(')
      ) {
        const op = text.substring(1, text.indexOf('('));
        text = /\(([^)]*)\)/.exec(text)[1];
        const startCell = text.substring(0, text.indexOf(':'));
        const endCell = text.substring(text.indexOf(':') + 1);
        const startCol = startCell[0];
        const endCol = endCell[0];
        const startRow = startCell.substring(1);
        const endRow = endCell.substring(1);
        if (
          !startRow.isNaN &&
          !endRow.isNaN &&
          startCol &&
          columns[startCol.toString()] !== null &&
          endCol &&
          columns[endCol.toString()] !== null
        ) {
          let result: any = '';
          if (startCol == endCol || (startCol != endCol && startRow != endRow)) {
            const column = columns[endCol.toString()];
            switch (op) {
              case 'SUM':
                result = 0;
                for (let i = startRow - 1; i < endRow; i++) {
                  if (
                    data[i].cells !== null &&
                    data[i].cells[column] !== null &&
                    data[i].cells[column].value.text !== null
                  ) {
                    result += Number(data[i].cells[column].value.text);
                  }
                }
                break;
              case 'AVERAGE':
                result = 0;
                for (let i = startRow - 1; i < endRow; i++) {
                  if (
                    data[i].cells !== null &&
                    data[i].cells[column] !== null &&
                    data[i].cells[column].value.text !== null
                  ) {
                    result += Number(data[i].cells[column].value.text);
                  }
                }
                result = result / (endRow - startRow + 1);
                break;
              case 'MIN':
                for (let i = startRow - 1; i < endRow; i++) {
                  if (
                    data[i].cells !== null &&
                    data[i].cells[column] !== null &&
                    data[i].cells[column].value.text !== null
                  ) {
                    if (result == '' || result > Number(data[i].cells[column].value.text)) {
                      result = Number(data[i].cells[column].value.text);
                    }
                  }
                }
                break;
              case 'MAX':
                for (let i = startRow - 1; i < endRow; i++) {
                  if (
                    data[i].cells !== null &&
                    data[i].cells[column] !== null &&
                    data[i].cells[column].value.text !== null
                  ) {
                    if (result == '' || result < Number(data[i].cells[column].value.text)) {
                      result = Number(data[i].cells[column].value.text);
                    }
                  }
                }
                break;
              case 'CONCAT':
                for (let i = startRow - 1; i < endRow; i++) {
                  if (
                    data[i].cells !== null &&
                    data[i].cells[column] !== null &&
                    data[i].cells[column].value.text !== null
                  ) {
                    result += data[i].cells[column].value.text.toString();
                  }
                }
                break;
            }
          } else if (startRow == endRow) {
            const row = startRow - 1;
            switch (op) {
              case 'SUM':
                result = 0;
                for (let i = columns[startCol.toString()]; i <= columns[endCol.toString()]; i++) {
                  if (
                    data[row].cells !== null &&
                    data[row].cells[i] !== null &&
                    data[row].cells[i].value.text !== null
                  ) {
                    result += Number(data[row].cells[i].value.text);
                  }
                }
                break;
              case 'AVERAGE':
                result = 0;
                for (let i = columns[startCol.toString()]; i <= columns[endCol.toString()]; i++) {
                  if (
                    data[row].cells !== null &&
                    data[row].cells[i] !== null &&
                    data[row].cells[i].value.text !== null
                  ) {
                    result += Number(data[row].cells[i].value.text);
                  }
                }
                result = result / (columns[endCol.toString()] - columns[startCol.toString()] + 1);
                break;
              case 'MIN':
                for (let i = columns[startCol.toString()]; i <= columns[endCol.toString()]; i++) {
                  if (
                    data[row].cells !== null &&
                    data[row].cells[i] !== null &&
                    data[row].cells[i].value.text !== null
                  ) {
                    if (result == '' || result > Number(data[row].cells[i].value.text)) {
                      result = Number(data[row].cells[i].value.text);
                    }
                  }
                }
                break;
              case 'MAX':
                for (let i = columns[startCol.toString()]; i <= columns[endCol.toString()]; i++) {
                  if (
                    data[row].cells !== null &&
                    data[row].cells[i] !== null &&
                    data[row].cells[i].value.text !== null
                  ) {
                    if (result == '' || result < Number(data[row].cells[i].value.text)) {
                      result = Number(data[row].cells[i].value.text);
                    }
                  }
                }
                break;
              case 'CONCAT':
                for (let i = columns[startCol.toString()]; i <= columns[endCol.toString()]; i++) {
                  if (
                    data[row].cells !== null &&
                    data[row].cells[i] !== null &&
                    data[row].cells[i].value.text !== null
                  ) {
                    result += data[row].cells[i].value.text.toString();
                  }
                }
                break;
            }
          }
          text = result;
        } else {
          text = '';
        }
      }
    } else {
      text = '';
    }
    return text;
  }
}
