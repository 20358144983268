export class UnitScore {
  color: string;
  id: number;
  score: number;
  score_range: string;
  uuid: string;

  constructor() {
    this.color = null;
    this.id = null;
    this.score = null;
    this.score_range = null;
    this.uuid = null;
  }
}
