

























































import { Component, Emit, Prop } from 'vue-property-decorator';
import ChapterItemCommentHighlight from './mixins/ChapterItemCommentHighlight.vue';
import { ChapterItem } from '@/app/shared/models/reports/ChapterItem';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import { ChapterItemMode } from '@/app/shared/types/chapter-item-mode.type';
import TinyEditor from '@/app/shared/components/TinyEditor.vue';
import _debounce from 'lodash.debounce';

import { UpdateChapterItemPayload, WS_DEBOUNCE_VALUE } from '@/app/shared/store/modules/reports.store-module';

@Component({
  components: { TinyEditor },
})
export default class TextChapterItem extends ChapterItemCommentHighlight {
  @Prop() item: ChapterItem;
  @Prop() mode: ChapterItemMode;
  @Prop() isDeleteButtonAllowed: boolean;

  ChapterItemType: ChapterItemType;
  supportsTextHighlighting = true;

  @Emit('switchMode')
  switchMode(_newMode: ChapterItemMode) {
    // just emiting event
  }

  @Emit('deleteChapterItem')
  deleteChapterItem() {
    // just emiting event
  }

  @Emit('update')
  updateChapterItem(_payload: UpdateChapterItemPayload) {
    // just emiting event
  }

  @Emit('toggleExpand')
  toggleExpand() {
    // just emiting event
  }

  get isExpanded() {
    return this.item && this.item.isExpandedInContent;
  }

  onTextChange = _debounce((event: string) => {
    if (event !== this.item.text) {
      this.updateChapterItem({
        uuid: this.item.uuid,
        type: ChapterItemType.TEXT,
        propertyName: 'text',
        propertyValue: event,
      });
    }
  }, WS_DEBOUNCE_VALUE);
}
