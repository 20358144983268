import { UserRole } from '../../enums/user-role.enum';
import { User } from '../../models/User';
import httpClient from '@/app/shared/api/axios.config';

const PERSON_ENDPOINT = '/person';

export default {
  async getPerson(uuid: string): Promise<User> {
    const data: User = await httpClient.get(`${PERSON_ENDPOINT}/${uuid}`);

    // TODO: dev only
    if (data.email.startsWith('admin')) data.role = UserRole.ADMIN;
    else data.role = UserRole.USER;

    return data;
  },
};
