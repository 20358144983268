export default (fieldValue: string, valuesContainer: { password: string }): boolean => {
  // Will be handled by the required validator.
  if (fieldValue === undefined || fieldValue === null || !fieldValue.length) return true;

  // Check if the password field exists in the form.
  //  Log an error if that's not the case.
  if (!valuesContainer.hasOwnProperty('password')) {
    console.error('Did you forget to include a field named `password` in your form?');
  }

  return fieldValue === valuesContainer.password;
};
