var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.active)?_c('editor',{staticClass:"app-tiny-editor",attrs:{"api-key":"cfyuhwoejy79izedb5kc516o21107x4f9ajgnh2djlwo4552","init":{
    menubar: false,
    contextmenu: false,
    toolbar_drawer: 'floating',
    plugins: [
      'link',
      'lists advlist searchreplace wordcount visualblocks visualchars code fullscreen charmap',
      'table',
      'autoresize' ],
    autoresize_bottom_margin: 10,
    extended_valid_elements: 'annotation,span[data-uuid|class|style]',
    toolbar:
      'undo redo cut copy paste searchreplace | bold italic underline styleselect removeformat | flite | lance | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | forecolor backcolor | charmap | code fullscreen',
    invalid_elements: 'img',
    min_height: 124,
    max_height: 500,
    browser_spellcheck: true,
    content_style: 'body {font-family: Graphik, Avenir, Helvetica, Arial, sans-serif;}',
  },"output-format":_vm.outputFormat},on:{"onInit":_vm.onInit},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }