
import { Component } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ExpandableSidebarForm from '@/app/shared/components/mixins/ExpandableSidebarForm.vue';
import changePasswordFormSchema from '@/app/shared/components/form/schema/change-password.json';
import api from '@/app/shared/api';

@Component
export default class UserChangePasswordForm extends ExpandableSidebarForm {
  get form() {
    return {
      id: 'change-password',
      label: 'Change Password',
      schema: changePasswordFormSchema,
    };
  }

  get isFormSingle() {
    return true;
  }

  get hasFormSubmitButton() {
    return true;
  }

  get formSubmitButtonLabel() {
    return 'Update';
  }

  get backRoute() {
    return '/user-profile';
  }

  async onSubmit(payload: any) {
    this.serverError = null;

    try {
      await api.user.submitChangePasswordForm(payload);
      await this.redirectOnSuccess();
    } catch (error) {
      this.serverError = error as AxiosError;
    }
  }
}
