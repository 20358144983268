import httpClient from '@/app/shared/api/axios.config';
import { OptionItem } from '@/app/shared/models/form/field/FormFieldSelect';

const OPTIONS_ENDPOINT = '/options';

export default {
  async getOptions(payload: {
    model: string;
    context?: Record<string, any>;
    key: string;
    value: string;
    concat?: any;
  }): Promise<OptionItem[]> {
    return await httpClient.post(`${OPTIONS_ENDPOINT}/${payload.model}`, {
      context: payload.context || {},
      key: payload.key || '',
      value: payload.value || '',
      concat: payload.concat || {},
    });
  },

  async getCustomOptions(
    route: string,
    payload: {
      context?: Record<string, any>;
      key: string;
      value: string;
      concat?: any;
    },
  ): Promise<OptionItem[]> {
    return await httpClient.post(route, {
      context: payload.context || {},
      key: payload.key || '',
      value: payload.value || '',
      concat: payload.concat || {},
    });
  },
};
