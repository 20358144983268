import { Vue } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import { AssessData } from '@/app/shared/models/calculations/AssessData';
import { SingleImpact } from '@/app/shared/models/reports/SingleImpact';
import { CheckTree, CheckTreeSelection } from '@/app/shared/models/CheckTree';
import { UnitList } from '@/app/shared/models/calculations/UnitList';
import { LibraryParagraph } from '@/app/shared/models/presentations/LibraryParagraph';
import { EventType } from '@/app/shared/event-bus/eventBus';
import { eventBus } from '@/app/shared/event-bus/eventBus';
import { PresentationsSidebarOptionsData } from '@/app/shared/models/presentations/PresentationsSidebarOptionsData';
import { PRESENTATIONS_SIDEBAR_OPTIONS } from './presentations.constants';
import {
  PresentationsTableType,
  PresentationsTabType,
  PresentationsStatsType,
  PresentationsChartsType,
} from '@/app/shared/types/presentations.types';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { PresentationsSidebarOption } from '../../models/presentations/PresentationsSidebarOption';
import api from '@/app/shared/api';
import LoaderService from '@/app/shared/utils/loader.service';
import CheckTreeItem from '@/app/shared/models/CheckTreeItem';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import { PresentationsSidebarOptionGroupEnum } from '@/app/shared/enums/presentations-sidebar-option-group.enum';
import { PresentationsSidebarOptionItemEnum } from '@/app/shared/enums/presentations-sidebar-option-item.enum';
import { PresentationsSidebarOptionSelection } from '@/app/shared/models/presentations/PresentationsSidebarOption';
import { ModuleChecktree } from '@/app/shared/models/Module';
import store from '..';

export interface ToggleSidebarSelectionItemCheckPayload {
  checked: boolean;
  checktreeUuid: string;
  checktreeItemUuid: string;
}

export interface ScreeningTableSelection {
  title: string;
  checktree: CheckTree;
}

export interface ScopingTableSelection {
  title: string;
  unitList: UnitList;
}

export interface AssessingTableSelection {
  title: string;
  impacts: AssessData[];
}

export interface StatisticsData {
  title: string;
  data: Array<Array<string>>;
}

export interface ToggleSingleImpactCheckPayload {
  checked: boolean;
  impact: SingleImpact;
}

export interface EditLibraryParagraphData {
  selection: CheckTree[];
  options: PresentationsSidebarOption[];
}

const util = {
  walkChecktree(checktree: CheckTree, node: CheckTreeItem, callback: (el: CheckTreeItem) => void): void {
    if (node) {
      callback(node);
      if (node.children && node.children.length > 0) {
        for (const childNode of node.children) {
          this.walkChecktree(checktree, childNode, callback);
        }
      }
    } else {
      // root level
      for (const firstLevelNode of checktree.children) {
        this.walkChecktree(checktree, firstLevelNode, callback);
      }
    }
  },
  findChecktreeItem(checktree: CheckTree, node: CheckTreeItem, uuid: string): CheckTreeItem {
    if (node) {
      if (node.uuid === uuid) {
        return node;
      } else {
        let foundItem = null;
        for (const item of node.children) {
          foundItem = util.findChecktreeItem(checktree, item, uuid);
          if (foundItem) break;
        }
        return foundItem;
      }
    } else {
      // root level
      let foundItem = null;
      for (const item of checktree.children) {
        foundItem = util.findChecktreeItem(checktree, item, uuid);
        if (foundItem) break;
      }
      return foundItem;
    }
  },
  enumerateChecktree(checktree: CheckTree, node: CheckTreeItem) {
    if (node) {
      if (node.children) {
        node.children.forEach((el, index) => {
          el.presentationEnumeration = node.presentationEnumeration + '.' + ++index;
          this.enumerateChecktree(checktree, el);
        });
      }
    } else {
      // root level
      checktree.children.forEach((el, index) => {
        el.presentationEnumeration = (index + 1).toString();
        this.enumerateChecktree(checktree, el);
      });
    }
  },
};

@Module({ namespaced: true })
export class PresentationsModule extends VuexModule {
  // tabs: Array<any> = [];
  studyUuid: string = '';
  moduleUuid: string = '';
  moduleChecktrees: ModuleChecktree[] = null;
  sidebarChecktrees: CheckTree[] = null;

  // tables selection
  screeningTableSelection: ScreeningTableSelection = null;
  scopingTableSelection: ScopingTableSelection = null;
  assessingTableSelection: AssessingTableSelection = null;

  // statistics selection
  statisticsSelectionData: StatisticsData = null;

  // impacts selection
  impactsSelection: SingleImpact[] = null;

  sidebarOptionsData: PresentationsSidebarOptionsData = cloneDeep(PRESENTATIONS_SIDEBAR_OPTIONS);
  activeTab: PresentationsTabType = 'tables';
  activeTableType: PresentationsTableType = null;
  activeTableTitle: string = '';
  activeChartType: PresentationsChartsType = null;
  activeChartTitle: string = '';
  activeStatsType: PresentationsStatsType = null;
  activeStatsTitle: string = '';

  meansOfPresentation: Array<any> = [];
  typesOfMeans: { [key: string]: any[] } = {};
  presentationTitle: string = '';

  // editLibraryParagraphData: EditLibraryParagraphData = null;

  get sidebarOptions() {
    return this.activeTab === 'tables'
      ? this.sidebarOptionsData[this.activeTab][this.activeTableType]
      : this.sidebarOptionsData[this.activeTab];
  }

  findChecktree(uuid: string): CheckTree {
    const moduleChecktree = this.moduleChecktrees?.find(
      (moduleChecktree) =>
        (moduleChecktree.source_uuid && moduleChecktree.source_uuid === uuid) || moduleChecktree.uuid === uuid,
    );
    return this.sidebarChecktrees?.find((checktree) => checktree.uuid === moduleChecktree?.uuid);
  }

  get alternativesChecktree() {
    return this.findChecktree('1e081f51-f039-4861-9a6b-2d166ff8cdf9');
  }

  get developmentPhasesChecktree() {
    return this.findChecktree('0ce1618d-79c8-4a42-93da-78d91c6b5c5f');
  }

  get methodApproachChecktree() {
    return this.findChecktree('5633869e-71a9-4d93-911c-571210595604');
  }

  get receptorsChecktree() {
    return this.findChecktree('5354bf82-804c-490f-8457-b3b93d299f17');
  }

  get checktreeSelection() {
    return this.sidebarChecktrees.reduce(
      (checktreeSelection, checktree) =>
        [
          ...checktreeSelection,
          {
            id: checktree.id,
            uuid: checktree.uuid,
            children: checktree.children?.map((checktreeItem) => ({
              id: checktreeItem.id,
              uuid: checktreeItem.uuid,
              checked: checktreeItem.checked,
            })),
          },
        ] as CheckTreeSelection[],
      [],
    );
  }

  get options() {
    return this.sidebarOptions
      ? this.sidebarOptions.reduce((options, option) => {
          let value: string = option.value;

          // Map certain option values to checktree UUIDs.
          switch (value) {
            case PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_ALTERNATIVES:
            case PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_ALTERNATIVES:
              value = this.alternativesChecktree.uuid;
              break;
            case PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_DEV_PHASES:
            case PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_DEV_PHASES:
              value = this.developmentPhasesChecktree.uuid;
              break;
            case PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_RECEPTORS:
            case PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_RECEPTORS:
              value = this.receptorsChecktree.uuid;
              break;
          }

          return {
            ...options,
            [option.type]: value,
          };
        }, {} as PresentationsSidebarOptionSelection)
      : ({} as PresentationsSidebarOptionSelection);
  }

  // @Mutation
  // setTabs(value: Array<any>) {
  //   this.tabs = value;
  // }

  @Mutation
  setStudyUuid(value: string) {
    this.studyUuid = value;
  }

  @Mutation
  setModuleUuid(value: string) {
    this.moduleUuid = value;
  }

  @Mutation
  setMeansOfPresentation(value: Array<any>) {
    this.meansOfPresentation = value;
  }

  @Mutation
  setTypesOfMeans(value: { [key: string]: any[] }) {
    this.typesOfMeans = value;
  }

  @Mutation
  setPresentationTitle(value: string) {
    this.presentationTitle = value;
  }

  @Mutation
  setActiveTab(value: PresentationsTabType) {
    this.activeTab = value;
  }

  @Mutation
  setActiveTableType(value: PresentationsTableType) {
    this.activeTableType = value;
  }

  @Mutation
  setActiveTableTitle(value: string) {
    this.activeTableTitle = value;
  }

  @Mutation
  setActiveChartType(value: PresentationsChartsType) {
    this.activeChartType = value;
  }

  @Mutation
  setActiveChartTitle(value: string) {
    this.activeChartTitle = value;
  }

  @Mutation
  setActiveStatsType(value: PresentationsStatsType) {
    this.activeStatsType = value;
  }

  @Mutation
  setActiveStatsTitle(value: string) {
    this.activeStatsTitle = value;
  }

  @Mutation
  setModuleChecktrees(value: ModuleChecktree[]) {
    this.moduleChecktrees = value;
  }

  @Mutation
  setSidebarChecktrees(value: CheckTree[]) {
    this.sidebarChecktrees = value;
  }

  @Mutation
  setScreeningTableSelection(value: ScreeningTableSelection) {
    this.screeningTableSelection = value;
  }

  @Mutation
  setScopingTableSelection(value: ScopingTableSelection) {
    this.scopingTableSelection = value;
  }

  @Mutation
  setAssessingTableSelection(value: AssessingTableSelection) {
    this.assessingTableSelection = value;
  }

  @Mutation
  setStatisticsSelectionData(value: StatisticsData) {
    this.statisticsSelectionData = value;
  }

  @Mutation
  setImpactsSelection(value: SingleImpact[]) {
    this.impactsSelection = value;
  }

  @Mutation
  clearSidebarSelectionMutation() {
    for (const checktree of this.sidebarChecktrees) {
      util.walkChecktree(checktree, null, (el) => (el.checked = false));
    }
  }

  @Mutation
  addSidebarChecktree(value: CheckTree) {
    if (!this.sidebarChecktrees) {
      this.sidebarChecktrees = [];
    }
    this.sidebarChecktrees.push(value);
  }

  @Mutation
  updateOptionValueMutation(option: PresentationsSidebarOption) {
    const visibleOptions =
      this.activeTab === 'tables'
        ? this.sidebarOptionsData[this.activeTab][this.activeTableType]
        : this.sidebarOptionsData[this.activeTab];
    visibleOptions.find((el) => el.id === option.id).value = option.value;
    const foundOption = visibleOptions.find((el) => el.id === option.id);
    foundOption.value = option.value;

    // disabling same value for statistics row/column
    if (option.type === PresentationsSidebarOptionGroupEnum.STATS_ROW) {
      const statsColumnGroup = visibleOptions.find(
        (el) => el.type === PresentationsSidebarOptionGroupEnum.STATS_COLUMN,
      );
      if (!option.value) {
        statsColumnGroup.value = null;
        statsColumnGroup.items.forEach((el) => (el.disabled = true));
        visibleOptions
          .find((el) => el.type === PresentationsSidebarOptionGroupEnum.STATS_ROW)
          .items.forEach((el) => (el.disabled = false));
      } else {
        statsColumnGroup.items.forEach((el) => (el.disabled = el.value === option.value));
      }
    } else if (option.type === PresentationsSidebarOptionGroupEnum.STATS_COLUMN) {
      visibleOptions
        .find((el) => el.type === PresentationsSidebarOptionGroupEnum.STATS_ROW)
        .items.forEach((el) => (el.disabled = el.value === option.value));
    }

    // Disable same values for charts (grouped/stacked for).
    else if (option.type === PresentationsSidebarOptionGroupEnum.CHARTS_GROUPED_FOR) {
      const chartsStackedForGroup = visibleOptions.find(
        (el) => el.type === PresentationsSidebarOptionGroupEnum.CHARTS_STACKED_FOR,
      );
      switch (option.value) {
        case PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_RECEPTORS:
          if (chartsStackedForGroup.value === PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_RECEPTORS)
            Vue.set(chartsStackedForGroup, 'value', PresentationsSidebarOptionItemEnum.NONE);
          chartsStackedForGroup.items.forEach((item) => {
            if (item.value === PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_RECEPTORS)
              Vue.set(item, 'disabled', true);
            else Vue.set(item, 'disabled', false);
          });
          break;
        case PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_DEV_PHASES:
          if (chartsStackedForGroup.value === PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_DEV_PHASES)
            Vue.set(chartsStackedForGroup, 'value', PresentationsSidebarOptionItemEnum.NONE);
          chartsStackedForGroup.items.forEach((item) => {
            if (item.value === PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_DEV_PHASES)
              Vue.set(item, 'disabled', true);
            else Vue.set(item, 'disabled', false);
          });
          break;
        case PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_ALTERNATIVES:
          if (chartsStackedForGroup.value === PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_ALTERNATIVES)
            Vue.set(chartsStackedForGroup, 'value', PresentationsSidebarOptionItemEnum.NONE);
          chartsStackedForGroup.items.forEach((item) => {
            if (item.value === PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_ALTERNATIVES)
              Vue.set(item, 'disabled', true);
            else Vue.set(item, 'disabled', false);
          });
          break;
        case PresentationsSidebarOptionItemEnum.NONE:
          chartsStackedForGroup.items.forEach((item) => item.disabled && Vue.set(item, 'disabled', false));
          break;
      }
    } else if (option.type === PresentationsSidebarOptionGroupEnum.CHARTS_STACKED_FOR) {
      const chartsGroupedForGroup = visibleOptions.find(
        (el) => el.type === PresentationsSidebarOptionGroupEnum.CHARTS_GROUPED_FOR,
      );
      switch (option.value) {
        case PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_RECEPTORS:
          if (chartsGroupedForGroup.value === PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_RECEPTORS)
            Vue.set(chartsGroupedForGroup, 'value', PresentationsSidebarOptionItemEnum.NONE);
          chartsGroupedForGroup.items.forEach((item) => {
            if (item.value === PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_RECEPTORS)
              Vue.set(item, 'disabled', true);
            else Vue.set(item, 'disabled', false);
          });
          break;
        case PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_DEV_PHASES:
          if (chartsGroupedForGroup.value === PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_DEV_PHASES)
            Vue.set(chartsGroupedForGroup, 'value', PresentationsSidebarOptionItemEnum.NONE);
          chartsGroupedForGroup.items.forEach((item) => {
            if (item.value === PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_DEV_PHASES)
              Vue.set(item, 'disabled', true);
            else Vue.set(item, 'disabled', false);
          });
          break;
        case PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_ALTERNATIVES:
          if (chartsGroupedForGroup.value === PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_ALTERNATIVES)
            Vue.set(chartsGroupedForGroup, 'value', PresentationsSidebarOptionItemEnum.NONE);
          chartsGroupedForGroup.items.forEach((item) => {
            if (item.value === PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_ALTERNATIVES)
              Vue.set(item, 'disabled', true);
            else Vue.set(item, 'disabled', false);
          });
          break;
        case PresentationsSidebarOptionItemEnum.NONE:
          chartsGroupedForGroup.items.forEach((item) => item.disabled && Vue.set(item, 'disabled', false));
          break;
      }
    }

    //TODO: disabling half size
    // if (option.type === PresentationsSidebarOptionGroupEnum.TABLE_LEVEL_OF_DETAIL) {
    //   visibleOptions
    //     .find((el) => el.type === PresentationsSidebarOptionGroupEnum.TABLE_SIZE)
    //     .items.find((el) => el.value === PresentationsSidebarOptionItemEnum.TABLE_SIZE_HALF).disabled =
    //     option.value !== PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_ONLY_LIST;
    // }
    // console.log(visibleOptions);
  }

  @Mutation
  toggleSidebarSelectionItemCheckMutation(payload: ToggleSidebarSelectionItemCheckPayload) {
    const item = util.findChecktreeItem(
      this.sidebarChecktrees.find((el) => el.uuid === payload.checktreeUuid),
      null,
      payload.checktreeItemUuid,
    );
    item.checked = payload.checked;
  }

  @Mutation
  updateChecktreeMutation(payload: { title: string; checktree: CheckTree }) {
    const checktree = this.sidebarChecktrees.find((el) => el.uuid === payload.checktree.uuid);
    checktree.children = payload.checktree.children;
  }

  @Mutation
  toggleSingleImpactCheckMutation(payload: ToggleSingleImpactCheckPayload) {
    const singleImpact = this.impactsSelection.find((el) => el.uuid === payload.impact.uuid);
    singleImpact.library_id = payload.checked ? 999 : null;
  }

  @Mutation
  updateSidebarOptions(selectedOptions: PresentationsSidebarOption[]) {
    if (this.activeTab === 'tables') {
      const clonedTablesOptions = cloneDeep(this.sidebarOptionsData.tables);
      clonedTablesOptions[this.activeTableType] = selectedOptions;
      Vue.set(this.sidebarOptionsData, 'tables', clonedTablesOptions);
    } else {
      Vue.set(this.sidebarOptionsData, this.activeTab, selectedOptions);
    }
  }

  @Action({ rawError: true })
  async changeTab(payload: { studyUuid: string; moduleUuid: string; tab: PresentationsTabType }) {
    try {
      this.context.commit('setScreeningTableSelection', null);
      this.context.commit('setScopingTableSelection', null);
      this.context.commit('setAssessingTableSelection', null);
      this.context.commit('setStatisticsSelectionData', null);
      this.context.commit('setActiveTableType', null);
      this.context.commit('setActiveTableTitle', '');
      this.context.commit('setActiveChartType', null);
      this.context.commit('setActiveChartTitle', '');
      this.context.commit('setActiveStatsType', null);
      this.context.commit('setActiveStatsTitle', '');

      LoaderService.disableHttpLoader();
      LoaderService.showLoader();

      const moduleChecktrees = await api.module.getModuleFilters(payload.studyUuid, payload.moduleUuid, payload.tab);

      this.context.commit('setModuleChecktrees', moduleChecktrees);
      this.context.commit('setSidebarChecktrees', moduleChecktrees);

      if (payload.tab === 'impacts') {
        const res: SingleImpact[] = await api.presentations.getImpactsSelection(
          [...this.sidebarChecktrees],
          store.getters['ModuleProperties/moduleProperties'],
        );
        // TODO: dev only
        res.forEach((el) => {
          el.checked = false;
        });
        this.context.commit('setImpactsSelection', res);
      }

      this.context.commit('setActiveTab', payload.tab);
      this.context.commit('setStudyUuid', payload.studyUuid);
      this.context.commit('setModuleUuid', payload.moduleUuid);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.hideLoader();
      LoaderService.enableHttpLoader();
    }
  }

  @Action({ rawError: true })
  async changeTableType(value: PresentationsTableType) {
    if (value === 'screening') {
      const selectionRes: any = await api.presentations.getTableData({
        checktreeSelection: this.checktreeSelection,
        tableType: value,
        options: this.options,
        module: store.getters['ModuleProperties/moduleProperties'],
      });
      util.enumerateChecktree(selectionRes.checktree, null);
      this.context.commit('setScreeningTableSelection', {
        title: selectionRes.title,
        checktree: selectionRes.checktree,
      });
    } else if (value === 'scoping') {
      const selectionRes: any = await api.presentations.getTableData({
        checktreeSelection: this.checktreeSelection,
        tableType: value,
        options: this.options,
        module: store.getters['ModuleProperties/moduleProperties'],
        unitListID: store.getters['ModuleProperties/moduleProperties'].unitlist_id,
      });
      this.context.commit('setScopingTableSelection', {
        title: selectionRes.title,
        unitList: selectionRes.unitlist,
      });
    } else if (value === 'assessing') {
      const selectionRes = await api.presentations.getTableData({
        checktreeSelection: this.checktreeSelection,
        tableType: value,
        options: this.options,
        module: store.getters['ModuleProperties/moduleProperties'],
      });
      this.context.commit('setAssessingTableSelection', selectionRes);
    }
    this.context.commit('setActiveTableType', value);
  }

  @Action({ rawError: true })
  async initializePresentation(payload: { studyUuid: string; moduleUuid: string }) {
    this.context.commit('setActiveTab', 'tables');

    try {
      LoaderService.disableHttpLoader();
      LoaderService.hideLoader();

      // Load Module.
      const mod = await api.workflow.getModuleByUuid(payload.moduleUuid);
      this.context.commit('setMeansOfPresentation', Object.values(JSON.parse((mod as any).means_of_presentation)));
      this.context.commit(
        'setTypesOfMeans',
        Object.entries(JSON.parse((mod as any).types_of_means))
          .sort(([, a], [, b]) => Number(a) - Number(b))
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {}),
      );
      this.context.commit('setPresentationTitle', (mod as any).name);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  // @Action({ rawError: true })
  // async initializeActiveTabs(payload: { studyUuid: string; moduleUuid: string }) {
  //   try {
  //     this.meansOfPresentation.forEach((item) => {
  //       this.context.commit('setTabs', []);
  //       this.tabs.push({ key: item, title: item, icon: item });
  //     });
  //   } catch (error) {
  //     console.warn(error);
  //   } finally {
  //     LoaderService.enableHttpLoader();
  //   }
  // }

  @Action({ rawError: true })
  async initializeSidebarFilters(payload: { studyUuid: string; moduleUuid: string }) {
    try {
      LoaderService.disableHttpLoader();
      LoaderService.hideLoader();

      // Sidebar Checktrees.
      const moduleChecktrees = await api.module.getModuleFilters(payload.studyUuid, payload.moduleUuid, this.activeTab);

      this.context.commit('setModuleChecktrees', moduleChecktrees);
      this.context.commit('setSidebarChecktrees', moduleChecktrees);
      this.context.commit('setStudyUuid', payload.studyUuid);
      this.context.commit('setModuleUuid', payload.moduleUuid);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  @Action({ rawError: true })
  async getStatisticsSelection(statsType: PresentationsStatsType) {
    if (!statsType) {
      this.context.commit('setStatisticsSelectionData', null);
      this.context.commit('setActiveStatsType', null);
      return;
    }

    const rowOption = this.sidebarOptions.find((el) => el.type === PresentationsSidebarOptionGroupEnum.STATS_ROW);
    const columnOption = this.sidebarOptions.find((el) => el.type === PresentationsSidebarOptionGroupEnum.STATS_COLUMN);
    if (!rowOption || !columnOption) {
      return;
    }

    const res = await api.presentations.getStatsSelection(
      this.sidebarChecktrees,
      statsType,
      rowOption.value,
      columnOption.value,
      store.getters['ModuleProperties/moduleProperties'],
    );
    this.context.commit('setStatisticsSelectionData', res);
    this.context.commit('setActiveStatsType', statsType);
  }

  @Action({ rawError: true })
  updateOptionValue(payload: PresentationsSidebarOption) {
    this.context.commit('updateOptionValueMutation', payload);
    eventBus.$emit(EventType.PRESENTATIONS_SIDEBAR_OPTIONS_EVENT, payload);
  }

  @Action({ rawError: true })
  async toggleSidebarSelectionItemCheck(payload: ToggleSidebarSelectionItemCheckPayload) {
    this.context.commit('toggleSidebarSelectionItemCheckMutation', payload);
    const selectedChecktreesCount = this.sidebarChecktrees
      .map((checktree) => checktree.children.some((el) => el.checked))
      .filter((el) => el === true).length;
    const checktreesCount = this.sidebarChecktrees.length;
    if (this.activeTableType === 'screening') {
      if (selectedChecktreesCount !== 1) {
        this.context.commit('setActiveTableType', null);
        return;
      }
      const selectionRes: any = await api.presentations.getTableData({
        checktreeSelection: this.checktreeSelection,
        tableType: this.activeTableType,
        options: this.options,
        module: store.getters['ModuleProperties/moduleProperties'],
      });
      util.enumerateChecktree(selectionRes.checktree, null);
      this.context.commit('setScreeningTableSelection', {
        title: selectionRes.title,
        checktree: selectionRes.checktree,
      });
    } else if (this.activeTableType === 'scoping') {
      if (selectedChecktreesCount !== checktreesCount) {
        this.context.commit('setActiveTableType', null);
        return;
      }
      const selectionRes: any = await api.presentations.getTableData({
        checktreeSelection: this.checktreeSelection,
        tableType: this.activeTableType,
        options: this.options,
        module: store.getters['ModuleProperties/moduleProperties'],
        unitListID: store.getters['ModuleProperties/moduleProperties'].unitlist_id,
      });
      this.context.commit('setScopingTableSelection', {
        title: selectionRes.title,
        unitList: selectionRes.unitlist,
      });
    } else if (this.activeTableType === 'assessing') {
      if (selectedChecktreesCount !== checktreesCount) {
        this.context.commit('setActiveTableType', null);
        return;
      }
      const selectionRes = await api.presentations.getTableData({
        checktreeSelection: this.checktreeSelection,
        tableType: this.activeTableType,
        options: this.options,
        module: store.getters['ModuleProperties/moduleProperties'],
      });
      this.context.commit('setAssessingTableSelection', selectionRes);
    }
  }

  @Action({ rawError: true })
  async toggleSingleImpactCheck(payload: ToggleSingleImpactCheckPayload) {
    if (payload.checked) {
      await api.presentations.addImpactToLibrary(payload.impact.uuid);
    } else {
      await api.presentations.deleteImpactFromLibrary(payload.impact.uuid);
    }
    this.context.commit('toggleSingleImpactCheckMutation', payload);
    eventBus.$emit(EventType.LIBRARY_UPDATE);
  }

  @Action({ rawError: true })
  async toggleAllSingleImpactCheck(shouldCheck: boolean) {
    const promises = [];
    for (const impact of this.impactsSelection) {
      if (shouldCheck) {
        promises.push(api.presentations.addImpactToLibrary(impact.uuid));
      } else {
        promises.push(api.presentations.deleteImpactFromLibrary(impact.uuid));
      }
    }
    await Promise.all(promises);
    for (const impact of this.impactsSelection) {
      this.context.commit('toggleSingleImpactCheckMutation', { impact: impact, checked: shouldCheck });
    }
    eventBus.$emit(EventType.LIBRARY_UPDATE);
  }

  @Action({ rawError: true })
  async addLibraryParagraph(payload: LibraryParagraph) {
    payload.options = {
      selectedOptions: [...this.sidebarOptions],
      activeTab: this.activeTab,
      activeTabType:
        this.activeTab === 'tables'
          ? this.activeTableType
          : this.activeTab === 'charts'
          ? this.activeChartType
          : this.activeTab === 'statistics'
          ? this.activeStatsType
          : null,
    };
    await api.presentations.addLibraryParagraph(payload);
    eventBus.$emit(EventType.LIBRARY_UPDATE);
  }

  @Action({ rawError: true })
  async addLibraryParagraphRaw(payload: LibraryParagraph) {
    await api.presentations.addLibraryParagraph(payload);
    eventBus.$emit(EventType.LIBRARY_UPDATE);
  }

  @Action({ rawError: true })
  async clearTableSelection() {
    this.context.commit('clearSidebarSelectionMutation');
    this.context.commit('setActiveTableType', null);
    this.context.commit('setScreeningTableSelection', null);
    this.context.commit('setScopingTableSelection', null);
    this.context.commit('setAssessingTableSelection', null);
  }

  @Action({ rawError: true })
  async setSidebarDataForEditParagraph(value: LibraryParagraph) {
    await this.context.dispatch('changeTab', {
      studyUuid: value.payload.module.study_uuid,
      moduleUuid: value.payload.module.module_uuid,
      tab: value.type === ChapterItemType.LIBRARY_ENVIGO_CHART ? 'charts' : value.payload.activeTab,
    });

    const clonedSidebarChecktrees = cloneDeep(this.sidebarChecktrees);
    if (value.payload?.checktreeSelection) {
      value.payload.checktreeSelection.forEach((checktree: CheckTree) => {
        const selectionChecktree = clonedSidebarChecktrees.find(
          (sidebarChecktree) => sidebarChecktree.uuid === checktree.uuid,
        );
        checktree.children.forEach((checktreeItem: CheckTreeItem) => {
          if (typeof selectionChecktree !== 'undefined') {
            const selectionChecktreeItem = selectionChecktree.children.find(
              (selectionChecktreeItem: CheckTreeItem) => selectionChecktreeItem.uuid === checktreeItem.uuid,
            );
            if (!selectionChecktreeItem) return;
            selectionChecktreeItem.checked = checktreeItem.checked;
          }
        });
      });
    }
    this.context.commit('setSidebarChecktrees', clonedSidebarChecktrees);

    if (this.activeTab === 'tables') {
      this.context.commit('setActiveTableType', value.payload?.tableType);
      this.context.commit('setActiveTableTitle', value.title);
      await this.context.dispatch('changeTableType', value.payload.tableType as PresentationsTableType);
    } else if (this.activeTab === 'charts') {
      this.context.commit('setActiveChartType', value.payload?.chartType);
      this.context.commit('setActiveChartTitle', value.title);
    } else if (this.activeTab === 'statistics') {
      this.context.commit('setActiveStatsTitle', value.title);
      await this.context.dispatch('getStatisticsSelection', value.payload.statsType as PresentationsStatsType);
    }

    if (value.payload?.options) {
      const clonedSidebarOptions = cloneDeep(this.sidebarOptions);

      Object.entries(value.payload.options).forEach(([type, value]) => {
        const sidebarOption = clonedSidebarOptions.find((sidebarOption) => sidebarOption.type === type);
        if (!sidebarOption) return;
        sidebarOption.value = value as PresentationsSidebarOptionItemEnum;
      });

      this.context.commit('updateSidebarOptions', clonedSidebarOptions);
    }
  }
}
