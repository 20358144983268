














































































import { Component, Mixins, Watch, Vue } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import FormFieldSelect from '@/app/shared/components/form/field/FormFieldSelect.vue';
import FormFieldText from '@/app/shared/components/form/field/FormFieldText.vue';
import FormFieldFocus from '@/app/shared/components/form/field/mixins/FormFieldFocus.vue';
import { ValidationRule } from '@/app/shared/models/form/FormSchema';
import { Facility } from '@/app/shared/models/form/field/FormFieldFacility';
import { FormFieldValue, FormFieldValueModel } from '@/app/shared/models/form/field';
import DialogService from '@/app/shared/utils/dialog.service';

@Component({
  components: {
    FormFieldSelect,
    FormFieldText,
  },
})
export default class FormFieldFacility extends Mixins(Vue, FormFieldFocus, BaseControlSkeleton) {
  value: FormFieldValue<Facility>;
  deleted: string[] = [];
  facilities: Facility[] = [new Facility()];
  control: {
    validations: ValidationRule[];
  };
  updateValue: (value: FormFieldValue<Facility>) => void;

  @Watch('value', { deep: true })
  onValueChanged(value: FormFieldValue<Facility>) {
    this.$set(this, 'facilities', value.data);
  }

  @Watch('facilities', { deep: true })
  onFacilitiesChange(value: Facility[]) {
    this.updateValue(new FormFieldValue(value, FormFieldValueModel.FACILITY, this.deleted));
  }

  get isRequiredObjectProjectName() {
    if (this.control.validations)
      return (
        this.control.validations.filter(
          (validation) =>
            validation.ruleType === 'customClosure' && validation.additionalValue === 'requiredObjectProjectName',
        ).length > 0
      );

    return false;
  }

  created() {
    if (this.value) this.$set(this, 'facilities', this.value.data);
  }

  addGroup(index: number) {
    this.facilities.splice(index + 1, 0, new Facility());
  }

  removeGroup(index: number) {
    const removeClosure = () => {
      const uuid = this.facilities[index].uuid;
      if (uuid) this.deleted.push(uuid);
      this.facilities.splice(index, 1);
    };

    // Skip showing confirmation dialog in test environment.
    if (process.env.NODE_ENV === 'test') {
      removeClosure();
      return;
    }

    DialogService.presentDialog(
      'Delete this item?',
      'You will not be able to recover it.',
      'Yes',
      'Cancel',
      removeClosure,
    );
  }

  focus() {
    const formField = this.$refs.formField as (Vue & { focus: () => void })[];
    if (formField[0]) formField[0].focus();
  }
}
