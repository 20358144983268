
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkspaceActiveButton } from '@/app/shared/types/workspace-active-button.type';
import { WorkspaceResponseItem } from '@/app/shared/models/workspace/WorkspaceResponseItem';

@Component
export default class WorkspaceLines extends Vue {
  @Prop() items: WorkspaceResponseItem[];
  @Prop() activeButton: WorkspaceActiveButton;
  @Prop() rerenderLines: boolean;
  lines = [
    {
      id: 'act-src',
      slug: '26-FUC-activities',
      image: 'Activities-Sources',
      x: 356,
      y: 351,
      show: () => true,
    },
    {
      id: 'asp-src',
      slug: '27-FUC-aspects',
      image: 'Aspects-Sources',
      x: 493,
      y: 351,
      show: () => true,
    },
    {
      id: 'src-ii',
      slug: '29-CCM-sources-causes',
      image: 'Causes-Impact identification',
      x: 359,
      y: 455,
      show: () => true,
    },
    {
      id: 'rec-ii',
      slug: '28-AUC-receptors',
      image: 'Receptors-Impact identification',
      x: 222,
      y: 455,
      show: () => true,
    },
    {
      id: 'ess-ddess',
      slug: '100-AUC-ecosystem-services',
      image: 'Ecosystem services (ESS-Development dependencies on ESS',
      x: 758,
      y: 455,
      show: () => true,
    },
    {
      id: 'ess-iess',
      slug: '100-AUC-ecosystem-services',
      image: 'Ecosystem services (ESS)-Impacts to ESS',
      x: 627,
      y: 455,
      show: () => true,
    },
    {
      id: 'src-iess',
      slug: '29-CCM-sources-causes',
      image: 'Causes-Impact to ESS',
      x: 490,
      y: 455,
      show: () => true,
    },
    {
      id: 'alt-altsc',
      slug: '32-AUC-alternatives',
      image: 'Alternatives-Alternatives scoping',
      x: 222,
      y: 559,
      show: () => true,
    },
    {
      id: 'sh-she',
      slug: 'stakeholders',
      image: 'Stakeholders-Stakeholder engagement',
      x: 1006,
      y: 559,
      show: () => true,
    },
    {
      id: 'ii-iinter',
      slug: '31-CCM-impact-identification',
      image: 'Impact identification-Impact interaction',
      x: 356,
      y: 711,
      show: () => true,
    },
    {
      id: 'iess-iinter',
      slug: '101-CCM-impacts-to-ess',
      image: 'Impacts to ESS-Impact interaction',
      x: 344,
      y: 711,
      show: () => true,
    },
    {
      id: 'ii-id',
      slug: '31-CCM-impact-identification',
      image: 'Impact identification-Impact definition',
      x: 368,
      y: 711,
      show: () => true,
    },
    {
      id: 'iess-id',
      slug: '101-CCM-impacts-to-ess',
      image: 'Impacs to ESS-Impact definition',
      x: 624,
      y: 711,
      show: () => true,
    },
    {
      id: 'iinter-ic',
      slug: 'impacts-interaction',
      image: 'Impact interaction-Impact characterisation',
      x: 356,
      y: 815,
      show: () => true,
    },
    {
      id: 'id-ic',
      slug: '34-CCM-impact-definition',
      image: 'Impact definition-Impact characterisation',
      x: 493,
      y: 815,
      show: () => true,
    },
    {
      id: 'dp-ic',
      slug: '30-AUC-development phases',
      image: 'Development phases-Impact characterisation',
      x: 490,
      y: 559,
      show: () => true,
    },
    {
      id: 'altsc-ic',
      slug: '107-TUM-alternatives-scoping',
      image: 'Alternatives scoping-Impact characterisation',
      x: 336,
      y: 887,
      show: () => true,
    },
    {
      id: 'ic-routine',
      slug: '39-TUM-impact characterization',
      image: 'Impact characterisation-Routine',
      x: 225,
      y: 919,
      show: () => this.activeButton === 'routine',
    },
    {
      id: 'ic-accident',
      slug: '39-TUM-impact characterization',
      image: 'Impact characterisation - Accident',
      x: 490,
      y: 919,
      show: () => this.activeButton === 'accident',
    },
    {
      id: 'ic-cumulative',
      slug: '39-TUM-impact characterization',
      image: 'Impact characterisation - Cumulative',
      x: 490,
      y: 919,
      show: () => this.activeButton === 'cumulative',
    },
    {
      id: 'ic-ecosystem',
      slug: '39-TUM-impact characterization',
      image: 'Impact characterisation - Ecosystem Services',
      x: 490,
      y: 919,
      show: () => this.activeButton === 'ecosystem-services',
    },
    {
      id: 'vecs-cumulative',
      slug: '109-AUC-valuable-es-comp-vec',
      image: 'VECs-Cumulative',
      x: 758,
      y: 919,
      show: () => this.activeButton === 'cumulative',
    },
    {
      id: 'routine-imass',
      slug: '39-TUM-impact characterization',
      image: 'Routine - Impact assessment',
      x: 225,
      y: 1011,
      show: () => this.activeButton === 'routine',
    },
    {
      id: 'accident-imass',
      slug: '39-TUM-impact characterization',
      image: 'Accident - Impact assessment',
      x: 490,
      y: 1011,
      show: () => this.activeButton === 'accident',
    },
    {
      id: 'cumulative-imvecs',
      slug: '39-TUM-impact characterization',
      image: 'Cumulative-Impacts to VECs',
      x: 624,
      y: 1011,
      show: () => this.activeButton === 'cumulative',
    },
    {
      id: 'imvecs-cima',
      slug: '116-TUM-impacts-to-vecs',
      image: 'Impacts to VECs-Cumulative impact assessment',
      x: 490,
      y: 1127,
      show: () => this.activeButton == 'cumulative',
    },
    {
      id: 'cima-mm',
      slug: '117-AUP-cumulative-impact-assessment',
      image: 'Cumulative impact assessment-Mitigation measures',
      x: 604,
      y: 1199,
      show: () => this.activeButton == 'cumulative',
    },
    {
      id: 'cima-mp',
      slug: '117-AUP-cumulative-impact-assessment',
      image: 'Cumulative impact assessment-Monitoring plan',
      x: 490,
      y: 1231,
      show: () => this.activeButton === 'cumulative',
    },
    {
      id: 'ecosystem-imass',
      slug: '39-TUM-impact characterization',
      image: 'Ecosystem Services - Impact assessment',
      x: 490,
      y: 1011,
      show: () => this.activeButton === 'ecosystem-services',
    },
    {
      id: 'imass-mm',
      slug: '47-AUP-assessment-routine-impacts',
      image: 'Impact assessment-Mitigation measures',
      x: 604,
      y: 1095,
      show: () => this.activeButton === 'routine' || this.activeButton === 'accident',
    },
    {
      id: 'essimass-mm',
      slug: '112-AUP-ess-impact-assessment',
      image: 'Impact assessment-Mitigation measures',
      x: 604,
      y: 1095,
      show: () => this.activeButton === 'ecosystem-services',
    },
    {
      id: 'imass-altass',
      slug: '47-AUP-assessment-routine-impacts',
      image: 'Impact assessment-Alternative assessment',
      x: 222,
      y: 1127,
      show: () => this.activeButton === 'routine' || this.activeButton === 'accident',
    },
    {
      id: 'essimass-altass',
      slug: '112-AUP-ess-impact-assessment',
      image: 'Impact assessment-Alternative assessment',
      x: 222,
      y: 1127,
      show: () => this.activeButton === 'ecosystem-services',
    },
    {
      id: 'imass-mp',
      slug: '47-AUP-assessment-routine-impacts',
      image: 'Impact assessment-Monitoring plan',
      x: 490,
      y: 1127,
      show: () => this.activeButton === 'routine' || this.activeButton === 'accident',
    },
    {
      id: 'essimass-mp',
      slug: '112-AUP-ess-impact-assessment',
      image: 'Impact assessment-Monitoring plan',
      x: 490,
      y: 1127,
      show: () => this.activeButton === 'ecosystem-services',
    },
  ];

  get activeLines() {
    return this.items.reduce(
      (activeItems, item) => ({
        ...activeItems,
        [item.slug]: item.state && item.state.some((state: any) => state.resumed),
      }),
      {},
    );
  }
}
