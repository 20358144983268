





































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PageFooter from '@/app/shared/components/PageFooter.vue';
import PresentationsSidebar from './components/PresentationsSidebar.vue';
import Library from '@/app/shared/components/Library.vue';
import PresentationsPageHeader from './components/PresentationsPageHeader.vue';
import PresentationsTables from './components/tables/PresentationsTables.vue';
import PresentationsImpacts from './components/impacts/PresentationsImpacts.vue';
import PresentationsCharts from './components/charts/PresentationsCharts.vue';
import PresentationsStatistics from './components/statistics/PresentationsStatistics.vue';
import LoaderService from '@/app/shared/utils/loader.service';
import UtilService from '../shared/utils/util.service';
import { ModuleProperties } from '@/app/shared/models/Module';
import { PresentationsTabType } from '@/app/shared/types/presentations.types';
import { UserPermission } from '../shared/enums/user-permission.enum';
import type LibraryItemType from '@/app/shared/models/library/LibraryItem';
import api from '@/app/shared/api';

const PresentationsStore = namespace('Presentations');
const ModulePropertiesStore = namespace('ModuleProperties');
const UiStore = namespace('UI');
const UserStore = namespace('User');

@Component({
  components: {
    PageFooter,
    Library,
    PresentationsSidebar,
    PresentationsPageHeader,
    PresentationsTables,
    PresentationsImpacts,
    PresentationsCharts,
    PresentationsStatistics,
  },
})
export default class Presentations extends Vue {
  @Prop() studyUuid: string;
  @Prop() moduleUuid: string;
  isLoaded: boolean = false;
  tabs: Array<any> = [];
  defaultLibraryWidth: number = 0;

  // @PresentationsStore.State
  // tabs: Array<any>;

  @PresentationsStore.State
  meansOfPresentation: Array<any>;

  @PresentationsStore.State
  presentationTitle: string;

  @PresentationsStore.State
  activeTab: PresentationsTabType;

  @UiStore.Getter
  isFooterLibraryActive: boolean;

  @PresentationsStore.Action
  changeTab: (payload: { studyUuid: string; moduleUuid: string; tab: PresentationsTabType }) => Promise<void>;

  @PresentationsStore.Action
  initializePresentation: (payload: { studyUuid: string; moduleUuid: string }) => Promise<void>;

  // @PresentationsStore.Action
  // initializeActiveTabs: () => Promise<void>;

  @PresentationsStore.Action
  initializeSidebarFilters: (payload: { studyUuid: string; moduleUuid: string }) => Promise<void>;

  @PresentationsStore.Action
  setSidebarDataForEditParagraph: (item: LibraryItemType) => Promise<any>;

  @ModulePropertiesStore.Getter
  moduleProperties: ModuleProperties;

  @UiStore.Mutation
  setFooterLibraryActive!: (value: boolean) => void;

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  get pageTitle() {
    return this.moduleProperties ? this.moduleProperties?.name || this.presentationTitle || '' : '';
  }

  async created() {
    this.initializeData();
    if (await this.isAllowed(UserPermission.LIBRARY_BAR_SHOW)) {
      this.defaultLibraryWidth = UtilService.calculateSidebarWidth();
      this.setFooterLibraryActive(true);
    }
  }

  async initializeData() {
    try {
      LoaderService.disableHttpLoader();

      const payload = {
        studyUuid: this.studyUuid,
        moduleUuid: this.moduleUuid,
      };

      await this.initializeSidebarFilters(payload);
      await this.initializePresentation(payload);
      this.initializeActiveTabs();

      if (this.$route.query.nodeUuid) {
        await api.library.getLibraryItem(String(this.$route.query.nodeUuid)).then((result) => {
          this.setSidebarDataForEditParagraph(result);
        });
      }
      this.isLoaded = true;
    }
    catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  initializeActiveTabs() {
    this.meansOfPresentation.forEach((item) => {
      this.tabs.push({ key: item, title: item, icon: item });
    });
  }
}
