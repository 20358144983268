






















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class UserAvatar extends Vue {
  @Prop() dataUri: string;
  @Prop() image: string;
  @Prop() name: string;
  @Prop({ default: 'medium' }) size: 'enormous' | 'huge' | 'large' | 'medium' | 'small';
  @Prop({ default: false }) isDisabled: boolean;
  @Prop() avatarClass: string;
  @Prop() hasAvatarInitials: boolean;

  @Emit('click')
  click(_event: MouseEvent) {
    //
  }

  get avatarSize() {
    switch (this.size) {
      case 'enormous':
        return 120;
      case 'huge':
        return 72;
      case 'large':
        return 48;
      case 'small':
        return 24;
      case 'medium':
      default:
        return 32;
    }
  }
}
