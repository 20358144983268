var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tableData)?_c('div',{staticClass:"table-wrapper",style:(Object.assign({}, _vm.style.tableWrapper, {width: _vm.sizeOption === 'half' ? '50%' : '100%'}))},[_c('div',{staticClass:"top-border",style:(_vm.style.topBorder)}),_c('div',{ref:"tableRef",staticClass:"table",style:(_vm.style.table)},[(_vm.type === 'row_column')?_c('div',{staticClass:"table-header",style:(Object.assign({}, _vm.style.tableRow, {background: '#f8fafa', borderBottom: '1px solid #e5eaec'}))},[_vm._l((_vm.tableData[0]),function(col,index){return _c('div',{key:index,staticClass:"table-header-cell",style:(Object.assign({}, _vm.style.tableColumn,
          {borderLeft: 'none',
          display: index > 0 ? 'none' : 'flex',
          padding: 0}))})}),_c('div',{staticClass:"table-header-cell",style:(Object.assign({}, _vm.style.tableColumn,
          {borderLeft: '1px solid #e5eaec',
          flex: _vm.tableData[0] ? _vm.tableData[0].length - 1 : 1,
          padding: 0,
          justifyContent: 'center'}))},[_c('span',{style:(_vm.style.tableColumnTitleSpan)},[_vm._v(" "+_vm._s(_vm.tableHeader)+" ")])])],2):_vm._e(),_vm._l((_vm.tableData),function(row,rowIndex){return _c('div',{key:rowIndex,staticClass:"table-row",style:(Object.assign({}, _vm.style.tableRow,
        {borderWidth: rowIndex === 0 ? '0px' : '1px',
        borderColor: rowIndex === 1 ? '#e5eaec' : '#D3DADC',
        background: rowIndex === 0 ? '#f8fafa' : 'white'}))},_vm._l((row),function(col,colIndex){return _c('div',{key:colIndex,staticClass:"table-column",style:(Object.assign({}, _vm.style.tableColumn,
          {borderWidth: colIndex === 0 ? '0px' : '1px',
          borderColor: rowIndex === 0 ? '#e5eaec' : '#D3DADC',
          justifyContent: rowIndex === 0 || colIndex === 0 ? 'flex-start' : 'center'}))},[((_vm.type === 'row_column' && rowIndex === 0) || (_vm.type === 'row' && rowIndex === 0 && colIndex === 0))?_c('v-icon',{staticStyle:{"margin-left":"0.5rem","margin-right":"0.25rem"}},[_vm._v("$receptors-24")]):_vm._e(),_c('span',{style:(Object.assign({}, (rowIndex > 0
              ? _vm.style.tableColumnValueSpan
              : _vm.type === 'row_column'
              ? _vm.style.tableColumnLabelSpan
              : colIndex === 0
              ? _vm.style.tableColumnLabelSpan
              : _vm.style.tableColumnTitleSpan)))},[_vm._v(_vm._s(col))])],1)}),0)})],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }