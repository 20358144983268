


































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import UserAvatar from '@/app/shared/components/UserAvatar.vue';
import {
  TrackingResponseItem,
  TrackingHistory as TrackingHistoryType,
  TrackingActivityType,
} from '@/app/shared/models/Tracking';
import { User } from '@/app/shared/models/User';

dayjs.extend(utc);

const TrackingStore = namespace('Tracking');
const ReportsStore = namespace('Reports');
const UserStore = namespace('User');

@Component({
  components: {
    UserAvatar,
  },
})
export default class TrackingHistory extends Vue {
  @Prop() trackingHistory: TrackingHistoryType[];
  dateFormat = 'YYYY-MM-DD';
  timeFormat = 'HH:mm';

  @UserStore.Getter
  userUuid: string;

  @UserStore.Getter
  userModel: User;

  @TrackingStore.State
  trackingItems: TrackingResponseItem[];

  @TrackingStore.Getter
  hasExpandedTrackingItem: boolean;

  @ReportsStore.Getter
  navigatorCollapsed: boolean;

  @Emit()
  click(_trackingHistory: TrackingHistoryType) {
    //
  }

  getTimeLabel(timestamp: string) {
    return dayjs
      .utc(timestamp)
      .local()
      .format(this.timeFormat);
  }

  getTypeLabel(type: TrackingActivityType) {
    switch (type) {
      case 'add':
        return 'Added';
      case 'assess':
        return 'Assessed';
      case 'check':
        return 'Checked';
      case 'edit':
        return 'Edited';
      case 'reorder':
        return 'Reordered';
      case 'resume':
        return 'Resumed';
    }
  }

  getUserName(user: User) {
    return user.uuid === this.userUuid ? 'You' : `${user.first_name} ${user.last_name}`;
  }

  jumpToObjectItem(history: TrackingHistoryType) {
    const trackingItemElement = document.querySelector(
      `#${CSS.escape(`content/${history.object_item_uuid}`)}`,
    ) as HTMLDivElement;

    if (!trackingItemElement || trackingItemElement.offsetTop === 0) return;

    const yOffset = 20;
    const y = trackingItemElement.offsetTop + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}
