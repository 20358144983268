


























import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseSwitch extends Vue {
  @Prop() private value!: string;
  @Model('update') checked: boolean;
  @Prop() disabled: boolean;
  @Prop({ default: false, type: Boolean }) isDark: boolean;

  @Emit()
  update(_value: boolean) {
    // just emiting event
  }

  @Emit()
  change(value: boolean) {
    this.update(value);
  }
}
