















































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ChapterItem, TEMPORARY_UUID_PREFIX } from '@/app/shared/models/reports/ChapterItem';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import HeadingChapterItem from './HeadingChapterItem.vue';
import SingleImpactHeaderChapterItem from './SingleImpactHeaderChapterItem.vue';
import TextChapterItem from './TextChapterItem.vue';
import SpreadsheetChapterItem from './SpreadsheetChapterItem.vue';
import ExcelTableChapterItem from './ExcelTableChapterItem.vue';
import ChartChapterItem from './ChartChapterItem.vue';
import LibraryChapterItem from './LibraryChapterItem.vue';
import ImageChapterItem from './ImageChapterItem.vue';
import AddParagraphButton from './AddParagraphButton.vue';
import { namespace } from 'vuex-class';
import { NewChapterItemPayload, UpdateChapterItemPayload } from '@/app/shared/store/modules/reports.store-module';
import { ChapterItemMode } from '@/app/shared/types/chapter-item-mode.type';
import { User } from '@/app/shared/models/User';
import DialogService from '@/app/shared/utils/dialog.service';
import { Chapter } from '@/app/shared/models/reports/Chapter';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

const CommentsStore = namespace('Comments');
const ReportsStore = namespace('Reports');
const UserStore = namespace('User');
const UiModuleStore = namespace('UI');

@Component({
  name: 'ChapterItemComponent',
  components: {
    HeadingChapterItem,
    TextChapterItem,
    SpreadsheetChapterItem,
    ExcelTableChapterItem,
    ChartChapterItem,
    LibraryChapterItem,
    ImageChapterItem,
    SingleImpactHeaderChapterItem,
    AddParagraphButton,
  },
})
export default class ChapterItemComponent extends Vue {
  @Prop() item: ChapterItem;
  @Prop() index: number;
  @Prop({ default: false }) locked: boolean;
  @Prop({ default: false, type: Boolean }) isEditModeAllowed: boolean;
  @Prop({ default: true, type: Boolean }) isDeleteButtonAllowed: boolean;

  mode: ChapterItemMode = 'view';

  ChapterItemType: any = ChapterItemType;

  @UiModuleStore.Getter
  isFooterCommentingActive: boolean;

  @UiModuleStore.Getter
  isFooterTrackingActive: boolean;

  @UiModuleStore.Mutation
  setFooterLibraryActive!: (value: boolean) => void;

  @UiModuleStore.Mutation
  setFooterCommentingActive!: (value: boolean) => void;

  @UiModuleStore.Mutation
  setFooterTrackingActive!: (value: boolean) => void;

  @UiModuleStore.Mutation
  setFooterVersioningActive!: (value: boolean) => void;

  @UserStore.State
  user!: User;

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  @CommentsStore.Action
  expandComment: (uuid: string) => void;

  @ReportsStore.Getter
  public getCurrentChapter!: Chapter;

  @ReportsStore.Action
  public addChapterItem!: (payload: NewChapterItemPayload) => Promise<any>;

  @ReportsStore.Action
  public lockChapterItem!: (uuid: string) => Promise<any>;

  @ReportsStore.Action
  public unlockChapterItem!: (uuid: string) => Promise<any>;

  @ReportsStore.Action
  deleteChapterItem!: (uuid: string) => Promise<any>;

  @ReportsStore.Action
  updateChapterItem!: (payload: UpdateChapterItemPayload) => Promise<any>;

  @ReportsStore.Action
  toggleParagraphExpand!: (uuid: string) => Promise<any>;

  @Watch('locked')
  onLockedPropChange() {
    if (this.mode === 'comment' || this.mode === 'tracking') return;
    if (this.isLockedByOtherUser) {
      this.mode = 'lock';
    } else if (this.mode === 'lock') {
      this.mode = 'view';
    }
  }

  @Watch('isFooterCommentingActive')
  onIsFooterCommentingActiveChange(isFooterCommentingActive: boolean) {
    if (isFooterCommentingActive) {
      this.switchMode('comment');
      this.registerMouseEvent();
    } else {
      this.switchMode('view');
      this.deregisterMouseEvent();
    }
  }

  @Watch('isFooterTrackingActive')
  onIsFooterTrackingActiveChange(isFooterTrackingActive: boolean) {
    if (isFooterTrackingActive) {
      this.switchMode('tracking');
    } else {
      this.switchMode('view');
    }
  }

  @Watch('getCurrentChapter', { deep: true })
  onCurrentChapterChange() {
    if (this.mode === 'comment') {
      this.deregisterMouseEvent();
      this.registerMouseEvent();
    }
  }

  created() {
    if (this.isFooterCommentingActive) {
      this.mode = 'comment';
      this.registerMouseEvent();
    } else if (this.isFooterTrackingActive) {
      this.mode = 'tracking';
    } else {
      if (!this.isEditModeAllowed) return;

      if (this.item.uuid.startsWith(TEMPORARY_UUID_PREFIX)) {
        // new item
        this.mode = 'edit';
      }
      if (this.isLockedByOtherUser) {
        this.mode = 'lock';
      } else if (this.item.locked_by) {
        // locked by current user
        this.mode = 'edit';
      }
    }
  }

  beforeDestroy() {
    this.deregisterMouseEvent();
  }

  registerMouseEvent() {
    const chapterItem = this.$refs.chapterItem as HTMLDivElement;
    if (chapterItem) chapterItem.addEventListener('mousedown', this.onMouseDown);
  }

  deregisterMouseEvent() {
    const chapterItem = this.$refs.chapterItem as HTMLDivElement;
    if (chapterItem) chapterItem.removeEventListener('mousedown', this.onMouseDown);
  }

  onMouseDown(event: MouseEvent) {
    const target = event.target as HTMLSpanElement;
    if (!target || target.className !== 'comment-highlight') return;
    this.expandComment(target.dataset.uuid);
  }

  get isHelpIconVisible() {
    return false;
    // return this.mode !== 'view' && true;
  }

  get isLockedByOtherUser() {
    return this.item.locked_by && this.item.locked_by.person && this.item.locked_by.person.id !== this.user.id;
  }

  get shouldShowAddAfterParagraphButton() {
    return this.mode === 'edit' && this.item.type !== ChapterItemType.SINGLE_IMPACT_HEADER;
  }

  get shouldShowAddBeforeParagraphButton() {
    return (
      this.mode === 'edit' &&
      this.item.type !== ChapterItemType.SINGLE_IMPACT_HEADER &&
      !(this.item.type === ChapterItemType.TEXT && !this.item.apply_synclocker)
    );
  }

  async addNewParagraph(event: { type: ChapterItemType; position: 'after' | 'before' }) {
    if (event.type === ChapterItemType.LIBRARY_CONTENT && (await this.isAllowed(UserPermission.LIBRARY_BAR_SHOW))) {
      this.setFooterCommentingActive(false);
      this.setFooterTrackingActive(false);
      this.setFooterVersioningActive(false);
      this.setFooterLibraryActive(true);
    }
    this.addChapterItem({ type: event.type, uuid: this.item.uuid, position: event.position });
  }

  async updateParagraph(payload: UpdateChapterItemPayload) {
    let isLocked = false;

    // Lock the chapter item to the current user, unless it's already locked.
    //   Do not do this for temporary items, as they are not known by the server yet.
    //   For more information, please see: https://gitlab.com/eon-plus/envigo/v2/app/-/issues/89
    if (!this.item.uuid.startsWith(TEMPORARY_UUID_PREFIX) && !this.item.locked_by) {
      await this.lockChapterItem(this.item.uuid);
      isLocked = true;
    }

    await this.updateChapterItem(payload);

    if (isLocked) await this.unlockChapterItem(this.item.uuid);
  }

  async switchMode(mode: ChapterItemMode) {
    try {
      if (
        this.mode === mode ||
        this.mode === 'lock' ||
        ((this.mode === 'comment' || this.mode === 'tracking') && mode !== 'view') ||
        (mode === 'edit' && !this.isEditModeAllowed)
      )
        return;
      if (mode === 'edit') {
        await this.lockChapterItem(this.item.uuid);
        if (!this.item.isExpandedInContent) {
          this.toggleParagraphExpand(this.item.uuid);
        }
      } else if (
        (this.mode !== 'comment' && this.mode !== 'tracking' && mode === 'view') ||
        (this.mode === 'edit' && (mode === 'comment' || mode === 'tracking'))
      ) {
        let shouldDelete = false;
        if (!this.item.uuid.startsWith(TEMPORARY_UUID_PREFIX)) {
          // already created paragraph
          switch (this.item.type) {
            case ChapterItemType.HEADING:
              if (!this.item.title && !this.item.text) {
                shouldDelete = true;
              }
              break;
            case ChapterItemType.TEXT:
              if (!this.item.text) {
                shouldDelete = true;
              }
              break;
            case ChapterItemType.IMAGE:
              if (!this.item.title && !this.item.image) {
                shouldDelete = true;
              }
              break;
            case ChapterItemType.TABLE:
              if (!this.item.title && !this.item.html) {
                shouldDelete = true;
              }
              break;
            case ChapterItemType.SPREADSHEET:
              if (!this.item.title && !this.item.html && !this.item.selection) {
                shouldDelete = true;
              }
              break;

            default:
              break;
          }
        }
        if (!this.item.apply_synclocker) {
          shouldDelete = false;
        }

        if (shouldDelete) {
          await this.deleteChapterItem(this.item.uuid);
        } else {
          await this.unlockChapterItem(this.item.uuid);
        }
      }
      this.mode = mode;
      // console.log('--------> this.mode: ', this.mode);
    } catch (error) {
      console.error(error);
    }
  }

  onDelete() {
    DialogService.presentDialog('Are you sure?', 'Lorem ipsum dolor sit amet.', 'Yes', 'Cancel', () => {
      this.deleteChapterItem(this.item.uuid);
    });
  }
}
