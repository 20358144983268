
















































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CriteriaGroup from '../CriteriaGroup.vue';

import LoaderService from '@/app/shared/utils/loader.service';
import { AssessData } from '@/app/shared/models/calculations/AssessData';
import CheckTreeItem from '@/app/shared/models/CheckTreeItem';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import LocalStorage from '@/app/shared/utils/local-storage';
import { LocalStorageKey } from '@/app/shared/enums/local-storage-key.enum';
import { PasteAssessmentSelectionPayload } from '@/app/shared/store/modules/calculations.store-module';
import { ModuleProperties } from '@/app/shared/models/Module';
const CalculationsStore = namespace('Calculations');
const ModulePropertiesStore = namespace('ModuleProperties');

interface AssessModalTab {
  id: string;
  title: string;
  children?: AssessModalTab[];
}

@Component({
  components: { CriteriaGroup },
})
export default class AssessModal extends Vue {
  @Prop() unitUuid: string;
  @Prop() modalVisible: boolean;

  tabs: AssessModalTab[] = null;
  activeFirstLevelTabIndex: number = 0;
  activeSecondLevelTabIndex: number = 0;

  initialized: boolean = false;
  pasteInfoVisible: boolean = false;

  selectionsCopy: CheckTreeItem[] = null;

  @CalculationsStore.Getter
  getAssessData: AssessData;

  @ModulePropertiesStore.Getter
  moduleProperties: ModuleProperties;

  @CalculationsStore.Action
  pasteAssessmentSelection: (payload: PasteAssessmentSelectionPayload) => Promise<any>;

  created() {
    this.setPasteInfoVisibility();
    setTimeout(() => {
      this.initialized = true;
    }, 500);
  }

  @Watch('modalVisible')
  onModalVisibilityChange() {
    if (!this.modalVisible) {
      this.changeAlternativeTab(0);
    }
  }

  // @CalculationsStore.Action
  // initializeFormulateData: (unitUuid: string) => Promise<any>;

  get title() {
    return this.getAssessData.method.name;
  }

  get impactTitle() {
    return this.getAssessData.name;
  }

  get alternatives() {
    return this.getAssessData.alternatives;
  }

  get activeAlternativePhase() {
    return this.alternatives[this.activeFirstLevelTabIndex].phases[this.activeSecondLevelTabIndex];
  }

  get scoreFieldColor() {
    return this.activeAlternativePhase.color;
  }

  get scoreFieldValue() {
    return this.activeAlternativePhase.score;
  }

  get criteriaGroups() {
    return this.getAssessData.method.unit.checktrees[0].children;
  }

  @Emit()
  close() {
    // just emiting event
  }

  setPasteInfoVisibility() {
    if (!LocalStorage.getItem(LocalStorageKey.ASSESSMENT_PASTE_INFO)) {
      this.pasteInfoVisible = true;
    }
  }

  changeAlternativeTab(tabIndex: number) {
    this.activeFirstLevelTabIndex = tabIndex;
    this.changeAlternativePhaseTab(0);
  }

  changeAlternativePhaseTab(tabIndex: number) {
    this.activeSecondLevelTabIndex = tabIndex;
  }

  hidePasteInfo() {
    LocalStorage.setItem(LocalStorageKey.ASSESSMENT_PASTE_INFO, true);
    this.pasteInfoVisible = false;
  }

  copySelection() {
    this.selectionsCopy = [...this.activeAlternativePhase.selections];
  }

  async pasteSelection() {
    try {
      LoaderService.disableHttpLoader();
      LoaderService.showLoader();
      await this.pasteAssessmentSelection({
        unitUuid: this.unitUuid,
        phase: this.activeAlternativePhase,
        selection: this.selectionsCopy,
        module: this.moduleProperties,
      });
      eventBus.$emit(EventType.ASSESSMENT_SELECTION_PASTED);
    } catch (error) {
      console.error(error);
    } finally {
      LoaderService.hideLoader();
      LoaderService.enableHttpLoader();
    }
  }
}
