
import { Component, Vue } from 'vue-property-decorator';
import { EVENT_CONSTANTS } from 'v-form-builder/src/configs/events';

@Component
export default class FormFieldSubmit extends Vue {
  submit() {
    this.$formEvent.$emit(EVENT_CONSTANTS.RENDERER.RUN_VALIDATION, true);
  }
}
