







































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaseButton extends Vue {
  @Prop() type:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quart'
    | 'quin'
    | 'sen'
    | 'septim'
    | 'octav'
    | 'noven'
    | 'icon'
    | 'icon-light'
    | 'icon-sen'
    | 'icon-septim'
    | 'icon-special';
  @Prop() icon: boolean;
  @Prop() disabled: boolean;
  @Prop({ default: 'default' }) size: 'default' | 'small';
  @Prop({ default: 'block' }) display: 'block' | 'inline';
  @Prop({ type: Boolean }) isBlock: boolean;

  @Emit()
  click(event: PointerEvent) {
    // just emiting event
    event.preventDefault();
    (this.$refs.baseBtn as HTMLElement).blur();
  }

  get isIconOnly() {
    return (
      this.type === 'icon' ||
      this.type === 'icon-light' ||
      this.type === 'icon-sen' ||
      this.type === 'icon-septim' ||
      this.type === 'icon-special'
    );
  }
}
