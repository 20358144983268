import store from '../store';

export default class LoaderService {
  static showLoader(httpLoaderDisabled: boolean = false) {
    if (httpLoaderDisabled) {
      store.dispatch('Loader/disableHttpLoader');
    }
    store.dispatch('Loader/pending');
    // store.dispatch('Loader/show');
  }

  static hideLoader() {
    if (store.getters['Loader/isHttpLoaderDisabled']) {
      store.dispatch('Loader/enableHttpLoader');
    }
    store.dispatch('Loader/done');
    // store.dispatch('Loader/hide');
  }

  static disableHttpLoader() {
    store.dispatch('Loader/disableHttpLoader');
  }

  static enableHttpLoader() {
    store.dispatch('Loader/enableHttpLoader');
  }
}
