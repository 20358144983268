




















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PageHeader from '@/app/shared/components/PageHeader.vue';
import { MatrixType } from '@/app/shared/enums/matrix-type.enum';

const UserStore = namespace('User');
const ChecklistsStore = namespace('Checklists');

@Component({
  components: { PageHeader },
})
export default class MatrixRelationsPageHeader extends Vue {
  @Prop() title: string;
  @Prop() matrixType: MatrixType;
  @Prop() isAllExpanded: boolean;

  @Emit()
  toggleExpandAll(_shouldExpand: boolean) {
    //
  }

  @Emit('resume')
  resume() {
    //
  }

  @UserStore.Getter
  public isAuthenticated: boolean;

  @ChecklistsStore.Action
  public lockAllItems!: (shouldLock: boolean) => void;

  @ChecklistsStore.Action
  public selectAllItems!: (shouldSelect: boolean) => void;

  @ChecklistsStore.Action
  public enumerateChecktreeItems!: (shouldEnumerate: boolean) => void;

  @ChecklistsStore.Action
  public toggleFoldItems!: (shouldFold: boolean) => void;

  @Watch('stepTitle')
  onStepTitleChange(stepTitle: string) {
    // Set the meta title directly, since we are fetching this value asynchronously.
    if (stepTitle) document.title = `${stepTitle} - Envigo`;
  }

  get titleIcon() {
    return this.matrixType == MatrixType.FIRST ? '$matrix' : '$matrix-type-2';
  }
}
