
























































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { debounce, escapeRegExp } from 'lodash';
import CommentingCommentEditable from './CommentingCommentEditable.vue';
import api from '@/app/shared/api';
import LoaderService from '@/app/shared/utils/loader.service';
import { OptionItem } from '@/app/shared/models/form/field/FormFieldSelect';

@Component({
  components: {
    CommentingCommentEditable,
  },
})
export default class CommentingCommentAdd extends Vue {
  @Prop() defaultContent: string;
  @Prop({ default: 'Comment' }) placeholderText: string;
  @Prop({ default: 'Comment' }) addText: string;
  @Prop({ default: false, type: Boolean }) hideButtons: boolean;
  @Prop({ default: false, type: Boolean }) noFocus: boolean;
  commentContent = '';
  wasFocused = false;
  isDropdownShown = false;
  dropdownItems: OptionItem[] = [];
  dropdownRegex = /(?<!<strong>)\B(@)(\w*)/;
  editableWidth = 'auto';

  @Emit()
  add(_commentContent: string) {
    //
  }

  @Emit()
  cancel() {
    this.reset();
  }

  @Watch('commentContent')
  onCommentContentChange(commentContent: string) {
    if (this.dropdownRegex.test(commentContent)) {
      this.debouncedGetDropdownItems();
    } else this.hideDropdown();
  }

  created() {
    if (this.defaultContent) this.commentContent = this.defaultContent;
  }

  mounted() {
    this.$nextTick(() => {
      this.getEditableWidth();
      if (!this.noFocus) this.focusEditable();
    });
  }

  getEditableWidth() {
    if (
      !this.$refs.editable ||
      !(this.$refs.editable as Vue & { $el: HTMLSpanElement }).$el ||
      !(this.$refs.editable as Vue & { $el: HTMLSpanElement }).$el.offsetWidth
    ) {
      this.editableWidth = 'auto';
      return;
    }

    this.editableWidth = (this.$refs.editable as Vue & { $el: HTMLSpanElement }).$el.offsetWidth.toString();
  }

  focusEditable() {
    if (this.$refs.editable) (this.$refs.editable as Vue & { setCaretEnd: () => void }).setCaretEnd();
  }

  get hasCommentContent() {
    return this.commentContent?.length && this.commentContent.replaceAll(/\s+|(&nbsp;)+/g, '').length;
  }

  get areButtonsShown() {
    if (this.wasFocused) return true;
    if (this.hideButtons) return false;
    return true;
  }

  addComment(commentContent: string) {
    if (!this.hasCommentContent) return;
    this.reset();
    this.add(commentContent);
  }

  reset() {
    this.commentContent = '';
    this.wasFocused = false;
  }

  async getDropdownItems() {
    try {
      LoaderService.disableHttpLoader();

      let userItems = await api.options.getOptions({
        model: 'Person',
        context: {
          type: 'User',
        },
        key: 'id',
        value: '',
        concat: {
          fields: ['first_name', 'last_name'],
          separator: ' ',
        },
      });

      const userMatch = this.commentContent.match(this.dropdownRegex);

      if (userMatch && userMatch[2])
        userItems = userItems.filter((item) => new RegExp(escapeRegExp(userMatch[2]), 'i').test(item.text));

      if (!userItems.length) {
        this.hideDropdown();
        return;
      }

      this.dropdownItems = userItems;

      this.showDropdown();
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  debouncedGetDropdownItems = debounce(() => {
    this.getDropdownItems();
  }, 250);

  showDropdown() {
    this.getEditableWidth();
    this.isDropdownShown = true;
  }

  hideDropdown() {
    this.isDropdownShown = false;
    this.dropdownItems = [];
  }

  replaceDropdownMatch(dropdownReplacement: string) {
    this.commentContent = this.commentContent.replace(
      this.dropdownRegex,
      `<strong>$1${dropdownReplacement}</strong>&nbsp;`,
    );
    this.hideDropdown();
    this.$nextTick(() => {
      this.focusEditable();
    });
  }
}
