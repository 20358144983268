




import { UnitFactor } from '@/app/shared/models/calculations/UnitFactor';
import { Component, Prop, Vue } from 'vue-property-decorator';
import _debounce from 'lodash.debounce';
import { namespace } from 'vuex-class';
import LoaderService from '@/app/shared/utils/loader.service';
import { UpdateFactorPayload } from '@/app/shared/store/modules/calculations.store-module';
const CalculationsStore = namespace('Calculations');

@Component({})
export default class FactorInput extends Vue {
  @Prop() private factor: UnitFactor;
  @Prop() unitUuid: string;

  @CalculationsStore.Action
  updateFactor: (payload: UpdateFactorPayload) => Promise<any>;

  onInput = _debounce(async (value: string) => {
    try {
      LoaderService.disableHttpLoader();
      await this.updateFactor({
        unitUuid: this.unitUuid,
        factorUuid: this.factor.uuid,
        weight: value === '' ? null : Number(value),
      });
    } catch (error) {
      console.error(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }, 500);
}
