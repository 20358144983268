import MatrixRelationField from '@/app/shared/models/MatrixRelationField';

export class MatrixAxis {
  public id: number;
  public uuid: string;
  public name: string;
  public description: string;
  public help: string;
  public is_admin: boolean;
  public weigth: string;
  public user_id: number;
  public study_id: number;
  public type_id: number;
  public from_matrix_id: number;
  public cloned_from_id: number;
  public children: Array<MatrixRelationField>;
}
