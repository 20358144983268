




















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { DashboardDataTableAction } from '@/app/shared/models/DashboardDataTable';

@Component
export default class DashboardDataTableActionField extends Vue {
  @Prop() item: any;
  @Prop() action: string;
  @Prop() config: DashboardDataTableAction[];

  @Emit('action-click')
  onActionClick(_type: string) {
    //
  }

  get buttonConfig() {
    if (!this.config) return {} as DashboardDataTableAction;
    return this.config.find((button) => button.type === this.action);
  }

  @AsyncComputed()
  async isDisabled() {
    if (typeof this.buttonConfig.isDisabled === 'function') return await this.buttonConfig.isDisabled(this.item);
    return false;
  }
}
