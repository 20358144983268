export default (fieldValue: string, valuesContainer: { current_password: string }): boolean => {
  // Will be handled by the required validator.
  if (fieldValue === undefined || fieldValue === null || !fieldValue.length) return true;

  // Check if the current password field exists in the form.
  //  Log an error if that's not the case.
  if (!valuesContainer.hasOwnProperty('current_password')) {
    console.error('Did you forget to include a field named `current_password` in your form?');
  }

  return fieldValue !== valuesContainer.current_password;
};
