var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center study-reviews-timeline",style:({
    '--status-color': _vm.statusColor,
  })},[_c('div',{staticClass:"study-reviews-timeline__background-bar"}),_c('div',{staticClass:"study-reviews-timeline__progress-bar",style:({
      width: _vm.currentProgress,
    }),attrs:{"data-status":_vm.status,"data-cy":"progress-bar"}}),_c('div',{staticClass:"study-reviews-timeline__event-dot study-reviews-timeline__event-dot--is-start"}),_c('div',{staticClass:"study-reviews-timeline__event-dot study-reviews-timeline__event-dot--is-end"}),_vm._l((_vm.timelineEvents),function(event){return _c('v-tooltip',{key:event.id,ref:"tooltip",refInFor:true,attrs:{"top":!event.is_admin,"bottom":event.is_admin,"content-class":_vm.getTooltipClass(event),"data-cy":"event-tooltip","transition":"fade","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"study-reviews-timeline__event-dot",class:{
          'study-reviews-timeline__event-dot--is-past': _vm.isPastDate(event.dayObj),
          'study-reviews-timeline__event-dot--is-future': _vm.isFutureDate(event.dayObj),
        },style:({
          left: _vm.getDateLeftPosition(event.dayObj),
        }),attrs:{"data-date":event.date,"data-cy":"event-dot"}},'div',attrs,false),_vm.isFutureDate(event.dayObj) || _vm.isShown ? null : on))]}},{key:"default",fn:function(){return [_c('div',{staticClass:"d-flex flex-column study-reviews-timeline__event-container"},[_c('div',{staticClass:"study-reviews-timeline__event-date"},[_vm._v(" "+_vm._s(event.dayObj.format('D MMM YYYY'))+" ")]),_c('div',{staticClass:"study-reviews-timeline__event-name"},[_vm._v(" "+_vm._s(event.name)+" ")])]),_c('div',{staticClass:"study-reviews-timeline__event-arrow"})]},proxy:true}],null,true),model:{value:(_vm.shownTimelineEvents[event.id]),callback:function ($$v) {_vm.$set(_vm.shownTimelineEvents, event.id, $$v)},expression:"shownTimelineEvents[event.id]"}})}),(_vm.isAddEventsAllowed)?_c('BaseButton',{staticClass:"align-self-end study-reviews-timeline__add-button",attrs:{"type":"septim","icon":"calendar-1px","data-cy":"add-events"},on:{"click":_vm.add}},[_vm._v(" Add events ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }