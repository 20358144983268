





























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ReportChaptersTable extends Vue {
  @Prop() header: { text: string; icon: string };
  @Prop() columns: {
    text: string;
    value?: string;
    width?: number;
    numericValue?: string;
    checkboxType?: string;
    checkboxValue?: string;
    actionType?: string;
    actionIcon?: string;
  }[];
  @Prop() data: Record<string, string>[];

  @Emit('action-click')
  onActionClick(_type: string, _item: Record<string, string>) {
    //
  }

  @Emit('checkbox-click')
  onCheckboxClick(_type: string, _item: Record<string, string>) {
    //
  }

  disallowNonNumericInput(event: KeyboardEvent) {
    if (/\d/.test(event.key)) return;
    event.preventDefault();
  }

  isEmpty(value: any) {
    return value == null || value === '';
  }
}
