import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { WorkflowResponse } from '@/app/shared/models/workflow/WorkflowResponse';

@Module({ namespaced: true })
export class WorkflowModule extends VuexModule {
  workflowData: Record<any, WorkflowResponse>;
  currentNode: Record<any, any> = {};
  currentConnection: Record<any, any> = {};
  checktrees: Array<any> = [];
  matrices: Array<any> = [];
  currentFile: Array<any> = [];
  flows: Array<any> = [];

  get getWorkflow(): Record<any, WorkflowResponse> {
    return this.workflowData;
  }

  get getCurrentNode(): any {
    return this.currentNode;
  }

  get getCurrentConnection(): any {
    return this.currentConnection;
  }

  get getChecktreesOptions(): any {
    const options: Array<any> = [];

    for (const checktree of this.checktrees) {
      options.push({ label: checktree.name, value: checktree.id });
    }

    return options;
  }

  get getMatricesOptions(): any {
    const options: Array<any> = [];

    for (const matrix of this.matrices) {
      options.push({ label: matrix.name, value: matrix.id });
    }

    return options;
  }

  get getCurrentFile(): any {
    return this.currentFile;
  }

  get getFlows(): any {
    return this.flows;
  }

  @Mutation
  public setWorkflow(data: Record<any, WorkflowResponse>): void {
    this.workflowData = data;
  }

  @Mutation
  public setCurrentNode(data: Record<any, any>): void {
    this.currentNode = data;
  }

  @Mutation
  public setCurrentConnection(data: Record<any, any>): void {
    this.currentConnection = data;
  }

  @Mutation
  public setChecktrees(data: any): void {
    this.checktrees = data;
  }

  @Mutation
  public setMatrices(data: any): void {
    this.matrices = data;
  }

  @Mutation
  public addNewlyAddedChecktree(data: any): void {
    this.checktrees.push(data);
  }

  @Mutation
  public addNewlyAddedMatrix(data: any): void {
    this.matrices.push(data);
  }

  @Mutation
  public setCurrentFile(data: any): void {
    this.currentFile = data;
  }

  @Mutation
  public setFlows(data: any): void {
    this.flows = data;
  }
}
