var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item-content",class:{
    'edit-mode': _vm.mode === 'edit',
    'lock-mode': _vm.mode === 'lock',
    expanded: _vm.isExpanded,
  }},[_c('div',{staticClass:"d-flex align-center figure-title"},[(_vm.hasComment)?_c('ChapterItemCommentIndicator',{attrs:{"uuid":_vm.item.comment},on:{"click":_vm.expandItemComment}}):_vm._e(),_c('span',{staticClass:"figure-numeration",style:({
        cursor: _vm.hasDraftComment || _vm.hasComment ? 'default' : 'pointer',
      }),on:{"click":function($event){_vm.isCommentMode ? _vm.addDraftComment() : _vm.onFigureTitleClick()}}},[_vm._v(" Figure "+_vm._s(_vm.item.numeration)+" ")]),(_vm.mode === 'edit')?_c('BaseTextField',{attrs:{"value":_vm.item.title,"placeholder":"Enter title","type":"title"},on:{"input":_vm.onTitleInput}}):_c('span',{staticClass:"title-value",style:({
        cursor: _vm.hasDraftComment || _vm.hasComment ? 'default' : 'pointer',
      }),on:{"click":function($event){_vm.isCommentMode ? _vm.addDraftComment() : _vm.onFigureTitleClick()}}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('div',{staticClass:"btns-right relative-position"},[_c('div',{staticClass:"btns-col"},[_c('BaseButton',{staticClass:"btn-expand",attrs:{"type":"icon-light"},on:{"click":_vm.toggleExpand}},[_c('v-icon',{class:{ expanded: _vm.isExpanded }},[_vm._v("$expand")])],1)],1),_c('div',{staticClass:"btns-col"},[(_vm.mode === 'edit' && _vm.isDeleteButtonAllowed)?_c('BaseButton',{staticClass:"delete-btn",attrs:{"type":"icon-light"},on:{"click":_vm.deleteChapterItem}},[_c('v-icon',[_vm._v("$delete")])],1):_vm._e(),(_vm.mode === 'lock')?_c('v-icon',[_vm._v("$lock")]):_vm._e()],1)])],1),(_vm.mode === 'edit' && _vm.isExpanded && !_vm.item.html)?[_c('div',{staticStyle:{"position":"relative"}},[_vm._m(0),(!_vm.libraryContentDropped)?_c('Tree',{ref:"treeEl",staticClass:"tree-elem",attrs:{"value":[],"crossTree":true},on:{"change":_vm.onChange}}):_vm._e()],1)]:(_vm.item.html && _vm.isExpanded)?_c('div',[_c('div',{staticClass:"library-item-preview",style:({
        cursor: _vm.hasDraftComment || _vm.hasComment ? 'default' : 'pointer',
      }),domProps:{"innerHTML":_vm._s(_vm.item.html)},on:{"click":function($event){_vm.isCommentMode ? _vm.addDraftComment() : _vm.switchMode('edit')}}}),(_vm.mode === 'edit')?_c('div',{staticClass:"d-flex flex-row align-center landscape-checkbox"},[_c('BaseCheckbox',{attrs:{"value":_vm.item.landscape},on:{"change":_vm.onLandscapeValueChange}}),_c('span',[_vm._v("Landscape (PDF output)")])],1):_vm._e()]):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"drop-from-library-wrapper d-flex flex-column align-center"},[_c('img',{staticClass:"library-drop-icon",attrs:{"src":require("@/assets/img/library-drop.svg")}}),_c('span',{staticClass:"library-drop-here-text"},[_vm._v("Drop your Library content here")]),_c('span',{staticClass:"library-drop-desc"},[_vm._v("Drag and drop your Charts, Tables, Impacts...")])])}]

export { render, staticRenderFns }