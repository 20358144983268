



































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ReportsSidebar from './components/ReportsSidebar.vue';
import ReportsNavigator from './components/ReportsNavigator.vue';
import ReportsPageHeader from './components/ReportsPageHeader.vue';
import ChapterItemComponent from './components/ChapterItem/ChapterItem.vue';
import SingleImpactHeader from './components/ChapterItem/SingleImpactHeader.vue';
import SingleImpactNavigationBar from './components/SingleImpactNavigationBar.vue';
import PageFooter from '@/app/shared/components/PageFooter.vue';
import Commenting from '@/app/shared/components/Commenting.vue';
import Library from '@/app/shared/components/Library.vue';
import api from '@/app/shared/api';
import LoaderService from '@/app/shared/utils/loader.service';
import Tracking from '@/app/shared/components/Tracking.vue';
import UtilService from '@/app/shared/utils/util.service';
import { Chapter } from '@/app/shared/models/reports/Chapter';
import { ChapterItem } from '@/app/shared/models/reports/ChapterItem';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import { NewChapterItemPayload } from '@/app/shared/store/modules/reports.store-module';
import { WSMessageResponse } from '@/app/shared/models/WSMessageResponse';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import { WebSocketMessageStatus } from '@/app/shared/enums/WebsocketMessageStatus.enum';
import { WSMessageCommand } from '@/app/shared/enums/ws-message-command.enum';
import AsyncComputed from 'vue-async-computed-decorator';

const CommentsModuleStore = namespace('Comments');
const TrackingModuleStore = namespace('Tracking');
const ReportsModuleStore = namespace('Reports');
const UiModuleStore = namespace('UI');
const UserStore = namespace('User');

@Component({
  components: {
    PageFooter,
    ReportsSidebar,
    ReportsNavigator,
    ReportsPageHeader,
    ChapterItemComponent,
    SingleImpactHeader,
    SingleImpactNavigationBar,
    Commenting,
    Library,
    Tracking,
  },
})
export default class Reports extends Vue {
  @Prop() studyUuid: string;
  @Prop() uuid: string;
  @Prop() chapter: string;
  @Prop({ default: false, type: Boolean }) htu: boolean;
  @Prop() singleImpactView: boolean;
  @Prop() fromMatrix: string;
  @Prop() editPermission: string | string[];

  public activeChapterNumber: number = 1;
  public visitedChapterUuids: string[] = [];
  public defaultCommentingWidth = 0;
  public defaultLibraryWidth = 0;
  public defaultTrackingWidth = 0;
  isLoading: boolean = false;
  isDeleteButtonAllowed: boolean = true;

  @ReportsModuleStore.Getter
  public navigatorCollapsed!: boolean;

  @ReportsModuleStore.Getter
  public getChapters!: Chapter[];

  @ReportsModuleStore.Getter
  public getCurrentChapter!: Chapter;

  @ReportsModuleStore.Getter
  public getCurrentChapterNumber!: number;

  @ReportsModuleStore.Getter
  public getSingleImpact!: ChapterItem;

  @ReportsModuleStore.Getter
  public getParagraphs!: ChapterItem[];

  @ReportsModuleStore.Getter
  public areAllParagraphsExpandedInContent!: boolean;

  @ReportsModuleStore.Mutation
  setNavigatorCollapsed!: (isNavigatorCollapsed: boolean) => void;

  @UiModuleStore.Getter
  isFooterCommentingActive: boolean;

  @UiModuleStore.Getter
  isFooterLibraryActive: boolean;

  @UiModuleStore.Getter
  isFooterTrackingActive: boolean;

  @UiModuleStore.Mutation
  setFooterCommentingActive!: (value: boolean) => void;

  @UiModuleStore.Mutation
  setFooterTrackingActive!: (value: boolean) => void;

  @UiModuleStore.Mutation
  setFooterVersioningActive!: (value: boolean) => void;

  @ReportsModuleStore.Action
  public initializeChapters!: (data: { uuid: string; currentChapterNumber: number }) => Promise<any>;

  @ReportsModuleStore.Action
  public initializeSingleImpact!: (payload: { uuid: string; studyUuid: string; fromMatrix: string }) => Promise<any>;

  @ReportsModuleStore.Action
  public setCurrentSidebarItem!: (uuid: string) => Promise<void>;

  @ReportsModuleStore.Action
  public handleWebsocketMessage!: (response: WSMessageResponse) => Promise<boolean>;

  @ReportsModuleStore.Action
  public addChapterItem!: (payload: NewChapterItemPayload) => Promise<any>;

  @ReportsModuleStore.Action
  public expandAllInContent!: (shouldExpand: boolean) => Promise<void>;

  @ReportsModuleStore.Action
  public clearState!: () => Promise<void>;

  @CommentsModuleStore.Mutation
  setCommentObject: (payload: { objectModel: string; objectUuid: string }) => void;

  @TrackingModuleStore.Mutation
  setTrackingItemObject: (payload: { objectModel: string; objectUuid: string }) => void;

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  @Watch('getCurrentChapter')
  onCurrentChapterChange() {
    this.activeChapterNumber = this.getChapters.findIndex((el) => el.uuid === this.getCurrentChapter.uuid) + 1;
    const chapterUuid = this.getCurrentChapter ? this.getCurrentChapter.uuid : null;
    if (chapterUuid && this.visitedChapterUuids.indexOf(chapterUuid) === -1) {
      this.visitedChapterUuids.push(chapterUuid);
    }
    this.setFooterCommentingActive(false);
    this.setFooterTrackingActive(false);
    this.setFooterVersioningActive(false);
    this.setCommentObject({ objectModel: 'Chapter', objectUuid: this.getCurrentChapter.uuid });
    this.setTrackingItemObject({ objectModel: 'Chapter', objectUuid: this.getCurrentChapter.uuid });
    this.toggleExpandAll(true);
  }

  @Watch('getParagraphs')
  onParagraphsChange() {
    this.isDeleteButtonAllowed = this.getParagraphs.length <= 1 ? false : true;
  }

  @AsyncComputed()
  async isEditModeAllowed() {
    return await this.isAllowed(this.editPermission);
  }

  headingOrder(index: number) {
    let count = 0;
    for (let i = 0; i < index; i++) {
      const el = this.getCurrentChapter.children[i];
      if (el.type !== ChapterItemType.HEADING) {
        count++;
      }
    }
    return this.activeChapterNumber + '.' + (index + 1 - count);
  }

  get paragraphs() {
    return this.getParagraphs;
    // if (this.singleImpactView) {
    //   return this.getSingleImpact.children.slice(1);
    // } else {
    //   return this.getCurrentChapter.children;
    // }
  }

  get singleImpactParagraphs() {
    return this.paragraphs.slice(1);
  }

  get isPageHeaderVisible() {
    return this.getSingleImpact || (this.getChapters && this.getChapters.length > 0 && this.getCurrentChapter);
  }

  get pageHeaderTitle() {
    return this.singleImpactView
      ? this.getSingleImpact
        ? this.getSingleImpact.name
        : null
      : this.getCurrentChapter
      ? `${this.getCurrentChapterNumber}. ${this.getCurrentChapter.title}`
      : null;
  }

  created() {
    this.setFooterCommentingActive(false);
    this.setFooterTrackingActive(false);
    this.setFooterVersioningActive(false);
    this.initializeData();

    // Calculate default sidebar widths
    this.defaultTrackingWidth = this.defaultCommentingWidth = this.defaultLibraryWidth = UtilService.calculateSidebarWidth();

    eventBus.$on(EventType.REPORTS_RECEIVE_MSG, async (response: WSMessageResponse) => {
      try {
        if (response.status == WebSocketMessageStatus.SUCCESS) {
          const isActionVisibleOnUI: boolean = await this.handleWebsocketMessage(response);
          if (!isActionVisibleOnUI) {
            throw new Error('Action successful, but not visible on UI.');
          }
        } else {
          if (
            (response.command === WSMessageCommand.LOCK && response.lock) ||
            (response.command === WSMessageCommand.UNLOCK && !response.lock)
          ) {
            // if item is already locked/unlocked
            return;
          }
          throw new Error('Action unsuccessful -> error message: ' + response.message);
        }
      } catch (error) {
        console.error(error);
        // TODO: Handle error, show modal
      }
    });
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    this.clearState();
  }

  handleScroll() {
    let minOffsetTop = 100000;
    let currentParagraphUuid = '';
    document.querySelectorAll('div[id^="content/"]').forEach((div: any) => {
      const offsetPageTop = div.getBoundingClientRect().top + window.scrollY;
      if (offsetPageTop < minOffsetTop && this.isElementInViewport(div)) {
        minOffsetTop = offsetPageTop;
        currentParagraphUuid = div.id.split('/')[1];
      }
    });
    this.setCurrentSidebarItem(currentParagraphUuid);
  }
  isElementInViewport(el: any) {
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  }

  public toggleExpandAll(shouldExpand: boolean) {
    this.expandAllInContent(shouldExpand);
  }

  async initializeData() {
    try {
      this.isLoading = true;
      LoaderService.showLoader(true);
      if (this.singleImpactView) {
        await this.initializeSingleImpact({
          uuid: this.uuid,
          studyUuid: this.studyUuid,
          fromMatrix: this.fromMatrix,
        });
      } else {
        const chapter = this.chapter;
        let currentChapterNumber = 1;
        if (!isNaN(+chapter)) {
          currentChapterNumber = Number(chapter);
        }
        await this.initializeChapters({ uuid: this.uuid, currentChapterNumber: currentChapterNumber });
        this.setCommentObject({ objectModel: 'Chapter', objectUuid: this.getCurrentChapter.uuid });
      }
      this.toggleExpandAll(true);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
      LoaderService.hideLoader();
      // listens to scroll and select top element to show as active in sidebar
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  public toggleNavigatorCollapse(isNavigatorCollapsed: boolean) {
    this.setNavigatorCollapsed(isNavigatorCollapsed);
  }

  async onResume() {
    try {
      await api.reports.resumeReport(this.$route.fullPath);
      this.$router.go(-1);
    } catch (error) {
      console.warn(error);
    }
  }

  async onDownloadChapter() {
    try {
      const res = await api.how_to_use.renderHTUPdf(
        this.$route.params.uuid,
        this.getCurrentChapter.uuid,
        Number(this.$route.params.chapter),
        true,
      );

      const today = new Date().toISOString().slice(0, 10);
      const name = 'How_To_Use_' + today + '_chapter_#' + this.$route.params.chapter + '.pdf';

      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${name}`);
      link.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.warn(error);
    }
  }

  async onDownloadAll() {
    try {
      const res = await api.how_to_use.renderHTUPdf(this.$route.params.uuid, this.$route.params.uuid, 0, false);

      const today = new Date().toISOString().slice(0, 10);
      const name = 'How_To_Use_' + today + '.pdf';

      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${name}`);
      link.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.warn(error);
    }
  }
}
