export enum FormFieldValueModel {
  AUTHORITY = 'Authority',
  FACILITY = 'Facility',
  LOCATION = 'Location',
  INVESTOR = 'Investor',
  ASSESSOR = 'Assessor',
  ASSESSMENT_TEAM = 'AssessmentTeam',
  EVENT = 'StudyReview',
  ORGANIZATION = 'Organization',
  ORGANIZATION_UNIT = 'OrganizationUnit',
}

export class FormFieldValue<T> {
  data: T[];
  model: FormFieldValueModel;
  deleted_uuid: string[];

  constructor(data: T[], model: FormFieldValueModel, deleted_uuid: string[] = []) {
    this.data = data;
    this.model = model;
    this.deleted_uuid = deleted_uuid;
  }
}
