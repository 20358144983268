















import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';

@Component
export default class VersioningStar extends Vue {
  @Model('update') isStarred: boolean;
  @Prop({ default: false, type: Boolean }) isDisabled: boolean;

  @Emit()
  update(_isStarred: string) {
    //
  }
}
