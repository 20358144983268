




























































import { Component, Mixins, Prop } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import DashboardDetailsBar from '@/app/shared/components/dashboard/DashboardDetailsBar.vue';
import DashboardPageHeader from '@/app/shared/components/dashboard/DashboardPageHeader.vue';
import DashboardSidebar from '@/app/shared/components/dashboard/DashboardSidebar.vue';
import DashboardDataTable from '@/app/shared/components/dashboard/DashboardDataTable.vue';
import DashboardFiltering from '@/app/shared/components/mixins/DashboardFiltering.vue';
import DashboardDynamicColumns from '@/app/shared/components/mixins/DashboardDynamicColumns.vue';
import UserAvatar from '@/app/shared/components/UserAvatar.vue';
import PageFooter from '@/app/shared/components/PageFooter.vue';
import {
  DashboardDataTableAction,
  DashboardDataTableColumn,
  DashboardDataTableStatus,
} from '@/app/shared/models/DashboardDataTable';
import { DashboardDataTableColumnType } from '@/app/shared/enums/dashboard-data-table.enum';
import api from '@/app/shared/api';
import { Organization, OrganizationUserTableRow } from '@/app/shared/models/Organization';
import { User } from '@/app/shared/models/User';
import { Table } from '@/app/shared/models/Table';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

const UserStore = namespace('User');

@Component({
  components: {
    DashboardDetailsBar,
    DashboardPageHeader,
    DashboardSidebar,
    DashboardDataTable,
    PageFooter,
    UserAvatar,
  },
})
export default class OrganizationUsersDashboard extends Mixins(DashboardFiltering, DashboardDynamicColumns) {
  @Prop() uuid: string;
  roleIdLookup: Record<number, string> = null;
  organizationData: Organization = null;
  tableData: OrganizationUserTableRow[] = null;
  detailsData: Record<string, Table[][]> = {};
  fixedColumns: DashboardDataTableColumn[] = [
    {
      text: 'Status',
      value: 'status',
      type: DashboardDataTableColumnType.STATUS,
      width: '164px !important',
      resizable: false,
    },
    {
      text: 'Name',
      value: 'name',
      type: DashboardDataTableColumnType.TITLE,
      // width: (((720 / 1920) * window.screen.width) / 100) * 100 + 'px',
      resizable: true,
      transformValue: (user) =>
        user.first_name || user.last_name ? `${user.first_name ?? ''} ${user.last_name ?? ''}` : user.email,
    },
  ];
  dynamicColumns: DashboardDataTableColumn[] = [];
  activeFilters: Record<keyof User, string[]> = null;
  statusConfig: DashboardDataTableStatus[] = [
    {
      type: 'invited',
      icon: 'invited-16px',
      label: 'Invited',
      highlightColor: 'var(--v-violet-in-progress-status-highlight)',
      solidColor: 'var(--v-violet-in-progress-status-solid)',
    },
    {
      type: 'logged-in',
      icon: 'logged-in-16px',
      label: 'Logged in',
      highlightColor: 'var(--v-green-approve-status-highlight)',
      solidColor: 'var(--v-green-approve-status-solid)',
    },
    {
      type: 'logged-out',
      icon: 'logged-out-16px',
      label: 'Logged out',
      highlightColor: 'var(--v-gray-pale)',
      solidColor: 'var(--v-gray-dark)',
    },
    {
      type: 'inactive',
      icon: 'active-before-16px',
      label: 'Inactive',
      highlightColor: 'var(--v-gray-extra-light)',
      solidColor: 'var(--v-gray-dark)',
    },
  ];
  actionConfig: DashboardDataTableAction[] = [
    {
      type: 'add-study',
      icon: 'add',
      label: 'Add study',
      style: 'secondary',
      highlightColor: 'var(--v-blue-pale)',
      solidColor: 'var(--v-blue-primary)',
      isDisabled: (item: OrganizationUserTableRow) => item.status === 'invited',
    },
  ];
  isDetailsBarExpanded: boolean = false;

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  @AsyncComputed()
  async userActionColumns(): Promise<DashboardDataTableColumn[]> {
    return [
      {
        text: 'Action',
        value: 'action',
        type: DashboardDataTableColumnType.ACTION,
        actionType: 'add-study',
        width: '172px !important',
        resizable: false,
      },
    ];
  }

  @AsyncComputed()
  async createButton() {
    return (await this.isAllowed(UserPermission.INVITE_NEW_USER_ACCESS))
      ? {
          icon: 'add-user',
          label: 'Invite new user',
          route: `/organization/${this.uuid}/invite-new-user`,
        }
      : null;
  }

  @AsyncComputed()
  async isEditAllowed() {
    return await this.isAllowed(UserPermission.EDIT_USER_PROFILE_ACCESS);
  }

  @AsyncComputed()
  async isOrganizationSetupAllowed() {
    return await this.isAllowed(UserPermission.EDIT_ORGANIZATION_DETAILS_ACCESS);
  }

  get organizationTable(): Table {
    const organizationHeader = {
      text: 'Organization Details',
      ...(this.isOrganizationSetupAllowed
        ? {
            actionIcon: 'edit-24px',
            actionLabel: 'Edit',
            actionType: 'organization-setup',
          }
        : {}),
    };
    const organizationColumns = [
      {
        text: 'Organization name',
        value: 'name',
      },
      {
        text: 'Headquarters',
        value: 'address',
      },
      {
        text: 'Email address',
        value: 'email',
      },
      {
        text: 'Telephone number',
        value: 'phone_number',
      },
      {
        text: 'Website address',
        value: 'website',
      },
    ];
    const organizationData = this.organizationData || {};

    return {
      type: 'list',
      header: organizationHeader,
      columns: organizationColumns,
      data: organizationData,
    };
  }

  get organizationUuid() {
    return this.organizationData && this.organizationData.uuid;
  }

  get allColumns() {
    return [...this.fixedColumns, ...this.dynamicColumns, ...this.actionColumns];
  }

  get tableColumns() {
    return [...this.fixedColumns, ...this.dynamicColumns.filter((el) => el.checked), ...this.actionColumns];
  }

  get tagsColumns() {
    return [
      {
        field: 'department',
        name: 'Department',
      },
      {
        field: 'title',
        name: 'Title',
      },
      {
        field: 'position',
        name: 'Position',
      },
      {
        field: 'role_id',
        name: 'Role',
        transformValue: (user: OrganizationUserTableRow) => this.roleIdLookup[user.role_id],
      },
    ];
  }

  get headerIcon() {
    return '$company-dashboard';
  }

  get headerTitle() {
    return this.organizationData?.name ?? 'People';
  }

  get filterFields() {
    return ['department', 'role_id'];
  }

  get filterItems() {
    return [
      {
        field: 'department',
        icon: 'company-v3',
        text: 'Department',
        items: this.tableData && this.uniqueFilterValueItems.department,
      },
      {
        field: 'role_id',
        icon: 'user-role',
        text: 'Role',
        items: this.tableData && this.uniqueFilterValueItems.role_id,
      },
    ];
  }

  get licenceTable(): Table {
    const licenceHeader = {
      text: 'SaaS Details',
    };
    const licenceColumns = [
      {
        text: 'Subscription',
        value: 'subscription',
      },
      {
        text: 'Studies',
        value: 'studies',
      },
      {
        text: 'Users',
        value: 'users',
      },
      {
        text: 'Products',
        value: 'products_str',
      },
      {
        text: 'Expires in',
        value: 'expires_in',
      },
      {
        text: 'Studies to add',
        value: 'studies_left',
      },
      {
        text: 'Users to add',
        value: 'users_left',
      },
    ];
    const licenceData =
      this.organizationData &&
      Object.fromEntries(
        Object.entries(this.organizationData.saas).map(([key, value]) => {
          switch (key) {
            case 'subscription':
              if (value) value += ' month(s)';
              break;
            case 'studies':
            case 'users':
              if (value === null) value = 'Unlimited';
              break;
            case 'expires_in': {
              const [days] = (value && value.split(',')) || [];
              if (days) value = days;
              if (/^-/.test(value)) value = 'Expired';
              break;
            }
          }

          return [key, value];
        }),
      );

    return {
      type: 'list',
      header: licenceHeader,
      columns: licenceColumns,
      data: licenceData,
    };
  }

  created() {
    this.initDynamicColumns();
    this.getData();
  }

  async getData() {
    try {
      this.organizationData = await api.organization.getOrganization(this.uuid);

      const roleOptions = await api.options.getCustomOptions('/checktree/options/Role', {
        context: {
          organization_uuid: this.uuid,
        },
        key: 'id',
        value: 'title',
      });

      this.roleIdLookup = roleOptions.reduce(
        (roleIdLookup, roleOption) => ({
          ...roleIdLookup,
          [roleOption.value]: roleOption.text,
        }),
        {},
      ) as Record<number, string>;

      this.tableData = await api.organization.getOrganizationUsers(this.uuid);

      this.initActiveFilters();
    } catch (error) {
      console.error(error);
    }
  }

  async getDetails(uuid: string) {
    try {
      const organizationUserTableDetails = await api.organization.getOrganizationUserDetails(this.uuid, uuid);

      const profileHeader = {
        icon: 'profile-information',
        text: 'Profile Information',
      };

      const profileColumns = organizationUserTableDetails.profile.map((row, index) => {
        let text;

        switch (row.column) {
          case 'title':
            text = 'Title / degree';
            break;
          case 'position':
            text = 'Position with organization';
            break;
          case 'email':
            text = 'Email address';
            break;
          case 'phone_number':
            text = 'Telephone number';
            break;
          case 'role':
            text = 'Envigo user role';
            break;
          case 'status':
            text = 'Status';
            break;
          case 'organization_unit_name':
            text = 'Organization unit';
            break;
          case 'organization_unit_address':
            text = 'Address';
            break;
          case 'organization_unit_email':
            text = 'Contact email address';
            break;
          case 'organization_unit_phone_number':
            text = 'Contact phone number';
            break;
          case 'organization_unit_fax_number':
            text = 'Contact fax number';
            break;
          default:
            text = row.column;
            break;
        }

        return {
          text,
          value: index,
        };
      });

      const profileData = organizationUserTableDetails.profile.reduce(
        (data, row, index) => ({
          ...data,
          [index]: row.text?.length ? row.text : '-',
        }),
        {},
      );

      const reviewHeader = {
        icon: 'performance',
        text: 'Description / Responsibility / Review',
      };

      const review = organizationUserTableDetails.review.find((row) => row.column === 'description');

      const reviewData = review?.isRichText
        ? review.text?.length
          ? review.text
          : '-'
        : `<p>${review?.text?.length ? review.text : '-'}</p>`;

      const activeStudiesHeader = {
        icon: 'study-type',
        text: 'Active studies',
      };
      const activeStudiesColumns = [
        {
          text: 'Study name',
          value: 'study_name',
          resizable: true,
        },
        {
          text: 'Responsibility',
          value: 'responsibility',
          width: '116px',
          resizable: true,
        },
        {
          text: 'Metric 1',
          value: 'metric_1',
          width: '60px',
          color: 'var(--v-green-approve-status-solid)',
          background: 'var(--v-green-approve-status-highlight)',
          resizable: true,
        },
        {
          text: 'Metric 2',
          value: 'metric_2',
          width: '72px',
          color: 'var(--v-blue-primary)',
          background: 'var(--v-blue-pale)',
          resizable: true,
        },
        {
          text: 'Metric 3',
          value: 'metric_3',
          width: '56px',
          color: 'var(--v-orange-under-revision-status-solid)',
          background: 'var(--v-orange-under-revision-status-highlight)',
          resizable: true,
        },
        {
          text: 'Metric 4',
          value: 'metric_4',
          width: '56px',
          color: 'var(--v-violet-in-progress-status-solid)',
          background: 'var(--v-violet-in-progress-status-highlight)',
          resizable: true,
        },
        {
          text: 'Action',
          width: '100px',
          actionType: 'activity',
          actionLabel: 'Activity',
        },
      ];

      const activeStudiesDummyData = [
        {
          responsibility: 'Reviewer',
          metric_1: '328',
          metric_2: '3443',
          metric_3: '10.5',
          metric_4: '0.19',
        },
        {
          responsibility: 'Consultant',
          metric_1: '136',
          metric_2: '1892',
          metric_3: '13.9',
          metric_4: '0.28',
        },
        {
          responsibility: 'Consultant',
          metric_1: '234',
          metric_2: '2756',
          metric_3: '11.7',
          metric_4: '0.23',
        },
      ];

      const activeStudiesData = organizationUserTableDetails.active_studies.map((row, index) => ({
        study_name: row.study_name,
        ...activeStudiesDummyData[index % activeStudiesDummyData.length],
      }));

      const pastStudiesHeader = {
        icon: 'study-type',
        text: 'Past studies',
      };
      const pastStudiesColumns = [
        {
          text: 'Study name',
          value: 'study_name',
          resizable: true,
        },
        {
          text: 'Responsibility',
          value: 'responsibility',
          width: '116px',
          resizable: true,
        },
        {
          text: 'Metric 1',
          value: 'metric_1',
          width: '60px',
          color: 'var(--v-green-approve-status-solid)',
          background: 'var(--v-green-approve-status-highlight)',
          resizable: true,
        },
        {
          text: 'Metric 2',
          value: 'metric_2',
          width: '72px',
          color: 'var(--v-blue-primary)',
          background: 'var(--v-blue-pale)',
          resizable: true,
        },
        {
          text: 'Metric 3',
          value: 'metric_3',
          width: '56px',
          color: 'var(--v-orange-under-revision-status-solid)',
          background: 'var(--v-orange-under-revision-status-highlight)',
          resizable: true,
        },
        {
          text: 'Metric 4',
          value: 'metric_4',
          width: '56px',
          color: 'var(--v-violet-in-progress-status-solid)',
          background: 'var(--v-violet-in-progress-status-highlight)',
          resizable: true,
        },
        {
          text: 'Action',
          width: '100px',
          actionType: 'activity',
          actionLabel: 'Activity',
        },
      ];

      const pastStudiesDummyData = [
        {
          responsibility: 'Consultant',
          metric_1: '168',
          metric_2: '1967',
          metric_3: '11.7',
          metric_4: '0.23',
        },
        {
          responsibility: 'Consultant',
          metric_1: '289',
          metric_2: '3563',
          metric_3: '12.3',
          metric_4: '0.29',
        },
        {
          responsibility: 'Reviewer',
          metric_1: '229',
          metric_2: '2884',
          metric_3: '12.6',
          metric_4: '0.31',
        },
        {
          responsibility: 'Manager',
          metric_1: '146',
          metric_2: '1772',
          metric_3: '12.1',
          metric_4: '0.27',
        },
        {
          responsibility: 'Consultant',
          metric_1: '282',
          metric_2: '2987',
          metric_3: '10.6',
          metric_4: '0.18',
        },
        {
          responsibility: 'Consultant',
          metric_1: '132',
          metric_2: '1658',
          metric_3: '12.5',
          metric_4: '0.30',
        },
        {
          responsibility: 'Consultant',
          metric_1: '197',
          metric_2: '2103',
          metric_3: '10.6',
          metric_4: '0.18',
        },
        {
          responsibility: 'Reviewer',
          metric_1: '164',
          metric_2: '1844',
          metric_3: '11.2',
          metric_4: '0.21',
        },
        {
          responsibility: 'Reviewer',
          metric_1: '197',
          metric_2: '2103',
          metric_3: '10.6',
          metric_4: '0.18',
        },
      ];

      const pastStudiesData = organizationUserTableDetails.past_studies.map((row, index) => ({
        study_name: row.study_name,
        ...pastStudiesDummyData[index % pastStudiesDummyData.length],
      }));

      this.$set(this.detailsData, uuid, [
        [
          {
            type: 'presentation',
            header: profileHeader,
            columns: profileColumns,
            data: profileData,
          },
          {
            type: 'text',
            header: reviewHeader,
            data: reviewData,
          },
        ],
        [
          {
            type: 'resizable',
            header: activeStudiesHeader,
            columns: activeStudiesColumns,
            data: activeStudiesData,
          },
          {
            type: 'resizable',
            header: pastStudiesHeader,
            columns: pastStudiesColumns,
            data: pastStudiesData,
          },
        ],
      ]);
    } catch (error) {
      console.error(error);
    }
  }

  onSelectedColumnsChange(columns: DashboardDataTableColumn[]) {
    this.dynamicColumns = columns;
  }

  onActionClick(type: string, item: any) {
    switch (type) {
      case 'organization-setup':
        this.$router.push(`/organization/${this.uuid}/${type}`);
        break;
      case 'add-study':
        this.$router.push(`/add-study/${this.uuid}/${item.uuid}`);
        break;
      // default:
      // console.debug('ACTION!', type, item);
    }
  }

  onEdit(item: User) {
    this.$router.push(`/user-profile/${item.uuid}/${this.uuid}`);
  }

  onDetailsBarExpand(isExpanded: boolean) {
    this.isDetailsBarExpanded = isExpanded;
  }

  isItemEditable(item: OrganizationUserTableRow) {
    return item.status !== 'invited';
  }

  isItemExpandable(item: OrganizationUserTableRow) {
    return item.status !== 'invited';
  }

  onExpand(item: OrganizationUserTableRow) {
    this.getDetails(item.uuid);
  }

  async onExpandAll() {
    for (const item of this.filteredData) {
      if (!this.isItemExpandable(item)) continue;
      await this.getDetails(item.uuid);
    }
  }
}
