var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-input flex-column form-field-upload",class:{
    'v-input--is-disabled': _vm.control.isDisabled,
    'v-input--dense': _vm.control.isSmall,
  }},[(_vm.control.label)?_c('div',{staticClass:"v-input__prepend-outer"},[_c('label',{attrs:{"for":_vm.control.uniqueId}},[_vm._v(_vm._s(_vm.label))]),(_vm.control.helpText)?_c('v-icon',{staticClass:"form-field-label__help-icon",attrs:{"aria-label":_vm.control.helpText},on:{"click":function($event){return _vm.showHelpTooltip(_vm.control.helpText, $event)}}},[_vm._v(" $help ")]):_vm._e()],1):_vm._e(),_c('input',{ref:"inputField",staticClass:"form-field-upload__file-input",attrs:{"id":_vm.control.uniqueId,"name":_vm.control.name,"multiple":_vm.control.isMultiple,"accept":_vm.control.acceptType,"disabled":_vm.control.isDisabled,"data-cy":_vm.control.name,"type":"file"},on:{"change":_vm.onUpload}}),(_vm.isDropZoneVisible)?_c('div',{ref:"formField",staticClass:"d-flex justify-center align-center form-field-upload__drop-zone",class:{
      'form-field-upload__drop-zone--dragged-over': _vm.isDraggedOver,
    },attrs:{"tabindex":"0"},on:{"dragenter":function($event){$event.preventDefault();_vm.isDraggedOver = true},"dragover":function($event){$event.preventDefault();_vm.isDraggedOver = true},"dragleave":function($event){$event.preventDefault();_vm.isDraggedOver = false},"drop":function($event){$event.preventDefault();return _vm.onDrop.apply(null, arguments)},"click":_vm.showBrowseDialog,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.showBrowseDialog.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.showBrowseDialog.apply(null, arguments)}]}},[_c('div',{staticClass:"form-field-upload__drop-zone-label"},[_c('v-icon',[_vm._v(_vm._s(_vm.dropZoneIcon))]),_c('span',[_vm._v(_vm._s(_vm.dropZoneLabel))])],1)]):_vm._e(),(_vm.control.subLabel)?_c('div',{staticClass:"form-field-upload__details"},[_c('div',{staticClass:"v-messages"},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(_vm.control.subLabel)+" ")])])])]):_vm._e(),(_vm.uploadResults.length)?_c('div',{staticClass:"form-field-upload__results"},[_c('label',[_vm._v("Uploaded documents")]),_c('div',{staticClass:"flex-column form-field-upload__results-list"},_vm._l((_vm.uploadResults),function(uploadResult,index){return _c('div',{key:("upload-results-" + index),staticClass:"justify-space-between form-field-upload__result"},[_c('div',{staticClass:"form-field-upload__result-icon",class:{
            'form-field-upload__result--is-error': !uploadResult.uploadId,
          }},[_c('v-icon',[_vm._v(_vm._s(uploadResult.uploadId ? '$uploaded-document' : '$error'))])],1),_c('div',{staticClass:"flex-grow-1 align-center form-field-upload__result-name",class:{
            'form-field-upload__result--is-error': !uploadResult.uploadId,
          }},[(uploadResult.uploadId)?_c('a',{attrs:{"href":("/download/" + (uploadResult.uploadId)),"target":"_blank"}},[_vm._v(" "+_vm._s(uploadResult.uploadName)+" ")]):_c('span',[_vm._v(" "+_vm._s(uploadResult.uploadName)+" "),_c('small',[_vm._v("("+_vm._s(uploadResult.uploadError)+")")])])]),_c('div',{staticClass:"form-field-upload__result-remove"},[_c('BaseButton',{attrs:{"disabled":_vm.control.isDisabled,"type":"icon-light"},on:{"click":function($event){return _vm.removeUpload(uploadResult, index)}}},[_c('v-icon',[_vm._v("$delete")])],1)],1)])}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }