













































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { StudyReviewsType } from '@/app/shared/enums/study-reviews-type.enum';
import { StudyReviewsItem as StudyReviewsItemType } from '@/app/shared/models/StudyReviewsItem';
import { ModuleProperties } from '@/app/shared/models/Module';
import api from '@/app/shared/api';

const ModulePropertiesStore = namespace('ModuleProperties');

@Component
export default class StudyReviewsItem extends Vue {
  @Prop() item: StudyReviewsItemType;
  @Prop() title: string;
  @Prop() type: StudyReviewsType;

  @ModulePropertiesStore.Getter
  moduleProperties: ModuleProperties;

  get itemTitle() {
    return this.item?.name ?? this.title;
  }

  get attributes() {
    let icon = 'templates';
    let color = '185264';
    const enabledTextColor = 'var(--v-white)';

    switch (this.type) {
      case StudyReviewsType.SUMMARY_REPORT:
        icon = 'summary-report-workspace';
        color = '185264';
        break;
      case StudyReviewsType.FULL_REPORT:
        icon = 'reports-icon';
        color = '185264';
        break;
      case StudyReviewsType.PUBLIC_CONSULTATION:
        icon = 'public-consultation';
        color = '4c4285';
        break;
      case StudyReviewsType.COMMENTS_OVERVIEW:
        icon = 'comments-overview';
        color = '4c4285';
        break;
      case StudyReviewsType.TECHNICAL_REPORT:
        icon = 'technical-review';
        color = '4c4285';
        break;
      case StudyReviewsType.DECISIONS:
        icon = 'decisions';
        color = '107aa1';
        break;
      case StudyReviewsType.QUESTIONNAIRE:
        icon = 'questionnaire';
        color = '05808a';
        break;
    }

    if (!this.isEnabled) icon = `${icon}-disabled`;

    return {
      icon,
      color,
      enabledTextColor,
    };
  }

  get isEnabled() {
    return this.item?.state?.some((state) => state.active);
  }

  get isDownloadEnabled() {
    return this.item?.slug == '130-RD-decisions-screening' ||
      (this.item?.reports?.slice(-1)[0] && this.item?.reports?.slice(-1)[0].status == 'completed')
      ? true
      : false;
  }

  get hasSideBadge() {
    return this.item?.state?.some((state) => !state.hasPdf);
  }

  get isUpdated() {
    return this.item?.state?.some((state) => state.updated);
  }

  itemClick() {
    if (this.item.url) this.$router.push(this.item.url);
  }

  async sideBadgeClick() {
    try {
      if (this.item?.reports?.slice(-1)[0] && this.item?.reports?.slice(-1)[0].uuid) {
        const res = await api.reports.downloadPdf(this.item?.reports?.slice(-1)[0].uuid);

        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `${this.item?.reports?.slice(-1)[0].name}.pdf`);
        link.click();

        window.URL.revokeObjectURL(url);
      } else if (typeof this.item !== 'undefined') {
        if (this.item.url.split('/')[1] == 'reports') {
          const templateUuid = this.item.url.split('/')[3];

          const res = await api.reports.renderDirectPdf(
            this.moduleProperties.study_uuid,
            templateUuid,
            'all',
            'screening',
            'full-report',
            'final',
          );

          const today = new Date().toISOString().slice(0, 10);
          const name =
            this.moduleProperties.study_short_name.replace(/\s+/g, '_') +
            '_' +
            today +
            '_' +
            this.item.name.replace(/\s+/g, '_') +
            '.pdf';

          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');

          link.href = url;
          link.setAttribute('download', `${name}`);
          link.click();

          window.URL.revokeObjectURL(url);
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
