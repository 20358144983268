import authRepository from './repositories/auth';
import checktreeRepository from './repositories/checktree';
import commentsRepository from './repositories/comments';
import organizationRepository from './repositories/organization';
import matrixRepository from './repositories/matrix';
import reportsRepository from './repositories/reports';
import libraryRepository from './repositories/library';
import workspaceRepository from './repositories/workspace';
import workflowRepository from './repositories/workflow';
import calculationRepository from './repositories/calculations';
import userRepository from './repositories/user';
import personRepository from './repositories/person';
import presentationsRepository from './repositories/presentations';
import optionsRepository from './repositories/options';
import studyRepository from './repositories/study';
import templatesRepository from './repositories/templates';
import moduleRepository from './repositories/module';
import fileRepository from './repositories/file';
import versioningRepository from './repositories/versioning';
import howToUseRepository from './repositories/how_to_use';

export default {
  auth: authRepository,
  checktree: checktreeRepository,
  comments: commentsRepository,
  organization: organizationRepository,
  matrix: matrixRepository,
  reports: reportsRepository,
  library: libraryRepository,
  workspace: workspaceRepository,
  workflow: workflowRepository,
  calculations: calculationRepository,
  user: userRepository,
  person: personRepository,
  presentations: presentationsRepository,
  options: optionsRepository,
  study: studyRepository,
  templates: templatesRepository,
  module: moduleRepository,
  file: fileRepository,
  versioning: versioningRepository,
  how_to_use: howToUseRepository,
};
