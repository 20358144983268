







































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import isYesterday from 'dayjs/plugin/isYesterday';

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(isYesterday);

@Component
export default class BaseDropdown extends Vue {
  @Prop() items: { text: string; value: string | number }[];
  @Prop() date: string;
  @Prop() disabled: boolean;
  isExpanded: boolean = false;
  dateFormat = 'YYYY-MM-DD';

  @Emit()
  select(_value: string) {
    //
  }

  get label() {
    const now = dayjs();
    const date = dayjs(this.date, this.dateFormat);
    if (now.year() > date.year()) return date.format('D MMMM YYYY');
    if (now.diff(date, 'day') > 1) return date.format('dddd, D MMMM');
    if (date.isYesterday()) return 'Yesterday';
    return 'Today';
  }
}
