import Vue from 'vue';
import { VueFormBuilderPlugin } from 'v-form-builder';
import FormControlAssessmentTeam from '@/app/shared/components/form/control/FormControlAssessmentTeam';
import FormControlAssessor from '@/app/shared/components/form/control/FormControlAssessor';
import FormControlAuthority from '@/app/shared/components/form/control/FormControlAuthority';
import FormControlAvatar from '@/app/shared/components/form/control/FormControlAvatar';
import FormControlButton from '@/app/shared/components/form/control/FormControlButton';
import FormControlCaptcha from '@/app/shared/components/form/control/FormControlCaptcha';
import FormControlCheckbox from '@/app/shared/components/form/control/FormControlCheckbox';
import FormControlCheckTree from '@/app/shared/components/form/control/FormControlCheckTree';
import FormControlComment from '@/app/shared/components/form/control/FormControlComment';
import FormControlDate from '@/app/shared/components/form/control/FormControlDate';
import FormControlDivider from '@/app/shared/components/form/control/FormControlDivider';
import FormControlEvent from '@/app/shared/components/form/control/FormControlEvent';
import FormControlFacility from '@/app/shared/components/form/control/FormControlFacility';
import FormControlInvestor from '@/app/shared/components/form/control/FormControlInvestor';
import FormControlLocation from '@/app/shared/components/form/control/FormControlLocation';
import FormControlOrganization from '@/app/shared/components/form/control/FormControlOrganization';
import FormControlOrganizationUnit from '@/app/shared/components/form/control/FormControlOrganizationUnit';
import FormControlPassword from '@/app/shared/components/form/control/FormControlPassword';
import FormControlRole from '@/app/shared/components/form/control/FormControlRole';
import FormControlSelect from '@/app/shared/components/form/control/FormControlSelect';
import FormControlText from '@/app/shared/components/form/control/FormControlText';
import FormControlTextarea from '@/app/shared/components/form/control/FormControlTextarea';
import FormControlUpload from '@/app/shared/components/form/control/FormControlUpload';
import FormControlUploadInline from '@/app/shared/components/form/control/FormControlUploadInline';
import confirmPassword from '@/app/shared/components/form/validator/confirm-password';
import differentPassword from '@/app/shared/components/form/validator/different-password';
import emailAddress from '@/app/shared/components/form/validator/email-address';
import maxFiveWords from '@/app/shared/components/form/validator/max-five-words';
import maxFiveWordsObjectName from '@/app/shared/components/form/validator/max-five-words-object-name';
import passwordPolicy from '@/app/shared/components/form/validator/password-policy';
import requiredObjectName from '@/app/shared/components/form/validator/required-object-name';
import requiredObjectProjectName from '@/app/shared/components/form/validator/required-object-project-name';
import requiredObjectResponsibilityUserId from '@/app/shared/components/form/validator/required-object-responsibility-user-id';
import requiredObjectTitle from '@/app/shared/components/form/validator/required-object-title';
import requiredValue from '@/app/shared/components/form/validator/required-value';
import validNumber from '@/app/shared/components/form/validator/valid-number';
import validObjectEmail from '@/app/shared/components/form/validator/valid-object-email';

// Define all custom form controls.
const controls = {
  FormControlAssessmentTeam,
  FormControlAssessor,
  FormControlAuthority,
  FormControlAvatar,
  FormControlButton,
  FormControlCaptcha,
  FormControlCheckbox,
  FormControlCheckTree,
  FormControlComment,
  FormControlDate,
  FormControlDivider,
  FormControlEvent,
  FormControlFacility,
  FormControlInvestor,
  FormControlLocation,
  FormControlOrganization,
  FormControlOrganizationUnit,
  FormControlPassword,
  FormControlRole,
  FormControlSelect,
  FormControlText,
  FormControlTextarea,
  FormControlUpload,
  FormControlUploadInline,
};

// Disable all built-in form controls, as they are incompatible with our UI toolkit (Bootstrap !== Vuetify).
const disableControls = [
  'input',
  'number',
  'text',
  'date',
  'dropDown',
  'checkbox',
  'radio',
  'fileUploader',
  'label',
  'button',
  'emptyBlock',
  'textBlock',
];

// Define all custom validation closures.
const validationClosures = {
  confirmPassword,
  differentPassword,
  emailAddress,
  maxFiveWords,
  maxFiveWordsObjectName,
  passwordPolicy,
  requiredObjectName,
  requiredObjectProjectName,
  requiredObjectResponsibilityUserId,
  requiredObjectTitle,
  requiredValue,
  validNumber,
  validObjectEmail,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = {
  CONTAINER: {
    FLUID: 'container-fluid container--fluid',
    NORMAL: 'container',
  },

  ROW: 'row',

  COLUMNS: {
    COL1: 'col-md-1',
    COL2: 'col-md-2',
    COL3: 'col-md-3',
    COL4: 'col-md-4',
    COL5: 'col-md-5',
    COL6: 'col-md-6',
    COL7: 'col-md-7',
    COL8: 'col-md-8',
    COL9: 'col-md-9',
    COL10: 'col-md-10',
    COL11: 'col-md-11',
    COL12: 'col-md-12',
    NARROW_FULL: 'col-md-10 offset-md-1 col-lg-8 offset-lg-2',
    NARROW_HALF_LEFT: 'col-md-5 offset-md-1 col-lg-4 offset-lg-2',
    NARROW_HALF_RIGHT: 'col-md-5 col-lg-4',
  },

  /**
   * List Group Classes - Used in AddSectionVueControl
   */
  LIST_GROUP: {
    CONTAINER: 'list-group v-item-group v-list-item-group', // div.list-group
    SINGLE_ITEM: 'list-group-item list-group-item-action v-list-item v-list-item--link', //a[href=...].list-group-item.list-group-item-action
  },

  /**
   * Button Classes
   */
  BUTTON: {
    PRIMARY: 'btn btn-primary v-btn primary',
    SECONDARY: 'btn btn-secondaryv-btn secondary',
    DEFAULT: 'btn btn-default v-btn default',
    SUCCESS: 'btn btn-success v-btn success',
    DANGER: 'btn btn-danger v-btn error',
    WARNING: 'btn btn-warning v-btn warning',
    INFO: 'btn btn-info v-btn info',
  },

  /**
   * Form Classes
   */
  FORM: {
    FORM_GROUP: 'form-group v-input',
    INPUT_GROUP: 'input-group v-input__control',
    FORM_CONTROL: 'form-control',
    ERROR_OUTLINE: 'is-invalid v-input--has-state error--text',
    ERROR_MESSAGE: 'invalid-feedback v-input--has-state error--text',
  },

  /**
   * Table Classes
   */
  TABLE: {
    TABLE_CLASS: 'table v-data-table',
    TR_CLASS: '',
    TD_CLASS: '',
  },
};

const options = {
  globalInjection: false,
  controls,
  disableControls,
  styles,
  // This will be ignored due to a bug in VueFormBuilder.
  //   Check for an explicit override in FormRenderer component.
  //   More information here: https://github.com/sethsandaru/vue-form-builder/issues/113
  validationErrorShowAlert: false,

  // This will be ignored due to a bug in VueFormBuilder.
  //   Check for an explicit override in FormRenderer component.
  //   More information here: https://github.com/sethsandaru/vue-form-builder/issues/115
  validationClosures,
};

Vue.use(VueFormBuilderPlugin, options);

export { validationClosures };
