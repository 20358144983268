var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-system-bar',{staticClass:"main-navbar p-y-half p-x-1",class:{
    'main-navbar--has-blue-background': _vm.hasBlueBackground,
  },attrs:{"data-cy":"top-bar","app":""}},[(_vm.userHome)?_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":_vm.userHome,"data-cy":"landing-button"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo32.svg"),"alt":"envigo logo"}})]):_vm._e(),(_vm.isAuthenticated)?_c('div',{staticClass:"links d-flex"},[_c('BaseButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.Workspace),expression:"Workspace"}],attrs:{"icon":"workspace-solid","type":"sen","data-cy":"workspace-button"},on:{"click":function($event){return _vm.goTo('Workspace')}}},[_vm._v(" Workspace ")]),_c('BaseButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.StudyReviews),expression:"StudyReviews"}],attrs:{"icon":"study-reviews-solid","type":"sen","data-cy":"study-reviews-button"},on:{"click":function($event){return _vm.goTo('StudyReviews')}}},[_vm._v(" Study Reviews ")]),_c('BaseButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.StudiesDashboard),expression:"StudiesDashboard"}],attrs:{"icon":"studies-solid","type":"sen","data-cy":"studies-dashboard-button"},on:{"click":function($event){return _vm.goTo('StudiesDashboard')}}},[_vm._v(" Studies ")]),_c('v-menu',{attrs:{"close-on-content-click":false,"content-class":"callout","bottom":"","left":"","nudge-bottom":"40","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('BaseButton',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.StudyDetails),expression:"StudyDetails"}],attrs:{"type":"icon-sen","data-cy":"study-details-button"}},'BaseButton',attrs,false),on),[_c('v-icon',[_vm._v("$project")])],1)]}}],null,false,1436655111),model:{value:(_vm.isStudyCalloutVisible),callback:function ($$v) {_vm.isStudyCalloutVisible=$$v},expression:"isStudyCalloutVisible"}},[_c('NavbarCallout',{attrs:{"text":_vm.studyName,"button-text":_vm.StudyDetailsButton,"subtitle":"Study name"},on:{"goto":function($event){return _vm.goTo('StudyDetails')}},model:{value:(_vm.isStudyCalloutVisible),callback:function ($$v) {_vm.isStudyCalloutVisible=$$v},expression:"isStudyCalloutVisible"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"content-class":"callout","bottom":"","left":"","nudge-bottom":"40","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('BaseButton',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.OrganizationUsersDashboard),expression:"OrganizationUsersDashboard"}],attrs:{"type":"icon-sen","data-cy":"organization-users-dashboard-button"}},'BaseButton',attrs,false),on),[_c('v-icon',[_vm._v("$company")])],1)]}}],null,false,3439358440),model:{value:(_vm.isOrganizationCalloutVisible),callback:function ($$v) {_vm.isOrganizationCalloutVisible=$$v},expression:"isOrganizationCalloutVisible"}},[_c('NavbarCallout',{attrs:{"text":_vm.userOrganizationName,"button-text":_vm.OrganizationDetailsButton,"subtitle":"Organization / institution name"},on:{"goto":function($event){return _vm.goTo('OrganizationUsersDashboard')}},model:{value:(_vm.isOrganizationCalloutVisible),callback:function ($$v) {_vm.isOrganizationCalloutVisible=$$v},expression:"isOrganizationCalloutVisible"}})],1),_c('BaseBadge',{directives:[{name:"show",rawName:"v-show",value:(_vm.StudyVersionBadge),expression:"StudyVersionBadge"}],attrs:{"id":_vm.activeStudyVersion,"size":"large","data-cy":"study-version-badge"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"content-class":"callout","bottom":"","left":"","nudge-bottom":"40","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('UserAvatar',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.user),expression:"user"}],attrs:{"data-uri":_vm.userAvatar,"name":_vm.userFullName,"has-avatar-initials":_vm.hasAvatarInitials,"size":"medium","data-cy":"user-avatar"}},'UserAvatar',attrs,false),on))]}}],null,false,3943161121),model:{value:(_vm.isUserCalloutVisible),callback:function ($$v) {_vm.isUserCalloutVisible=$$v},expression:"isUserCalloutVisible"}},[_c('UserCallout',{attrs:{"border-radius":"6px"},on:{"goto":function($event){return _vm.goTo('UserProfileForm')},"logout":_vm.logoutUser},model:{value:(_vm.isUserCalloutVisible),callback:function ($$v) {_vm.isUserCalloutVisible=$$v},expression:"isUserCalloutVisible"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }