

























































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import VersioningStar from './VersioningStar.vue';
import { VersioningItem } from '@/app/shared/models/Versioning';

@Component({
  components: {
    VersioningStar,
  },
})
export default class VersioningItemContent extends Vue {
  @Prop() versioningItem: VersioningItem;
  @Prop({ default: false, type: Boolean }) isEditAllowed: boolean;
  @Prop({ default: false, type: Boolean }) isToolbarAllowed: boolean;
  @Prop({ default: false, type: Boolean }) isRestoreAllowed: boolean;
  isEditingName: boolean = false;
  isEditingComment: boolean = false;
  isCommentVisible: boolean = false;

  @Emit('update:name')
  updateName(_newValue: string) {
    //
  }

  @Emit('update:comment')
  updateComment(_newValue: string) {
    //
  }

  @Emit('toggle-important')
  toggleImportant() {
    //
  }

  @Emit('toggle-authority')
  toggleAuthority() {
    //
  }

  @Emit('toggle-public')
  togglePublic() {
    //
  }

  @Emit('restore')
  restore() {
    //
  }

  get isActive() {
    return this.versioningItem.is_active;
  }

  get isAuthority() {
    return this.versioningItem.is_authority;
  }

  get isPublic() {
    return this.versioningItem.is_public;
  }

  get isImportant() {
    return this.versioningItem.is_important;
  }

  get isExpanded() {
    return this.versioningItem.is_expanded;
  }

  editName() {
    if (!this.isEditAllowed) return false;
    this.isEditingName = true;
    this.$nextTick(() => this.$refs.editName && (this.$refs.editName as Vue & { focus: () => void }).focus());
  }

  editComment() {
    if (!this.isEditAllowed) return false;
    this.isEditingComment = true;
    this.$nextTick(() => this.$refs.editComment && (this.$refs.editComment as Vue & { focus: () => void }).focus());
  }

  exitEditComment() {
    this.isEditingComment = false;
    if (!this.versioningItem.comment) this.isCommentVisible = !this.isCommentVisible;
  }

  toggleComment() {
    this.isCommentVisible = !this.isCommentVisible;
    if (this.isCommentVisible && !this.versioningItem.comment) this.editComment();
  }
}
