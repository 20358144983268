


















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import PageHeader from '@/app/shared/components/PageHeader.vue';
import UtilService from '@/app/shared/utils/util.service';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

const UiStore = namespace('UI');
const UserStore = namespace('User');

@Component({
  components: { PageHeader },
})
export default class ReportsPageHeader extends Vue {
  @Prop() htu: boolean;
  @Prop() title: string;
  @Prop() isAllExpanded: boolean;
  @Prop() isCommentingMode: boolean;
  @Prop({ default: false, type: Boolean }) isEditModeAllowed: boolean;

  @Emit('resume')
  resume() {
    // emiting event
  }

  @Emit('downloadChapter')
  downloadChapter() {
    // emiting event
  }

  @Emit('downloadAll')
  downloadAll() {
    // emiting event
  }

  @Emit()
  toggleExpandAll(_shouldExpand: boolean) {
    // just emiting event
  }

  @UiStore.Getter
  isLeftSidebarExpanded!: boolean;

  @UiStore.Getter
  isFooterCommentingActive: boolean;

  @UiStore.Mutation
  setFooterCommentingActive!: (value: boolean) => void;

  @UiStore.Mutation
  setFooterLibraryActive!: (value: boolean) => void;

  @UiStore.Mutation
  setFooterTrackingActive!: (value: boolean) => void;

  @UiStore.Mutation
  setFooterVersioningActive!: (value: boolean) => void;

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  @AsyncComputed()
  async isCommentingAllowed() {
    return await this.isAllowed(UserPermission.COMMENTING_BAR_SHOW);
  }

  get xOffset() {
    return this.isLeftSidebarExpanded ? UtilService.calculateSidebarWidth() : 56;
  }

  toggleCommentingSidebar() {
    if (!this.isFooterCommentingActive) {
      this.setFooterLibraryActive(false);
      this.setFooterTrackingActive(false);
      this.setFooterVersioningActive(false);
    }

    this.setFooterCommentingActive(!this.isFooterCommentingActive);
  }
}
