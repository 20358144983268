














































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import _debounce from 'lodash.debounce';
const UiStore = namespace('UI');

@Component({
  components: {},
})
export default class Sidebar extends Vue {
  @Prop()
  title!: string;
  @Prop({ default: 240 })
  defaultWidth: number;
  @Prop({ default: false })
  shouldExpandOverlapContent: boolean;
  @Prop({ default: false, type: Boolean })
  defaultShown: boolean;
  readonly collapsedWidth = 56;
  // Prop has default value
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  readonly expandedWidth = this.defaultWidth;
  readonly maxWidth = 648;
  // reference to main html element is needed because of changing paddingLeft style on resize
  mainRef: HTMLElement;

  navigation = {
    shown: false,
    // Prop has default value
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    width: this.defaultShown ? this.expandedWidth : this.collapsedWidth,
  };

  @UiStore.Action
  public toggleLeftSidebar!: (isExpanded: boolean) => void;

  created() {
    if (this.defaultShown) {
      setTimeout(() => {
        this.toggleSidebar();
      }, 300);
    }
  }

  mounted() {
    // this.setBorderWidth();
    this.mainRef = document.querySelector('main');
  }

  @Emit('resize')
  public emitResizeEvent() {
    //
  }

  toggleSidebar() {
    if (this.navigation.shown) {
      this.navigation.shown = false;
      this.navigation.width = this.collapsedWidth;
      this.toggleLeftSidebar(false);
    } else {
      this.navigation.shown = true;
      this.navigation.width = this.expandedWidth;
      this.toggleLeftSidebar(true);

      setTimeout(() => {
        this.setEvents();
      }, 0);
    }
    this.mainRef.style.paddingLeft = this.navigation.width + 'px';
  }

  setEvents() {
    const el = (this.$refs.drawer as any).$el;
    const resizeGrabber = el.querySelector('.resize-grabber');
    const direction = el.classList.contains('v-navigation-drawer--right') ? 'right' : 'left';

    const resize = (e: any) => {
      document.body.style.cursor = 'ew-resize';
      const drawerWidth = direction === 'right' ? document.body.scrollWidth - e.clientX : e.clientX;
      // el.style.width = drawerWidth + 'px';
      if (this.navigation.shown && drawerWidth >= this.expandedWidth && drawerWidth <= this.maxWidth) {
        el.style.width = drawerWidth + 'px';
        if (!this.shouldExpandOverlapContent) {
          this.mainRef.style.paddingLeft = drawerWidth + 'px';
        }
        // this.setLeftSidebarWidth(drawerWidth);
      }
      this.emitResizeEvent();
    };

    const debounceResize = _debounce(resize, 0);

    resizeGrabber.addEventListener(
      'mousedown',
      (e: any) => {
        if (e.preventDefault) e.preventDefault();
        // this.toggleResizingLeftSidebar();
        this.mainRef.style.transition = 'initial';
        el.style.transition = 'initial';
        document.addEventListener('mousemove', debounceResize, false);
      },
      false,
    );

    document.addEventListener(
      'mouseup',
      () => {
        el.style.transition = '';
        this.mainRef.style.transition = '';
        document.body.style.cursor = '';

        // this.toggleResizingLeftSidebar();
        document.removeEventListener('mousemove', debounceResize, false);
      },
      false,
    );
  }

  destroyed() {
    this.toggleLeftSidebar(false);
  }
}
