



















import { Component, Mixins, Vue } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import FormFieldFocus from '@/app/shared/components/form/field/mixins/FormFieldFocus.vue';
import FormFieldSubmit from '@/app/shared/components/form/field/mixins/FormFieldSubmit.vue';

@Component({})
export default class FormFieldCheckbox extends Mixins(Vue, FormFieldFocus, FormFieldSubmit, BaseControlSkeleton) {
  control: {
    uniqueId: string;
    isDisabled: boolean;
    disabledOnValue: string;
  };

  get isDisabled() {
    if (this.control.isDisabled) return true;
    if (this.control.disabledOnValue) {
      const valueContainer = JSON.parse(JSON.stringify(this.$attrs['value-container'])); // NB: why?!?
      return (
        valueContainer &&
        valueContainer.hasOwnProperty(this.control.disabledOnValue) &&
        Boolean(valueContainer[this.control.disabledOnValue as any])
      );
    }
    return false;
  }

  focus() {
    const inputElement = document.querySelector(`#${this.control.uniqueId}`) as HTMLInputElement;
    if (inputElement) inputElement.focus();
  }
}
