var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-input d-flex flex-column form-field-comment",class:{
    'form-field-comment--supports-resizer': _vm.$browserDetect.isChrome || _vm.$browserDetect.isSafari,
  }},[(!_vm.hasComment)?_c('BaseButton',{staticClass:"form-field-comment__add-comment-button",attrs:{"disabled":_vm.control.isDisabled,"icon":"comment","type":"quin"},on:{"click":_vm.addComment}},[_vm._v(" "+_vm._s(_vm.control.label || 'Add Comment')+" ")]):[(_vm.control.label)?_c('div',{staticClass:"v-input__prepend-outer"},[_c('label',{attrs:{"for":_vm.control.uniqueId}},[_vm._v(_vm._s(_vm.label))]),(_vm.control.helpText)?_c('v-icon',{staticClass:"form-field-label__help-icon",attrs:{"aria-label":_vm.control.helpText},on:{"click":function($event){return _vm.showHelpTooltip(_vm.control.helpText, $event)}}},[_vm._v(" $help ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"d-flex flex-row form-field-comment__horizontal-container"},[(_vm.value.isRichText)?_c('div',{directives:[{name:"safe-html",rawName:"v-safe-html",value:(_vm.value.text),expression:"value.text"}],ref:"richTextPreview",staticClass:"flex-grow-1 form-field-comment__rich-text-preview"}):_c('FormFieldTextarea',{ref:"formField",attrs:{"value":_vm.value.text,"control":{
          uniqueId: _vm.control.uniqueId,
          name: _vm.control.name,
          subLabel: _vm.control.subLabel,
          placeholderText: _vm.control.placeholderText,
          isDisabled: _vm.control.isDisabled,
          isReadonly: _vm.control.isReadonly,
          isSmall: _vm.control.isSmall,
        }},on:{"change":_vm.commentUpdateValue}}),_c('div',{staticClass:"d-flex flex-column form-field-comment__vertical-container"},[_c('BaseButton',{staticClass:"form-field-comment__text-editor-button",attrs:{"id":_vm.value.isRichText ? _vm.control.uniqueId : null,"type":"icon-light"},on:{"click":_vm.showTextEditor}},[_c('v-icon',[_vm._v("$texteditor")])],1),_c('v-dialog',{attrs:{"width":"52%","content-class":"tiny-editor-dialog"},model:{value:(_vm.isTextEditorVisible),callback:function ($$v) {_vm.isTextEditorVisible=$$v},expression:"isTextEditorVisible"}},[_c('v-card',[_c('TinyEditorModal',{attrs:{"value":_vm.value.text,"active":_vm.isTextEditorVisible,"output-format":"html"},on:{"close":_vm.hideTextEditor,"input":function($event){return _vm.commentUpdateValue($event, true)}}})],1)],1),(_vm.control.isRemovable)?_c('BaseButton',{staticClass:"form-field-comment__delete-comment-button",attrs:{"type":"icon-light"},on:{"click":_vm.deleteComment}},[_c('v-icon',[_vm._v("$delete")])],1):_vm._e()],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }