import {
  PresentationsTabType,
  PresentationsTableType,
  PresentationsChartsType,
  PresentationsStatsType,
} from './../../types/presentations.types';
import { ChapterItemType } from './../../enums/chapter-item-type.enum';
import { PresentationsSidebarOption } from '@/app/shared/models/presentations/PresentationsSidebarOption';
export class LibraryParagraph {
  type: ChapterItemType;
  level: number;
  order: number;
  parent_id: number;
  title: string;
  text: string;
  help: string;
  html: string;
  selection: any;
  landscape: boolean;
  file_id: number;
  file_ids?: number[];
  options?: {
    selectedOptions: PresentationsSidebarOption[];
    activeTab: PresentationsTabType;
    activeTabType: PresentationsTableType | PresentationsChartsType | PresentationsStatsType;
  };
  study_id: number;
  payload: any;
  data: any;

  constructor(type: ChapterItemType, html?: string, selection?: any, title?: string) {
    this.type = type;
    this.html = html;
    this.selection = selection; // @TODO Selection should be removed, right?
    this.title = title;
  }

  get itemFileIds() {
    return this.file_ids;
  }

  set itemFileIds(fileIds: number[]) {
    this.file_ids = fileIds;
  }

  get itemPayload() {
    return this.payload;
  }

  set itemPayload(payload: any) {
    this.payload = payload;
  }

  get itemData() {
    return this.payload;
  }

  set itemData(data: any) {
    this.data = data;
  }
}
