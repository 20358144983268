













































import { Component, Mixins, Prop } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import DashboardPageHeader from '@/app/shared/components/dashboard/DashboardPageHeader.vue';
import DashboardSidebar from '@/app/shared/components/dashboard/DashboardSidebar.vue';
import DashboardDataTable from '@/app/shared/components/dashboard/DashboardDataTable.vue';
import DashboardFiltering from '@/app/shared/components/mixins/DashboardFiltering.vue';
import DashboardDynamicColumns from '@/app/shared/components/mixins/DashboardDynamicColumns.vue';
import PageFooter from '@/app/shared/components/PageFooter.vue';
import { DashboardDataTableAction, DashboardDataTableColumn } from '@/app/shared/models/DashboardDataTable';
import { DashboardDataTableColumnType } from '@/app/shared/enums/dashboard-data-table.enum';
import api from '@/app/shared/api';
import { Organization, OrganizationTableRow } from '@/app/shared/models/Organization';
import { User } from '@/app/shared/models/User';
import { Table } from '@/app/shared/models/Table';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

const UserStore = namespace('User');

@Component({
  components: {
    DashboardPageHeader,
    DashboardSidebar,
    DashboardDataTable,
    PageFooter,
  },
})
export default class OrganizationsDashboard extends Mixins(DashboardFiltering, DashboardDynamicColumns) {
  @Prop() uuid: string;
  organizationTypeIdLookup: Record<number, string> = null;
  tableData: OrganizationTableRow[] = null;
  detailsData: Record<string, Table[][]> = {};
  fixedColumns: DashboardDataTableColumn[] = [
    {
      text: 'Name',
      value: 'name',
      type: DashboardDataTableColumnType.TITLE,
      resizable: true,
    },
  ];
  dynamicColumns: DashboardDataTableColumn[] = [];
  activeFilters: Record<keyof User, string[]> = null;
  actionConfig: DashboardDataTableAction[] = [
    {
      type: 'saas-details',
      icon: 'licence',
      label: 'SaaS Details',
      style: 'secondary',
      highlightColor: 'var(--v-blue-pale)',
      solidColor: 'var(--v-blue-primary)',
    },
    {
      type: 'invite-new-user',
      icon: 'add-user',
      label: 'Invite new user',
      style: 'secondary',
      highlightColor: 'var(--v-blue-pale)',
      solidColor: 'var(--v-blue-primary)',
    },
    {
      type: 'organization-users',
      icon: 'edit-24px',
      label: 'Edit Users',
      style: 'primary',
    },
  ];
  isDetailsBarExpanded: boolean = false;

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  @AsyncComputed()
  async createButton() {
    return (await this.isAllowed(UserPermission.CREATE_ORGANIZATION_FORM_ACCESS))
      ? {
          icon: 'add-company',
          label: 'Create Organization',
          route: '/organization/new/organization-create',
        }
      : null;
  }

  @AsyncComputed()
  async userActionColumns(): Promise<DashboardDataTableColumn[]> {
    return [
      ...((await this.isAllowed(UserPermission.SAAS_DETAILS_FORM_ACCESS))
        ? [
            {
              text: 'Action',
              value: 'action',
              type: DashboardDataTableColumnType.ACTION,
              actionType: 'saas-details',
              width: '172px !important',
              resizable: false,
            },
          ]
        : []),
      ...((await this.isAllowed(UserPermission.INVITE_NEW_USER_ACCESS))
        ? [
            {
              text: 'Action',
              value: 'action2',
              type: DashboardDataTableColumnType.ACTION,
              actionType: 'invite-new-user',
              width: '196px !important',
              resizable: false,
            },
          ]
        : []),
      ...((await this.isAllowed(UserPermission.ORGANIZATION_USERS_DASHBOARD_ACCESS))
        ? [
            {
              text: 'Action',
              value: 'action3',
              type: DashboardDataTableColumnType.ACTION,
              actionType: 'organization-users',
              width: '172px !important',
              resizable: false,
            },
          ]
        : []),
    ];
  }

  @AsyncComputed()
  async isEditAllowed() {
    return await this.isAllowed(UserPermission.EDIT_ORGANIZATION_DETAILS_ACCESS);
  }

  get allColumns() {
    return [...this.fixedColumns, ...this.dynamicColumns, ...this.actionColumns];
  }

  get tableColumns() {
    return [...this.fixedColumns, ...this.dynamicColumns.filter((el) => el.checked), ...this.actionColumns];
  }

  get tagsColumns() {
    return [
      {
        field: 'type_id',
        name: 'Type',
        transformValue: (organization: OrganizationTableRow) => this.organizationTypeIdLookup[organization.type_id],
      },
      {
        field: 'num_users',
        name: 'Users',
      },
    ];
  }

  get headerIcon() {
    return '$company-dashboard';
  }

  get headerTitle() {
    return 'Organizations';
  }

  get filterFields() {
    return ['type_id'];
  }

  get filterItems() {
    return [
      {
        field: 'type_id',
        icon: 'company-1px',
        text: 'Type',
        items: this.tableData && this.uniqueFilterValueItems.type_id,
      },
    ];
  }

  created() {
    this.initDynamicColumns();
    this.getData();
  }

  async getData() {
    try {
      const organizationTypeOptions = await api.options.getCustomOptions('/checktree/options/OrganizationType', {
        context: {},
        key: 'id',
        value: 'title',
      });

      this.organizationTypeIdLookup = organizationTypeOptions.reduce(
        (organizationTypeIdLookup, organizationTypeOption) => ({
          ...organizationTypeIdLookup,
          [organizationTypeOption.value]: organizationTypeOption.text,
        }),
        {},
      ) as Record<number, string>;

      this.tableData = await api.organization.getOrganizations();

      this.initActiveFilters();
    } catch (error) {
      console.error(error);
    }
  }

  async getDetails(uuid: string) {
    try {
      const organizationData = await api.organization.getOrganization(uuid);

      const organizationHeader = {
        text: 'Organization Details',
        icon: 'company-1px',
      };
      const organizationColumns = [
        {
          text: 'Organization name',
          value: 'name',
        },
        {
          text: 'Headquarters',
          value: 'address',
        },
        {
          text: 'Email address',
          value: 'email',
        },
        {
          text: 'Telephone number',
          value: 'phone_number',
        },
        {
          text: 'Website address',
          value: 'website',
        },
      ];

      const licenceHeader = {
        text: 'SaaS Details',
        icon: 'licence-1px',
      };
      const licenceColumns = [
        {
          text: 'Subscription',
          value: 'subscription',
        },
        {
          text: 'Total number of studies',
          value: 'studies',
        },
        {
          text: 'Total number of users',
          value: 'users',
        },
        {
          text: 'Products',
          value: 'products_str',
        },
        {
          text: 'Expires in',
          value: 'expires_in',
        },
        {
          text: 'Studies to add',
          value: 'studies_left',
        },
        {
          text: 'Users to add',
          value: 'users_left',
        },
      ];
      const licenceData = Object.fromEntries(
        Object.entries(organizationData.saas).map(([key, value]) => {
          switch (key) {
            case 'subscription':
              if (value) value += ' month(s)';
              break;
            case 'studies':
            case 'users':
              if (value === null) value = 'Unlimited';
              break;
            case 'expires_in': {
              const [days] = (value && value.split(',')) || [];
              if (days) value = days;
              if (/^-/.test(value)) value = 'Expired';
              break;
            }
          }

          return [key, value];
        }),
      );

      const unitHeader = {
        text: 'Organization Units',
        icon: 'company-1px',
      };
      const unitColumns =
        organizationData.units &&
        organizationData.units.reduce(
          (acc, _unit, index) => [
            ...acc,
            ...[
              {
                text: 'Unit logo',
                value: `logo_${index}`,
                isImage: true,
              },
              {
                text: 'Unit name',
                value: `name_${index}`,
              },
              {
                text: 'Unit address',
                value: `address_${index}`,
              },
              {
                text: 'Unit email',
                value: `email_${index}`,
              },
              {
                text: 'Unit phone number',
                value: `phone_number_${index}`,
              },
              {
                text: 'Unit fax number',
                value: `fax_number_${index}`,
              },
            ],
          ],
          [],
        );

      const unitData =
        organizationData.units &&
        organizationData.units.reduce(
          (units, unit, index) => ({
            ...units,
            ...Object.keys(unit).reduce(
              (acc, key) => ({
                ...acc,
                [`${key}_${index}`]: (unit as any)[key],
              }),
              {},
            ),
          }),
          {},
        );

      this.$set(this.detailsData, uuid, [
        [
          {
            type: 'presentation',
            header: organizationHeader,
            columns: organizationColumns,
            data: organizationData,
          },
          ...(organizationData.saas
            ? [
                {
                  type: 'presentation',
                  header: licenceHeader,
                  columns: licenceColumns,
                  data: licenceData,
                },
              ]
            : []),
        ],
        ...(organizationData.units && organizationData.units.length
          ? [
              [
                {
                  type: 'presentation',
                  header: unitHeader,
                  columns: unitColumns,
                  data: unitData,
                },
              ],
            ]
          : []),
      ]);
    } catch (error) {
      console.warn(error);
    }
  }

  onSelectedColumnsChange(columns: DashboardDataTableColumn[]) {
    this.dynamicColumns = columns;
  }

  onActionClick(type: string, item: any) {
    switch (type) {
      case 'saas-details':
      case 'invite-new-user':
        this.$router.push(`/organization/${item.uuid}/${type}`);
        break;
      case 'organization-users':
        this.$router.push(`/organization/${item.uuid}`);
        break;
      // default:
      // console.debug('ACTION!', type, item);
    }
  }

  onEdit(item: Organization) {
    this.$router.push(`/organization/${item.uuid}/organization-setup`);
  }

  onExpand(item: OrganizationTableRow) {
    this.getDetails(item.uuid);
  }

  async onExpandAll() {
    for (const item of this.filteredData) {
      await this.getDetails(item.uuid);
    }
  }
}
