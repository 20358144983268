/**
 * ADDING NEW PERMISSIONS TO THE SYSTEM
 *
 * 1. Define permission in Permissions checktree, accessible at: /admin/checktree/f9c0cacb-cc2f-47c4-a01e-6deed016e1e9
 *   1a. Note the UUID of the newly added permission checktree item.
 * 2. Assign permission to Roles in the matrix, accessible at: /matrix-relations/657a06c0-0e9e-4219-be0b-677dab0b40eb
 * 3. Define a new constant below in a suitable group, while keeping in mind that:
 *   - It is self-descriptive and unique
 *   - It is UPPERCASE and delimited by underscores `_`
 *   - It is named `_ACCESS` for route permissions
 *   - It contains the name of the screen at the start
 *   - It contains the name of the feature at the end
 *   - Good:
 *     [+] `QUESTIONNAIRE_ADMIN_ACCESS`
 *     [+] `STUDIES_DASHBOARD_ORGANIZATION_COLUMN`
 *     [+] `VERSIONING_BAR_SHOW`
 *   - Bad:
 *     [-] `QUESTIONNAIRE_ROUTE`
 *     [-] `DELETE_BUTTON_FOR_COMMENTS`
 *     [-] `RESTORE-BUTTON`
 * 4. Set the new constant to the UUID value of the newly added permission checktree item (see 1a. above).
 * 5. Refer to the new permission via this enum (and only via this enum!) in an async method or a property, like so:
 *
 *   import AsyncComputed from 'vue-async-computed-decorator';
 *   import { namespace } from 'vuex-class';
 *   import { UserPermission } from '@/app/shared/enums/user-permission.enum';
 *
 *   const UserStore = namespace('User');
 *
 *   @UserStore.Action
 *   isAllowed: (permission: string | string[]) => Promise<boolean>;
 *
 *   @AsyncComputed()
 *   async isDeleteButtonAllowed() {
 *     return await this.isAllowed(UserPermission.PDF_OUTPUT_DELETE_BUTTON);
 *   }
 */
export enum UserPermission {
  // Add Study Form (669947a4-c5a8-43da-b134-0cb76b8f333e)
  ADD_STUDY_FORM_ACCESS = '0768ff3b-b9be-46bd-a9e2-3b934289125b',

  // Admin landing (1618108f-8a78-4586-9025-1719330abc29)
  ADMIN_LANDING_ACCESS = '02516a3a-e719-4db2-869a-3bafc6452e12',

  // Change Main Organization (1145bf0f-ef0c-441d-bf1a-a6f51f3510a1)
  CHANGE_MAIN_ORGANIZATION_ACCESS = '944c1a30-304a-4448-b963-68df3e5fa25e',

  // Checktree Admin (8940e60c-7bdf-4d0a-86a0-d1028904f98f)
  CHECKTREE_ADMIN_ACCESS = '8bfc8490-37e3-4e41-8a31-cc6b89f95131',
  CHECKTREE_ADMIN_TOOLS = '5e0f3d23-0e6a-4faa-8549-85b16a596646',

  // Checktree User (fd668aa5-b3b8-4e84-a4c2-731a58861c30)
  CHECKTREE_USER_ACCESS = '32c17985-c6b1-461e-8488-cfa5043c9fe5',
  CHECKTREE_USER_ADMIN_TOOLS = 'a13ad819-9206-4692-99b4-664b2983733a',

  // Commenting Bar (292251d3-9483-40d3-9fff-45c171d1de4d)
  COMMENTING_BAR_SHOW = '3932dfde-3bf4-4696-9953-88666eafb8ba',
  COMMENTING_BAR_EDIT_MODE = 'd3227e13-c457-423b-938e-a5cb20495ce8',
  COMMENTING_BAR_AUTHORITIES_TAB = 'c3466a2f-fdd4-4578-a474-7a8f7e8cbe00',
  COMMENTING_BAR_INTERNAL_TAB = 'ecf97323-d81c-4dc7-811d-d9d816ee972a',

  // Create Organization Form (5965230f-50bd-4ddb-8ef9-fda60c65ad32)
  CREATE_ORGANIZATION_FORM_ACCESS = '5a63d506-a20b-4af1-875a-f21481ade729',

  // Cross-Connect Matrix (5d0c0467-5393-4850-aba7-ddfb782b5cbc)
  CROSS_CONNECT_MATRIX_ACCESS = '36524948-4439-4482-8f94-9d92263eb68b',

  // Dashboard Builder (298c00dd-a03e-4a4a-a959-908ec111ef7e)
  DASHBOARD_BUILDER_ACCESS = '370b7f41-cea0-49c9-a11a-1200e06b0c81',

  // Edit Organization Details (a786ffce-b3e1-4f38-bf83-c8bb6b9727ba)
  EDIT_ORGANIZATION_DETAILS_ACCESS = '6b37c283-fd6f-4b4a-b08c-1b83f92cddad',

  // Edit User Profile (8f28fd16-75e8-4b11-bf7c-5d84e7fa551e)
  EDIT_USER_PROFILE_ACCESS = '4e8a4c8e-de44-4096-8c85-455e21e59ab9',

  // Form Builder (e15cc70e-e8ce-47e9-8829-176355399b2e)
  FORM_BUILDER_ACCESS = '15ce42f9-a899-4bfb-a6ef-15454b629bc6',

  // Forms Dashboard (081c32f0-b7a8-422c-9d8c-b8b509e4d639)
  FORMS_DASHBOARD_ACCESS = '75e2b66d-ffd6-4e17-8d97-c6b83b1cbcce',

  // Full Reports (f6d70a45-6274-400d-a3e5-8b4a6914403a)
  FULL_REPORTS_ACCESS = '9316304d-a78f-4220-b764-9c6a556f180c',
  FULL_REPORTS_EDIT_MODE = 'f4e4eb1d-b9b8-4788-b377-f0954414fa2b',

  // How To Use reports (f1d5fa84-cef6-4266-8c09-33c0a5efd53b)
  HTU_REPORTS_ACCESS = 'd3b38693-0af5-4041-974e-4431faad543d',
  HTU_REPORTS_EDIT_MODE = 'ce2a25e8-d74e-4066-a59c-8cc2b6690303',

  // Invite new user (61e32dff-989e-469d-a700-af9bff24680a)
  INVITE_NEW_USER_ACCESS = 'dc4b7b7b-7ff5-42b3-8eb3-32277e05afee',

  // Landing page (2f9921e8-5ac0-4c7e-8649-5c95d9bf9c99)
  LANDING_ACCESS = 'add02ddc-890d-4d37-b0a7-9b91cccfb40e',

  // Library Bar (6c4f9ed1-452c-4737-b741-02f74bf3b132)
  LIBRARY_BAR_SHOW = '7d08f1fb-4e27-4cf5-9ba3-fc8e097ef6e6',

  // Organization Users Dashboard (4ddef4cd-faf0-4c95-ba6a-e5444cd4ac44)
  ORGANIZATION_USERS_DASHBOARD_ACCESS = 'c090487a-e5b6-4ba1-b1b4-bc8f688189fe',

  // Organizations Dashboard (22fea700-d4a4-411c-b6ac-56c167318658)
  ORGANIZATIONS_DASHBOARD_ACCESS = '233b51ba-ac1c-4682-8010-2acedbf92b07',

  // PDF Output (7a6290e9-9bd4-45eb-a5b7-ca5556d3cbed)
  PDF_OUTPUT_ACCESS = '1cf3145b-8764-452f-a084-73cd73349c2a',
  PDF_OUTPUT_FINAL_PDF_BUTTON = '3cf9337a-b352-471d-8c84-8ef3a8853461',
  PDF_OUTPUT_VERSIONING_TOOLS = '1a3d2c2e-183f-47f9-801c-5ea3c52dd283',
  PDF_OUTPUT_DELETE_BUTTON = '5c8c962e-afd9-4665-8b88-cde06d0a3a6a',

  // Presentations (c3f6c1a6-ccd2-4d0a-a0d7-23e8a5ec9daa)
  PRESENTATIONS_ACCESS = 'be448236-9193-4498-8b26-119bde55d7a7',

  // Processor Admin Define (d96af31b-5002-4f75-b265-b88951a0e6f5)
  PROCESSOR_ADMIN_DEFINE_ACCESS = '201c177e-efed-4b93-9838-28e9cfc420f8',

  // Processor Admin Formulate (478a3bc4-f3df-4f3b-9925-33fd36225360)
  PROCESSOR_ADMIN_FORMULATE_ACCESS = 'f3f25164-6ec7-4b77-8798-235bdd35aacd',

  // Processor User Assess (12e8fb8b-1768-4506-b46c-6db720969370)
  PROCESSOR_USER_ASSESS_ACCESS = 'b9b57086-3225-405c-8e77-455e377e103f',

  // Processor User Define (fe738173-b089-474b-9957-8c7221de61a7)
  PROCESSOR_USER_DEFINE_ACCESS = 'f233329c-a159-4bf1-b9b2-32bb0e68f295',

  // Products Dashboard (0401cdfb-90b1-42af-b8fd-29f924e672e6)
  PRODUCTS_DASHBOARD_ACCESS = '69bd3c01-119f-41d5-b510-c28486f6dfd5',

  // Questionnaire Admin (ce2790f2-72df-4295-b3cf-22900ce31ae7)
  QUESTIONNAIRE_ADMIN_ACCESS = '39df773d-e7d2-4d35-9e65-028dd8c35456',

  // Questionnaire User (a3733d1b-8215-4925-89be-0c11f8ed3f23)
  QUESTIONNAIRE_USER_ACCESS = '83c6313b-447e-4cfe-a8ba-d786e37b028c',

  // Report Templates (e65c5442-1144-4916-8ec7-9fe0b01b39b3)
  REPORT_TEMPLATES_ACCESS = '424265cb-ea5c-41ec-91b4-6ee92c70eadc',

  // SaaS Definition Panel (fe261664-d2ed-4b40-b95a-1aad93e6c40e)
  SAAS_DEFINITION_PANEL_ACCESS = '24d42e3a-a945-4914-a0d8-a6228a380788',

  // SaaS Details Form (ce1f5a4a-ce57-4675-8f2e-821b22e38e4f)
  SAAS_DETAILS_FORM_ACCESS = 'eda9533a-66b4-42de-aaee-a8c44326e9ee',

  // Single Impact Report (97bacb28-40a8-471d-9e2d-831435eed7f2)
  SINGLE_IMPACT_REPORT_ACCESS = '264277b8-7e7f-41ba-9718-950f0621ffe5',
  SINGLE_IMPACT_REPORT_EDIT_MODE = '78a5351e-35ce-4904-92ef-4c6afdff8780',

  // Studies Dashboard (51b00fdd-2aa0-4bff-a109-48ad926373c4)
  STUDIES_DASHBOARD_ACCESS = '91e423e9-b9f1-451a-8d4b-2f6f337c4956',
  STUDIES_DASHBOARD_ORGANIZATION_COLUMN = '2e379c2d-2eef-4345-a6ff-b4431e5ad2ef',
  STUDIES_DASHBOARD_ADMIN_INFO = 'f9a2fa70-864d-467e-a27e-2ccc027531c2',

  // Study Registration Panel and Forms (e719c9a5-5106-4c7e-a7e0-cf401d72de65)
  STUDY_REGISTRATION_ACCESS = '7251c113-f59e-42e5-8726-716eaed5474a',

  // Study Review (84a94512-0f3b-47fa-ba95-f958c4406674)
  STUDY_REVIEW_ACCESS = '379f9418-d82d-409d-936f-a6acd60501c7',

  // Summary Reports (febbcc90-908f-425b-b52d-06df1f2a1362)
  SUMMARY_REPORTS_ACCESS = '29144a50-9c51-4c6b-bae6-2ea9596fea21',
  SUMMARY_REPORTS_EDIT_MODE = '00812ade-c8b2-482c-88b1-120c750fe8ce',

  // System Workflow (380daadf-3e51-4f51-8bb5-dbb63502ca7b)
  SYSTEM_WORKFLOW_ACCESS = '436bdc97-c1ed-4856-98fe-a4f803569d85',

  // Tagging Admin Matrix (3edcfe62-449a-4be0-adc1-a84b481577dc)
  TAGGING_ADMIN_MATRIX_ACCESS = '01902e78-02eb-4b4c-908e-d0466254f885',

  // Tagging User Matrix (45e96d9f-cfb9-4de0-9239-3997c7ba6e2b)
  TAGGING_USER_MATRIX_ACCESS = 'c0c99af8-7747-4684-bd2b-aac8a56c0dc6',

  // Template Checktree (477b4a91-0a4c-4940-bf6f-5ab7dd6f6601)
  TEMPLATE_CHECKTREE_ACCESS = '631e3a61-4bbd-4517-9c18-2dadfe146460',
  TEMPLATE_CHECKTREE_ADMIN_TOOLS = '6511f470-31c0-403b-98e4-cc6f43cfa0b4',

  // Timeline Forms (a3957035-0e70-49e0-826c-1c54116960de)
  TIMELINE_FORMS_ACCESS = 'e14206f9-1af7-494d-a6b7-db5c00376f9f',
  TIMELINE_FORMS_AUTHORITY_FILTER = '74b31652-d260-407a-abba-a57e1cedef20',
  TIMELINE_FORMS_CLIENT_FILTER = '60c88812-7b94-4817-aea8-ad543fd16e27',

  // Top Bar (b604a114-44ef-42b6-930a-8ca79d0ac1c0)
  TOP_BAR_SHOW_BLUE_BACKGROUND = '1f699dd6-7274-4e84-b1f4-7c9376980f42',
  TOP_BAR_SHOW_AVATAR_INITIALS = '6bd6e7ce-0dd4-43f5-ac62-a213b109c29e',
  TOP_BAR_SHOW_VERSION_BADGE = 'fb7d0df5-20f7-4450-b596-db06955605f7',
  TOP_BAR_SHOW_ORGANIZATION_CALLOUT = 'c569dd6b-9914-41c7-9821-b21418f11660',
  TOP_BAR_SHOW_STUDY_CALLOUT = 'bc4cb271-d03e-4427-ac13-48178d20c102',

  // Tracking Bar (aefc82e1-35f9-4a3d-b6dc-476c643a0029)
  TRACKING_BAR_SHOW = '3b5d34e1-13b0-4c47-8996-4d4340e4b87d',
  TRACKING_BAR_UNLOCK_BUTTON = 'b84f000f-ca0f-4301-9785-9f75dc08e6fe',

  // User profile (a4b8a594-3e09-4530-86a1-49ccd5ce5434)
  USER_PROFILE_ACCESS = '223c19f4-93d1-4d55-83e5-0da5bc9d0460',

  // Versioning Bar (c3a06b7e-b642-4dbc-b4f4-ffed581264a5)
  VERSIONING_BAR_SHOW = 'eb6fbc57-c539-45f4-a1c0-dde73b84d7b4',
  VERSIONING_BAR_EDIT_TITLE = '1020ddc0-3742-47e9-9523-952d59653712',
  VERSIONING_BAR_RESTORE_BUTTON = 'a344a91b-1ddb-4f60-ad05-3c6d131d7959',
  VERSIONING_BAR_TOOLS = '02049c2a-59a8-4575-b891-89371ae8c94a',

  // Workflow (789eb40a-f080-43c9-8b06-b408bd24d992)
  WORKFLOW_ACCESS = '57834018-0bc9-45ce-af0f-daa6ea22d6f6',

  // Workspace (f7eeae7f-9cce-44bd-bf23-29dff645499b)
  WORKSPACE_ACCESS = '05d226c0-0dbd-4d4b-89cc-abc733638979',
  WORKSPACE_SAVE_VERSION_BUTTON = '968531b4-2e1e-417f-88d3-9687e76166c2',
}
