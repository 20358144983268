import httpClient from '../axios.config';

const END_POINT = '/how_to_use';
const PDF_OUTPUT_END_POINT = '/pdf-output';

export default {
  async importHTU(data: any, file: string | Blob, module_uuid: string) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('file', file);
    formData.append('module_uuid', module_uuid);
    return await httpClient.put(`${END_POINT}/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  async renderHTUPdf(checktreeUuid: string, templateUuid: string, chapterId: number, chapter: boolean): Promise<Blob> {
    return await httpClient.get(
      `${PDF_OUTPUT_END_POINT}/htu/pdf/${checktreeUuid}/${templateUuid}/${chapterId}/${chapter}`,
      {
        responseType: 'blob',
      },
    );
  },
};
