






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaseBadge extends Vue {
  @Prop() id: number | string;
  @Prop({ default: 'medium' }) size: 'large' | 'medium' | 'small';
}
