






























































































































import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { debounce, isEqual } from 'lodash';
import ChapterItemCommentHighlight from './mixins/ChapterItemCommentHighlight.vue';
import SimpleBarPlot from '@/app/presentations/components/charts/SimpleBarPlot.vue';
import GroupedBarPlot from '@/app/presentations/components/charts/GroupedBarPlot.vue';
import StackedBarPlot from '@/app/presentations/components/charts/StackedBarPlot.vue';
import StepLinePlot from '@/app/presentations/components/charts/StepLinePlot.vue';
import SimplePieChart from '@/app/presentations/components/charts/SimplePieChart.vue';
import MultiplesPieCharts from '@/app/presentations/components/charts/MultiplesPieCharts.vue';
import api from '@/app/shared/api';
import { ChapterItem } from '@/app/shared/models/reports/ChapterItem';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import { ChapterItemMode } from '@/app/shared/types/chapter-item-mode.type';
import { UpdateChapterItemPayload, WS_DEBOUNCE_VALUE } from '@/app/shared/store/modules/reports.store-module';
import { PresentationsChartsType } from '@/app/shared/types/presentations.types';
import {
  ChartDataResponse,
  ChartDataset,
  ChartDatasetItem,
  PieChartData,
} from '@/app/shared/models/presentations/Charts';

@Component({
  components: {
    SimpleBarPlot,
    GroupedBarPlot,
    StackedBarPlot,
    StepLinePlot,
    SimplePieChart,
    MultiplesPieCharts,
  },
})
export default class ChartChapterItem extends ChapterItemCommentHighlight {
  @Prop() item: ChapterItem;
  @Prop() mode: ChapterItemMode;
  @Prop() isDeleteButtonAllowed: boolean;

  ChapterItemType: ChapterItemType;
  supportsTextHighlighting = true;
  chartData: ChartDataResponse = null;
  chartBase64Images: string[] = [];

  @Emit('switchMode')
  switchMode(_newMode: ChapterItemMode) {
    //
  }

  @Emit('deleteChapterItem')
  deleteChapterItem() {
    // just emitting event
  }

  @Emit('update')
  updateChapterItem(_payload: UpdateChapterItemPayload) {
    // just emitting event
  }

  @Emit('toggleExpand')
  toggleExpand() {
    // just emitting event
  }

  @Watch('mode')
  onModeChange(mode: ChapterItemMode) {
    if (mode === 'edit') this.getChartData();
  }

  get isExpanded() {
    return this.item && this.item.isExpandedInContent;
  }

  get chartType(): PresentationsChartsType {
    return this.item?.payload?.chartType;
  }

  get savedChartData(): ChartDataResponse {
    return this.item?.data;
  }

  get hasUpdatedChartData() {
    if (!this.chartData) return false;
    return !isEqual(this.savedChartData, this.chartData);
  }

  get simpleBarPlotBars() {
    return (this.chartData?.data as ChartDatasetItem[]) || [];
  }

  get groupedBarPlotBars() {
    return (this.chartData?.data as ChartDataset[]) || [];
  }

  get groupedBarPlotLabels() {
    return this.chartData?.labels || [];
  }

  get stackedBarPlotBars() {
    return (this.chartData?.data as ChartDataset[]) || [];
  }

  get stackedBarPlotLabels() {
    return this.chartData?.labels || [];
  }

  get multiplePieChartsData() {
    return (this.chartData?.data as PieChartData[]) || [];
  }

  created() {
    if (this.mode === 'edit') this.getChartData();
  }

  onTitleInput = debounce((event: string) => {
    this.updateChapterItem({
      uuid: this.item.uuid,
      type: ChapterItemType.CHART,
      propertyName: 'title',
      propertyValue: event,
    });
  }, WS_DEBOUNCE_VALUE);

  switchToEditMode() {
    if (this.mode === 'lock') {
      return;
    }

    this.switchMode('edit');
  }

  async getChartData() {
    try {
      this.chartData = await api.presentations.getChartData(this.item.payload);
    } catch (error) {
      console.warn(error);
    }
  }

  onChartUpdate(base64Images: string[]) {
    this.chartBase64Images = base64Images;
  }

  async updateChart() {
    try {
      const uploadedFiles = [];

      for (let i = 0; i < this.chartBase64Images.length; i++) {
        const chartBase64Image = this.chartBase64Images[i];
        const uploadedFileResponse = await api.file.uploadFile('chart', chartBase64Image, 'image/png');
        if (uploadedFileResponse.status !== 'success') throw new Error('Upload failed');
        uploadedFiles.push({
          ...uploadedFileResponse,
          contents: chartBase64Image,
        });
      }

      this.updateChapterItem({
        uuid: this.item.uuid,
        type: ChapterItemType.CHART,
        propertyName: 'files',
        propertyValue: uploadedFiles,
        additionalProperties: [
          {
            name: 'data',
            value: this.chartData,
          },
        ],
      });
    } catch (error) {
      console.warn(error);
    }
  }
}
