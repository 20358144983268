import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

export interface DialogOptions {
  title: string;
  message: string;
  confirmLabel: string;
  confirmCallback: (context?: any) => void;
  cancelLabel: string;
  cancelCallback: () => void;
  checkboxLabel: string;
}

@Module({ namespaced: true })
class DialogModule extends VuexModule {
  visible: boolean = false;
  options: DialogOptions = {
    title: 'Are you sure?',
    message: 'Lorem ipsum dolor sit amet.',
    confirmLabel: 'Yes',
    confirmCallback: () => {
      /**/
    },
    cancelLabel: 'Cancel',
    cancelCallback: () => {
      /**/
    },
    checkboxLabel: null,
  };

  get isDialogVisible() {
    return this.visible;
  }

  get dialogOptions() {
    return this.options;
  }

  @Mutation
  private showDialog(payload: DialogOptions) {
    this.options = payload;
    this.visible = true;
  }

  @Mutation
  private hideDialog() {
    this.visible = false;
  }

  @Mutation
  private toggle(isVisible: boolean) {
    this.visible = isVisible;
  }

  @Action({ rawError: true })
  public show(payload: DialogOptions): void {
    this.context.commit('showDialog', payload);
  }

  @Action({ rawError: true })
  public hide(): void {
    this.context.commit('hideDialog');
  }

  @Action({ rawError: true })
  public toggleDialog(_isVisible: boolean): void {
    this.context.commit('toggle');
  }
}
export default DialogModule;
