





















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import ResizeObserver from 'resize-observer-polyfill';
import Loader from '@/app/shared/components/Loader.vue';
import CommentingBadge from './CommentingBadge.vue';
import CommentingBox from './CommentingBox.vue';
import CommentingTimestamp from './CommentingTimestamp.vue';
import { Comment, CommentingMode, CommentReply, DraftComment } from '@/app/shared/models/Comment';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';
import { OptionItem } from '@/app/shared/models/form/field/FormFieldSelect';

const CommentsStore = namespace('Comments');
const UserStore = namespace('User');

@Component({
  components: {
    CommentingBadge,
    CommentingBox,
    CommentingTimestamp,
    Loader,
  },
})
export default class CommentingContent extends Vue {
  isLoading: boolean = true;
  commentUserFilters: OptionItem[] = [];
  resizeObserver: ResizeObserver = null;

  @CommentsStore.State
  mode: 'feed' | 'history';

  @CommentsStore.State
  comments: Comment[];

  @CommentsStore.State
  commentFilter: string;

  @CommentsStore.State
  commentUserFilter: string;

  @CommentsStore.Getter
  commentHistory: Record<string, Comment[]>;

  @CommentsStore.State
  draftComment: DraftComment;

  @CommentsStore.Getter
  isCommentFeedMode: boolean;

  @CommentsStore.Getter
  isCommentHistoryMode: boolean;

  @CommentsStore.Getter
  commentFilters: any[];

  @CommentsStore.Getter
  dateItems: any[];

  @CommentsStore.Mutation
  setCommentUserFilter: (commentUserFilter: 'internal' | 'authority' | 'public') => void;

  @CommentsStore.Mutation
  setCommentFilter: (commentFilter: string) => void;

  @CommentsStore.Action
  switchMode: () => void;

  @CommentsStore.Action
  getComments: () => Promise<void>;

  @CommentsStore.Action
  changeUserFilter: (userFilter: string) => Promise<void>;

  @CommentsStore.Action
  addDraftComment: (payload: DraftComment) => void;

  @CommentsStore.Action
  cancelDraftComment: () => void;

  @CommentsStore.Action
  deleteComment: (comment: Comment) => void;

  @CommentsStore.Action
  deleteCommentReply: (commentReply: CommentReply) => void;

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  @Watch('mode')
  onModeChange(mode: CommentingMode) {
    switch (mode) {
      case 'history':
        this.deregisterEventListeners();
        break;
      case 'feed':
      default:
        this.registerEventListeners();
        this.setCommentFilter(this.commentFilter === 'resolved' ? 'all' : this.commentFilter);
    }
  }

  @AsyncComputed()
  async isEditModeAllowed() {
    return await this.isAllowed(UserPermission.COMMENTING_BAR_EDIT_MODE);
  }

  get filter() {
    return this.commentFilter;
  }

  set filter(filter: string) {
    this.setCommentFilter(filter);
  }

  get userFilter() {
    return this.commentUserFilters.findIndex((filter) => filter.value === this.commentUserFilter);
  }

  set userFilter(userFilterIndex: number) {
    const { value } = this.commentUserFilters[userFilterIndex];
    this.changeUserFilter(value);
  }

  get newCount() {
    const newComments = this.comments.filter((comment) => comment.is_new);
    if (!newComments.length) return null;
    return `${newComments.length} new`;
  }

  async mounted() {
    try {
      this.commentUserFilters = [
        ...((await this.isAllowed(UserPermission.COMMENTING_BAR_INTERNAL_TAB))
          ? [
              {
                value: 'internal',
                text: 'Internal',
              },
            ]
          : []),
        ...((await this.isAllowed(UserPermission.COMMENTING_BAR_AUTHORITIES_TAB))
          ? [
              {
                value: 'authority',
                text: 'Authorities',
              },
            ]
          : []),
        {
          value: 'public',
          text: 'Public',
        },
      ];

      const { value } = this.commentUserFilters[0];
      if (value) this.setCommentUserFilter(value as 'internal' | 'authority' | 'public');

      await this.getComments();
    } catch (error) {
      console.warn(error);
    } finally {
      this.isLoading = false;
    }

    this.registerEventListeners();
  }

  beforeDestroy() {
    this.deregisterEventListeners();
  }

  registerEventListeners() {
    window.addEventListener('scroll', this.setFeedPosition);
    this.resizeObserver = new ResizeObserver(this.setFeedHeight);
    this.resizeObserver.observe(document.querySelector('#report-contents'));
    this.$nextTick(() => {
      this.setFeedPosition();
    });
  }

  deregisterEventListeners() {
    this.resizeObserver.disconnect();
    window.removeEventListener('scroll', this.setFeedPosition);
  }

  setFeedHeight(entries?: ResizeObserverEntry[]) {
    if (!this.$refs.commentsFeed) return;

    const reportContents =
      (entries && (entries[0].target as HTMLDivElement)) ||
      (document.querySelector('#report-contents') as HTMLDivElement);

    const commentsFeed = this.$refs.commentsFeed as HTMLDivElement;
    commentsFeed.style.height = `${reportContents.scrollHeight}px`;
  }

  setFeedPosition() {
    if (!this.$refs.commentsFeed) return;

    const commentsFeed = this.$refs.commentsFeed as HTMLDivElement;
    commentsFeed.style.top = `${-document.scrollingElement.scrollTop - 38}px`;
  }

  jumpToDate(date: string) {
    const historyContainer = this.$refs.historyContainer as HTMLDivElement;
    if (!historyContainer) return;

    if (date === 'all') {
      historyContainer.scrollTop = 0;
      return;
    }

    const dateGroup = this.$refs[date] as HTMLDivElement[];
    if (!dateGroup || !dateGroup[0]) return;

    dateGroup[0].scrollIntoView();
  }
}
