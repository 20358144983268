

























































import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import DashboardDataTableExpandedRow from './data-table/DashboardDataTableExpandedRow.vue';
import DashboardDataTableField from './data-table/DashboardDataTableField.vue';
import ResizableTableColumns from '@/app/shared/components/mixins/ResizableTableColumns.vue';
import {
  DashboardDataTableAction,
  DashboardDataTableColumn,
  DashboardDataTableStatus,
} from '@/app/shared/models/DashboardDataTable';
import { Table } from '@/app/shared/models/Table';

@Component({
  components: {
    DashboardDataTableExpandedRow,
    DashboardDataTableField,
  },
})
export default class DashboardDataTable extends ResizableTableColumns {
  @Prop() columns: DashboardDataTableColumn[];
  @Prop() data: any[];
  @Prop() details: Record<string, Table[][]>;
  @Prop() autoExpand: string;
  @Prop() extraPane: Record<string, Table>;
  @Prop() statusConfig: DashboardDataTableStatus[];
  @Prop() actionConfig: DashboardDataTableAction[];
  @Prop() isEditVisible: boolean;
  @Prop() isItemEditable: (item: any) => boolean;
  @Prop() isItemExpandable: (item: any) => boolean;
  @Prop() itemClass: string | ((item: any) => string);
  @Prop({ default: 'calc(100vh - var(--toolbar-height) - var(--app-bar-height) - var(--footer-height))' })
  height: string;
  tableRef: string = 'dataTable';
  tableResizableColumnClass: string = 'dashboard-data-table__column-header--is-resizable';

  @Emit('action-click')
  onActionClick(_type: string, _item: any) {
    //
  }

  @Emit('edit')
  onEdit(_item: any) {
    //
  }

  @Emit('copy')
  onCopy(_item: any) {
    //
  }

  @Emit('expand')
  expand(_item: any) {
    //
  }

  @Watch('columns')
  onColumnsPropChange(newVal: DashboardDataTableColumn[], oldVal: DashboardDataTableColumn[]) {
    if (newVal.length !== oldVal.length) {
      setTimeout(() => {
        this.addResize();
      }, 0);
    }
  }

  headerSlotName(column: DashboardDataTableColumn) {
    return 'header.' + column.value;
  }

  itemSlotName(column: DashboardDataTableColumn) {
    return 'item.' + column.value;
  }

  get tableHeaders() {
    return this.columns.map((el) => {
      return {
        text: el.text,
        value: el.value,
        class: el.resizable ? this.tableResizableColumnClass : null,
        width: el.width,
      };
    });
  }

  get expanded() {
    return this.data.filter((item) => item.is_expanded);
  }
}
