import httpClient from '@/app/shared/api/axios.config';
import { ManageWorkflowNodesAndConnections } from '@/app/shared/models/workflow/ManageWorkflowNodesAndConnections';
import { ModuleOptions } from '@/app/shared/models/workflow/Module';

const ENDPOINT = '/workflow';
const MODULE_ENDPOINT = '/module';
const FLOW_ENDPOINT = '/flow';
const CHECKTTREE_ENDPOINT = '/checktree';
const MATRIX_ENDPOINT = '/matrix';

export default {
  async getByUuid(uuid: string) {
    return await httpClient.get(`${ENDPOINT}/${uuid}`);
  },
  async saveNodesAndConnections(uuid: string, data: ManageWorkflowNodesAndConnections) {
    return await httpClient.post(`${ENDPOINT}/${uuid}`, data);
  },
  async getChecktreeModuleOptions() {
    const data: ModuleOptions[] = await httpClient.get(MODULE_ENDPOINT + '/checktree/options');
    return data;
  },
  async addModule(data: any) {
    return await httpClient.put(`${MODULE_ENDPOINT}`, data);
  },
  async getModuleByUuid(uuid: string) {
    return await httpClient.get(`${MODULE_ENDPOINT}/${uuid}`);
  },
  async editModule(uuid: string, data: any) {
    return await httpClient.post(`${MODULE_ENDPOINT}/${uuid}`, data);
  },
  async deleteModule(uuid: string) {
    return await httpClient.delete(`${MODULE_ENDPOINT}/${uuid}`);
  },
  async getAllFlows(workflow_id: number) {
    return await httpClient.get(`${FLOW_ENDPOINT}/?workflow_id=` + workflow_id);
  },
  async addFlow(data: any) {
    return await httpClient.put(`${FLOW_ENDPOINT}`, data);
  },
  async editFlow(uuid: string, data: any) {
    return await httpClient.post(`${FLOW_ENDPOINT}/${uuid}`, data);
  },
  async deleteFlow(uuid: string) {
    return await httpClient.delete(`${FLOW_ENDPOINT}/${uuid}`);
  },
  async getAdminChecktrees() {
    return await httpClient.get(`${CHECKTTREE_ENDPOINT}/?is_admin=true`);
  },
  async getTaggingMatrices() {
    return await httpClient.get(`${MATRIX_ENDPOINT}/?is_tagging=true`);
  },
};
