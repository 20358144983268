import FormConfigText from '@/app/shared/components/form/config/FormConfigText.vue';
import FormFieldText from '@/app/shared/components/form/field/FormFieldText.vue';

export default {
  name: 'Text Control',
  description: 'Single line text input',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldText,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigText,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    isDisabled: false,
    isReadonly: false,
    inputType: 'text',
    inputStep: 'any',
    helpText: '',
    isSmall: false,

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {string}
   */
  rendererDefaultData() {
    return '';
  },
};
