








































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ResizableTableCells from '@/app/shared/components/mixins/ResizableTableCells.vue';
import { TooltipOptions } from '@/app/shared/store/modules/ui.store-module';
import MatrixRelationMatrix from '@/app/shared/models/MatrixRelationMatrix';
import MatrixRelationField from '@/app/shared/models/MatrixRelationField';
import { ModuleProperties } from '@/app/shared/models/Module';
import api from '@/app/shared/api';

const MatrixRelationsStore = namespace('MatrixRelations');
const ModulePropertiesStore = namespace('ModuleProperties');
const UiStore = namespace('UI');

@Component
export default class MatrixRelationsRowHeaderSecondType extends ResizableTableCells {
  @Prop() index: number;
  @Prop() row: MatrixRelationField;
  @Prop() rerender: boolean;

  @MatrixRelationsStore.Getter
  public matrixRelationsData: MatrixRelationMatrix;

  @ModulePropertiesStore.Getter
  moduleProperties: ModuleProperties;

  @UiStore.Action
  showCallout!: (payload: TooltipOptions) => void;
  @UiStore.Action
  hideCallout!: () => void;

  setShowCallout() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const rect = this.$refs.tableHead.getBoundingClientRect();
    this.showCallout({
      text: this.row.title,
      top: rect.top + 10,
      left: rect.right,
      position: 'bottom',
    });
  }

  toogleExpand(row: MatrixRelationField) {
    this.$emit('toogle-expand', row);
  }

  get getRowStatus(): 'done' | 'in-progress' | 'new' | 'none' {
    this.rerender;
    let atLeastOneSelected = false;
    let allSelected = true;
    for (const column of this.matrixRelationsData.axis_x.children) {
      let noneSelectedInGroup = true;
      for (const child of column.children) {
        if (this.matrixRelationsData.matrix[this.index][child.id] == true) {
          atLeastOneSelected = true;
          noneSelectedInGroup = false;
          break;
        }
      }
      if (noneSelectedInGroup) {
        allSelected = false;
      }
    }
    if (allSelected) {
      return 'done';
    }
    if (atLeastOneSelected) {
      return 'in-progress';
    }
    // TODO: Implement `new` state too!
    return 'none';
  }

  get statusIcon() {
    return {
      done: 'done',
      'in-progress': 'in-progress',
      new: 'add-v2',
    };
  }

  @Watch('rerender')
  updateComponent() {
    // TODO: Check if you can solve better
    this.$forceUpdate();
  }

  async goToSingleImpact() {
    if (this.getRowStatus == 'done') {
      await api.reports
        .getImpactAssesmentParagraphs(this.row.uuid, this.moduleProperties.study_uuid, '1')
        .then(() => {
          this.$router.push({
            path: `/single-impact/${this.moduleProperties.study_uuid}/${this.row.uuid}`,
            query: { fromMatrix: '1' },
          });
        })
        .catch(() => {
          console.warn('A resume needs to be triggered before the single impact report is available for display.');
        });
    }
  }

  onRowResize(event: MouseEvent) {
    this.currentCell = this.$refs.tableHead as HTMLTableCellElement;
    this.onCellResize(event);
  }
}
