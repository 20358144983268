




















































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep, debounce, escapeRegExp } from 'lodash';
import { namespace } from 'vuex-class';
import CommentingCommentEditable from './CommentingCommentEditable.vue';
// import api from '@/app/shared/api';
import LoaderService from '@/app/shared/utils/loader.service';
import { Comment } from '@/app/shared/models/Comment';
import { OptionItem } from '@/app/shared/models/form/field/FormFieldSelect';

const CommentsStore = namespace('Comments');

@Component({
  components: {
    CommentingCommentEditable,
  },
})
export default class CommentingCommentLink extends Vue {
  @Prop() comment: Comment;
  @Prop({ default: false, type: Boolean }) noFocus: boolean;
  placeholderText = 'Link comment number with #';
  textInput = '';
  isDropdownShown = false;
  dropdownItems: OptionItem[] = [];
  dropdownRegex = /\B(#)(\d*)/;
  isDirty = true;
  isReplaced = false;
  editableWidth = 'auto';

  @Emit()
  link(_textInput: string) {
    //
  }

  @Emit()
  cancel() {
    this.reset();
  }

  @Watch('textInput')
  onTextInputChange(textInput: string) {
    if (this.isReplaced) {
      this.isReplaced = false;
      return;
    }

    if (textInput.length) this.debouncedGetDropdownItems();
    else this.hideDropdown();

    this.isDirty = true;
  }

  @CommentsStore.State
  comments: Comment[];

  get hasValidTextInput() {
    return this.textInput?.length && !this.isDirty;
  }

  mounted() {
    this.$nextTick(() => {
      this.getEditableWidth();
      if (!this.noFocus) this.focusEditable();
    });
  }

  focusEditable() {
    if (this.$refs.editable) (this.$refs.editable as Vue & { setCaretEnd: () => void }).setCaretEnd();
  }

  getEditableWidth() {
    this.editableWidth =
      (this.$refs.editable && (this.$refs.editable as Vue & { $el: HTMLSpanElement }).$el.offsetWidth).toString() ||
      'auto';
  }

  linkComment(textInput: string) {
    if (!this.hasValidTextInput) return;
    this.reset();
    this.link(textInput);
  }

  reset() {
    this.textInput = '';
  }

  async getDropdownItems() {
    try {
      LoaderService.disableHttpLoader();

      let textInput = this.textInput;
      const numberMatch = textInput.match(this.dropdownRegex);

      // Make a deep copy of all comments across object, but ignore the current and already linked comments.
      let commentItems = cloneDeep(
        this.comments.filter(
          (comment) => comment.uuid !== this.comment.uuid && !this.comment.linked?.includes(comment.uuid),
        ),
      );

      // Match explicit comment number.
      if (numberMatch) {
        if (numberMatch[2])
          commentItems = commentItems.filter((comment) => comment.number.toString() === numberMatch[2]);
        textInput = textInput.replace(this.dropdownRegex, '');
      }

      const searchTerms = textInput.split(/\s+/);

      // Match any search terms.
      if (searchTerms.length) {
        commentItems = commentItems.filter((comment) =>
          searchTerms.some((term) => {
            const r = new RegExp(escapeRegExp(term), 'i');
            return r.test(comment.user.first_name) || r.test(comment.user.last_name) || r.test(comment.content);
          }),
        );
      }

      if (!commentItems.length) {
        this.hideDropdown();
        return;
      }

      this.dropdownItems = commentItems
        .map((comment) => ({
          text: `#${comment.number}: ${comment.user.first_name} ${comment.user.last_name} - ${comment.content}`,
          value: comment.number.toString(),
        }))
        .slice(0, 10);

      this.showDropdown();
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  debouncedGetDropdownItems = debounce(() => {
    this.getDropdownItems();
  }, 250);

  showDropdown() {
    this.getEditableWidth();
    this.isDropdownShown = true;
  }

  hideDropdown() {
    this.isDropdownShown = false;
    this.dropdownItems = [];
  }

  replaceDropdownMatch(dropdownReplacement: string) {
    const replacement = `#${dropdownReplacement}`;

    if (this.textInput !== replacement) {
      this.isReplaced = true;
      this.textInput = `#${dropdownReplacement}`;
    }

    this.isDirty = false;
    this.hideDropdown();

    this.$nextTick(() => {
      this.focusEditable();
    });
  }
}
