










































































































import { Component, Watch, Mixins, Vue } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import filesize from 'filesize';
import FormFieldLabel from '@/app/shared/components/form/field/mixins/FormFieldLabel.vue';
import FormFieldFocus from '@/app/shared/components/form/field/mixins/FormFieldFocus.vue';
import { UploadResult } from '@/app/shared/models/form/field/FormFieldUpload';

declare global {
  interface Window {
    Cypress: any;
  }
}

@Component
export default class FormFieldUpload extends Mixins(Vue, FormFieldLabel, FormFieldFocus, BaseControlSkeleton) {
  value: UploadResult[];
  control: {
    isMultiple: boolean;
    isInline: boolean;
    acceptType: string;
    maxSize: number;
  };
  isDraggedOver: boolean = false;
  uploadResults: UploadResult[] = [];
  updateValue: (value: UploadResult[]) => void;

  @Watch('value', { deep: true })
  onValueChanged(value: UploadResult[]) {
    this.$set(this, 'uploadResults', value);
  }

  @Watch('uploadResults', { deep: true })
  onUploadResultsChanged(value: UploadResult[]) {
    this.updateValue(value);
  }

  get dropZoneIcon() {
    return this.isDraggedOver ? '$drop-here' : '$upload-document-v2';
  }

  get dropZoneLabel() {
    return this.isDraggedOver
      ? 'Drop here'
      : this.control.isMultiple
      ? 'Drag and drop or click here to upload files'
      : 'Drag and drop or click here to upload file';
  }

  get isDropZoneVisible() {
    if (!this.control.isMultiple && this.uploadResults.length > 0) return false;
    return true;
  }

  get invalidImagePreview() {
    return 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aAogICAgZmlsbC1ydWxlPSJldmVub2RkIgogICAgY2xpcC1ydWxlPSJldmVub2RkIgogICAgZD0iTTEyLDIwIEMxNi40MTgyNzgsMjAgMjAsMTYuNDE4Mjc4IDIwLDEyIEMyMCw3LjU4MTcyMiAxNi40MTgyNzgsNCAxMiw0IEM3LjU4MTcyMiw0IDQsNy41ODE3MjIgNCwxMiBDNCwxNi40MTgyNzggNy41ODE3MjIsMjAgMTIsMjAgWiBNMTQuNDg0LDggTDExLjk0OSwxMC41MzUgTDkuNDE0LDggTDgsOS40MTQgTDEwLjUzNCwxMS45NDkgTDgsMTQuNDg0IEw5LjQxNCwxNS44OTggTDExLjk0OSwxMy4zNjQgTDE0LjQ4NCwxNS44OTggTDE1Ljg5OCwxNC40ODQgTDEzLjM2MywxMS45NDkgTDE1Ljg5OCw5LjQxNCBMMTQuNDg0LDggWiIKICAgIGZpbGw9IiNENUUwRTYiCiAgLz4KPC9zdmc+';
  }

  created() {
    if (this.value) {
      this.$set(this, 'uploadResults', this.value);
    }
  }

  onUpload(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files) this.uploadFiles(target.files);
  }

  onDrop(event: DragEvent) {
    if (event.dataTransfer && event.dataTransfer.files) this.uploadFiles(event.dataTransfer.files);
    this.isDraggedOver = false;
  }

  isFileTypeAllowed(fileType: string) {
    if (!this.control.acceptType) return true;
    if (window.Cypress) return true;
    const allowedType = new RegExp(this.control.acceptType.replace('*', ''));
    return allowedType && allowedType.test(fileType);
  }

  async uploadFiles(files: FileList) {
    if (!this.control.isMultiple && this.uploadResults.length > 0) {
      this.uploadResults.forEach((uploadResult, index) => {
        this.removeUpload(uploadResult, index);
      });
    }

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);

      if (!this.isFileTypeAllowed(file.type)) {
        console.warn('File type not allowed:', file.type);
        continue;
      }

      let uploadResult: UploadResult;

      try {
        uploadResult = await this.uploadFile(file);
      } catch (error) {
        console.warn('Problem during file upload:', error);
        uploadResult = new UploadResult(null, file.name, this.invalidImagePreview, (error as Error).message);
      }

      this.addUploadResult(uploadResult);

      if (!this.control.isMultiple) break;
    }
  }

  uploadFile(file: File): Promise<UploadResult> {
    return new Promise((resolve, reject) => {
      if (!file.type) {
        reject(new Error('Unknown file type'));
        return;
      }

      if (this.control.maxSize && file.size > this.control.maxSize) {
        const fileSize = this.getFileSize(file.size);
        const maxSize = this.getFileSize(this.control.maxSize);
        reject(new Error(`File size too large: ${fileSize}, but max. allowed ${maxSize}`));
        return;
      }

      // if (!this.control.isInline) console.log('UPLOAD FILE!', file.name, file.type, file.size);

      const uploadResult = new UploadResult((Math.random() + 1).toString(36).substring(2, 9), file.name);

      if (/image\//.test(file.type)) {
        const fileReader = new FileReader();

        fileReader.addEventListener('load', (value) => {
          uploadResult.uploadPreview = value.target.result.toString();
        });

        fileReader.addEventListener('error', () => {
          uploadResult.uploadPreview = this.invalidImagePreview;
          uploadResult.uploadError = 'Unknown error';
        });

        fileReader.readAsDataURL(file);
      } else {
        uploadResult.uploadPreview = this.invalidImagePreview;
        uploadResult.uploadError = 'Unknown image type';
      }

      resolve(uploadResult);
    });
  }

  deleteUploadedFile(uploadResult: UploadResult): Promise<boolean> {
    return new Promise((resolve) => {
      console.log('DELETE UPLOADED FILE!', uploadResult.uploadId);

      resolve(true);
    });
  }

  addUploadResult(uploadResult: UploadResult) {
    this.uploadResults.push(uploadResult);
  }

  removeUploadResult(index: number) {
    this.uploadResults.splice(index, 1);
  }

  async removeUpload(uploadResult: UploadResult, index: number) {
    if (uploadResult.uploadId) await this.deleteUploadedFile(uploadResult);
    this.removeUploadResult(index);
  }

  showBrowseDialog() {
    if (this.$refs.inputField) (this.$refs.inputField as HTMLInputElement).click();
  }

  getFileSize(value: number) {
    return filesize(value, {
      standard: 'jedec',
    });
  }
}
