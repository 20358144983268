




import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseInput extends Vue {
  @Prop() private label!: string;
  @Prop() private value!: string;

  @Emit()
  input(_value: string) {
    // just emiting event
  }
}
