























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Loader from '@/app/shared/components/Loader.vue';
import { namespace } from 'vuex-class';
import LoaderService from '@/app/shared/utils/loader.service';
import FactorsTab from './factors/FactorsTab.vue';
import FormulasTab from './FormulasTab.vue';
import ScoresTab from './ScoresTab.vue';
import { FormulateData } from '@/app/shared/models/calculations/FormulateData';
const CalculationsStore = namespace('Calculations');

@Component({
  components: { editor: Loader, FactorsTab, FormulasTab, ScoresTab },
})
export default class FormulateModal extends Vue {
  @Prop() unitUuid: string;

  activeTab: 'factors' | 'formulas' | 'scores' = 'factors';
  initialized: boolean = true;

  @CalculationsStore.Getter
  getFormulateData: FormulateData;

  @CalculationsStore.Action
  initializeFormulateData: (unitUuid: string) => Promise<any>;

  created() {
    // initialize formulate data
    // this.initializeUI();
  }

  get title() {
    return this.getFormulateData ? this.getFormulateData.name : null;
  }

  get criteriaList() {
    return this.getFormulateData ? this.getFormulateData.unit.checktrees[0] : null;
  }

  get formula() {
    return this.getFormulateData.formulas[0];
  }

  get scoreRanges() {
    return this.getFormulateData.scores;
  }

  async initializeUI() {
    try {
      LoaderService.disableHttpLoader();
      await this.initializeFormulateData(this.unitUuid);
    } catch (error) {
      console.error(error);
    } finally {
      LoaderService.enableHttpLoader();
      this.initialized = true;
    }
  }

  @Emit()
  close() {
    // just emiting event
  }
}
