import { FormFieldValue } from '@/app/shared/models/form/field';
import emailAddress from './email-address';

const validObjectEmail = (fieldValue: FormFieldValue<any>): boolean => {
  if (!Array.isArray(fieldValue.data) || !Array.isArray(fieldValue.deleted_uuid)) return false;

  if (!fieldValue.data.every((item) => emailAddress(item.email))) return false;

  return true;
};

export default validObjectEmail;
