import FormConfigUpload from '@/app/shared/components/form/config/FormConfigUpload.vue';
import FormFieldUpload from '@/app/shared/components/form/field/FormFieldUpload.vue';
import { UploadResult } from '@/app/shared/models/form/field/FormFieldUpload';

export default {
  name: 'Upload Control',
  description: 'Drag and drop file upload field',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldUpload,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigUpload,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    isMultiple: true,
    isInline: false,
    acceptType: '',
    isDisabled: false,
    maxSize: 0,
    helpText: '',
    isSmall: false,

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {array}
   */
  rendererDefaultData(): UploadResult[] {
    return [];
  },
};
