
import { Component, Watch, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import { namespace } from 'vuex-class';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ExpandableSidebarForm from '@/app/shared/components/mixins/ExpandableSidebarForm.vue';
import studyReviewsFormList from '@/app/shared/components/form/list/study-reviews';
import { FormList } from '@/app/shared/models/form/FormSchema';
import { Study } from '@/app/shared/models/Study';
import api from '@/app/shared/api';
import DialogService from '@/app/shared/utils/dialog.service';

dayjs.extend(customParseFormat);

const UserStore = namespace('User');

@Component
export default class StudyReviewsForm extends ExpandableSidebarForm {
  @Prop() studyUuid!: string;
  @Prop() workspaceUuid!: string;
  study: Study = null;
  formList: FormList[] = [];
  target: string = '';
  serverError: AxiosError = null;
  valueFormat: string = 'YYYY-MM-DD';

  @Watch('formId')
  onFormIdChange(formId: string) {
    this.setExpandableSidebarOptions(formId);
    this.getStudy();
  }

  @UserStore.Getter
  skipConfirmationDialog!: () => boolean;

  @UserStore.Mutation
  setSkipConfirmationDialog!: (value: boolean) => void;

  get studyReviewStage() {
    switch (this.formId) {
      case 'study-scoping':
        return 'scoping';
      case 'study-assessing':
        return 'assessing';
      case 'study-screening':
      default:
        return 'screening';
    }
  }

  get formDefaultValues() {
    if (!this.study.study_reviews || !this.study.study_reviews.length) return {};

    const studyReview = this.study.study_reviews.find((studyReview) => studyReview.stage === this.studyReviewStage);
    if (!studyReview) return {};

    return cloneDeep({
      uuid: studyReview.uuid,
      status: studyReview.status,
      events: studyReview.events,
    });
  }

  get isFormShown() {
    return Boolean(this.study);
  }

  get formHintText() {
    return 'Make sure that the events are in chronological order and that the correct progress status has been chosen. Also, make sure you enter both the first and last event so that the timeline can be displayed adequately.';
  }

  get backRoute() {
    return `/study-reviews/${this.studyUuid}/${this.workspaceUuid}`;
  }

  async created() {
    this.formList = studyReviewsFormList;
    this.getStudy();
  }

  async getStudy() {
    try {
      this.study = await api.study.getStudy(this.studyUuid);
    } catch (error) {
      console.warn(error);
    }
  }

  getFormRoute(formId: string) {
    return `/study-reviews/${this.studyUuid}/${this.workspaceUuid}/${formId}`;
  }

  async onSubmit(payload: any) {
    this.showDialog(payload);
  }

  showDialog(payload: any) {
    if (this.skipConfirmationDialog) {
      this.saveForm(payload);
      return;
    }

    const confirmCallback = (context: { checkboxValue: boolean }) => {
      this.setSkipConfirmationDialog(context.checkboxValue);
      this.saveForm(payload);
    };

    DialogService.presentDialog(
      'Are you sure the dates of the events are correct?',
      'This information may be important for other stakeholders.',
      'Yes',
      'Cancel',
      confirmCallback,
      () => {
        //
      },
      'Do not show me this again',
    );
  }

  async saveForm(payload: any) {
    this.serverError = null;

    try {
      await api.study.saveStudyEvents(this.studyUuid, {
        ...payload,
        stage: this.studyReviewStage,
      });

      await this.redirectOnSuccess();
    } catch (error) {
      this.serverError = error as AxiosError;
    }
  }
}
