export enum ChapterItemType {
  HEADING = 'ParagraphHeading',
  TEXT = 'ParagraphText',
  IMAGE = 'ParagraphImage', // figure
  TABLE = 'ParagraphEnvigoTable', // table
  SPREADSHEET = 'ParagraphSpreadsheetTable', // table
  STATISTIC = 'ParagraphEnvigoStatistic', // NB: not implemented yet!
  CHART = 'ParagraphEnvigoChart',
  IMPACT = 'ParagraphImpact', // NB: not implemented yet!
  IMPORTED_TABLE = 'ParagraphImportedTable', // NB: not implemented yet!
  LIBRARY_CONTENT = 'LibraryContent', // NB: temporary type only!
  SINGLE_IMPACT_HEADER = 'ParagraphHeader',
  LIBRARY_ENVIGO_TABLE = 'LibraryEnvigoTable',
  LIBRARY_ENVIGO_STATISTICS = 'LibraryEnvigoStatistic',
  LIBRARY_ENVIGO_CHART = 'LibraryEnvigoChart',
  LIBRARY_IMPACT = 'LibraryImpact',
  LIBRARY_IMAGE = 'LibraryImage',
  LIBRARY_SPREADSHEET_TABLE = 'LibrarySpreadsheetTable',
  LIBRARY_IMPORTED_TABLE = 'LibraryImportedTable',
}
