var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column form-field-assessor"},[_vm._l((_vm.assessors),function(assessor,index){return [_c('div',{key:("assessor-" + index + "-0")},[_c('FormFieldText',{ref:"formField",refInFor:true,attrs:{"control":{
          uniqueId: ((_vm.control.uniqueId) + "_title"),
          name: ("title_" + index),
          label: _vm.control.label,
          placeholderText: _vm.control.placeholderText,
          subLabel: _vm.control.subLabel,
          helpText: _vm.control.helpText,
          inputType: 'text',
          isDisabled: _vm.control.isDisabled,
          isReadonly: _vm.control.isReadonly,
        }},model:{value:(assessor.title),callback:function ($$v) {_vm.$set(assessor, "title", $$v)},expression:"assessor.title"}})],1),_c('div',{key:("assessor-" + index + "-1"),staticClass:"mt-3"},[_c('FormFieldText',{class:{
          'form-field-assessor--show-error': _vm.isRequiredObjectName,
        },attrs:{"control":{
          uniqueId: ((_vm.control.uniqueId) + "_name"),
          name: ("name_" + index),
          label: 'Company/institution name',
          placeholderText: 'Enter',
          subLabel: _vm.control.subLabel,
          inputType: 'text',
          isDisabled: _vm.control.isDisabled,
          isReadonly: _vm.control.isReadonly,
          validations: _vm.isRequiredObjectName ? [{ ruleType: 'required' }] : null,
        }},model:{value:(assessor.name),callback:function ($$v) {_vm.$set(assessor, "name", $$v)},expression:"assessor.name"}})],1),(!assessor.isDetailsHidden)?_c('div',{key:("assessor-" + index + "-2"),staticClass:"d-flex flex-column form-field-assessor__details"},[_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"form-field-assessor__details-spacer"}),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldText',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_address"),
              name: ("address_" + index),
              label: 'Address',
              placeholderText: 'Enter',
              subLabel: _vm.control.subLabel,
              inputType: 'text',
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(assessor.address),callback:function ($$v) {_vm.$set(assessor, "address", $$v)},expression:"assessor.address"}})],1),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldText',{class:{
              'form-field-assessor--show-error': _vm.isValidObjectEmail,
            },attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_email"),
              name: ("email_" + index),
              label: 'Contact email address',
              placeholderText: 'Enter',
              subLabel: _vm.control.subLabel,
              inputType: 'text',
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(assessor.email),callback:function ($$v) {_vm.$set(assessor, "email", $$v)},expression:"assessor.email"}})],1)]),_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"form-field-assessor__details-spacer"}),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldText',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_phone_number"),
              name: ("phone_number_" + index),
              label: 'Contact phone number',
              placeholderText: 'Enter',
              subLabel: _vm.control.subLabel,
              inputType: 'text',
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(assessor.phone_number),callback:function ($$v) {_vm.$set(assessor, "phone_number", $$v)},expression:"assessor.phone_number"}})],1),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldText',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_fax_number"),
              name: ("fax_number_" + index),
              label: 'Contact fax number',
              placeholderText: 'Enter',
              subLabel: _vm.control.subLabel,
              inputType: 'text',
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(assessor.fax_number),callback:function ($$v) {_vm.$set(assessor, "fax_number", $$v)},expression:"assessor.fax_number"}})],1)]),_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"flex-shrink-0 form-field-assessor__details-spacer"}),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldUploadInline',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_logo"),
              name: ("logo_" + index),
              label: 'Company/institution logo',
              subLabel: _vm.control.subLabel,
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(assessor.logo),callback:function ($$v) {_vm.$set(assessor, "logo", $$v)},expression:"assessor.logo"}})],1)])]):_vm._e(),_c('div',{key:("assessor-" + index + "-3"),staticClass:"d-flex flex-column form-field-assessor__toggles"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"form-field-assessor__toggles-spacer"}),_c('div',{staticClass:"d-flex flex-grow-1 justify-start"},[_c('BaseButton',{staticClass:"form-field-assessor__toggle-details-button",attrs:{"icon":assessor.isDetailsHidden ? 'show' : 'hide',"type":"quin"},on:{"click":function($event){return _vm.toggleDetails(assessor, $event)}}},[_vm._v(" "+_vm._s(assessor.isDetailsHidden ? 'Show Details' : 'Hide Details')+" ")])],1),_c('div',{staticClass:"d-flex flex-grow-1 justify-end"},[(index > 0)?_c('BaseButton',{staticClass:"form-field-assessor__delete-group-button",attrs:{"disabled":_vm.control.isDisabled || _vm.control.isReadonly,"type":"quin","icon":"delete"},on:{"click":function($event){return _vm.removeGroup(index)}}},[_vm._v(" Delete Group ")]):_vm._e()],1)])]),(!_vm.control.isDisabled && !_vm.control.isReadonly)?_c('div',{key:("assessor-" + index + "-4")},[_c('div',{staticClass:"d-flex align-center form-field-assessor__add-group",class:{
          'form-field-assessor__add-group--is-sm': _vm.$vuetify.breakpoint.smAndDown,
        }},[_c('v-icon',{on:{"click":function($event){return _vm.addGroup(index)}}},[_vm._v(" $add-v2 ")])],1)]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }