import { wsInstance } from './../websocket.service';
import store from './../../store';
import { UpdatedProperty } from '@/app/shared/store/modules/checklists.store-module';
import { WSMessageCommand } from '@/app/shared/enums/ws-message-command.enum';
import { WSMessageHandler } from '@/app/shared/enums/ws-message-handler.enum';
import { WSMessage } from '@/app/shared/models/WSMessage';

export default {
  addCheckTreeItem(afterCheckTreeItemUuid: string, route: string = null) {
    const message: WSMessage = {
      handler: WSMessageHandler.BASE_LOCKER,
      command: WSMessageCommand.ADD,
      params: {
        model: 'CheckTreeItem',
        after: afterCheckTreeItemUuid,
        uid: store.getters['User/userId'],
        route: route,
      },
      data: {},
    };
    wsInstance.sendMessage(message);
  },

  toggleLockCheckTreeItem(
    command: WSMessageCommand.LOCK | WSMessageCommand.UNLOCK,
    uuid: string,
    route: string = null,
  ) {
    const message: WSMessage = {
      handler: WSMessageHandler.BASE_LOCKER,
      command: command,
      params: {
        model: 'CheckTreeItem',
        uuid: uuid,
        uid: store.getters['User/userId'],
        route: route,
      },
      data: {},
    };
    wsInstance.sendMessage(message);
  },

  updateCheckTreeItem(
    uuid: string,
    propertyName: UpdatedProperty,
    propertyValue: string | boolean,
    route: string = null,
    lockedBy: any,
  ) {
    const dataObj: any = {
      uuid,
    };
    const apiPropertyName = propertyName === '$folded' ? 'folded' : propertyName;
    dataObj[apiPropertyName] = propertyValue;
    const message: WSMessage = {
      handler: WSMessageHandler.BASE_LOCKER,
      command: WSMessageCommand.UPDATE,
      params: {
        model: 'CheckTreeItem',
        uid: store.getters['User/userId'],
        route: route,
      },
      data: [dataObj],
    };
    if (!lockedBy) {
      this.toggleLockCheckTreeItem(WSMessageCommand.LOCK, uuid);
    }
    wsInstance.sendMessage(message);
  },

  deleteCheckTreeItem(uuid: string, route: string = null) {
    const message: WSMessage = {
      handler: WSMessageHandler.BASE_LOCKER,
      command: WSMessageCommand.DELETE,
      params: {
        model: 'CheckTreeItem',
        uid: store.getters['User/userId'],
        route: route,
      },
      data: [{ uuid: uuid }],
    };
    wsInstance.sendMessage(message);
  },

  reorderCheckTreeItem(uuid: string, afterCheckTreeItemUuid: string, asChild: boolean, route: string = null) {
    const message: WSMessage = {
      handler: WSMessageHandler.BASE_LOCKER,
      command: WSMessageCommand.REORDER,
      params: {
        model: 'CheckTreeItem',
        uuid: uuid,
        after: afterCheckTreeItemUuid,
        as_child: asChild ? 1 : 0,
        uid: store.getters['User/userId'],
        route: route,
      },
      data: {},
    };
    wsInstance.sendMessage(message);
  },

  propagateUpdateCheckTreeItems(
    propagateType: 'updateContainer' | 'updateAll',
    uuid: string,
    updatedPropertyName: UpdatedProperty,
    updatedPropertyValue: string | boolean,
    route: string = null,
  ) {
    let modelParam: 'CheckTree' | 'CheckTreeItem' = 'CheckTreeItem';
    if (propagateType !== 'updateContainer') {
      modelParam = 'CheckTree';
    }

    const apiPropertyName = updatedPropertyName === '$folded' ? 'folded' : updatedPropertyName;
    const dataObj: any = {};
    dataObj[apiPropertyName] = updatedPropertyValue;

    const message: WSMessage = {
      handler: WSMessageHandler.CHECKTREE_LOCKER,
      command: WSMessageCommand.PROPAGATE,
      params: {
        model: modelParam,
        uuid: uuid,
        uid: store.getters['User/userId'],
        route: route,
      },
      data: dataObj,
    };
    wsInstance.sendMessage(message);
  },

  updateChecktreeEnumerateValue(checktreeUuid: string, shouldEnumerate: boolean, route: string = null) {
    const dataObj: any = {
      uuid: checktreeUuid,
      enumerate: shouldEnumerate,
    };
    const message: WSMessage = {
      handler: WSMessageHandler.CHECKTREE_LOCKER,
      command: WSMessageCommand.UPDATE,
      params: {
        model: 'CheckTree',
        uid: store.getters['User/userId'],
        route: route,
      },
      data: [dataObj],
    };
    wsInstance.sendMessage(message);
  },
};
