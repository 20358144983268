





























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Loader from '@/app/shared/components/Loader.vue';
import type LibraryItem from '@/app/shared/models/library/LibraryItem';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import { FileResource } from '@/app/shared/models/File';

@Component({
  components: { editor: Loader },
})
export default class LibraryItemPreviewModal extends Vue {
  @Prop() item: LibraryItem;

  @Emit()
  close() {
    // just emitting event
  }

  get itemLabel() {
    switch (this.item && this.item.type) {
      case ChapterItemType.LIBRARY_ENVIGO_TABLE:
      case ChapterItemType.LIBRARY_IMPORTED_TABLE:
      case ChapterItemType.LIBRARY_SPREADSHEET_TABLE:
      case ChapterItemType.LIBRARY_ENVIGO_STATISTICS:
        return 'Table 1.1';
      case ChapterItemType.LIBRARY_CONTENT:
      case ChapterItemType.LIBRARY_ENVIGO_CHART:
      case ChapterItemType.LIBRARY_IMAGE:
      case ChapterItemType.LIBRARY_IMPACT:
        return 'Figure 1.1';
      default:
        return '';
    }
  }

  getFileType(type: string) {
    switch (type) {
      case 'Chart':
        return 'img';
      default:
        return 'div';
    }
  }

  getFileSrc(file: FileResource) {
    switch (file.type) {
      case 'Chart':
        return file.contents;
      default:
        return null;
    }
  }
}
