import { render, staticRenderFns } from "./Criteria.vue?vue&type=template&id=241376c6&scoped=true&"
import script from "./Criteria.vue?vue&type=script&lang=ts&"
export * from "./Criteria.vue?vue&type=script&lang=ts&"
import style0 from "./Criteria.vue?vue&type=style&index=0&id=241376c6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "241376c6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
