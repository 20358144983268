import { render, staticRenderFns } from "./DashboardDataTableExpandedRow.vue?vue&type=template&id=74b9ea54&scoped=true&"
import script from "./DashboardDataTableExpandedRow.vue?vue&type=script&lang=ts&"
export * from "./DashboardDataTableExpandedRow.vue?vue&type=script&lang=ts&"
import style0 from "./DashboardDataTableExpandedRow.vue?vue&type=style&index=0&id=74b9ea54&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74b9ea54",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VIcon,VItem,VItemGroup,VWindow,VWindowItem})
