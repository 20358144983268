






































































































import { Tree } from 'he-tree-vue';
import Vue from 'vue';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import { ChapterItem } from '@/app/shared/models/reports/ChapterItem';
import { namespace } from 'vuex-class';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import { Chapter } from '@/app/shared/models/reports/Chapter';

const ReportsModuleStore = namespace('Reports');
const UiModuleStore = namespace('UI');

@Component
export default class ReportsSidebarItem extends Vue {
  @Prop() node: ChapterItem;
  @Prop() path: number[];
  @Prop() tree: Tree;
  @Prop() index: number;
  @Prop() chapterNumber: string;
  @Prop() toggleResize: boolean;
  @Prop({ default: false, type: Boolean }) isEditModeAllowed: boolean;

  @ReportsModuleStore.Getter
  public getCurrentChapter!: Chapter;

  ChapterItemType = ChapterItemType;

  @ReportsModuleStore.Mutation
  public removeCurrentFlags!: () => void;

  @ReportsModuleStore.Action
  public toggleSidebarChapterItemFold!: (uuid: string) => void;

  @UiModuleStore.Getter
  isFooterCommentingActive: boolean;

  showPopover: boolean = false;

  created() {
    eventBus.$on(EventType.RERENDER_REPORT_SIDEBAR, () => {
      this.$forceUpdate();
    });
  }
  // get itemOrder() {
  //   if (this.node.numeration) {
  //     return this.node.numeration;
  //   }

  //   let parentNode = this.tree.getNodeParentByPath(this.path);
  //   if (!parentNode) {
  //     parentNode = this.getCurrentChapter;
  //   }

  //   let value: string = `${this.chapterNumber}`;
  //   for (let index = 0; index < this.path.length; index++) {
  //     if (index === this.path.length - 1) {
  //       let count = 0;
  //       const elIndex = this.path[index];

  //       for (let i = 0; i < elIndex; i++) {
  //         const el = parentNode.children[i];
  //         if (el.type !== ChapterItemType.HEADING) {
  //           count++;
  //         }
  //       }
  //       value = value + '.' + (elIndex + 1 - count);
  //     } else {
  //       const el = this.path[index];
  //       value = value + '.' + (el + 1);
  //     }
  //   }
  //   return value;
  // }

  public calculateShowPopover() {
    const element: any = document.getElementById(this.node.uuid);
    if (element && element.scrollWidth > element.offsetWidth) {
      this.showPopover = true;
    } else {
      this.showPopover = false;
    }
  }

  mounted() {
    // timeout so the content can expand and load
    setTimeout(() => {
      this.calculateShowPopover();
    }, 500);
  }

  public toggleFold() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    // this.tree.toggleFold(node, path);
    this.toggleSidebarChapterItemFold(this.node.uuid);
    this.collapseChangeEvent();
  }

  public scrollToParagraph() {
    const contentElement = document.getElementById(`content/${this.node.uuid}`);
    if (contentElement) {
      // TODO Fix for fixed header overlaps content
      // contentElement.scrollIntoView({ behavior: 'smooth' });
      const yOffset = -170;
      const y = contentElement.getBoundingClientRect().top + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
      this.removeCurrentFlags();
      this.node.isCurrent = true;
      eventBus.$emit(EventType.RERENDER_REPORT_SIDEBAR);
    }
  }

  @Watch('toggleResize')
  public recalculatePopover() {
    this.calculateShowPopover();
  }

  @Watch('node', { deep: true })
  public onNodeUpdate() {
    setTimeout(() => {
      this.calculateShowPopover();
    }, 0);
  }

  @Emit('collapse-change')
  public collapseChangeEvent() {
    //
  }
}
