
import { Component, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ExpandableSidebarForm from '@/app/shared/components/mixins/ExpandableSidebarForm.vue';
import addStudyFormSchema from '@/app/shared/components/form/schema/add-study.json';
import api from '@/app/shared/api';
import { User } from '@/app/shared/models/User';

@Component
export default class UserChangePasswordForm extends ExpandableSidebarForm {
  @Prop() organizationUuid: string;
  @Prop() userUuid: string;
  user: User = null;
  defaultValues = {};
  serverError: AxiosError = null;

  get form() {
    return {
      id: 'add-study',
      label: 'Add Study',
      schema: addStudyFormSchema,
    };
  }

  get formDefaultValues() {
    return {
      ...this.defaultValues,
      organization_uuid: this.organizationUuid,
    };
  }

  get formContainerClass() {
    return {
      'width-552': !this.isExpanded,
      'width-1128': this.isExpanded,
    };
  }

  get formWidth() {
    return this.isExpanded ? 1128 : 552;
  }

  get isFormSingle() {
    return true;
  }

  get hasFormSubmitButton() {
    return true;
  }

  get backRoute() {
    return '';
  }

  async created() {
    try {
      this.defaultValues = await api.organization.loadAddStudyForm(this.organizationUuid, this.userUuid);
    } catch (error) {
      console.warn(error);
    }
  }

  async onSubmit(payload: any) {
    this.serverError = null;

    try {
      await api.organization.submitAddStudyForm(this.organizationUuid, this.userUuid, payload);
      await this.redirectOnSuccess();
    } catch (error) {
      this.serverError = error as AxiosError;
    }
  }
}
