


















































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import StudyReviewsPageHeader from './components/StudyReviewsPageHeader.vue';
import StudyReviewsTimeline from './components/StudyReviewsTimeline.vue';
import StudyReviewsVersionsBar from './components/StudyReviewsVersionsBar.vue';
import PageFooter from '@/app/shared/components/PageFooter.vue';
import StudyReviewsItem from './components/StudyReviewsItem.vue';
import { Study } from '../shared/models/Study';
import api from '@/app/shared/api';
import { WorkspaceResponse } from '../shared/models/workspace/WorkspaceResponse';
import { WorkspaceResponseItem } from '../shared/models/workspace/WorkspaceResponseItem';

@Component({
  components: {
    PageFooter,
    StudyReviewsItem,
    StudyReviewsPageHeader,
    StudyReviewsTimeline,
    StudyReviewsVersionsBar,
  },
})
export default class StudyReviews extends Vue {
  @Prop() studyUuid: string;
  @Prop() workspaceUuid: string;
  study: Study = null;
  items: WorkspaceResponseItem[] = [];
  loading = true;
  areTimelineEventsShown = false;
  isVersionsBarExpanded = false;

  @Watch('studyUuid')
  @Watch('workspaceUuid')
  onUuidChange() {
    this.getItems();
  }

  get studyReviewsItems() {
    return this.items.reduce((studyReviewsItems: any, item: any) => ({ ...studyReviewsItems, [item.slug]: item }), {});
  }

  get studyReviewsStatus() {
    if (!this.study?.study_reviews?.length) return {};
    return this.study.study_reviews.reduce(
      (studyReviewsStatus: any, studyReview: any) => ({
        ...studyReviewsStatus,
        [studyReview.stage]: studyReview.status,
      }),
      {},
    );
  }

  get studyReviewsEvents() {
    if (!this.study?.study_reviews?.length) return {};
    return this.study.study_reviews.reduce(
      (studyReviewsStatus: any, studyReview: any) => ({
        ...studyReviewsStatus,
        [studyReview.stage]: studyReview.events.data,
      }),
      {},
    );
  }

  get activeItems() {
    if (!this.items?.length) return {};
    return this.items.reduce(
      (activeItems: any, item: any) => ({
        ...activeItems,
        [item.slug]: item.state && item.state.some((state: any) => state.active),
      }),
      {},
    ) as { [key: string]: boolean };
  }

  created() {
    this.getItems();
  }

  async getItems() {
    this.loading = true;

    try {
      // @TODO Ovo treba da se refaktorise i implementira u odnosu na workspace copy i povezivanje koje se ocekuje.
      // Ovo je zaostalo kao primer inije implementirano, povezano sa realnim WS items.
      this.study = await api.study.getStudy(this.studyUuid);

      const versions: any = await api.versioning.getStudyVersions(this.study.uuid);
      const data: WorkspaceResponse = await api.workspace.getStudyReviews(this.workspaceUuid);

      this.items = data.items;

      // TODO: dev only
      this.items = [
        ...this.items,
        ...[
          {
            state: [{ active: true }],
            slug: 'public-consultation-screening',
          },
          {
            state: [{ active: true }],
            slug: 'comments-overview-screening',
          },
          {
            state: [{ active: true }],
            slug: 'technical-report-screening',
          },
          {
            state: [{ active: true }],
            slug: 'decisions-screening',
          },
          {
            state: [{ active: true }],
            slug: 'questionnaire-screening',
          },
        ],
      ];
      this.study = {
        ...this.study,
        versions,
      };
    } catch (error) {
      console.warn(error);
    } finally {
      this.loading = false;
    }
  }

  toggleExpand() {
    this.isVersionsBarExpanded = !this.isVersionsBarExpanded;
  }

  toggleShow() {
    this.areTimelineEventsShown = !this.areTimelineEventsShown;
  }

  onVersionClick(studyUuid: string, workspaceUuid: string) {
    this.$router.push(`/study-reviews/${studyUuid}/${workspaceUuid}`);
  }

  onAddEvents(formId: string) {
    this.$router.push({
      name: 'StudyReviewsForm',
      params: {
        studyUuid: this.studyUuid,
        workspaceUuid: this.workspaceUuid,
        formId,
      },
    });
  }
}
