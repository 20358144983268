






import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { PieChartData } from '@/app/shared/models/presentations/Charts';

@Component
export default class MultiplesPieCharts extends Vue {
  @Prop() data: PieChartData[];
  charts: Chart[] = [];

  @Emit('chart-updated')
  chartUpdated(_base64Images: string[]) {
    // emitting event
  }

  @Watch('data')
  onDataChanged() {
    this.charts.forEach((chart) => {
      if (chart) chart.destroy();
    });

    this.initializeCharts();
  }

  mounted() {
    this.initializeCharts();
  }

  initializeCharts() {
    if (!this.data || !this.data.length || !this.$refs.chartCanvas) return;

    const base64Images: string[] = [];

    this.data.forEach((chartData, index) => {
      if (!chartData.data) return;
      base64Images.push(this.drawChart(chartData, index));
    });

    this.chartUpdated(base64Images);
  }

  drawChart(chartData: PieChartData, chartIndex: number): string {
    const chartCanvas = (this.$refs.chartCanvas as HTMLCanvasElement[])[chartIndex];
    chartCanvas.height = 300;
    chartCanvas.width = 300;

    const data: ChartData = {
      labels: chartData.data.map((el) => el.label.toString().toUpperCase()),
      datasets: [
        {
          // minBarLength: 60,
          data: chartData.data.map((el) => el.value),
          backgroundColor: chartData.data.map((el) => el.color || '#22A2D0'),
        },
      ],
    };

    let chart: Chart = null;

    const options: ChartOptions = {
      legend: { display: false },
      maintainAspectRatio: false,
      responsive: false,
      title: {
        display: true,
        text: chartData.title,
        position: 'bottom',
        fontColor: '#222C37',
        fontFamily: "'Graphik', Avenir, Helvetica, Arial, sans-serif",
        fontSize: 18,
        fontStyle: '500',
      },
      scales: {
        xAxes: [
          {
            gridLines: { display: false, drawOnChartArea: false },
            ticks: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: { display: false, drawOnChartArea: false },
            ticks: { display: false, beginAtZero: true },
          },
        ],
      },
      plugins: {
        datalabels: {
          color: 'white',
          font: {
            family: "'Graphik', Avenir, Helvetica, Arial, sans-serif",
            weight: 500,
            size: 14,
          },
          formatter: function(value, context) {
            return context.chart.data.labels[context.dataIndex];
          },
        },
      },
      animation: null,
    };

    const chartConfig = {
      plugins: [ChartDataLabels],
      type: 'doughnut',
      data: data,
      options: options,
    };

    chart = new Chart(chartCanvas, chartConfig);

    this.charts.push(chart);

    return chart.toBase64Image();
  }
}
