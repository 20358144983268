





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SingleImpact } from '@/app/shared/models/reports/SingleImpact';
import { ToggleSingleImpactCheckPayload } from '@/app/shared/store/modules/presentations.store-module';
const PresentationsStore = namespace('Presentations');

@Component({
  components: {},
})
export default class PresentationsImpactsRow extends Vue {
  @Prop() impact: SingleImpact;

  style = {
    baseCheckbox: {},
  };

  get isLink() {
    return true;
  }

  get assessStatus(): 'default' | 'in-progress' | 'done' {
    return 'default';
  }

  @PresentationsStore.Action
  toggleSingleImpactCheck: (payload: ToggleSingleImpactCheckPayload) => Promise<any>;

  async onCheckboxChange(value: boolean) {
    try {
      await this.toggleSingleImpactCheck({ impact: this.impact, checked: value });
    } catch (error) {
      console.error(error);
    }
  }

  openSingleImpactPage() {
    this.$router.push(`/single-impact/${this.impact.uuid}`);
  }
}
