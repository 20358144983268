export default (fieldValue: string): boolean => {
  // Will be handled by the required validator.
  if (fieldValue === undefined || fieldValue === null || !fieldValue.length) return true;

  // Look for maximum five groups of word characters, all the while ignoring:
  // - extra non-word/punctuation characters like parentheses, dots, etc.
  // - extra spaces at the beginning or the end of the string value (#96)
  if (/^(?:\w+\W+){0,4}(?:\w+)$/.test(fieldValue.trim().replaceAll(/[^\w\s]/g, ''))) return true;

  return false;
};
