export enum WSMessageCommand {
  LOCK = 'lock',
  UNLOCK = 'unlock',
  ADD = 'add',
  UPDATE = 'update',
  DELETE = 'delete',
  REORDER = 'reorder',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  PROPAGATE = 'propagate',
}
