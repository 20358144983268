
















































import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ResizableTableCells from '@/app/shared/components/mixins/ResizableTableCells.vue';
import DialogService from '@/app/shared/utils/dialog.service';
import { TooltipOptions } from '@/app/shared/store/modules/ui.store-module';
import MatrixRelationField from '@/app/shared/models/MatrixRelationField';
import MatrixRelationMatrix from '@/app/shared/models/MatrixRelationMatrix';

const MatrixRelationsStore = namespace('MatrixRelations');
const UiStore = namespace('UI');
const UserStore = namespace('User');

@Component
export default class MatrixRelationsRowHeaderFirstType extends ResizableTableCells {
  @Prop() index: number;
  @Prop() row: MatrixRelationField;
  @Prop() rerender: boolean;
  @MatrixRelationsStore.Getter
  public matrixRelationsData: MatrixRelationMatrix;
  @MatrixRelationsStore.Mutation
  public selectWholeRow!: (row: MatrixRelationField) => void;
  @UiStore.Action
  showCallout!: (payload: TooltipOptions) => void;
  @UiStore.Action
  hideCallout!: () => void;

  @UserStore.Getter
  skipConfirmationDialog: () => boolean;

  @UserStore.Mutation
  setSkipConfirmationDialog: (value: boolean) => void;

  setShowCallout() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const rect = this.$refs.tableHead.getBoundingClientRect();
    this.showCallout({
      text: this.row.title,
      top: rect.top + 10,
      left: rect.right,
      position: 'bottom',
    });
  }

  toogleExpand(row: MatrixRelationField) {
    this.$emit('toogle-expand', row);
  }

  selectRow() {
    if (!this.skipConfirmationDialog) {
      const confirmCallback = (context: { checkboxValue: boolean }) => {
        this.setSkipConfirmationDialog(context.checkboxValue);
        this.selectWholeRow(this.row);
        this.$emit('rerender-items');
      };

      DialogService.presentDialog(
        `Are you sure you want to select / deselect all?`,
        'This action will immediately update the existing list of items in the following modules.',
        'Yes',
        'Cancel',
        confirmCallback,
        () => {
          //
        },
        'Do not show me this again',
      );
      return;
    }

    this.selectWholeRow(this.row);
    this.$emit('rerender-items');
  }

  get wholeRowSelected() {
    return (rowId: number) => {
      if (!this.matrixRelationsData.matrix[rowId]) {
        return false;
      }
      return this.matrixRelationsData.matrix[rowId].filter((item: boolean) => item === false).length === 0;
    };
  }

  @Watch('rerender')
  updateComponent() {
    // TODO: Check if you can solve better
    this.$forceUpdate();
  }

  onRowResize(event: MouseEvent) {
    this.currentCell = this.$refs.tableHead as HTMLTableCellElement;
    this.onCellResize(event);
  }
}
