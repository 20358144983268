























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NavigationPanelButton extends Vue {
  @Prop() icon: string;
  @Prop() status?: 'inactive' | 'not-enough' | 'enough' | 'full';
  @Prop() isHidden?: boolean;

  @Emit()
  click() {
    //
  }
}
