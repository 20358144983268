import registerAcceptFormSchema from '@/app/shared/components/form/schema/register-accept.json';
import registerRoleFormSchema from '@/app/shared/components/form/schema/register-role.json';
import registerUserFormSchema from '@/app/shared/components/form/schema/register-user.json';
import { FormList } from '@/app/shared/models/form/FormSchema';

export default [
  {
    id: 'register-accept',
    label: 'Register (Accept)',
    schema: registerAcceptFormSchema,
  },
  {
    id: 'register-role',
    label: 'Register (Role)',
    schema: registerRoleFormSchema,
  },
  {
    id: 'register-user',
    label: 'Register (User)',
    schema: registerUserFormSchema,
  },
] as FormList[];
