import FormConfigUploadInline from '@/app/shared/components/form/config/FormConfigUploadInline.vue';
import FormFieldUploadInline from '@/app/shared/components/form/field/FormFieldUploadInline.vue';

export default {
  name: 'Inline Upload Control',
  description: 'Drag and drop inline file upload field with image preview',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldUploadInline,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigUploadInline,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    isDisabled: false,
    maxSize: 0,
    helpText: '',
    isSmall: false,

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {string}
   */
  rendererDefaultData(): string {
    return '';
  },
};
