import ExpandableSidebar from '@/app/shared/components/ExpandableSidebar.vue';
import Login from '@/app/auth/Login.vue';
import Register from '@/app/auth/Register.vue';
import ConfirmRegistration from '@/app/auth/ConfirmRegistration.vue';
import ErrorPage from '@/app/auth/ErrorPage.vue';
import Recover from '@/app/auth/Recover.vue';
import AdminLandingPage from '@/app/home/AdminLandingPage.vue';
import LandingPage from '@/app/home/LandingPage.vue';
import nProgress from 'nprogress';
import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import store from '../app/shared/store';
import UserChangePasswordForm from '@/app/user/UserChangePasswordForm.vue';
import UserProfileForm from '@/app/user/UserProfileForm.vue';
import UserAddStudyForm from '@/app/user/UserAddStudyForm.vue';
import OrganizationsDashboard from '@/app/organization/OrganizationsDashboard.vue';
import OrganizationUsersDashboard from '@/app/organization/OrganizationUsersDashboard.vue';
import OrganizationForm from '@/app/organization/OrganizationForm.vue';
import StudiesDashboard from '@/app/study/StudiesDashboard.vue';
import StudyRegistration from '@/app/study/StudyRegistration.vue';
import StudyRegistrationForm from '@/app/study/StudyRegistrationForm.vue';
import StudyReviews from '@/app/study/StudyReviews.vue';
import StudyReviewsForm from '@/app/study/StudyReviewsForm.vue';
import TemplatesPanel from '@/app/templates/TemplatesPanel.vue';
import MatrixRelations from '@/app/matrix-relations/MatrixRelations.vue';
import Reports from '@/app/reports/Reports.vue';
import Calculations from '@/app/calculations/Calculations.vue';
import Presentations from '@/app/presentations/Presentations.vue';
import PdfOutput from '@/app/reports/PdfOutput.vue';
import Workspace from '@/app/workspace/Workspace.vue';
import Workflow from '@/app/workflow/Workflow.vue';
import FormBuilder from '@/app/form-builder/FormBuilder.vue';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

nProgress.configure({ showSpinner: false });

const nonAuthenticatedRoutes = ['Login', 'Register', 'Recover', 'ConfirmRegistration', 'ErrorPage'];

const getLoginPath = (to: Route) => {
  if (to.fullPath && to.fullPath !== '/') return `/login?redirect=${encodeURIComponent(to.fullPath)}`;
  return '/login';
};

const authGuard = async (to: Route, from: Route, next: any) => {
  // Non-authenticated users should be redirected to the login screen,
  //   if they are trying to access a route that requires authentication.
  //   We also pass the information about requested URI to redirect the user on login.
  if (!store.getters['User/isAuthenticated'] && !nonAuthenticatedRoutes.includes(to.name)) {
    next(getLoginPath(to));
    nProgress.done();
  }

  if (store.getters['User/isAuthenticated']) {
    // Authenticated users should be redirected to an appropriate landing page, depending on their permissions.
    //   This kicks in only if they requested a route that does not require authentication.
    if (nonAuthenticatedRoutes.includes(to.name) && to.name !== 'ErrorPage') {
      if (await store.dispatch('User/isAllowed', UserPermission.ADMIN_LANDING_ACCESS)) next('/');
      else if (await store.dispatch('User/isAllowed', UserPermission.LANDING_ACCESS)) next('/landing');
      else next('/error/404');
      nProgress.done();
    }

    // If the route requires an access permission, here is where we check it.
    else if (to.meta.permission) {
      // This check will wait until the session data is in place, and defaults to `false`.
      //   It may happen that it invalidates the user's token.
      if (await store.dispatch('User/isAllowed', to.meta.permission)) {
        next();
      }

      // We redirect to the forbidden error page, but only in case the user is still authenticated.
      else if (store.getters['User/isAuthenticated']) {
        next('/error/403');
      }

      // If the user is suddenly unauthenticated, we redirect them to the login screen.
      //   We also pass the information about requested URI to redirect the user on login.
      else {
        next(getLoginPath(to));
      }

      nProgress.done();
    }
  }

  // Catch-all, no-op.
  next();
};

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'AdminLandingPage',
    component: AdminLandingPage,
    beforeEnter: authGuard,
    meta: {
      title: 'Admin Landing Page - Envigo',
      permission: UserPermission.ADMIN_LANDING_ACCESS,
    },
  },
  {
    path: '/landing',
    name: 'LandingPage',
    component: LandingPage,
    beforeEnter: authGuard,
    meta: {
      title: 'Landing Page - Envigo',
      permission: UserPermission.LANDING_ACCESS,
    },
  },
  {
    path: '/register/confirm/:uuid?',
    name: 'ConfirmRegistration',
    component: ConfirmRegistration,
    beforeEnter: authGuard,
    meta: { title: 'Confirm Registration - Envigo' },
    props: true,
  },
  {
    path: '/error/:type?/:message?',
    name: 'ErrorPage',
    component: ErrorPage,
    beforeEnter: authGuard,
    meta: { title: 'Error - Envigo' },
    props: true,
  },
  {
    path: '',
    name: 'WelcomeSidebar',
    component: ExpandableSidebar,
    props: {
      isExpandable: true,
      isContentCentered: true,
      backgroundType: 'welcome',
      headingText: 'Welcome!',
      subHeadingText:
        'You are entering a digital space of Environmental and Social Impact Assessment.<br />Have a fruitful and pleasant experience with Envigo.',
      buttons: [
        {
          label: 'Privacy Policy',
          route: '/privacy',
        },
        {
          label: 'Envigo Terms',
          route: '/terms',
        },
      ],
      contentWidth: 552,
    },
    children: [
      {
        path: '/login/:mode?',
        name: 'Login',
        component: Login,
        beforeEnter: authGuard,
        meta: { title: 'Login - Envigo' },
        props: true,
      },
      {
        path: '/register/:role?/:email?/:uuid?',
        name: 'Register',
        component: Register,
        beforeEnter: authGuard,
        meta: { title: 'Register - Envigo' },
        props: true,
      },
    ],
  },
  {
    path: '/recover/:token',
    name: 'Recover',
    component: Recover,
    beforeEnter: authGuard,
    meta: { title: 'Recover - Envigo' },
    props: true,
  },
  {
    path: '',
    name: 'StudySidebar',
    component: ExpandableSidebar,
    props: {
      isExpandable: true,
      backgroundType: 'study-registration',
      headingText: 'Study Registration',
    },
    children: [
      {
        path: '/study-registration/:uuid?',
        name: 'StudyRegistration',
        component: StudyRegistration,
        beforeEnter: authGuard,
        meta: {
          title: 'Study Registration - Envigo',
          permission: UserPermission.STUDY_REGISTRATION_ACCESS,
        },
        props: true,
      },
      {
        path: '/study-registration/:uuid/:formId',
        name: 'StudyRegistrationForm',
        component: StudyRegistrationForm,
        beforeEnter: authGuard,
        meta: {
          title: 'Study Registration Form - Envigo',
          permission: UserPermission.STUDY_REGISTRATION_ACCESS,
        },
        props: true,
      },
    ],
  },
  {
    path: '',
    name: 'TemplatesSidebar',
    component: ExpandableSidebar,
    props: {
      isExpandable: false,
      backgroundType: 'templates',
      headingText: 'Templates',
    },
    children: [
      {
        path: '/templates/:studyUuid/:moduleUuid/:workspaceItemUuid/:stage',
        name: 'TemplatesPanel',
        component: TemplatesPanel,
        beforeEnter: authGuard,
        meta: {
          title: 'Templates - Envigo',
          permission: UserPermission.REPORT_TEMPLATES_ACCESS,
        },
        props: true,
      },
    ],
  },
  {
    path: '/matrix-relations/:uuid',
    name: 'MatrixRelations',
    component: MatrixRelations,
    beforeEnter: authGuard,
    meta: {
      title: 'Matrix relations - Envigo',
      permission: [
        UserPermission.CROSS_CONNECT_MATRIX_ACCESS,
        UserPermission.TAGGING_ADMIN_MATRIX_ACCESS,
        UserPermission.TAGGING_USER_MATRIX_ACCESS,
      ],
    },
  },
  {
    path: '/checktree/:uuid',
    name: 'UserCheckTree',
    component: () => import(/* webpackChunkName: "checktree" */ '@/app/checktree/CheckTree.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'CheckTree - Envigo',
      permission: UserPermission.CHECKTREE_USER_ACCESS,
    },
    props: {
      adminPermission: UserPermission.CHECKTREE_USER_ADMIN_TOOLS,
    },
  },
  {
    path: '/checktree/:uuid/:workspaceItemUuid/:stage/:templateType',
    name: 'TemplateCheckTree',
    component: () => import(/* webpackChunkName: "checktree" */ '@/app/checktree/CheckTree.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'CheckTree - Envigo',
      permission: UserPermission.TEMPLATE_CHECKTREE_ACCESS,
    },
    props: {
      adminPermission: UserPermission.TEMPLATE_CHECKTREE_ADMIN_TOOLS,
    },
  },
  {
    path: '/admin/checktree/:uuid',
    name: 'AdminCheckTree',
    component: () => import(/* webpackChunkName: "checktree" */ '@/app/checktree/CheckTree.vue'),
    beforeEnter: authGuard,
    meta: {
      title: 'CheckTree - Envigo',
      permission: UserPermission.CHECKTREE_ADMIN_ACCESS,
    },
    props: {
      adminPermission: UserPermission.CHECKTREE_ADMIN_TOOLS,
    },
  },
  {
    path: '/reports/:studyUuid/:uuid/:chapter?',
    name: 'Reports',
    component: Reports,
    beforeEnter: authGuard,
    meta: {
      title: 'Reports - Envigo',
      permission: [UserPermission.FULL_REPORTS_ACCESS, UserPermission.SUMMARY_REPORTS_ACCESS],
    },
    props: (route) => ({
      studyUuid: route.params.studyUuid,
      uuid: route.params.uuid,
      chapter: route.params.chapter,
      editPermission: [UserPermission.FULL_REPORTS_EDIT_MODE, UserPermission.SUMMARY_REPORTS_EDIT_MODE],
    }),
  },
  {
    path: '/how-to-use/:uuid/:chapter?',
    name: 'How To Use',
    component: Reports,
    beforeEnter: authGuard,
    meta: {
      title: 'How To Use - Envigo',
      permission: [UserPermission.HTU_REPORTS_ACCESS],
    },
    props: (route) => ({
      htu: true,
      uuid: route.params.uuid,
      chapter: route.params.chapter,
      editPermission: [UserPermission.HTU_REPORTS_EDIT_MODE],
    }),
  },
  {
    path: '/single-impact/:studyUuid/:uuid',
    name: 'SingleImpact',
    component: Reports,
    beforeEnter: authGuard,
    meta: {
      title: 'Single Impact Assesment - Envigo',
      permission: UserPermission.SINGLE_IMPACT_REPORT_ACCESS,
    },
    props: (route) => ({
      singleImpactView: true,
      fromMatrix: typeof route.query.fromMatrix !== 'undefined' && route.query.fromMatrix === '1' ? true : false,
      studyUuid: route.params.studyUuid,
      uuid: route.params.uuid,
      editPermission: UserPermission.SINGLE_IMPACT_REPORT_EDIT_MODE,
    }),
  },
  {
    path: '/study-reviews/:studyUuid/:workspaceUuid',
    name: 'StudyReviews',
    component: StudyReviews,
    beforeEnter: authGuard,
    meta: {
      title: 'Study Reviews - Envigo',
      permission: UserPermission.STUDY_REVIEW_ACCESS,
    },
    props: true,
  },
  {
    path: '',
    name: 'StudyReviewsSidebar',
    component: ExpandableSidebar,
    props: {
      isExpandable: true,
    },
    children: [
      {
        path: '/study-reviews/:studyUuid/:workspaceUuid/:formId',
        name: 'StudyReviewsForm',
        component: StudyReviewsForm,
        beforeEnter: authGuard,
        meta: {
          title: 'Study Reviews Timeline - Envigo',
          permission: UserPermission.TIMELINE_FORMS_ACCESS,
        },
        props: true,
      },
    ],
  },
  {
    path: '/pdf-output/:studyUuid/:stage',
    name: 'PdfOutput',
    component: PdfOutput,
    beforeEnter: authGuard,
    meta: {
      title: 'PDF Output - Envigo',
      permission: UserPermission.PDF_OUTPUT_ACCESS,
    },
    props: true,
  },
  {
    path: '/workspace/:uuid',
    name: 'Workspace',
    component: Workspace,
    beforeEnter: authGuard,
    meta: {
      title: 'Workspace - Envigo',
      permission: UserPermission.WORKSPACE_ACCESS,
    },
    props: true,
  },
  {
    path: '/workflow/:uuid',
    name: 'Workflow',
    component: Workflow,
    beforeEnter: authGuard,
    meta: {
      title: 'Workflow - Envigo',
      permission: UserPermission.WORKFLOW_ACCESS,
    },
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '@/app/about/About.vue'),
  // },
  {
    path: '',
    name: 'UserSidebar',
    component: ExpandableSidebar,
    props: {
      isExpandable: true,
    },
    children: [
      {
        path: '/change-password',
        name: 'UserChangePasswordForm',
        component: UserChangePasswordForm,
        beforeEnter: authGuard,
        meta: { title: 'Change Password - Envigo' },
        props: true,
      },
      {
        path: '/user-profile',
        name: 'UserProfileForm',
        component: UserProfileForm,
        beforeEnter: authGuard,
        meta: {
          title: 'User Profile - Envigo',
          permission: UserPermission.USER_PROFILE_ACCESS,
        },
        props: {
          formId: 'user-profile',
        },
      },
      {
        path: '/user-profile/:userUuid/:organizationUuid',
        name: 'EditUserProfileForm',
        component: UserProfileForm,
        beforeEnter: authGuard,
        meta: {
          title: 'Edit User Profile - Envigo',
          permission: UserPermission.EDIT_USER_PROFILE_ACCESS,
        },
        props: (route) => ({
          formId: 'edit-user-profile',
          userUuid: route.params.userUuid,
          organizationUuid: route.params.organizationUuid,
        }),
      },
      {
        path: '/change-main-organization/:userUuid/:organizationUuid',
        name: 'ChangeMainOrganization',
        component: UserProfileForm,
        beforeEnter: authGuard,
        meta: {
          title: 'Change Main Organization - Envigo',
          permission: UserPermission.CHANGE_MAIN_ORGANIZATION_ACCESS,
        },
        props: (route) => ({
          formId: 'change-main-organization',
          userUuid: route.params.userUuid,
          organizationUuid: route.params.organizationUuid,
        }),
      },
      {
        path: '/add-study/:organizationUuid/:userUuid',
        name: 'UserAddStudyForm',
        component: UserAddStudyForm,
        beforeEnter: authGuard,
        meta: {
          title: 'Add Study - Envigo',
          permission: UserPermission.ADD_STUDY_FORM_ACCESS,
        },
        props: true,
      },
    ],
  },
  {
    path: '/organizations',
    name: 'OrganizationsDashboard',
    component: OrganizationsDashboard,
    beforeEnter: authGuard,
    meta: {
      title: 'Organizations - Envigo',
      permission: UserPermission.ORGANIZATIONS_DASHBOARD_ACCESS,
    },
    props: true,
  },
  {
    path: '/organization/:uuid',
    name: 'OrganizationUsersDashboard',
    component: OrganizationUsersDashboard,
    beforeEnter: authGuard,
    meta: {
      title: 'Organization Users - Envigo',
      permission: UserPermission.ORGANIZATION_USERS_DASHBOARD_ACCESS,
    },
    props: true,
  },
  {
    path: '',
    name: 'OrganizationSidebar',
    component: ExpandableSidebar,
    props: {
      isExpandable: true,
    },
    children: [
      {
        path: '/organization/:uuid/:formId',
        name: 'OrganizationForm',
        component: OrganizationForm,
        beforeEnter: authGuard,
        meta: {
          title: 'Organization Form - Envigo',
          permission: [
            UserPermission.CREATE_ORGANIZATION_FORM_ACCESS,
            UserPermission.EDIT_ORGANIZATION_DETAILS_ACCESS,
            UserPermission.SAAS_DETAILS_FORM_ACCESS,
            UserPermission.INVITE_NEW_USER_ACCESS,
          ],
        },
        props: true,
      },
    ],
  },
  {
    path: '/studies/:studyUuid?',
    name: 'StudiesDashboard',
    component: StudiesDashboard,
    beforeEnter: authGuard,
    meta: {
      title: 'Studies - Envigo',
      permission: UserPermission.STUDIES_DASHBOARD_ACCESS,
    },
    props: true,
  },
  {
    path: '/calculations/:processorType/:unitListId/:findByClass',
    name: 'CalculationsMethod',
    component: Calculations,
    beforeEnter: authGuard,
    meta: {
      title: 'Calculations - Envigo',
      permission: [
        UserPermission.PROCESSOR_ADMIN_DEFINE_ACCESS,
        UserPermission.PROCESSOR_ADMIN_FORMULATE_ACCESS,
        UserPermission.PROCESSOR_USER_ASSESS_ACCESS,
        UserPermission.PROCESSOR_USER_DEFINE_ACCESS,
      ],
    },
    props: true,
  },
  {
    path: '/presentations/:studyUuid/:moduleUuid',
    name: 'Presentations',
    component: Presentations,
    beforeEnter: authGuard,
    meta: {
      title: 'Presentations - Envigo',
      permission: UserPermission.PRESENTATIONS_ACCESS,
    },
    props: true,
  },
  {
    path: '/form-builder',
    name: 'FormBuilder',
    component: FormBuilder,
    beforeEnter: authGuard,
    meta: {
      title: 'Form Builder - Envigo',
      permission: UserPermission.FORM_BUILDER_ACCESS,
    },
  },

  // The "catch-all" route, redirects to error page.
  {
    path: '*',
    redirect: '/error/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  // If this isn't an initial page load...
  if (routeFrom.name !== null) {
    // Start the route progress bar.
    nProgress.start();
  }
  next();
});

const DEFAULT_TITLE = 'Envigo';
// When each route is finished evaluating...
router.afterEach((routeTo) => {
  // Complete the animation of the route progress bar.
  nProgress.done();
  Vue.nextTick(() => {
    document.title = routeTo.meta.title || DEFAULT_TITLE;
  });
});

export default router;
