export enum PresentationsSidebarOptionItemEnum {
  TABLE_LEVEL_OF_DETAIL_ONLY_LIST = 'table_level_of_detail_only_list',
  TABLE_LEVEL_OF_DETAIL_WITH_COMMENTS = 'table_level_of_detail_with_comments',
  TABLE_LEVEL_OF_DETAIL_WITH_DETAILS = 'table_level_of_detail_with_details',
  TABLE_LEVEL_OF_DETAIL_WITH_DETAILS_AND_COMMENTS = 'table_level_of_detail_with_details_and_comments',
  TABLE_SIZE_FULL = 'table_size_full',
  TABLE_SIZE_HALF = 'table_size_half',
  TABLE_SORT_FROM_MOST_NEGATIVE = 'table_sort_from_most_negative',
  TABLE_SORT_FROM_MOST_POSITIVE = 'table_sort_from_most_positive',
  TABLE_SORT_FROM_TOP_5_NEGATIVE = 'table_sort_top_5_negative',
  TABLE_SORT_FROM_TOP_5_POSITIVE = 'table_sort_top_5_positive',
  TABLE_SORT_FROM_TOP_10_NEGATIVE = 'table_sort_top_10_negative',
  TABLE_SORT_FROM_TOP_10_POSITIVE = 'table_sort_top_10_positive',
  TABLE_ENUMERATE_WITH_NUMBERS = 'table_enumerate_with_numbers',
  TABLE_ENUMERATE_WITHOUT_NUMBERS = 'table_enumerate_without_numbers',
  STATS_SIZE_FULL = 'stats_size_full',
  STATS_SIZE_HALF = 'stats_size_half',
  STATS_RECEPTORS = 'Receptors',
  STATS_DEV_PHASES = 'Development phases',
  STATS_ALTERNATIVES = 'Alternatives',
  CHARTS_NORMAL_WITHOUT_NUMBERS = 'charts_normalization_without_numbers',
  CHARTS_NORMAL_PER_NUMBER_OF_SELECTED_CATEGORIES = 'charts_normalization_per_number_of_selected_categories',
  CHARTS_NORMAL_PER_TOTAL_NUMBER = 'charts_normalization_per_total_numbers',
  CHARTS_GROUPED_FOR_RECEPTORS = 'charts_grouped_for_receptors',
  CHARTS_GROUPED_FOR_DEV_PHASES = 'charts_grouped_for_dev_phases',
  CHARTS_GROUPED_FOR_ALTERNATIVES = 'charts_grouped_for_alternatives',
  CHARTS_STACKED_FOR_RECEPTORS = 'charts_stacked_for_receptors',
  CHARTS_STACKED_FOR_DEV_PHASES = 'charts_stacked_for_dev_phases',
  CHARTS_STACKED_FOR_ALTERNATIVES = 'charts_stacked_for_alternatives',
  NONE = 'none',
}
