import FormConfigLocation from '@/app/shared/components/form/config/FormConfigLocation.vue';
import FormFieldLocation from '@/app/shared/components/form/field/FormFieldLocation.vue';
import { Location } from '@/app/shared/models/form/field/FormFieldLocation';
import { FormFieldValue, FormFieldValueModel } from '@/app/shared/models/form/field';

export default {
  name: 'Location Control',
  description: 'Complex input for location info',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldLocation,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigLocation,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    isDisabled: false,
    isReadonly: false,

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {FormFieldValue<Location>}
   */
  rendererDefaultData(): FormFieldValue<Location> {
    return new FormFieldValue([new Location()], FormFieldValueModel.LOCATION);
  },
};
