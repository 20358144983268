




















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseTextField extends Vue {
  @Prop() private placeholder!: string;
  @Prop() private value!: string;
  @Prop() private type: 'default' | 'title';
  @Prop({ default: false, type: Boolean }) isDark: boolean;

  @Emit()
  input(_value: string) {
    // just emiting event
    this.change(_value);
  }

  @Emit()
  change(_value: string) {
    // just emiting event
  }
  @Emit('paste')
  paste() {
    // just emiting event
  }
  @Emit()
  blur(_event: any) {
    // just emiting event
  }

  focus() {
    if (this.$refs.textField) (this.$refs.textField as HTMLInputElement).focus();
  }
}
