import { render, staticRenderFns } from "./CommentingContent.vue?vue&type=template&id=118f12fb&scoped=true&"
import script from "./CommentingContent.vue?vue&type=script&lang=ts&"
export * from "./CommentingContent.vue?vue&type=script&lang=ts&"
import style0 from "./CommentingContent.vue?vue&type=style&index=0&id=118f12fb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "118f12fb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTab,VTabs})
