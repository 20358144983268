













































import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AxiosError } from 'axios';
import NavigationPanelButton from '@/app/shared/components/NavigationPanelButton.vue';
import ExpandableSidebarRoute from '@/app/shared/components/mixins/ExpandableSidebarRoute.vue';
import studyRegistrationFormList from '@/app/shared/components/form/list/study-registration';
import api from '@/app/shared/api';
import { ExpandableSidebarOptions } from '@/app/shared/models/ExpandableSidebar';
import { FormList } from '@/app/shared/models/form/FormSchema';
import { Study } from '@/app/shared/models/Study';

const UserStore = namespace('User');

@Component({
  components: {
    NavigationPanelButton,
  },
})
export default class StudyRegistration extends ExpandableSidebarRoute {
  @Prop() uuid: string;
  study: Study = null;
  formList: FormList[] = [];
  expandableSidebarOptions: ExpandableSidebarOptions = { isExpandable: false };
  isError: boolean = false;
  errorMessage: string = '';

  @UserStore.Getter
  userOrganizationUuid: string;

  get isBuildCreated() {
    return this.study.build_created;
  }

  get canBeBuilt() {
    return this.study.workflow_id && this.study.has_types;
  }

  async created() {
    if (this.uuid) {
      try {
        this.study = await api.study.getStudy(this.uuid);
      } catch (error) {
        this.isError = true;
        console.warn(error);
      }
    } else {
      try {
        this.study = await api.study.createStudy(this.userOrganizationUuid);
        this.$router.replace(`/study-registration/${this.study.uuid}`);
      } catch (error) {
        this.isError = true;
        console.warn(error);
      }
    }

    this.formList = studyRegistrationFormList;
  }

  showForm(formId: string) {
    this.$router.push(`/study-registration/${this.uuid}/${formId}`);
  }

  async buildStudy() {
    try {
      await api.study.buildStudy(this.uuid);
      this.$router.push('/studies');
    } catch (error) {
      const res = (error as AxiosError).response;
      if (res && res.data && res.data.detail) {
        this.errorMessage = res.data.detail;
        return;
      }
      console.warn(error);
    }
  }
}
