












































import { Component, Prop, Vue } from 'vue-property-decorator';
import api from '@/app/shared/api';
import { AxiosError } from 'axios';

@Component
export default class ConfirmRegistration extends Vue {
  @Prop() uuid: string;
  isError: boolean = false;
  isSuccess: boolean = false;

  async created() {
    if (!this.uuid || this.isError || this.isSuccess) return;

    try {
      await api.auth.confirmRegistration(this.uuid);
      this.isSuccess = true;
    } catch (error) {
      this.isError = true;

      const axiosResponse = (error as AxiosError).response;

      if (axiosResponse && axiosResponse.data && axiosResponse.data.detail) {
        console.warn(axiosResponse.data.detail);
      }
    }
  }
}
