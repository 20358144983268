import httpClient from '@/app/shared/api/axios.config';
import { WorkspaceBase } from '@/app/shared/models/workspace/Workspace';
import { WorkspaceResponse } from '@/app/shared/models/workspace/WorkspaceResponse';
import { Study } from '@/app/shared/models/Study';

const ENDPOINT = '/workspace';
const STUDY_ENDPOINT = '/study';

export default {
  async getByUuid(uuid: string) {
    const data: WorkspaceResponse = await httpClient.get(`${ENDPOINT}/${uuid}`);
    return data;
  },

  async getStudyReviews(uuid: string) {
    const data: WorkspaceResponse = await httpClient.get(`${ENDPOINT}/${uuid}/study_review`);
    return data;
  },

  async getStudyByWorkspace(uuid: string): Promise<Study> {
    return await httpClient.get(`${ENDPOINT}/${uuid}/study`);
  },

  async getWorkspaceByStudy(uuid: string): Promise<WorkspaceBase> {
    return await httpClient.get(`${STUDY_ENDPOINT}/${uuid}/workspace`);
  },
};
