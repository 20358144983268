







































import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import api from '@/app/shared/api';
import { ChecktreBehaveAsType } from '@/app/shared/types/checktree.types';
import { namespace } from 'vuex-class';
import WorkflowFileUpload from '@/app/workflow/components/WorkflowFileUpload.vue';

const WorkflowStoreModule = namespace('Workflow');

@Component({
  components: { WorkflowFileUpload },
})
export default class WorkflowCreateChecktreeModal extends Vue {
  @Prop() title!: string;
  @Prop() import!: boolean;

  formName: string = '';
  formDescription: string = '';
  formBehaveAs: string = 'checkbox';

  behaveAsOptions: Array<{ label: string; value: ChecktreBehaveAsType }> = [];

  errorMessage: string = '';

  @WorkflowStoreModule.Getter
  getCurrentFile!: any;

  @WorkflowStoreModule.Mutation
  addNewlyAddedChecktree!: (data: any) => void;

  @WorkflowStoreModule.Mutation
  setCurrentFile!: (data: any) => void;

  created() {
    this.setCurrentFile([]);
    this.initializeBehaveAsOptions();
  }

  async save() {
    this.errorMessage = '';

    const data = {
      name: this.formName ? this.formName : null,
      description: this.formDescription ? this.formDescription : null,
      type_id: 1,
      is_admin: true,
      behave_as: this.formBehaveAs ? this.formBehaveAs : null,
    };

    const response: any = await api.checktree.importChecktree(data, this.getCurrentFile);
    if (response.status == 'success') {
      this.addNewlyAddedChecktree(response.data);
      this.$emit('pre-select-newly-added-checktree', response.data);
      this.$emit('workflow-create-checktree-dialog-close');
    } else {
      this.errorMessage = response.message;
    }
  }

  initializeBehaveAsOptions() {
    this.behaveAsOptions = [
      {
        label: 'Checkbox',
        value: 'checkbox',
      },
      {
        label: 'Radio',
        value: 'radio',
      },
    ];
  }

  @Emit()
  close() {
    this.$emit('workflow-create-checktree-dialog-close');
  }
}
