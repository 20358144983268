







import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Navbar extends Vue {
  @Prop() size!: number;
  @Prop() width!: number;
  @Prop() global!: boolean;
}
