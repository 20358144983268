






































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import { PresentationsSidebarOptionItemEnum } from '@/app/shared/enums/presentations-sidebar-option-item.enum';
import { PresentationsSidebarOption } from '@/app/shared/models/presentations/PresentationsSidebarOption';
import { PresentationsSidebarOptionGroupEnum } from '@/app/shared/enums/presentations-sidebar-option-group.enum';
import PresentationsAssessingTableImpact from './PresentationsAssessingTableImpact.vue';
import PresentationsBottomActionBar from './../PresentationsBottomActionBar.vue';
import ImpactTable from '@/app/shared/components/ImpactTable.vue';
import { PresentationsTabType } from '@/app/shared/types/presentations.types';
import { PresentationsTableType } from '@/app/shared/types/presentations.types';
import { PresentationsSidebarOptionSelection } from '@/app/shared/models/presentations/PresentationsSidebarOption';

import { AssessingTableSelection } from '@/app/shared/store/modules/presentations.store-module';
import { LibraryParagraph } from '@/app/shared/models/presentations/LibraryParagraph';
import { CheckTreeSelection } from '@/app/shared/models/CheckTree';
import { GRAPHIK_FONT_FAMILY_FOR_HTML_STYLE } from '@/app/shared/store/modules/presentations.constants';
import { Study } from '@/app/shared/models/Study';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import store from '@/app/shared/store';

const PresentationsStore = namespace('Presentations');
const LibraryStore = namespace('Library');

@Component({ components: { PresentationsBottomActionBar, PresentationsAssessingTableImpact, ImpactTable } })
export default class PresentationsAssessingTable extends Vue {
  tableTitle: string = null;
  titleInEditMode: boolean = false;
  viewMode: 'list' | 'comments' | 'details' | 'details_and_comments' = 'list';
  sizeMode: 'full' | 'half' = 'full';

  style = {
    titleWrapper: {
      display: 'flex',
      marginBottom: '38px',
    },
    tableTitleNumeration: {
      height: '32px',
      fontSize: '24px',
      lineHeight: '32px',
      display: 'flex',
      alignItems: 'center',
    },
    numeration: {
      marginRight: '0.75rem',
      position: 'relative',
      fontWeight: '500',
    },
    tableTitle: {
      flex: 1,
      position: 'relative',
      textAlign: 'left',
    },
    tableTitleSpan: { fontWeight: '500', color: '#222c37' },
    tableWrapper: {
      // marginTop: '38px',
      color: '#222C37',
    },
    topBorder: {
      borderRadius: '2px 2px 0 0',
      height: '8px',
      background: '#D3DADC',
    },
    tableHeader: {
      height: '64px',
      width: '100%',
      borderBottom: '6px solid #a3aeba',
      boxSizing: 'content-box',
      display: 'flex',
    },
    evaluationMethod: {
      flex: '1',
      background: '#a3aeba',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: '0 16px',
      minWidth: 0,
    },
    evaluationMethodLabel: {
      color: '#E5EAEC',
      textTransform: 'uppercase',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '0.15px',
      fontWeight: 500,
    },
    evaluationMethodValue: {
      color: 'white',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.22px',
      fontWeight: 500,
      textAlign: 'left',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
    criteriaGroupsWrapper: {
      flex: 1,
      display: 'flex',
    },
    criteriaGroup: {
      display: 'flex',
      flexDirection: 'column',
      height: '64px',
    },
    criteriaLabel: {
      height: '50%',
      borderBottom: '1px solid #d3dadc',
      borderRight: '1px solid #d3dadc',
      color: '#6F8195',
      fontSize: '12px',
      lineHeight: '12px',
      letterSpacing: '-0.2px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#f8fafa',
    },
    criteriaList: {
      height: '50%',
      display: 'flex',
    },
    criteria: {
      height: '100%',
      width: '80px',
      borderRight: '1px solid #d3dadc',
      color: '#6F8195',
      fontSize: '11px',
      lineHeight: '12px',
      letterSpacing: '-0.2px',
      fontWeight: 500,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    significanceCol: {
      flexBasis: '40px',
      background: '#a3aeba',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    significanceColSpan: {
      color: '#E5EAEC',
      textTransform: 'uppercase',
      fontSize: '10px',
      lineHeight: '10px',
      letterSpacing: '0px',
      fontWeight: 500,
    },
    table: { border: '1px solid #D3DADC' },
  };

  @PresentationsStore.State
  assessingTableSelection: AssessingTableSelection;

  @PresentationsStore.State
  activeTab: PresentationsTabType;

  @PresentationsStore.State
  activeTableType: PresentationsTableType;

  @PresentationsStore.Getter
  checktreeSelection: CheckTreeSelection;

  @PresentationsStore.Getter
  options: PresentationsSidebarOptionSelection;

  @LibraryStore.State
  study: Study;

  get impactList() {
    return this.assessingTableSelection ? this.assessingTableSelection.impacts : [];
  }

  get methodName() {
    return this.assessingTableSelection &&
      this.assessingTableSelection.impacts &&
      this.assessingTableSelection.impacts.length > 0
      ? this.assessingTableSelection.impacts[0].method.name
      : null;
  }

  get criteriaList() {
    return this.assessingTableSelection &&
      this.assessingTableSelection.impacts &&
      this.assessingTableSelection.impacts.length > 0
      ? this.assessingTableSelection.impacts[0].method.unit.checktrees[0].children.filter((el) => el.checked)
      : [];
  }

  created() {
    eventBus.$on(EventType.PRESENTATIONS_SIDEBAR_OPTIONS_EVENT, this.onPresentationsSidebarOptionEvent);
    if (this.assessingTableSelection) {
      this.tableTitle = this.assessingTableSelection.title;
    }
  }

  @PresentationsStore.Action
  addLibraryParagraphRaw: (value: LibraryParagraph) => Promise<void>;

  @PresentationsStore.Action
  clearTableSelection: () => Promise<any>;

  onTitleWrapperClick(_event: any) {
    this.titleInEditMode = true;
    const tableTitleWrapper = this.$refs.tableTitle as HTMLElement;
    setTimeout(() => {
      tableTitleWrapper.querySelector('input').focus();
    }, 0);
  }

  onPresentationsSidebarOptionEvent(option: PresentationsSidebarOption) {
    if (option.type === PresentationsSidebarOptionGroupEnum.TABLE_LEVEL_OF_DETAIL) {
      if (option.value === PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_ONLY_LIST) {
        this.viewMode = 'list';
      } else if (option.value === PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_WITH_COMMENTS) {
        this.viewMode = 'comments';
      } else if (option.value === PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_WITH_DETAILS) {
        this.viewMode = 'details';
      } else if (option.value === PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_WITH_DETAILS_AND_COMMENTS) {
        this.viewMode = 'details_and_comments';
      }
    } else if (option.type === PresentationsSidebarOptionGroupEnum.TABLE_SIZE) {
      if (option.value === PresentationsSidebarOptionItemEnum.TABLE_SIZE_HALF) {
        this.sizeMode = 'half';
      } else if (option.value == PresentationsSidebarOptionItemEnum.TABLE_SIZE_FULL) {
        this.sizeMode = 'full';
      }
    }
  }

  async addToLibrary() {
    try {
      const html = `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            ${GRAPHIK_FONT_FAMILY_FOR_HTML_STYLE}
            * {
              font-family: 'Graphik', Avenir, Helvetica, Arial, sans-serif;
            }
          </style>
        </head>
        <body>
          ${(this.$refs.assessingTable as HTMLElement).innerHTML}
        </body>
      </html>
      `;

      const libraryParagraph = new LibraryParagraph(ChapterItemType.LIBRARY_ENVIGO_TABLE);
      libraryParagraph.title = this.tableTitle;
      libraryParagraph.html = html;
      libraryParagraph.itemPayload = {
        checktreeSelection: this.checktreeSelection,
        activeTab: this.activeTab,
        tableType: this.activeTableType,
        options: this.options,
        module: store.getters['ModuleProperties/moduleProperties'],
      };
      libraryParagraph.itemData = this.assessingTableSelection;
      await this.addLibraryParagraphRaw(libraryParagraph);
    } catch (error) {
      console.error(error);
    }
  }

  clear() {
    this.clearTableSelection();
  }

  destroyed() {
    eventBus.$off(EventType.PRESENTATIONS_SIDEBAR_OPTIONS_EVENT, this.onPresentationsSidebarOptionEvent);
  }
}
