var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column form-field-assessment-team",class:{
    'form-field-assessment-team--show-error': _vm.isRequiredObjectResponsibilityUserId,
  }},[_vm._l((_vm.assessments),function(assessment,index){return [_c('div',{key:("assessment-" + index + "-0")},[_c('FormFieldText',{ref:"formField",refInFor:true,attrs:{"control":{
          uniqueId: ((_vm.control.uniqueId) + "_responsibility"),
          name: ("responsibility_" + index),
          label: _vm.control.label,
          placeholderText: _vm.control.placeholderText,
          inputType: 'text',
          isDisabled: _vm.control.isDisabled,
          isReadonly: _vm.control.isReadonly,
          validations: _vm.isRequiredObjectResponsibilityUserId ? [{ ruleType: 'required' }] : null,
        }},model:{value:(assessment.responsibility),callback:function ($$v) {_vm.$set(assessment, "responsibility", $$v)},expression:"assessment.responsibility"}})],1),_c('div',{key:("assessment-" + index + "-1"),staticClass:"mt-3"},[_c('FormFieldSelect',{attrs:{"control":{
          uniqueId: ((_vm.control.uniqueId) + "_user_id"),
          name: ("user_id_" + index),
          label: 'Name',
          placeholderText: 'Select',
          endpointPayload: _vm.optionsEndpointPayload,
          endpointRoute: '',
          isMultiple: true,
          isDisabled: _vm.control.isDisabled,
          isReadonly: _vm.control.isReadonly,
          validations: _vm.isRequiredObjectResponsibilityUserId ? [{ ruleType: 'required' }] : null,
        },"value-container":_vm.$attrs['value-container']},model:{value:(assessment.user_id),callback:function ($$v) {_vm.$set(assessment, "user_id", $$v)},expression:"assessment.user_id"}})],1),_c('div',{key:("assessment-" + index + "-2"),staticClass:"d-flex flex-column form-field-assessment-team__toggles"},[_c('div',{staticClass:"d-flex flex-grow-1 justify-end"},[(index > 0)?_c('BaseButton',{staticClass:"form-field-assessment-team__delete-group-button",attrs:{"disabled":_vm.control.isDisabled || _vm.control.isReadonly,"type":"quin","icon":"delete"},on:{"click":function($event){return _vm.removeGroup(index)}}},[_vm._v(" Delete Group ")]):_vm._e()],1)]),(!_vm.control.isDisabled && !_vm.control.isReadonly)?_c('div',{key:("assessment-" + index + "-3")},[_c('div',{staticClass:"d-flex align-center form-field-assessment-team__add-group",class:{
          'form-field-assessment-team__add-group--is-sm': _vm.$vuetify.breakpoint.smAndDown,
        }},[_c('v-icon',{on:{"click":function($event){return _vm.addGroup(index)}}},[_vm._v(" $add-v2 ")])],1)]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }