
























































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { PresentationsTableType } from '@/app/shared/types/presentations.types';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import { PresentationsSidebarOptionItemEnum } from '@/app/shared/enums/presentations-sidebar-option-item.enum';
import { PresentationsSidebarOption } from '@/app/shared/models/presentations/PresentationsSidebarOption';
import { PresentationsSidebarOptionGroupEnum } from '@/app/shared/enums/presentations-sidebar-option-group.enum';
const PresentationsStore = namespace('Presentations');

@Component({ components: {} })
export default class PresentationsQuestionnaireTable extends Vue {
  titleInEditMode: boolean = false;
  borderColor: string = '#D3DADC';
  cellColor: string = 'white';
  shouldShowComments: boolean = false;

  get tableTitle() {
    return 'Placeholder table';
  }

  //TODO: dev only
  get answer() {
    return 'yes';
  }

  created() {
    eventBus.$on(EventType.PRESENTATIONS_SIDEBAR_OPTIONS_EVENT, this.onPresentationsSidebarOptionEvent);
  }

  @PresentationsStore.Action
  changeTableType: (value: PresentationsTableType) => Promise<any>;

  onTitleWrapperClick(_event: any) {
    this.titleInEditMode = true;
    const tableTitleWrapper = this.$refs.tableTitle as HTMLElement;
    setTimeout(() => {
      tableTitleWrapper.querySelector('input').focus();
    }, 0);
  }

  onPresentationsSidebarOptionEvent(option: PresentationsSidebarOption) {
    if (option.type === PresentationsSidebarOptionGroupEnum.TABLE_LEVEL_OF_DETAIL_SIMPLE) {
      if (option.value === PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_ONLY_LIST) {
        this.shouldShowComments = false;
      } else if (option.value === PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_WITH_COMMENTS) {
        this.shouldShowComments = true;
      }
    }
  }

  destroyed() {
    eventBus.$off(EventType.PRESENTATIONS_SIDEBAR_OPTIONS_EVENT, this.onPresentationsSidebarOptionEvent);
  }
}
