














import { Component, Model, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class CommentingCommentEditable extends Vue {
  @Model('update') value: string;
  @Prop() defaultContent: string;
  @Prop({ default: 'Comment' }) placeholderText: string;

  @Emit()
  focus() {
    //
  }

  @Emit()
  submit() {
    //
  }

  @Emit()
  update(_value: string) {
    //
  }

  @Watch('value')
  onValueChange(newValue: string) {
    const editable = this.$refs.editable as HTMLSpanElement;
    if (!editable || editable.innerHTML === newValue) return;
    editable.innerHTML = newValue;
  }

  mounted() {
    this.initValue();
  }

  initValue() {
    if (!this.$refs.editable) return;
    if (this.value) {
      (this.$refs.editable as HTMLSpanElement).innerHTML = this.value;
    }
  }

  updateValue() {
    const value = (this.$refs.editable as HTMLSpanElement).innerHTML;
    this.update(value.trim());
  }

  setCaretEnd() {
    const editable = this.$refs.editable as HTMLSpanElement;
    const range = document.createRange();
    range.selectNodeContents(editable);
    range.collapse(false);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
}
