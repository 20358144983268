export default class UtilService {
  static calculateSidebarWidth() {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    if (vw > 1920) {
      return 360;
    } else {
      // 1128 is content width and 16 is requested margin
      return (vw - 1128) / 2 - 16;
    }
  }
}
