
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ValidationRule } from '@/app/shared/models/form/FormSchema';
import { TooltipOptions } from '@/app/shared/store/modules/ui.store-module';

const UiStore = namespace('UI');

@Component
export default class FormFieldLabel extends Vue {
  control: {
    uniqueId: string;
    label: string;
    helpText: string;
    validations: ValidationRule[];
  };

  get isRequired() {
    if (this.control.validations)
      return (
        this.control.validations.filter(
          (validation) =>
            validation.ruleType === 'required' ||
            (validation.ruleType === 'customClosure' && validation.additionalValue === 'requiredValue'),
        ).length > 0
      );
    return false;
  }

  get label() {
    if (this.isRequired) return `${this.control.label} *`;
    return this.control.label;
  }

  @UiStore.Action
  public showTooltip!: (options: TooltipOptions) => void;

  showHelpTooltip(helpText: string, event: PointerEvent) {
    const el: HTMLElement = event.target as HTMLElement;
    const elDOMRect: DOMRect = el.getBoundingClientRect();

    this.showTooltip({
      text: helpText,
      position: 'right',
      top: elDOMRect.y + 20,
      right: document.body.clientWidth - elDOMRect.x - 20,
      buttonText: 'Got it',
    });
  }
}
