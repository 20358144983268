var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center study-reviews-item"},[_c('div',{staticClass:"d-flex align-center study-reviews-item__module-button",class:{
      'study-reviews-item__module-button--is-disabled': !_vm.isEnabled,
    },style:({
      background: _vm.isEnabled ? ("#" + (_vm.attributes.color)) : 'var(--v-gray-ultra-dark)',
    }),attrs:{"tabindex":_vm.isEnabled ? '0' : '-1',"data-cy":_vm.item && _vm.item.slug},on:{"click":_vm.itemClick}},[_c('v-icon',[_vm._v("$"+_vm._s(_vm.attributes.icon))]),_c('span',{staticClass:"study-reviews-item__title",class:{ 'study-reviews-item__title--is-disabled': !_vm.isEnabled },style:({ color: _vm.attributes.enabledTextColor })},[_vm._v(" "+_vm._s(_vm.itemTitle)+" ")])],1),_c('div',{staticClass:"study-reviews-item__side-badge d-flex flex-column align-center justify-center",class:{
      'study-reviews-item__side-badge--is-disabled': !_vm.isDownloadEnabled,
      'study-reviews-item__side-badge--has-disabled-side-badge': !_vm.hasSideBadge,
    },attrs:{"tabindex":_vm.hasSideBadge ? '0' : '-1'},on:{"click":_vm.sideBadgeClick}},[(_vm.isEnabled)?[_c('span',{staticClass:"study-reviews-item__side-badge-label"},[_vm._v(" PDF ")]),_c('v-icon',{staticClass:"study-reviews-item__side-badge-icon"},[_vm._v("$download")])]:_vm._e()],2),(_vm.isUpdated)?_c('div',{staticClass:"study-reviews-item__updated-state"},[_c('v-icon',[_vm._v("$add")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }