






























import { Component, Watch, Vue } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import LandingPageButton from '@/app/home/components/LandingPageButton.vue';
import PageFooter from '@/app/shared/components/PageFooter.vue';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';
import { HowToUse } from '@/app/shared/enums/how-to-use.enum';

const UserStore = namespace('User');

@Component({
  components: {
    LandingPageButton,
    PageFooter,
  },
})
export default class LandingPage extends Vue {
  skipLandingPage: boolean = false;

  @AsyncComputed()
  async buttons() {
    return [
      {
        key: 'user-profile',
        icon: 'checktree',
        label: 'User Profile',
        route: '/user-profile',
        isDisabled: !(await this.isAllowed(UserPermission.USER_PROFILE_ACCESS)),
      },
      {
        key: 'studies-dashboard',
        icon: 'studies-dashboard',
        label: 'Studies Dashboard',
        route: '/studies',
        isDisabled: !(await this.isAllowed(UserPermission.STUDIES_DASHBOARD_ACCESS)),
      },
      {
        key: 'how-to-use',
        icon: 'checktree',
        label: 'How To Use Envigo',
        route: '/how-to-use/' + HowToUse.UUID,
        isDisabled: !(await this.isAllowed(UserPermission.SINGLE_IMPACT_REPORT_ACCESS)),
      },
      {
        key: 'organization-users-dashboard',
        icon: 'company-dashboard',
        label: 'Organization Dashboard',
        route: `/organization/${this.userOrganizationUuid}`,
        isDisabled: !(await this.isAllowed(UserPermission.ORGANIZATION_USERS_DASHBOARD_ACCESS)),
      },
      {
        key: 'external-library',
        icon: 'checktree',
        label: 'External Library',
        route: '/external-library',
        isDisabled: true,
      },
      {
        key: 'activity-center',
        icon: 'checktree',
        label: 'Activity Center',
        route: '/activity-center',
        isDisabled: true,
      },
    ];
  }

  @UserStore.Getter
  public userOrganizationUuid: () => string;

  @UserStore.Action
  public isAllowed: (permission: string | string[]) => Promise<boolean>;

  @Watch('skipLandingPage')
  onSkipLandingPageChange(newValue: boolean) {
    console.log('SKIP!', newValue);
  }

  goTo(route: string) {
    this.$router.push(route);
  }
}
