import activities from './activities.vue';
import add from './add.vue';
import add10 from './add-10.vue';
import addCompany from './add-company.vue';
import addThick from './add-thick.vue';
import addV2 from './add-v2.vue';
import addUser from './add-user.vue';
import accept from './accept.vue';
import activeBefore16px from './active-before-16px.vue';
import adminInfo16px from './admin-info-16px.vue';
import alternatives from './alternatives.vue';
import alternativesSmaller from './alternatives-24.vue';
import approved16px from './approved-16px.vue';
import aspects from './aspects.vue';
import assess from './assess.vue';
import assessor from './assessor.vue';
import attach from './attach.vue';
import authorities from './authorities.vue';
import back from './back.vue';
import backToRoot from './back-to-root.vue';
import backToTop from './back-to-top.vue';
import calculationsMethod from './calculations-method.vue';
import calendar from './calendar.vue';
import calendar1px from './calendar-1px.vue';
import caretDown from './caret-down.vue';
import change from './change.vue';
import chart from './chart.vue';
import chartsBarGrouped from './charts-bar-grouped.vue';
import chartsBarSimple from './charts-bar-simple.vue';
import chartsBarStacked from './charts-bar-stacked.vue';
import chartsLine from './charts-line.vue';
import chartsPie from './charts-pie.vue';
import chartsPieMultiple from './charts-pie-multiple.vue';
import checkCircle from './checkcircle.vue';
import checkmark from './checkmark.vue';
import checktree from './checktree.vue';
import checktreeDisabled from './checktree-disabled.vue';
import checktreeDisabledPresentations from './checktree-disabled-presentations.vue';
import classIcon from './class.vue';
import close from './close.vue';
import collapse from './collapse.vue';
import collapse16px from './collapse-16px.vue';
import collapseAll from './collapseall.vue';
import collapseAllV2 from './collapseall-v2.vue';
import collapseSidebar from './collapse-sidebar.vue';
import collapseTabs from './collapse-tabs.vue';
import colorpicker from './colorpicker.vue';
import comment from './comment.vue';
import comment2px from './comment-2px.vue';
import commentSolid from './comment-solid.vue';
import commentingModeSolid from './commenting-mode-solid.vue';
import commentingReplies from './commenting-replies.vue';
import commentingSidebar from './commenting-sidebar.vue';
import commentFilled from './commentFilled.vue';
import commentsOverview from './comments-overview.vue';
import commentsOverviewDisabled from './comments-overview-disabled.vue';
import company from './company.vue';
import company1px from './company-1px.vue';
import companyV3 from './company-v3.vue';
import companyDashboard from './company-dashboard.vue';
import copy from './copy.vue';
import copyPaste from './copy-paste.vue';
import createPdf from './create-pdf.vue';
import crop from './crop.vue';
import dashboard from './dashboard.vue';
import decisions from './decisions.vue';
import decisionsDisabled from './decisions-disabled.vue';
import deleteIcon from './delete.vue';
import deleteV2 from './delete-v2.vue';
import delete16px from './delete-16px.vue';
import demo16px from './demo-16px.vue';
import developmentFacilityLocation from './development-facility-location.vue';
import developmentFacilityTypeV1 from './development-facility-type-v1.vue';
import developmentFacilityTypeV2 from './development-facility-type-v2.vue';
import developmentType from './development-type.vue';
import done from './done.vue';
import download from './download.vue';
import drag from './drag.vue';
import dragAndDrop from './drag-and-drop.vue';
import dragAndDropImage from './drag-and-drop-image.vue';
import dropHere from './drop-here.vue';
import edit from './edit.vue';
import edit24px from './edit-24px.vue';
import enter from './enter.vue';
import error from './error.vue';
import expandAll from './expandall.vue';
import expandAllV2 from './expand-all-v2.vue';
import expert from './expert.vue';
import eye from './eye.vue';
import factors from './factors.vue';
import fields from './fields.vue';
import filter from './filter.vue';
import formulas from './formulas.vue';
import formulasAndScores from './formulas-and-scores.vue';
import formulate from './formulate.vue';
import fullReport from './full-report.vue';
import fullReportDisabled from './full-report-disabled.vue';
import google from './google.vue';
import charts from './charts.vue';
import help from './help.vue';
import hide from './hide.vue';
import history from './history.vue';
import howToUse from './how-to-use.vue';
import image from './image.vue';
import impactAlternative from './impact-alternative.vue';
import impactCharacter from './impact-character.vue';
import impactInteraction from './impact-interaction.vue';
import impactInteractionDisabled from './impact-interaction-disabled.vue';
import impactPhase from './impact-phase.vue';
import impacts from './impacts.vue';
import inProgress from './in-progress.vue';
import inProgress16px from './in-progress-16px.vue';
import includeExclude from './include-exclude.vue';
import industrySector from './industry-sector.vue';
import investor from './investor.vue';
import invited16px from './invited-16px.vue';
import library from './library.vue';
import libraryDrop from './library-drop.vue';
import licence from './licence.vue';
import licence1px from './licence-1px.vue';
import link from './link.vue';
import link2px from './link-2px.vue';
import lock from './lock.vue';
import location from './location.vue';
import loggedIn16px from './logged-in-16px.vue';
import loggedOut16px from './logged-out-16px.vue';
import loggedOut from './logged-out.vue';
import matrix from './matrix.vue';
import matrixDisabled from './matrix-disabled.vue';
import matrixDisabledPresentations from './matrix-disabled-presentations.vue';
import matrixType2 from './matrix-type-2.vue';
import matrixType2Disabled from './matrix-type-2-disabled.vue';
import menu16px from './menu-16px.vue';
import minimize from './minimize.vue';
import minimizeSidebar from './minimize-sidebar.vue';
import minus from './minus.vue';
import new16px from './new-16px.vue';
import newIcon from './new.vue';
import next from './next.vue';
import notifications from './notifications.vue';
import open1px from './open-1px.vue';
import paragraphs from './paragraphs.vue';
import participant from './participant.vue';
import paste from './paste.vue';
import pasteImage from './paste-image.vue';
import pdfOutput from './pdf-output.vue';
import pdfOutputDisabled from './pdf-output-disabled.vue';
import performance from './performance.vue';
import phases from './phases.vue';
import phasesSmaller from './phases-24.vue';
import presentation from './presentation.vue';
import presentationDisabled from './presentation-disabled.vue';
import presentations from './presentations.vue';
import presentationsStatsCount from './presentations-stats-count.vue';
import presentationsStatsMean from './presentations-stats-mean.vue';
import presentationsStatsVariance from './presentations-stats-variance.vue';
import previous from './previous.vue';
import processing from './processing.vue';
import processingChecktree from './processing-checktree.vue';
import processingChecktreeDisabled from './processing-checktree-disabled.vue';
import processingDisabled from './processing-disabled.vue';
import processingDisabledPresentations from './processing-disabled-presentations.vue';
import profileInformation from './profile-information.vue';
import progressCircle from './progress-circle.vue';
import project from './project.vue';
import publicConsultation from './public-consultation.vue';
import publicConsultationDisabled from './public-consultation-disabled.vue';
import publicVersion from './public-version.vue';
import question from './question.vue';
import questionnaire from './questionnaire.vue';
import questionnaireDisabled from './questionnaire-disabled.vue';
import questionnaireDisabledPresentations from './questionnaire-disabled-presentations.vue';
import radioChecked from './radio-checked.vue';
import radioUnchecked from './radio-unchecked.vue';
import receptors from './receptors-24.vue';
import rejected16px from './rejected-16px.vue';
import reorder from './reorder.vue';
import reportsIcon from './reports-icon.vue';
import reportsIconDisabled from './reports-icon-disabled.vue';
import resize from './resize.vue';
import resizeGrabber from './resize-grabber.vue';
import restore from './restore.vue';
import reveal from './reveal.vue';
import reveal16px from './reveal-16px.vue';
import revealSidebar from './reveal-sidebar.vue';
import review from './review.vue';
import reviewer from './reviewer.vue';
import rotate from './rotate.vue';
import scores from './scores.vue';
import search from './search.vue';
import show from './show.vue';
import sign from './sign.vue';
import singleImpactAssessment from './single-impact-assessment.vue';
import singleImpactAssessmentDisabled from './single-impact-assessment-disabled.vue';
import sort from './sort.vue';
import stakeholders from './stakeholders.vue';
import star from './star.vue';
import starSolid from './star-solid.vue';
import statistics from './statistics.vue';
import studies from './studies.vue';
import studiesDashboard from './studies-dashboard.vue';
import studiesSolid from './studies-solid.vue';
import studyType from './study-type.vue';
import studyRegistrationType from './study-registration-type.vue';
import studyReviews from './study-reviews.vue';
import studyReviewsSolid from './study-reviews-solid.vue';
import summaryReport from './summary-report.vue';
import summaryReportWorkspace from './summary-report-workspace.vue';
import summaryReportWorkspaceDisabled from './summary-report-workspace-disabled.vue';
import tables from './tables.vue';
import team from './team.vue';
import technicalReview from './technical-review.vue';
import technicalReviewDisabled from './technical-review-disabled.vue';
import templates from './templates.vue';
import templatesBlankColor2 from './templates-blank-color-2.vue';
import templatesDisabled from './templates-disabled.vue';
import texteditor from './texteditor.vue';
import trackingActivity from './tracking-activity.vue';
import trackingAndCommenting from './tracking-and-commenting.vue';
import typeIcon from './type.vue';
import underReview16px from './under-review-16px.vue';
import underRevision16px from './under-revision-16px.vue';
import unlink2px from './unlink-2px.vue';
import unlock from './unlock.vue';
import update from './update.vue';
import upgrade from './upgrade.vue';
import uploadDocumentV2 from './upload-document-v2.vue';
import uploadedDocument from './uploaded-document.vue';
import user from './user.vue';
import userRole from './user-role.vue';
import usersActive from './users-active.vue';
import versioningSidebar from './versioning-sidebar.vue';
import warning from './warning.vue';
import workflowIcon from './workflow-icon.vue';
import workspace from './workspace.vue';
import workspaceIcon from './workspace-icon.vue';
import workspaceSolid from './workspace-solid.vue';

export default {
  activities: { component: activities },
  add: { component: add },
  'add-10': { component: add10 },
  'add-company': { component: addCompany },
  'add-thick': { component: addThick },
  'add-v2': { component: addV2 },
  'add-user': { component: addUser },
  accept: { component: accept },
  'active-before-16px': { component: activeBefore16px },
  'admin-info-16px': { component: adminInfo16px },
  'alternatives-24': { component: alternativesSmaller },
  alternatives: { component: alternatives },
  'approved-16px': { component: approved16px },
  aspects: { component: aspects },
  assess: { component: assess },
  assessor: { component: assessor },
  attach: { component: attach },
  authorities: { component: authorities },
  'back-to-root': { component: backToRoot },
  'back-to-top': { component: backToTop },
  back: { component: back },
  'calculations-method': { component: calculationsMethod },
  calendar: { component: calendar },
  'calendar-1px': { component: calendar1px },
  'caret-down': { component: caretDown },
  change: { component: change },
  chart: { component: chart },
  'charts-bar-grouped': { component: chartsBarGrouped },
  'charts-bar-simple': { component: chartsBarSimple },
  'charts-bar-stacked': { component: chartsBarStacked },
  'charts-line': { component: chartsLine },
  'charts-pie-multiple': { component: chartsPieMultiple },
  'charts-pie': { component: chartsPie },
  checkCircle: { component: checkCircle },
  checkmark: { component: checkmark },
  'checktree-disabled-presentations': { component: checktreeDisabledPresentations },
  'checktree-disabled': { component: checktreeDisabled },
  checktree: { component: checktree },
  class: { component: classIcon },
  close: { component: close },
  collapse: { component: collapse },
  'collapse-16px': { component: collapse16px },
  collapseAll: { component: collapseAll },
  'collapse-all-v2': { component: collapseAllV2 },
  'collapse-sidebar': { component: collapseSidebar },
  'collapse-tabs': { component: collapseTabs },
  colorpicker: { component: colorpicker },
  comment: { component: comment },
  'comment-2px': { component: comment2px },
  'comment-solid': { component: commentSolid },
  'commenting-mode-solid': { component: commentingModeSolid },
  'commenting-replies': { component: commentingReplies },
  'commenting-sidebar': { component: commentingSidebar },
  commentFilled: { component: commentFilled },
  'comments-overview': { component: commentsOverview },
  'comments-overview-disabled': { component: commentsOverviewDisabled },
  company: { component: company },
  'company-1px': { component: company1px },
  'company-v3': { component: companyV3 },
  'company-dashboard': { component: companyDashboard },
  'copy-paste': { component: copyPaste },
  copy: { component: copy },
  'create-pdf': { component: createPdf },
  crop: { component: crop },
  dashboard: { component: dashboard },
  decisions: { component: decisions },
  'decisions-disabled': { component: decisionsDisabled },
  'delete-v2': { component: deleteV2 },
  'delete-16px': { component: delete16px },
  delete: { component: deleteIcon },
  'demo-16px': { component: demo16px },
  'development-facility-location': { component: developmentFacilityLocation },
  'development-facility-type-v1': { component: developmentFacilityTypeV1 },
  'development-facility-type-v2': { component: developmentFacilityTypeV2 },
  'development-type': { component: developmentType },
  done: { component: done },
  download: { component: download },
  'drag-and-drop-image': { component: dragAndDropImage },
  'drag-and-drop': { component: dragAndDrop },
  drag: { component: drag },
  'drop-here': { component: dropHere },
  edit: { component: edit },
  'edit-24px': { component: edit24px },
  enter: { component: enter },
  'expand-all-v2': { component: expandAllV2 },
  error: { component: error },
  expandAll: { component: expandAll },
  expert: { component: expert },
  eye: { component: eye },
  factors: { component: factors },
  fields: { component: fields },
  filter: { component: filter },
  'formulas-and-scores': { component: formulasAndScores },
  formulas: { component: formulas },
  formulate: { component: formulate },
  'full-report': { component: fullReport },
  'full-report-disabled': { component: fullReportDisabled },
  google: { component: google },
  charts: { component: charts },
  help: { component: help },
  hide: { component: hide },
  history: { component: history },
  'how-to-use': { component: howToUse },
  image: { component: image },
  'impact-alternative': { component: impactAlternative },
  'impact-character': { component: impactCharacter },
  'impact-interaction-disabled': { component: impactInteractionDisabled },
  'impact-interaction': { component: impactInteraction },
  'impact-phase': { component: impactPhase },
  impacts: { component: impacts },
  'include-exclude': { component: includeExclude },
  'industry-sector': { component: industrySector },
  investor: { component: investor },
  'invited-16px': { component: invited16px },
  'in-progress': { component: inProgress },
  'in-progress-16px': { component: inProgress16px },
  'library-drop': { component: libraryDrop },
  library: { component: library },
  licence: { component: licence },
  'licence-1px': { component: licence1px },
  link: { component: link },
  'link-2px': { component: link2px },
  lock: { component: lock },
  location: { component: location },
  'logged-in-16px': { component: loggedIn16px },
  'logged-out-16px': { component: loggedOut16px },
  'logged-out': { component: loggedOut },
  'matrix-disabled-presentations': { component: matrixDisabledPresentations },
  'matrix-disabled': { component: matrixDisabled },
  'matrix-type-2-disabled': { component: matrixType2Disabled },
  'matrix-type-2': { component: matrixType2 },
  matrix: { component: matrix },
  'menu-16px': { component: menu16px },
  'minimize-sidebar': { component: minimizeSidebar },
  minimize: { component: minimize },
  minus: { component: minus },
  'new-16px': { component: new16px },
  new: { component: newIcon },
  next: { component: next },
  notifications: { component: notifications },
  'open-1px': { component: open1px },
  paragraphs: { component: paragraphs },
  participant: { component: participant },
  'paste-image': { component: pasteImage },
  paste: { component: paste },
  'pdf-output-disabled': { component: pdfOutputDisabled },
  'pdf-output': { component: pdfOutput },
  performance: { component: performance },
  'phases-24': { component: phasesSmaller },
  phases: { component: phases },
  'presentation-disabled': { component: presentationDisabled },
  presentation: { component: presentation },
  'presentations-stats-count': { component: presentationsStatsCount },
  'presentations-stats-mean': { component: presentationsStatsMean },
  'presentations-stats-variance': { component: presentationsStatsVariance },
  presentations: { component: presentations },
  previous: { component: previous },
  'processing-checktree-disabled': { component: processingChecktreeDisabled },
  'processing-checktree': { component: processingChecktree },
  'processing-disabled-presentations': { component: processingDisabledPresentations },
  'processing-disabled': { component: processingDisabled },
  processing: { component: processing },
  'profile-information': { component: profileInformation },
  'progress-circle': { component: progressCircle },
  project: { component: project },
  'public-consultation': { component: publicConsultation },
  'public-consultation-disabled': { component: publicConsultationDisabled },
  'public-version': { component: publicVersion },
  question: { component: question },
  'questionnaire-disabled-presentations': { component: questionnaireDisabledPresentations },
  'questionnaire-disabled': { component: questionnaireDisabled },
  questionnaire: { component: questionnaire },
  'radio-checked': { component: radioChecked },
  'radio-unchecked': { component: radioUnchecked },
  'receptors-24': { component: receptors },
  'rejected-16px': { component: rejected16px },
  reorder: { component: reorder },
  'reports-icon-disabled': { component: reportsIconDisabled },
  'reports-icon': { component: reportsIcon },
  'resize-grabber': { component: resizeGrabber },
  resize: { component: resize },
  restore: { component: restore },
  reveal: { component: reveal },
  'reveal-16px': { component: reveal16px },
  'reveal-sidebar': { component: revealSidebar },
  review: { component: review },
  reviewer: { component: reviewer },
  rotate: { component: rotate },
  scores: { component: scores },
  search: { component: search },
  show: { component: show },
  sign: { component: sign },
  'single-impact-assessment': { component: singleImpactAssessment },
  'single-impact-assessment-disabled': { component: singleImpactAssessmentDisabled },
  sort: { component: sort },
  stakeholders: { component: stakeholders },
  star: { component: star },
  'star-solid': { component: starSolid },
  statistics: { component: statistics },
  studies: { component: studies },
  'studies-dashboard': { component: studiesDashboard },
  'studies-solid': { component: studiesSolid },
  'study-type': { component: studyType },
  'study-registration-type': { component: studyRegistrationType },
  'study-reviews': { component: studyReviews },
  'study-reviews-solid': { component: studyReviewsSolid },
  'summary-report-workspace-disabled': { component: summaryReportWorkspaceDisabled },
  'summary-report-workspace': { component: summaryReportWorkspace },
  'summary-report': { component: summaryReport },
  tables: { component: tables },
  team: { component: team },
  'technical-review': { component: technicalReview },
  'technical-review-disabled': { component: technicalReviewDisabled },
  'templates-blank-color-2': { component: templatesBlankColor2 },
  'templates-disabled': { component: templatesDisabled },
  templates: { component: templates },
  texteditor: { component: texteditor },
  'tracking-activity': { component: trackingActivity },
  'tracking-and-commenting': { component: trackingAndCommenting },
  type: { component: typeIcon },
  'under-review-16px': { component: underReview16px },
  'under-revision-16px': { component: underRevision16px },
  'unlink-2px': { component: unlink2px },
  unlock: { component: unlock },
  update: { component: update },
  upgrade: { component: upgrade },
  'upload-document-v2': { component: uploadDocumentV2 },
  'uploaded-document': { component: uploadedDocument },
  user: { component: user },
  'user-role': { component: userRole },
  'users-active': { component: usersActive },
  'versioning-sidebar': { component: versioningSidebar },
  warning: { component: warning },
  'workflow-icon': { component: workflowIcon },
  workspace: { component: workspace },
  'workspace-icon': { component: workspaceIcon },
  'workspace-solid': { component: workspaceSolid },
};
