



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Sidebar from '@/app/shared/components/Sidebar.vue';

const UserStore = namespace('User');

@Component({
  components: { Sidebar },
})
export default class MatrixRelationsFilterSidebar extends Vue {
  @Prop()
  title!: string;

  //TODO: dev only
  filterItems: any[] = [
    { uuid: 1, label: 'Level 0', checked: true, expanded: false, parentUuid: null, level: 1 },
    { uuid: 2, label: 'Level 1', checked: false, expanded: false, parentUuid: null, level: 1 },
    { uuid: 3, label: 'Level 2', checked: false, expanded: true, parentUuid: null, level: 1 },
    { uuid: 31, label: 'Level 2.1', checked: false, expanded: false, parentUuid: null, level: 2 },
    { uuid: 32, label: 'Level 2.2', checked: false, expanded: true, parentUuid: null, level: 2 },
    { uuid: 321, label: 'Level 2.2.1', checked: false, expanded: false, parentUuid: null, level: 3 },
    { uuid: 4, label: 'Level 3', checked: true, expanded: true, parentUuid: null, level: 1 },
    { uuid: 41, label: 'Level 3.1', checked: true, expanded: false, parentUuid: null, level: 2 },
    { uuid: 42, label: 'Level 3.2', checked: true, expanded: false, parentUuid: null, level: 2 },
  ];

  //TODO: dev only
  isItemPartiallyChecked(_item: any) {
    return false;
  }

  // TODO: dev only
  isExpanded(item: any) {
    if ([3, 32, 4].includes(item.uuid)) {
      return true;
    }
    return false;
  }

  @UserStore.Getter
  public isAuthenticated: boolean;

  @UserStore.Action
  public logoutUser!: () => void;
}
