
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ResizableTableColumns extends Vue {
  tableRef: string = 'tableRef';
  tableHeaderRow: number = 0;
  tableResizableColumnClass: string = 'col-resizable';

  mounted() {
    this.addResize();
  }

  addResize() {
    const createDiv = (height: number) => {
      const div = document.createElement('div');
      div.style.top = '0';
      div.style.right = '0';
      div.style.width = '16px';
      div.style.position = 'absolute';
      div.style.cursor = 'col-resize';
      div.style.userSelect = 'none';
      div.style.height = height + 'px';
      return div;
    };
    const getStyleVal = (elm: any, css: any) => {
      return window.getComputedStyle(elm, null).getPropertyValue(css);
    };

    const paddingDiff = (col: HTMLElement) => {
      if (getStyleVal(col, 'box-sizing') == 'border-box') {
        return 0;
      }

      const padLeft = getStyleVal(col, 'padding-left');
      const padRight = getStyleVal(col, 'padding-right');
      return parseInt(padLeft) + parseInt(padRight);
    };

    const setListeners = (div: HTMLElement) => {
      let pageX: number, curCol: HTMLElement, curColWidth: number;
      // let nxtCol: HTMLElement, nxtColWidth: number;

      div.addEventListener('mousedown', function(e: any) {
        curCol = e.target.parentElement;
        // nxtCol = curCol.nextElementSibling as HTMLElement;
        pageX = e.pageX;

        const padding = paddingDiff(curCol);

        curColWidth = curCol.offsetWidth - padding;
        // if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding;
      });

      document.addEventListener('mousemove', function(e) {
        if (curCol) {
          const diffX = e.pageX - pageX;

          // if (nxtCol) {
          //   nxtCol.style.width = nxtColWidth - diffX + 'px';
          //   (nxtCol.style as any)['min-width'] = nxtColWidth - diffX + 'px';
          // }

          curCol.style.width = curColWidth + diffX + 'px';
          (curCol.style as any)['min-width'] = curColWidth + diffX + 'px';
        }
      });

      document.addEventListener('mouseup', function(_event: any) {
        curCol = undefined;
        // nxtCol = undefined;
        pageX = undefined;
        // nxtColWidth = undefined;
        curColWidth = undefined;
      });
    };

    const row = (this.$refs[this.tableRef] as Vue & { $el: HTMLElement }).$el.getElementsByTagName('tr')[
      this.tableHeaderRow
    ];
    const cols = row ? row.children : null;
    if (!cols) return;

    for (const col of cols) {
      const isColResizable = col.classList.value.indexOf(this.tableResizableColumnClass) > -1;
      if (isColResizable) {
        const div = createDiv(32);
        col.appendChild(div);
        (col as HTMLElement).style.position = 'sticky';
        setListeners(div);
      }
    }
  }
}
