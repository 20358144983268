






































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CheckTreeItem from '@/app/shared/models/CheckTreeItem';
import { CheckTree } from '@/app/shared/models/CheckTree';
import { ToggleSidebarSelectionItemCheckPayload } from '@/app/shared/store/modules/presentations.store-module';
import PresentationsSidebarSelectionChildItem from './PresentationsSidebarSelectionChildItem.vue';
import { PresentationsSidebarOption } from '@/app/shared/models/presentations/PresentationsSidebarOption';
import { PresentationsSidebarOptionGroupEnum } from '@/app/shared/enums/presentations-sidebar-option-group.enum';
import { PresentationsSidebarOptionItemEnum } from '@/app/shared/enums/presentations-sidebar-option-item.enum';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
const PresentationsStore = namespace('Presentations');

@Component({
  components: { PresentationsSidebarSelectionChildItem },
})
export default class PresentationsSidebarSelectionItem extends Vue {
  @Prop() item: CheckTree & { expanded: boolean };
  @Prop() foundItemTitle: string;

  shouldEnumerate: boolean = false;

  get parentCheckStatus(): 'unchecked' | 'partially' | 'checked' {
    let checkChildrenCount = 0;
    for (const item of this.children) {
      if (item.checked) checkChildrenCount++;
    }
    if (checkChildrenCount === 0) {
      this.shouldEnumerate = false;
    }
    return checkChildrenCount === 0
      ? 'unchecked'
      : checkChildrenCount === this.children.length
      ? 'checked'
      : 'partially';
  }

  get children() {
    return this.item.children || [];
  }

  get radioGroupValue() {
    return (
      this.item.children &&
      this.item.children.find((child) => child.checked) &&
      this.item.children.find((child) => child.checked).uuid
    );
  }

  set radioGroupValue(uuid) {
    this.item.children.forEach((child) => {
      if (child.uuid === uuid) child.checked = true;
      else child.checked = false;
    });
  }

  get radioGroupOptions() {
    return (
      this.item.children &&
      this.item.children.map((child, index) => ({
        label: `${this.shouldEnumerate ? index + 1 + '. ' : ''}${child.title}`,
        value: child.uuid,
      }))
    );
  }

  created() {
    eventBus.$on(EventType.PRESENTATIONS_SIDEBAR_OPTIONS_EVENT, this.onPresentationsSidebarOptionEvent);
  }

  @PresentationsStore.Action
  toggleSidebarSelectionItemCheck: (payload: ToggleSidebarSelectionItemCheckPayload) => Promise<void>;

  isTooltipDisabled(item: CheckTreeItem) {
    const el: HTMLElement = (this.$refs.sidebarSelectionItems as HTMLElement).querySelector(
      '#presentations-sidebar-selection-' + item.uuid,
    );
    console.log(item.title, el);
  }

  @Watch('item')
  onItemChange(_newVal: CheckTree & { expanded: boolean }, _oldVal: CheckTree & { expanded: boolean }) {
    if (!this.foundItemTitle) {
      return;
    }
    const hasSearchedItem = this.children.findIndex((el) => el.title.toLowerCase() === this.foundItemTitle) > -1;
    this.toggleExpand(hasSearchedItem);
  }

  @Watch('foundItemTitle')
  onFoundItemTitleChange() {
    if (!this.foundItemTitle) {
      return;
    }
    const hasSearchedItem = this.children.findIndex((el) => el.title.toLowerCase() === this.foundItemTitle) > -1;
    this.toggleExpand(hasSearchedItem);
  }

  @Emit('toggleExpand')
  toggleExpand(_shouldExpand: boolean) {
    // emiting event
  }

  onParentCheckboxValueChange(value: boolean) {
    for (const item of this.children) {
      this.toggleSidebarSelectionItemCheck({
        checktreeUuid: this.item.uuid,
        checktreeItemUuid: item.uuid,
        checked: value,
      });
    }
  }

  onChildCheckboxChange(value: boolean, item: CheckTreeItem) {
    this.toggleSidebarSelectionItemCheck({
      checktreeUuid: this.item.uuid,
      checktreeItemUuid: item.uuid,
      checked: value,
    });
  }

  onPresentationsSidebarOptionEvent(option: PresentationsSidebarOption) {
    if (option.type === PresentationsSidebarOptionGroupEnum.TABLE_ENUMERATE) {
      this.shouldEnumerate =
        option.value === PresentationsSidebarOptionItemEnum.TABLE_ENUMERATE_WITH_NUMBERS &&
        this.parentCheckStatus !== 'unchecked';
    }
  }

  destroyed() {
    eventBus.$off(EventType.PRESENTATIONS_SIDEBAR_OPTIONS_EVENT, this.onPresentationsSidebarOptionEvent);
  }
}
