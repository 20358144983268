


































































import { Component, Prop, Vue } from 'vue-property-decorator';
import PageFooter from '@/app/shared/components/PageFooter.vue';
import { ExpandableSidebarOptions } from '@/app/shared/models/ExpandableSidebar';

@Component({
  components: {
    PageFooter,
  },
})
export default class ExpandableSidebar extends Vue {
  @Prop() isExpandable: boolean;
  @Prop() backgroundType: string;
  @Prop() headingText: string;
  @Prop() subHeadingText?: string;
  @Prop() buttons?: { label: string; route: string }[];
  @Prop() contentWidth?: number;
  @Prop() isContentCentered?: boolean;
  options: ExpandableSidebarOptions = {};
  width: number = 744;
  isExpanded: boolean = false;

  get defaultOptions(): ExpandableSidebarOptions {
    return {
      isExpandable: this.isExpandable,
      backgroundType: this.backgroundType,
      headingText: this.headingText,
      subHeadingText: this.subHeadingText,
      buttons: this.buttons,
      contentWidth: this.contentWidth,
      isContentCentered: this.isContentCentered,
    };
  }

  get backgroundImageSet() {
    try {
      /* eslint-disable @typescript-eslint/no-var-requires */
      const image1x = require(`@/assets/img/backgrounds/background-${this.options.backgroundType}.jpg`);
      const image2x = require(`@/assets/img/backgrounds/background-${this.options.backgroundType}@2x.jpg`);
      /* eslint-enable @typescript-eslint/no-var-requires */
      return `-webkit-image-set(url(${image1x}) 1x, url(${image2x}) 2x)`;
    } catch (error) {
      console.error('Missing background image!', error);
      return 'none';
    }
  }

  get hasButtons() {
    return Boolean(this.options.buttons && this.options.buttons.length);
  }

  get asideWidth() {
    if (this.isExpanded) return '0';
    return 'unset';
  }

  get sectionWidth() {
    if (this.isExpanded) return '100%';
    return `${this.width}px`;
  }

  get expandButtonPosition() {
    if (this.isExpanded) {
      return {
        left: `var(--spacer-xs)`,
      };
    }

    return {
      left: `calc(100% - ${this.width}px + var(--spacer-xs))`,
    };
  }

  created() {
    this.options = this.defaultOptions;
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  onSetOptions(options: ExpandableSidebarOptions) {
    // Reset expanded state if the route is not expandable.
    if (options.isExpandable !== undefined && !options.isExpandable) this.isExpanded = false;

    this.$set(this, 'options', {
      ...this.defaultOptions,
      ...options,
    });

    // Set the document title right away.
    //   We cannot delegate this to the Vue router navigation guard, as it might have already fired at this point.
    if (this.options.metaTitle) document.title = this.$route.meta.title = this.options.metaTitle;
  }
}
