import FormConfigCheckTree from '@/app/shared/components/form/config/FormConfigCheckTree.vue';
import FormFieldCheckTree from '@/app/shared/components/form/field/FormFieldCheckTree.vue';

export default {
  name: 'CheckTree Control',
  description: 'Selection of nested data',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldCheckTree,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigCheckTree,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    isDisabled: false,
    disabledOnValue: '',

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer.
   *   Not really used, as this field does not support pre-defined values.
   *   Instead, they are fetched via API on load.
   * @returns {array}
   */
  rendererDefaultData(): string[] {
    return [];
  },
};
