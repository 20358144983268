import { UserRole } from '../../enums/user-role.enum';
import { ApiResponse, NewApiResponse, MessageApiResponse } from '../../models/ApiResponse';
import { LoginResponse } from '../../models/auth/LoginResponse';
import { SessionResponse } from '../../models/auth/SessionResponse';
import httpClient from '@/app/shared/api/axios.config';

export default {
  async login(username: string, password: string): Promise<ApiResponse<{ accessToken: string }>> {
    const formData = new FormData();

    formData.append('username', username.toLowerCase());
    formData.append('password', password);

    const data: LoginResponse = await httpClient.post('/login', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    return {
      data: {
        accessToken: data.access_token,
      },
      message: 'success',
    };
  },

  async me(): Promise<ApiResponse<SessionResponse>> {
    const data: SessionResponse = await httpClient.get('/user/me');

    // TODO: dev only
    if (data.user.email.startsWith('admin')) data.user.role = UserRole.ADMIN;
    else data.user.role = UserRole.USER;

    return {
      data,
      message: 'success',
    };
  },

  async session(accessToken?: string): Promise<ApiResponse<SessionResponse>> {
    const data: SessionResponse = await httpClient.get('/session', { data: { accessToken } });

    // // TODO: dev only
    // if (data.user.email.startsWith('admin')) data.user.role = UserRole.ADMIN;
    // else data.user.role = UserRole.USER;

    return {
      data,
      message: 'success',
    };
  },

  async logout(): Promise<ApiResponse<{}>> {
    // TODO: missing endpoint!
    const data = await httpClient.get('/logout');

    return {
      data,
      message: 'success',
    };
  },

  async register(payload: {
    email: string;
    password: string;
    user_class: string;
    first_name: string;
    last_name: string;
    g_recaptcha_response: string;
  }): Promise<ApiResponse<{ detail: string }>> {
    const data: { detail: string } = await httpClient.put('/register', payload);

    return {
      data,
      message: 'success',
    };
  },

  async registerAccept(
    uuid: string,
    payload: {
      password: string;
      first_name: string;
      last_name: string;
      g_recaptcha_response: string;
    },
  ): Promise<MessageApiResponse> {
    return await httpClient.post(`/register/accept/${uuid}`, {
      password: payload.password,
      first_name: payload.first_name,
      last_name: payload.last_name,
      g_recaptcha_response: payload.g_recaptcha_response,
    });
  },

  async confirmRegistration(uuid: string): Promise<ApiResponse<{}>> {
    const data = await httpClient.get(`/register/confirm/${encodeURIComponent(uuid)}`);

    return {
      data,
      message: 'success',
    };
  },

  async emailPasswordRecover(email: string): Promise<NewApiResponse<{}>> {
    return await httpClient.post(`/register/recover/`, {
      email: email,
    });
  },

  async validatePasswordRecoverToken(token: string): Promise<NewApiResponse<{}>> {
    return await httpClient.get(`/register/recover/${token}`);
  },

  async updatePassword(password: string, token: string): Promise<NewApiResponse<{}>> {
    return await httpClient.post(`/register/recover/${token}`, {
      new_password: password,
    });
  },
};
