import studyTypeFormSchema from '@/app/shared/components/form/schema/study-type.json';
import studyFacilityNameFormSchema from '@/app/shared/components/form/schema/study-facility-name.json';
import studyFacilityLocationFormSchema from '@/app/shared/components/form/schema/study-facility-location.json';
import studyInvestorFormSchema from '@/app/shared/components/form/schema/study-investor.json';
import studyAssessorFormSchema from '@/app/shared/components/form/schema/study-assessor.json';
import studyAssessmentTeamFormSchema from '@/app/shared/components/form/schema/study-assessment-team.json';
import studyFacilityTypeFormSchema from '@/app/shared/components/form/schema/study-facility-type.json';
import { FormList } from '@/app/shared/models/form/FormSchema';
import { Study } from '@/app/shared/models/Study';

export default [
  {
    id: 'study-type',
    icon: 'study-registration-type',
    label: 'Study',
    schema: studyTypeFormSchema,
  },
  {
    id: 'study-facility-name',
    icon: 'development-facility-type-v1',
    label: 'Development / Facility Name',
    schema: studyFacilityNameFormSchema,
  },
  {
    id: 'study-facility-location',
    icon: 'development-facility-location',
    label: 'Development / Facility Location',
    schema: studyFacilityLocationFormSchema,
  },
  {
    id: 'study-investor',
    icon: 'investor',
    label: 'Investor',
    schema: studyInvestorFormSchema,
  },
  {
    id: 'study-assessor',
    icon: 'assessor',
    label: 'Assessor',
    schema: studyAssessorFormSchema,
  },
  {
    id: 'study-assessment-team',
    icon: 'team',
    label: 'Assessment Team',
    schema: studyAssessmentTeamFormSchema,
  },
  {
    id: 'study-facility-type',
    icon: 'development-facility-type-v2',
    label: 'Development / Facility Type',
    schema: studyFacilityTypeFormSchema,
    isHidden: (study: Study) => study.build_created,
  },
] as FormList[];
