

































import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import api from '@/app/shared/api';
import { namespace } from 'vuex-class';
import WorkflowFileUpload from '@/app/workflow/components/WorkflowFileUpload.vue';

const WorkflowStoreModule = namespace('Workflow');

@Component({
  components: { WorkflowFileUpload },
})
export default class WorkflowCreateChecktreeModal extends Vue {
  @Prop() title!: string;
  @Prop() import!: boolean;

  formName: string = '';
  formDescription: string = '';

  errorMessage: string = '';

  @WorkflowStoreModule.Getter
  getCurrentNode!: Record<any, any>;

  @WorkflowStoreModule.Getter
  getCurrentFile!: any;

  // @WorkflowStoreModule.Mutation
  // addNewlyAddedChecktree!: (data: any) => void;

  @WorkflowStoreModule.Mutation
  setCurrentFile!: (data: any) => void;

  created() {
    this.setCurrentFile([]);
  }

  async save() {
    this.errorMessage = '';

    const data = {
      name: this.formName ? this.formName : null,
      description: this.formDescription ? this.formDescription : null,
      type_id: 1,
      is_admin: true,
    };

    const response: any = await api.how_to_use.importHTU(data, this.getCurrentFile, this.getCurrentNode.module.uuid);
    if (response.status == 'success') {
      // this.addNewlyAddedChecktree(response.data);
      // this.$emit('pre-select-newly-added-checktree', response.data);
      this.$emit('workflow-create-htu-dialog-close');
    } else {
      this.errorMessage = response.message;
    }
  }

  @Emit()
  close() {
    this.$emit('workflow-create-htu-dialog-close');
  }
}
