




















































import { Component, Emit, Prop } from 'vue-property-decorator';
import ResizableTableColumns from '@/app/shared/components/mixins/ResizableTableColumns.vue';

@Component
export default class ResizableTable extends ResizableTableColumns {
  @Prop() header: { text: string; icon: string };
  @Prop() columns: {
    text: string;
    value?: string;
    width?: number;
    color?: string;
    background?: string;
    actionType?: string;
    actionLabel?: string;
    resizable?: string;
  }[];
  @Prop() data: Record<string, string>[];
  tableRef: string = 'simpleTable';
  tableResizableColumnClass: string = 'resizable-table__column-header--is-resizable';

  @Emit('action-click')
  onActionClick(_type: string, _item: Record<string, string>) {
    //
  }

  isEmpty(value: any) {
    return value == null || value === '';
  }
}
