import FormConfigRole from '@/app/shared/components/form/config/FormConfigRole.vue';
import FormFieldRole from '@/app/shared/components/form/field/FormFieldRole.vue';

export default {
  name: 'Role Control',
  description: 'Single choice for a user role',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldRole,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigRole,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    options: [],

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {string}
   */
  rendererDefaultData() {
    return '';
  },
};
