import FormConfigEvent from '@/app/shared/components/form/config/FormConfigEvent.vue';
import FormFieldEvent from '@/app/shared/components/form/field/FormFieldEvent.vue';
import { Event } from '@/app/shared/models/form/field/FormFieldEvent';
import { FormFieldValue, FormFieldValueModel } from '@/app/shared/models/form/field';

export default {
  name: 'Event Control',
  description: 'Complex input for event info',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldEvent,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigEvent,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    isDisabled: false,
    isReadonly: false,

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {FormFieldValue<Event>}
   */
  rendererDefaultData(): FormFieldValue<Event> {
    return new FormFieldValue([new Event()], FormFieldValueModel.EVENT);
  },
};
