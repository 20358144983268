




























import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import Vue from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';
@Component({
  components: {},
})
export default class AddParagraphButton extends Vue {
  @Prop() position: 'before' | 'after';
  @Prop() level: number;
  @Prop() isFirstParagraph: boolean;

  types = [
    { label: 'Heading + Text', type: ChapterItemType.HEADING },
    { label: 'Text', type: ChapterItemType.TEXT },
    { label: 'Image', type: ChapterItemType.IMAGE },
    { label: 'Spreadsheet', type: ChapterItemType.SPREADSHEET },
    { label: 'Table (from Excel as HTML file)', type: ChapterItemType.TABLE },
    { label: 'Content from Envigo Library', type: ChapterItemType.LIBRARY_CONTENT },
  ];

  @Emit()
  select(_event: { type: ChapterItemType; position: 'before' | 'after' }) {
    // emiting event
  }
}
