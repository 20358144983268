
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ResizableTableCells extends Vue {
  currentCell: HTMLTableCellElement = null;
  offsetCoordinate: number = null;
  currentCellDimension: number = null;

  get resizeDirection() {
    return 'horizontal';
  }

  created() {
    document.addEventListener('mouseup', this.onMouseUp);
  }

  beforeDestroy() {
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  getStyleVal(elm: HTMLElement, css: string) {
    return window.getComputedStyle(elm, null).getPropertyValue(css);
  }

  paddingDiff(elm: HTMLElement) {
    if (this.getStyleVal(elm, 'box-sizing') == 'border-box') {
      return 0;
    }

    const startDimension = this.resizeDirection === 'horizontal' ? 'left' : 'top';
    const endDimension = this.resizeDirection === 'horizontal' ? 'right' : 'bottom';

    const padStart = this.getStyleVal(elm, `padding-${startDimension}`);
    const padEnd = this.getStyleVal(elm, `padding-${endDimension}`);
    return parseInt(padStart) + parseInt(padEnd);
  }

  onCellResize(event: MouseEvent) {
    this.offsetCoordinate = this.resizeDirection === 'horizontal' ? event.pageX : event.pageY;

    const padding = this.paddingDiff(this.currentCell);

    this.currentCellDimension =
      (this.resizeDirection === 'horizontal' ? this.currentCell.offsetWidth : this.currentCell.offsetHeight) - padding;

    document.addEventListener('mousemove', this.onMouseMove);
  }

  onMouseMove(event: MouseEvent) {
    const offsetDifference =
      (this.resizeDirection === 'horizontal' ? event.pageX : event.pageY) - this.offsetCoordinate;

    const dimensionName = this.resizeDirection === 'horizontal' ? 'width' : 'height';

    this.currentCell.style[dimensionName] = this.currentCellDimension + offsetDifference + 'px';
    (this.currentCell.style as any)[`min-${dimensionName}`] = this.currentCellDimension + offsetDifference + 'px';
  }

  onMouseUp() {
    document.removeEventListener('mousemove', this.onMouseMove);
    this.currentCell = null;
    this.offsetCoordinate = null;
    this.currentCellDimension = null;
  }
}
