





























import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CalculationsPageHeader from './components/CalculationsPageHeader.vue';
import CalculationsSidebar from './components/CalculationsSidebar.vue';
import CalculationsDataTable, {
  CalculationsColumnActionType,
  CalculationsDataTableColumn,
  CalculationsDataTableType,
} from '@/app/calculations/components/table/DataTable.vue';
import PageFooter from '@/app/shared/components/PageFooter.vue';
import api from '@/app/shared/api';
import { User } from '@/app/shared/models/User';
import { UnitList } from '@/app/shared/models/calculations/UnitList';
import { ModuleProperties } from '@/app/shared/models/Module';
import { CheckTree } from '@/app/shared/models/CheckTree';
import Unit from '@/app/shared/models/calculations/Unit';
import { UserPermission } from '../shared/enums/user-permission.enum';

const CalculationsStore = namespace('Calculations');
const ModulePropertiesStore = namespace('ModuleProperties');
const UserStore = namespace('User');

@Component({
  components: {
    CalculationsPageHeader,
    CalculationsSidebar,
    CalculationsDataTable,
    PageFooter,
  },
})
export default class Calculations extends Vue {
  @Prop() processorType: 'assess' | 'altassess' | 'define' | 'mmdefine' | 'madefine' | 'formulate';
  @Prop() unitListId: number;
  @Prop() findByClass: number;
  type: 'formulate' | 'define' | 'assess';
  fixedColumns: CalculationsDataTableColumn[] = [];
  dynamicColumns: Array<CalculationsDataTableColumn & { checked: boolean }> = [];
  activeFilters: CheckTree[] = [];
  filterChecktrees: CheckTree[] = [];
  isInitialized: boolean = false;

  @UserStore.State
  public user!: User;

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  @CalculationsStore.Getter
  getUnitList: UnitList;

  @CalculationsStore.Action
  initializeUnitList: (payload: {
    unitListId: number;
    module: ModuleProperties;
    filterChecktrees: CheckTree[];
  }) => Promise<void>;

  @ModulePropertiesStore.Getter
  moduleProperties: ModuleProperties;

  @ModulePropertiesStore.Action
  getModuleProperties: () => Promise<void>;

  @Watch('filterChecktrees', { deep: true })
  async onFilterChecktreesChange() {
    if (!this.isInitialized) return;
    this.updateData();
  }

  initializeTableColumns() {
    this.fixedColumns = [
      {
        text: 'Impact phrase',
        value: 'impact_phrase',
        type: CalculationsDataTableType.TITLE,
        // width: (((720 / 1920) * window.screen.width) / 100) * 100 + 'px',
        width: 0.25 * window.screen.width + 'px',
        // width: '28%',
        // width: '38.5%',
        resizable: true,
      },
      {
        text: 'Action',
        value: 'action',
        type: CalculationsDataTableType.ACTION,
        actionType:
          this.type === 'assess'
            ? CalculationsColumnActionType.ASSESS
            : this.type === 'formulate'
            ? CalculationsColumnActionType.FORMULATE
            : CalculationsColumnActionType.DEFINE,
        width: '172px !important',
        // width: '9.25% !important',
        resizable: false,
        actionIsDisabled: (item: Unit) => !!item.locked_by,
      },
    ];
    if (
      (this.type === 'assess' || this.type === 'define') &&
      this.moduleProperties.module_type !== 'ProcessorAdminDefineModule'
    ) {
      this.fixedColumns.push({
        text: 'Significance',
        value: 'significance',
        type: CalculationsDataTableType.SIGNIFICANCE,
        width: '172px !important',
        // width: '9.25% !important',
        resizable: false,
      });
    }

    // remaining columns width sum = 43%
    // const tagsColumnInitialWidth = (43 / Object.keys(exampleUnit.grouped_tags).length).toFixed(2) + '%';
    // const tagsColumnInitialWidth = `calc((100% - 1066px) / ${Object.keys(exampleUnit.grouped_tags).length})`;

    for (const tagsColumn of this.tagsColumns) {
      this.dynamicColumns.push({
        text: tagsColumn.name,
        value: tagsColumn.field,
        type: CalculationsDataTableType.SELECT,
        resizable: true,
        checked: true,
      });
    }
  }

  get tableColumns() {
    return [...this.fixedColumns, ...this.dynamicColumns.filter((el) => el.checked)];
  }

  get tagsColumns() {
    return this.getUnitList.dynamic_columns;
  }

  get tableData() {
    return this.getUnitList && this.getUnitList.children;
  }

  get headerIcon() {
    switch (this.processorType) {
      case 'assess':
        return 'processing';
      case 'formulate':
        return 'formulas-and-scores';
      default:
        return 'calculations-method';
    }
  }

  get headerTitle() {
    switch (this.processorType) {
      case 'assess':
        return 'Impact Assessment';
      case 'formulate':
        return 'Formulas and Scores';
      default:
        return 'Mitigations Measures';
    }
  }

  get pageTitle() {
    return this.moduleProperties ? this.moduleProperties?.name || this.headerTitle || '' : '';
  }

  get filterByClass() {
    return this.moduleProperties?.filter_by_class;
  }

  get hasActiveFilterChecktrees() {
    return this.filterChecktrees.some((filterChecktree) =>
      filterChecktree.children.some((checktreeItem) => checktreeItem.checked),
    );
  }

  created() {
    switch (this.processorType) {
      case 'assess':
      case 'altassess':
        this.type = 'assess';
        break;
      case 'formulate':
        this.type = 'formulate';
        break;
      case 'define':
      case 'mmdefine':
      case 'madefine':
        this.type = 'define';
        break;
      default:
        this.$router.back();
    }

    this.initializeData();
  }

  async initializeData() {
    try {
      await this.getModuleProperties();

      let processorPermission;

      // Check if the user has enough permissions to access this type of processor.
      switch (this.moduleProperties.module_type) {
        case 'ProcessorFormulateModule':
          processorPermission = UserPermission.PROCESSOR_ADMIN_FORMULATE_ACCESS;
          break;
        case 'ProcessorAdminDefineModule':
          processorPermission = UserPermission.PROCESSOR_ADMIN_DEFINE_ACCESS;
          break;
        case 'ProcessorUserDefineModule':
          processorPermission = UserPermission.PROCESSOR_USER_DEFINE_ACCESS;
          break;
        case 'ProcessorAssessModule':
          processorPermission = UserPermission.PROCESSOR_USER_ASSESS_ACCESS;
          break;
      }

      const moduleChecktrees = await api.module.getModuleFilters(
        this.moduleProperties.study_uuid,
        this.moduleProperties.module_uuid,
      );
      this.filterChecktrees = moduleChecktrees as Array<any>;

      await this.initializeUnitList({
        unitListId: this.unitListId,
        module: this.moduleProperties,
        filterChecktrees: [], // empty on the first load
      });

      if (!(await this.isAllowed(processorPermission))) {
        this.$router.replace('/error/403').catch(() => {
          // Dummy error handler for avoiding duplicated navigation logs.
        });
        return;
      }

      this.initializeTableColumns();
    } catch (error) {
      console.error(error);
    } finally {
      this.isInitialized = true;
    }
  }

  updateData() {
    let filterChecktrees: CheckTree[] = [];

    if (this.hasActiveFilterChecktrees) filterChecktrees = this.filterChecktrees;

    this.initializeUnitList({
      unitListId: this.unitListId,
      module: this.moduleProperties,
      filterChecktrees,
    });
  }

  onSelectedColumnsChange(columns: Array<CalculationsDataTableColumn & { checked: boolean }>) {
    this.dynamicColumns = columns;
  }

  async onResume() {
    await api.calculations.resume(this.unitListId, this.$route.fullPath, this.moduleProperties).then(() => {
      this.$router.go(-1);
    });
    return;
  }
}
