import { LibraryParagraph } from './../../models/presentations/LibraryParagraph';
import { Study } from './../../models/Study';
import httpClient from '@/app/shared/api/axios.config';
import LibraryItems from '@/app/shared/models/library/LibraryItems';
import LibraryItem from '@/app/shared/models/library/LibraryItem';

const LIBRARY_ENDPOINT = '/library';

export default {
  async getStudy(): Promise<Study[]> {
    return await httpClient.get('/study/');
  },
  async getLibraryContent(studyUuid: string) {
    const data: LibraryItems = await httpClient.get(`${LIBRARY_ENDPOINT}/paragraphs/${studyUuid}`);
    return data;
  },
  async getLibraryItem(uuid: string): Promise<LibraryItem> {
    return await httpClient.get(`${LIBRARY_ENDPOINT}/${uuid}`);
  },
  async deleteLibraryItem(uuid: string) {
    await httpClient.delete(`${LIBRARY_ENDPOINT}/${uuid}`);
  },
  async addLibraryParagraph(data: LibraryParagraph) {
    const res: any = await httpClient.put(`${LIBRARY_ENDPOINT}/`, data);
    return res;
  },
  async editLibraryParagraph(data: LibraryParagraph) {
    const res: any = await httpClient.post(`${LIBRARY_ENDPOINT}/`, data);
    return res;
  },
};
