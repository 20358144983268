import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
// import api from '@/app/shared/api';
import { TrackingHistory, TrackingItem, TrackingMode, TrackingResponseItem } from '@/app/shared/models/Tracking';
import { User } from '@/app/shared/models/User';
import LoaderService from '@/app/shared/utils/loader.service';
import store from '..';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(weekday);

const dateFormat = 'YYYY-MM-DD';

@Module({ namespaced: true })
class TrackingModule extends VuexModule {
  objectModel: string;
  objectUuid: string;
  mode: TrackingMode = 'feed';
  trackingItems: TrackingResponseItem[] = [];
  trackingItemFilter = 'all';
  trackingItemDateFilter = 'all';
  userLookup: Record<string, User> = null;

  get isTrackingFeedMode() {
    return this.mode === 'feed';
  }

  get isTrackingHistoryMode() {
    return this.mode === 'history';
  }

  get filteredTrackingItems() {
    return this.trackingItems
      .sort((a, b) => {
        const aDayObj = dayjs.utc(a.created);
        const bDayObj = dayjs.utc(b.created);
        if (aDayObj > bDayObj) return -1;
        if (aDayObj < bDayObj) return 1;
        return 0;
      })
      .filter((trackingItem) => {
        switch (this.trackingItemFilter) {
          case 'user':
            return trackingItem.user_uuid === store.getters['User/userUuid'];
          case 'locked':
            return trackingItem.is_locked;
          case 'all':
          default:
            return true;
        }
      });
  }

  get trackingItemFeed() {
    if (!this.filteredTrackingItems.length) return [];
    return Object.values(
      this.filteredTrackingItems.reduce(
        (objectTrackingItems, trackingItem) => ({
          ...objectTrackingItems,
          [trackingItem.object_item_uuid]: {
            uuid: trackingItem.uuid,
            is_locked: trackingItem.is_locked,
            is_updated: trackingItem.is_updated,
            is_expanded: trackingItem.is_expanded,
            activity: [
              ...((objectTrackingItems[trackingItem.object_item_uuid as never] &&
                objectTrackingItems[trackingItem.object_item_uuid as never].activity) ||
                []),
              {
                uuid: trackingItem.uuid,
                created: trackingItem.created,
                type: trackingItem.type,
                user_uuid: trackingItem.user_uuid,
                user: this.userLookup[trackingItem.user_uuid as never],
              },
            ] as never,
            object_model: trackingItem.object_model,
            object_uuid: trackingItem.object_uuid,
            object_module: trackingItem.object_module,
            object_item_uuid: trackingItem.object_item_uuid,
            object_item_type: trackingItem.object_item_type,
            object_item_key: trackingItem.object_item_key,
          } as never,
        }),
        {} as Record<string, TrackingItem>,
      ),
    );
  }

  get trackingItemHistory() {
    if (!this.filteredTrackingItems.length) return {};
    return this.filteredTrackingItems.reduce((trackingItemHistory, trackingItem) => {
      const date = dayjs
        .utc(trackingItem.created)
        .local()
        .format(dateFormat);
      return {
        ...trackingItemHistory,
        [date]: [
          ...(((trackingItemHistory[date] as never) as TrackingHistory[]) || []),
          {
            uuid: trackingItem.uuid,
            created: trackingItem.created,
            is_locked: trackingItem.is_locked,
            is_updated: trackingItem.is_updated,
            type: trackingItem.type,
            user_uuid: trackingItem.user_uuid,
            user: this.userLookup[trackingItem.user_uuid as never],
            object_model: trackingItem.object_model,
            object_uuid: trackingItem.object_uuid,
            object_module: trackingItem.object_module,
            object_item_uuid: trackingItem.object_item_uuid,
            object_item_type: trackingItem.object_item_type,
            object_item_key: trackingItem.object_item_key,
          },
        ] as never,
      };
    }, {} as Record<string, TrackingHistory[]>);
  }

  get hasExpandedTrackingItem() {
    return this.trackingItems.some((trackingItem) => trackingItem.is_expanded);
  }

  get trackingItemFilters() {
    return [
      {
        text: 'All',
        value: 'all',
      },
      {
        text: 'Only yours',
        value: 'user',
      },
      {
        text: 'Locked',
        value: 'locked',
      },
    ];
  }

  get trackingItemHistoryLastWeekDate() {
    return Object.keys(this.trackingItemHistory).find(
      (date) =>
        dayjs(date, dateFormat) >=
          dayjs()
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .day(1)
            .subtract(1, 'week') &&
        dayjs(date, dateFormat) <
          dayjs()
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .day(1),
    );
  }

  get trackingItemHistoryLastMonthDate() {
    return Object.keys(this.trackingItemHistory).find(
      (date) =>
        dayjs(date, dateFormat) >=
          dayjs()
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .date(1)
            .subtract(1, 'month') &&
        dayjs(date, dateFormat) <
          dayjs()
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .date(1),
    );
  }

  get dateItems() {
    return [
      {
        text: 'Last week',
        value: this.trackingItemHistoryLastWeekDate,
        disabled: !this.trackingItemHistoryLastWeekDate,
      },
      {
        text: 'Last month',
        value: this.trackingItemHistoryLastMonthDate,
        disabled: !this.trackingItemHistoryLastMonthDate,
      },
      {
        text: 'The very beginning',
        value: 'all',
      },
    ];
  }

  @Mutation
  private setTrackingItemObject(payload: { objectModel: string; objectUuid: string }) {
    this.objectModel = payload.objectModel;
    this.objectUuid = payload.objectUuid;
    this.mode = 'feed';
  }

  @Mutation
  private setMode(mode: TrackingMode) {
    this.mode = mode;
  }

  @Mutation
  private setTrackingItems(trackingItems: TrackingResponseItem[]) {
    this.trackingItems = trackingItems;
  }

  @Mutation
  private setUserLookup(users: User[]) {
    this.userLookup = users.reduce(
      (userLookup, user) =>
        ({
          ...userLookup,
          [user.uuid]: user,
        } as Record<string, User>),
      {},
    );
  }

  @Mutation
  private setTrackingItemFilter(trackingItemFilter: string) {
    this.trackingItemFilter = trackingItemFilter;
  }

  @Mutation
  private setTrackingItemDateFilter(trackingItemDateFilter: string) {
    this.trackingItemDateFilter = trackingItemDateFilter;
  }

  @Action({ rawError: true })
  public expandTrackingItem(uuid: string) {
    const trackingItem = this.trackingItems.find((trackingItem) => trackingItem.uuid === uuid);
    if (!trackingItem) return;
    this.context.dispatch('toggleExpand', { trackingItem, isExpanded: true });
  }

  @Action({ rawError: true })
  public switchMode() {
    switch (this.mode) {
      case 'history':
        this.context.commit('setMode', 'feed');
        break;
      case 'feed':
      default:
        this.context.commit('setMode', 'history');
    }

    this.context.dispatch('collapseAll');
  }

  @Action({ rawError: true })
  async getTrackingItems(): Promise<void> {
    try {
      LoaderService.disableHttpLoader();

      // TODO: dev only
      // const res = await api.tracking.getTrackingItems(this.objectModel, this.objectUuid);
      const res = {
        items: [
          {
            uuid: '4a363a25-30f5-476f-97c5-61e506f89ff2',
            created: '2022-07-15 09:07:12.965124',
            type: 'edit',
            is_locked: true,
            is_expanded: false,
            user_uuid: '55820e22-9404-4eb1-8e97-698b36482c2d',
            object_model: 'Chapter',
            object_uuid: '9027f39e-ec8d-4ba0-ab43-d110f0bd734a',
            object_item_uuid: 'ee3d69c2-a16f-4c5b-b9cc-8fd3b13df1b8',
            object_item_type: 'ParagraphHeading',
            object_item_key: '',
          },
          {
            uuid: 'efb80ccd-d276-4f3c-9820-458f2be77fd7',
            created: '2022-07-15 08:34:45.437242',
            type: 'reorder',
            is_locked: true,
            is_expanded: false,
            user_uuid: 'd1b3abea-35c3-46f3-a7dd-d2c2741be7ee',
            object_model: 'Chapter',
            object_uuid: '9027f39e-ec8d-4ba0-ab43-d110f0bd734a',
            object_item_uuid: 'ee3d69c2-a16f-4c5b-b9cc-8fd3b13df1b8',
            object_item_type: 'ParagraphHeading',
            object_item_key: '',
          },
          {
            uuid: '770183c5-93a0-488b-a48f-6387fadaff60',
            created: '2022-07-14 14:32:18.876442',
            type: 'edit',
            is_locked: true,
            is_expanded: false,
            user_uuid: '765d09f2-8efc-410f-ba38-49fcc94de9e6',
            object_model: 'Chapter',
            object_uuid: '9027f39e-ec8d-4ba0-ab43-d110f0bd734a',
            object_item_uuid: 'ee3d69c2-a16f-4c5b-b9cc-8fd3b13df1b8',
            object_item_type: 'ParagraphHeading',
            object_item_key: '',
          },
          {
            uuid: 'f9934891-22bb-43b8-ba50-79fb85afe69f',
            created: '2021-12-12 12:12:27.346572',
            type: 'edit',
            is_locked: true,
            is_expanded: false,
            user_uuid: 'f6a0b6bd-edc7-4fd4-b1eb-a065c5d647fe',
            object_model: 'Chapter',
            object_uuid: '9027f39e-ec8d-4ba0-ab43-d110f0bd734a',
            object_item_uuid: 'ee3d69c2-a16f-4c5b-b9cc-8fd3b13df1b8',
            object_item_type: 'ParagraphHeading',
            object_item_key: '',
          },
          {
            uuid: 'a5a66660-a229-4eb0-b938-2909178dcb9d',
            created: '2021-12-12 08:39:51.672406',
            type: 'add',
            is_locked: true,
            is_expanded: false,
            user_uuid: '55820e22-9404-4eb1-8e97-698b36482c2d',
            object_model: 'Chapter',
            object_uuid: '9027f39e-ec8d-4ba0-ab43-d110f0bd734a',
            object_item_uuid: 'ee3d69c2-a16f-4c5b-b9cc-8fd3b13df1b8',
            object_item_type: 'ParagraphHeading',
            object_item_key: '',
          },
          {
            uuid: 'd8fc7852-30d8-4fd1-9207-a8b4b474df27',
            created: '2021-12-12 08:39:51.672406',
            type: 'add',
            is_locked: true,
            is_expanded: false,
            user_uuid: 'd1b3abea-35c3-46f3-a7dd-d2c2741be7ee',
            object_model: 'Chapter',
            object_uuid: '9027f39e-ec8d-4ba0-ab43-d110f0bd734a',
            object_item_uuid: '4963b9f3-5948-4ab8-847c-683f1a74af5f',
            object_item_type: 'ParagraphImage',
            object_item_key: '',
          },
          {
            uuid: 'e5359691-a40e-46cd-9aae-268ed9c81b84',
            created: '2022-07-23 14:33:07.642138',
            type: 'resume',
            name: 'Module Name',
            is_locked: false,
            is_updated: true,
            is_expanded: false,
            user_uuid: '67637aec-3fdb-4b13-9e9d-2114f84a8a66',
            object_model: 'Chapter',
            object_module: 'Aspects',
            object_uuid: '9027f39e-ec8d-4ba0-ab43-d110f0bd734a',
            object_item_uuid: '99f3013c-94ea-4e51-a1a8-b6cf7b13cdea',
            object_item_type: 'ParagraphHeading',
            object_item_key: '',
          },
          {
            uuid: '3bdd9565-51ad-4338-b321-e7043ae140cb',
            created: '2022-07-24 09:18:53.731524',
            type: 'add',
            is_locked: false,
            is_expanded: false,
            user_uuid: '765d09f2-8efc-410f-ba38-49fcc94de9e6',
            object_model: 'Chapter',
            object_uuid: '9027f39e-ec8d-4ba0-ab43-d110f0bd734a',
            object_item_uuid: '32478eeb-602f-472b-8923-15c667f69bef',
            object_item_type: 'ParagraphHeading',
            object_item_key: '',
          },
        ],
        users: [
          {
            id: 1,
            uuid: '55820e22-9404-4eb1-8e97-698b36482c2d',
            type: 'Admin',
            email: 'camila@example.com',
            first_name: 'Camila',
            last_name: 'Méndez',
            position: 'Director',
            organization_uuid: 'c4643032-c4e3-41c5-a80c-e671edfeb44e',
            role: 'Admin',
            avatar: '2cbfb9408e25522f449412d77ae5fc16a4f633fa',
          },
          {
            id: 2,
            uuid: 'd1b3abea-35c3-46f3-a7dd-d2c2741be7ee',
            type: 'User',
            email: 'marian@example.com',
            first_name: 'Marian',
            last_name: 'Lupulescu',
            position: 'Engineer',
            organization_uuid: 'c4643032-c4e3-41c5-a80c-e671edfeb44e',
            role: 'Expert',
            avatar: 'c061f334fed85538811247a6b2b4a66200db6de8',
          },
          {
            id: 3,
            uuid: '765d09f2-8efc-410f-ba38-49fcc94de9e6',
            type: 'User',
            email: 'anne@example.com',
            first_name: 'Anne',
            last_name: 'Bishop',
            position: 'Assistant',
            organization_uuid: 'c4643032-c4e3-41c5-a80c-e671edfeb44e',
            role: 'Client',
            avatar: '1f591ae6b165b6b2d1b0dbd9b14dfcbf52244fd7',
          },
          {
            id: 4,
            uuid: 'f6a0b6bd-edc7-4fd4-b1eb-a065c5d647fe',
            type: 'User',
            email: 'marcus@example.com',
            first_name: 'Marcus',
            last_name: 'Paige',
            position: 'Architect',
            organization_uuid: 'c4643032-c4e3-41c5-a80c-e671edfeb44e',
            role: 'Expert',
            avatar: '35bcc49a477a227cf650b810d0bbd266d93c633e',
          },
          {
            id: 8,
            uuid: '67637aec-3fdb-4b13-9e9d-2114f84a8a66',
            type: 'Admin',
            email: 'admin@example.com',
            first_name: 'Admin',
            last_name: 'User',
            position: 'Admin',
            organization_uuid: 'c4643032-c4e3-41c5-a80c-e671edfeb44e',
            role: 'Admin',
            avatar: '891eb099110aca67720a06b13ac98373a6537dee',
          },
        ],
      };

      this.context.commit('setTrackingItems', res.items);
      this.context.commit('setUserLookup', res.users);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  @Action({ rawError: true })
  async toggleExpand(payload: { trackingItem: TrackingItem; isExpanded: boolean }) {
    if (payload.isExpanded) {
      this.trackingItems.forEach((trackingItem) => {
        if (trackingItem.uuid === payload.trackingItem.uuid) trackingItem.is_expanded = true;
        else trackingItem.is_expanded = false;
      });
    } else {
      this.trackingItems.find((trackingItem) => trackingItem.uuid === payload.trackingItem.uuid).is_expanded = false;
    }
  }

  @Action({ rawError: true })
  collapseAll() {
    this.trackingItems.forEach((trackingItem) => {
      trackingItem.is_expanded = false;
    });
  }

  @Action({ rawError: true })
  async unlockTrackingItem(trackingItem: TrackingItem) {
    // TODO: dev only
    this.trackingItems
      .filter((i) => i.object_item_uuid === trackingItem.object_item_uuid)
      .forEach((i) => (i.is_locked = false));
  }
}

export default TrackingModule;
