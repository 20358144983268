















































import { Component, Prop, Vue } from 'vue-property-decorator';
import ImpactTable from '@/app/shared/components/ImpactTable.vue';
import { SingleImpact } from '@/app/shared/models/reports/SingleImpact';
import CheckTreeItem from '@/app/shared/models/CheckTreeItem';
import { UnitAlternativePhase } from '@/app/shared/models/calculations/UnitAlternative';

@Component({ components: { ImpactTable } })
export default class PresentationsAssessingTableImpact extends Vue {
  @Prop() impact: SingleImpact;
  @Prop() criteriaList: CheckTreeItem[];
  @Prop() viewMode: 'list' | 'comments' | 'details' | 'details_and_comments';

  style = {
    // '.impact-table': { margin: '0' },
    tableRow: {
      position: 'relative',
      paddingLeft: '6px',
      width: '100%',
    },
    impactTitle: {
      padding: '0 0.75rem',
      display: 'flex',
      alignItems: 'center',
      height: '48px',
    },
    impactTitleSpan: {
      fontSize: '16px',
      lineHeight: '18px',
      letterSpacing: '0.25px',
      fontWeight: '500',
      textAlign: 'left',
      color: '#222C37',
    },
    impactDetails: {
      padding: '0 14px 0 26px',
      fontSize: '14x',
      lineHeight: '18px',
      letterSpacing: '0.25px',
      textAlign: 'left',
      paddingBottom: '12px',
    },
    rowMarker: {
      position: 'absolute',
      left: '0',
      top: '0',
      bottom: '0',
      background: 'palegreen',
      width: '6px',
    },
    alternative: {
      height: '32px',
      background: '#f8fafa',
      paddingLeft: '0.5rem',
      paddingRight: '16px',
      borderBottom: '1px solid #E5EAEC',
      display: 'flex',
      alignItems: 'center',
    },
    alternativeName: {
      marginLeft: '4px',
      color: '#6F8195',
      fontSize: '13px',
      lineHeight: '13px',
      fontStyle: 'italic',
      letterSpacing: '0.25px',
    },
    phase: {
      height: '32px',
      borderBottom: '1px solid #E5EAEC',
      display: 'flex',
      alignItems: 'center',
    },
    phaseName: {
      padding: '0px 0.75rem',
      textAlign: 'left',
      fontWeight: '500',
      letterSpacing: '0.25px',
      fontSize: '12px',
      lineHeight: '12px',
      color: '#222C37',
      width: '168px',
      flexShrink: '0',
      height: '32px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      // display: '-webkit-box !important',
      // '-webkit-line-clamp': 2,
      // '-webkit-box-orient': 'vertical',
    },
    phaseNameSpan: {
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      width: '100%',
      overflow: 'hidden',
      wordBreak: 'break-word',
    },
    selectionWrapper: {
      flex: 1,
      display: 'flex',
      height: '100%',
      overflow: 'auto',
    },
    detailsCol: {
      width: '80px',
      height: '100%',
      background: '#edfcff',
      fontWeight: 500,
      color: '#222C37',
      fontSize: '11px',
      lineHeight: '12px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderLeft: '1px solid #d3dadc',
      textTransform: 'capitalize',
      flexShrink: 0,
      padding: '4px',
      wordBreak: 'break-all',
    },
    phaseScore: {
      height: '32px',
      flexBasis: '40px',
      flexShrink: 0,
      borderLeft: '1px solid #d3dadc',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    phaseScoreMarker: { height: '6px', width: '100%' },
    phaseScoreValue: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '14px',
      letterSpacing: '0.25px',
      color: '#222C37',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
    },
  };

  getPhaseSelections(phase: UnitAlternativePhase) {
    const selections = [];
    const criterias = this.criteriaList.map((el) => [...el.children]).reduce((prev, curr) => [...prev, ...curr]);
    for (const criteria of criterias) {
      const selection = phase.selections.find((el) => el.parent.uuid === criteria.uuid);
      selections.push(selection || null);
    }
    // if (this.impact.name === 'Increase of  Economic development due to Employment from Construction') {
    //   console.log(this.criteriaList);
    //   console.log(this.impact);
    //   console.log(criterias);
    //   console.log(selections);
    // }
    return selections;
  }

  mounted() {
    const row = this.$refs.tableRow as HTMLElement;
    row.querySelectorAll('.alternative .v-icon.alt-icon svg path').forEach((el) => {
      (el as HTMLElement).style.fill = '#6F8195';
    });
  }
}
