














import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';
import Loader from '@/app/shared/components/Loader.vue';
import { namespace } from 'vuex-class';
import TinyEditor from './TinyEditor.vue';
const UiStore = namespace('UI');

@Component({
  components: { editor: Editor, Loader, TinyEditor },
})
export default class TinyEditorModal extends Vue {
  @Prop() private value!: string;
  @Prop() active: boolean;
  @Prop({ default: 'text' }) outputFormat: 'text' | 'html';

  content: string = '';
  initialized: boolean = false;

  @UiStore.Action
  setTinyEditorVisibility: (value: boolean) => void;

  created() {
    this.setTinyEditorVisibility(true);
    if (this.value) {
      this.content = this.value;
    }
  }

  onInit() {
    // (document.querySelector('.tox.tox-tinymce') as HTMLElement).style.height = '124px';
    this.initialized = true;
  }

  @Emit()
  input(_value: string) {
    // just emiting event
  }

  @Emit()
  close() {
    // just emiting event
  }

  @Watch('value')
  onValuePropChange() {
    this.content = this.value;
  }

  @Watch('content')
  onContentChange() {
    this.input(this.content);
  }
}
