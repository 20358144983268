
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ExpandableSidebarOptions } from '@/app/shared/models/ExpandableSidebar';

@Component
export default class ExpandableSidebarRoute extends Vue {
  @Prop() isExpanded: boolean;
  expandableSidebarOptions: ExpandableSidebarOptions = {};

  @Emit('set-options')
  setOptions(_options: ExpandableSidebarOptions) {
    //
  }

  mounted() {
    this.setOptions(this.expandableSidebarOptions);
  }
}
