import httpClient from '@/app/shared/api/axios.config';
import MatrixRelationMatrix from '@/app/shared/models/MatrixRelationMatrix';

const END_POINT = '/matrix';
export default {
  async getByUuid(uuid: string) {
    const data: MatrixRelationMatrix = await httpClient.get(`${END_POINT}/${uuid}`);
    return data;
  },

  async importMatrix(data: any, file: string | Blob) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('file', file);
    return await httpClient.put(`${END_POINT}/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  async resume(uuid: string) {
    await httpClient.post(`${END_POINT}/${uuid}/resume`);
  },
};
