export class Location {
  uuid?: string;
  name: string;
  region: string;
  municipality: string;
  latitude: string;
  longitude: string;
  facility_id: number[];
  area_classification: string;
  isDetailsHidden: boolean;

  constructor(
    name: string = '',
    region: string = '',
    municipality: string = '',
    latitude: string = '',
    longitude: string = '',
    facility_id: number[] = [],
    area_classification: string = '',
    isDetailsHidden: boolean = false,
  ) {
    this.name = name;
    this.region = region;
    this.municipality = municipality;
    this.latitude = latitude;
    this.longitude = longitude;
    this.facility_id = facility_id;
    this.area_classification = area_classification;
    this.isDetailsHidden = isDetailsHidden;
  }
}
