import studyReviewsFormSchema from '@/app/shared/components/form/schema/study-reviews.json';
import { FormList } from '@/app/shared/models/form/FormSchema';

export default [
  {
    id: 'study-screening',
    label: 'Screening Timeline',
    schema: studyReviewsFormSchema,
  },
  {
    id: 'study-scoping',
    label: 'Scoping Timeline',
    schema: studyReviewsFormSchema,
  },
  {
    id: 'study-assessing',
    label: 'Assessing Timeline',
    schema: studyReviewsFormSchema,
  },
] as FormList[];
