







import { Component, Emit, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class PresentationsBottomActionBar extends Vue {
  @Emit('clear')
  clear() {
    // emitting event
  }

  @Emit('add-to-library')
  addToLibrary() {
    // emitting event
  }
}
