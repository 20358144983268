import { SetTemplateResponse, TemplateGroup, TemplateStage, TemplateType } from '@/app/shared/models/Template';
import httpClient from '@/app/shared/api/axios.config';
import { NewApiResponse } from '@/app/shared/models/ApiResponse';

const TEMPLATES_ENDPOINT = '/templates';

export default {
  async getTemplateGroups(
    workspaceItemUuid: string,
    moduleUuid: string,
    studyUuid: string,
    stage: string,
  ): Promise<TemplateGroup[]> {
    return await httpClient.get(`${TEMPLATES_ENDPOINT}/${workspaceItemUuid}/${moduleUuid}/${studyUuid}/${stage}`);
  },

  async setTemplate(
    workspaceItemUuid: string,
    studyUuid: string,
    stage: TemplateStage,
    templateUuid: string,
    templateType: TemplateType,
  ): Promise<SetTemplateResponse> {
    return await httpClient.post(
      `${TEMPLATES_ENDPOINT}/set/${workspaceItemUuid}/${studyUuid}/${templateUuid}/${stage}/${templateType}`,
    );
  },

  async resumeTemplate(
    checkTreeUuid: string,
    workspaceItemUuid: string,
    stage: TemplateStage,
    templateType: TemplateType,
  ): Promise<NewApiResponse<any>> {
    return await httpClient.post(
      `${TEMPLATES_ENDPOINT}/resume/${checkTreeUuid}/${workspaceItemUuid}/${stage}/${templateType}`,
    );
  },
};
