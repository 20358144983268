































import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaseInput extends Vue {
  @Model('input') value: string;
  @Prop() label: string;

  @Emit()
  input(_value: string) {
    //
  }
}
