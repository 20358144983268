import FormConfigAuthority from '@/app/shared/components/form/config/FormConfigAuthority.vue';
import FormFieldAuthority from '@/app/shared/components/form/field/FormFieldAuthority.vue';
import { Authority } from '@/app/shared/models/form/field/FormFieldAuthority';
import { FormFieldValue, FormFieldValueModel } from '@/app/shared/models/form/field';

export default {
  name: 'Authority Control',
  description: 'Complex input for authority info',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldAuthority,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigAuthority,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    isDisabled: false,
    isReadonly: false,

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {FormFieldValue<Authority>}
   */
  rendererDefaultData(): FormFieldValue<Authority> {
    return new FormFieldValue([new Authority()], FormFieldValueModel.AUTHORITY);
  },
};
