
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FormFieldFocus extends Vue {
  control: {
    uniqueId: string;
  };

  mounted() {
    this.$form.fields = this.$form.fields || {};
    this.$form.fields[this.control.uniqueId] = this;
  }

  focus() {
    if (this.$refs.formField) (this.$refs.formField as Vue & { focus: () => void }).focus();
  }
}
