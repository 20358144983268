var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{ref:"tableHead",staticClass:"MatrixTable__BodyColumn MatrixTable__BodyColumn--RowLabel",class:[
    { 'parent-expanded-row': _vm.row.isParent && _vm.row.children[0].expanded && _vm.row.level == 0 },
    { 'parent-collapsed-row': _vm.row.isParent && !_vm.row.children[0].expanded && _vm.row.level == 0 },
    { 'parent-expanded-row-padding': _vm.row.isParent && _vm.row.children[0].expanded && _vm.row.level == 0 },
    { 'parent-collapsed-padding': _vm.row.isParent && !_vm.row.children[0].expanded && _vm.row.level == 0 },
    { 'in-progress': _vm.getRowStatus == 'in-progress' && (_vm.row.level != 0 || !_vm.row.isParent) },
    { done: _vm.getRowStatus == 'done' && (_vm.row.level != 0 || !_vm.row.isParent) },
    { new: _vm.getRowStatus == 'new' && (_vm.row.level != 0 || !_vm.row.isParent) },
    { 'link-state': _vm.row.level != 0 || !_vm.row.isParent } ],style:({
    background: _vm.row.isParent && _vm.row.level == 0 && _vm.row.color && _vm.row.children[0].expanded ? ("#" + (_vm.row.color)) : '',
  }),attrs:{"tabindex":_vm.row.level != 0 || !_vm.row.isParent ? '0' : '-1'}},[_c('div',{staticClass:"d-flex align-center justify-space-between cell-header-wrapper",class:[{ 'caret-icon-padding': _vm.row.children.length > 0 }, { 'parent-padding': _vm.row.isParent && _vm.row.level == 0 }]},[_c('div',{staticClass:"d-flex align-center",on:{"click":_vm.goToSingleImpact}},[(!_vm.row.isParent)?_c('div',{staticClass:"custom-color",style:({ background: _vm.row.color ? ("#" + (_vm.row.color)) : 'var(--v-gray-pale)' })}):_vm._e(),(_vm.row.level != 0 || !_vm.row.isParent)?_c('v-icon',{staticClass:"enter-icon",class:{ 'first-level-margin': _vm.row.level == 0 && !_vm.row.isParent },style:({ 'margin-right': ((_vm.row.level * 8) + "px") })},[_vm._v("$enter")]):_vm._e(),_c('v-tooltip',{attrs:{"content-class":"ellipsis-text","right":"","offset-overflow":"","transition":"fade"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-flex align-center justify-center row-name-wrapper",class:{ 'regular-font': _vm.row.level > 1 }},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.row.title)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.row.title))])])],1),_c('div',{staticClass:"d-flex"},[(_vm.getRowStatus != 'none' && (_vm.row.level != 0 || !_vm.row.isParent))?_c('v-icon',{staticClass:"progress-icon"},[_vm._v("$"+_vm._s(_vm.statusIcon[_vm.getRowStatus]))]):_vm._e(),(_vm.row.children.length > 0)?_c('BaseButton',{staticClass:"row-expand-btn",class:{ 'expand-icon-child': _vm.row.level > 0 },attrs:{"type":"icon-septim"},on:{"click":function($event){return _vm.toogleExpand(_vm.row)}}},[(_vm.row.children[0].expanded)?_c('v-icon',[_vm._v("$collapse")]):_c('v-icon',[_vm._v("$reveal")])],1):_vm._e()],1),(_vm.index === 0)?_c('div',{staticClass:"row-resize-grabber",on:{"mousedown":function($event){$event.preventDefault();return _vm.onRowResize.apply(null, arguments)}}},[_c('v-icon',[_vm._v("$resize-grabber")])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }