























import { Component, Prop, Vue } from 'vue-property-decorator';
import { TooltipOptions } from '../../store/modules/ui.store-module';

@Component
export default class BaseTooltip extends Vue {
  @Prop() options: TooltipOptions;
  @Prop() hideCallback: () => void;
  @Prop({ default: '2px' }) borderRadius: string;
}
