import FormConfigSelect from '@/app/shared/components/form/config/FormConfigSelect.vue';
import FormFieldSelect from '@/app/shared/components/form/field/FormFieldSelect.vue';
import { FormControl } from '@/app/shared/models/form/FormSchema';

export default {
  name: 'Select Control',
  description: 'Dropdown selection for both single and multiple values',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldSelect,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigSelect,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    items: [],
    endpointPayload: '',
    endpointRoute: '',
    isMultiple: false,
    isDisabled: false,
    isReadonly: false,
    isSmall: false,
    helpText: '',
    disabledOnValue: '',

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {string|string[]}
   */
  rendererDefaultData(control: FormControl & { isMultiple: boolean }): string[] | string {
    if (control.isMultiple) return [];
    return '';
  },
};
