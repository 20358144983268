











































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import TrackingBadge from './TrackingBadge.vue';
import UserAvatar from '@/app/shared/components/UserAvatar.vue';
import { TrackingItem } from '@/app/shared/models/Tracking';
import { User } from '@/app/shared/models/User';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

const TrackingStore = namespace('Tracking');
const UserStore = namespace('User');

@Component({
  components: {
    TrackingBadge,
    UserAvatar,
  },
})
export default class TrackingItemHeader extends Vue {
  @Prop() trackingItem: TrackingItem;
  displayFormat: string = 'D MMM YYYY HH:mm';
  maxUsers: number = 3;

  @Emit()
  collapse() {
    //
  }

  @Emit()
  unlock() {
    //
  }

  @TrackingStore.Action
  toggleExpand: (payload: { trackingItem: TrackingItem; isExpanded: boolean }) => void;

  @UserStore.Getter
  userUuid: string;

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  @AsyncComputed()
  async isUnlockAllowed() {
    return await this.isAllowed(UserPermission.TRACKING_BAR_UNLOCK_BUTTON);
  }

  get objectModule() {
    return this.trackingItem.object_module;
  }

  get isLocked() {
    return this.trackingItem.is_locked;
  }

  get isUpdated() {
    return this.trackingItem.is_updated;
  }

  get isExpanded() {
    return this.trackingItem.is_expanded;
  }

  set isExpanded(isExpanded: boolean) {
    this.toggleExpand({ trackingItem: this.trackingItem, isExpanded });
  }

  get trackingItemUsers() {
    return Object.values(
      this.trackingItem.activity.reduce(
        (trackingItemUsers, trackingActivity) => ({
          ...trackingItemUsers,
          [trackingActivity.user_uuid]: trackingActivity.user,
        }),
        {},
      ),
    ) as User[];
  }

  get plusMoreLabel() {
    if (this.trackingItemUsers.length <= this.maxUsers) return '';
    const moreUsers = this.trackingItemUsers.length - this.maxUsers;
    return ` +${moreUsers > 99 ? 99 : moreUsers}`;
  }

  get trackingItemUserNames() {
    return (
      this.trackingItemUsers
        .slice(0, this.maxUsers)
        .reduce((userNames, trackingItemUser) => [...userNames, this.getUserName(trackingItemUser)], [])
        .join(', ') + this.plusMoreLabel
    );
  }

  getUserName(user: User) {
    return user.uuid === this.userUuid ? 'You' : `${user.first_name} ${user.last_name}`;
  }
}
