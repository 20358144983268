import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import api from '@/app/shared/api';
import router from '@/router/router';
import { ModuleProperties } from '@/app/shared/models/Module';

@Module({ namespaced: true })
class ModulePropertiesModule extends VuexModule {
  module: ModuleProperties = null;
  getModulePromise: Promise<void> = null;

  get moduleProperties() {
    return this.module;
  }

  get studyUuid() {
    return this.module?.study_uuid;
  }

  get studyName() {
    return this.module?.study_name;
  }

  get workspaceUuid() {
    return this.module?.workspace_uuid;
  }

  get activeStudyVersion() {
    return this.module?.active_version;
  }

  @Mutation
  private setGetModulePromise(value: Promise<void>): void {
    this.getModulePromise = value;
  }

  @Mutation
  private setModuleProperties(module: ModuleProperties) {
    this.module = module;
  }

  @Action({ rawError: true })
  init(): void {
    router.afterEach(async (routeTo, routeFrom) => {
      if (routeTo.name !== routeFrom.name) this.context.commit('setModuleProperties', null);

      switch (routeTo.name) {
        case 'AdminCheckTree':
        case 'CalculationsMethod':
        case 'MatrixRelations':
        case 'Presentations':
        case 'PdfOutput':
        case 'TemplateCheckTree':
        case 'TemplatesPanel':
        case 'UserCheckTree':
        case 'StudyRegistration':
        case 'StudyRegistrationForm':
        case 'StudyReviews':
        case 'StudyReviewsForm':
        case 'Workspace':
          await this.context.dispatch('getModuleProperties');
          return;
        case 'Reports':
          if (routeTo.name !== routeFrom.name) await this.context.dispatch('getModuleProperties');
          break;
      }
    });
  }

  @Action({ rawError: true })
  async getModuleProperties(): Promise<void> {
    // If a module request is already running, simply wait for it to finish.
    if (this.getModulePromise) {
      await this.getModulePromise;
      return;
    }

    const getModulePromise = async () => {
      try {
        const module = await api.module.getModuleProperties(window.location.pathname);
        this.context.commit('setModuleProperties', module);
      } catch (error) {
        // no-op
      }
    };

    // Remember module request promise as a state.
    this.context.commit('setGetModulePromise', getModulePromise());

    // Wait until the module request promise resolves.
    await this.getModulePromise;

    // Clear the state with module request promise.
    this.context.commit('setGetModulePromise', null);
  }
}

export default ModulePropertiesModule;
