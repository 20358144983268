import { UserRole } from './../../enums/user-role.enum';
import api from '@/app/shared/api';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { User } from '../../models/User';
import { Permission } from '../../models/Permission';
import store from '..';

@Module({ namespaced: true })
class UserModule extends VuexModule {
  user: User = null;
  permission: Permission[] = null;
  accessToken: string = null;
  isLoginInteractive: boolean = false;
  checkSessionPromise: Promise<void> = null;
  doNotShowConfirmationDialog: boolean = false;

  get userModel() {
    return this.user;
  }

  get userFullName() {
    if (this.user) return `${this.user.first_name} ${this.user.last_name}`;
    return '';
  }

  get userAvatar() {
    return this.user && this.user.avatar;
  }

  get userOrganizationUuid() {
    return this.user && this.user.organization_uuid;
  }

  get isAuthenticated() {
    return this.accessToken !== null;
  }

  get isAdmin() {
    return this.user && this.user.role === UserRole.ADMIN;
  }

  get isUser() {
    return this.user && this.user.role === UserRole.USER;
  }

  get userId(): number {
    return this.user.id;
  }

  get userUuid(): string {
    return this.user.uuid;
  }

  get skipConfirmationDialog() {
    return this.doNotShowConfirmationDialog;
  }

  // // TODO: dev only
  // get userPermissions() {
  //   return (
  //     this.permission &&
  //     this.permission.reduce(
  //       (userPermissions, permissionParent) => ({
  //         ...userPermissions,
  //         ...permissionParent.children.reduce(
  //           (userPermissions, permission) => ({
  //             ...userPermissions,
  //             [`${permissionParent.name} (${permissionParent.uuid}) - ${permission.name}`]: permission.uuid,
  //           }),
  //           {},
  //         ),
  //       }),
  //       {} as Record<string, string>,
  //     )
  //   );
  // }

  get permissionLookup() {
    return (
      this.permission &&
      this.permission.reduce(
        (permissionLookup, permissionParent) => ({
          ...permissionLookup,
          ...permissionParent.children.reduce(
            (permissionLookup, permission) => ({
              ...permissionLookup,
              [permission.uuid]: permission.allowed,
            }),
            {},
          ),
        }),
        {} as Record<string, boolean>,
      )
    );
  }

  get arePermissionsLoaded() {
    return Boolean(this.permission);
  }

  @Mutation
  private setUser(value: User): void {
    this.user = value;
  }

  @Mutation
  private setPermission(value: Permission[]): void {
    this.permission = value;
  }

  @Mutation
  private setAccessToken(value: string): void {
    this.accessToken = value;
  }

  @Mutation
  private setCheckSessionPromise(value: Promise<void>): void {
    this.checkSessionPromise = value;
  }

  @Mutation
  private setSkipConfirmationDialog(value: boolean): void {
    this.doNotShowConfirmationDialog = value;
  }

  @Mutation
  private setIsLoginInteractive(value: boolean): void {
    this.isLoginInteractive = value;
  }

  @Action({ rawError: true })
  public async init() {
    // Watch for access token changes.
    store.watch(
      (state) => state['User'].accessToken,
      async (accessToken) => {
        if (accessToken) await store.dispatch('User/checkSession', accessToken);
      },
    );
  }

  @Action({ rawError: true })
  public async isAllowed(permission: string | string[]) {
    // Permissions are only valid for authenticated sessions and defined permission UUIDs.
    if (!this.isAuthenticated || !permission || !permission.length) return false;

    // Check if the permission matrix has been loaded, and trigger session check if not.
    if (!this.permission) {
      await this.context.dispatch('checkSession');
    }

    if (!Array.isArray(permission)) permission = [permission];

    return (
      // SEC: If a permission is undefined, explicitly default to `false` value.
      (this.permissionLookup && permission.some((permissionUuid) => this.permissionLookup[permissionUuid])) ?? false
    );
  }

  @Action({ rawError: true })
  public async loginUser({ username, password }: { username: string; password: string }) {
    const res = await api.auth.login(username, password);
    this.context.commit('setAccessToken', res.data.accessToken);
  }

  @Action({ rawError: true })
  public async getUserData() {
    if (this.accessToken === null) {
      this.context.commit('setPermission', null);
      this.context.commit('setUser', null);
      return;
    }

    try {
      const res = await api.auth.me();
      this.context.commit('setPermission', res.data.permission);
      this.context.commit('setUser', res.data.user);
    } catch (error) {
      console.warn('Could not fetch user data...', error);
    }
  }

  @Action({ rawError: true })
  public async checkSession(accessToken: string) {
    // If a session check is already running, simply wait for it to finish.
    if (this.checkSessionPromise) {
      await this.checkSessionPromise;
      return;
    }

    const checkSessionPromise = async () => {
      if (this.accessToken === null && accessToken === undefined) {
        this.context.commit('setPermission', null);
        this.context.commit('setUser', null);
        return;
      }

      try {
        const res = await api.auth.session(accessToken);
        this.context.commit('setPermission', res.data.permission);
        this.context.commit('setUser', res.data.user);
      } catch (error) {
        console.warn('Removing invalid access token...', error);
        this.context.commit('setAccessToken', null);
        this.context.commit('setPermission', null);
        this.context.commit('setUser', null);
      }
    };

    // Remember session check promise as a state.
    this.context.commit('setCheckSessionPromise', checkSessionPromise());

    // Wait until the session check promise resolves.
    await this.checkSessionPromise;

    // Clear the state with session check promise.
    this.context.commit('setCheckSessionPromise', null);
  }

  @Action({ rawError: true })
  public async logoutUser() {
    try {
      await api.auth.logout();
    } catch (error) {
      console.warn('Logout unsuccessful, user session might persist on server...', error);
    }

    this.context.commit('setAccessToken', null);
    this.context.commit('setPermission', null);
    this.context.commit('setUser', null);
  }
}

export default UserModule;
