





















































































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import { encode } from 'html-entities';
import FormFieldTextarea from '@/app/shared/components/form/field/FormFieldTextarea.vue';
import FormFieldLabel from '@/app/shared/components/form/field/mixins/FormFieldLabel.vue';
import FormFieldFocus from '@/app/shared/components/form/field/mixins/FormFieldFocus.vue';
import TinyEditorModal from '@/app/shared/components/TinyEditorModal.vue';
import { CommentValue } from '@/app/shared/models/form/field/FormFieldComment';

@Component({
  components: {
    FormFieldTextarea,
    TinyEditorModal,
  },
})
export default class FormFieldComment extends Mixins(Vue, FormFieldLabel, FormFieldFocus, BaseControlSkeleton) {
  value: CommentValue;
  control: {
    isRemovable: boolean;
  };
  isCommentAdded: boolean = false;
  isTextEditorVisible: boolean = false;
  updateValue: (value: CommentValue) => void;
  focus: () => void;

  @Watch('isTextEditorVisible')
  onIsTextEditorVisibleChange() {
    this.$store.dispatch('UI/setTinyEditorVisibility', this.isTextEditorVisible);
  }

  get hasText() {
    if (this.value === undefined || this.value === null) return false;
    return this.value.text !== undefined && this.value.text !== null;
  }

  get hasComment() {
    return !this.control.isRemovable || this.hasText;
  }

  addComment() {
    this.commentUpdateValue('');
    this.$nextTick(() => {
      this.focus();
    });
  }

  deleteComment() {
    this.commentUpdateValue(null);
  }

  showTextEditor() {
    if (this.value.isRichText) this.commentUpdateValue(this.value.text, true);
    else this.commentUpdateValue(encode(this.value.text), true);

    this.isTextEditorVisible = true;
  }

  hideTextEditor() {
    this.isTextEditorVisible = false;
  }

  commentUpdateValue(text: string, isRichText: boolean = false) {
    this.updateValue(new CommentValue(text, isRichText));
  }
}
