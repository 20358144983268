















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CommentingBadge extends Vue {
  @Prop() type: 'new' | 'unseen';
}
