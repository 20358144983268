var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-presentations-impacts-row",style:({
    background: _vm.impact.checked
      ? '#f3feff'
      : _vm.assessStatus === 'in-progress'
      ? 'rgba(127, 95, 197, 0.08)'
      : _vm.assessStatus === 'done'
      ? 'rgba(0, 180, 180, 0.08)'
      : 'white',
  })},[_c('div',{staticClass:"color-indicator",style:({ background: 'paleturquoise' })}),_c('div',{staticClass:"title-wrapper"},[(_vm.isLink)?_c('div',{staticClass:"link-icon"},[_c('v-icon',{staticClass:"enter-icon"},[_vm._v("$enter")])],1):_vm._e(),_c('div',{staticClass:"value",class:{ 'is-link': _vm.isLink }},[_vm._v(_vm._s(_vm.impact.name))])]),(_vm.assessStatus !== 'default')?_c('v-icon',{staticClass:"progress-icon"},[_vm._v("$"+_vm._s(_vm.assessStatus))]):_vm._e(),_c('BaseCheckbox',{staticClass:"impact-list-checkbox",style:(_vm.style.baseCheckbox),attrs:{"value":_vm.impact.library_id},on:{"change":_vm.onCheckboxChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }