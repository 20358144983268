import { render, staticRenderFns } from "./PresentationsSidebarSelectionItem.vue?vue&type=template&id=73c45baf&scoped=true&"
import script from "./PresentationsSidebarSelectionItem.vue?vue&type=script&lang=ts&"
export * from "./PresentationsSidebarSelectionItem.vue?vue&type=script&lang=ts&"
import style0 from "./PresentationsSidebarSelectionItem.vue?vue&type=style&index=0&id=73c45baf&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73c45baf",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
