































































































import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator';
import Sidebar from '@/app/shared/components/Sidebar.vue';

@Component({
  components: {
    Sidebar,
  },
})
export default class DashboardSidebar extends Vue {
  @ModelSync('value', 'change')
  readonly activeFilters!: Record<string, string[]>;
  @Prop() filterItems: any[];
  public resizeToggle = false;
  expandedFilters: number[] = [];

  get areAllFiltersExpanded() {
    return this.expandedFilters.length === this.filterItems.length;
  }

  public emitResizeEvent() {
    this.resizeToggle = !this.resizeToggle;
  }

  hasItemValue(item: { value: string }) {
    return item.value?.length;
  }

  isFilterItemComplete(filterItem: { field: string; items: any[] }) {
    if (!this.activeFilters[filterItem.field].length) return false;
    return this.activeFilters[filterItem.field].length === filterItem.items.length;
  }

  isFilterItemPartial(filterItem: { field: string; items: any[] }) {
    if (!this.activeFilters[filterItem.field].length) return false;
    return this.activeFilters[filterItem.field].length < filterItem.items.length;
  }

  onFilterItemChange(filterItem: { field: string; items: any[] }, isChecked: boolean) {
    if (isChecked) this.activeFilters[filterItem.field] = filterItem.items.map((item: any) => item.value);
    else this.activeFilters[filterItem.field] = [];
  }

  toggleAllExpandedFilters() {
    if (this.areAllFiltersExpanded) this.expandedFilters = [];
    else this.expandedFilters = this.filterItems.map((item, index) => index);
  }

  expandFilterItem(filterIndex: number, toggleSidebar: () => void) {
    if (!this.expandedFilters.includes(filterIndex)) this.expandedFilters.push(filterIndex);
    toggleSidebar();

    this.$nextTick(() => {
      const filterItemContainer = this.$refs.filterItemContainer;
      const filterItems = this.$refs.filterItem;
      if (filterItemContainer && Array.isArray(filterItems) && filterItems[filterIndex]) {
        (filterItemContainer as Vue).$el.parentElement.scrollTop = ((filterItems[filterIndex] as Vue)
          .$el as HTMLDivElement).offsetTop;
      }
    });
  }

  blurActiveElement(event: MouseEvent) {
    // Do not blur if the element was activated via keyboard.
    if (event.detail < 1) return;
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement && typeof activeElement.blur === 'function') activeElement.blur();
  }
}
