

































import Unit from '@/app/shared/models/calculations/Unit';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CalculationsDataTableField from './DataTableField.vue';

export enum CalculationsDataTableType {
  TITLE = 'title',
  ACTION = 'action',
  SIGNIFICANCE = 'significance',
  SELECT = 'select',
}

export interface CalculationsDataTableColumn {
  text: string;
  resizable?: boolean;
  value: string;
  type: CalculationsDataTableType;
  width?: string;
  actionType?: CalculationsColumnActionType;
  actionIsDisabled?: (item: any) => boolean;
}

export enum CalculationsColumnActionType {
  ASSESS = 'assess',
  DEFINE = 'define',
  FORMULATE = 'formulate',
}

@Component({
  components: { CalculationsDataTableField },
})
export default class CalculationsDataTable extends Vue {
  @Prop() columns: CalculationsDataTableColumn[];
  @Prop() data: Unit[];
  @Prop() processorType: 'assess' | 'altassess' | 'define' | 'mmdefine' | 'madefine' | 'formulate';
  @Prop() unitListId: number;
  @Prop() tableType: 'formulate' | 'assess' | 'define';

  tableRef: HTMLElement;

  mounted() {
    this.tableRef = document.querySelector('#calculations-data-table table');
    this.addResize();
  }

  @Watch('columns')
  onColumnsPropChange(newVal: CalculationsDataTableColumn[], oldVal: CalculationsDataTableColumn[]) {
    if (newVal.length !== oldVal.length) {
      setTimeout(() => {
        this.addResize();
      }, 0);
    }
  }

  headerSlotName(column: CalculationsDataTableColumn) {
    return 'header.' + column.value;
  }

  itemSlotName(column: CalculationsDataTableColumn) {
    return 'item.' + column.value;
  }

  get tableHeaders() {
    return this.columns.map((el) => {
      return {
        text: el.text,
        value: el.value,
        class: el.resizable ? `col-resizable col-${el.value}` : `col-${el.value}`,
        width: el.width,
      };
    });
  }

  addResize() {
    const createDiv = (height: number) => {
      const div = document.createElement('div');
      div.style.top = '0';
      div.style.right = '0';
      div.style.width = '16px';
      div.style.position = 'absolute';
      div.style.cursor = 'col-resize';
      div.style.userSelect = 'none';
      div.style.height = height + 'px';
      return div;
    };
    const getStyleVal = (elm: any, css: any) => {
      return window.getComputedStyle(elm, null).getPropertyValue(css);
    };

    const paddingDiff = (col: HTMLElement) => {
      if (getStyleVal(col, 'box-sizing') == 'border-box') {
        return 0;
      }

      const padLeft = getStyleVal(col, 'padding-left');
      const padRight = getStyleVal(col, 'padding-right');
      return parseInt(padLeft) + parseInt(padRight);
    };

    const setListeners = (div: HTMLElement) => {
      let pageX: number, curCol: HTMLElement, nxtCol: HTMLElement, curColWidth: number, nxtColWidth: number;

      div.addEventListener('mousedown', function(e: any) {
        curCol = e.target.parentElement;
        nxtCol = curCol.nextElementSibling as HTMLElement;
        pageX = e.pageX;

        const padding = paddingDiff(curCol);

        curColWidth = curCol.offsetWidth - padding;
        // if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding;
      });

      // div.addEventListener('mouseover', function(e: any) {
      //   e.target.style.borderRight = '2px solid #0000ff';
      // });

      // div.addEventListener('mouseout', function(e: any) {
      //   e.target.style.borderRight = '';
      // });

      document.addEventListener('mousemove', function(e) {
        if (curCol) {
          const diffX = e.pageX - pageX;

          if (nxtCol) {
            nxtCol.style.width = nxtColWidth - diffX + 'px';
            (nxtCol.style as any)['min-width'] = nxtColWidth - diffX + 'px';
          }

          curCol.style.width = curColWidth + diffX + 'px';
          (curCol.style as any)['min-width'] = curColWidth + diffX + 'px';
        }
      });

      document.addEventListener('mouseup', function(_event: any) {
        curCol = undefined;
        nxtCol = undefined;
        pageX = undefined;
        nxtColWidth = undefined;
        curColWidth = undefined;
      });
    };

    const row = this.tableRef.getElementsByTagName('tr')[0];
    const cols = row ? row.children : null;
    if (!cols) return;

    // this.tableRef.style.overflow = 'hidden';
    // const tableHeight = this.tableRef.offsetHeight;

    for (const col of cols) {
      const isColResizable = col.classList.value.indexOf('col-resizable') > -1;
      if (isColResizable) {
        const div = createDiv(32);
        col.appendChild(div);
        (col as HTMLElement).style.position = 'sticky';
        setListeners(div);
      }
    }
  }
}
