







import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ChapterItemCommentIndicator extends Vue {
  @Prop() uuid: string;

  @Emit()
  click() {
    //
  }
}
