export class UploadResult {
  uploadId: string;
  uploadName: string;
  uploadPreview?: string;
  uploadError?: string;

  constructor(
    uploadId: string = null,
    uploadName: string = '',
    uploadPreview: string = null,
    uploadError: string = '',
  ) {
    this.uploadId = uploadId;
    this.uploadName = uploadName;
    this.uploadPreview = uploadPreview;
    this.uploadError = uploadError;
  }
}
