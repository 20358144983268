












































import { Component, Mixins, Vue } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import { GLOBAL_CONFIG } from 'v-form-builder/src/configs/global';
import { EVENT_CONSTANTS } from 'v-form-builder/src/configs/events';

@Component
export default class FormFieldButton extends Mixins(Vue, BaseControlSkeleton) {
  control: {
    buttonType: string;
    to: string;
    label: string;
    uniqueId: string;
    isRunValidation: boolean;
    eventName: string;
    eventPayload: {};
    isDisabled: boolean;
    disabledOnValue: string;
  };
  isValidationRunning: boolean = false;

  get buttonText() {
    if (this.isValidationRunning) return 'Validating...';
    return this.control.label;
  }

  get isDisabled() {
    if (this.control.isDisabled) return true;
    if (this.control.disabledOnValue) {
      const valueContainer = JSON.parse(JSON.stringify(this.$attrs['value-container'])); // NB: why?!?
      return (
        valueContainer &&
        valueContainer.hasOwnProperty(this.control.disabledOnValue) &&
        Boolean(valueContainer[this.control.disabledOnValue as any])
      );
    }
    return false;
  }

  created() {
    this.$formEvent.$on(EVENT_CONSTANTS.RENDERER.VALIDATION_OK, this.onValidationOk);
    this.$formEvent.$on(EVENT_CONSTANTS.RENDERER.VALIDATION_FAILED, this.onValidationFailed);
    this.$formEvent.$on(EVENT_CONSTANTS.RENDERER.RUN_VALIDATION, this.onRunValidation);
  }

  mounted() {
    this.$form.fields = this.$form.fields || {};
    this.$form.fields[this.control.uniqueId] = this;
  }

  beforeDestroy() {
    this.$formEvent.$off(EVENT_CONSTANTS.RENDERER.VALIDATION_OK, this.onValidationOk);
    this.$formEvent.$off(EVENT_CONSTANTS.RENDERER.VALIDATION_FAILED, this.onValidationFailed);
    this.$formEvent.$off(EVENT_CONSTANTS.RENDERER.RUN_VALIDATION, this.onRunValidation);
  }

  focus() {
    if (this.$refs.button) (this.$refs.button as Vue & { $el: HTMLElement & { focus: () => void } }).$el.focus();
  }

  startValidation() {
    this.isValidationRunning = true;
  }

  stopValidation() {
    this.isValidationRunning = false;
  }

  onClick() {
    switch (this.control.buttonType) {
      case 'button':
        if (this.control.eventName) {
          this.$formEvent.$emit('form-field-button', {
            id: this.control.uniqueId,
            name: this.control.eventName,
            payload: this.control.eventPayload,
          });
        }
        return;

      case 'link':
        this.$router.push(this.control.to).catch(() => {
          //
        });
        return;

      case 'submit':
        if (this.control.isRunValidation) {
          this.startValidation();
          this.$formEvent.$emit(EVENT_CONSTANTS.RENDERER.RUN_VALIDATION, true);
        } else {
          this.submit();
        }
        return;
    }
  }

  onValidationOk() {
    this.stopValidation();
    this.submit();
  }

  onValidationFailed() {
    this.stopValidation();
  }

  onRunValidation() {
    // Dummy handler
  }

  submit() {
    const formDOM = document.getElementById(GLOBAL_CONFIG.rendererFormId) as HTMLFormElement;
    if (!formDOM || this.control.buttonType !== 'submit') return;

    formDOM.submit();
  }
}
