import FormConfigFacility from '@/app/shared/components/form/config/FormConfigFacility.vue';
import FormFieldFacility from '@/app/shared/components/form/field/FormFieldFacility.vue';
import { Facility } from '@/app/shared/models/form/field/FormFieldFacility';
import { FormFieldValue, FormFieldValueModel } from '@/app/shared/models/form/field';

export default {
  name: 'Facility Control',
  description: 'Complex input for facility info',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldFacility,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigFacility,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    isDisabled: false,
    isReadonly: false,

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {FormFieldValue<Facility>}
   */
  rendererDefaultData(): FormFieldValue<Facility> {
    return new FormFieldValue([new Facility()], FormFieldValueModel.FACILITY);
  },
};
