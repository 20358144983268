import { WorkspaceType } from '@/app/shared/enums/workspace-type.enum';
import { WorkspaceResponseItem } from '@/app/shared/models/workspace/WorkspaceResponseItem';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class WorkspaceModule extends VuexModule {
  // TODO Fake DEV data
  workspaceItems: Array<WorkspaceResponseItem>;

  get getWorkspaceItems(): Array<WorkspaceResponseItem> {
    return this.workspaceItems;
  }

  @Mutation
  public setWorkspaceItems(data: Array<WorkspaceResponseItem>): void {
    this.workspaceItems = data;
  }
  @Action({ rawError: true })
  getWorkSpaceItem(slug: string): WorkspaceResponseItem {
    const item = this.workspaceItems.find((i) => i.slug == slug);

    // TODO DEV ONLY
    if (!item) {
      return {
        enabled: true,
      };
    }

    return item;
  }
  @Action({ rawError: true })
  getItemAttributes(data: {
    type: WorkspaceType;
    enabled: boolean;
  }): { color: string; icon: string; enabledTextColor: string } {
    // default if no icon found
    let icon = 'checktree';
    let color = '1493be';
    const enabledTextColor = 'var(--v-white)';
    switch (data.type) {
      case WorkspaceType.CHECKTREE:
        icon = 'checktree';
        color = '1493be';
        break;
      case WorkspaceType.QUESTIONNAIRE:
        icon = 'questionnaire';
        color = '05808a';
        break;
      case WorkspaceType.MATRIX_CROSSCONNECT:
        icon = 'matrix';
        color = '1e7495';
        break;
      case WorkspaceType.PRESENTATION:
        icon = 'presentation';
        color = '1d6871';
        break;
      case WorkspaceType.TEMPLATES:
        icon = 'templates';
        color = '185264';
        break;
      case WorkspaceType.SUMMARY_REPORT:
        icon = 'summary-report-workspace';
        color = '185264';
        break;
      case WorkspaceType.FULL_REPORT:
        icon = 'reports-icon';
        color = '185264';
        break;
      case WorkspaceType.PDF_OUTPUT:
        icon = 'pdf-output';
        color = '185264';
        break;
      case WorkspaceType.IMPACT_INTERACTION:
        icon = 'impact-interaction';
        color = '46396a';
        break;
      case WorkspaceType.MATRIX_TAGGING:
        icon = 'matrix-type-2';
        color = '1e7495';
        break;
      case WorkspaceType.PROCESSING:
        icon = 'processing';
        color = '3f5492';
        break;
      case WorkspaceType.PROCESSING_CHECKTREE:
        icon = 'processing-checktree';
        color = '3f5492';
        break;
    }
    if (!data.enabled) {
      icon = `${icon}-disabled`;
    }
    return {
      icon,
      color,
      enabledTextColor,
    };
  }
}
