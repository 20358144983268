import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import store from '../store';
import LoaderService from '../utils/loader.service';

const httpClient = axios.create({
  baseURL: import.meta.env.API_URL,
  timeout: 300000,
});

// request interceptor
httpClient.interceptors.request.use((config: AxiosRequestConfig) => {
  if ((store.state && store.state.User && store.state.User.accessToken) || (config.data && config.data.accessToken)) {
    if (!config.headers) config.headers = new Headers();
    config.headers.Authorization = `Bearer ${store.state.User.accessToken || config.data.accessToken}`;
  }

  const isLoaderDisabled = store.getters['Loader/isHttpLoaderDisabled'];
  if (!isLoaderDisabled) {
    LoaderService.showLoader();
  }
  return config;
});

// response interceptor
httpClient.interceptors.response.use(
  (response: AxiosResponse) => {
    const isLoaderDisabled = store.getters['Loader/isHttpLoaderDisabled'];
    if (!isLoaderDisabled) {
      LoaderService.hideLoader();
    }
    return response.data || response;
  },
  (error: AxiosError) => {
    LoaderService.hideLoader();

    if (error.response && error.response.status === 401) {
      store.dispatch('User/checkSession');
    }

    // handle error
    return Promise.reject(error);
  },
);

export default httpClient;
