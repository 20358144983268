import Vue from 'vue';

export enum EventType {
  UNAUTHORIZED = 'unauthorized',
  MATRIX_RECIEVE_MSG = 'matrix_recieve_message',
  CHECKTREE_RECEIVE_MSG = 'checktree_receive_message',
  REPORTS_RECEIVE_MSG = 'reports_receive_message',
  RERENDER_REPORT_SIDEBAR = 'rerender_report_sidebar',
  REORDER_PDF = 'reorder_pdf',
  EXCLUDE_ALL_PDF = 'exclude_all_pdf',
  ASSESSMENT_SELECTION_PASTED = 'assessment_selection_pasted',
  PRESENTATIONS_SIDEBAR_OPTIONS_EVENT = 'presentations_sidebar_options_event',
  LIBRARY_UPDATE = 'presentations_library_update',
  COMMENT_DELETE = 'comment_delete',
  COMMENT_DRAFT_ADD = 'comment_draft_add',
  COMMENT_DRAFT_CANCEL = 'comment_draft_cancel',
}

export const eventBus = new Vue();
