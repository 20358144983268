

































































import { Component, Watch, Prop } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import { AxiosError } from 'axios';
import FormRenderer from '@/app/shared/components/form/FormRenderer.vue';
import ExpandableSidebarRoute from '@/app/shared/components/mixins/ExpandableSidebarRoute.vue';
import { FormList } from '@/app/shared/models/form/FormSchema';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

const UserStore = namespace('User');

@Component({
  components: {
    FormRenderer,
  },
})
export default class ExpandableSidebarForm extends ExpandableSidebarRoute {
  @Prop() formId: string;
  isLoaded: boolean = false;
  isExpanded: boolean = false;
  formList: FormList[] = [];
  target: string = '';
  serverError: AxiosError = null;
  isFormAccessAllowed: boolean = false;

  @UserStore.Action
  public isAllowed: (permission: string | string[]) => Promise<boolean>;

  // @Watch('formId')
  // onFormIdChange(formId: string) {
  //   console.debug('onFormIdChange', formId);
  //   this.setExpandableSidebarOptions(formId);
  // }

  @Watch('form')
  async onFormChange(form: FormList) {
    await this.checkFormPermission();
    await this.$nextTick(() => {
      //
    });
    this.setExpandableSidebarOptions(form.id);
  }

  @AsyncComputed()
  async userHome() {
    // Redirect user to the correct landing screen depending on their permissions.
    if (await this.isAllowed(UserPermission.ADMIN_LANDING_ACCESS)) return '/';
    else if (await this.isAllowed(UserPermission.LANDING_ACCESS)) return '/landing';
    return '/error/404';
  }

  get form() {
    return this.findForm(this.formId);
  }

  get formDefaultValues() {
    return {};
  }

  get formContainerClass(): any {
    return 'width-552';
  }

  get formWidth() {
    return 552;
  }

  get formSubmitButtonLabel() {
    return 'Save';
  }

  get formHintText(): string {
    return null;
  }

  get isFormShown() {
    if (!this.form || !this.isFormAccessAllowed) return false;
    return true;
  }

  get isFormHintHidden() {
    return false;
  }

  get isFormSingle() {
    return false;
  }

  get hasFormSubmitButton() {
    return false;
  }

  get backRoute() {
    return '';
  }

  get previousRoute() {
    const formIndex = this.formList.findIndex((form) => this.formId === form.id);
    if (formIndex === 0) return null;
    return this.getFormRoute(this.formList[formIndex - 1].id);
  }

  get nextRoute() {
    const formIndex = this.formList.findIndex((form) => this.formId === form.id);
    if (this.formList.length - 1 > formIndex) return this.getFormRoute(this.formList[formIndex + 1].id);
    return null;
  }

  async mounted() {
    await this.checkFormPermission();
    this.$nextTick(() => {
      this.initExpandableSidebar();
      this.isLoaded = true;
    });
  }

  async checkFormPermission() {
    if (this.form && this.form.permission && !(await this.isAllowed(this.form.permission))) {
      this.$router.replace('/error/403').catch(() => {
        // Dummy error handler for avoiding duplicated navigation logs.
      });

      this.isFormAccessAllowed = false;

      return false;
    }

    this.isFormAccessAllowed = true;

    return true;
  }

  findForm(formId: string) {
    return this.formList.find((form) => form.id === formId);
  }

  initExpandableSidebar() {
    this.setExpandableSidebarOptions(this.formId);
  }

  setExpandableSidebarOptions(formId: string) {
    if (this.isFormSingle) {
      this.expandableSidebarOptions = {
        backgroundType: this.form.backgroundType || this.form.id,
        headingText: this.form.label,
      };
    } else {
      const form = this.findForm(formId);

      this.expandableSidebarOptions = {
        backgroundType: form.backgroundType || formId,
        metaTitle: `${form.label} - Envigo`,
        headingText: form.label,
      };
    }

    this.setOptions(this.expandableSidebarOptions);
  }

  getFormRoute(_formId: string) {
    return '';
  }

  validateForm(target: string) {
    this.target = target;
    if (this.$refs.form) (this.$refs.form as Vue & { runValidation: () => void }).runValidation();
  }

  async goBack() {
    // If there is a page in browser history, go back to it.
    //   Otherwise, redirect user to their home screen.
    if (window.history.length > 1 && !window.Cypress) this.$router.go(-1);
    else this.$router.push(this.userHome as never);
  }

  onFormFieldButton(_event: any) {
    // no-op
  }

  async onSubmit(payload: any) {
    this.serverError = null;

    try {
      console.debug('onSubmit', payload);
      await this.redirectOnSuccess();
    } catch (error) {
      this.serverError = error as AxiosError;
    }
  }

  async redirectOnSuccess() {
    switch (this.target) {
      case 'previous':
        this.$router.push(this.previousRoute);
        return;
      case 'back':
      case 'submit':
        if (this.backRoute) this.$router.push(this.backRoute);
        else await this.goBack();
        return;
      default:
      case 'next':
        this.$router.push(this.nextRoute);
    }
  }
}
