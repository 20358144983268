









































import { Component, Vue } from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';
import Loader from '@/app/shared/components/Loader.vue';
import { namespace } from 'vuex-class';
import { DialogOptions } from '../store/modules/dialog.store-module';

const DialogStore = namespace('Dialog');

@Component({
  components: { editor: Editor, Loader },
})
export default class ConfirmDialog extends Vue {
  @DialogStore.Getter
  public isDialogVisible!: boolean;
  checkboxValue: boolean = false;

  get visible() {
    return this.isDialogVisible;
  }

  set visible(value: boolean) {
    this.toggleDialog(value);
  }

  @DialogStore.Getter
  public dialogOptions!: DialogOptions;

  @DialogStore.Action
  public toggleDialog!: (isVisible: boolean) => void;

  onCancel() {
    this.dialogOptions.cancelCallback.call(this);
    this.toggleDialog(false);
    this.checkboxValue = false;
  }

  onConfirm() {
    this.dialogOptions.confirmCallback.call(this, { checkboxValue: this.checkboxValue });
    this.toggleDialog(false);
    this.checkboxValue = false;
  }
}
