














import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardDataTableStatus } from '@/app/shared/models/DashboardDataTable';

@Component
export default class DashboardDataTableStatusField extends Vue {
  @Prop() status: string;
  @Prop() config: DashboardDataTableStatus[];

  get statusConfig() {
    const defaultConfig = {
      icon: 'error',
      highlightColor: 'var(--v-gray-extra-light)',
      solidColor: 'var(--v-gray-medium)',
      label: 'Unknown',
    };

    if (!this.config) return defaultConfig;

    const config = this.config.find((status) => status.type === this.status);
    if (!config) return defaultConfig;

    return config;
  }
}
