






















































import { Component, Mixins, Watch, Vue } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import FormFieldDate from '@/app/shared/components/form/field/FormFieldDate.vue';
import FormFieldText from '@/app/shared/components/form/field/FormFieldText.vue';
import FormFieldFocus from '@/app/shared/components/form/field/mixins/FormFieldFocus.vue';
import { ValidationRule } from '@/app/shared/models/form/FormSchema';
import { Event } from '@/app/shared/models/form/field/FormFieldEvent';
import { FormFieldValue, FormFieldValueModel } from '@/app/shared/models/form/field';

@Component({
  components: {
    FormFieldDate,
    FormFieldText,
  },
})
export default class FormFieldEvent extends Mixins(Vue, FormFieldFocus, BaseControlSkeleton) {
  value: FormFieldValue<Event>;
  deleted: string[] = [];
  events: Event[] = [new Event()];
  control: {
    validations: ValidationRule[];
  };
  updateValue: (value: FormFieldValue<Event>) => void;

  @Watch('value', { deep: true })
  onValueChanged(value: FormFieldValue<Event>) {
    this.$set(this, 'events', value.data);
  }

  @Watch('events', { deep: true })
  onEventsChange(events: Event[]) {
    const deleted: string[] = [];

    events.forEach((event) => {
      if (event.uuid) {
        if (event.name === '' && !event.date) {
          if (!deleted.includes(event.uuid)) deleted.push(event.uuid);
        } else {
          if (deleted.includes(event.uuid))
            deleted.splice(
              deleted.findIndex((uuid) => uuid === event.uuid),
              1,
            );
        }
      }
    });

    this.updateValue(new FormFieldValue(events, FormFieldValueModel.EVENT, deleted));
  }

  get isMaxFiveWordsObjectName() {
    if (this.control.validations)
      return (
        this.control.validations.filter(
          (validation) =>
            validation.ruleType === 'customClosure' && validation.additionalValue === 'maxFiveWordsObjectName',
        ).length > 0
      );

    return false;
  }

  created() {
    if (this.value) this.$set(this, 'events', this.value.data);
  }

  addGroup(index: number) {
    this.events.splice(index + 1, 0, new Event());
  }

  focus() {
    const formField = this.$refs.formField as (Vue & { focus: () => void })[];
    if (formField[0]) formField[0].focus();
  }
}
