const requiredObjectProjectName = (fieldValue: any): boolean => {
  if (Array.isArray(fieldValue.data)) {
    return fieldValue.data.every((item: any) => requiredObjectProjectName(item));
  } else if (Array.isArray(fieldValue)) {
    return fieldValue.every((item: any) => requiredObjectProjectName(item));
  } else if (typeof fieldValue === 'object' && !Array.isArray(fieldValue) && fieldValue !== null) {
    if (
      fieldValue.project !== undefined &&
      fieldValue.project !== null &&
      fieldValue.project.length &&
      fieldValue.name !== undefined &&
      fieldValue.name !== null &&
      fieldValue.name.length
    )
      return true;
    return false;
  }

  return false;
};

export default requiredObjectProjectName;
