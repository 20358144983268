







































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { WorkspaceType } from '@/app/shared/enums/workspace-type.enum';
import { WorkspaceResponseItem } from '@/app/shared/models/workspace/WorkspaceResponseItem';
import AsyncComputed from 'vue-async-computed-decorator';

const UserStore = namespace('User');

@Component
export default class WorkspaceItem extends Vue {
  @Prop() item: WorkspaceResponseItem;
  @Prop() title: string;
  @Prop() type: WorkspaceType;

  @UserStore.Action
  public isAllowed: (permission: string | string[]) => Promise<boolean>;

  @AsyncComputed()
  async isForbidden() {
    if (!this.item || !this.item.url) return false;
    const { route } = this.$router.resolve(this.item.url);
    if (route && route.meta && route.meta.permission) return !(await this.isAllowed(route.meta.permission));
    else return false;
  }

  get itemTitle() {
    return this.item?.name ?? this.title;
  }

  get attributes() {
    let icon = 'checktree';
    let color = '1493be';
    const enabledTextColor = 'var(--v-white)';

    switch (this.type) {
      case WorkspaceType.CHECKTREE:
        icon = 'checktree';
        color = '14a3be';
        break;
      case WorkspaceType.QUESTIONNAIRE:
        icon = 'questionnaire';
        color = '05808a';
        break;
      case WorkspaceType.MATRIX_CROSSCONNECT:
        icon = 'matrix';
        color = '1e7495';
        break;
      case WorkspaceType.PRESENTATION:
        icon = 'presentation';
        color = '1d6871';
        break;
      case WorkspaceType.TEMPLATES:
        icon = 'templates';
        color = '185264';
        break;
      case WorkspaceType.SUMMARY_REPORT:
        icon = 'summary-report-workspace';
        color = '185264';
        break;
      case WorkspaceType.FULL_REPORT:
        icon = 'reports-icon';
        color = '185264';
        break;
      case WorkspaceType.PDF_OUTPUT:
        icon = 'pdf-output';
        color = '185264';
        break;
      case WorkspaceType.IMPACT_INTERACTION:
        icon = 'impact-interaction';
        color = '46396a';
        break;
      case WorkspaceType.MATRIX_TAGGING:
        icon = 'matrix-type-2';
        color = '1e7495';
        break;
      case WorkspaceType.PROCESSING:
        icon = 'processing';
        color = '3f5492';
        break;
      case WorkspaceType.PROCESSING_CHECKTREE:
        icon = 'processing-checktree';
        color = '3f5492';
        break;
    }

    if (!this.isEnabled) icon = `${icon}-disabled`;

    return {
      icon,
      color,
      enabledTextColor,
    };
  }

  get isEnabled() {
    return this.item?.state?.some((state) => state.active);
  }

  get isUpdated() {
    return this.item?.state?.some((state) => state.updated);
  }

  itemClick() {
    if (this.isForbidden) return;
    if (this.item && this.item.url) this.$router.push(this.item.url);
  }
}
