































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { PresentationsTabType } from '@/app/shared/types/presentations.types';
import { PresentationsTableType } from '@/app/shared/types/presentations.types';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import PresentationsScreeningTable from './PresentationsScreeningTable.vue';
import PresentationsQuestionnaireTable from './PresentationsQuestionnaireTable.vue';
import PresentationsScopingTable from './PresentationsScopingTable.vue';
import PresentationsAssessingTable from './PresentationsAssessingTable.vue';
import { CheckTree } from '@/app/shared/models/CheckTree';
import { PresentationsSidebarOption } from '@/app/shared/models/presentations/PresentationsSidebarOption';

const PresentationsStore = namespace('Presentations');

@Component({
  components: {
    PresentationsScreeningTable,
    PresentationsQuestionnaireTable,
    PresentationsScopingTable,
    PresentationsAssessingTable,
  },
})
export default class PresentationsTables extends Vue {
  tableTypes: Array<{ title: string; type: PresentationsTableType; icon: string }> = [];
  disabledTableTypes: PresentationsTableType[] = ['screening', 'scoping', 'question', 'assessing'];

  @PresentationsStore.State
  activeTableType: PresentationsTableType;

  @PresentationsStore.State
  sidebarChecktrees: CheckTree[];

  @PresentationsStore.State
  activeTab: PresentationsTabType;

  @PresentationsStore.State
  typesOfMeans: any;

  @Watch('sidebarChecktrees', { deep: true })
  onSidebarChecktreesChange() {
    this.updateDisabledTableTypesBasedOnSelection();
  }

  mounted() {
    this.initializeTableTypes();
    eventBus.$on(EventType.PRESENTATIONS_SIDEBAR_OPTIONS_EVENT, this.onPresentationsSidebarOptionEvent);
    this.updateDisabledTableTypesBasedOnSelection();
  }

  updateDisabledTableTypesBasedOnSelection(): void {
    if (!this.sidebarChecktrees) {
      this.disabledTableTypes = ['screening', 'scoping', 'question', 'assessing'];
      return;
    }

    const checktreesCount = this.sidebarChecktrees.length;
    let selectedChecktreesCount = 0;
    for (const checktree of this.sidebarChecktrees) {
      if (checktree.children.some((el) => el.checked)) {
        selectedChecktreesCount++;
      }
    }

    if (checktreesCount === selectedChecktreesCount) {
      if (this.activeTableType === 'screening') {
        this.changeTableType(null);
      }
      this.disabledTableTypes = ['screening'];
    } else if (selectedChecktreesCount === 1) {
      if (this.activeTableType !== 'screening') {
        this.changeTableType(null);
      }
      this.disabledTableTypes = ['scoping', 'question', 'assessing'];
    } else {
      this.disabledTableTypes = ['screening', 'scoping', 'question', 'assessing'];
    }
  }

  @PresentationsStore.Action
  changeTableType: (value: PresentationsTableType) => Promise<any>;

  onPresentationsSidebarOptionEvent(_option: PresentationsSidebarOption) {
    //
  }

  initializeTableTypes() {
    if (!this.typesOfMeans || !this.typesOfMeans[this.activeTab]) return;
    this.typesOfMeans[this.activeTab].forEach((item: any, index: any) => {
      this.tableTypes.push({ title: item, type: item, icon: item });

      // @todo This should be done through the API, but from now on...
      if (this.tableTypes[index].icon == 'screening') this.tableTypes[index].icon = 'checktree';
      if (this.tableTypes[index].icon == 'scoping') this.tableTypes[index].icon = 'matrix';
      if (this.tableTypes[index].icon == 'assessing') this.tableTypes[index].icon = 'processing';
      // if (this.tableTypes[index].icon == 'question') this.tableTypes[index].icon = 'questionnaire';
    });
  }

  destroyed() {
    eventBus.$off(EventType.PRESENTATIONS_SIDEBAR_OPTIONS_EVENT, this.onPresentationsSidebarOptionEvent);
  }
}
