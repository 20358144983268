import userProfileFormSchema from '@/app/shared/components/form/schema/user-profile.json';
import editUserProfileFormSchema from '@/app/shared/components/form/schema/edit-user-profile.json';
import changeMainOrganizationFormSchema from '@/app/shared/components/form/schema/change-main-organization.json';
import { FormList } from '@/app/shared/models/form/FormSchema';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

export default [
  {
    id: 'user-profile',
    label: 'User Profile',
    schema: userProfileFormSchema,
    permission: UserPermission.USER_PROFILE_ACCESS,
  },
  {
    id: 'edit-user-profile',
    label: 'Edit User Profile',
    backgroundType: 'user-profile',
    schema: editUserProfileFormSchema,
    permission: UserPermission.EDIT_USER_PROFILE_ACCESS,
  },
  {
    id: 'change-main-organization',
    label: 'Change Main Organization',
    backgroundType: 'user-profile',
    schema: changeMainOrganizationFormSchema,
    permission: UserPermission.CHANGE_MAIN_ORGANIZATION_ACCESS,
  },
] as FormList[];
