




































































import { Component, Watch, Mixins, Vue } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import FormFieldUpload from '@/app/shared/components/form/field/FormFieldUpload.vue';
import FormFieldLabel from '@/app/shared/components/form/field/mixins/FormFieldLabel.vue';
import FormFieldFocus from '@/app/shared/components/form/field/mixins/FormFieldFocus.vue';
import { UploadResult } from '@/app/shared/models/form/field/FormFieldUpload';

@Component({
  components: {
    FormFieldUpload,
  },
})
export default class FormFieldUploadInline extends Mixins(Vue, FormFieldLabel, FormFieldFocus, BaseControlSkeleton) {
  value: string;
  uploadResults: UploadResult[] = [];
  updateValue: (value: string) => void;

  @Watch('value', { deep: true })
  onValueChanged(value: string) {
    const [firstUploadResult] = this.uploadResults;
    if (value && (!firstUploadResult || value !== firstUploadResult.uploadPreview))
      this.uploadResults = [new UploadResult(null, 'inline', value)];
  }

  @Watch('uploadResults', { deep: true })
  onUploadResultsChanged(value: UploadResult[]) {
    if (value && value.length) {
      const [firstValue] = value;
      this.updateValue(firstValue && firstValue.uploadPreview);
      return;
    }

    this.updateValue('');
  }

  created() {
    if (this.value) {
      this.uploadResults = [new UploadResult(null, 'inline', this.value)];
    }
  }

  removeUploadResult(index: number) {
    this.uploadResults.splice(index, 1);
  }

  async removeUpload(uploadResult: UploadResult, index: number) {
    this.removeUploadResult(index);
  }

  showBrowseDialog() {
    if (this.$refs.formField) (this.$refs.formField as Vue & { showBrowseDialog: () => void }).showBrowseDialog();
  }
}
