









































































































import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import ChapterItemCommentHighlight from './mixins/ChapterItemCommentHighlight.vue';
import { ChapterItem } from '@/app/shared/models/reports/ChapterItem';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import { ChapterItemMode } from '@/app/shared/types/chapter-item-mode.type';
import _debounce from 'lodash.debounce';
import api from '@/app/shared/api';
import LoaderService from '@/app/shared/utils/loader.service';
import { UpdateChapterItemPayload, WS_DEBOUNCE_VALUE } from '@/app/shared/store/modules/reports.store-module';

@Component({
  components: {},
})
export default class ExcelTableChapterItem extends ChapterItemCommentHighlight {
  @Prop() item: ChapterItem;
  @Prop() mode: ChapterItemMode;
  @Prop() isDeleteButtonAllowed: boolean;

  ChapterItemType: ChapterItemType;

  activeTab: number = 1;

  fileAsHTML: string = null;
  tableUrl: string = '';

  @Watch('tableUrl')
  onTableUrlChange() {
    if (this.tableUrl && this.tableUrl.length > 0) {
      LoaderService.disableHttpLoader();
      api.reports
        .addTableFromUrl(this.tableUrl)
        .then((res: { data: { html: string } }) => {
          this.fileAsHTML = res.data.html;
          this.updateChapterItem({
            uuid: this.item.uuid,
            type: ChapterItemType.TABLE,
            propertyName: 'html',
            propertyValue: this.fileAsHTML,
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => LoaderService.enableHttpLoader());
    }
  }

  @Emit('switchMode')
  switchMode(_newMode: ChapterItemMode) {
    // just emiting event
  }

  @Emit('deleteChapterItem')
  deleteChapterItem() {
    // just emiting event
  }

  @Emit('update')
  updateChapterItem(_payload: UpdateChapterItemPayload) {
    // just emiting event
  }

  @Emit('toggleExpand')
  toggleExpand() {
    // just emiting event
  }

  created() {
    if (this.item.html) {
      this.fileAsHTML = this.item.html;
    }
  }

  get isExpanded() {
    return this.item && this.item.isExpandedInContent;
  }

  onDrop(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer.files.length > 0) {
      this.onFileChosen(e.dataTransfer.files[0]);
    }
  }

  async onFileInput(e: any) {
    if (e.target.files.length > 0) {
      this.onFileChosen(e.target.files[0]);
    }
  }

  onFileChosen(file: File) {
    if (file.type === 'text/html') {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent) => {
        const htmlOutput = (e.target as any).result;
        this.fileAsHTML = htmlOutput;
        this.updateChapterItem({
          uuid: this.item.uuid,
          type: ChapterItemType.TABLE,
          propertyName: 'html',
          propertyValue: this.fileAsHTML,
        });
      };
      reader.readAsText(file);
    }
  }

  onTitleInput = _debounce((event: string) => {
    this.updateChapterItem({
      uuid: this.item.uuid,
      type: ChapterItemType.TABLE,
      propertyName: 'title',
      propertyValue: event,
    });
  }, WS_DEBOUNCE_VALUE);

  onFigureTitleClick() {
    if (this.mode === 'view') {
      this.switchMode('edit');
    }
  }
}
