

























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { CalculationsColumnActionType, CalculationsDataTableColumn, CalculationsDataTableType } from './DataTable.vue';
import TitleCalcColumn from './DataTableTitleField.vue';
import ActionCalcColumn from './DataTableActionField.vue';
import SignificanceCalcColumn from './DataTableSignificanceField.vue';
import TagsCalcColumn, { TagsChangeEvent } from './DataTableTagsField.vue';
import Unit from '@/app/shared/models/calculations/Unit';
import { namespace } from 'vuex-class';
import { ToggleGroupedTagsSelectPayload } from '@/app/shared/store/modules/calculations.store-module';
import LoaderService from '@/app/shared/utils/loader.service';
import api from '@/app/shared/api';
import FormulateModal from '../formulate/FormulateModal.vue';
import AssessModal from '../assess/AssessModal.vue';
import SignificanceModal from '../assess/SignificanceModal.vue';
import { User } from '@/app/shared/models/User';
import { UserRole } from '@/app/shared/enums/user-role.enum';
import { UnitAlternativePhase } from '@/app/shared/models/calculations/UnitAlternative';
import { ModuleProperties } from '@/app/shared/models/Module';

const CalculationsStore = namespace('Calculations');
const ModulePropertiesStore = namespace('ModuleProperties');
const UserStore = namespace('User');

@Component({
  components: {
    TitleCalcColumn,
    ActionCalcColumn,
    SignificanceCalcColumn,
    TagsCalcColumn,
    FormulateModal,
    AssessModal,
    SignificanceModal,
  },
})
export default class CalculationsDataTableField extends Vue {
  @Prop() item: Unit;
  @Prop() column: CalculationsDataTableColumn;
  @Prop() processorType: 'assess' | 'altassess' | 'define' | 'mmdefine' | 'madefine' | 'formulate';
  @Prop() unitListId: number;
  @Prop() tableType: 'formulate' | 'assess' | 'define';

  @UserStore.State
  public user!: User;

  // hasLink: boolean;
  formulateModalVisible: boolean = false;
  assessModalVisible: boolean = false;
  significanceModalVisible: boolean = false;

  get isTitleType() {
    return this.column.type === CalculationsDataTableType.TITLE;
  }

  get isActionType() {
    return this.column.type === CalculationsDataTableType.ACTION;
  }

  get isSignificanceType() {
    return this.column.type === CalculationsDataTableType.SIGNIFICANCE;
  }

  get isSelectType() {
    return this.column.type === CalculationsDataTableType.SELECT;
  }

  get action() {
    if (this.column.actionType === CalculationsColumnActionType.ASSESS) {
      return { label: 'Assess', icon: 'assess', isDisabled: this.column.actionIsDisabled };
    } else if (this.column.actionType === CalculationsColumnActionType.DEFINE) {
      return { label: 'Define', icon: 'assess', isDisabled: this.column.actionIsDisabled };
    } else {
      return { label: 'Formulate', icon: 'formulate', isDisabled: this.column.actionIsDisabled };
    }
  }

  get tagsChecktree() {
    return this.item.grouped_tags[this.column.value];
  }

  get isUserAdmin() {
    return this.user.role === UserRole.ADMIN;
  }

  get unitAssessStatus(): 'default' | 'in-progress' | 'done' | 'new' | 'locked' {
    if (this.item.locked_by) return 'locked';
    if (!this.item.alternatives || this.item.alternatives.length === 0) {
      return 'default';
    }
    let phasesCount = 0;
    let assessedPhasesCount = 0;
    for (const alternative of this.item.alternatives) {
      for (const phase of alternative.phases) {
        phasesCount++;
        if (phase.score) {
          assessedPhasesCount++;
        }
      }
    }
    // TODO: Implement `new` state too!
    return phasesCount === assessedPhasesCount ? 'done' : assessedPhasesCount > 0 ? 'in-progress' : 'default';
  }

  get hasLink() {
    // TODO: Clarify the behavior of the link column and adjust the condition below.
    return true;
    // return this.tableType === 'assess' || this.tableType === 'define' || this.tableType === 'formulate';
  }

  get hasSelectionMenu() {
    // TODO: Clarify the behavior of the tags column and adjust the condition below.
    return false;
    // return this.tableType === 'assess' || this.tableType === 'define' || this.tableType === 'formulate';
  }

  get significancePhase() {
    let sigPhase: UnitAlternativePhase = null;
    if (this.item.alternatives) {
      for (const alternative of this.item.alternatives) {
        for (const phase of alternative.phases) {
          if (!sigPhase || Math.abs(phase.score) > Math.abs(sigPhase.score)) {
            sigPhase = phase;
          }
        }
      }
    }
    return sigPhase;
  }

  @CalculationsStore.Action
  toggleSelectWithinUnitGroupedTags: (payload: ToggleGroupedTagsSelectPayload) => Promise<any>;

  @CalculationsStore.Action
  initializeFormulateData: (unitUuid: string) => Promise<any>;

  @CalculationsStore.Action
  initializeAssessData: (payload: any) => Promise<any>;

  @ModulePropertiesStore.Getter
  moduleProperties: ModuleProperties;

  openSingleImpactPage() {
    this.$router.push(`/single-impact/${this.moduleProperties.study_uuid}/${this.item.uuid}`);
  }

  async onActionClick() {
    try {
      LoaderService.disableHttpLoader();
      LoaderService.showLoader();

      if (this.column.actionType === CalculationsColumnActionType.DEFINE) {
        const checktreeUuid =
          this.processorType === 'define'
            ? (await api.calculations.getChecktreeByUnitUuid(this.item.uuid)).uuid
            : (await api.calculations.getMMChecktreeByUnitUuid(this.item.uuid, this.$route)).uuid;

        this.$router.push({
          name: 'UserCheckTree',
          params: { uuid: checktreeUuid },
          query: { unitUuid: this.item.uuid.toString(), unitListId: this.unitListId.toString() },
        });
      } else if (this.column.actionType === CalculationsColumnActionType.FORMULATE) {
        await this.initializeFormulateData(this.item.uuid);
        this.formulateModalVisible = true;
      } else if (this.column.actionType === CalculationsColumnActionType.ASSESS) {
        const payload = {
          unitUuid: this.item.uuid,
          alternatives:
            this.moduleProperties.alternatives == 'vecs'
              ? this.moduleProperties.alternatives
              : this.processorType == 'altassess'
              ? 'all'
              : 'default',
        };
        await this.initializeAssessData(payload);
        this.assessModalVisible = true;
      }
    } catch (error) {
      console.error(error);
    } finally {
      LoaderService.hideLoader();
      LoaderService.enableHttpLoader();
    }
  }

  openSignificanceModal() {
    this.significanceModalVisible = true;
  }

  async onTagsChange(event: TagsChangeEvent) {
    try {
      LoaderService.disableHttpLoader();
      for (const tagChange of event) {
        await this.toggleSelectWithinUnitGroupedTags({
          unitUuid: this.item.uuid,
          checktreeItemUuid: tagChange.checktreeItemUuid,
          value: tagChange.value,
          groupedTagsKey: this.column.value,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }
}
