






import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import { ChartDataset } from '@/app/shared/models/presentations/Charts';

@Component
export default class StepLinePlot extends Vue {
  @Prop() data: ChartDataset[];
  @Prop() labels: string[];
  chart: Chart = null;

  @Emit('chart-updated')
  chartUpdated(_base64Images: string[]) {
    // emitting event
  }

  @Watch('data')
  onDataChanged() {
    if (this.chart) this.chart.destroy();
    this.initializeChart();
  }

  mounted() {
    this.initializeChart();
  }

  initializeChart() {
    if (!this.data || !this.data.length) return;

    const chartCanvas = this.$refs.chartCanvas as HTMLCanvasElement;
    chartCanvas.height = 300;

    const data: ChartData = {
      labels: this.labels.map((el) => el.toUpperCase()),
      datasets: this.data.map((el) => {
        return {
          label: el.label,
          borderColor: el.color,
          backgroundColor: el.color,
          fill: false,
          data: el.value,
          lineTension: 0,
        };
      }),
    };

    const options: ChartOptions = {
      // legend: { position: 'bottom' },
      maintainAspectRatio: false,
      title: {
        display: true,
        // text: 'Custom Chart Title',
        position: 'bottom',
        fontFamily: "'Graphik', Avenir, Helvetica, Arial, sans-serif",
        fontColor: '#222C37',
        fontSize: 18,
        fontStyle: '500',
      },
      scales: {
        xAxes: [
          {
            gridLines: { drawOnChartArea: false },
            ticks: {
              fontColor: '#222C37',
              fontFamily: "'Graphik', Avenir, Helvetica, Arial, sans-serif",
              fontSize: 14,
              fontStyle: '500',
            },
          },
        ],
        yAxes: [
          {
            gridLines: { drawOnChartArea: true },
            ticks: { beginAtZero: true },
          },
        ],
      },
      tooltips: {
        // enabled: false,
      },
      // plugins: {
      //   datalabels: {
      //     anchor: 'end',
      //     align: 'bottom',
      //     color: 'white',
      //     display: function(context: any) {
      //       return context.dataset.data[context.dataIndex] > 20;
      //     },
      //     font: {
      //       family: "'Graphik', Avenir, Helvetica, Arial, sans-serif",
      //       weight: 'bold',
      //       size: 14,
      //     },
      //     formatter: Math.round,
      //   },
      // },
      animation: {
        onComplete: () => {
          if (this.chart) this.chartUpdated([this.chart.toBase64Image()]);
        },
      },
    };

    const chartConfig = {
      // plugins: [ChartDataLabels],
      type: 'line',
      data: data,
      options: options,
    };

    this.chart = new Chart(chartCanvas, chartConfig);
  }
}
