















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { DashboardDataTableAction } from '@/app/shared/models/DashboardDataTable';

@Component
export default class DashboardDataTableTitleField extends Vue {
  @Prop() item: any;
  @Prop() title: string;
  @Prop() isExpanded: boolean;
  @Prop({ default: false, type: Boolean }) isEditVisible: boolean;
  @Prop({ default: false, type: Boolean }) isEditDisabled: boolean;
  @Prop({ default: false, type: Boolean }) isExpandDisabled: boolean;
  @Prop() config: DashboardDataTableAction[];

  @Emit('click')
  click() {
    //
  }

  @Emit('edit')
  edit() {
    //
  }

  @Emit('copy')
  copy() {
    //
  }

  @Emit('expand')
  expand() {
    //
  }

  @Emit('update:isExpanded')
  updateIsExpanded(_isExpanded: boolean) {
    //
  }

  toggleExpand() {
    this.updateIsExpanded(!this.isExpanded);
    if (!this.isExpanded) this.expand();
  }

  get buttonConfig() {
    if (!this.config) return {} as DashboardDataTableAction;
    return this.config.find((button) => button.type === 'work');
  }

  @AsyncComputed()
  async isCopyVisible() {
    let isDisabled = false;
    if (this.$route.name === 'StudiesDashboard') {
      if (typeof this.buttonConfig !== 'undefined' && typeof this.buttonConfig.isDisabled === 'function') {
        isDisabled = await this.buttonConfig.isDisabled(this.item);
        return !isDisabled;
      }
    }
    return false;
  }
}
