import FormConfigButton from '@/app/shared/components/form/config/FormConfigButton.vue';
import FormFieldButton from '@/app/shared/components/form/field/FormFieldButton.vue';

export default {
  name: 'Button Control',
  description: 'Button for triggering form actions',
  disableValidation: true,
  disableValue: true,

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldButton,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigButton,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    isDisabled: false,
    isRunValidation: false,
    buttonType: 'submit',
    buttonColor: 'primary',
    isBlock: true,
    to: null,
    eventName: null,
    eventPayload: null,
    icon: null,
    hintText: null,
    disabledOnValue: '',

    // Override common default value.
    isShowLabel: false,
  },
};
