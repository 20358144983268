import Vue from 'vue';
import { ws } from './../../ws';
import { WSMessageResponse } from './../../models/WSMessageResponse';
import { CheckTree } from './../../models/CheckTree';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import api from '../../api';
import CheckTreeItem from '../../models/CheckTreeItem';
import { ChecktreeItemUpdateMode } from './../../enums/checktree-item-update-mode.enum';
import { WSMessageCommand } from '../../enums/ws-message-command.enum';
import { foldAll, unfoldAll, walkTreeData } from 'he-tree-vue';
import LoaderService from '../../utils/loader.service';

export type UpdatedProperty =
  | 'title'
  | 'color'
  | 'comment_placeholder'
  | 'help'
  | 'comment'
  | 'comment_visible'
  | 'lock'
  | 'checked'
  | '$folded';

const util = {
  hasAllActiveAttrs(node: CheckTreeItem, attr: keyof CheckTreeItem): boolean {
    if (node) {
      if (!node[attr]) return false;

      let result = true;

      if (node.children && node.children.length > 0) {
        for (const childNode of node.children) {
          result = util.hasAllActiveAttrs(childNode, attr);
          if (!result) return false;
        }
      }

      return result;
    }

    return false;
  },
};

@Module({ namespaced: true })
class ChecklistsModule extends VuexModule {
  checktreeState: CheckTree = null;
  checklistsData: CheckTreeItem[] = [];

  // shouldEnumerateChecktree: boolean = false;
  shouldSelectAllItems: boolean = false;

  get checktree() {
    return this.checktreeState;
  }

  get checklistsTreeData(): CheckTreeItem[] {
    return [...this.checklistsData];
  }

  get getChecktreeItem() {
    const findNode = (parentNode: CheckTreeItem, path: number[]): CheckTreeItem => {
      const levelIndex = path[0];
      if (path.length === 1) {
        // parent node found
        if (!parentNode) {
          // if parentNode is falsy it means that new item should be added to root array
          return this.checklistsTreeData[levelIndex];
        } else {
          return parentNode.children[levelIndex];
        }
      } else {
        return findNode(
          parentNode ? parentNode.children[levelIndex] : this.checklistsTreeData[levelIndex],
          path.slice(1),
        );
      }
    };
    return (path: number[]) => findNode(null, path);
  }

  get isAllSelected() {
    if (!this.checklistsData || this.checklistsData.length === 0) return false;

    let result = true;

    for (const firstLevelNode of this.checklistsData) {
      result = util.hasAllActiveAttrs(firstLevelNode, 'checked');
      if (!result) return false;
    }

    return result;
  }

  get isAllLocked() {
    if (!this.checklistsData || this.checklistsData.length === 0) return false;

    let result = true;

    for (const firstLevelNode of this.checklistsData) {
      result = util.hasAllActiveAttrs(firstLevelNode, 'lock');
      if (!result) return false;
    }

    return result;
  }

  get shouldEnumerateChecktree() {
    return this.checktreeState ? this.checktreeState.enumerate : false;
  }

  @Mutation
  private setChecktree(data: CheckTree): void {
    this.checktreeState = data;
  }

  @Mutation
  private setData(data: CheckTreeItem[]): void {
    this.checklistsData = data;
  }

  @Mutation
  private addItem(clickedItem: { node: CheckTreeItem; path: number[] }): void {
    const addNodeRecursively = (path: number[], parentNode: CheckTreeItem, newItem: CheckTreeItem) => {
      const levelIndex = path[0];
      if (path.length === 1) {
        if (!parentNode) {
          // if parentNode is falsy it means that new item should be added to root array
          this.checklistsData.splice(levelIndex + 1, 0, newItem);
        } else {
          parentNode.children.splice(levelIndex + 1, 0, newItem);
        }
      } else {
        addNodeRecursively(
          path.slice(1),
          parentNode ? parentNode.children[levelIndex] : this.checklistsData[levelIndex],
          newItem,
        );
      }
    };

    const newItem: CheckTreeItem = {
      id: null,
      uuid: null,
      title: null,
      level: null,
      slug: null,
      help: '',
      comment: '',
      comment_placeholder: '',
      comment_visible: false,
      color: '',
      lock: false,
      checked: false,
      description: '',
      locked_by: null,
      children: [],
      $folded: false,
    };
    addNodeRecursively(clickedItem.path, null, newItem);
  }

  @Mutation
  private updateItemUuid(payload: { oldUuid: string; newUuid: string }): void {
    const findItemByUUid = (uuid: string, node: CheckTreeItem): CheckTreeItem => {
      if (node) {
        if (node.uuid == uuid) {
          return node;
        } else if (node.children && node.children.length > 0) {
          let result = null;
          for (const childNode of node.children) {
            result = findItemByUUid(uuid, childNode);
            if (result) return result;
          }
          return result;
        }
        return null;
      }

      let result = null;
      for (const firstLevelNode of this.checklistsData) {
        result = findItemByUUid(uuid, firstLevelNode);
        if (result) return result;
      }
      return result;
    };
    const foundItem = findItemByUUid(payload.oldUuid, null);
    foundItem.uuid = payload.newUuid;
  }

  @Mutation
  public toggleChecktreeItemLock(payload: { uuid: string; lockedByObj?: any }): void {
    const findItemByUUid = (uuid: string, node: CheckTreeItem): CheckTreeItem => {
      if (node) {
        if (node.uuid == uuid) {
          return node;
        } else if (node.children && node.children.length > 0) {
          let result = null;
          for (const childNode of node.children) {
            result = findItemByUUid(uuid, childNode);
            if (result) return result;
          }
        }
        return null;
      }

      let result = null;
      for (const firstLevelNode of this.checklistsData) {
        result = findItemByUUid(uuid, firstLevelNode);
        if (result) return result;
      }
    };
    const foundItem = findItemByUUid(payload.uuid, null);
    if (payload.lockedByObj) {
      // should lock item
      foundItem.locked_by = payload.lockedByObj;
    } else {
      foundItem.locked_by = null;
    }
  }

  @Mutation
  private updateItem(payload: {
    updatedItem: CheckTreeItem;
    path: number[];
    updateMode: ChecktreeItemUpdateMode;
    propertyUpdated: UpdatedProperty;
  }): void {
    const updateNodeAndChildren = (
      node: CheckTreeItem,
      updatedItem: CheckTreeItem,
      propertyUpdated: UpdatedProperty,
    ) => {
      if (
        propertyUpdated === 'comment_visible' ||
        propertyUpdated === 'lock' ||
        propertyUpdated === 'checked' ||
        propertyUpdated === '$folded'
      ) {
        node[propertyUpdated] = updatedItem[propertyUpdated];
      } else {
        node[propertyUpdated] = updatedItem[propertyUpdated];
      }
      if (!node.children || node.children.length === 0) {
        return;
      }
      for (const child of node.children) {
        updateNodeAndChildren(child, updatedItem, propertyUpdated);
      }
    };

    const updateNode = (
      path: number[],
      parentNode: CheckTreeItem,
      updatedItem: CheckTreeItem,
      updateMode: ChecktreeItemUpdateMode,
      propertyUpdated: UpdatedProperty,
    ) => {
      const levelIndex = path[0];
      if (path.length === 1) {
        // parent node found
        let foundNode: CheckTreeItem;
        if (!parentNode) {
          // if parentNode is falsy it means that item is at root level
          foundNode = this.checklistsData[levelIndex];
        } else {
          foundNode = parentNode.children[levelIndex];
        }

        // after node is found, update it
        if (updateMode === ChecktreeItemUpdateMode.ONLY_HERE) {
          // ws.updateCheckItem(updatedItem.uuid, propertyUpdated, updatedItem[propertyUpdated]);
          if (
            propertyUpdated === 'comment_visible' ||
            propertyUpdated === 'lock' ||
            propertyUpdated === 'checked' ||
            propertyUpdated === '$folded'
          ) {
            foundNode[propertyUpdated] = updatedItem[propertyUpdated];
          } else {
            foundNode[propertyUpdated] = updatedItem[propertyUpdated];
          }
        } else if (updateMode === ChecktreeItemUpdateMode.CONTAINER) {
          updateNodeAndChildren(foundNode, updatedItem, propertyUpdated);
        }
      } else {
        updateNode(
          path.slice(1),
          parentNode ? parentNode.children[levelIndex] : this.checklistsData[levelIndex],
          updatedItem,
          updateMode,
          propertyUpdated,
        );
      }
    };

    if (payload.updateMode === ChecktreeItemUpdateMode.ALL) {
      for (const item of this.checklistsData) {
        updateNodeAndChildren(item, payload.updatedItem, payload.propertyUpdated);
      }
    } else {
      updateNode(payload.path, null, payload.updatedItem, payload.updateMode, payload.propertyUpdated);
    }
  }

  @Mutation
  public delete(path: number[]): void {
    const removeNodeRecursively = (path: number[], parentNode: CheckTreeItem) => {
      const levelIndex = path[0];
      if (path.length === 1) {
        if (!parentNode) {
          // if parentNode is falsy it means that new item should be added to root array
          this.checklistsData.splice(levelIndex, 1);
        } else {
          parentNode.children.splice(levelIndex, 1);
        }
      } else {
        removeNodeRecursively(
          path.slice(1),
          parentNode ? parentNode.children[levelIndex] : this.checklistsData[levelIndex],
        );
      }
    };
    removeNodeRecursively(path, null);
  }

  @Mutation
  public lockAll(shouldLock: boolean): void {
    walkTreeData(this.checklistsData, (node, _index, _parent, _path) => {
      const item: CheckTreeItem = node as CheckTreeItem;
      item.lock = shouldLock;
    });
  }

  @Mutation
  public selectAll(shouldSelect: boolean): void {
    walkTreeData(this.checklistsData, (node, _index, _parent, _path) => {
      const item: CheckTreeItem = node as CheckTreeItem;
      item.checked = shouldSelect;
    });
  }

  @Mutation
  public enumerateChecktree(shouldEnumerate: boolean): void {
    this.checktreeState.enumerate = shouldEnumerate;
  }

  @Mutation
  public toggleFold(shouldFold: boolean): void {
    if (shouldFold) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      foldAll(this.checklistsData);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      unfoldAll(this.checklistsData);
    }
  }

  @Action({ rawError: true })
  public onCheckTreeReorder(payload: {
    dragNode: CheckTreeItem;
    afterNode: CheckTreeItem;
    isFirstChild: boolean;
    updatedTreeData: CheckTreeItem[];
  }): void {
    this.context.commit('setData', payload.updatedTreeData);
    ws.checktree.reorderCheckTreeItem(
      payload.dragNode.uuid,
      payload.afterNode ? payload.afterNode.uuid : null,
      payload.isFirstChild,
    );
  }

  @Action({ rawError: true })
  public addChecklistItem(clickedItem: { node: CheckTreeItem; path: number[] }): void {
    this.context.commit('addItem', clickedItem);
    ws.checktree.addCheckTreeItem(clickedItem.node.uuid);
  }

  @Action({ rawError: true })
  private updateChecklistItem(payload: {
    updatedItem: CheckTreeItem;
    path: number[];
    updateMode: ChecktreeItemUpdateMode;
    propertyUpdated: UpdatedProperty;
  }): void {
    this.context.commit('updateItem', payload);
    if (payload.updateMode === ChecktreeItemUpdateMode.ONLY_HERE) {
      ws.checktree.updateCheckTreeItem(
        payload.updatedItem.uuid,
        payload.propertyUpdated,
        payload.updatedItem[payload.propertyUpdated],
        null,
        payload.updatedItem.locked_by,
      );
    } else {
      ws.checktree.propagateUpdateCheckTreeItems(
        payload.updateMode === ChecktreeItemUpdateMode.CONTAINER ? 'updateContainer' : 'updateAll',
        payload.updateMode === ChecktreeItemUpdateMode.CONTAINER ? payload.updatedItem.uuid : this.checktreeState.uuid,
        payload.propertyUpdated,
        payload.updatedItem[payload.propertyUpdated],
      );
    }
  }

  @Action({ rawError: true })
  public async initializeChecklistsData(checktreeUuid: string): Promise<boolean> {
    try {
      const checktree: CheckTree = await api.checktree.getByUuid(checktreeUuid);
      const data = checktree.children;

      const firstParentChildItems: string[] = [];

      // Initialize the `$folded` attribute of all nodes as a client-only state (#103).
      //  More info here: https://gitlab.com/eon-plus/envigo/v2/app/-/issues/103
      walkTreeData(data, (node, index, parent) => {
        const item: CheckTreeItem = node as CheckTreeItem;

        //  Implicitly expand the first parent node.
        if (!parent && index === 0) {
          Vue.set(item, '$folded', false);

          // Additionally, expand all of its children.
          if (item.children && item.children.length) {
            walkTreeData(item.children, (child) => {
              const childItem = child as CheckTreeItem;
              Vue.set(childItem, '$folded', false);
              firstParentChildItems.push(childItem.uuid);
            });
          }
        }

        // Leave the rest of the items collapsed.
        //   Make sure to skip first parent child items, as this is not a recursion.
        else if (!firstParentChildItems.includes(item.uuid)) {
          Vue.set(item, '$folded', true);
        }
      });

      this.context.commit('setChecktree', checktree);
      this.context.commit('setData', data);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @Action({ rawError: true })
  public deleteItem(payload: { uuid: string; path: number[] }): void {
    this.context.commit('delete', payload.path);
    ws.checktree.deleteCheckTreeItem(payload.uuid);
  }

  @Action({ rawError: true })
  public lockAllItems(shouldLock: boolean): void {
    this.context.commit('lockAll', shouldLock);
    ws.checktree.propagateUpdateCheckTreeItems('updateAll', this.checktreeState.uuid, 'lock', shouldLock);
  }

  @Action({ rawError: true })
  public selectAllItems(shouldSelect: boolean): void {
    this.context.commit('selectAll', shouldSelect);
    ws.checktree.propagateUpdateCheckTreeItems('updateAll', this.checktreeState.uuid, 'checked', shouldSelect);
  }

  @Action({ rawError: true })
  public enumerateChecktreeItems(shouldEnumerate: boolean): void {
    this.context.commit('enumerateChecktree', shouldEnumerate);

    LoaderService.disableHttpLoader();
    api.checktree
      .updateChecktreeEnumaration(this.checktreeState.uuid, shouldEnumerate)
      .catch((_err) => {
        this.context.commit('enumerateChecktree', !shouldEnumerate);
      })
      .finally(() => LoaderService.enableHttpLoader());
  }

  @Action({ rawError: true })
  public toggleFoldItems(shouldFold: boolean): void {
    this.context.commit('toggleFold', shouldFold);

    // For now, disable updating folded state on the server (#103).
    //   We only need local `$folded` state to render it client-side.
    //   More info here: https://gitlab.com/eon-plus/envigo/v2/app/-/issues/103
    // ws.checktree.propagateUpdateCheckTreeItems('updateAll', this.checktreeState.uuid, '$folded', shouldFold);
  }

  @Action({ rawError: true })
  public handleWebsocketMessage(response: WSMessageResponse) {
    const findItemByUUid = (uuid: string, node: CheckTreeItem): CheckTreeItem => {
      if (node) {
        if (node.uuid == uuid) {
          return node;
        } else if (node.children && node.children.length > 0) {
          let result = null;
          for (const childNode of node.children) {
            result = findItemByUUid(uuid, childNode);
          }
          return result;
        }
        return null;
      }

      let result = null;
      for (const firstLevelNode of this.checklistsData) {
        result = findItemByUUid(uuid, firstLevelNode);
        if (result) return result;
      }
      return result;
    };
    // check if action is successful
    if (response.command === WSMessageCommand.ADD) {
      this.context.commit('updateItemUuid', { oldUuid: null, newUuid: response.params.uuid });
      return true;
    } else if (response.command === WSMessageCommand.DELETE) {
      const deletedItemUuid: string = response.data[0].uuid;
      const deletedItem = findItemByUUid(deletedItemUuid, null);
      if (deletedItem) {
        // item isn't deleted
        return false;
      }
    } else if (response.command === WSMessageCommand.REORDER) {
      return true;
    } else if (response.command === WSMessageCommand.PROPAGATE) {
      const updatedItemUuid: string = response.params.uuid;
      if (response.data.checked && response.params.model === 'CheckTreeItem') {
        const updatedItem = findItemByUUid(updatedItemUuid, null);
        if (updatedItem && updatedItem.locked_by) {
          // if item is checked unlock it
          ws.checktree.toggleLockCheckTreeItem(WSMessageCommand.UNLOCK, updatedItemUuid);
        }
      }
    } else if (response.command === WSMessageCommand.LOCK || response.command === WSMessageCommand.UNLOCK) {
      const updatedItemUuid: string = response.params.uuid;
      this.context.commit('toggleChecktreeItemLock', { uuid: updatedItemUuid, lockedByObj: response.lock });
    } else if (response.command === WSMessageCommand.UPDATE) {
      const updatedItemUuid: string = response.data[0].uuid;
      const updatedItem = findItemByUUid(updatedItemUuid, null);
      if (updatedItem && updatedItem.locked_by)
        ws.checktree.toggleLockCheckTreeItem(WSMessageCommand.UNLOCK, updatedItemUuid);
    }
    return true;
  }

  private addNodeToTreeStructure(
    parentItemId: number,
    itemBeforeId: number,
    node: CheckTreeItem,
    newItem: CheckTreeItem,
  ) {
    // console.log(node);
    if (node.id === parentItemId) {
      const itemBeforeIndex = node.children.findIndex((el) => el.id === itemBeforeId);
      node.children.splice(itemBeforeIndex + 1, 0, newItem);
    } else {
      for (let i = 0; i < node.children.length; i++) {
        this.addNodeToTreeStructure(parentItemId, itemBeforeId, node.children[i], newItem);
      }
    }
  }
}
export default ChecklistsModule;
