import FormConfigInvestor from '@/app/shared/components/form/config/FormConfigInvestor.vue';
import FormFieldInvestor from '@/app/shared/components/form/field/FormFieldInvestor.vue';
import { Investor } from '@/app/shared/models/form/field/FormFieldInvestor';
import { FormFieldValue, FormFieldValueModel } from '@/app/shared/models/form/field';

export default {
  name: 'Investor Control',
  description: 'Complex input for investor info',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldInvestor,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigInvestor,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    helpText: '',
    isDisabled: false,
    isReadonly: false,

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {FormFieldValue<Investor>}
   */
  rendererDefaultData(): FormFieldValue<Investor> {
    return new FormFieldValue([new Investor()], FormFieldValueModel.INVESTOR);
  },
};
