var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{ref:"tableHead",staticClass:"MatrixTable__BodyColumn MatrixTable__BodyColumn--RowLabel",class:[
    { 'parent-expanded-row': _vm.row.isParent && _vm.row.children[0].expanded && _vm.row.level == 0 },
    { 'parent-collapsed-row': _vm.row.isParent && !_vm.row.children[0].expanded && _vm.row.level == 0 } ]},[_c('div',{staticClass:"d-flex align-center justify-space-between cell-header-wrapper"},[_c('span',{staticClass:" d-flex align-center justify-center row-name-wrapper"},[(!_vm.row.isParent || _vm.row.level != 0)?_c('v-icon',{staticClass:"p-y-half add-whole-item-wrapper",class:[
          { 'add-whole-item': !_vm.wholeRowSelected(_vm.row.id) },
          { 'add-whole-item-blue': _vm.wholeRowSelected(_vm.row.id) },
          { 'first-level-margin': (_vm.row.level == 0 || _vm.row.level == 1) && !_vm.row.isParent } ],style:({ 'margin-right': _vm.row.level === 0 ? '0' : ((_vm.row.level * 8 - 4) + "px") }),on:{"click":_vm.selectRow}},[_vm._v("$add-thick")]):_vm._e(),_c('v-tooltip',{attrs:{"content-class":"ellipsis-text","right":"","offset-overflow":"","transition":"fade"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"row-name",class:{ 'regular-font': _vm.row.level > 1 }},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.row.title)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.row.title))])])],1),(_vm.row.children.length > 0)?_c('BaseButton',{staticClass:"row-expand-btn",attrs:{"type":_vm.row.level === 0 ? 'icon-septim' : 'icon-light'},on:{"click":function($event){return _vm.toogleExpand(_vm.row)}}},[(_vm.row.children[0].expanded)?_c('v-icon',[_vm._v("$collapse")]):_c('v-icon',[_vm._v("$reveal")])],1):_vm._e(),(_vm.index === 0)?_c('div',{staticClass:"row-resize-grabber",on:{"mousedown":function($event){$event.preventDefault();return _vm.onRowResize.apply(null, arguments)}}},[_c('v-icon',[_vm._v("$resize-grabber")])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }