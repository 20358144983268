






























import { Component, Watch, Vue } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import LandingPageButton from '@/app/home/components/LandingPageButton.vue';
import PageFooter from '@/app/shared/components/PageFooter.vue';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';
import { HowToUse } from '@/app/shared/enums/how-to-use.enum';

const UserStore = namespace('User');

@Component({
  components: {
    LandingPageButton,
    PageFooter,
  },
})
export default class AdminLandingPage extends Vue {
  skipLandingPage: boolean = false;

  @AsyncComputed()
  async buttons() {
    return [
      {
        key: 'organizations-dashboard',
        icon: 'company-dashboard',
        label: 'Organizations Dashboard',
        route: '/organizations',
        isDisabled: !(await this.isAllowed(UserPermission.ORGANIZATIONS_DASHBOARD_ACCESS)),
      },
      {
        key: 'system-workflow',
        icon: 'workflow-icon',
        label: 'System Workflow',
        route: '/workflow/0158973c-49e2-44d1-bdcd-847f291a0ffb',
        isDisabled: !(await this.isAllowed(UserPermission.WORKFLOW_ACCESS)),
      },
      {
        key: 'workflow',
        icon: 'workflow-icon',
        label: 'Workflow',
        route: '/workflow/811c3d68-e4bf-4304-bd8f-2eb05b37b9b8',
        isDisabled: !(await this.isAllowed(UserPermission.WORKFLOW_ACCESS)),
      },
      {
        key: 'form-builder',
        icon: 'development-facility-type-v2',
        label: 'Form Builder',
        route: '/form-builder',
        isDisabled: !(await this.isAllowed(UserPermission.FORM_BUILDER_ACCESS)),
      },
      {
        key: 'landing-page',
        icon: 'checktree',
        label: 'Landing Page',
        route: '/landing',
        isDisabled: !(await this.isAllowed(UserPermission.LANDING_ACCESS)),
      },
      {
        key: 'how-to-use',
        icon: 'checktree',
        label: 'How To Use Envigo',
        route: '/how-to-use/' + HowToUse.UUID,
        isDisabled: !(await this.isAllowed(UserPermission.SINGLE_IMPACT_REPORT_ACCESS)),
      },
    ];
  }

  @UserStore.Getter
  public userOrganizationUuid: () => string;

  @UserStore.Action
  public isAllowed: (permission: string | string[]) => Promise<boolean>;

  @Watch('skipLandingPage')
  onSkipLandingPageChange(newValue: boolean) {
    console.log('SKIP!', newValue);
  }

  goTo(route: string) {
    this.$router.push(route);
  }
}
