var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column form-field-authority"},[_vm._l((_vm.authorities),function(authority,index){return [_c('div',{key:("authority-" + index + "-0")},[_c('FormFieldText',{ref:"formField",refInFor:true,attrs:{"control":{
          uniqueId: ((_vm.control.uniqueId) + "_title"),
          name: ("title_" + index),
          label: _vm.control.label,
          placeholderText: _vm.control.placeholderText,
          subLabel: _vm.control.subLabel,
          inputType: 'text',
          isDisabled: _vm.control.isDisabled,
          isReadonly: _vm.control.isReadonly,
        }},model:{value:(authority.title),callback:function ($$v) {_vm.$set(authority, "title", $$v)},expression:"authority.title"}})],1),(!authority.isDetailsHidden)?_c('div',{key:("authority-" + index + "-1"),staticClass:"d-flex flex-column form-field-authority__details"},[_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"form-field-authority__details-spacer"}),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldText',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_name"),
              name: ("name_" + index),
              label: 'Name',
              placeholderText: 'Enter',
              subLabel: _vm.control.subLabel,
              inputType: 'text',
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(authority.name),callback:function ($$v) {_vm.$set(authority, "name", $$v)},expression:"authority.name"}})],1),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldText',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_address"),
              name: ("address_" + index),
              label: 'Address',
              placeholderText: 'Enter',
              subLabel: _vm.control.subLabel,
              inputType: 'text',
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(authority.address),callback:function ($$v) {_vm.$set(authority, "address", $$v)},expression:"authority.address"}})],1)]),_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"form-field-authority__details-spacer"}),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldText',{class:{
              'form-field-authority--show-error': _vm.isValidObjectEmail,
            },attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_email"),
              name: ("email_" + index),
              label: 'Email',
              placeholderText: 'Enter',
              subLabel: _vm.control.subLabel,
              inputType: 'text',
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(authority.email),callback:function ($$v) {_vm.$set(authority, "email", $$v)},expression:"authority.email"}})],1),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldText',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_phone_number"),
              name: ("phone_number_" + index),
              label: 'Phone number',
              placeholderText: 'Enter',
              subLabel: _vm.control.subLabel,
              inputType: 'text',
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(authority.phone_number),callback:function ($$v) {_vm.$set(authority, "phone_number", $$v)},expression:"authority.phone_number"}})],1)])]):_vm._e(),_c('div',{key:("authority-" + index + "-2"),staticClass:"d-flex flex-column form-field-authority__toggles"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"form-field-authority__toggles-spacer"}),_c('div',{staticClass:"d-flex flex-grow-1 justify-start"},[_c('BaseButton',{staticClass:"form-field-authority__toggle-details-button",attrs:{"icon":authority.isDetailsHidden ? 'show' : 'hide',"type":"quin"},on:{"click":function($event){return _vm.toggleDetails(authority, $event)}}},[_vm._v(" "+_vm._s(authority.isDetailsHidden ? 'Show Details' : 'Hide Details')+" ")])],1),_c('div',{staticClass:"d-flex flex-grow-1 justify-end"},[(index > 0)?_c('BaseButton',{staticClass:"form-field-authority__delete-group-button",attrs:{"disabled":_vm.control.isDisabled || _vm.control.isReadonly,"type":"quin","icon":"delete"},on:{"click":function($event){return _vm.removeGroup(index)}}},[_vm._v(" Delete Group ")]):_vm._e()],1)])]),(!_vm.control.isDisabled && !_vm.control.isReadonly)?_c('div',{key:("authority-" + index + "-3")},[_c('div',{staticClass:"d-flex align-center form-field-authority__add-group",class:{
          'form-field-authority__add-group--is-sm': _vm.$vuetify.breakpoint.smAndDown,
        }},[_c('v-icon',{on:{"click":function($event){return _vm.addGroup(index)}}},[_vm._v(" $add-v2 ")])],1)]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }