var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isIconOnly)?_c('button',{ref:"baseBtn",staticClass:"base-btn btn-icon-only",class:['btn-' + _vm.type, { 'btn-small': _vm.size === 'small' }],style:({
    display: _vm.display === 'block' ? 'flex' : 'inline-flex',
    'margin-right': _vm.display === 'block' ? 'var(--spacer-half)' : 'var(--spacer)',
    width: _vm.isBlock ? '100%' : 'auto',
  }),attrs:{"disabled":_vm.disabled},on:{"click":_vm.click}},[_vm._t("default"),_c('div',{staticClass:"state-overlay"})],2):_c('button',{ref:"baseBtn",staticClass:"base-btn btn-label",class:['btn-' + _vm.type, { 'btn-small': _vm.size === 'small', 'has-icon': _vm.icon }],style:({
    display: _vm.display === 'block' ? 'flex' : 'inline-flex',
    'margin-right': _vm.display === 'block' ? 'var(--spacer-half)' : 'var(--spacer)',
    width: _vm.isBlock ? '100%' : 'auto',
  }),attrs:{"disabled":_vm.disabled},on:{"click":_vm.click}},[(_vm.icon)?_c('v-icon',[_vm._v("$"+_vm._s(_vm.icon))]):_vm._e(),_c('span',{staticClass:"label"},[_vm._t("default")],2),_c('div',{staticClass:"state-overlay"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }