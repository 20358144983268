import FormConfigDate from '@/app/shared/components/form/config/FormConfigDate.vue';
import FormFieldDate from '@/app/shared/components/form/field/FormFieldDate.vue';

export default {
  name: 'Date Control',
  description: 'Date input with a pop-up calendar',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldDate,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigDate,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    isDisabled: false,
    isReadonly: false,
    helpText: '',

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {string}
   */
  rendererDefaultData(): string {
    return null;
  },
};
