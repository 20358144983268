import FormConfigTextarea from '@/app/shared/components/form/config/FormConfigTextarea.vue';
import FormFieldTextarea from '@/app/shared/components/form/field/FormFieldTextarea.vue';

export default {
  name: 'Textarea Control',
  description: 'Multi line text input',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldTextarea,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigTextarea,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    isDisabled: false,
    isReadonly: false,
    helpText: '',
    isSmall: false,

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {string}
   */
  rendererDefaultData() {
    return '';
  },
};
