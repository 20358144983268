import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
class LoaderModule extends VuexModule {
  loading: boolean = false;
  requestsPending: number = 0;
  httpLoaderDisabled: boolean = false;

  get isLoaderVisible() {
    return this.loading;
  }

  get isHttpLoaderDisabled() {
    return this.httpLoaderDisabled;
  }

  @Mutation
  private showLoader() {
    this.loading = true;
  }
  @Mutation
  private hideLoader() {
    this.loading = false;
  }
  @Mutation
  private increasePendingRequestsCount() {
    this.requestsPending++;
  }
  @Mutation
  private decreasePendingRequestsCount() {
    if (this.requestsPending >= 1) {
      this.requestsPending--;
    }
  }
  @Mutation
  private setHttpLoaderDisabledState(isDisabled: boolean) {
    this.httpLoaderDisabled = isDisabled;
  }

  @Action({ rawError: true })
  public show(): void {
    this.context.commit('showLoader');
  }
  @Action({ rawError: true })
  public hide(): void {
    this.context.commit('hideLoader');
  }
  @Action({ rawError: true })
  public pending(): void {
    if (this.requestsPending === 0) {
      this.context.commit('showLoader');
    }
    this.context.commit('increasePendingRequestsCount');
  }
  @Action({ rawError: true })
  public done(): void {
    this.context.commit('decreasePendingRequestsCount');
    if (this.requestsPending === 0) {
      this.context.commit('hideLoader');
    }
  }
  @Action({ rawError: true })
  public disableHttpLoader(): void {
    this.context.commit('setHttpLoaderDisabledState', true);
  }
  @Action({ rawError: true })
  public enableHttpLoader(): void {
    this.context.commit('setHttpLoaderDisabledState', false);
  }
}
export default LoaderModule;
