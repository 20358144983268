











































import { Tree, Draggable } from 'he-tree-vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import LibraryItem from './LibraryItem.vue';
import DraggablePro from '@/assets/plugins/DraggablePro.vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
DraggablePro.LICENSE_NUMBER =
  'U2FsdGVkX18OzUM1WLXc3mcQrTwimr7zHXybgHePYhOsQIWEf/IB9w1SIjh3TbUlglrsE2Haqn7wW1zmrGwUBtwdUk5IXjCnnyo3DCRRyMGIzclfWujHMXhCL5qyaAgVpBhl80CRfmc=';
@Component({
  components: {
    LibraryItem,
    Tree: (Tree as any).mixPlugins([Draggable, DraggablePro]),
  },
})
export default class LibrarySection extends Vue {
  @Prop() icon: string;
  @Prop() title: string;
  @Prop() tableData: any;
  public collapsed: boolean = false;
  public shouldColoneOnDrag: boolean = false;
  public onChange() {
    //
  }

  created() {
    if (this.$route.name == 'Reports') {
      this.shouldColoneOnDrag = true;
    }
  }
}
