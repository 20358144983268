var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column form-field-organization-unit"},[_vm._l((_vm.organizationUnits),function(organizationUnit,index){return [_c('div',{key:("organization-unit-" + index + "-0"),staticClass:"mt-3"},[_c('FormFieldText',{ref:"formField",refInFor:true,class:{
          'form-field-organization-unit--show-error': _vm.isRequiredObjectName,
        },attrs:{"control":{
          uniqueId: ((_vm.control.uniqueId) + "_name"),
          name: ("name_" + index),
          label: _vm.control.label,
          placeholderText: _vm.control.placeholderText,
          subLabel: _vm.control.subLabel,
          inputType: 'text',
          isDisabled: _vm.control.isDisabled,
          isReadonly: _vm.control.isReadonly,
          validations: _vm.isRequiredObjectName ? [{ ruleType: 'required' }] : null,
        }},model:{value:(organizationUnit.name),callback:function ($$v) {_vm.$set(organizationUnit, "name", $$v)},expression:"organizationUnit.name"}})],1),(!organizationUnit.isDetailsHidden)?_c('div',{key:("organization-unit-" + index + "-1"),staticClass:"d-flex flex-column form-field-organization-unit__details"},[_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"form-field-organization-unit__details-spacer"}),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldText',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_address"),
              name: ("address_" + index),
              label: 'Address',
              placeholderText: 'Enter',
              subLabel: _vm.control.subLabel,
              inputType: 'text',
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(organizationUnit.address),callback:function ($$v) {_vm.$set(organizationUnit, "address", $$v)},expression:"organizationUnit.address"}})],1),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldText',{class:{
              'form-field-organization-unit--show-error': _vm.isValidObjectEmail,
            },attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_email"),
              name: ("email_" + index),
              label: 'Contact email address',
              placeholderText: 'Enter',
              subLabel: _vm.control.subLabel,
              inputType: 'text',
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(organizationUnit.email),callback:function ($$v) {_vm.$set(organizationUnit, "email", $$v)},expression:"organizationUnit.email"}})],1)]),_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"form-field-organization-unit__details-spacer"}),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldText',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_phone_number"),
              name: ("phone_number_" + index),
              label: 'Contact phone number',
              placeholderText: 'Enter',
              subLabel: _vm.control.subLabel,
              inputType: 'text',
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(organizationUnit.phone_number),callback:function ($$v) {_vm.$set(organizationUnit, "phone_number", $$v)},expression:"organizationUnit.phone_number"}})],1),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldText',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_fax_number"),
              name: ("fax_number_" + index),
              label: 'Contact fax number',
              placeholderText: 'Enter',
              subLabel: _vm.control.subLabel,
              inputType: 'text',
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(organizationUnit.fax_number),callback:function ($$v) {_vm.$set(organizationUnit, "fax_number", $$v)},expression:"organizationUnit.fax_number"}})],1)]),_c('div',{staticClass:"d-flex mt-1"},[_c('div',{staticClass:"flex-shrink-0 form-field-organization-unit__details-spacer"}),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldUploadInline',{attrs:{"control":{
              uniqueId: ((_vm.control.uniqueId) + "_logo"),
              name: ("logo_" + index),
              label: 'Organization logo',
              subLabel: _vm.control.subLabel,
              isDisabled: _vm.control.isDisabled,
              isReadonly: _vm.control.isReadonly,
              isSmall: true,
            }},model:{value:(organizationUnit.logo),callback:function ($$v) {_vm.$set(organizationUnit, "logo", $$v)},expression:"organizationUnit.logo"}})],1)])]):_vm._e(),_c('div',{key:("organization-unit-" + index + "-2"),staticClass:"d-flex flex-column form-field-organization-unit__toggles"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"form-field-organization-unit__toggles-spacer"}),_c('div',{staticClass:"d-flex flex-grow-1 justify-start"},[_c('BaseButton',{staticClass:"form-field-organization-unit__toggle-details-button",attrs:{"icon":organizationUnit.isDetailsHidden ? 'show' : 'hide',"type":"quin"},on:{"click":function($event){return _vm.toggleDetails(organizationUnit, $event)}}},[_vm._v(" "+_vm._s(organizationUnit.isDetailsHidden ? 'Show Details' : 'Hide Details')+" ")])],1),_c('div',{staticClass:"d-flex flex-grow-1 justify-end"},[(index > 0)?_c('BaseButton',{staticClass:"form-field-organization-unit__delete-group-button",attrs:{"disabled":_vm.control.isDisabled || _vm.control.isReadonly,"type":"quin","icon":"delete"},on:{"click":function($event){return _vm.removeGroup(index)}}},[_vm._v(" Delete Group ")]):_vm._e()],1)])]),(!_vm.control.isDisabled && !_vm.control.isReadonly)?_c('div',{key:("organization-unit-" + index + "-3")},[_c('div',{staticClass:"d-flex align-center form-field-organization-unit__add-group",class:{
          'form-field-organization-unit__add-group--is-sm': _vm.$vuetify.breakpoint.smAndDown,
        }},[_c('v-icon',{on:{"click":function($event){return _vm.addGroup(index)}}},[_vm._v(" $add-v2 ")])],1)]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }