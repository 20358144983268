export class Facility {
  uuid?: string;
  project: string;
  name: string;

  constructor(project: string = '', name: string = '') {
    this.project = project;
    this.name = name;
  }
}
