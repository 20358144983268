
import { Component, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import DialogService from '@/app/shared/utils/dialog.service';
import ExpandableSidebarForm from '@/app/shared/components/mixins/ExpandableSidebarForm.vue';
import studyRegistrationFormList from '@/app/shared/components/form/list/study-registration';
import { FormList } from '@/app/shared/models/form/FormSchema';
import { Study } from '@/app/shared/models/Study';
import api from '@/app/shared/api';

@Component
export default class StudyRegistrationForm extends ExpandableSidebarForm {
  @Prop() uuid!: string;
  isExpanded: boolean = false;
  study: Study = null;
  formList: FormList[] = [];
  target: string = '';
  serverError: AxiosError = null;

  get formDefaultValues() {
    return cloneDeep(this.study);
  }

  get formContainerClass() {
    return {
      'width-552': this.formId !== 'study-facility-type' || !this.isExpanded,
      'width-1128': this.formId === 'study-facility-type' && this.isExpanded,
    };
  }

  get formWidth() {
    return this.formId === 'study-facility-type' && this.isExpanded ? 1128 : 552;
  }

  get isFormShown() {
    return Boolean(this.study);
  }

  get isFormHintHidden() {
    return this.formId === 'study-facility-type';
  }

  get backRoute() {
    return `/study-registration/${this.uuid}`;
  }

  get isStudyFinalized() {
    return this.study.build_created;
  }

  async created() {
    this.formList = studyRegistrationFormList;

    try {
      this.study = await api.study.getStudy(this.uuid);
      if (typeof this.form.isHidden === 'function' && this.form.isHidden(this.study))
        this.$router.replace('/study-registration');
    } catch (error) {
      console.warn(error);
    }
  }

  getFormRoute(formId: string) {
    return `/study-registration/${this.uuid}/${formId}`;
  }

  async onSubmit(payload: Study) {
    this.serverError = null;

    // Sanitize certain payload keys if the study has been finalized.
    //   This avoids them being overwritten if they are submitted again.
    if (this.isStudyFinalized) {
      if (payload.hasOwnProperty('workflow_id')) delete payload.workflow_id;
      if (payload.hasOwnProperty('development_types')) delete payload.development_types;
    }

    try {
      const confirmCallback = async () => {
        await api.study.saveStudy(this.uuid, { ...payload });
        await this.redirectOnSuccess();
      };

      if (this.$route.params.formId == 'study-facility-type') {
        DialogService.presentDialog(
          'Are you sure?',
          'Your selection of development types cannot be changed after your confirmation.<br /><br />This is because predefined wider lists (project activities, aspects, mitigation measures, etc.) will be now filtered according to the selection.<br /><br />However, you can always add and define new groups and custom items within the lists.',
          'Yes',
          'Cancel',
          confirmCallback,
        );
      } else {
        await api.study.saveStudy(this.uuid, { ...payload });
        await this.redirectOnSuccess();
      }
    } catch (error) {
      this.serverError = error as AxiosError;
    }
  }
}
