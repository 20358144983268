















































import { Component, Prop, Vue } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';
import { ModuleProperties } from '@/app/shared/models/Module';
import { HowToUse } from '@/app/shared/enums/how-to-use.enum';

const UiModuleStore = namespace('UI');
const UserStore = namespace('User');
const ModulePropertiesStore = namespace('ModuleProperties');

@Component
export default class PageFooter extends Vue {
  @Prop() scrollListenerSelector: string;
  isBackToTopButtonVisible: boolean = false;

  @UiModuleStore.Getter
  isFooterCommentingActive: boolean;

  @UiModuleStore.Getter
  isFooterLibraryActive: boolean;

  @UiModuleStore.Getter
  isFooterTrackingActive: boolean;

  @UiModuleStore.Getter
  isFooterVersioningActive: boolean;

  @ModulePropertiesStore.Getter
  moduleProperties: ModuleProperties;

  @UiModuleStore.Mutation
  setFooterCommentingActive!: (value: boolean) => void;

  @UiModuleStore.Mutation
  setFooterLibraryActive!: (value: boolean) => void;

  @UiModuleStore.Mutation
  setFooterTrackingActive!: (value: boolean) => void;

  @UiModuleStore.Mutation
  setFooterVersioningActive!: (value: boolean) => void;

  @UserStore.Getter
  public isAuthenticated!: () => boolean;

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  @AsyncComputed()
  async isCommentingAllowed() {
    if (!(await this.isAllowed(UserPermission.COMMENTING_BAR_SHOW))) return false;

    switch (this.$route.name) {
      case 'Reports':
      case 'SingleImpact':
        return true;
      default:
        return false;
    }
  }

  @AsyncComputed()
  async isLibraryAllowed() {
    if (!(await this.isAllowed(UserPermission.LIBRARY_BAR_SHOW))) return false;

    switch (this.$route.name) {
      case 'Presentations':
      case 'PdfOutput':
      case 'Reports':
      case 'SingleImpact':
        return true;
      default:
        return false;
    }
  }

  @AsyncComputed()
  async isTrackingAllowed() {
    if (!(await this.isAllowed(UserPermission.TRACKING_BAR_SHOW))) return false;

    switch (this.$route.name) {
      case 'Reports':
      case 'SingleImpact':
        return true;
      default:
        return false;
    }
  }

  @AsyncComputed()
  async isVersioningAllowed() {
    if (!(await this.isAllowed(UserPermission.VERSIONING_BAR_SHOW))) return false;

    switch (this.$route.name) {
      case 'Workspace':
        return true;
      default:
        return false;
    }
  }

  mounted() {
    if (!this.scrollListenerSelector) {
      window.addEventListener('scroll', this.handleScroll);
    } else {
      const elem = document.querySelector(this.scrollListenerSelector);
      elem.addEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll() {
    if (!this.scrollListenerSelector) {
      if (window.scrollY > 0) {
        this.isBackToTopButtonVisible = true;
      } else {
        this.isBackToTopButtonVisible = false;
      }
    } else {
      if (document.querySelector(this.scrollListenerSelector).scrollTop > 0) {
        this.isBackToTopButtonVisible = true;
      } else {
        this.isBackToTopButtonVisible = false;
      }
    }
  }

  scrollToTop() {
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (!this.scrollListenerSelector) {
      window.scrollTo(0, 0);
    } else {
      const elem = document.querySelector(this.scrollListenerSelector);
      elem.scrollTo(elem.scrollLeft, 0);
    }
  }

  toggleTab(type: 'commenting' | 'library' | 'tracking' | 'versioning') {
    switch (type) {
      case 'commenting':
        if (this.isFooterLibraryActive) this.setFooterLibraryActive(false);
        if (this.isFooterTrackingActive) this.setFooterTrackingActive(false);
        if (this.isFooterVersioningActive) this.setFooterVersioningActive(false);
        this.setFooterCommentingActive(!this.isFooterCommentingActive);
        break;
      case 'library':
        if (this.isFooterCommentingActive) this.setFooterCommentingActive(false);
        if (this.isFooterTrackingActive) this.setFooterTrackingActive(false);
        if (this.isFooterVersioningActive) this.setFooterVersioningActive(false);
        this.setFooterLibraryActive(!this.isFooterLibraryActive);
        break;
      case 'tracking':
        if (this.isFooterCommentingActive) this.setFooterCommentingActive(false);
        if (this.isFooterLibraryActive) this.setFooterLibraryActive(false);
        if (this.isFooterVersioningActive) this.setFooterVersioningActive(false);
        this.setFooterTrackingActive(!this.isFooterTrackingActive);
        break;
      case 'versioning':
        if (this.isFooterCommentingActive) this.setFooterCommentingActive(false);
        if (this.isFooterLibraryActive) this.setFooterLibraryActive(false);
        if (this.isFooterTrackingActive) this.setFooterTrackingActive(false);
        this.setFooterVersioningActive(!this.isFooterVersioningActive);
        break;
    }
  }

  async howToUse() {
    let chapter = null;

    if (['LandingPage', 'UserProfileForm'].includes(this.$route.name)) {
      chapter = 1;
    }

    if (
      ['OrganizationUsersDashboard', 'OrganizationForm', 'UserAddStudyForm', 'EditUserProfileForm'].includes(
        this.$route.name,
      )
    ) {
      chapter = 2;
    }

    if (['StudiesDashboard'].includes(this.$route.name)) {
      chapter = 3;
    }

    if (['StudyRegistration', 'StudyRegistrationForm'].includes(this.$route.name)) {
      chapter = 4;
    }

    if (['StudyReviews', 'StudyReviewsForm'].includes(this.$route.name)) {
      chapter = 5;
    }

    if (['Workspace'].includes(this.$route.name)) {
      chapter = 6;
    }

    if (['UserCheckTree'].includes(this.$route.name)) {
      chapter = 8;
    }

    if (['MatrixRelations'].includes(this.$route.name)) {
      if (this.moduleProperties.module_type == 'CrossconnectMatrixModule') {
        chapter = 9;
      }
      if (this.moduleProperties.module_type == 'TaggingUserMatrixModule') {
        chapter = 10;
      }
    }

    if (['CalculationsMethod'].includes(this.$route.name)) {
      if (
        this.$route.fullPath.includes('/calculations/assess/') ||
        this.$route.fullPath.includes('/calculations/altassess/')
      ) {
        chapter = 11;
      }
      if (
        this.$route.fullPath.includes('/calculations/mmdefine/') ||
        this.$route.fullPath.includes('/calculations/madefine/')
      ) {
        chapter = 13;
      }
    }

    if (['SingleImpact'].includes(this.$route.name)) {
      chapter = 14;
    }

    if (['Presentations'].includes(this.$route.name)) {
      chapter = 15;
    }

    if (['TemplatesPanel'].includes(this.$route.name)) {
      chapter = 16;
    }

    if (['Reports'].includes(this.$route.name)) {
      chapter = 17;
    }

    if (['PdfOutput'].includes(this.$route.name)) {
      chapter = 18;
    }

    const route = this.$router.resolve({
      name: 'How To Use',
      params: { uuid: HowToUse.UUID, chapter: String(chapter) },
    });
    window.open(route.href);
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
