




import { Component, Mixins, Vue } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';

declare global {
  interface Window {
    grecaptchaCallback: () => void;
  }
}

declare const grecaptcha: {
  /**
   * Programmatically invoke the reCAPTCHA check. Used if the invisible reCAPTCHA is on a div
   * instead of a button.
   *
   * @param {string} opt_widget_id Optional widget ID, defaults to the first widget created if
   *     unspecified.
   */
  execute(opt_widget_id?: string): void;

  /**
   * Renders the container as a reCAPTCHA widget and returns the ID of the newly created widget.
   *
   * @param {HTMLElement|string} container The HTML element to render the reCAPTCHA widget. Specify
   *    either the ID of the container (string) or the DOM element itself.
   * @param {Object} parameters An object containing parameters as key=value pairs, for example,
   *    {"sitekey": "your_site_key", "theme": "light"}.
   */
  render(
    container: HTMLElement | string,
    parameters: {
      sitekey: string;
      theme?: 'dark' | 'light';
      size?: 'compact' | 'normal';
      tabindex?: number;
      callback?: (token: string) => void;
      'expired-callback'?: () => void;
      'error-callback'?: () => void;
    },
  ): void;

  /**
   * Resets the reCAPTCHA widget.
   *
   * @param {string} opt_widget_id Optional widget ID, defaults to the first widget created if
   *     unspecified.
   */
  reset(opt_widget_id?: string): void;

  /**
   * Gets the response for the reCAPTCHA widget. Returns a null if reCaptcha is not validated.
   *
   * @param {string} opt_widget_id Optional widget ID, defaults to the first widget created if
   *     unspecified.
   */
  getResponse(opt_widget_id?: string): string;
};

// @TODO Load from docker environment variables.
const sitekey = '6LdprlwiAAAAAN1Xej8XOoZEwgNVnODXdxXQ8Gnh';

@Component
export default class FormFieldCaptcha extends Mixins(Vue, BaseControlSkeleton) {
  updateValue: (value: string) => void;

  mounted() {
    window.grecaptchaCallback = () => {
      grecaptcha.render(this.$refs.grecaptcha as HTMLElement, {
        sitekey,
        callback: this.responseCallback,
      });
    };

    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit',
    );
    recaptchaScript.setAttribute('async', 'async');
    recaptchaScript.setAttribute('defer', 'defer');
    document.head.appendChild(recaptchaScript);
  }

  responseCallback(token: string) {
    this.updateValue(token);
  }
}
