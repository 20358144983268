import { render, staticRenderFns } from "./ReportsSidebarItem.vue?vue&type=template&id=520d0fd9&scoped=true&"
import script from "./ReportsSidebarItem.vue?vue&type=script&lang=ts&"
export * from "./ReportsSidebarItem.vue?vue&type=script&lang=ts&"
import style0 from "./ReportsSidebarItem.vue?vue&type=style&index=0&id=520d0fd9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "520d0fd9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})
