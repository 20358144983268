









































































import { Component, Prop } from 'vue-property-decorator';
import FormRenderer from '@/app/shared/components/form/FormRenderer.vue';
import ExpandableSidebarRoute from '@/app/shared/components/mixins/ExpandableSidebarRoute.vue';
import registerAcceptFormSchema from '@/app/shared/components/form/schema/register-accept.json';
import registerRoleFormSchema from '@/app/shared/components/form/schema/register-role.json';
import registerUserFormSchema from '@/app/shared/components/form/schema/register-user.json';
import api from '@/app/shared/api';
import { AxiosError } from 'axios';

@Component({
  components: {
    FormRenderer,
  },
})
export default class Register extends ExpandableSidebarRoute {
  @Prop() role: string;
  @Prop() email: string;
  @Prop() uuid: string;
  isRegistered: boolean = false;
  isError: boolean = false;
  serverError: AxiosError = null;

  get isRegisterAcceptForm() {
    return this.role === 'accept';
  }

  get registerAcceptFormSchema() {
    return registerAcceptFormSchema;
  }

  get registerRoleFormSchema() {
    return registerRoleFormSchema;
  }

  get registerUserFormSchema() {
    if (this.email) {
      Object.values(registerUserFormSchema.controls).forEach((control: any) => {
        if (control.name === 'email') control.isDisabled = true;
      });
    }

    return registerUserFormSchema;
  }

  get registerFormDefaultValues() {
    if (this.email) return { email: this.email };
    return {};
  }

  registerRole(values: { user_class: string }) {
    if (values.user_class) this.$router.push(`/register/${values.user_class}`);
  }

  loginGuest() {
    // console.log('GUEST LOGIN!');
  }

  async register(payload: {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    g_recaptcha_response: string;
  }) {
    this.serverError = null;

    try {
      await api.auth.register({ ...payload, user_class: this.role });
      this.isRegistered = true;
      this.$router.replace('/register');
    } catch (error) {
      this.serverError = error as AxiosError;
    }
  }

  registerSso() {
    // console.log('REGISTER SSO!');
  }

  async registerAccept(payload: {
    password: string;
    first_name: string;
    last_name: string;
    g_recaptcha_response: string;
  }) {
    this.serverError = null;

    try {
      await api.auth.registerAccept(this.uuid, payload);
      this.isRegistered = true;
      this.$router.push('/register/accept').catch((error) => {
        if (error.name != 'NavigationDuplicated') {
          throw error;
        }
      });
    } catch (error) {
      this.serverError = error as AxiosError;
      if (this.serverError.toString() === 'Error: Request failed with status code 500') this.isError = true;
    }
  }
}
