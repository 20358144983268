
import { Component, Vue } from 'vue-property-decorator';
import { DashboardDataTableColumn } from '../../models/DashboardDataTable';

@Component
export default class DashboardFiltering extends Vue {
  filterFields: string[];
  allColumns: DashboardDataTableColumn[];
  tableData: any[];
  activeFilters: Record<string, any[]>;

  get uniqueFilterValues() {
    const uniqueFilterValues: Record<string, any[]> = {};

    this.filterFields.forEach((field: string) => {
      const column = this.allColumns.find((column) => column.value === field);

      uniqueFilterValues[field] = this.tableData
        .map((item) => {
          let columnValue = item[field];
          if (column && typeof column.transformValue === 'function') columnValue = column.transformValue(item);
          return columnValue;
        })
        .filter((value, valueIndex, items) => value !== null && items.indexOf(value) === valueIndex);
    });

    return uniqueFilterValues;
  }

  get uniqueFilterValueItems() {
    const uniqueFilterValues: Record<string, any[]> = {};

    this.filterFields.forEach((field: string) => {
      const column = this.allColumns.find((column) => column.value === field);

      uniqueFilterValues[field] = this.tableData
        .map((item) => {
          let columnValue = item[field];
          if (column && typeof column.transformValue === 'function') columnValue = column.transformValue(item);
          return columnValue;
        })
        .filter((value, valueIndex, items) => value !== null && items.indexOf(value) === valueIndex)
        .map((filter) => ({ value: filter, text: filter }));
    });

    return uniqueFilterValues;
  }

  get filteredData() {
    if (!this.activeFilters) return this.tableData;

    const filteredData: any[] = [];

    this.tableData.forEach((item) => {
      const isAllowed = Object.keys(this.activeFilters).every((field) => {
        const column = this.allColumns.find((column) => column.value === field);
        let columnValue = item[field] as string;
        if (column && typeof column.transformValue === 'function') columnValue = column.transformValue(item);
        if (columnValue === null) return true;
        return this.activeFilters[field].includes(columnValue);
      });
      if (isAllowed) filteredData.push(item);
    });

    return filteredData;
  }

  initActiveFilters() {
    this.activeFilters = this.uniqueFilterValues;
  }
}
