
















import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NavbarCallout extends Vue {
  @Model('update') readonly isVisible: boolean;
  @Prop() text: string;
  @Prop() subtitle: string;
  @Prop() buttonText: string;
  @Prop({ default: '2px' }) borderRadius: string;

  @Emit()
  goto() {
    //
  }

  @Emit()
  update(_isVisible: boolean) {
    //
  }

  hideCallout() {
    this.update(false);
    return true;
  }
}
