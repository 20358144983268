

































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PresentationTable extends Vue {
  @Prop() header: { text: string; icon: string };
  @Prop() columns: { text: string; value: string }[];
  @Prop() data: Record<string, string>;

  @Emit('action-click')
  onActionClick(_type: string, _item: any) {
    //
  }

  isEmpty(value: any) {
    return value == null || value === '';
  }
}
