




















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TextTable extends Vue {
  @Prop() header: { text: string; icon: string };
  @Prop() data: string;
}
