var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-screening-table"},[_c('div',{staticClass:"title-wrapper",staticStyle:{"display":"flex","margin-bottom":"22px"},on:{"click":_vm.onTitleWrapperClick}},[_c('div',{staticClass:"numeration",style:(Object.assign({}, _vm.style.tableTitleNumeration, _vm.style.numeration))},[_c('span',[_vm._v("Table 1.1")])]),_c('div',{ref:"tableTitle",staticClass:"table-title",style:(Object.assign({}, _vm.style.tableTitleNumeration, _vm.style.tableTitle))},[(_vm.titleInEditMode)?_c('BaseTextField',{attrs:{"placeholder":"Enter title","type":"title"},on:{"blur":function($event){_vm.titleInEditMode = false}},model:{value:(_vm.tableTitle),callback:function ($$v) {_vm.tableTitle=$$v},expression:"tableTitle"}}):_c('span',{style:(_vm.style.tableTitleSpan)},[_vm._v(_vm._s(_vm.tableTitle))])],1)]),_c('div',{ref:"screeningTable"},[_c('div',{staticClass:"table-wrapper",style:({
        '--border-color': _vm.borderColor,
        '--cell-color': _vm.cellColor,
        width: _vm.sizeMode === 'half' ? '50%' : '100%',
      })},[_c('div',{staticClass:"top-border",style:(_vm.style.topBorder)}),_c('div',{staticClass:"table",staticStyle:{"border":"1px solid #D3DADC"}},_vm._l((_vm.selectedChecktreeItems),function(item,index){return _c('div',{key:item.uuid,staticClass:"table-row",style:(Object.assign({}, _vm.style.tableRow,
            {'border-bottom': index === _vm.selectedChecktreeItems.length - 1 ? 'none' : '1px solid #D3DADC',
            padding: _vm.shouldShowComments && item.comment ? '8px 0 14px 0' : '5px 0',
            '--row-font-size': item.level === 1 ? '18px' : item.level === 2 ? '16px' : '14px',
            '--row-line-height': item.level === 1 ? '22px' : item.level === 2 ? '20px' : '18px',
            marginTop: item.level === 1 ? '0' : item.level === 2 ? '3px' : '5px'}))},[_c('div',{staticClass:"row-title",style:(Object.assign({}, _vm.style.rowTitle, {'margin-left': 1 + (item.level - 1) * 0.5 + 'rem'}))},[(_vm.shouldEnumerate)?_c('span',[_vm._v(_vm._s(item.presentationEnumeration)+_vm._s(item.level === 1 ? '.' : ''))]):_vm._e(),_vm._v(" "+_vm._s(item.title)+" ")]),(_vm.shouldShowComments && item.comment)?_c('div',{staticClass:"row-comment",style:(Object.assign({}, _vm.style.rowComment, {'margin-left': 2 + (item.level - 1) * 0.5 + 'rem'})),domProps:{"innerHTML":_vm._s(item.comment)}}):_vm._e()])}),0)])]),_c('PresentationsBottomActionBar',{on:{"add-to-library":_vm.addToLibrary,"clear":_vm.clear}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }