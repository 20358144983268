




























































import { Component, Mixins, Vue } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import FormFieldLabel from '@/app/shared/components/form/field/mixins/FormFieldLabel.vue';
import FormFieldFocus from '@/app/shared/components/form/field/mixins/FormFieldFocus.vue';
import { OptionItem } from '@/app/shared/models/form/field/FormFieldSelect';
import api from '@/app/shared/api';

@Component({
  components: {
    // NB: A workaround for a race condition that may occur when assigning initial field value.
    //   It seems like a bug in Vuetify component instead, and it may be resolved in a future update.
    //   More info here: https://gitlab.com/eon-plus/envigo/v2/development/-/issues/41
    //   And possibly here: https://github.com/vuetifyjs/vuetify/issues/12137
    VuetifySelect: () => import('vuetify/lib/components/VSelect'),
  },
})
export default class FormFieldSelect extends Mixins(Vue, FormFieldLabel, FormFieldFocus, BaseControlSkeleton) {
  control!: {
    uniqueId: string;
    items: OptionItem[];
    endpointPayload: string;
    endpointRoute: string;
    isDisabled: boolean;
    disabledOnValue: string;
  };
  options: OptionItem[] = this.control.items || [];
  maxWidth: number = 552;

  get isDisabled() {
    if (this.control.isDisabled) return true;
    if (this.control.disabledOnValue) {
      const valueContainer = JSON.parse(JSON.stringify(this.$attrs['value-container'])); // NB: why?!?
      return (
        valueContainer &&
        valueContainer.hasOwnProperty(this.control.disabledOnValue) &&
        Boolean(valueContainer[this.control.disabledOnValue as any])
      );
    }
    return false;
  }

  mounted() {
    // Set the maximum width of the menu to that of the rendered field.
    //   More information here: https://gitlab.com/eon-plus/envigo/v2/app/-/issues/134#note_944442323
    const formField = this.$refs.formField as Vue & { $el: HTMLElement };
    if (formField) this.maxWidth = formField.$el.clientWidth;

    this.$nextTick(() => {
      this.getOptions();
    });
  }

  async getOptions() {
    if (!this.control.endpointPayload) return;

    try {
      const payload = JSON.parse(this.control.endpointPayload);

      if (payload.formContext && payload.formContext.length) {
        payload.formContext.forEach(({ key, value }: { key: string; value: string }) => {
          if (this.$attrs['value-container'] && this.$attrs['value-container'].hasOwnProperty(value)) {
            payload.context[key] = this.$attrs['value-container'][value as any];
          }
        });
      }

      if (this.control.endpointRoute) {
        this.options = await api.options.getCustomOptions(this.control.endpointRoute, payload);
      } else {
        this.options = await api.options.getOptions(payload);
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
