




























































































import { Component, Emit, Prop } from 'vue-property-decorator';
import ChapterItemCommentHighlight from './mixins/ChapterItemCommentHighlight.vue';
import { ChapterItem } from '@/app/shared/models/reports/ChapterItem';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import { ChapterItemMode } from '@/app/shared/types/chapter-item-mode.type';
import { Tree, Draggable } from 'he-tree-vue';
import DraggablePro from '@/assets/plugins/DraggablePro.vue';
import _debounce from 'lodash.debounce';
import { UpdateChapterItemPayload, WS_DEBOUNCE_VALUE } from '@/app/shared/store/modules/reports.store-module';
import api from '@/app/shared/api';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
DraggablePro.LICENSE_NUMBER =
  'U2FsdGVkX18OzUM1WLXc3mcQrTwimr7zHXybgHePYhOsQIWEf/IB9w1SIjh3TbUlglrsE2Haqn7wW1zmrGwUBtwdUk5IXjCnnyo3DCRRyMGIzclfWujHMXhCL5qyaAgVpBhl80CRfmc=';
@Component({
  components: {
    Tree: (Tree as any).mixPlugins([Draggable, DraggablePro]),
  },
})
export default class LibraryChapterItem extends ChapterItemCommentHighlight {
  @Prop() item: ChapterItem;
  @Prop() mode: ChapterItemMode;
  @Prop() isDeleteButtonAllowed: boolean;

  libraryContentDropped: boolean = false;

  ChapterItemType: ChapterItemType;

  @Emit('switchMode')
  switchMode(_newMode: ChapterItemMode) {
    // just emiting event
  }

  @Emit('deleteChapterItem')
  deleteChapterItem() {
    // just emiting event
  }

  @Emit('update')
  updateChapterItem(_payload: UpdateChapterItemPayload) {
    // just emiting event
  }

  @Emit('toggleExpand')
  toggleExpand() {
    // just emiting event
  }

  async getLibraryItem(uuid: string) {
    try {
      return await api.library.getLibraryItem(uuid);
    } catch (error) {
      console.warn(error);
    }
  }

  async onChange(event: { dragNode: ChapterItem; startPath: number[]; targetPath: number[] }) {
    let itemType: ChapterItemType = null;
    let propertyName = null;
    let propertyValue: any = null;

    const libraryItem = await this.getLibraryItem(event.dragNode.uuid);

    const additionalProperties = [
      {
        name: 'title',
        value: event.dragNode.title,
      },
    ];

    // Convert library chapter to paragraph item type.
    switch (event.dragNode.type) {
      case ChapterItemType.LIBRARY_ENVIGO_TABLE:
      case ChapterItemType.LIBRARY_ENVIGO_STATISTICS:
        itemType = ChapterItemType.TABLE;

        propertyName = 'html';
        propertyValue = libraryItem.html;
        additionalProperties.push(
          {
            name: 'data',
            value: libraryItem.data,
          },
          {
            name: 'payload',
            value: libraryItem.payload,
          },
        );

        break;
      case ChapterItemType.LIBRARY_ENVIGO_CHART: {
        itemType = ChapterItemType.CHART;

        // Chart chapter items use a more complex property scheme.
        propertyName = 'files';
        propertyValue = libraryItem.files;
        additionalProperties.push(
          {
            name: 'data',
            value: libraryItem.data,
          },
          {
            name: 'payload',
            value: libraryItem.payload,
          },
        );

        break;
      }
      case ChapterItemType.LIBRARY_IMPACT:
        itemType = ChapterItemType.IMPACT;
        break;
      case ChapterItemType.LIBRARY_IMAGE:
        itemType = ChapterItemType.IMAGE;

        // Image chapter items use a different property for its content.
        propertyName = 'image';
        propertyValue = {
          id: event.dragNode.image.id,
          uuid: event.dragNode.image.uuid,
          contents: event.dragNode.image.contents,
        };

        break;
      case ChapterItemType.LIBRARY_SPREADSHEET_TABLE:
        itemType = ChapterItemType.SPREADSHEET;
        break;
      case ChapterItemType.LIBRARY_IMPORTED_TABLE:
        itemType = ChapterItemType.IMPORTED_TABLE;
        break;
    }

    this.updateChapterItem({
      type: itemType,
      uuid: this.item.uuid,
      propertyName,
      propertyValue,
      additionalProperties,
    });

    this.item.type = itemType;
    this.libraryContentDropped = true;
  }

  get isExpanded() {
    return this.item && this.item.isExpandedInContent;
  }

  onTitleInput = _debounce((event: string) => {
    this.updateChapterItem({
      uuid: this.item.uuid,
      type: this.item.type,
      propertyName: 'title',
      propertyValue: event,
    });
  }, WS_DEBOUNCE_VALUE);

  onFigureTitleClick() {
    if (this.mode === 'view') {
      this.switchMode('edit');
    }
  }

  onLandscapeValueChange(value: boolean) {
    this.updateChapterItem({
      uuid: this.item.uuid,
      type: this.item.type,
      propertyName: 'landscape',
      propertyValue: value,
    });
  }
}
