import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import api from '@/app/shared/api';
import { VersioningItem } from '@/app/shared/models/Versioning';
import LoaderService from '@/app/shared/utils/loader.service';

dayjs.extend(utc);

@Module({ namespaced: true })
class VersioningModule extends VuexModule {
  objectModel: string;
  objectUuid: string;
  versioningItems: VersioningItem[] = [];
  getVersioningItemsPromise: Promise<void> = null;
  versioningUserFilter: 'internal' | 'authority' | 'public' = 'internal';

  get hasExpandedVersioningItem() {
    return this.versioningItems.some((versioningItem) => versioningItem.is_expanded);
  }

  get filteredVersioningItems() {
    return this.versioningItems.filter((versioningItem) => {
      switch (this.versioningUserFilter) {
        case 'authority':
          return versioningItem.is_authority;
        case 'public':
          return versioningItem.is_public;
        case 'internal':
        default:
          return true;
      }
    });
  }

  @Mutation
  private setVersioningObject(payload: { objectModel: string; objectUuid: string }) {
    this.objectModel = payload.objectModel;
    this.objectUuid = payload.objectUuid;
  }

  @Mutation
  private setGetVersioningItemsPromise(value: Promise<void>): void {
    this.getVersioningItemsPromise = value;
  }

  @Mutation
  private setVersioningItems(versioningItems: VersioningItem[]) {
    this.versioningItems = versioningItems;
  }

  @Mutation
  private setVersioningUserFilter(versioningUserFilter: 'internal' | 'authority' | 'public') {
    this.versioningUserFilter = versioningUserFilter;
  }

  @Action({ rawError: true })
  async getVersioningItems(): Promise<void> {
    // If a versioning items get process is already running, simply wait for it to finish.
    if (this.getVersioningItemsPromise) {
      await this.getVersioningItemsPromise;
      return;
    }

    const getVersioningItemsPromise = async () => {
      try {
        LoaderService.disableHttpLoader();
        const res = await api.versioning.getVersioningItems(this.objectUuid);
        this.context.commit('setVersioningItems', res.items);
      } catch (error) {
        console.warn(error);
      } finally {
        LoaderService.enableHttpLoader();
      }
    };

    // Remember get versioning items promise as a state.
    this.context.commit('setGetVersioningItemsPromise', getVersioningItemsPromise());

    // Wait until get versioning items promise resolves.
    await this.getVersioningItemsPromise;

    // Clear the state with get versioning items promise.
    this.context.commit('setGetVersioningItemsPromise', null);
  }

  @Action({ rawError: true })
  changeUserFilter(userFilter: string): void {
    this.context.dispatch('collapseAll');
    this.context.commit('setVersioningUserFilter', userFilter);
  }

  @Action({ rawError: true })
  async updateVersioningItemName(payload: { versioningItem: VersioningItem; name: string }) {
    this.versioningItems.find((versioningItem) => versioningItem.uuid === payload.versioningItem.uuid).name =
      payload.name;
    await api.versioning.updateStudyVersionName(payload.versioningItem.uuid, { name: payload.name });
  }

  @Action({ rawError: true })
  async updateVersioningItemComment(payload: { versioningItem: VersioningItem; comment: string }) {
    this.versioningItems.find((versioningItem) => versioningItem.uuid === payload.versioningItem.uuid).comment =
      payload.comment;
    await api.versioning.updateStudyVersionComment(payload.versioningItem.uuid, { comment: payload.comment });
  }

  @Action({ rawError: true })
  async toggleVersioningItemExpanded(versioningItem: VersioningItem) {
    const item = this.versioningItems.find((item) => item.uuid === versioningItem.uuid);
    item.is_expanded = !item.is_expanded;
  }

  @Action({ rawError: true })
  async toggleVersioningItemImportant(versioningItem: VersioningItem) {
    const item = this.versioningItems.find((item) => item.uuid === versioningItem.uuid);
    item.is_important = !item.is_important;
    await api.versioning.updateStudyVersionIsImportant(item.uuid, { is_important: item.is_important });
  }

  @Action({ rawError: true })
  async toggleVersioningItemAuthority(versioningItem: VersioningItem) {
    const item = this.versioningItems.find((item) => item.uuid === versioningItem.uuid);
    item.is_authority = !item.is_authority;
    await api.versioning.updateStudyVersionIsAuthority(item.uuid, { is_authority: item.is_authority });
  }

  @Action({ rawError: true })
  async toggleVersioningItemPublic(versioningItem: VersioningItem) {
    const item = this.versioningItems.find((item) => item.uuid === versioningItem.uuid);
    item.is_public = !item.is_public;
    await api.versioning.updateStudyVersionIsPublic(item.uuid, { is_public: item.is_public });
  }

  @Action({ rawError: true })
  async restoreVersioningItem(versioningItem: VersioningItem) {
    if (versioningItem.is_active) return;
    await api.versioning.restoreStudyVersion(versioningItem.uuid).then(() => {
      this.context.dispatch('clearActiveVersions');
      this.versioningItems.find((item) => item.uuid === versioningItem.uuid).is_active = true;
    });
  }

  @Action({ rawError: true })
  async saveVersion(uuid: string) {
    await this.getVersioningItemsPromise;
    await api.versioning.setStudyVersion(uuid).then(() => {
      this.context.dispatch('clearActiveVersions');
    });
  }

  @Action({ rawError: true })
  expandAll() {
    this.versioningItems.forEach((versioningItem) => {
      versioningItem.is_expanded = true;
    });
  }

  @Action({ rawError: true })
  collapseAll() {
    this.versioningItems.forEach((versioningItem) => {
      versioningItem.is_expanded = false;
    });
  }

  @Action({ rawError: true })
  clearActiveVersions() {
    this.versioningItems
      .filter((versioningItem) => versioningItem.is_active)
      .forEach((versioningItem) => {
        versioningItem.is_active = false;
      });
  }
}

export default VersioningModule;
