


































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Sidebar from '@/app/shared/components/Sidebar.vue';
import UtilService from '@/app/shared/utils/util.service';
import { namespace } from 'vuex-class';
import PresentationsSidebarSelectionItem from './PresentationsSidebarSelectionItem.vue';
import PresentationsSidebarOptionsItem from './PresentationsSidebarOptionsItem.vue';
import { PresentationsSidebarOption } from '@/app/shared/models/presentations/PresentationsSidebarOption';
import { CheckTree } from '@/app/shared/models/CheckTree';

const PresentationsStore = namespace('Presentations');

@Component({
  components: {
    Sidebar,
    PresentationsSidebarSelectionItem,
    PresentationsSidebarOptionsItem,
  },
})
export default class PresentationsSidebar extends Vue {
  @Prop() stage: 'screening' | 'scoping' | 'assessing';

  public defaultSidebarWidth = 0;
  public resizeToggle = false;

  // isSelectionExpanded: boolean = true;
  // isOptionsExpanded: boolean = true;

  selectionItems: Array<CheckTree & { expanded: boolean }> = [];
  optionsItems: Array<PresentationsSidebarOption & { expanded: boolean }> = [];

  searchValue: string = '';

  @PresentationsStore.State
  sidebarChecktrees: CheckTree[];

  @PresentationsStore.Getter
  sidebarOptions: PresentationsSidebarOption[];

  get isOptionsSectionVisible() {
    return this.sidebarOptions && this.sidebarOptions.length > 0;
  }

  get isSelectionExpanded() {
    return this.selectionItems.every((el) => el.expanded);
  }

  get isOptionsExpanded() {
    return this.optionsItems.every((el) => el.expanded);
  }

  created() {
    // calculate default sidebar width
    this.defaultSidebarWidth = UtilService.calculateSidebarWidth();
  }

  @Watch('sidebarChecktrees')
  onSidebarChecktreesChange(_newVal: CheckTree[], _oldVal: CheckTree[]) {
    if (this.sidebarChecktrees) {
      this.selectionItems = [
        ...this.sidebarChecktrees.map((el) => ({ ...el, expanded: false })),

        // // FIXME: Sidebar checktrees are temporarily sorted by their numeric `id` values.
        // //  Consider introducing another key for deterministic sorting in a predictable way.
        // .sort((a, b) => (Number(a.id) > Number(b.id) ? 1 : -1)),
      ];
    }
  }

  @Watch('sidebarOptions')
  onSidebarOptionsChange(_newVal: PresentationsSidebarOption[], _oldVal: PresentationsSidebarOption[]) {
    if (this.sidebarOptions) {
      this.optionsItems = [...this.sidebarOptions.map((el) => ({ ...el, expanded: true }))];
    }
  }

  toggleSelectionExpand() {
    let shouldExpand = true;
    if (this.isSelectionExpanded) {
      shouldExpand = false;
    }
    this.selectionItems.forEach((el) => (el.expanded = shouldExpand));
  }

  toggleOptionsExpand() {
    let shouldExpand = true;
    if (this.isOptionsExpanded) {
      shouldExpand = false;
    }
    this.optionsItems.forEach((el) => (el.expanded = shouldExpand));
  }

  emitResizeEvent() {
    this.resizeToggle = !this.resizeToggle;
  }

  toggleExpandAll() {
    if (this.isSelectionExpanded && this.isOptionsExpanded) {
      this.selectionItems.forEach((el) => (el.expanded = false));
      this.optionsItems.forEach((el) => (el.expanded = false));
    } else {
      this.selectionItems.forEach((el) => (el.expanded = true));
      this.optionsItems.forEach((el) => (el.expanded = true));
    }
  }

  // toggleSelectionExpand() {
  //   this.isSelectionExpanded = !this.isSelectionExpanded;
  // }

  // toggleOptionsExpand() {
  //   this.isOptionsExpanded
  // }

  onSearchInput(value: string) {
    const foundSelectionItem = this.sidebarChecktrees
      .map((checktree) => [...checktree.children])
      .reduce((prev, curr) => [...prev, ...curr])
      .find((el) => el.title.toLowerCase() === value.toLowerCase());
    if (foundSelectionItem) {
      this.searchValue = foundSelectionItem.title.toLowerCase();
      return;
    } else {
      if (this.sidebarOptions) {
        const foundOptionsItem = this.sidebarOptions
          .map((checktree) => [...checktree.items])
          .reduce((prev, curr) => [...prev, ...curr])
          .find((el) => el.label.toLowerCase() === value.toLowerCase());
        if (foundOptionsItem) {
          this.searchValue = foundOptionsItem.label.toLowerCase();
          return;
        }
      }
    }
    this.searchValue = null;
  }
}
