










import { SingleImpact } from '@/app/shared/models/reports/SingleImpact';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const ReportsStore = namespace('Reports');

@Component({
  components: {},
})
export default class SingleImpactNavigationBar extends Vue {
  @ReportsStore.Getter
  getSingleImpact!: SingleImpact;

  back() {
    //TODO:
  }

  backToRoot() {
    //TODO:
  }

  next() {
    //TODO:
  }

  addToLibrary() {
    //TODO:
  }
}
