




















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DialogService from '@/app/shared/utils/dialog.service';
import MatrixRelationField from '@/app/shared/models/MatrixRelationField';
import MatrixRelationMatrix from '@/app/shared/models/MatrixRelationMatrix';

const MatrixRelationsStore = namespace('MatrixRelations');
const UserStore = namespace('User');

@Component
export default class MatrixRelationsFieldItemFirstType extends Vue {
  @Prop() i: number;
  @Prop() j: number;
  @Prop() rowItem: MatrixRelationField;
  @Prop() columnItem: MatrixRelationField;
  @Prop() rerender: boolean;
  @Prop() numOfParentItems: number;

  @MatrixRelationsStore.Getter
  public matrixRelationsData: MatrixRelationMatrix;

  @MatrixRelationsStore.Mutation
  public itemClick!: (data: {
    value: boolean;
    i: number;
    j: number;
    rowItem: MatrixRelationField;
    columnItem: MatrixRelationField;
    matrixUuid: string;
    isFromWebsocket: boolean;
  }) => void;

  @UserStore.Getter
  skipConfirmationDialog: () => boolean;

  @UserStore.Mutation
  setSkipConfirmationDialog: (value: boolean) => void;

  cellClick(value: boolean) {
    if (
      this.numOfParentItems > 0 &&
      ((this.rowItem.isParent && this.rowItem.level === 0) ||
        (this.columnItem.isParent && this.columnItem.level === 0)) &&
      !this.skipConfirmationDialog
    ) {
      const confirmCallback = (context: { checkboxValue: boolean }) => {
        this.setSkipConfirmationDialog(context.checkboxValue);
        this.clickItem(value);
      };

      DialogService.presentDialog(
        // eslint-disable-next-line no-irregular-whitespace
        `Are you sure you want to ${!value ? 'select' : 'deselect'} all?`,
        'This action will immediately update the existing list of items in the following modules.',
        'Yes',
        'Cancel',
        confirmCallback,
        () => {
          //
        },
        'Do not show me this again',
      );
      return;
    }

    this.clickItem(value);
  }

  clickItem(value: boolean) {
    this.itemClick({
      value: value,
      i: this.i,
      j: this.j,
      rowItem: this.rowItem,
      columnItem: this.columnItem,
      matrixUuid: this.$route.params.uuid,
      isFromWebsocket: false,
    });

    // TODO: This speeds up the click process. Still need to figure out why since watch function is empty.
    this.$emit('rerender-items');
  }

  @Watch('rerender')
  updateComponent() {
    // this.$forceUpdate();
  }
}
