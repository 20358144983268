














import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import PageHeader from '@/app/shared/components/PageHeader.vue';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

const UserStore = namespace('User');

@Component({
  components: {
    PageHeader,
  },
})
export default class WorkspacePageHeader extends Vue {
  @Prop() title: string;

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  @Emit()
  save() {
    //
  }

  @AsyncComputed()
  async isSaveVersionAllowed() {
    return await this.isAllowed(UserPermission.WORKSPACE_SAVE_VERSION_BUTTON);
  }
}
