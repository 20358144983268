


































import { Component, Mixins, Vue } from 'vue-property-decorator';
import { BaseControlConfigSkeleton } from 'v-form-builder';

@Component
export default class FormConfigPassword extends Mixins(Vue, BaseControlConfigSkeleton) {}
