




import { Component, Mixins, Vue } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';

@Component
export default class FormFieldDivider extends Mixins(Vue, BaseControlSkeleton) {}
