















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TrackingBadge extends Vue {
  @Prop() type: 'locked' | 'updated';
}
