var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-column pdf-output-report-content"},[(_vm.isGenerating || _vm.isDownloading)?_c('div',{staticClass:"d-flex flex-column pdf-output-report-content__progress"},[_c('v-progress-linear',{staticClass:"pdf-output-report-content__progress-bar",attrs:{"value":_vm.isDownloading ? _vm.report.download_progress : null,"indeterminate":_vm.isGenerating,"reverse":_vm.isGenerating,"color":"blue","background-color":"gray","rounded":""}}),_c('div',{staticClass:"pdf-output-report-content__progress-text"},[(_vm.isGenerating)?[_vm._v("Generating...")]:(_vm.isDownloading)?[_vm._v("Downloading..."+_vm._s(_vm.report.download_progress)+"%")]:_vm._e()],2)],1):_vm._e(),(
      (_vm.isError && _vm.isErrorMessageVisible) ||
        (_vm.isGenerated && !_vm.isDownloading && _vm.isGeneratedMessageVisible) ||
        (_vm.isDownloaded && _vm.isDownloadedMessageVisible)
    )?_c('div',{staticClass:"d-flex pdf-output-report-content__message"},[_c('div',{staticClass:"d-flex align-center justify-center flex-grow-1 pdf-output-report-content__message-text"},[(_vm.isError)?_c('v-icon',{staticClass:"pdf-output-report-content__error-icon"},[_vm._v("$error")]):_c('v-icon',[_vm._v("$checkCircle")]),(_vm.isError && !_vm.isGenerated && !_vm.isDownloading && !_vm.isDownloaded)?[_vm._v(" Error generating file, please try again. ")]:(_vm.isGenerated && !_vm.isDownloading && !_vm.isDownloaded)?[_vm._v(" File generated successfully! ")]:(_vm.isDownloaded)?[_vm._v(" File downloaded successfully! ")]:_vm._e()],2),_c('BaseButton',{staticClass:"flex-shrink-0",attrs:{"type":"icon-septim"},on:{"click":function($event){return _vm.dismissMessage(_vm.isError, _vm.isGenerated, _vm.isDownloaded)}}},[_c('v-icon',[_vm._v("$close")])],1)],1):_vm._e(),(_vm.isEditingName)?_c('BaseTextField',{ref:"editName",attrs:{"value":_vm.report.name,"placeholder":"Enter name","type":"title","is-dark":""},on:{"input":_vm.updateName,"blur":function($event){_vm.isEditingName = false}},nativeOn:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.isEditingName = false},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.isEditingName = false}]}}):_c('div',{staticClass:"pdf-output-report-content__name",class:{
      'pdf-output-report-content__name--is-disabled': !_vm.isToolbarAllowed,
    },attrs:{"tabindex":"0"},on:{"click":_vm.editName}},[_vm._v(" "+_vm._s(_vm.report.name)+" ")]),(_vm.isEditingComment)?_c('BaseTextArea',{ref:"editComment",attrs:{"value":_vm.report.comment,"label":"Enter comment","is-dark":""},on:{"input":_vm.updateComment,"blur":_vm.exitEditComment},nativeOn:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.exitEditComment.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.exitEditComment.apply(null, arguments)}]}}):(_vm.isCommentVisible)?_c('div',{staticClass:"pdf-output-report-content__comment",class:{
      'pdf-output-report-content__comment--is-disabled': !_vm.isToolbarAllowed,
    },attrs:{"tabindex":"0"},on:{"click":_vm.editComment}},[_vm._v(" "+_vm._s(_vm.report.comment)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex align-center justify-start pdf-output-report-content__toolbar"},[(_vm.isToolbarAllowed || _vm.report.comment)?_c('BaseButton',{staticClass:"flex-shrink-0 pdf-output-report-content__comment-button",class:{
        'pdf-output-report-content__comment-button--is-visible': _vm.isCommentVisible,
      },attrs:{"type":"icon-light"},on:{"click":_vm.toggleComment}},[_c('v-icon',{staticClass:"outlined"},[_vm._v("$comment-2px")]),_c('v-icon',{staticClass:"filled"},[_vm._v("$comment-solid")])],1):_vm._e(),(_vm.isToolbarAllowed || _vm.report.is_important)?_c('VersioningStar',{attrs:{"is-starred":_vm.report.is_important,"is-disabled":!_vm.isToolbarAllowed},on:{"update":_vm.toggleImportant}}):_vm._e(),(_vm.isDeleteAllowed)?_c('BaseButton',{staticClass:"flex-shrink-0",attrs:{"type":"icon-septim"},on:{"click":_vm.deleteReport}},[_c('v-icon',[_vm._v("$delete")])],1):_vm._e(),(_vm.isToolbarAllowed)?_c('BaseSwitch',{staticClass:"pdf-output-report-content__authority-switch",attrs:{"value":_vm.report.is_authority,"is-dark":""},on:{"change":_vm.toggleAuthority}},[_vm._v(" Authorities ")]):_vm._e(),(_vm.isToolbarAllowed)?_c('BaseSwitch',{staticClass:"pdf-output-report-content__public-switch",attrs:{"value":_vm.report.is_public,"is-dark":""},on:{"change":_vm.togglePublic}},[_vm._v(" Public ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }