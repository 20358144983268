import loginFormSchema from '@/app/shared/components/form/schema/login.json';
import forgotPasswordFormSchema from '@/app/shared/components/form/schema/forgot-password.json';
import resetPasswordFormSchema from '@/app/shared/components/form/schema/reset-password.json';
import { FormList } from '@/app/shared/models/form/FormSchema';

export default [
  {
    id: 'login',
    label: 'Login',
    schema: loginFormSchema,
  },
  {
    id: 'forgot-password',
    label: 'Forgot Password',
    schema: forgotPasswordFormSchema,
  },
  {
    id: 'reset-password',
    label: 'Reset Password',
    schema: resetPasswordFormSchema,
  },
] as FormList[];
