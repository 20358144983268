import { User } from '@/app/shared/models/User';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import { FileResource } from '../File';

export const TEMPORARY_UUID_PREFIX = '__temporary';

export class ChapterItem {
  uuid: string;
  checktree_id: number;
  help: string;
  html: string;
  id: number;
  level: number;
  locked_by: { id: number; model: string; person: User; person_id: number; uuid: string };
  order: number;
  parent_id: number;
  selection: string;
  text: string;
  title: string;
  name: string;
  type: ChapterItemType;
  children: ChapterItem[];
  image: {
    id: number;
    uuid: string;
    contents: string;
  };
  landscape?: boolean;
  file_id: number;
  apply_synclocker: boolean;
  custom_title: string;
  comment?: string;
  files?: FileResource[];
  file_ids?: number[];
  data?: any;
  payload?: any;

  isCurrent: boolean = false;
  numeration?: string;

  // plugin he-tree specific node property
  $folded?: boolean;

  // front-end specific
  isExpandedInContent?: boolean;

  constructor(type: ChapterItemType, level: number) {
    // temporary uuid
    this.uuid =
      TEMPORARY_UUID_PREFIX +
      '-' +
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15);
    this.type = type;
    this.level = level;
    this.title = '';
    this.text = '';
    this.html = '';
    this.selection = '';
    this.file_id = null;
    this.locked_by = null;
    this.children = [];
    this.image = {
      id: null,
      uuid: null,
      contents: null,
    };
    this.numeration = null;
    this.isExpandedInContent = true;
    this.landscape = false;
    this.apply_synclocker = true;
    this.custom_title = null;
  }
}
