






























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import CommentingBadge from './CommentingBadge.vue';
import CommentingReplies from './CommentingReplies.vue';
import CommentingResolution from './CommentingResolution.vue';
import CommentingStar from './CommentingStar.vue';
import UserAvatar from '@/app/shared/components/UserAvatar.vue';
import { Comment, CommentReply, DraftComment } from '@/app/shared/models/Comment';

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const CommentsStore = namespace('Comments');
const UserStore = namespace('User');

@Component({
  components: {
    CommentingBadge,
    CommentingReplies,
    CommentingResolution,
    CommentingStar,
    UserAvatar,
  },
})
export default class CommentingCommentHeader extends Vue {
  @Prop() comment: Comment | CommentReply | DraftComment;
  @Prop({ default: false, type: Boolean }) isExpanded: boolean;
  @Prop({ default: false, type: Boolean }) isLinking: boolean;
  @Prop({ default: false, type: Boolean }) hideButtons: boolean;
  menuItems = [
    {
      text: 'Edit',
      value: 'edit',
    },
    {
      text: 'Delete',
      value: 'delete',
    },
  ];
  displayFormat: string = 'D MMM YYYY HH:mm';

  @Emit()
  link() {
    //
  }

  @Emit()
  edit() {
    //
  }

  @Emit()
  delete() {
    //
  }

  @CommentsStore.Action
  toggleImportantComment: (payload: Comment) => void;

  @CommentsStore.Action
  resolveComment: (payload: Comment) => void;

  @UserStore.Getter
  userId: number;

  get isDraftComment() {
    return !(this.comment as Comment).created;
  }

  get isParent() {
    return !(this.comment as CommentReply).comment_uuid;
  }

  get isOwnComment() {
    return this.comment.user?.id === this.userId;
  }

  get commentTimestamp() {
    const commentDateKey = this.isDraftComment ? 'date' : 'created';
    const commentDate = dayjs.utc(this.comment[commentDateKey as never]).local();
    if (dayjs().diff(commentDate, 'hour') < 22) return commentDate.fromNow();
    return commentDate.format(this.displayFormat);
  }

  triggerMenuItem(menuItem: string) {
    switch (menuItem) {
      case 'edit':
        this.edit();
        break;
      case 'delete':
        this.delete();
        break;
    }
  }
}
