import FormConfigPassword from '@/app/shared/components/form/config/FormConfigPassword.vue';
import FormFieldPassword from '@/app/shared/components/form/field/FormFieldPassword.vue';

export default {
  name: 'Password Control',
  description: 'Secret text input',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldPassword,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigPassword,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    isDisabled: false,
    isReadonly: false,
    hasToggleIcon: true,
    helpText: '',

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {string}
   */
  rendererDefaultData() {
    return '';
  },
};
