













import { Component, Vue, Prop } from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';
import Loader from '@/app/shared/components/Loader.vue';
import { CheckTree } from '@/app/shared/models/CheckTree';
import CriteriaGroup from '../../CriteriaGroup.vue';
import { UnitFactor } from '@/app/shared/models/calculations/UnitFactor';

@Component({
  components: { editor: Editor, Loader, CriteriaGroup },
})
export default class FactorsTab extends Vue {
  @Prop() criteriaList: CheckTree;
  @Prop() factors: UnitFactor[];
  @Prop() unitUuid: string;

  // get sections() {
  //   return this.criteriaList.children;
  // }
}
