import FormConfigOrganization from '@/app/shared/components/form/config/FormConfigOrganization.vue';
import FormFieldOrganization from '@/app/shared/components/form/field/FormFieldOrganization.vue';
import { Organization } from '@/app/shared/models/form/field/FormFieldOrganization';
import { FormFieldValue, FormFieldValueModel } from '@/app/shared/models/form/field';

export default {
  name: 'Organization Control',
  description: 'Complex input for organization info',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldOrganization,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigOrganization,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    helpText: '',
    isDisabled: false,
    isReadonly: false,
    isMultiple: false,

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {FormFieldValue<Organization>}
   */
  rendererDefaultData(): FormFieldValue<Organization> {
    return new FormFieldValue([new Organization()], FormFieldValueModel.ORGANIZATION);
  },
};
