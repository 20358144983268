var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-input d-flex form-field-upload-inline",class:{
    'v-input--is-disabled': _vm.control.isDisabled,
    'v-input--dense': _vm.control.isSmall,
    'flex-column': _vm.uploadResults.length,
  }},[_c('FormFieldUpload',{directives:[{name:"show",rawName:"v-show",value:(!_vm.uploadResults.length),expression:"!uploadResults.length"}],ref:"formField",attrs:{"value":_vm.uploadResults,"control":Object.assign({}, _vm.control,
      {isMultiple: false,
      isInline: true,
      acceptType: 'image/*'})},on:{"change":function($event){_vm.uploadResults = $event}}}),(_vm.uploadResults.length)?[(_vm.control.label)?_c('div',{staticClass:"v-input__prepend-outer"},[_c('label',{attrs:{"for":_vm.control.uniqueId}},[_vm._v(_vm._s(_vm.label))]),(_vm.control.helpText)?_c('v-icon',{staticClass:"form-field-label__help-icon",attrs:{"aria-label":_vm.control.helpText},on:{"click":function($event){return _vm.showHelpTooltip(_vm.control.helpText, $event)}}},[_vm._v(" $help ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"d-flex flex-wrap align-end"},[_vm._l((_vm.uploadResults),function(uploadResult,index){return [_c('img',{key:("upload-preview-image-" + index),staticClass:"form-field-upload-inline__preview-image",attrs:{"src":uploadResult.uploadPreview,"alt":"Preview","tabindex":"0"},on:{"click":_vm.showBrowseDialog,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.showBrowseDialog.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.showBrowseDialog.apply(null, arguments)}]}}),_c('div',{key:("upload-preview-remove-" + index),staticClass:"form-field-upload-inline__preview-remove"},[_c('BaseButton',{attrs:{"disabled":_vm.control.isDisabled,"data-cy":("preview-remove-" + (_vm.control.name || _vm.control.uniqueId)),"type":"icon-light"},on:{"click":function($event){return _vm.removeUpload(uploadResult, index)}}},[_c('v-icon',[_vm._v("$delete")])],1)],1),(uploadResult.uploadError)?_c('div',{key:("upload-error-" + index),staticClass:"error--text form-field-upload-inline__upload-error"},[_vm._v(" "+_vm._s(uploadResult.uploadError)+" ")]):_vm._e()]})],2)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }