import FormConfigCheckbox from '@/app/shared/components/form/config/FormConfigCheckbox.vue';
import FormFieldCheckbox from '@/app/shared/components/form/field/FormFieldCheckbox.vue';

export default {
  name: 'Checkbox Control',
  description: 'Simple tick box',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldCheckbox,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigCheckbox,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    isDisabled: false,
    disabledOnValue: '',

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {boolean}
   */
  rendererDefaultData(): boolean {
    return false;
  },
};
