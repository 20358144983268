import FormConfigCaptcha from '@/app/shared/components/form/config/FormConfigCaptcha.vue';
import FormFieldCaptcha from '@/app/shared/components/form/field/FormFieldCaptcha.vue';

export default {
  name: 'reCAPTCHA Control',
  description: '"I’m not a robot" check box',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldCaptcha,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigCaptcha,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the control in renderer
   * @returns {string}
   */
  rendererDefaultData(): string {
    return '';
  },
};
