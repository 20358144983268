













































import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FormRenderer from '@/app/shared/components/form/FormRenderer.vue';
import ExpandableSidebarRoute from '@/app/shared/components/mixins/ExpandableSidebarRoute.vue';
import loginFormSchema from '@/app/shared/components/form/schema/login.json';
import forgotPasswordFormSchema from '@/app/shared/components/form/schema/forgot-password.json';
import { AxiosError } from 'axios';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import store from '@/app/shared/store';
import api from '@/app/shared/api';

const UserStore = namespace('User');

@Component({
  components: {
    FormRenderer,
  },
})
export default class Login extends ExpandableSidebarRoute {
  @Prop() mode?: 'recover' | undefined;
  isRecovered: boolean = false;
  serverError: AxiosError = null;
  redirectUri: string = null;

  @UserStore.Getter
  public arePermissionsLoaded: boolean;

  @UserStore.Mutation
  public setIsLoginInteractive: (isLoginInteractive: boolean) => void;

  @UserStore.Action
  public isAllowed: (permission: string | string[]) => Promise<boolean>;

  @UserStore.Action
  public loginUser!: (payload: { username: string; password: string }) => Promise<boolean>;

  @Watch('mode')
  onModeChange() {
    this.isRecovered = false;
  }

  get loginFormSchema() {
    return loginFormSchema;
  }

  get forgotPasswordFormSchema() {
    return forgotPasswordFormSchema;
  }

  created() {
    // Listen for WS messages.
    eventBus.$on(EventType.UNAUTHORIZED, () => {
      // This will trigger a session check for everyone who is on WS, not only the one who sends the message.
      // All expired sessions will be canceled. In the case of many expired sessions and simultaneous requests, it may slow down.
      store.dispatch('User/checkSession');
    });

    this.redirectUri = this.$route.query.redirect as string;
  }

  async login(values: { username: string; password: string }) {
    this.serverError = null;

    try {
      this.setIsLoginInteractive(true);

      await this.loginUser(values);

      // console.log('Logged in as:', values.username);

      let targetRoute = '/error/404';

      // Redirect user to the correct landing screen depending on their request and permissions.
      if (this.redirectUri) targetRoute = this.redirectUri;
      else if (await this.isAllowed(UserPermission.ADMIN_LANDING_ACCESS)) targetRoute = '/';
      else if (await this.isAllowed(UserPermission.LANDING_ACCESS)) targetRoute = '/landing';

      await this.$router.push(targetRoute);
    } catch (error) {
      if ((error as AxiosError).isAxiosError) this.serverError = error as AxiosError;
    } finally {
      this.setIsLoginInteractive(false);
    }
  }

  loginSso(payload: { values: { remember_me_sso: boolean } }) {
    console.log('SSO LOGIN!', payload.values.remember_me_sso);
  }

  loginGuest() {
    console.log('GUEST LOGIN!');
  }

  async sendRecoveryEmail(values: { username: string }) {
    try {
      await api.auth.emailPasswordRecover(values.username);
      this.isRecovered = true;
    } catch (error) {
      // @TODO Display backend validation messages on the form.
    }
  }

  goToLogin() {
    this.$router.replace('/login');
  }
}
