































import { Component, Mixins, Vue } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import FormFieldText from '@/app/shared/components/form/field/FormFieldText.vue';
import FormFieldFocus from '@/app/shared/components/form/field/mixins/FormFieldFocus.vue';

dayjs.extend(customParseFormat);

@Component({
  components: {
    FormFieldText,
  },
})
export default class FormFieldDate extends Mixins(Vue, FormFieldFocus, BaseControlSkeleton) {
  value: string;
  isCalendarShown: boolean = false;
  valueFormat: string = 'YYYY-MM-DD';
  inputFormat: string = 'DD/MM/YYYY';
  updateValue: (value: string) => void;

  get inputValue() {
    const date = dayjs(this.value, this.valueFormat);
    if (!date.isValid()) return null;
    return date.format(this.inputFormat);
  }

  set inputValue(value: string) {
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value)) {
      this.updateValue(null);
      return;
    }

    const date = dayjs(value, this.inputFormat);
    if (!date.isValid()) return;
    this.updateValue(date.format(this.valueFormat));
  }

  showCalendar() {
    this.isCalendarShown = true;
  }

  hideCalendar() {
    this.isCalendarShown = false;
  }

  setValue(value: string) {
    this.updateValue(value);
    this.hideCalendar();
  }
}
