
import { Component, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ExpandableSidebarForm from '@/app/shared/components/mixins/ExpandableSidebarForm.vue';
import organizationFormList from '@/app/shared/components/form/list/organization';
import { FormList } from '@/app/shared/models/form/FormSchema';
import { Organization } from '@/app/shared/models/Organization';
import api from '@/app/shared/api';

@Component
export default class OrganizationForm extends ExpandableSidebarForm {
  @Prop() uuid!: string;
  organization: Organization = null;
  defaultValues = {};
  formList: FormList[] = [];
  serverError: AxiosError = null;

  get formDefaultValues() {
    switch (this.formId) {
      case 'organization-create':
        return {};
      case 'invite-new-user':
        return {
          organization_uuid: this.organization.uuid,
          organization_name: this.organization.name,
        };
      default:
      case 'organization-setup':
      case 'saas-details':
        return this.defaultValues;
    }
  }

  get formSubmitButtonLabel() {
    if (this.formId === 'invite-new-user') return 'Invite';
    return 'Save';
  }

  get isFormShown() {
    return this.isFormAccessAllowed && (this.uuid === 'new' || Boolean(this.organization));
  }

  get hasFormSubmitButton() {
    return true;
  }

  get backRoute() {
    switch (this.formId) {
      case 'organization-create':
        return '/organizations';
      default:
        return '';
    }
  }

  async created() {
    this.formList = organizationFormList;

    if (!(await this.checkFormPermission())) return;

    try {
      if (this.uuid !== 'new') this.organization = await api.organization.getOrganization(this.uuid);

      switch (this.formId) {
        case 'organization-setup':
          this.defaultValues = await api.organization.loadOrganizationSetupForm(this.uuid);
          break;
        case 'saas-details':
          this.defaultValues = await api.organization.loadSaasDetailsForm(this.uuid);
          break;
      }
    } catch (error) {
      console.warn(error);
    }
  }

  async onSubmit(payload: any) {
    this.serverError = null;

    try {
      switch (this.formId) {
        case 'organization-create':
          await api.organization.submitOrganizationCreateForm(payload);
          break;
        case 'organization-setup':
          await api.organization.submitOrganizationSetupForm(this.uuid, payload);
          break;
        case 'invite-new-user':
          await api.organization.submitInviteNewUserForm(this.uuid, payload);
          break;
        case 'saas-details':
          await api.organization.submitSaasDetailsForm(this.uuid, payload);
          break;
      }

      await this.redirectOnSuccess();
    } catch (error) {
      this.serverError = error as AxiosError;
    }
  }
}
