import { /*AssessingTableSelection,*/ StatisticsData } from '@/app/shared/store/modules/presentations.store-module';
import httpClient from '@/app/shared/api/axios.config';
import { SingleImpact } from '@/app/shared/models/reports/SingleImpact';
// import { UnitList } from '@/app/shared/models/calculations/UnitList';
import { LibraryParagraph } from '@/app/shared/models/presentations/LibraryParagraph';
import { CheckTree, CheckTreeSelection } from '@/app/shared/models/CheckTree';
import { ModuleProperties } from '@/app/shared/models/Module';
import { PresentationsChartsType, PresentationsTableType } from '@/app/shared/types/presentations.types';
import { PresentationsSidebarOptionSelection } from '@/app/shared/models/presentations/PresentationsSidebarOption';
import { ChartDataResponse } from '@/app/shared/models/presentations/Charts';

const PRESENTATION_END_POINT = '/presentation';
const LIBRARY_END_POINT = '/library';

export default {
  async getTableData(payload: {
    checktreeSelection: CheckTreeSelection[];
    tableType: PresentationsTableType;
    options: PresentationsSidebarOptionSelection;
    module: ModuleProperties;
    unitListID?: number;
  }) {
    return await httpClient.post(`${PRESENTATION_END_POINT}/tables`, {
      display: {
        type: payload.tableType,
        unitlist_id: payload.unitListID,
      },
      module: {
        uuid: payload.module.module_uuid,
        type: payload.module.module_type,
        study_uuid: payload.module.study_uuid,
      },
      filters: {
        checktrees: payload.checktreeSelection,
        options: payload.options,
      },
    });
  },
  async getImpactsSelection(data: CheckTree[], module: ModuleProperties) {
    const res: SingleImpact[] = await httpClient.post(`${PRESENTATION_END_POINT}/impacts`, {
      module: {
        uuid: module.module_uuid,
        type: module.module_type,
        study_uuid: module.study_uuid,
      },
      filters: {
        checktrees: data,
        options: [],
      },
    });
    return res;
  },
  async addLibraryParagraph(data: LibraryParagraph) {
    const res: any = await httpClient.put(`${LIBRARY_END_POINT}/`, data);
    return res;
  },
  async addImpactToLibrary(impactUuid: string) {
    const res: SingleImpact = await httpClient.put(`${LIBRARY_END_POINT}/impact/${impactUuid}`);
    return res;
  },
  async deleteImpactFromLibrary(impactUuid: string) {
    const res: SingleImpact = await httpClient.delete(`${LIBRARY_END_POINT}/impact/${impactUuid}`);
    return res;
  },
  async getStatsSelection(
    data: CheckTree[],
    statsType: 'count' | 'mean' | 'variance',
    rowOption: any,
    columnOption: any,
    module: ModuleProperties,
  ) {
    // @TODO
    const res: StatisticsData = await httpClient.post(`${PRESENTATION_END_POINT}/statistics/${statsType}`, {
      module: {
        uuid: module.module_uuid,
        type: module.module_type,
        study_uuid: module.study_uuid,
      },
      filters: {
        checktrees: data,
        options: { row: rowOption, column: columnOption },
      },
    });
    return res;
  },

  async getChartData(payload: {
    checktreeSelection: CheckTreeSelection[];
    chartType: PresentationsChartsType;
    options: PresentationsSidebarOptionSelection;
    module: ModuleProperties;
  }): Promise<ChartDataResponse> {
    return await httpClient.post(`${PRESENTATION_END_POINT}/charts`, {
      display: {
        type: payload.chartType,
      },
      module: {
        uuid: payload.module.module_uuid,
        type: payload.module.module_type,
        study_uuid: payload.module.study_uuid,
      },
      filters: {
        checktrees: payload.checktreeSelection,
        options: payload.options,
      },
    });
  },
};
