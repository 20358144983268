

































































import { CheckTree } from '@/app/shared/models/CheckTree';
import CheckTreeItem from '@/app/shared/models/CheckTreeItem';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

export interface TagChange {
  checktreeItemUuid: string;
  value: boolean;
}

export type TagsChangeEvent = TagChange[];

@Component({
  components: {},
})
export default class CalculationsDataTableTagsField extends Vue {
  @Prop() checktree: CheckTree;
  @Prop() hasSelectionMenu: boolean;

  isMenuVisible: boolean = false;
  tagsForChange: TagChange[] = [];

  radioButtonGroupValue: string = null;

  @Emit('change')
  change(_event: TagsChangeEvent) {
    // just emiting event
  }

  created() {
    this.radioButtonGroupValue = this.radioButtonGroupCheckedItem ? this.radioButtonGroupCheckedItem.value : null;
  }

  get allTags() {
    return this.checktree ? this.checktree.children : [];
  }

  get chosenTags() {
    return this.checktree
      ? this.checktree.children
          .filter((el) => el.checked)
          .map((el) => el.title)
          .join(' | ')
      : null;
  }

  get radioButtonGroupOptions() {
    return this.allTags.map((el) => {
      return {
        label: el.title,
        value: el.uuid,
      };
    });
  }

  get radioButtonGroupCheckedItem() {
    const checked = this.allTags
      .filter((el) => el.checked)
      .map((el) => {
        return {
          label: el.title,
          value: el.uuid,
        };
      });
    return checked ? checked[0] : null;
  }

  onTagCheckboxChange(item: CheckTreeItem, value: boolean) {
    const index = this.tagsForChange.findIndex((el) => el.checktreeItemUuid === item.uuid);
    if (index > -1) {
      this.tagsForChange[index].value = value;
    } else {
      this.tagsForChange.push({ checktreeItemUuid: item.uuid, value: value });
    }
  }

  onRadioGroupValueChange(event: string) {
    this.radioButtonGroupValue = event;
    if (this.tagsForChange.length > 0) {
      this.tagsForChange.pop();
    } else {
      // this.tagsForChange[0] - always previously checked item
      this.tagsForChange.push({ checktreeItemUuid: this.radioButtonGroupCheckedItem.value, value: false });
    }
    this.tagsForChange.push({ checktreeItemUuid: event, value: true });
    if (this.tagsForChange[0].checktreeItemUuid === this.tagsForChange[1].checktreeItemUuid) {
      this.tagsForChange = [];
    }
    // console.log(this.tagsForChange);
  }

  onConfirm() {
    this.change(this.tagsForChange);
    this.clearState();
  }

  onCancel() {
    this.clearState();
  }

  clearState() {
    this.tagsForChange = [];
    this.isMenuVisible = false;
    // if (this.checktree.behave_as === 'radio') {
    //   this.radioButtonGroupValue = this.radioButtonGroupCheckedItem.value;
    // }
  }
}
