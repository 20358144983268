




















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CalculationsDataTableTitleField extends Vue {
  @Prop() title: string;
  @Prop() color: string;
  @Prop() isLink: boolean;
  @Prop() assessStatus: 'default' | 'in-progress' | 'done' | 'new' | 'locked';

  @Emit('click')
  click() {
    // emit
  }

  @Emit()
  unlock() {
    //
  }

  get progressIcon() {
    switch (this.assessStatus) {
      case 'done':
        return 'approved-16px';
      case 'in-progress':
        return 'in-progress-16px';
      case 'new':
        return 'new-16px';
      default:
        return 'error';
    }
  }
}
