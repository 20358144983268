import organizationCreateFormSchema from '@/app/shared/components/form/schema/organization-create.json';
import organizationSetupFormSchema from '@/app/shared/components/form/schema/organization-setup.json';
import saasDetailsFormSchema from '@/app/shared/components/form/schema/saas-details.json';
import inviteNewUserFormSchema from '@/app/shared/components/form/schema/invite-new-user.json';
import { FormList } from '@/app/shared/models/form/FormSchema';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

export default [
  {
    id: 'organization-create',
    label: 'Create Organization',
    backgroundType: 'organization-setup',
    schema: organizationCreateFormSchema,
    permission: UserPermission.CREATE_ORGANIZATION_FORM_ACCESS,
  },
  {
    id: 'organization-setup',
    label: 'Organization Setup',
    schema: organizationSetupFormSchema,
    permission: UserPermission.EDIT_ORGANIZATION_DETAILS_ACCESS,
  },
  {
    id: 'saas-details',
    label: 'SaaS Details',
    schema: saasDetailsFormSchema,
    permission: UserPermission.SAAS_DETAILS_FORM_ACCESS,
  },
  {
    id: 'invite-new-user',
    label: 'Invite New User',
    schema: inviteNewUserFormSchema,
    permission: UserPermission.INVITE_NEW_USER_ACCESS,
  },
] as FormList[];
