





















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaseDropdown extends Vue {
  @Prop() value: string;
  @Prop() items: { text: string; value: string | number }[];
  @Prop() disabled: boolean;
  @Prop({ default: false, type: Boolean }) isOverflowMenu: boolean;
  isExpanded: boolean = false;

  @Emit()
  input(_value: boolean) {
    //
  }

  get label() {
    return this.value && this.items.find((item) => item.value === this.value).text;
  }
}
