























import Vue from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { ChapterItem } from '@/app/shared/models/reports/ChapterItem';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import { ChapterItemMode } from '@/app/shared/types/chapter-item-mode.type';
import ChapterItemComponent from '@/app/reports/components/ChapterItem/ChapterItem.vue';
import ImpactTable from '@/app/shared/components/ImpactTable.vue';
import _debounce from 'lodash.debounce';
import { UpdateChapterItemPayload, WS_DEBOUNCE_VALUE } from '@/app/shared/store/modules/reports.store-module';

import { SingleImpact } from '@/app/shared/models/reports/SingleImpact';

@Component({
  components: { ChapterItemComponent, ImpactTable },
})
export default class SingleImpactHeaderChapterItem extends Vue {
  @Prop() singleImpact: SingleImpact;
  @Prop() item: ChapterItem;
  @Prop({ default: false, type: Boolean }) isEditModeAllowed: boolean;

  ChapterItemType: ChapterItemType;

  title: string = null;
  @Emit('switchMode')
  switchMode(_newMode: ChapterItemMode) {
    // just emiting event
  }

  @Emit('update')
  updateChapterItem(_payload: UpdateChapterItemPayload) {
    // just emiting event
  }

  get alternativesRow(): string {
    return this.singleImpact.unit.grouped_tags.alternatives.children
      .filter((el) => el.checked)
      .map((el) => el.title)
      .join(' | ');
  }

  get phasesRow(): string {
    return this.singleImpact.unit.grouped_tags['development-phases'].children
      .filter((el) => el.checked)
      .map((el) => el.title)
      .join(' | ');
  }

  get signCol(): string {
    return this.singleImpact.unit.grouped_tags.sign.children
      .filter((el) => el.checked)
      .map((el) => el.title)
      .join(' | ');
  }

  get typeCol(): string {
    return this.singleImpact.unit.grouped_tags.type.children
      .filter((el) => el.checked)
      .map((el) => el.title)
      .join(' | ');
  }

  get classCol(): string {
    return this.singleImpact.unit.grouped_tags['method-approach'].children
      .filter((el) => el.checked)
      .map((el) => el.title)
      .join(' | ');
  }

  onTitleInput = _debounce((_event: string) => {
    // this.updateChapterItem({
    //   uuid: this.item.uuid,
    //   type: ChapterItemType.HEADING,
    //   propertyName: 'title',
    //   propertyValue: event,
    // });
  }, WS_DEBOUNCE_VALUE);
}
