























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import CheckTreeItem from '@/app/shared/models/CheckTreeItem';

@Component({
  components: {},
})
export default class PresentationsSidebarSelectionChildItem extends Vue {
  @Prop() item: CheckTreeItem;
  @Prop() enumeration: number;
  shouldShowPopover: boolean = null;

  @Emit('change')
  change(_event: any) {
    // emiting event
  }

  mounted() {
    setTimeout(() => {
      const el: HTMLElement = this.$refs.itemTitle as HTMLElement;
      if (el.scrollWidth > el.offsetWidth) {
        this.shouldShowPopover = true;
      } else {
        this.shouldShowPopover = false;
      }
    }, 0);
  }
}
