
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CreateElement, VNode } from 'vue';

@Component
export default class BaseTextWithLinks extends Vue {
  @Prop() text: string;

  render(createElement: CreateElement): VNode {
    const elements: VNode[] = [];

    const originalString = this.text;

    const breakRegex = /<br\s*\/?>/gi;

    let match;
    let lastIndex = 0;

    while ((match = breakRegex.exec(originalString)) !== null) {
      const preceedingText = originalString.substring(lastIndex, match.index);

      lastIndex = breakRegex.lastIndex;

      elements.push(createElement('span', {}, preceedingText));
      elements.push(createElement('br'));
    }

    const urlRegex = /<a[^>]*?\shref=["']([^"']*?)["'][^>]*?(\starget=["']([^"']*?)["'])?[^>]*?(\sdata-cy=["']([^"']*?)["'])?[^>]*?>([^<]+?)<\/a>/gi;

    while ((match = urlRegex.exec(originalString)) !== null) {
      const preceedingText = originalString.substring(lastIndex, match.index);

      lastIndex = urlRegex.lastIndex;

      elements.push(createElement('span', {}, preceedingText));
      elements.push(
        createElement(
          'router-link',
          {
            attrs: {
              target: match[3] ? match[3] : undefined,
              'data-cy': match[5] ? match[5] : undefined,
            },
            props: {
              to: match[1],
            },
          },
          [match[6]],
        ),
      );
    }

    const lastText = originalString.substring(lastIndex);
    elements.push(createElement('span', {}, lastText));

    return createElement('div', {}, elements);
  }
}
