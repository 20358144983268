































import { Component, Vue } from 'vue-property-decorator';
import { FormBuilder as VueFormBuilder } from 'v-form-builder';
import loginFormList from '@/app/shared/components/form/list/login';
import registerFormList from '@/app/shared/components/form/list/register';
import studyRegistrationFormList from '@/app/shared/components/form/list/study-registration';
import studyReviewsFormList from '@/app/shared/components/form/list/study-reviews';
import organizationFormList from '@/app/shared/components/form/list/organization';
import userProfileFormList from '@/app/shared/components/form/list/user-profile';
import changePasswordFormSchema from '@/app/shared/components/form/schema/change-password.json';
import addStudyFormSchema from '@/app/shared/components/form/schema/add-study.json';
import { FormList, FormSchema } from '@/app/shared/models/form/FormSchema';

@Component({
  components: {
    VueFormBuilder,
  },
})
export default class FormBuilder extends Vue {
  configuration: FormSchema;
  formId: string = '';
  formList: FormList[] = [
    ...loginFormList,
    ...registerFormList,
    ...studyRegistrationFormList,
    ...studyReviewsFormList,
    ...organizationFormList,
    ...userProfileFormList,
    ...[
      {
        id: 'change-password',
        label: 'Change Password',
        schema: changePasswordFormSchema,
      },
      {
        id: 'add-study',
        label: 'Add Study',
        schema: addStudyFormSchema,
      },
    ],
  ];

  get formLabel() {
    const form = this.formList.find((form) => form.id === this.formId);
    return form && form.label;
  }

  get formSchema() {
    const form = this.formList.find((form) => form.id === this.formId);
    return form && form.schema;
  }

  loadFormSchema(formId: string, formSchema: FormSchema) {
    this.formId = formId;
    this.configuration = formSchema;
  }

  copyFormConfig() {
    navigator.clipboard.writeText(JSON.stringify({ ...this.configuration }, null, 2) + '\n');
  }
}
