















import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CommentingResolution extends Vue {
  @Model('update') isResolved: boolean;
  @Prop() isDisabled: boolean;

  @Emit()
  update(_isResolved: string) {
    //
  }
}
