




































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import DataTableActionField from './DashboardDataTableActionField.vue';
import DataTableTitleField from './DashboardDataTableTitleField.vue';
import DataTableStatusField from './DashboardDataTableStatusField.vue';
import {
  DashboardDataTableAction,
  DashboardDataTableColumn,
  DashboardDataTableStatus,
} from '@/app/shared/models/DashboardDataTable';
import { DashboardDataTableColumnType } from '@/app/shared/enums/dashboard-data-table.enum';
import { namespace } from 'vuex-class';

import { User } from '@/app/shared/models/User';

const UserStore = namespace('User');

@Component({
  components: {
    DataTableActionField,
    DataTableTitleField,
    DataTableStatusField,
  },
})
export default class DashboardDataTableField extends Vue {
  @Prop() item: any;
  @Prop() column: DashboardDataTableColumn;
  @Prop() autoExpand: string;
  @Prop() statusConfig: DashboardDataTableStatus[];
  @Prop() actionConfig: DashboardDataTableAction[];
  @Prop() isEditVisible: boolean;
  @Prop() isItemEditable: (item: any) => boolean;
  @Prop() isItemExpandable: (item: any) => boolean;

  @Emit('action-click')
  onActionClick(_type: string, _item: any) {
    //
  }

  @Emit('edit')
  onEdit(_item: any) {
    //
  }

  @Emit('copy')
  onCopy(_item: any) {
    //
  }

  @Emit('expand')
  onExpand(_item: any) {
    //
  }

  @UserStore.State
  public user!: User;

  get columnValue() {
    let columnValue = this.item[this.column.value];
    if (typeof this.column.transformValue === 'function') columnValue = this.column.transformValue(this.item);
    if (columnValue === undefined || columnValue === null || columnValue === '') return '-';
    return columnValue;
  }

  get isStatusType() {
    return this.column.type === DashboardDataTableColumnType.STATUS;
  }

  get isTitleType() {
    return this.column.type === DashboardDataTableColumnType.TITLE;
  }

  get isActionType() {
    return this.column.type === DashboardDataTableColumnType.ACTION;
  }

  get isSelectType() {
    return this.column.type === DashboardDataTableColumnType.SELECT;
  }

  get action() {
    if (!this.isActionType) return null;
    if (typeof this.column.actionType === 'function') return this.column.actionType(this.item);
    return this.column.actionType;
  }

  mounted() {
    if (this.isTitleType && this.autoExpand && this.item.uuid === this.autoExpand) {
      this.onExpand(this.item);
      this.$set(this.item, 'is_expanded', true);

      // Scroll the title field into view.
      //   Do this asynchronously, as the component may not be mounted yet.
      setTimeout(this.scrollTitleFieldIntoView, 100);
    }
  }

  scrollTitleFieldIntoView() {
    const titleField = this.$refs.titleField as Vue & { $el: HTMLDivElement };
    if (titleField && titleField.$el) {
      this.$nextTick(() => {
        titleField.$el.scrollIntoView();
      });
      return;
    }

    setTimeout(this.scrollTitleFieldIntoView, 100);
  }
}
