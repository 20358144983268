































import { Component, Vue, Emit } from 'vue-property-decorator';
import api from '@/app/shared/api';
import { namespace } from 'vuex-class';
const WorkflowStoreModule = namespace('Workflow');

@Component({
  components: {},
})
export default class WorkflowConnectionModal extends Vue {
  formName: string = '';

  @WorkflowStoreModule.Getter
  getCurrentConnection!: Record<any, any>;

  created() {
    this.formName = this.getCurrentConnection.flow ? this.getCurrentConnection.flow.name : '';
  }

  async save() {
    const data = {
      name: this.formName ? this.formName : null,
    };
    const response = await api.workflow.editFlow((this.$attrs.connection as any).flow.uuid, data);
    (this.$attrs.connection as any).flow = response.data;

    // Emit node dialog close.
    this.$emit('workflow-connection-dialog-close');

    // Emit workflow save.
    this.$emit('workflow-save');
  }

  @Emit()
  close() {
    this.$emit('workflow-connection-dialog-close');
  }
}
