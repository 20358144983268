var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item-content",class:{
    'zero-level': _vm.item.level === 0,
    'first-level': _vm.item.level === 1,
    'second-level': _vm.item.level === 2,
    'edit-mode': _vm.mode === 'edit',
    'lock-mode': _vm.mode === 'lock',
  }},[(_vm.mode === 'view' || _vm.mode === 'comment' || _vm.mode === 'tracking' || _vm.mode === 'lock')?[_c('div',{staticClass:"title-wrapper",style:({
        cursor: _vm.hasDraftComment || _vm.hasComment ? 'default' : 'pointer',
      }),attrs:{"data-cy":"heading-title"},on:{"click":_vm.addDraftComment}},[(_vm.hasComment)?_c('ChapterItemCommentIndicator',{attrs:{"uuid":_vm.item.comment,"data-cy":"heading-comment"},on:{"click":_vm.expandItemComment}}):_vm._e(),_c('div',{staticClass:"order",on:{"click":function($event){return _vm.switchMode('edit')}}},[_c('span',[_vm._v(_vm._s(_vm.item.numeration))])]),_c('div',{staticClass:"item-title",on:{"click":function($event){return _vm.switchMode('edit')}}},[_c('span',[_vm._v(" "+_vm._s(_vm.item.title)+" ")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpanded && _vm.item.text),expression:"isExpanded && item.text"}],ref:"text",staticClass:"text-wrapper",style:({
        cursor: _vm.hasDraftComment ? 'default' : 'text',
      }),attrs:{"data-item-key":"text"},domProps:{"innerHTML":_vm._s(_vm.item.text)},on:{"click":function($event){return _vm.switchMode('edit')}}})]:(_vm.mode === 'edit')?[_c('div',{staticClass:"title-wrapper"},[_c('div',{staticClass:"order"},[_c('span',[_vm._v(_vm._s(_vm.item.numeration))])]),_c('div',{staticClass:"item-title"},[_c('BaseTextField',{attrs:{"value":_vm.item.title,"placeholder":"Enter title","type":"title"},on:{"input":_vm.onTitleInput}})],1)]),(_vm.isExpanded)?_c('div',{staticClass:"tiny-editor-wrapper"},[_c('TinyEditor',{attrs:{"value":_vm.item.text,"output-format":"html"},on:{"input":_vm.onTextChange}})],1):_vm._e()]:_vm._e(),_c('div',{staticClass:"btns-right"},[_c('div',{staticClass:"btns-col"},[_c('BaseButton',{staticClass:"btn-expand",attrs:{"type":"icon-light"},on:{"click":_vm.toggleExpand}},[_c('v-icon',{class:{ expanded: _vm.isExpanded }},[_vm._v("$expand")])],1)],1),_c('div',{staticClass:"btns-col"},[(_vm.mode === 'edit' && _vm.isDeleteButtonAllowed)?_c('BaseButton',{staticClass:"delete-btn",attrs:{"type":"icon-light"},on:{"click":_vm.deleteChapterItem}},[_c('v-icon',[_vm._v("$delete")])],1):_vm._e(),(_vm.mode === 'lock')?_c('v-icon',[_vm._v("$lock")]):_vm._e()],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }