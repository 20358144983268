import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

export interface TooltipOptions {
  subtitle?: string;
  text?: string;
  top?: number;
  left?: number;
  position?: 'bottom' | 'top' | 'left' | 'right';
  right?: number;
  buttonText?: string;
}

@Module({ namespaced: true })
class UiModule extends VuexModule {
  private leftSidebarExpanded: boolean = false;
  private tinyEditorVisible: boolean = false;

  private tooltipVisible: boolean = false;
  private tooltipOptionsState: TooltipOptions = {
    text: null,
    top: 0,
    left: 0,
    position: 'bottom',
    buttonText: 'close',
  };

  private calloutVisible: boolean = false;
  private calloutOptionsState: TooltipOptions = {
    text: null,
    top: 0,
    left: 0,
    position: 'bottom',
    buttonText: 'close',
  };

  private footerCommentingActive: boolean = false;
  private footerLibraryActive: boolean = false;
  private footerTrackingActive: boolean = false;
  private footerVersioningActive: boolean = false;

  get isLeftSidebarExpanded() {
    return this.leftSidebarExpanded;
  }

  get isTinyEditorVisible() {
    return this.tinyEditorVisible;
  }

  get isTooltipVisible() {
    return this.tooltipVisible;
  }

  get tooltipOptions() {
    return this.tooltipOptionsState;
  }
  get isCalloutVisible() {
    return this.calloutVisible;
  }

  get calloutOptions() {
    return this.calloutOptionsState;
  }

  get isFooterCommentingActive() {
    return this.footerCommentingActive;
  }

  get isFooterLibraryActive() {
    return this.footerLibraryActive;
  }

  get isFooterTrackingActive() {
    return this.footerTrackingActive;
  }

  get isFooterVersioningActive() {
    return this.footerVersioningActive;
  }

  @Mutation
  private setTooltipVisiblity(isVisible: boolean) {
    this.tooltipVisible = isVisible;
  }

  @Mutation
  private setTooltipOptions(payload: TooltipOptions) {
    this.tooltipOptionsState = payload;
  }

  @Mutation
  private setCalloutVisiblity(isVisible: boolean) {
    this.calloutVisible = isVisible;
  }

  @Mutation
  private setCalloutOptions(payload: TooltipOptions) {
    this.calloutOptionsState = payload;
  }

  @Mutation
  public setFooterCommentingActive(value: boolean) {
    this.footerCommentingActive = value;
  }

  @Mutation
  public setFooterLibraryActive(value: boolean) {
    this.footerLibraryActive = value;
  }

  @Mutation
  public setFooterTrackingActive(value: boolean) {
    this.footerTrackingActive = value;
  }

  @Mutation
  public setFooterVersioningActive(value: boolean) {
    this.footerVersioningActive = value;
  }

  @Action({ rawError: true })
  public hideTooltip() {
    this.context.commit('setTooltipVisiblity', false);
  }

  @Action({ rawError: true })
  public showTooltip(payload: TooltipOptions): void {
    this.context.commit('setTooltipOptions', payload);
    this.context.commit('setTooltipVisiblity', true);
  }

  @Action({ rawError: true })
  public hideCallout() {
    this.context.commit('setCalloutVisiblity', false);
  }

  @Action({ rawError: true })
  public showCallout(payload: TooltipOptions): void {
    this.context.commit('setCalloutOptions', payload);
    this.context.commit('setCalloutVisiblity', true);
  }

  @Mutation
  private setLeftSidebarExpandedValue(isExpanded: boolean) {
    this.leftSidebarExpanded = isExpanded;
  }

  @Action({ rawError: true })
  public toggleLeftSidebar(value: boolean) {
    this.context.commit('setLeftSidebarExpandedValue', value);
  }

  @Mutation
  private setTinyEditorVisibleValue(isVisible: boolean) {
    this.tinyEditorVisible = isVisible;
  }

  @Action({ rawError: true })
  public setTinyEditorVisibility(value: boolean) {
    this.context.commit('setTinyEditorVisibleValue', value);
  }
}
export default UiModule;
