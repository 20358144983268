import httpClient from '@/app/shared/api/axios.config';
import { AssessData } from '@/app/shared/models/calculations/AssessData';
import { FormulateData } from '@/app/shared/models/calculations/FormulateData';
import { UnitScore } from '@/app/shared/models/calculations/UnitScore';
import { CheckTree } from '@/app/shared/models/CheckTree';
import { UnitList } from '@/app/shared/models/calculations/UnitList';
import { ModuleProperties } from '@/app/shared/models/Module';

const UNIT_LIST_END_POINT = '/unit';

export default {
  async resume(unitListId: number, fullPath: string, module: ModuleProperties) {
    await httpClient.post(`${UNIT_LIST_END_POINT}/processor/${unitListId}/resume/`, {
      full_path: fullPath,
      module: {
        uuid: module.module_uuid,
        type: module.module_type,
      },
    });
  },

  async getUnitList(unitListId: number, module: ModuleProperties, filterChecktrees: CheckTree[]): Promise<UnitList> {
    return await httpClient.post(`${UNIT_LIST_END_POINT}/processor/${unitListId}`, {
      module: {
        uuid: module.module_uuid,
        type: module.module_type,
      },
      filters: {
        checktrees: filterChecktrees,
        options: [],
      },
    });
  },

  async connectOptionWithinGroupedTags(unitListId: number, unitUuid: string, optionUuid: string) {
    const payload = {
      axis_y_item: unitUuid,
      axis_x_item: optionUuid,
      y_children: false,
      x_children: false,
    };
    const res: any = await httpClient.post(`${UNIT_LIST_END_POINT}/processor/${unitListId}/connect`, payload);
    return res;
  },
  async disconnectOptionWithinGroupedTags(unitListId: number, unitUuid: string, optionUuid: string) {
    const payload = {
      axis_y_item: unitUuid,
      axis_x_item: optionUuid,
      y_children: false,
      x_children: false,
    };
    const res: any = await httpClient.post(`${UNIT_LIST_END_POINT}/processor/${unitListId}/disconnect`, payload);
    return res;
  },
  async getChecktreeByUnitUuid(unitUuid: string) {
    const data: CheckTree = await httpClient.post(`${UNIT_LIST_END_POINT}/processor/${unitUuid}/define`);
    return data;
  },
  async getMMChecktreeByUnitUuid(unitUuid: string, route: any) {
    const payload = {
      full_path: route.fullPath,
    };
    const data: CheckTree = await httpClient.post(`${UNIT_LIST_END_POINT}/processor/${unitUuid}/mmdefine`, payload);
    return data;
  },
  async getFormulateValues(unitUuid: string) {
    const data: FormulateData = await httpClient.get(`${UNIT_LIST_END_POINT}/processor/${unitUuid}/formulate`);
    return data;
  },
  async updateFactor(unitUuid: string, factorUuid: string, weight: number) {
    await httpClient.post(`${UNIT_LIST_END_POINT}/processor/${unitUuid}/formulate/factor`, {
      uuid: factorUuid,
      weight: weight === null ? '' : weight,
    });
  },
  async updateFormula(unitUuid: string, formulaUuid: string, expression: string, defaultValue: boolean = false) {
    await httpClient.post(`${UNIT_LIST_END_POINT}/processor/${unitUuid}/formulate/formula`, {
      uuid: formulaUuid,
      expression,
      default: defaultValue,
    });
  },
  async addScoreRange(unitUuid: string, propertyName: string, propertyValue: string | number): Promise<UnitScore> {
    const payload: any = {};
    payload[propertyName] = propertyValue && propertyValue != 0 ? propertyValue : '';
    return await httpClient.put(`${UNIT_LIST_END_POINT}/processor/${unitUuid}/formulate/score`, payload);
  },
  async updateScoreRange(
    unitUuid: string,
    scoreRangeUuid: string,
    propertyName: string,
    propertyValue: string | number,
  ): Promise<UnitScore> {
    const payload: any = {
      uuid: scoreRangeUuid,
    };
    payload[propertyName] = propertyValue && propertyValue != 0 ? propertyValue : '';
    return await httpClient.post(`${UNIT_LIST_END_POINT}/processor/${unitUuid}/formulate/score`, payload);
  },
  async deleteScoreRange(unitUuid: string, scoreRangeUuid: string): Promise<UnitScore> {
    return await httpClient.delete(`${UNIT_LIST_END_POINT}/processor/${unitUuid}/formulate/score`, {
      data: {
        uuid: scoreRangeUuid,
      },
    });
  },
  async getAssessValues(payload: any) {
    const params = {
      alternatives: payload.alternatives,
    };
    const data: AssessData = await httpClient.get(`${UNIT_LIST_END_POINT}/processor/${payload.unitUuid}/assessment`, {
      params: params,
    });
    return data;
  },
  async connectCriteriaForPhase(unitUuid: string, phaseId: number, checktreeItemId: number) {
    const payload = {
      phase_id: phaseId,
      checktree_item_id: checktreeItemId,
    };
    const res: any = await httpClient.post(`${UNIT_LIST_END_POINT}/processor/${unitUuid}/phase/connect`, payload);
    return res;
  },
  async disconnectCriteriaForPhase(unitUuid: string, phaseId: number, checktreeItemId: number) {
    const payload = {
      phase_id: phaseId,
      checktree_item_id: checktreeItemId,
    };
    const res: any = await httpClient.post(`${UNIT_LIST_END_POINT}/processor/${unitUuid}/phase/disconnect`, payload);
    return res;
  },
  async clearPhaseSelection(unitUuid: string, phaseId: number) {
    const payload = {
      phase_id: phaseId,
    };
    const res: any = await httpClient.post(`${UNIT_LIST_END_POINT}/processor/${unitUuid}/phase/clear`, payload);
    return res;
  },
  async calculateScore(
    unitUuid: string,
    phaseUuid: string,
    alternatives: string,
  ): Promise<{ score: number; color: string }> {
    if (alternatives == 'vecs') {
      return await httpClient.post(`${UNIT_LIST_END_POINT}/processor/${unitUuid}/assessment/assess_vecs`, null, {
        params: { phase_uuid: phaseUuid },
      });
    } else {
      return await httpClient.post(`${UNIT_LIST_END_POINT}/processor/${unitUuid}/assessment/assess`, null, {
        params: { phase_uuid: phaseUuid },
      });
    }
  },
};
