




import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import { v4 as uuidv4 } from 'uuid';
import ChapterItemCommentIndicator from '../ChapterItemCommentIndicator.vue';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import { Comment } from '@/app/shared/models/Comment';
import { ChapterItem } from '@/app/shared/models/reports/ChapterItem';
import { ChapterItemMode } from '@/app/shared/types/chapter-item-mode.type';
import { UpdateChapterItemPayload } from '@/app/shared/store/modules/reports.store-module';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

const CommentsStore = namespace('Comments');
const UserStore = namespace('User');

@Component({
  components: {
    ChapterItemCommentIndicator,
  },
})
export default class ChapterItemCommentHighlight extends Vue {
  @Prop() item: ChapterItem;
  @Prop() mode: ChapterItemMode;
  supportsTextHighlighting = false;

  @CommentsStore.Getter
  isCommentFeedMode: boolean;

  @CommentsStore.Getter
  hasDraftComment: boolean;

  @CommentsStore.Action
  initDraftComment: (payload: {
    uuid: string;
    objectItemUuid: string;
    objectItemType: string;
    objectItemKey: string;
  }) => void;

  @CommentsStore.Action
  expandComment: (uuid: string) => void;

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  @AsyncComputed()
  async isEditModeAllowed() {
    return await this.isAllowed(UserPermission.COMMENTING_BAR_EDIT_MODE);
  }

  @Emit('update')
  updateChapterItem(_payload: UpdateChapterItemPayload) {
    //
  }

  @Watch('isCommentMode')
  onCommentingModeChange(isCommentMode: boolean) {
    if (!this.isEditModeAllowed || !this.supportsTextHighlighting) return;
    if (isCommentMode) this.registerMouseEvent();
    else this.deregisterMouseEvent();
  }

  get isCommentMode() {
    return this.mode === 'comment' && this.isCommentFeedMode;
  }

  get hasComment() {
    return Boolean(this.item['comment' as never]);
  }

  created() {
    eventBus.$off(EventType.COMMENT_DELETE, this.onCommentDelete).$on(EventType.COMMENT_DELETE, this.onCommentDelete);
  }

  mounted() {
    if (this.isCommentMode && this.isEditModeAllowed && this.supportsTextHighlighting) this.registerMouseEvent();
  }

  beforeDestroy() {
    eventBus.$off(EventType.COMMENT_DELETE, this.onCommentDelete);
    if (this.isEditModeAllowed && this.supportsTextHighlighting) this.deregisterMouseEvent();
  }

  async onCommentDelete(comment: Comment) {
    if (!this.isEditModeAllowed) return;

    // Return early if the deleted comment does not concern this chapter item.
    if (this.item.uuid !== comment.object_item_uuid) return;

    // Delete whole paragraph comment indicator.
    if (this.item['comment' as never] === comment.uuid) {
      this.$set(this.item, 'comment', null);

      this.updateChapterItem({
        uuid: this.item.uuid,
        type: this.item.type,
        propertyName: 'comment',
        propertyValue: null,
      });

      return;
    }

    // Delete comment highlight tied to the paragraph text.
    const chapterItemKey = comment.object_item_key as keyof ChapterItem;

    const itemElement = this.$refs[chapterItemKey] as HTMLElement;
    if (!itemElement) return;

    let chapterItemValue = itemElement.innerHTML;

    const highlightRegex = new RegExp(`<span[^>]+data-uuid=['"]${comment.uuid}['"][^>]+>(.*?)</span>`);
    const highlightMatch = chapterItemValue.match(highlightRegex);
    if (!highlightMatch) return;

    chapterItemValue = chapterItemValue.replace(highlightRegex, highlightMatch[1]);

    this.updateChapterItem({
      uuid: this.item.uuid,
      type: this.item.type,
      propertyName: chapterItemKey,
      propertyValue: chapterItemValue,
    });
  }

  registerMouseEvent() {
    const text = this.$refs.text as HTMLDivElement;
    if (text) text.addEventListener('mouseup', this.onMouseUp);
  }

  deregisterMouseEvent() {
    const text = this.$refs.text as HTMLDivElement;
    if (text) text.removeEventListener('mouseup', this.onMouseUp);
  }

  findParentChapterItem(element: HTMLElement): HTMLElement {
    if (element.dataset.itemKey) return element;
    if (!element.parentElement) return null;
    return this.findParentChapterItem(element.parentElement);
  }

  async onMouseUp(event: MouseEvent) {
    // Return early if no meaningful selection occurred.
    const selection = window.getSelection();
    if (!selection.toString() || selection.rangeCount === 0) return;

    // Return early if we have a pending draft comment.
    if (this.hasDraftComment) {
      selection.empty();
      return;
    }

    const target = event.target as HTMLSpanElement;

    // Return early if another comment highlight was selected.
    //   This will keep the complexity down.
    if (target && target.className === 'comment-highlight') {
      selection.empty();
      return;
    }

    // Return early if the selection does not starts and end with the same element
    if (selection.anchorNode !== selection.focusNode) {
      console.warn('Comment selection invalid, please try again to select a simpler text element...');
      selection.empty();
      return;
    }

    // Return early if the anchor and focus elements are not part of the same chapter item element.
    const anchorElement = this.findParentChapterItem(selection.anchorNode.parentElement);
    const focusElement = this.findParentChapterItem(selection.focusNode.parentElement);
    if (anchorElement !== focusElement) {
      console.warn('Comment selection invalid, please try again to select text within a single chapter item...');
      selection.empty();
      return;
    }

    const chapterItemKey = anchorElement.dataset.itemKey as keyof ChapterItem;

    // Extract the selected document fragment, wrap it in a highlight element and replace it in place.
    const uuid = uuidv4();
    const originalHtml = anchorElement.innerHTML;
    const range = selection.getRangeAt(0);
    const span = document.createElement('span');
    span.dataset.uuid = uuid;
    span.className = 'comment-highlight';
    span.appendChild(range.extractContents());
    range.insertNode(span);
    selection.empty();

    // If the draft comment is added, make the value change permanent
    eventBus.$off(EventType.COMMENT_DRAFT_ADD).$once(EventType.COMMENT_DRAFT_ADD, async (comment: Comment) => {
      span.dataset.uuid = comment.uuid;

      this.updateChapterItem({
        uuid: this.item.uuid,
        type: this.item.type,
        propertyName: chapterItemKey,
        propertyValue: anchorElement.innerHTML,
      });
    });

    // If the draft comment is canceled, restore the original HTML contents.
    eventBus.$off(EventType.COMMENT_DRAFT_CANCEL).$once(EventType.COMMENT_DRAFT_CANCEL, () => {
      anchorElement.innerHTML = originalHtml;
    });

    // Initialize and associate a new draft comment with the highlighted section.
    this.initDraftComment({
      uuid,
      objectItemUuid: this.item.uuid,
      objectItemType: this.item.type,
      objectItemKey: chapterItemKey,
    });
  }

  addDraftComment() {
    if (!this.isCommentMode || !this.isEditModeAllowed || this.hasDraftComment || this.hasComment) return;

    const uuid = uuidv4();

    this.$set(this.item, 'comment', uuid);

    // If the draft comment is added, make the value change permanent
    eventBus.$off(EventType.COMMENT_DRAFT_ADD).$once(EventType.COMMENT_DRAFT_ADD, async (comment: Comment) => {
      this.$set(this.item, 'comment', comment.uuid);

      this.updateChapterItem({
        uuid: this.item.uuid,
        type: this.item.type,
        propertyName: 'comment',
        propertyValue: comment.uuid,
      });
    });

    // If the draft comment is canceled, remove the temporary UUID.
    eventBus.$off(EventType.COMMENT_DRAFT_CANCEL).$once(EventType.COMMENT_DRAFT_CANCEL, () => {
      this.$set(this.item, 'comment', null);
    });

    // Initialize and associate a new draft comment with the highlighted section.
    this.initDraftComment({
      uuid,
      objectItemUuid: this.item.uuid,
      objectItemType: this.item.type,
      objectItemKey: 'comment',
    });
  }

  expandItemComment() {
    if (this.hasDraftComment) return;
    this.expandComment(this.item['comment' as never] as string);
  }
}
