


































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import VersioningItemContent from './VersioningItemContent.vue';
import VersioningItemHeader from './VersioningItemHeader.vue';
import DialogService from '@/app/shared/utils/dialog.service';
import { VersioningItem } from '@/app/shared/models/Versioning';

const UserStore = namespace('User');
const VersioningStore = namespace('Versioning');

@Component({
  components: {
    VersioningItemContent,
    VersioningItemHeader,
  },
})
export default class VersioningBox extends Vue {
  @Prop() versioningItem: VersioningItem;
  @Prop({ default: false, type: Boolean }) isCollapsedToolbar: boolean;
  @Prop({ default: false, type: Boolean }) isEditAllowed: boolean;
  @Prop({ default: false, type: Boolean }) isToolbarAllowed: boolean;
  @Prop({ default: false, type: Boolean }) isRestoreAllowed: boolean;

  @UserStore.Getter
  skipConfirmationDialog: () => boolean;

  @UserStore.Mutation
  setSkipConfirmationDialog: (value: boolean) => void;

  @VersioningStore.Action
  updateVersioningItemName: (payload: { versioningItem: VersioningItem; name: string }) => Promise<void>;

  @VersioningStore.Action
  updateVersioningItemComment: (payload: { versioningItem: VersioningItem; comment: string }) => Promise<void>;

  @VersioningStore.Action
  toggleVersioningItemExpanded: (versioningItem: VersioningItem) => void;

  @VersioningStore.Action
  toggleVersioningItemImportant: (versioningItem: VersioningItem) => Promise<void>;

  @VersioningStore.Action
  toggleVersioningItemAuthority: (versioningItem: VersioningItem) => Promise<void>;

  @VersioningStore.Action
  toggleVersioningItemPublic: (versioningItem: VersioningItem) => Promise<void>;

  @VersioningStore.Action
  restoreVersioningItem: (versioningItem: VersioningItem) => Promise<void>;

  get isActive() {
    return this.versioningItem.is_active;
  }

  get isImportant() {
    return this.versioningItem.is_important;
  }

  get isExpanded() {
    return this.versioningItem.is_expanded;
  }

  get versioningItemBackgroundColor() {
    if (this.isImportant) return 'var(--v-yellow-important-status-highlight)';
    if (this.isActive) return 'var(--v-blue-pale)';
    return 'var(--v-white)';
  }

  async updateName(name: string) {
    await this.updateVersioningItemName({ versioningItem: this.versioningItem, name });
  }

  async updateComment(comment: string) {
    await this.updateVersioningItemComment({ versioningItem: this.versioningItem, comment });
  }

  onRestore() {
    if (this.skipConfirmationDialog) {
      this.restoreVersioningItem(this.versioningItem);

      // @TODO Redirect to restored workspace.
      this.$router.push({ name: 'StudiesDashboard' });

      return;
    }

    const restoreClosure = (context: { checkboxValue: boolean }) => {
      this.setSkipConfirmationDialog(context.checkboxValue);
      this.restoreVersioningItem(this.versioningItem);

      // @TODO Redirect to restored workspace.
      this.$router.push({ name: 'StudiesDashboard' });
    };

    DialogService.presentDialog(
      'Are you sure?',
      'You will restore another version.',
      'Yes',
      'Cancel',
      restoreClosure,
      () => {
        //
      },
      'Do not show me this again',
    );
  }
}
