





































import { Component, Mixins, Prop } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import DashboardPageHeader from '@/app/shared/components/dashboard/DashboardPageHeader.vue';
import DashboardSidebar from '@/app/shared/components/dashboard/DashboardSidebar.vue';
import DashboardDataTable from '@/app/shared/components/dashboard/DashboardDataTable.vue';
import DashboardFiltering from '@/app/shared/components/mixins/DashboardFiltering.vue';
import DashboardDynamicColumns from '@/app/shared/components/mixins/DashboardDynamicColumns.vue';
import PageFooter from '@/app/shared/components/PageFooter.vue';
import {
  DashboardDataTableAction,
  DashboardDataTableColumn,
  DashboardDataTableStatus,
} from '@/app/shared/models/DashboardDataTable';
import { DashboardDataTableColumnType } from '@/app/shared/enums/dashboard-data-table.enum';
import api from '@/app/shared/api';
import { StudyTableRow } from '@/app/shared/models/Study';
import { Table } from '@/app/shared/models/Table';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

const UserStore = namespace('User');

@Component({
  components: {
    DashboardPageHeader,
    DashboardSidebar,
    DashboardDataTable,
    PageFooter,
  },
})
export default class StudiesDashboard extends Mixins(DashboardFiltering, DashboardDynamicColumns) {
  @Prop() studyUuid: string;
  tableData: StudyTableRow[] = null;
  detailsData: Record<string, Table[][]> = {};
  adminInfo: Record<string, Table> = {};
  fixedColumns: DashboardDataTableColumn[] = [
    {
      text: 'Status',
      value: 'status',
      type: DashboardDataTableColumnType.STATUS,
      width: '164px !important',
      resizable: false,
    },
    {
      text: 'Short Study Name',
      value: 'short_name',
      type: DashboardDataTableColumnType.TITLE,
      // width: (((720 / 1920) * window.screen.width) / 100) * 100 + 'px',
      resizable: true,
    },
  ];
  dynamicColumns: DashboardDataTableColumn[] = [];
  activeFilters: Record<keyof StudyTableRow, string[]> = null;
  statusConfig: DashboardDataTableStatus[] = [
    {
      type: 'approved',
      icon: 'approved-16px',
      label: 'Approved',
      highlightColor: 'var(--v-green-approve-status-highlight)',
      solidColor: 'var(--v-green-approve-status-solid)',
    },
    {
      type: 'demo',
      icon: 'demo-16px',
      label: 'Demo',
      highlightColor: 'var(--v-gray-pale)',
      solidColor: 'var(--v-gray-dark)',
    },
    {
      type: 'in-progress',
      icon: 'in-progress-16px',
      label: 'In Progress',
      highlightColor: 'var(--v-violet-in-progress-status-highlight)',
      solidColor: 'var(--v-violet-in-progress-status-solid)',
    },
    {
      type: 'rejected',
      icon: 'rejected-16px',
      label: 'Rejected',
      highlightColor: 'var(--v-red-error-highlight)',
      solidColor: 'var(--v-red-error-solid)',
    },
    {
      type: 'under-review',
      icon: 'under-review-16px',
      label: 'Under Review',
      highlightColor: 'var(--v-violet-under-review-status-highlight)',
      solidColor: 'var(--v-violet-under-review-status-solid)',
    },
    {
      type: 'under-revision',
      icon: 'under-revision-16px',
      label: 'Under Revision',
      highlightColor: 'var(--v-orange-under-revision-status-highlight)',
      solidColor: 'var(--v-orange-under-revision-status-solid)',
    },
  ];

  @UserStore.Getter
  public userOrganizationUuid: string;

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  @AsyncComputed()
  async createButton() {
    return (await this.isAllowed(UserPermission.STUDY_REGISTRATION_ACCESS))
      ? { icon: 'add', label: 'Create new study', route: '/study-registration' }
      : null;
  }

  @AsyncComputed()
  async userActionColumns(): Promise<DashboardDataTableColumn[]> {
    return [
      {
        text: 'Action',
        value: 'action',
        type: DashboardDataTableColumnType.ACTION,
        actionType: (item) => {
          switch (item.status) {
            case 'approved':
            case 'rejected':
              return 'view';
            case 'under-review':
            case 'in-progress':
            default:
              return 'review';
          }
        },
        width: '172px !important',
        resizable: false,
      },
      ...((await this.isAllowed(UserPermission.WORKSPACE_ACCESS))
        ? [
            {
              text: 'Action',
              value: 'action_work',
              type: DashboardDataTableColumnType.ACTION,
              actionType: 'work',
              width: '172px !important',
              resizable: false,
            },
          ]
        : []),
    ];
  }

  @AsyncComputed()
  async actionConfig(): Promise<DashboardDataTableAction[]> {
    return [
      {
        type: 'view',
        icon: 'show',
        label: 'View',
        style: 'secondary',
        highlightColor: 'var(--v-green-approve-status-highlight)',
        solidColor: 'var(--v-green-approve-status-solid)',
        isDisabled: async (item: StudyTableRow) => {
          if (!(await this.isAllowed(UserPermission.STUDY_REVIEW_ACCESS))) return true;
          return !item.build_finished;
        },
      },
      {
        type: 'review',
        icon: 'review',
        label: 'Review',
        style: 'secondary',
        highlightColor: 'var(--v-violet-under-review-status-highlight)',
        solidColor: 'var(--v-violet-under-review-status-solid)',
        isDisabled: async (item: StudyTableRow) => {
          if (!(await this.isAllowed(UserPermission.STUDY_REVIEW_ACCESS))) return true;
          return !item.build_finished;
        },
      },
      {
        type: 'work',
        icon: 'open-1px',
        label: 'Work',
        style: 'primary',
        isDisabled: (item: StudyTableRow) => !item.build_finished || /^approved|rejected$/.test(item.status),
      },
    ];
  }

  @AsyncComputed()
  async isEditAllowed() {
    return await this.isAllowed(UserPermission.STUDY_REGISTRATION_ACCESS);
  }

  get allColumns() {
    return [...this.fixedColumns, ...this.dynamicColumns, ...this.actionColumns];
  }

  get tableColumns() {
    return [...this.fixedColumns, ...this.dynamicColumns.filter((el) => el.checked), ...this.actionColumns];
  }

  get tagsColumns() {
    return [
      {
        field: 'workflow_name',
        name: 'Type',
      },
      {
        field: 'location_name',
        name: 'Location',
      },
      {
        field: 'industry_title',
        name: 'Industry',
      },
    ];
  }

  get headerIcon() {
    return '$studies-dashboard';
  }

  get headerTitle() {
    return 'Studies Dashboard';
  }

  get filterFields() {
    return ['workflow_name', 'location_name', 'industry_title'];
  }

  get filterItems() {
    return [
      {
        field: 'workflow_name',
        icon: 'study-type',
        text: 'Type',
        items: this.tableData && this.uniqueFilterValueItems.workflow_name,
      },
      {
        field: 'location_name',
        icon: 'location',
        text: 'Location',
        items: this.tableData && this.uniqueFilterValueItems.location_name,
      },
      {
        field: 'industry_title',
        icon: 'industry-sector',
        text: 'Industry',
        items: this.tableData && this.uniqueFilterValueItems.industry_title,
      },
    ];
  }

  created() {
    this.initDynamicColumns();
    this.getData();
  }

  async getData() {
    try {
      // this.tableData = await api.study.getStudyTableRows(this.userOrganizationUuid);
      this.tableData = await api.study.getStudyTableRows();
      this.initActiveFilters();
    } catch (error) {
      console.error(error);
    }
  }

  async getDetails(uuid: string) {
    try {
      const studyTableRows = await api.study.getStudyTableDetails(uuid);

      const facilityHeader = {
        icon: 'development-type',
        text: 'Development/Facility',
      };

      const facilityColumns = studyTableRows.facility.map((row, index) => {
        let text;

        switch (row.column) {
          case 'project':
            text = 'New project or Existing';
            break;
          case 'name':
            text = 'Development/Facility name';
            break;
          case 'location':
            text = 'Location';
            break;
          case 'development_types':
            text = 'Development/Facility type';
            break;
          case 'investor':
            text = row.title || 'Investor';
            break;
          default:
            text = row.title || row.column;
            break;
        }

        return {
          text,
          value: index,
        };
      });

      const facilityData = studyTableRows.facility.reduce((data, row, index) => {
        let value;

        switch (row.column) {
          case 'project': {
            if (row.text === 'new') value = 'New development';
            else if (row.text === 'existing') value = 'Existing facility';
            else value = '-';
            break;
          }
          default:
            value = row.text.length ? row.text : '-';
        }

        return {
          ...data,
          [index]: value,
        };
      }, {});

      const assessmentHeader = {
        icon: 'study-type',
        text: 'Assessment',
      };

      const assessmentColumns = studyTableRows.assessment.map((row, index) => {
        let text;

        switch (row.column) {
          case 'short_name':
            text = 'Study name';
            break;
          case 'workflow_name':
            text = 'Study type';
            break;
          case 'authority_title':
            text = 'Submission to';
            break;
          case 'assessor':
            text = row.title || 'Responsible Assessor';
            break;
          case 'assessment':
            text = row.title || 'Assessment Responsibility';
            break;
          default:
            text = row.title || row.column;
            break;
        }

        return {
          text,
          value: index,
        };
      });

      const assessmentData = studyTableRows.assessment.reduce(
        (data, row, index) => ({
          ...data,
          [index]: row.text.length ? row.text : '-',
        }),
        {},
      );

      this.$set(this.detailsData, uuid, [
        [
          {
            type: 'presentation',
            header: facilityHeader,
            columns: facilityColumns,
            data: facilityData,
          },
          {
            type: 'presentation',
            header: assessmentHeader,
            columns: assessmentColumns,
            data: assessmentData,
          },
        ],
      ]);

      // if (await this.isAllowed(UserPermission.SAAS_DETAILS_FORM_ACCESS)) {
      //   const adminInfo = {
      //     type: 'inline',
      //     header: {
      //       icon: 'admin-info-16px',
      //       text: 'Admin info',
      //     },
      //     columns: [
      //       {
      //         text: 'Company',
      //         value: 'company',
      //       },
      //       {
      //         text: 'Licence type',
      //         value: 'licence_type',
      //       },
      //       {
      //         text: 'Users',
      //         value: 'users',
      //       },
      //       {
      //         text: 'Active users',
      //         value: 'active_users',
      //       },
      //       {
      //         text: 'Expires in',
      //         value: 'expires_in',
      //       },
      //       {
      //         text: 'Action',
      //         actionType: 'upgrade',
      //         actionIcon: 'upgrade',
      //         actionLabel: 'Upgrade',
      //       },
      //     ],
      //     data: {
      //       id: 10,
      //       company: 'ExxonMobil Exploration Company',
      //       licence_type: '12 months',
      //       users: '10',
      //       active_users: '6',
      //       expires_in: '135 days',
      //     },
      //   };
      //   this.$set(this.adminInfo, uuid, adminInfo);
      // }
    } catch (error) {
      console.error(error);
    }
  }

  onSelectedColumnsChange(columns: DashboardDataTableColumn[]) {
    this.dynamicColumns = columns;
  }

  async onActionClick(type: string, item: any) {
    switch (type) {
      case 'review':
      case 'view':
        try {
          const workspace = await api.workspace.getWorkspaceByStudy(item.uuid);
          if (workspace) this.$router.push(`/study-reviews/${item.uuid}/${workspace.uuid}`);
        } catch (error) {
          console.warn(error);
        }
        break;
      case 'work': {
        try {
          const workspace = await api.workspace.getWorkspaceByStudy(item.uuid);
          if (workspace) this.$router.push(`/workspace/${workspace.uuid}`);
        } catch (error) {
          console.warn(error);
        }
        break;
      }
    }
  }

  onEdit(item: StudyTableRow) {
    this.$router.push(`/study-registration/${item.uuid}`);
  }

  async onCopy(item: StudyTableRow) {
    await api.study.copyStudy(item.uuid);
    this.$router.go(0);
  }

  onExpand(item: StudyTableRow) {
    this.getDetails(item.uuid);
  }

  async onExpandAll() {
    for (const item of this.filteredData) {
      await this.getDetails(item.uuid);
    }
  }
}
