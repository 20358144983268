


















import { Component, Mixins, Vue } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import FormFieldText from '@/app/shared/components/form/field/FormFieldText.vue';
import FormFieldFocus from '@/app/shared/components/form/field/mixins/FormFieldFocus.vue';

@Component({
  components: {
    FormFieldText,
  },
})
export default class FormFieldPassword extends Mixins(Vue, FormFieldFocus, BaseControlSkeleton) {
  isPasswordVisible: boolean = false;

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
