




















































import { Component, Vue, Prop } from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';
import Loader from '@/app/shared/components/Loader.vue';
import { namespace } from 'vuex-class';
import CheckTreeItem from '@/app/shared/models/CheckTreeItem';
import { UnitFormula } from '@/app/shared/models/calculations/UnitFormula';
import LoaderService from '@/app/shared/utils/loader.service';
import { UpdateFormulaPayload } from '@/app/shared/store/modules/calculations.store-module';
const CalculationsStore = namespace('Calculations');

@Component({
  components: { editor: Editor, Loader },
})
export default class FormulasTab extends Vue {
  @Prop() formula: UnitFormula;
  @Prop() criteriaGroups: CheckTreeItem[];
  @Prop() unitUuid: string;

  expression: string = '';
  menuVisible: boolean = false;
  activeMenuItemIndex: number = 0; // uuid of active criteria
  x: number = null;
  y: number = null;

  get options() {
    if (this.expression.endsWith('#')) {
      return this.criteriaGroups;
    } else if (this.expression.endsWith('.')) {
      const formulaStrArray = this.expression.split('+');
      let groupIndex = null;
      if (formulaStrArray[formulaStrArray.length - 1].indexOf('#') > -1) {
        groupIndex = Number(formulaStrArray[formulaStrArray.length - 1].split('#')[1].split('.')[0]) - 1;
      }
      return groupIndex === null || Number.isNaN(groupIndex) || groupIndex < 0
        ? null
        : this.criteriaGroups[groupIndex].children;
    }
    return null;
  }

  created() {
    if (this.formula && this.formula.expression) {
      this.expression = this.formula.expression;
    }
  }

  @CalculationsStore.Action
  updateFormula: (payload: UpdateFormulaPayload) => Promise<any>;

  onFormulaInput(event: InputEvent) {
    const inputValue = (event.target as any).value;

    // needed for x offset because of text input width
    const hiddenSpanEl = this.$refs.formulaInputHiddenSpan as HTMLElement;
    hiddenSpanEl.innerHTML = inputValue;
    const textWidth: number = hiddenSpanEl.offsetWidth;
    this.x = (event.target as HTMLElement).getBoundingClientRect().x + 12 + textWidth;
    this.y = (event.target as HTMLElement).getBoundingClientRect().y;

    const formulaLastCharacter = this.expression.slice(-1);

    if (formulaLastCharacter === '#' && this.options) {
      this.menuVisible = true;
    } else if (formulaLastCharacter === '.' && this.options) {
      this.menuVisible = true;
    } else {
      this.menuVisible = false;
      this.updateFormulaExpression();
    }
  }

  selectCriteria(index: number) {
    this.expression += index + 1;
    (this.$refs.formulaInput as HTMLElement).focus();
    this.updateFormulaExpression();
  }

  async updateFormulaExpression() {
    try {
      LoaderService.disableHttpLoader();
      await this.updateFormula({
        unitUuid: this.unitUuid,
        formulaUuid: this.formula.uuid,
        expression: this.expression,
        default: false,
      });
    } catch (error) {
      console.error(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  onArrowKeyDown(event: any, direction: 'up' | 'down') {
    if (!this.menuVisible) {
      return;
    }
    event.preventDefault();
    if (direction === 'down') {
      if (this.activeMenuItemIndex === this.options.length - 1) {
        return;
      }
      this.activeMenuItemIndex++;
    } else {
      if (this.activeMenuItemIndex === 0) {
        return;
      }
      this.activeMenuItemIndex--;
    }
  }

  onInputEnterKey(event: any) {
    if (!this.menuVisible) {
      return;
    }
    event.preventDefault();
    this.selectCriteria(this.activeMenuItemIndex);
    this.activeMenuItemIndex = 0;
  }
}
