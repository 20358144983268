import { render, staticRenderFns } from "./PresentationsAssessingTableImpact.vue?vue&type=template&id=74c1b28e&scoped=true&"
import script from "./PresentationsAssessingTableImpact.vue?vue&type=script&lang=ts&"
export * from "./PresentationsAssessingTableImpact.vue?vue&type=script&lang=ts&"
import style0 from "./PresentationsAssessingTableImpact.vue?vue&type=style&index=0&id=74c1b28e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74c1b28e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
