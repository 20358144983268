


























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ImpactTable extends Vue {
  @Prop() alternatives: string;
  @Prop() phases: string;
  @Prop() sign: string;
  @Prop() type: string;
  @Prop() impactClass: string;

  // inline css needed because raw html is sent in presentations module
  style: any = {
    impactTable: {
      margin: '0 96px 0 96px',
      // margin-top: var(--spacer);
      height: '96px',
      background: '#f8fafa',
      border: ' 1px solid #E5EAEC',
      overflow: 'hidden',
    },
    vIcon: { margin: '0 8px 0 12px', cursor: 'default' },
    impactRow: { height: ' 32px', borderBottom: '1px solid #E5EAEC', display: 'flex', alignItems: 'center' },
    impactRowLastOfType: { borderBottom: 'none', display: 'flex' },
    impactRowSpan: {
      color: '#6F8195',
      fontSize: '13px',
      lineHeight: '20px',
      letterSpacing: '0.23px',
      fontStyle: 'italic',
      paddingRight: '6px',
    },
    impactCol: {
      flex: '1',
      borderRight: ' 1px solid #E5EAEC',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    impactColLastOfType: { borderRight: 'none' },
  };

  mounted() {
    const table = this.$refs.impactTable as HTMLElement;
    table.querySelectorAll('.v-icon svg').forEach((el) => {
      (el as HTMLElement).style.height = '16px';
      (el as HTMLElement).style.width = '16px';
    });
  }
}
