




































































































































import { Component, Mixins, Vue } from 'vue-property-decorator';
import { BaseControlConfigSkeleton } from 'v-form-builder';

@Component
export default class FormConfigSelect extends Mixins(Vue, BaseControlConfigSkeleton) {
  control: {
    items: {
      value: string;
      text: string;
      disabled?: boolean;
    }[];
  };

  updateDisabled(index: number, event: Event) {
    if ((event.target as HTMLInputElement).checked) this.control.items[index].disabled = true;
    else delete this.control.items[index].disabled;
  }
}
