







































import Vue from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { ChapterItem } from '@/app/shared/models/reports/ChapterItem';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import { ChapterItemMode } from '@/app/shared/types/chapter-item-mode.type';
import TinyEditor from '@/app/shared/components/TinyEditor.vue';
import _debounce from 'lodash.debounce';
import { UpdateChapterItemPayload, WS_DEBOUNCE_VALUE } from '@/app/shared/store/modules/reports.store-module';

@Component({
  components: { TinyEditor },
})
export default class SingleImpactHeaderChapterItem extends Vue {
  @Prop() item: ChapterItem;
  @Prop() mode: ChapterItemMode;

  ChapterItemType: ChapterItemType;

  title: string = null;

  @Emit('switchMode')
  switchMode(_newMode: ChapterItemMode) {
    // just emiting event
  }

  @Emit('update')
  updateChapterItem(_payload: UpdateChapterItemPayload) {
    // just emiting event
  }

  created() {
    // lifecycle hook
    // this.text = this.item.text || '';
  }

  onTitleInput = _debounce((event: string) => {
    this.updateChapterItem({
      uuid: this.item.uuid,
      type: ChapterItemType.SINGLE_IMPACT_HEADER,
      propertyName: 'custom_title',
      propertyValue: event,
    });
  }, WS_DEBOUNCE_VALUE);
}
