


































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class PresentationTable extends Vue {
  @Prop() header: { text: string; icon: string };
  @Prop() columns: { text: string; value: string; isImage?: string }[];
  @Prop() data: Record<string, string>;

  isEmpty(value: any) {
    return value == null || value === '';
  }
}
