























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FactorInput from './formulate/factors/FactorInput.vue';
import CriteriaFactor from './formulate/factors/CriteriaFactor.vue';
import {
  CheckTreeItemWithFactor,
  ToggleConnectCriteriaForPhasePayload,
} from '@/app/shared/store/modules/calculations.store-module';
import { namespace } from 'vuex-class';
import LoaderService from '@/app/shared/utils/loader.service';
import { UnitAlternativePhase } from '@/app/shared/models/calculations/UnitAlternative';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import { ModuleProperties } from '@/app/shared/models/Module';
const CalculationsStore = namespace('Calculations');
const ModulePropertiesStore = namespace('ModuleProperties');

@Component({ components: { FactorInput, CriteriaFactor } })
export default class Criteria extends Vue {
  @Prop() private criteria: CheckTreeItemWithFactor;
  @Prop() unitUuid: string;
  @Prop() mode: 'assess' | 'formulate';
  // if mode is 'assess' alternativePhase should be passed as prop
  @Prop() alternativePhase: UnitAlternativePhase;

  currentlySelectedChecktreeItemId: number = null;
  radioGroupValue: number = null;

  @ModulePropertiesStore.Getter
  moduleProperties: ModuleProperties;

  @CalculationsStore.Action
  toggleConnectCriteriaForPhase: (payload: ToggleConnectCriteriaForPhasePayload) => Promise<any>;

  created() {
    if (this.mode === 'assess') {
      this.initializeDefaultValuesForAssessCriteria();
      eventBus.$on(EventType.ASSESSMENT_SELECTION_PASTED, () => {
        this.initializeDefaultValuesForAssessCriteria();
      });
    }
  }

  @Watch('alternativePhase', { deep: true })
  onAlternativePhaseChange(newVal: UnitAlternativePhase, oldVal: UnitAlternativePhase) {
    if (!oldVal) return;
    if (newVal.uuid !== oldVal.uuid) {
      this.initializeDefaultValuesForAssessCriteria();
    }
  }

  @Watch('radioGroupValue')
  async onRadioGroupChange(_newVal: number, _oldVal: number) {
    // console.log(
    //   this.criteria.title,
    //   'radioGroupValue: ' + this.radioGroupValue,
    //   'currentlySelected: ' + this.currentlySelectedChecktreeItemId,
    // );

    if (this.radioGroupValue === this.currentlySelectedChecktreeItemId) {
      return;
    }
    try {
      LoaderService.disableHttpLoader();
      if (this.currentlySelectedChecktreeItemId !== null) {
        await this.toggleConnectCriteriaForPhase({
          unitUuid: this.unitUuid,
          checktreeItemId: this.currentlySelectedChecktreeItemId,
          phase: this.alternativePhase,
          type: 'disconnect',
          module: this.moduleProperties,
        });
      }
      await this.toggleConnectCriteriaForPhase({
        unitUuid: this.unitUuid,
        checktreeItemId: this.radioGroupValue,
        phase: this.alternativePhase,
        type: 'connect',
        module: this.moduleProperties,
      });
      this.currentlySelectedChecktreeItemId = this.radioGroupValue;

      // console.log(this.alternativePhase.selections);
    } catch (error) {
      console.error(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  get label() {
    return this.criteria.title;
  }

  get radioButtonGroupOptions() {
    return this.criteria.children.map((el) => {
      return {
        label: el.title,
        value: el.id,
      };
    });
  }

  initializeDefaultValuesForAssessCriteria() {
    this.currentlySelectedChecktreeItemId = null;
    this.radioGroupValue = null;
    for (const item of this.criteria.children) {
      if (
        this.alternativePhase.selections &&
        this.alternativePhase.selections.findIndex((el) => el.uuid === item.uuid) > -1
      ) {
        this.currentlySelectedChecktreeItemId = item.id;
        this.radioGroupValue = item.id;
        break;
      }
    }
  }
}
