var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"matrix-item-wrapper d-flex justify-center align-center second-type-row",class:[
    { 'blue-background': _vm.matrixRelationsData.matrix[_vm.i][_vm.j] },
    {
      'default-cursor': _vm.columnItem.isParent && _vm.columnItem.level == 0 && _vm.columnItem.behave_as == _vm.matrixTypes.RADIO,
    } ],on:{"click":function($event){return _vm.cellClick(_vm.matrixRelationsData.matrix[_vm.i][_vm.j])}}},[(_vm.matrixRelationsData.matrix[_vm.i][_vm.j])?_c('v-icon',[_vm._v("$radio-checked")]):_vm._e(),(
      !_vm.matrixRelationsData.matrix[_vm.i][_vm.j] &&
        _vm.columnItem.behave_as == _vm.matrixTypes.CHECKBOX &&
        ((_vm.rowItem.isParent && _vm.rowItem.level == 0) || (_vm.columnItem.isParent && _vm.columnItem.level == 0))
    )?_c('v-icon',{staticClass:"parent-icon-hover"},[_vm._v("$radio-checked")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }