import httpClient from '@/app/shared/api/axios.config';
import { ModuleChecktree, ModuleProperties } from '@/app/shared/models/Module';
// import { CheckTree } from '@/app/shared/models/CheckTree';
import { PresentationsTabType } from './../../types/presentations.types';

const MODULE_ENDPOINT = '/module';

export default {
  async getModuleProperties(url: string): Promise<ModuleProperties> {
    if (url.includes('/study-registration')) {
      const url_parts = url.split('/');
      if (typeof url_parts[2] == 'undefined') {
        return null;
      }
    }

    return await httpClient.post(`${MODULE_ENDPOINT}/properties/`, {
      url,
    });
  },

  async getModuleFilters(
    studyUuid: string,
    moduleUuid: string,
    activePresentationTab: PresentationsTabType = null,
  ): Promise<ModuleChecktree[]> {
    return await httpClient.get(`${MODULE_ENDPOINT}/filters/${studyUuid}/${moduleUuid}`, {
      params: { means: activePresentationTab },
    });
  },

  // async getModuleChecktrees(
  //   studyUuid: string,
  //   moduleUuid: string,
  //   activePresentationTab: PresentationsTabType = null,
  // ): Promise<ModuleChecktree[]> {
  //   return await httpClient.get(`${MODULE_ENDPOINT}/filter/checktrees/${studyUuid}/${moduleUuid}`, {
  //     params: { means: activePresentationTab },
  //   });
  // },

  // async getModuleChecktree(checktreeUuid: string): Promise<CheckTree> {
  //   return await httpClient.get(`${MODULE_ENDPOINT}/filter/checktree/${checktreeUuid}`);
  // },
};
