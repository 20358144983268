














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CommentingReplies extends Vue {
  @Prop() count: number;
  @Prop() type: 'new';

  get replyCount() {
    if (this.count > 9) return '9+';
    return this.count;
  }
}
