





















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class BaseCheckbox extends Vue {
  @Prop() label: string;
  @Prop() value: boolean;
  @Prop({ default: false }) partiallyChecked: boolean;
  @Prop({ default: false }) disabled: boolean;
  @Prop() size: number;
  modelValue: boolean = false;

  created() {
    this.modelValue = this.value;
  }

  @Watch('value')
  onValuePropChange(value: boolean) {
    this.modelValue = value;
  }

  @Emit()
  input(_value: boolean) {
    //
  }

  @Emit()
  change(_value: boolean) {
    //
  }

  toggleCheckbox(_event: Event) {
    if (this.modelValue && this.partiallyChecked) this.modelValue = true;
    else this.modelValue = !this.modelValue;
    this.input(this.modelValue);
    this.change(this.modelValue);
  }
}
