import httpClient from '@/app/shared/api/axios.config';
import { Comment, CommentReply } from '@/app/shared/models/Comment';

const ENDPOINT = '/comments';

export default {
  async createComment(payload: {
    content: string;
    object_model: string;
    object_uuid: string;
    object_item_uuid?: string;
    object_item_type?: string;
    object_item_key?: string;
  }): Promise<Comment> {
    return await httpClient.put(ENDPOINT, payload);
  },

  async getComments(objectModel: string, objectUuid: string, userRole: string): Promise<Comment[]> {
    return await httpClient.get(`${ENDPOINT}/${objectModel}/${objectUuid}/${userRole}`);
  },

  async deleteComment(uuid: string): Promise<void> {
    return await httpClient.delete(`${ENDPOINT}/${uuid}`);
  },

  async updateComment(payload: {
    uuid: string;
    content?: string;
    is_unseen?: boolean;
    is_important?: boolean;
    is_resolved?: boolean;
    is_expanded?: boolean;
  }): Promise<Comment> {
    return await httpClient.post(`${ENDPOINT}/${payload.uuid}`, {
      ...(payload.content ? { content: payload.content } : {}),
      ...(payload.is_unseen !== undefined ? { is_unseen: payload.is_unseen } : {}),
      ...(payload.is_important !== undefined ? { is_important: payload.is_important } : {}),
      ...(payload.is_resolved !== undefined ? { is_resolved: payload.is_resolved } : {}),
      ...(payload.is_expanded !== undefined ? { is_expanded: payload.is_expanded } : {}),
    });
  },

  async createCommentReply(payload: { commentUuid: string; content: string }): Promise<CommentReply> {
    return await httpClient.put(`${ENDPOINT}/reply/${payload.commentUuid}`, { content: payload.content });
  },

  async deleteCommentReply(uuid: string): Promise<void> {
    return await httpClient.delete(`${ENDPOINT}/reply/${uuid}`);
  },

  async updateCommentReply(payload: { uuid: string; content: string }): Promise<CommentReply> {
    return await httpClient.post(`${ENDPOINT}/reply/${payload.uuid}`, { content: payload.content });
  },

  async linkComment(payload: { sourceUuid: string; targetUuid: string }): Promise<void> {
    return await httpClient.put(`${ENDPOINT}/link/${payload.sourceUuid}/${payload.targetUuid}`);
  },

  async removeLinkedComment(payload: { sourceUuid: string; targetUuid: string }): Promise<void> {
    return await httpClient.delete(`${ENDPOINT}/link/${payload.sourceUuid}/${payload.targetUuid}`);
  },
};
