import maxFiveWords from './max-five-words';

const maxFiveWordsObjectName = (fieldValue: any): boolean => {
  if (Array.isArray(fieldValue.data)) {
    return fieldValue.data.every((item: any) => maxFiveWordsObjectName(item));
  } else if (Array.isArray(fieldValue)) {
    return fieldValue.every((item: any) => maxFiveWordsObjectName(item));
  } else if (typeof fieldValue === 'object' && !Array.isArray(fieldValue) && fieldValue !== null) {
    return maxFiveWords(fieldValue.name);
  }

  return true;
};

export default maxFiveWordsObjectName;
