












import Vue from 'vue';
import { Component, Emit } from 'vue-property-decorator';
import PageHeader from '@/app/shared/components/PageHeader.vue';
@Component({
  components: { PageHeader },
})
export default class WorkflowPageHeader extends Vue {
  @Emit('resume')
  resume() {
    (this.$parent.$refs.chart as any).save();
  }
}
