













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProgressStatus extends Vue {
  @Prop() status: string;

  get statusConfig() {
    switch (this.status) {
      case 'approved':
        return {
          icon: 'approved-16px',
          label: 'Approved',
          color: 'var(--v-green-approve-status-solid)',
        };
      case 'in-progress':
        return {
          icon: 'in-progress-16px',
          label: 'In Progress',
          color: 'var(--v-violet-in-progress-status-solid)',
        };
      case 'rejected':
        return {
          icon: 'rejected-16px',
          label: 'Rejected',
          color: 'var(--v-red-error-solid)',
        };
      case 'under-review':
        return {
          icon: 'under-review-16px',
          label: 'Under Review',
          color: 'var(--v-violet-under-review-status-solid)',
        };
      case 'under-revision':
        return {
          icon: 'under-revision-16px',
          label: 'Under Revision',
          color: 'var(--v-orange-under-revision-status-solid)',
        };
      case 'demo':
      default:
        return {
          icon: 'demo-16px',
          label: 'Demo',
          color: 'var(--v-gray-dark)',
        };
    }
  }
}
