import { Study } from './../../models/Study';
import LibrarySection from '@/app/shared/models/library/LibraryItems';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import api from '../../api';
@Module({ namespaced: true })
export class LibraryModule extends VuexModule {
  private libraryItems: Array<LibrarySection> = null;
  private study: Study;

  get getLibraryItems(): Array<LibrarySection> {
    return this.libraryItems;
  }

  @Mutation
  setLibraryItems(items: Array<LibrarySection>) {
    this.libraryItems = items;
  }

  @Mutation
  setStudy(value: Study) {
    this.study = value;
  }

  @Action({ rawError: true })
  async getLibraryContent(studyUuid?: string) {
    const study = await api.study.getStudy(studyUuid);

    this.context.commit('setStudy', study);

    const data = await api.library.getLibraryContent(study.uuid);
    const libItems: Array<LibrarySection> = [];
    for (const key in data) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const section: LibrarySection = data[key];
      libItems.push(section);
    }
    this.context.commit('setLibraryItems', libItems);
  }
}
