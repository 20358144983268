












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class VersioningBadge extends Vue {
  @Prop() id: number | string;
  @Prop({ default: 'small' }) size: 'large' | 'medium' | 'small';
  @Prop({ default: false, type: Boolean }) isActive: boolean;
  @Prop({ default: false, type: Boolean }) isDark: boolean;
}
