var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column form-field-event"},[_vm._l((_vm.events),function(event,index){return [_c('div',{key:("event-" + index + "-0"),staticClass:"d-flex form-field-event__row-container"},[_c('div',{staticClass:"flex-grow-1",class:{
          'form-field-event--show-error': _vm.isMaxFiveWordsObjectName,
        }},[_c('FormFieldText',{ref:"formField",refInFor:true,attrs:{"control":{
            uniqueId: ((_vm.control.uniqueId) + "_name"),
            name: ("name_" + index),
            label: 'Event name',
            placeholderText: 'Enter',
            isDisabled: _vm.control.isDisabled,
            isReadonly: _vm.control.isReadonly,
            subLabel: '5 words maximum',
          }},model:{value:(event.name),callback:function ($$v) {_vm.$set(event, "name", $$v)},expression:"event.name"}})],1),_c('div',{staticClass:"flex-grow-1"},[_c('FormFieldDate',{attrs:{"control":{
            uniqueId: ((_vm.control.uniqueId) + "_date"),
            name: ("date_" + index),
            label: 'Event date',
            placeholderText: 'DD/MM/YYYY',
            isDisabled: _vm.control.isDisabled,
            isReadonly: _vm.control.isReadonly,
          }},model:{value:(event.date),callback:function ($$v) {_vm.$set(event, "date", $$v)},expression:"event.date"}})],1)]),(!_vm.control.isDisabled && !_vm.control.isReadonly)?_c('div',{key:("event-" + index + "-3")},[_c('div',{staticClass:"d-flex align-center form-field-event__add-group",class:{
          'form-field-event__add-group--is-sm': _vm.$vuetify.breakpoint.smAndDown,
        }},[_c('v-icon',{on:{"click":function($event){return _vm.addGroup(index)}}},[_vm._v(" $add-v2 ")])],1)]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }