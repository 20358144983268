







































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import { debounce, cloneDeep, DebouncedFunc } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { StudyEvent, StudyStatus } from '@/app/shared/models/Study';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

dayjs.extend(customParseFormat);

const UserStore = namespace('User');

@Component
export default class StudyReviewsTimeline extends Vue {
  @Prop() status: StudyStatus;
  @Prop() events: StudyEvent[];
  @Prop() isShown: boolean;
  @Prop() isExpanded: boolean;
  @Prop() scrollContainer: Element;
  valueFormat: string = 'YYYY-MM-DD';
  handleDebouncedScroll: DebouncedFunc<(event: any) => void>;
  transitionIntervalId: number = null;

  @UserStore.Action
  public isAllowed: (permission: string | string[]) => Promise<boolean>;

  @Emit('add')
  add() {
    //
  }

  @Watch('events')
  onEventsChange(events: StudyEvent[]) {
    if (events && events.length) this.registerScrollEventHandler();
    else this.unregisterScrollEventHandler();
  }

  @Watch('isExpanded')
  onIsExpandedChange() {
    this.transitionIntervalId = setInterval(this.rerenderTooltips, 10);
    setTimeout(() => {
      clearInterval(this.transitionIntervalId);
      this.transitionIntervalId = null;
    }, 201);
  }

  @AsyncComputed()
  async isAddEventsAllowed() {
    return await this.isAllowed(UserPermission.TIMELINE_FORMS_ACCESS);
  }

  get statusColor() {
    switch (this.status) {
      case 'approved':
        return 'var(--v-green-approve-status-solid)';
      case 'in-progress':
        return 'var(--v-violet-in-progress-status-solid)';
      case 'rejected':
        return 'var(--v-red-error-solid)';
      case 'under-review':
        return 'var(--v-violet-under-review-status-solid)';
      case 'under-revision':
        return 'var(--v-orange-under-revision-status-solid)';
      default:
        return 'var(--v-gray-dark)';
    }
  }

  get timelineEvents() {
    const events = cloneDeep(this.events);

    const processedEvents =
      events &&
      events.filter((event) => event.date).map((event) => ({ ...event, dayObj: dayjs(event.date, this.valueFormat) }));

    return (
      processedEvents &&
      (processedEvents as (StudyEvent & { dayObj: Dayjs })[]).sort((a, b) => {
        if (a.dayObj < b.dayObj) return -1;
        if (a.dayObj > b.dayObj) return 1;
        return 0;
      })
    );
  }

  get shownTimelineEvents() {
    return this.timelineEvents.reduce(
      (shownTimelineEvents, timelineEvent) => ({
        ...shownTimelineEvents,
        [timelineEvent.id]: this.isFutureDate(timelineEvent.dayObj) || this.isShown,
      }),
      {},
    );
  }

  get firstEventDayObj() {
    return (this.timelineEvents && this.timelineEvents[0] && this.timelineEvents[0].dayObj) || dayjs();
  }

  get lastEventDayObj() {
    return (
      (this.timelineEvents &&
        this.timelineEvents[this.timelineEvents.length - 1] &&
        this.timelineEvents[this.timelineEvents.length - 1].dayObj) ||
      dayjs()
    );
  }

  get currentProgress() {
    let progress = this.getDateRelativePosition(dayjs());
    if (progress > 100) progress = 100;

    // Make sure to reference a computed property in order to trigger updates!
    return this.timelineEvents && `calc(${progress}% - var(--dot-size))`;
  }

  mounted() {
    if (this.events && this.events.length) this.registerScrollEventHandler();
  }

  beforeDestroyed() {
    if (this.events && this.events.length) this.unregisterScrollEventHandler();
    clearInterval(this.transitionIntervalId);
    this.transitionIntervalId = null;
  }

  dayjs(...args: any) {
    return dayjs(...args);
  }

  getTooltipClass(event: StudyEvent & { dayObj: Dayjs }) {
    let tooltipClass = 'study-reviews-timeline__tooltip-content';

    if (this.isFutureDate(event.dayObj)) tooltipClass += ' study-reviews-timeline__tooltip-content--is-future';
    else tooltipClass += ' study-reviews-timeline__tooltip-content--is-past';

    if (event.is_admin) tooltipClass += ' study-reviews-timeline__tooltip-content--is-admin';

    return tooltipClass;
  }

  isPastDate(dayObj: Dayjs) {
    return dayObj.diff(dayjs()) <= 0;
  }

  isFutureDate(dayObj: Dayjs) {
    return dayObj.diff(dayjs()) > 0;
  }

  getDateRelativePosition(dayObj: Dayjs) {
    return (dayObj.diff(this.firstEventDayObj) / this.lastEventDayObj.diff(this.firstEventDayObj)) * 100;
  }

  getDateLeftPosition(dayObj: Dayjs) {
    const position = this.getDateRelativePosition(dayObj);
    return position === 0 ? 0 : `calc(${position}% - var(--dot-size))`;
  }

  rerenderTooltips() {
    if (!this.$refs.tooltip) return;

    // Re-render shown tooltips, so they appear to be in sync with the scroll container.
    (this.$refs.tooltip as (Vue & { activate: () => void })[]).forEach((tooltip) => {
      tooltip.activate();
    });
  }

  registerScrollEventHandler() {
    this.handleDebouncedScroll = debounce(this.rerenderTooltips, 10);
    this.scrollContainer && this.scrollContainer.addEventListener('scroll', this.handleDebouncedScroll);
  }

  unregisterScrollEventHandler() {
    this.scrollContainer && this.scrollContainer.removeEventListener('scroll', this.handleDebouncedScroll);
    this.handleDebouncedScroll = null;
  }
}
