



























import Vue from 'vue';
import Component from 'vue-class-component';
import LibrarySection from './LibrarySection.vue';
import { namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';
import Loader from '@/app/shared/components/Loader.vue';
import { eventBus, EventType } from '../event-bus/eventBus';
import type LibrarySectionType from '@/app/shared/models/library/LibraryItems';

const LibraryModuleStore = namespace('Library');
const UiModuleStore = namespace('UI');

@Component({
  components: {
    LibrarySection,
    Loader,
  },
})
export default class Library extends Vue {
  @Prop() studyUuid: string;
  @Prop() defaultWidth: number;
  readonly maxWidth = 648;
  readonly minWidth = 180;
  public loadingData = true;

  @UiModuleStore.Mutation
  setFooterLibraryActive!: (value: boolean) => void;

  @LibraryModuleStore.Action
  getLibraryContent!: (studyUuid: string) => Promise<any>;

  @LibraryModuleStore.Getter
  getLibraryItems: Array<LibrarySectionType>;

  async mounted() {
    const element: any = this.$refs.resizeGrabber;
    element.$el.addEventListener('mousedown', this.initResize, false);
    // const elem = document.getElementsByClassName('resize-grabber');
    // elem[0].addEventListener('mousedown', this.initResize, false);

    // Get library content for the current study (envigo-api:#37).
    await this.getLibraryContent(this.studyUuid);
    this.loadingData = false;

    eventBus.$on(EventType.LIBRARY_UPDATE, this.onNewLibraryParagraph);
  }

  async onNewLibraryParagraph() {
    try {
      await this.getLibraryContent(this.studyUuid);
    } catch (error) {
      console.warn(error);
    }
  }

  //Window funtion mousemove & mouseup
  initResize() {
    window.addEventListener('mousemove', this.resize, false);
    window.addEventListener('mouseup', this.stopResize, false);
  }
  //resize the element
  resize(e: any) {
    const element: any = document.getElementsByClassName('library-wrapper');
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    let newWidth = vw - e.clientX;
    if (newWidth > this.maxWidth) {
      newWidth = this.maxWidth;
    }
    if (newWidth < this.minWidth) {
      newWidth = this.minWidth;
    }
    element[0].style.width = `${newWidth}px`;
  }
  //on mouseup remove windows functions mousemove & mouseup
  stopResize() {
    window.removeEventListener('mousemove', this.resize, false);
    window.removeEventListener('mouseup', this.stopResize, false);
  }

  destroyed() {
    eventBus.$off(EventType.LIBRARY_UPDATE, this.onNewLibraryParagraph);
  }
}
