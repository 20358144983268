import FormConfigAssessor from '@/app/shared/components/form/config/FormConfigAssessor.vue';
import FormFieldAssessor from '@/app/shared/components/form/field/FormFieldAssessor.vue';
import { Assessor } from '@/app/shared/models/form/field/FormFieldAssessor';
import { FormFieldValue, FormFieldValueModel } from '@/app/shared/models/form/field';

export default {
  name: 'Assessor Control',
  description: 'Complex input for assessor info',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldAssessor,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigAssessor,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    helpText: '',
    isDisabled: false,
    isReadonly: false,

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {FormFieldValue<Assessor>}
   */
  rendererDefaultData(): FormFieldValue<Assessor> {
    return new FormFieldValue([new Assessor()], FormFieldValueModel.ASSESSOR);
  },
};
