import iconsValues from '@/assets/icons';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // Overrides for default Vuetify theme colors.
        primary: '#172983', // blue-envigo
        secondary: '#2DB8D2', // green-eon-turquoise
        white: '#FFFFFF',
        blue: '#22ADD0', // blue-primary
        gray: '#697F98', // gray-dark
        light: '#D5E0E6', // gray-light
        success: '#00B4B4', // green-approve-status-solid
        danger: '#F65555', // red-error-solid
        'danger-pale': '#FAEAEA', // red-error-highlight
        disabled: '#E7F0F3', // gray-pale
        warning: '#F08558', // orange-warning
        dark: '#293B51', // gray-ultra-dark
        progress: '#9C69C7', // violet-in-progress-status-solid

        /*
         * ENVIGO COLOR PALETTE
         * @updated 2022-01-01
         */

        // Beige
        'beige-receptors-anthroposphere': '#C9B28B',

        // Blue
        'blue-checktrees-module': '#14A3BE',
        'blue-decisions-report-module': '#107AA1',
        'blue-envigo': '#172983',
        'blue-matrices-module': '#1E7495',
        'blue-medium': '#CAECF9',
        'blue-pale': '#EBFDFF',
        'blue-primary': '#22ADD0',
        'blue-receptors-atmosphere': '#8AE2EE',

        // Brown
        'brown-receptors-lithosphere': '#73545F',

        // Gray
        'gray-dark': '#697F98',
        'gray-deep-navy': '#1E2A37',
        'gray-extra-light': '#F4F9FB',
        'gray-light': '#D5E0E6',
        'gray-medium': '#9BAEBE',
        'gray-midnight': '#131E2A',
        'gray-pale': '#E7F0F3',
        'gray-ultra-dark': '#293B51',

        // Green
        'green-presentation-module': '#1D6871',
        'green-questionnaire-module': '#05808A',
        'green-reporting-all-modules': '#185264',
        'green-approve-status-highlight': '#EEFCF6',
        'green-approve-status-solid': '#00B4B4',
        'green-eon-turquoise': '#2DB8D2',
        'green-impact-score-1': '#DCF56E',
        'green-impact-score-2': '#BEE682',
        'green-impact-score-3': '#9BD796',
        'green-impact-score-4': '#78B9A0',
        'green-impact-score-5': '#37918C',
        'green-impact-score-6': '#376E78',
        'green-receptors-biosphere': '#277174',
        'green-receptors-hydrosphere': '#02B3CD',

        // Orange
        'orange-warning': '#F08558',
        'orange-impact-score-3': '#F0A546',
        'orange-impact-score-4': '#FF823C',
        'orange-under-revision-status-highlight': '#FFF7E9',
        'orange-under-revision-status-solid': '#DF961D',

        // Red
        'red-error-highlight': '#FAEAEA',
        'red-error-solid': '#F65555',
        'red-impact-score-5': '#F55032',
        'red-impact-score-6': '#FF0032',

        // Violet
        'violet-impact-interaction-module': '#46396A',
        'violet-processors-module': '#3F5492',
        'violet-reviews-module': '#4C4285',
        'violet-in-progress-status-highlight': '#F9F1FD',
        'violet-in-progress-status-solid': '#9C69C7',
        'violet-receptors-sociosphere': '#9B639E',
        'violet-under-review-status-highlight': '#EEF0FB',
        'violet-under-review-status-solid': '#7166D7',
        'yellow-impact-score-1': '#F5F564',
        'yellow-impact-score-2': '#F0D23C',
        'yellow-important-status-highlight': '#FCF9E4',
        'yellow-important-status-solid': '#D8BB30',
      },
    },
    options: {
      customProperties: true,
      variations: false,
    },
  },
  icons: {
    values: iconsValues,
  },
});
