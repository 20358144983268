
















































function roundTo20(number: number) {
  return number < 20 ? 20 : number;
}

import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import WorkflowPageHeader from './components/WorkflowPageHeader.vue';
import WorkflowNodeModal from '@/app/workflow/components/WorkflowNodeModal.vue';
import WorkflowConnectionModal from '@/app/workflow/components/WorkflowConnectionModal.vue';
import WorkflowActionButtons from '@/app/workflow/components/WorkflowActionButtons.vue';
import PageFooter from '@/app/shared/components/PageFooter.vue';
import { WorkflowResponse } from '@/app/shared/models/workflow/WorkflowResponse';

import * as d3 from 'd3';
import FlowChart from 'flowchart-vue';
import api from '@/app/shared/api';
import { namespace } from 'vuex-class';

Vue.use(FlowChart);

const WorkflowStoreModule = namespace('Workflow');
@Component({
  components: { WorkflowConnectionModal, WorkflowPageHeader, PageFooter, WorkflowNodeModal, WorkflowActionButtons },
})
export default class Workflow extends Vue {
  loading = true;

  nodeForm: Record<string, unknown> = { target: null };
  connectionForm: Record<string, unknown> = { target: null };
  nodeModalVisible: boolean = false;
  connectionModalVisible: boolean = false;

  width: string = '100%';
  height: string = '3200px';
  nodes: Record<string, unknown> = {};
  connections: Record<string, unknown> = {};

  render = function(g: any, node: any, isSelected: any) {
    node.width = node.width || 120;
    node.height = node.height || 60;
    const borderColor = isSelected ? '#666666' : '#bbbbbb';
    if (node.type !== 'start' && node.type !== 'end') {
      // title
      g.append('rect')
        .attr('x', node.x)
        .attr('y', node.y)
        .attr('stroke', borderColor)
        .attr('class', 'title')
        .style('height', '20px')
        .style('fill', '#f1f3f4')
        .style('stroke-width', '1px')
        .style('width', node.width + 'px');

      g.append('text')
        .attr('x', node.x + 4)
        .attr('y', node.y + 15)
        .attr('class', 'unselectable')
        .text(() => node.name)
        .each(function wrap() {
          // eslint-disable-next-line prefer-const
          let self = d3.select(this),
            textLength = self.node().getComputedTextLength(),
            text = self.text();
          while (textLength > node.width - 2 * 4 && text.length > 0) {
            text = text.slice(0, -1);
            self.text(text + '...');
            textLength = self.node().getComputedTextLength();
          }
        });
    }

    // body
    const body = g.append('rect').attr('class', 'body');
    body
      .style('width', node.width + 'px')
      .style('fill', 'white')
      .style('stroke-width', '1px');

    if (node.type !== 'start' && node.type !== 'end') {
      body.attr('x', node.x).attr('y', node.y + 20);
      body.style('height', roundTo20(node.height - 20) + 'px');
    } else {
      body
        .attr('x', node.x)
        .attr('y', node.y)
        .classed(node.type, true)
        .attr('rx', 30);
      body.style('height', roundTo20(node.height) + 'px');
    }
    body.attr('stroke', borderColor);

    const text = node.module.name ? node.module.name : 'No Name';

    let bodyTextY;
    if (node.type !== 'start' && node.type !== 'end') {
      bodyTextY = node.y + 25 + roundTo20(node.height - 20) / 2;
    } else {
      bodyTextY = node.y + 5 + roundTo20(node.height) / 2;
    }
    g.append('text')
      .attr('x', node.x + node.width / 2)
      .attr('y', bodyTextY)
      .attr('class', 'unselectable')
      .attr('text-anchor', 'middle')
      .text(function() {
        return text;
      })
      .each(function wrap() {
        // eslint-disable-next-line prefer-const
        let self = d3.select(this),
          textLength = self.node().getComputedTextLength(),
          text = self.text();
        while (textLength > node.width - 2 * 4 && text.length > 0) {
          text = text.slice(0, -1);
          self.text(text + '...');
          textLength = self.node().getComputedTextLength();
        }
      });
  };

  @WorkflowStoreModule.Getter
  getWorkflow!: Record<any, WorkflowResponse>;
  @WorkflowStoreModule.Mutation
  setWorkflow!: (data: Record<any, WorkflowResponse>) => void;

  @WorkflowStoreModule.Mutation
  setCurrentNode!: (data: any) => void;

  @WorkflowStoreModule.Mutation
  setCurrentConnection!: (data: any) => void;

  @WorkflowStoreModule.Mutation
  setChecktrees!: (data: any) => void;

  @WorkflowStoreModule.Mutation
  setMatrices!: (data: any) => void;

  @WorkflowStoreModule.Mutation
  setFlows!: (data: any) => void;

  async created() {
    const workflowData: any = await api.workflow.getByUuid(this.$route.params.uuid);
    this.setWorkflow(workflowData);

    this.nodes = workflowData.nodes ? JSON.parse(workflowData.nodes) : [];
    this.connections = workflowData.connections ? JSON.parse(workflowData.connections) : [];

    const checktrees: any = await api.workflow.getAdminChecktrees();
    this.setChecktrees(checktrees);

    const matrices: any = await api.workflow.getTaggingMatrices();
    this.setMatrices(matrices);

    const flows: any = await api.workflow.getAllFlows((this.getWorkflow as any).id);
    this.setFlows(flows);

    this.loading = false;
  }

  async handleChartSave(nodes: any, connections: any) {
    const data = {
      nodes: JSON.stringify(nodes),
      connections: JSON.stringify(connections),
    };
    await api.workflow.saveNodesAndConnections(this.$route.params.uuid, data);
  }

  async handleEditNode(node: any) {
    this.setCurrentNode(node);
    this.nodeForm.target = node;
    this.nodeModalVisible = true;
  }

  async handleAddNode(node: any) {
    const data = {
      type: node.module.type,
      workflow_id: this.getWorkflow.id,
      result_type: node.module.type == 'CrossconnectMatrixModule' ? 'checktree' : '',
      binding_direction: ['TaggingUserMatrixModule', 'TaggingAdminMatrixModule'].includes(node.module.type)
        ? 'y_x'
        : '',
    };
    const response = await api.workflow.addModule(data);
    node.module = response.data;

    node.width = 180;
    node.height = 60;

    this.workflowSave();
  }

  async removeNode(node: any) {
    if (node.module) {
      await api.workflow.deleteModule(node.module.uuid);
    }
    this.workflowSave();
  }

  workflowSave() {
    this.handleChartSave((this.$refs.chart as any).internalNodes, (this.$refs.chart as any).internalConnections);
  }

  closeNodeDialog() {
    this.nodeModalVisible = false;
  }

  closeConnectionDialog() {
    this.connectionModalVisible = false;
  }

  handleEditConnection(connection: any) {
    this.setCurrentConnection(connection);
    this.connectionForm.target = connection;
    this.connectionModalVisible = true;
  }

  async addConnection(connection: any, internalNodes: any) {
    const sourceNode = internalNodes.filter((item: { id: any }) => item.id === connection.source.id);
    const destinationNode = internalNodes.filter((item: { id: any }) => item.id === connection.destination.id);

    const data = {
      from_id: sourceNode[0].module.id,
      to_id: destinationNode[0].module.id,
      workflow_id: this.getWorkflow.id,
    };
    const response = await api.workflow.addFlow(data);
    connection.flow = response.data;

    this.workflowSave();
  }

  async removeConnection(connection: any) {
    if (connection.flow) {
      await api.workflow.deleteFlow(connection.flow.uuid);
    }

    this.workflowSave();
  }
}
