import { wsInstance } from './../websocket.service';
import { WSMessageCommand } from './../../enums/ws-message-command.enum';
import { WSMessageHandler } from './../../enums/ws-message-handler.enum';
import { WSMessage } from './../../models/WSMessage';

export default {
  updateMatrixItem(
    uuid: string,
    axis_y_item: string,
    axis_x_item: string,
    y_children: boolean,
    x_children: boolean,
    route: string,
    connect: boolean,
  ) {
    const params = {
      uuid: uuid,
      axis_y_item: axis_y_item,
      axis_x_item: axis_x_item,
      y_children: y_children,
      x_children: x_children,
      route: route,
    };
    const command: WSMessageCommand = connect ? WSMessageCommand.CONNECT : WSMessageCommand.DISCONNECT;
    const message: WSMessage = {
      handler: WSMessageHandler.MATRIX_LOCKER,
      command: command,
      params: params,
      data: {},
    };
    wsInstance.sendMessage(message);
  },
};
