




























































































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ResizeObserver from 'resize-observer-polyfill';
import CommentingCommentContent from './CommentingCommentContent.vue';
import CommentingCommentAdd from './CommentingCommentAdd.vue';
import CommentingCommentLink from './CommentingCommentLink.vue';
import CommentingCommentHeader from './CommentingCommentHeader.vue';
import FormFieldText from '@/app/shared/components/form/field/FormFieldText.vue';
import { Comment, CommentReply, DraftComment } from '@/app/shared/models/Comment';
import { User } from '@/app/shared/models/User';

const CommentsStore = namespace('Comments');
const ReportsStore = namespace('Reports');
const UserStore = namespace('User');

@Component({
  components: {
    CommentingCommentContent,
    CommentingCommentAdd,
    CommentingCommentLink,
    CommentingCommentHeader,
    FormFieldText,
  },
})
export default class CommentingBox extends Vue {
  @Prop() comment: Comment | DraftComment;
  @Prop({ default: false, type: Boolean }) historyMode: boolean;
  @Prop({ default: false, type: Boolean }) isEditModeAllowed: boolean;
  top: number = 0;
  resizeObserver: ResizeObserver = null;
  hasCommentHighlight = false;
  isEditingComment: boolean = false;
  isEditingReply: boolean = false;
  editReplyUuid: string = null;
  isLinking: boolean = false;

  @Emit()
  add(_comment: Comment | DraftComment) {
    //
  }

  @Emit('delete')
  onDelete(_comment: Comment) {
    //
  }

  @Emit('delete-reply')
  onDeleteReply(_commentReply: CommentReply) {
    //
  }

  @Emit()
  cancel() {
    //
  }

  @Watch('comment', { deep: true })
  onIsExpandedChange(comment: Comment | DraftComment) {
    if (this.historyMode) return;
    const reportContents = document.querySelector('#report-contents') as HTMLDivElement;
    if (!reportContents) return;

    let paddingBottom = '68px';

    if (!this.hasExpandedComment) {
      reportContents.style.paddingBottom = paddingBottom;
      return;
    }

    if (this.hasExpandedComment && !comment.is_expanded) return;

    this.$nextTick(() => {
      const commentHeight = (this.$refs.commentingBox as HTMLDivElement).offsetHeight;
      if (commentHeight > 68) paddingBottom = `${commentHeight}px`;
      reportContents.style.paddingBottom = paddingBottom;
    });
  }

  @Watch('historyMode')
  onHistoryModeChange(historyMode: boolean) {
    if (historyMode) this.registerEventListeners();
    else this.deregisterEventListeners();
  }

  @UserStore.Getter
  userId: number;

  @UserStore.Getter
  userModel: User;

  @CommentsStore.State
  comments: Comment[];

  @CommentsStore.Getter
  hasDraftComment: boolean;

  @CommentsStore.Getter
  hasExpandedComment: boolean;

  @CommentsStore.Action
  addCommentReply: (payload: { commentUuid: string; content: string }) => void;

  @CommentsStore.Action
  editCommentReply: (payload: { commentReply: CommentReply; content: string }) => void;

  @CommentsStore.Action
  editComment: (payload: { comment: Comment | CommentReply; content: string }) => void;

  @CommentsStore.Action
  restoreComment: (payload: Comment) => void;

  @CommentsStore.Action
  toggleExpand: (payload: { comment: Comment; isExpanded: boolean }) => void;

  @CommentsStore.Action
  linkComments: (payload: { comment: Comment; uuid: string }) => void;

  @CommentsStore.Action
  unlinkComments: (payload: { comment: Comment; uuid: string }) => void;

  @ReportsStore.Getter
  navigatorCollapsed: boolean;

  get isShown() {
    if (this.historyMode) return true;
    if (this.isDraftComment) return true;
    if (this.hasDraftComment) return false;
    if (this.isResolved) return false;
    if (this.isExpanded) return true;
    if (this.hasExpandedComment) return false;
    return this.hasCommentHighlight;
  }

  get isOwnComment() {
    return this.comment.user?.id === this.userId;
  }

  get isExpanded() {
    return this.comment.is_expanded;
  }

  set isExpanded(isExpanded: boolean) {
    this.toggleExpand({ comment: this.comment as Comment, isExpanded });
  }

  get isDraftComment() {
    return !(this.comment as Comment).created;
  }

  get isImportant() {
    return (this.comment as Comment).is_important;
  }

  get isResolved() {
    return (this.comment as Comment).is_resolved;
  }

  get commentBackgroundColor() {
    if (this.isResolved) return 'var(--v-green-approve-status-highlight)';
    if (this.isImportant) return 'var(--v-yellow-important-status-highlight)';
    if (this.isExpanded) return 'var(--v-blue-pale)';
    return 'var(--v-white)';
  }

  get commentIndicatorIcon() {
    if (this.isExpanded) return 'filled';
    return 'outlined';
  }

  get commentIndicatorColor() {
    if (this.isResolved) return 'transparent';
    if (this.isImportant && this.isExpanded) return 'var(--v-yellow-important-status-solid)';
    if (this.isImportant) return 'var(--v-yellow-important-status-solid)';
    if (this.isExpanded) return 'var(--v-blue-primary)';
    return 'var(--v-blue-primary)';
  }

  get commentHighlightColor() {
    if (this.isResolved) return 'transparent';
    if (this.isImportant && this.isExpanded) return 'var(--v-yellow-important-status-solid)';
    if (this.isImportant) return 'var(--v-yellow-important-status-highlight)';
    if (this.isExpanded) return 'var(--v-blue-medium)';
    return 'var(--v-blue-pale)';
  }

  get commentHighlightStyle() {
    if (!this.commentHighlightColor) return null;
    return `.comment-indicator[data-uuid='${this.comment.uuid}'] .${this.commentIndicatorIcon} { display: block !important; } .comment-indicator[data-uuid='${this.comment.uuid}'] * { color: ${this.commentIndicatorColor}; } .comment-highlight[data-uuid='${this.comment.uuid}'] { background-color: ${this.commentHighlightColor} !important; }`;
  }

  get linkedComments() {
    if (this.isDraftComment) return [];
    return (
      ((this.comment as Comment).linked &&
        this.comments.filter((comment) => (this.comment as Comment).linked.includes(comment.uuid))) ||
      []
    );
  }

  get absoluteOffset() {
    let navigatorHeight = 56;
    if (this.navigatorCollapsed) navigatorHeight = 32;

    // NB: app bar height + toolbar height + navigator height + report contents top padding - comment number offset
    return 48 + 56 + navigatorHeight + 96 - 5;
  }

  mounted() {
    this.registerEventListeners();
  }

  beforeDestroy() {
    this.deregisterEventListeners();
  }

  registerEventListeners() {
    this.setVisibilityAndPosition();
    this.resizeObserver = new ResizeObserver(this.setVisibilityAndPosition);
    this.resizeObserver.observe(document.querySelector('#report-contents'));
  }

  deregisterEventListeners() {
    this.resizeObserver.disconnect();
  }

  setVisibilityAndPosition() {
    const highlightElement = document.querySelector(`[data-uuid="${this.comment.uuid}"]`) as HTMLSpanElement;

    if (!highlightElement || highlightElement.offsetParent === null) {
      if (this.isDraftComment) {
        this.cancel();
        return;
      }

      // if (this.isExpanded) this.isExpanded = false;
      this.hasCommentHighlight = false;
      return;
    }

    this.top = this.getElementAbsoluteTopOffset(highlightElement);
    this.hasCommentHighlight = true;
  }

  getElementAbsoluteTopOffset(elem: HTMLElement) {
    const box = elem.getBoundingClientRect();
    const body = document.body;
    const docEl = document.documentElement;
    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const clientTop = docEl.clientTop || body.clientTop || 0;
    const top = box.top + scrollTop - clientTop;

    return Math.round(top) - this.absoluteOffset;
  }

  addComment(commentContent: string) {
    this.add({ ...this.comment, content: commentContent } as DraftComment);
  }

  addReply(commentReplyContent: string) {
    this.addCommentReply({
      commentUuid: (this.comment as Comment).uuid,
      content: commentReplyContent,
    });
  }

  onEditComment() {
    this.isEditingComment = true;
  }

  onEditReply(replyUuid: string) {
    this.isEditingReply = true;
    this.editReplyUuid = replyUuid;
  }

  onLinkComment() {
    this.isLinking = true;
  }

  saveComment(content: string) {
    this.editComment({ comment: this.comment as Comment, content });
    this.exitEditingComment();
  }

  saveReply(commentReply: CommentReply, content: string) {
    this.editCommentReply({ commentReply, content });
    this.exitEditingReply();
  }

  exitEditingComment() {
    this.isEditingComment = false;
  }

  exitEditingReply() {
    this.isEditingReply = false;
    this.editReplyUuid = null;
  }

  linkComment(numberInput: string) {
    const number = parseInt(numberInput.replace('#', ''), 10);
    const comment = this.comments.find((comment) => comment.number === number);
    this.linkComments({ comment: this.comment as Comment, uuid: comment.uuid });
    this.isLinking = false;
  }

  exitLinking() {
    this.isLinking = false;
  }

  unlinkComment(uuid: string) {
    this.unlinkComments({ comment: this.comment as Comment, uuid });
  }
}
