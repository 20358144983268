import httpClient from '@/app/shared/api/axios.config';
import { User } from '@/app/shared/models/User';
import { CommentValue } from '@/app/shared/models/form/field/FormFieldComment';
import { MessageApiResponse } from '@/app/shared/models/ApiResponse';

const USER_ENDPOINT = '/user';

export default {
  async getUser(userId: number): Promise<User> {
    return await httpClient.get(`${USER_ENDPOINT}/${userId}`);
  },

  async loadUserProfileForm(): Promise<User> {
    return await httpClient.get(`${USER_ENDPOINT}/profile/`);
  },

  async submitUserProfileForm(payload: {
    avatar: string;
    first_name: string;
    last_name: string;
    title: string;
    position: string;
    phone_number: string;
  }): Promise<MessageApiResponse> {
    return await httpClient.post(`${USER_ENDPOINT}/profile/`, {
      avatar: payload.avatar,
      first_name: payload.first_name,
      last_name: payload.last_name,
      title: payload.title,
      position: payload.position,
      phone_number: payload.phone_number,
    });
  },

  async submitChangePasswordForm(payload: { current_password: string; password: string }): Promise<MessageApiResponse> {
    return await httpClient.post(`${USER_ENDPOINT}/change-password`, {
      current_password: payload.current_password,
      password: payload.password,
    });
  },

  async loadEditUserProfileForm(uuid: string, ouuid: string): Promise<User> {
    return await httpClient.get(`${USER_ENDPOINT}/profile/${uuid}/${ouuid}`);
  },

  async submitEditUserProfileForm(
    uuid: string,
    ouuid: string,
    payload: {
      avatar: string;
      first_name: string;
      last_name: string;
      role_id: number;
      status: boolean;
      title: string;
      position: string;
      phone_number: string;
      organization_unit: string[];
      description: CommentValue;
    },
  ): Promise<MessageApiResponse> {
    return await httpClient.post(`${USER_ENDPOINT}/profile/${uuid}/${ouuid}`, {
      avatar: payload.avatar,
      first_name: payload.first_name,
      last_name: payload.last_name,
      role_id: payload.role_id,
      status: payload.status,
      title: payload.title,
      position: payload.position,
      phone_number: payload.phone_number,
      organization_unit: payload.organization_unit,
      description: payload.description,
    });
  },

  async submitChangeMainOrganizationForm(
    uuid: string,
    ouuid: string,
    payload: {
      organization_uuid: string;
    },
  ): Promise<MessageApiResponse> {
    return await httpClient.post(`${USER_ENDPOINT}/profile/${uuid}/${ouuid}`, {
      organization_uuid: payload.organization_uuid,
    });
  },
};
