














































































































































































































































































































































































































































import { Component, Vue, Emit } from 'vue-property-decorator';
import WorkflowCreateChecktreeModal from '@/app/workflow/components/WorkflowCreateChecktreeModal.vue';
import WorkflowCreateMatrixModal from '@/app/workflow/components/WorkflowCreateMatrixModal.vue';
import WorkflowCreateHTUModal from '@/app/workflow/components/WorkflowCreateHTUModal.vue';
import {
  WorkflowModuleStageType,
  WorkflowModuleProductType,
  WorkflowModuleAlternativesType,
  WorkflowModuleMatchingType,
  WorkflowModuleMeansOfPresentationType,
  WorkflowModuleTypeOfReportsType,
} from '@/app/shared/types/workflow.types';
import api from '@/app/shared/api';
import { namespace } from 'vuex-class';
import { WorkflowResponse } from '@/app/shared/models/workflow/WorkflowResponse';
const WorkflowStoreModule = namespace('Workflow');

@Component({
  components: { WorkflowCreateChecktreeModal, WorkflowCreateMatrixModal, WorkflowCreateHTUModal },
})
export default class WorkflowNodeModal extends Vue {
  createChecktreeModalVisible: boolean = false;
  createMatrixModalVisible: boolean = false;
  createHTUModalVisible: boolean = false;

  formName: string = '';
  formSlug: string = '';
  formDescription: string = '';
  formChecktree: number = null;
  formBindingWord: string = '';
  formBindingDirection: string = '';
  formResultType: string = '';
  formMatrix: number = null;
  formAxisX: number = null;
  formAxisXS: Array<any> = [];
  formAxisY: number = null;
  formAxisYS: Array<any> = [];
  formStage: string = '';
  formProductType: string = '';
  formAlternatives: string = '';
  formFilterByClass: number = 0;
  formClassOfImpacts: number = 0;
  formMatching: string = '';
  formMeansOfPresentation: Array<any> = [];
  formTypesOfMeans: { [key: string]: any[] } = {};
  formSelections: Array<any> = [];
  formPresentationSelections: { [key: string]: any[] } = {};
  formTypesOfReports: Array<any> = [];
  formTypeOfReport: string = '';
  formReportingTemplates: { [key: string]: any[] } = {};
  formReports: Array<any> = [];

  flowOptions: Array<any> = [];
  stageOptions: Array<{ label: string; value: WorkflowModuleStageType }> = [];
  productTypeOptions: Array<{ label: string; value: WorkflowModuleProductType }> = [];
  alternativesOptions: Array<{ label: string; value: WorkflowModuleAlternativesType }> = [];
  filterByClassOptions: Array<{ label: string; value: number }> = [];
  matchingOptions: Array<{ label: string; value: WorkflowModuleMatchingType }> = [];
  meansOfPresentationOptions: Array<{ label: string; value: WorkflowModuleMeansOfPresentationType }> = [];
  typesOfMeansOptions: { [key: string]: any[] } = {};
  selectionsOptions: Array<{ label: string; value: string }> = [];
  presentationSelectionsOptions: { [key: string]: any[] } = {};
  typesOfReportsOptions: Array<{ label: string; value: WorkflowModuleTypeOfReportsType }> = [];
  reportingTemplatesOptions: { [key: string]: any[] } = {};
  reportsOptions: Array<any> = [];

  @WorkflowStoreModule.Getter
  getWorkflow!: Record<any, WorkflowResponse>;

  @WorkflowStoreModule.Getter
  getCurrentNode!: Record<any, any>;

  @WorkflowStoreModule.Getter
  getChecktreesOptions!: any;

  @WorkflowStoreModule.Getter
  getMatricesOptions!: any;

  @WorkflowStoreModule.Getter
  getFlows!: any;

  created() {
    this.initializeFlowOptions();
    this.initializeStageOptions();
    this.initializeProductTypeOptions();
    this.initializeAlternatives();
    this.initializeFilterByClassOptions();
    this.initializeMatchingOptions();
    this.initializeMeansOfPresentationOptions();
    this.inicializeTypesOfMeansOptions();
    this.inicializeSelectionsOptions();
    this.inicializePresentationSelectionsOptions();
    this.inicializeTypeOfReportsOptions();
    this.inicializeReportingTemplatesOptions();
    this.inicializeReportsOptions();

    this.formName = this.getCurrentNode.module ? this.getCurrentNode.module.name : '';
    this.formSlug = this.getCurrentNode.module ? this.getCurrentNode.module.slug : '';
    this.formDescription = this.getCurrentNode.module ? this.getCurrentNode.module.description : '';
    this.formChecktree =
      this.getCurrentNode.module && this.getCurrentNode.module.checktree ? this.getCurrentNode.module.checktree.id : '';
    this.formBindingWord = this.getCurrentNode.module ? this.getCurrentNode.module.binding_word : '';
    this.formBindingDirection = this.getCurrentNode.module ? this.getCurrentNode.module.binding_direction : '';
    this.formResultType = this.getCurrentNode.module ? this.getCurrentNode.module.result_type : '';
    this.formMatrix =
      this.getCurrentNode.module && this.getCurrentNode.module.matrix ? this.getCurrentNode.module.matrix.id : '';
    this.formAxisX =
      this.getCurrentNode.module && this.getCurrentNode.module.axis_x ? this.getCurrentNode.module.axis_x : '';
    this.formAxisXS =
      this.getCurrentNode.module && this.getCurrentNode.module.axis_xs ? this.getCurrentNode.module.axis_xs : '';
    this.formAxisY =
      this.getCurrentNode.module && this.getCurrentNode.module.axis_y ? this.getCurrentNode.module.axis_y : '';
    this.formAxisYS =
      this.getCurrentNode.module && this.getCurrentNode.module.axis_ys ? this.getCurrentNode.module.axis_ys : '';
    this.formStage = this.getCurrentNode.module ? this.getCurrentNode.module.stage : '';
    this.formProductType = this.getCurrentNode.module ? this.getCurrentNode.module.product_type : '';
    this.formAlternatives =
      this.getCurrentNode.module && this.getCurrentNode.module.alternatives
        ? this.getCurrentNode.module.alternatives
        : '';
    this.formFilterByClass =
      this.getCurrentNode.module && this.getCurrentNode.module.filter_by_class
        ? this.getCurrentNode.module.filter_by_class
        : 0;
    this.formClassOfImpacts =
      this.getCurrentNode.module && this.getCurrentNode.module.class_of_impacts
        ? this.getCurrentNode.module.class_of_impacts
        : 0;
    this.formMatching =
      this.getCurrentNode.module && this.getCurrentNode.module.matching ? this.getCurrentNode.module.matching : 0;
    this.formMeansOfPresentation =
      this.getCurrentNode.module && this.getCurrentNode.module.means_of_presentation
        ? Object.values(JSON.parse(this.getCurrentNode.module.means_of_presentation))
        : [];
    this.formTypesOfMeans =
      this.getCurrentNode.module && this.getCurrentNode.module.types_of_means
        ? Object.entries(JSON.parse(this.getCurrentNode.module.types_of_means))
            .sort(([, a], [, b]) => Number(a) - Number(b))
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
        : [];
    this.formSelections =
      this.getCurrentNode.module && this.getCurrentNode.module.selections
        ? Object.values(JSON.parse(this.getCurrentNode.module.selections))
        : [];
    this.formPresentationSelections =
      this.getCurrentNode.module && this.getCurrentNode.module.selections
        ? Object.entries(JSON.parse(this.getCurrentNode.module.selections))
            .sort(([, a], [, b]) => Number(a) - Number(b))
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
        : [];
    this.formTypesOfReports =
      this.getCurrentNode.module && this.getCurrentNode.module.types_of_reports
        ? Object.values(JSON.parse(this.getCurrentNode.module.types_of_reports))
        : [];
    this.formTypeOfReport =
      this.getCurrentNode.module && this.getCurrentNode.module.type_of_report
        ? this.getCurrentNode.module.type_of_report
        : '';
    this.formReportingTemplates =
      this.getCurrentNode.module && this.getCurrentNode.module.templates
        ? Object.entries(JSON.parse(this.getCurrentNode.module.templates))
            .sort(([, a], [, b]) => Number(a) - Number(b))
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
        : [];
    this.formReports =
      this.getCurrentNode.module && this.getCurrentNode.module.reports
        ? Object.values(JSON.parse(this.getCurrentNode.module.reports))
        : [];
  }

  async save() {
    const data = {
      name: this.formName ? this.formName : null,
      slug: this.formSlug ? this.formSlug : null,
      description: this.formDescription ? this.formDescription : null,
      checktree_id: this.formChecktree ? this.formChecktree : null,
      matrix_id: this.formMatrix ? this.formMatrix : null,
      binding_word: this.formBindingWord ? this.formBindingWord : null,
      binding_direction: this.formBindingDirection ? this.formBindingDirection : null,
      result_type: this.formResultType ? this.formResultType : null,
      axis_x: this.formAxisX && this.getCurrentNode.module.type == 'CrossconnectMatrixModule' ? this.formAxisX : null,
      axis_xs: this.formAxisXS && this.getCurrentNode.module.type == 'TaggingUserMatrixModule' ? this.formAxisXS : null,
      axis_y: this.formAxisY ? this.formAxisY : null,
      axis_ys:
        this.formAxisYS && this.getCurrentNode.module.type == 'CrossconnectMatrixModule' ? this.formAxisYS : null,
      stage: this.formStage ? this.formStage : null,
      product_type: this.formProductType ? this.formProductType : null,
      alternatives: this.formAlternatives ? this.formAlternatives : null,
      filter_by_class:
        this.getCurrentNode.module.type == 'ProcessorAssessModule' ||
        this.getCurrentNode.module.type == 'ProcessorUserDefineModule' ||
        this.getCurrentNode.module.type == 'TaggingUserMatrixModule'
          ? this.formFilterByClass
          : null,
      class_of_impacts: this.getCurrentNode.module.type == 'PresentationModule' ? this.formClassOfImpacts : null,
      matching:
        this.getCurrentNode.module.type == 'ProcessorAssessModule' ||
        this.getCurrentNode.module.type == 'ProcessorUserDefineModule'
          ? this.formMatching
          : null,
      means_of_presentation:
        this.formMeansOfPresentation && this.getCurrentNode.module.type == 'PresentationModule'
          ? JSON.stringify(Object.assign({}, this.formMeansOfPresentation))
          : null,
      types_of_means:
        this.formTypesOfMeans && this.getCurrentNode.module.type == 'PresentationModule'
          ? JSON.stringify(Object.assign({}, this.formTypesOfMeans))
          : null,
      selections:
        this.formSelections &&
        (this.getCurrentNode.module.type == 'ProcessorAssessModule' ||
          this.getCurrentNode.module.type == 'ProcessorUserDefineModule')
          ? JSON.stringify(this.formSelections)
          : null,
      presentationSelections:
        this.formPresentationSelections && this.getCurrentNode.module.type == 'PresentationModule'
          ? JSON.stringify(Object.assign({}, this.formPresentationSelections))
          : null,
      types_of_reports:
        this.formTypesOfReports && this.getCurrentNode.module.type == 'ReportingPanelModule'
          ? JSON.stringify(Object.assign({}, this.formTypesOfReports))
          : null,
      type_of_report: this.formTypeOfReport ? this.formTypeOfReport : null,
      templates:
        this.formReportingTemplates && this.getCurrentNode.module.type == 'ReportingPanelModule'
          ? JSON.stringify(Object.assign({}, this.formReportingTemplates))
          : null,
      reports:
        this.formReports && this.getCurrentNode.module.type == 'PDFOutputModule'
          ? JSON.stringify(Object.assign({}, this.formReports))
          : null,
    };

    const response = await api.workflow.editModule((this.$attrs.node as any).module.uuid, data);
    (this.$attrs.node as any).module = response.data;

    // Emit node dialog close.
    this.$emit('workflow-node-dialog-close');

    // Emit workflow save.
    this.$emit('workflow-save');
  }

  openCreateChecktreeModal() {
    this.createChecktreeModalVisible = true;
  }

  closeCreateChecktreeModal() {
    this.createChecktreeModalVisible = false;
  }

  openCreateMatrixModal() {
    this.createMatrixModalVisible = true;
  }

  closeCreateMatrixModal() {
    this.createMatrixModalVisible = false;
  }

  openCreateHTUModal() {
    this.createHTUModalVisible = true;
  }

  closeCreateHTUModal() {
    this.createHTUModalVisible = false;
  }

  preSelectNewlyAddedChecktree(checktree: any) {
    this.formChecktree = checktree.id;
  }

  preSelectNewlyAddedMatrix(matrix: any) {
    this.formMatrix = matrix.id;
  }

  initializeFlowOptions() {
    const filterFlows = (flows: Array<any>, module_id: number) => {
      return flows.filter(function(el: any) {
        return el.to_id == module_id;
      });
    };

    const flows = filterFlows(this.getFlows, this.getCurrentNode.module.id);
    for (const flow of flows) {
      this.flowOptions.push({ label: flow.name, value: flow.id });
    }
  }

  initializeStageOptions() {
    this.stageOptions = [
      {
        label: 'Screening',
        value: 'screening',
      },
      {
        label: 'Scoping',
        value: 'scoping',
      },
      {
        label: 'Assessing',
        value: 'assessing',
      },
    ];
  }

  initializeProductTypeOptions() {
    this.productTypeOptions = [
      {
        label: 'Impacts',
        value: 'impact',
      },
      {
        label: 'Methods',
        value: 'method',
      },
    ];
  }

  initializeAlternatives() {
    this.alternativesOptions = [
      {
        label: 'Default Alternative (Proposed Base Case)',
        value: 'default',
      },
      {
        label: 'All Alternatives',
        value: 'all',
      },
      {
        label: 'VECs',
        value: 'vecs',
      },
    ];
  }

  async initializeFilterByClassOptions() {
    this.filterByClassOptions = await api.checktree.getClassOptions();
  }

  initializeMatchingOptions() {
    this.matchingOptions = [
      {
        label: 'None',
        value: null,
      },
      {
        label: 'Impact Method',
        value: 'method',
      },
      {
        label: 'Impact Mitigation',
        value: 'mitigation',
      },
      {
        label: 'Impact Monitoring',
        value: 'monitoring',
      },
    ];
  }

  initializeMeansOfPresentationOptions() {
    this.meansOfPresentationOptions = [
      {
        label: 'Tables',
        value: 'tables',
      },
      {
        label: 'Charts',
        value: 'charts',
      },
      {
        label: 'Statistics',
        value: 'statistics',
      },
      {
        label: 'Impacts',
        value: 'impacts',
      },
    ];
  }

  inicializeTypesOfMeansOptions() {
    // Tables options.
    this.typesOfMeansOptions['tables'] = [
      {
        label: 'Screening',
        value: 'screening',
      },
      {
        label: 'Scoping',
        value: 'scoping',
      },
      {
        label: 'Assessing',
        value: 'assessing',
      },
      // @todo New one will be 'question'.
    ];

    // Charts options.
    this.typesOfMeansOptions['charts'] = [
      {
        label: 'Simple Bar Plot',
        value: 'bar-simple',
      },
      {
        label: 'Grouped Bar Plot',
        value: 'bar-grouped',
      },
      {
        label: 'Stacked & Grouped',
        value: 'bar-stacked',
      },
      {
        label: 'Step Line Plot',
        value: 'line',
      },
      {
        label: 'Simple Pie Chart',
        value: 'pie',
      },
      {
        label: 'Multiples Pie Charts',
        value: 'pie-multiple',
      },
    ];

    // Statistics options.
    this.typesOfMeansOptions['statistics'] = [
      {
        label: 'Number-Count',
        value: 'count',
      },
      {
        label: 'Mean Value',
        value: 'mean',
      },
      {
        label: 'Variance',
        value: 'variance',
      },
    ];
  }

  inicializeTypeOfReportsOptions() {
    this.typesOfReportsOptions = [
      {
        label: 'Full Report',
        value: 'full-report',
      },
      {
        label: 'Summary Report',
        value: 'summary-report',
      },
    ];
  }

  inicializeReportsOptions() {
    this.reportsOptions = [
      {
        label: 'Full Report',
        value: 'full-report',
      },
      {
        label: 'Summary Report',
        value: 'summary-report',
      },
      {
        label: 'Impacts',
        value: 'impacts',
      },
    ];
  }

  async inicializeSelectionsOptions() {
    const modules = await api.workflow.getChecktreeModuleOptions();

    for (const mod of modules) {
      this.selectionsOptions.push({ label: mod.name, value: mod.slug });
    }
  }

  async inicializePresentationSelectionsOptions() {
    const modulesOptions = [];
    const modules = await api.workflow.getChecktreeModuleOptions();

    for (const mod of modules) {
      modulesOptions.push({ label: mod.name, value: mod.slug });
    }

    for (let i = 0; i < this.meansOfPresentationOptions.length; i++) {
      this.presentationSelectionsOptions[this.meansOfPresentationOptions[i].value] = modulesOptions;
    }
  }

  // @todo Load specific template checktrees, not all.
  async inicializeReportingTemplatesOptions() {
    const checktreesOptions = [];
    const checktrees = await api.checktree.getAll();

    for (const checktree of checktrees) {
      checktreesOptions.push({ label: checktree.name, value: checktree.uuid });
    }

    for (let i = 0; i < this.typesOfReportsOptions.length; i++) {
      this.reportingTemplatesOptions[this.typesOfReportsOptions[i].value] = checktreesOptions;
    }
  }

  @Emit()
  close() {
    this.$emit('workflow-node-dialog-close');
  }
}
