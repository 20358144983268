


































import { Component, Emit, Prop, Watch, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseTextArea extends Vue {
  @Prop() private label!: string;
  @Prop() private rows: number;
  @Prop() private fontSize: number;
  @Prop() private value!: string;
  @Prop({ default: false, type: Boolean }) isDark: boolean;
  focused: boolean = false;

  @Watch('focused')
  onFocusedChange(focused: boolean) {
    if (!focused) this.blur();
  }

  @Emit()
  input(value: string) {
    this.change(value);
  }

  @Emit()
  change(_value: string) {
    // just emiting event
  }

  @Emit('paste')
  paste() {
    //
  }

  @Emit()
  blur() {
    //
  }

  mounted() {
    this.setResizeListener();
  }

  resize() {
    const textareaRef: HTMLElement = this.$refs.textareaRef as HTMLElement;
    textareaRef.style.height = 'auto';
    textareaRef.style.height = `${textareaRef.scrollHeight}px`;
  }

  setResizeListener() {
    const textareaRef: HTMLElement = this.$refs.textareaRef as HTMLElement;
    textareaRef.addEventListener('input', this.resize);
  }

  destroyed() {
    const textareaRef: HTMLElement = this.$refs.textareaRef as HTMLElement;
    if (textareaRef) {
      textareaRef.removeEventListener('input', this.resize);
    }
  }

  focus() {
    const textareaRef: HTMLElement = this.$refs.textareaRef as HTMLElement;
    if (textareaRef) textareaRef.focus();
  }
}
