







































































































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import VersioningStar from '@/app/shared/components/versioning/VersioningStar.vue';
import { Report } from '@/app/shared/models/Report';

@Component({
  components: {
    VersioningStar,
  },
})
export default class PdfOutputReportContent extends Vue {
  @Prop() report: Report;
  @Prop({ default: false, type: Boolean }) isToolbarAllowed: boolean;
  @Prop({ default: false, type: Boolean }) isDeleteAllowed: boolean;
  isErrorMessageVisible: boolean = true;
  isGeneratedMessageVisible: boolean = true;
  isDownloadedMessageVisible: boolean = true;
  isEditingName: boolean = false;
  isEditingComment: boolean = false;
  isCommentVisible: boolean = false;

  @Emit('update:name')
  updateName(_newValue: string) {
    //
  }

  @Emit('update:comment')
  updateComment(_newValue: string) {
    //
  }

  @Emit('delete-report')
  deleteReport() {
    //
  }

  @Emit('toggle-important')
  toggleImportant() {
    //
  }

  @Emit('toggle-authority')
  toggleAuthority() {
    //
  }

  @Emit('toggle-public')
  togglePublic() {
    //
  }

  @Watch('report.is_downloading')
  onIsDownloadingChange(isDownloading: boolean) {
    if (isDownloading) this.isDownloadedMessageVisible = true;
  }

  get isActive() {
    return this.report.version_is_active;
  }

  get isAuthority() {
    return this.report.is_authority;
  }

  get isPublic() {
    return this.report.is_public;
  }

  get isImportant() {
    return this.report.is_important;
  }

  get isGenerating() {
    return this.report.status === 'queued';
  }

  get isError() {
    return this.report.status === 'error';
  }

  get isGenerated() {
    return this.report.is_generated;
  }

  get isDownloading() {
    return this.report.is_downloading;
  }

  get isDownloaded() {
    return this.report.is_downloaded;
  }

  editName() {
    if (!this.isToolbarAllowed) return false;
    this.isEditingName = true;
    this.$nextTick(() => this.$refs.editName && (this.$refs.editName as Vue & { focus: () => void }).focus());
  }

  editComment() {
    if (!this.isToolbarAllowed) return false;
    this.isEditingComment = true;
    this.$nextTick(() => this.$refs.editComment && (this.$refs.editComment as Vue & { focus: () => void }).focus());
  }

  exitEditComment() {
    this.isEditingComment = false;
    if (!this.report.comment) this.isCommentVisible = !this.isCommentVisible;
  }

  toggleComment() {
    this.isCommentVisible = !this.isCommentVisible;
    if (this.isCommentVisible && !this.report.comment) this.editComment();
  }

  dismissMessage(isError: boolean, isGenerated: boolean, isDownloaded: boolean) {
    if (isError) this.isErrorMessageVisible = false;
    if (isGenerated) this.isGeneratedMessageVisible = false;
    if (isDownloaded) this.isDownloadedMessageVisible = false;
  }
}
