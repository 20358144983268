var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-grow-1 templates-panel"},[_c('v-tabs',{staticClass:"templates-panel__tabs-container",attrs:{"hide-slider":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.templateGroups),function(templateGroup){return _c('v-tab',{key:templateGroup.slug,staticClass:"templates-panel__tab",attrs:{"disabled":!templateGroup.active,"ripple":false,"data-cy":templateGroup.slug}},[_c('v-icon',[_vm._v("$"+_vm._s(templateGroup.slug === 'summary-report' ? 'summary-report' : 'study-type'))]),_vm._v(" "+_vm._s(templateGroup.name)+" ")],1)}),1),_c('v-tabs-items',{staticClass:"templates-panel__item-container",attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.templateGroups),function(templateGroup){return _c('v-tab-item',{key:templateGroup.slug,attrs:{"transition":"none"}},_vm._l((templateGroup.templates),function(template){return _c('NavigationPanelButton',{key:template.uuid,attrs:{"icon":template.is_blank
            ? 'templates-blank-color-2'
            : templateGroup.slug === 'full-report'
            ? 'full-report'
            : 'summary-report-workspace',"data-cy":template.uuid,"data-icon":template.is_blank
            ? 'templates-blank-color-2'
            : templateGroup.slug === 'full-report'
            ? 'full-report'
            : 'summary-report-workspace'},on:{"click":function($event){return _vm.onTemplateClick(template.uuid, templateGroup.slug)}}},[_vm._v(" "+_vm._s(template.name)+" ")])}),1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }