



























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import UserAvatar from '@/app/shared/components/UserAvatar.vue';
import UserCallout from '@/app/shared/components/UserCallout.vue';
import NavbarCallout from '@/app/shared/components/NavbarCallout.vue';
import api from '@/app/shared/api';
import LoaderService from '@/app/shared/utils/loader.service';
import { User } from '@/app/shared/models/User';
import { Organization } from '@/app/shared/models/Organization';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

const ModulePropertiesStore = namespace('ModuleProperties');
const UserStore = namespace('User');

@Component({
  components: {
    NavbarCallout,
    UserAvatar,
    UserCallout,
  },
})
export default class Navbar extends Vue {
  isOrganizationCalloutVisible: boolean = false;
  isStudyCalloutVisible: boolean = false;
  isUserCalloutVisible: boolean = false;
  userOrganization: Organization = null;

  @ModulePropertiesStore.Getter
  studyUuid: string;

  @ModulePropertiesStore.Getter
  studyName: string;

  @ModulePropertiesStore.Getter
  workspaceUuid: string;

  @ModulePropertiesStore.Getter
  activeStudyVersion: string;

  @UserStore.State
  user: User;

  @UserStore.Getter
  userFullName: string;

  @UserStore.Getter
  userAvatar: string;

  @UserStore.Getter
  userOrganizationUuid: string;

  @UserStore.Getter
  isAuthenticated: boolean;

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  @UserStore.Action
  logoutUser: () => void;

  @Watch('userOrganizationUuid')
  onUserOrganizationUuidChange() {
    this.userOrganization = null;
  }

  @Watch('isOrganizationCalloutVisible')
  onIsOrganizationCalloutVisibleChange(isOrganizationCalloutVisible: boolean) {
    if (!isOrganizationCalloutVisible || this.userOrganization) return;
    this.getUserOrganization();
  }

  @AsyncComputed()
  async userHome() {
    if (!this.isAuthenticated) return '/login';
    // Redirect user to the correct landing screen depending on their permissions.
    if (await this.isAllowed(UserPermission.ADMIN_LANDING_ACCESS)) return '/';
    else if (await this.isAllowed(UserPermission.LANDING_ACCESS)) return '/landing';
    return '/error/404';
  }

  @AsyncComputed()
  async hasBlueBackground() {
    return await this.isAllowed(UserPermission.TOP_BAR_SHOW_BLUE_BACKGROUND);
  }

  @AsyncComputed()
  async hasAvatarInitials() {
    return await this.isAllowed(UserPermission.TOP_BAR_SHOW_AVATAR_INITIALS);
  }

  @AsyncComputed()
  async hasVersionBadge() {
    return await this.isAllowed(UserPermission.TOP_BAR_SHOW_VERSION_BADGE);
  }

  @AsyncComputed()
  async hasOrganizationCallout() {
    return await this.isAllowed(UserPermission.TOP_BAR_SHOW_ORGANIZATION_CALLOUT);
  }

  @AsyncComputed()
  async hasStudyCallout() {
    return await this.isAllowed(UserPermission.TOP_BAR_SHOW_STUDY_CALLOUT);
  }

  get userOrganizationName() {
    return this.userOrganization && this.userOrganization.name;
  }

  get StudyVersionBadge() {
    switch (this.$route.name) {
      case 'CalculationsMethod':
      case 'MatrixRelations':
      case 'Presentations':
      case 'PdfOutput':
      case 'Reports':
      case 'TemplateCheckTree':
      case 'TemplatesPanel':
      case 'UserCheckTree':
      case 'StudyRegistration':
      case 'StudyRegistrationForm':
      case 'StudyReviews':
      case 'StudyReviewsForm':
      case 'Workspace':
        return this.hasVersionBadge;
      default:
        return false;
    }
  }

  @AsyncComputed()
  async StudiesDashboard() {
    switch (this.$route.name) {
      case 'StudiesDashboard':
        return false;
      default:
        return await this.isAllowed(UserPermission.STUDIES_DASHBOARD_ACCESS);
    }
  }

  get StudyDetails() {
    if (!this.studyUuid) return false;

    switch (this.$route.name) {
      case 'CalculationsMethod':
      case 'MatrixRelations':
      case 'Presentations':
      case 'PdfOutput':
      case 'Reports':
      case 'TemplateCheckTree':
      case 'TemplatesPanel':
      case 'UserCheckTree':
      case 'StudyRegistration':
      case 'StudyRegistrationForm':
      case 'StudyReviews':
      case 'StudyReviewsForm':
      case 'Workspace':
        return this.hasStudyCallout;
      default:
        return false;
    }
  }

  @AsyncComputed()
  async StudyDetailsButton() {
    return (await this.isAllowed(UserPermission.STUDIES_DASHBOARD_ACCESS)) ? 'Study details' : null;
  }

  @AsyncComputed()
  async StudyReviews() {
    if (!this.studyUuid || !this.workspaceUuid) return false;

    switch (this.$route.name) {
      case 'CalculationsMethod':
      case 'MatrixRelations':
      case 'Presentations':
      case 'PdfOutput':
      case 'Reports':
      case 'TemplateCheckTree':
      case 'TemplatesPanel':
      case 'UserCheckTree':
      case 'StudyRegistration':
      case 'StudyRegistrationForm':
      case 'StudyReviewsForm':
      case 'Workspace':
        return await this.isAllowed(UserPermission.STUDY_REVIEW_ACCESS);
      default:
        return false;
    }
  }

  get OrganizationUsersDashboard() {
    if (!this.userOrganizationUuid) return false;

    switch (this.$route.name) {
      case 'OrganizationUsersDashboard':
        return false;
      default:
        return this.hasOrganizationCallout;
    }
  }

  @AsyncComputed()
  async OrganizationDetailsButton() {
    return (await this.isAllowed(UserPermission.ORGANIZATION_USERS_DASHBOARD_ACCESS)) ? 'Organization details' : null;
  }

  @AsyncComputed()
  async Workspace() {
    if (!this.workspaceUuid) return false;

    switch (this.$route.name) {
      case 'CalculationsMethod':
      case 'MatrixRelations':
      case 'Presentations':
      case 'PdfOutput':
      case 'Reports':
      case 'TemplateCheckTree':
      case 'TemplatesPanel':
      case 'UserCheckTree':
      case 'StudyRegistration':
      case 'StudyRegistrationForm':
      case 'StudyReviews':
      case 'StudyReviewsForm':
        return await this.isAllowed(UserPermission.WORKSPACE_ACCESS);
      default:
        return false;
    }
  }

  async getUserOrganization() {
    try {
      LoaderService.disableHttpLoader();
      this.userOrganization = await api.organization.getOrganization(this.userOrganizationUuid);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  goTo(route: string) {
    switch (route) {
      case 'OrganizationUsersDashboard':
        this.$router.push(`/organization/${this.userOrganizationUuid}`).catch(() => {
          // Dummy error handler for avoiding duplicated navigation logs.
        });
        break;
      case 'StudiesDashboard':
        this.$router.push('/studies').catch(() => {
          // Dummy error handler for avoiding duplicated navigation logs.
        });
        break;
      case 'StudyDetails':
        this.$router.push(`/studies/${this.studyUuid}`).catch(() => {
          // Dummy error handler for avoiding duplicated navigation logs.
        });
        break;
      case 'StudyReviews':
        this.$router.push(`/study-reviews/${this.studyUuid}/${this.workspaceUuid}`).catch(() => {
          // Dummy error handler for avoiding duplicated navigation logs.
        });
        break;
      case 'UserProfileForm':
        this.$router.push('/user-profile').catch(() => {
          // Dummy error handler for avoiding duplicated navigation logs.
        });
        break;
      case 'Workspace':
        this.$router.push(`/workspace/${this.workspaceUuid}`).catch(() => {
          // Dummy error handler for avoiding duplicated navigation logs.
        });
        break;
    }
  }
}
