












































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { PresentationsTabType } from '@/app/shared/types/presentations.types';
import { PresentationsStatsType } from '@/app/shared/types/presentations.types';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import { PresentationsSidebarOption } from '@/app/shared/models/presentations/PresentationsSidebarOption';
import { PresentationsSidebarOptionItemEnum } from '@/app/shared/enums/presentations-sidebar-option-item.enum';
import { PresentationsSidebarOptionGroupEnum } from '@/app/shared/enums/presentations-sidebar-option-group.enum';
import { StatisticsData } from '@/app/shared/store/modules/presentations.store-module';
import { CheckTreeSelection } from '@/app/shared/models/CheckTree';
import { PresentationsSidebarOptionSelection } from '@/app/shared/models/presentations/PresentationsSidebarOption';
import PresentationsBottomActionBar from './../PresentationsBottomActionBar.vue';
import PresentationsStatisticsTable, { PresentationsStatisticsTableType } from './PresentationsStatisticsTable.vue';
import { GRAPHIK_FONT_FAMILY_FOR_HTML_STYLE } from '@/app/shared/store/modules/presentations.constants';
import { LibraryParagraph } from '@/app/shared/models/presentations/LibraryParagraph';
import { CheckTree } from '@/app/shared/models/CheckTree';
import { Study } from '@/app/shared/models/Study';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import store from '@/app/shared/store';

const PresentationsStore = namespace('Presentations');
const LibraryStore = namespace('Library');

@Component({
  components: { PresentationsBottomActionBar, PresentationsStatisticsTable },
})
export default class PresentationsStatistics extends Vue {
  statsTypes: Array<{ title: string; type: PresentationsStatsType; icon: string }> = [];
  sizeOption: 'half' | 'full' = 'full';

  tableTitle: string = null;
  titleInEditMode: boolean = false;

  @PresentationsStore.State
  statisticsSelectionData: StatisticsData;

  @PresentationsStore.State
  sidebarChecktrees: CheckTree[];

  @PresentationsStore.Getter
  sidebarOptions: PresentationsSidebarOption[];

  @LibraryStore.State
  study: Study;

  @PresentationsStore.Getter
  checktreeSelection: CheckTreeSelection;

  @PresentationsStore.Getter
  options: PresentationsSidebarOptionSelection;

  @PresentationsStore.State
  readonly activeStatsType: PresentationsStatsType;

  @PresentationsStore.State
  activeTab: PresentationsTabType;

  @PresentationsStore.State
  typesOfMeans: any;

  // @PresentationsStore.Mutation
  // setActiveStatsType: (type: PresentationsStatsType) => void;

  style: any = {
    titleWrapper: {
      display: 'flex',
      marginBottom: '38px',
    },
    tableTitleNumeration: {
      height: '32px',
      fontSize: '24px',
      lineHeight: '32px',
      display: 'flex',
      alignItems: 'center',
    },
    numeration: {
      marginRight: '0.75rem',
      position: 'relative',
      fontWeight: '500',
    },
    tableTitle: {
      flex: 1,
      position: 'relative',
      textAlign: 'left',
    },
    tableTitleSpan: { fontWeight: '500', color: '#222c37' },
  };

  get tableType(): PresentationsStatisticsTableType {
    const statsColumnGroup = this.sidebarOptions.find(
      (el) => el.type === PresentationsSidebarOptionGroupEnum.STATS_COLUMN,
    );
    const statsRowGroup = this.sidebarOptions.find((el) => el.type === PresentationsSidebarOptionGroupEnum.STATS_ROW);
    if (statsColumnGroup.value && statsRowGroup.value) {
      return 'row_column';
    } else if (statsRowGroup.value) {
      return 'row';
    }
    return 'none';
  }

  get statisticsTableHeader() {
    // const statsColumnGroup = this.sidebarOptions.find(
    //   (el) => el.type === PresentationsSidebarOptionGroupEnum.STATS_COLUMN,
    // );
    const statsRowGroup = this.sidebarOptions.find((el) => el.type === PresentationsSidebarOptionGroupEnum.STATS_ROW);
    if (statsRowGroup.value) {
      return this.activeStatsType === 'count'
        ? 'Number - count of impacts'
        : this.activeStatsType === 'mean'
        ? 'Mean of impacts'
        : 'Variance of impacts';
    } else {
      return null;
    }
  }

  created() {
    this.initializeStatsTypes();
    if (this.statisticsSelectionData) {
      this.tableTitle = this.statisticsSelectionData.title;
    }

    eventBus.$on(EventType.PRESENTATIONS_SIDEBAR_OPTIONS_EVENT, this.onPresentationsSidebarOptionEvent);
  }

  @PresentationsStore.Action
  getStatisticsSelection: (statsType: PresentationsStatsType) => Promise<StatisticsData>;

  @PresentationsStore.Action
  addLibraryParagraphRaw: (value: LibraryParagraph) => Promise<void>;

  onTitleWrapperClick(_event: any) {
    this.titleInEditMode = true;
    const tableTitleWrapper = this.$refs.tableTitle as HTMLElement;
    setTimeout(() => {
      tableTitleWrapper.querySelector('input').focus();
    }, 0);
  }

  @Watch('sidebarChecktrees', { deep: true })
  onSidebarSelectionChecktreesChange() {
    if (this.activeStatsType) {
      // this.updateStatsSelection();
    }
  }

  @Watch('statisticsSelectionData')
  onStatisticsSelectionDataChange() {
    if (this.statisticsSelectionData && this.statisticsSelectionData.title !== this.tableTitle) {
      this.tableTitle = this.statisticsSelectionData.title;
    }
  }

  async updateStatsSelection(statsType: PresentationsStatsType) {
    try {
      await this.getStatisticsSelection(statsType);
      // this.tableTitle = res.title;
      // this.tableData = res.data;
    } catch (error) {
      //
    }
  }

  changeStatsType(type: PresentationsStatsType) {
    // this.activeStatsType = type;
    // this.setActiveStatsType(type);
    this.updateStatsSelection(type);
  }

  onPresentationsSidebarOptionEvent(option: PresentationsSidebarOption) {
    if (
      option.type === PresentationsSidebarOptionGroupEnum.STATS_ROW ||
      option.type === PresentationsSidebarOptionGroupEnum.STATS_COLUMN
    ) {
      if (this.activeStatsType) {
        this.updateStatsSelection(this.activeStatsType);
      }
    } else if (option.type === PresentationsSidebarOptionGroupEnum.STATS_SIZE) {
      if (option.value === PresentationsSidebarOptionItemEnum.STATS_SIZE_FULL) {
        this.sizeOption = 'full';
      } else {
        this.sizeOption = 'half';
      }
    }
  }

  initializeStatsTypes() {
    if (!this.typesOfMeans || !this.typesOfMeans[this.activeTab]) return;
    this.typesOfMeans[this.activeTab].forEach((item: any, index: any) => {
      this.statsTypes.push({ title: item, type: item, icon: item });

      // @todo This should be done through the API, but from now on...
      if (this.statsTypes[index].type == 'count') {
        this.statsTypes[index].title = 'Number - count';
        this.statsTypes[index].icon = 'presentations-stats-count';
      }
      if (this.statsTypes[index].type == 'mean') {
        this.statsTypes[index].title = 'Mean value';
        this.statsTypes[index].icon = 'presentations-stats-mean';
      }
      if (this.statsTypes[index].type == 'variance') {
        this.statsTypes[index].title = 'Variance';
        this.statsTypes[index].icon = 'presentations-stats-variance';
      }
    });
  }

  async addToLibrary() {
    try {
      const html = `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            ${GRAPHIK_FONT_FAMILY_FOR_HTML_STYLE}
            * {
              font-family: 'Graphik', Avenir, Helvetica, Arial, sans-serif;
            }
            .table-column .v-icon svg path {
              fill: #6f8195;
            }
          </style>
        </head>
        <body>
          ${(this.$refs.statsTable as HTMLElement).innerHTML}
        </body>
      </html>
      `;

      const libraryParagraph = new LibraryParagraph(ChapterItemType.LIBRARY_ENVIGO_STATISTICS);
      libraryParagraph.title = this.tableTitle;
      libraryParagraph.html = html;
      libraryParagraph.itemPayload = {
        checktreeSelection: this.checktreeSelection,
        activeTab: this.activeTab,
        statsType: this.activeStatsType,
        options: this.options,
        module: store.getters['ModuleProperties/moduleProperties'],
      };
      libraryParagraph.itemData = this.statisticsSelectionData;

      await this.addLibraryParagraphRaw(libraryParagraph);
    } catch (error) {
      console.error(error);
    }
  }

  clear() {
    // this.activeStatsType = null;
    this.updateStatsSelection(null);
    // this.tableTitle = '';
    // this.tableData = [];
  }

  destroyed() {
    eventBus.$off(EventType.PRESENTATIONS_SIDEBAR_OPTIONS_EVENT, this.onPresentationsSidebarOptionEvent);
  }
}
