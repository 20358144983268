



















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { StudyVersion } from '@/app/shared/models/Study';

@Component
export default class StudyReviewsPageHeader extends Vue {
  @Prop() versions: StudyVersion[];
  @Prop() isExpanded: boolean;

  @Emit('click')
  click(_studyUuid: string, _workspaceUuid: string) {
    //
  }
}
