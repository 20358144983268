


























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import { PresentationsSidebarOptionItemEnum } from '@/app/shared/enums/presentations-sidebar-option-item.enum';
import { PresentationsSidebarOption } from '@/app/shared/models/presentations/PresentationsSidebarOption';
import { PresentationsSidebarOptionGroupEnum } from '@/app/shared/enums/presentations-sidebar-option-group.enum';
import { CheckTreeSelection } from '@/app/shared/models/CheckTree';
import { PresentationsTabType } from '@/app/shared/types/presentations.types';
import { PresentationsTableType } from '@/app/shared/types/presentations.types';
import { PresentationsSidebarOptionSelection } from '@/app/shared/models/presentations/PresentationsSidebarOption';
import PresentationsBottomActionBar from './../PresentationsBottomActionBar.vue';
import { LibraryParagraph } from '@/app/shared/models/presentations/LibraryParagraph';
import CheckTreeItem from '@/app/shared/models/CheckTreeItem';
import { ScreeningTableSelection } from '@/app/shared/store/modules/presentations.store-module';
import { GRAPHIK_FONT_FAMILY_FOR_HTML_STYLE } from '@/app/shared/store/modules/presentations.constants';
import { Study } from '@/app/shared/models/Study';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import store from '@/app/shared/store';

const PresentationsStore = namespace('Presentations');
const LibraryStore = namespace('Library');

@Component({ components: { PresentationsBottomActionBar } })
export default class PresentationsScreeningTable extends Vue {
  tableTitle: string = null;
  titleInEditMode: boolean = false;
  borderColor: string = '#D3DADC';
  cellColor: string = 'white';
  shouldShowComments: boolean = false;
  sizeMode: 'half' | 'full' = 'full';
  shouldEnumerate: boolean = false;

  style = {
    tableTitleNumeration: {
      height: '44px',
      fontSize: '24px',
      lineHeight: '32px',
      display: 'flex',
      alignItems: 'center',
    },
    numeration: {
      marginRight: '0.75rem',
      position: 'relative',
      fontWeight: '500',
    },
    tableTitle: {
      flex: 1,
      position: 'relative',
      textAlign: 'left',
    },
    tableTitleSpan: { fontWeight: '500', color: '#222c37' },
    topBorder: { borderRadius: '2px 2px 0 0', height: '8px', background: '#D3DADC' },
    tableRow: {
      background: 'white',
      textAlign: 'left',
      color: '#222C37',
      borderBottom: '1px solid #D3DADC',
      '--row-font-size': '14px',
      '--row-line-height': '18px',
    },
    rowTitle: {
      margin: '0 16px',
      fontSize: 'var(--row-font-size)',
      lineHeight: 'var(--row-line-height)',
      letterSpacing: '0.25px',
      fontWeight: '500',
    },
    rowComment: {
      paddingTop: '8px',
      margin: '0 2rem',
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: '0.25px',
    },
  };

  @PresentationsStore.State
  screeningTableSelection: ScreeningTableSelection;

  @LibraryStore.State
  study: Study;

  @PresentationsStore.State
  activeTab: PresentationsTabType;

  @PresentationsStore.State
  activeTableType: PresentationsTableType;

  @PresentationsStore.State
  readonly activeTableTitle: string;

  @PresentationsStore.Getter
  checktreeSelection: CheckTreeSelection;

  @PresentationsStore.Getter
  options: PresentationsSidebarOptionSelection;

  @Watch('activeTableTitle')
  onActiveTableTitleChange(activeTableTitle: string) {
    this.tableTitle = activeTableTitle;
  }

  get selectedChecktreeItems() {
    const items: CheckTreeItem[] = [];
    const addItem = (items: CheckTreeItem[], node: CheckTreeItem) => {
      items.push(node);
      if (node.children) {
        for (const childItem of node.children) {
          addItem(items, childItem);
        }
      }
    };
    if (this.screeningTableSelection) {
      for (const rootItem of this.screeningTableSelection.checktree.children) {
        addItem(items, rootItem);
      }
    }
    return items;
  }

  created() {
    eventBus.$on(EventType.PRESENTATIONS_SIDEBAR_OPTIONS_EVENT, this.onPresentationsSidebarOptionEvent);
    if (this.screeningTableSelection) {
      this.tableTitle = this.screeningTableSelection.title;
    }
  }

  @PresentationsStore.Action
  addLibraryParagraphRaw: (value: LibraryParagraph) => Promise<void>;

  @PresentationsStore.Action
  clearTableSelection: () => Promise<any>;

  onTitleWrapperClick(_event: any) {
    this.titleInEditMode = true;
    const tableTitleWrapper = this.$refs.tableTitle as HTMLElement;
    setTimeout(() => {
      tableTitleWrapper.querySelector('input').focus();
    }, 0);
  }

  onPresentationsSidebarOptionEvent(option: PresentationsSidebarOption) {
    if (option.type === PresentationsSidebarOptionGroupEnum.TABLE_LEVEL_OF_DETAIL_SIMPLE) {
      if (option.value === PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_ONLY_LIST) {
        this.shouldShowComments = false;
      } else if (option.value === PresentationsSidebarOptionItemEnum.TABLE_LEVEL_OF_DETAIL_WITH_COMMENTS) {
        this.shouldShowComments = true;
      }
    } else if (option.type === PresentationsSidebarOptionGroupEnum.TABLE_SIZE) {
      if (option.value === PresentationsSidebarOptionItemEnum.TABLE_SIZE_HALF) {
        this.sizeMode = 'half';
      } else if (option.value == PresentationsSidebarOptionItemEnum.TABLE_SIZE_FULL) {
        this.sizeMode = 'full';
      }
    } else if (option.type === PresentationsSidebarOptionGroupEnum.TABLE_ENUMERATE) {
      this.shouldEnumerate = option.value === PresentationsSidebarOptionItemEnum.TABLE_ENUMERATE_WITH_NUMBERS;
    }
  }

  async addToLibrary() {
    try {
      const html = `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            ${GRAPHIK_FONT_FAMILY_FOR_HTML_STYLE}
            * {
              font-family: 'Graphik', Avenir, Helvetica, Arial, sans-serif;
            }
          </style>
        </head>
        <body>
          ${(this.$refs.screeningTable as HTMLElement).innerHTML}
        </body>
      </html>
      `;

      const libraryParagraph = new LibraryParagraph(ChapterItemType.LIBRARY_ENVIGO_TABLE);
      libraryParagraph.title = this.tableTitle;
      libraryParagraph.html = html;
      libraryParagraph.itemPayload = {
        checktreeSelection: this.checktreeSelection,
        activeTab: this.activeTab,
        tableType: this.activeTableType,
        options: this.options,
        module: store.getters['ModuleProperties/moduleProperties'],
      };
      libraryParagraph.itemData = this.screeningTableSelection;
      await this.addLibraryParagraphRaw(libraryParagraph);
    } catch (error) {
      console.error(error);
    }
  }

  clear() {
    this.clearTableSelection();
  }

  destroyed() {
    eventBus.$off(EventType.PRESENTATIONS_SIDEBAR_OPTIONS_EVENT, this.onPresentationsSidebarOptionEvent);
  }
}
