
















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import InlineTable from '@/app/shared/components/table/InlineTable.vue';
import ListTable from '@/app/shared/components/table/ListTable.vue';
import PresentationTable from '@/app/shared/components/table/PresentationTable.vue';
import ResizableTable from '@/app/shared/components/table/ResizableTable.vue';
import TextTable from '@/app/shared/components/table/TextTable.vue';
import { Table } from '@/app/shared/models/Table';

@Component({
  components: {
    InlineTable,
    ListTable,
    PresentationTable,
    ResizableTable,
    TextTable,
  },
})
export default class DashboardPageHeader extends Vue {
  @Prop() title: string;
  @Prop() backgroundType: string;
  @Prop() leftTable: Table;
  @Prop() rightTable: Table;
  isExpanded: boolean = false;

  @Emit('expand')
  expand(_isExpanded: boolean) {
    //
  }

  @Emit('action-click')
  onActionClick(_type: string) {
    //
  }

  get backgroundImageSet() {
    try {
      /* eslint-disable @typescript-eslint/no-var-requires */
      const image1x = require(`@/assets/img/backgrounds/background-${this.backgroundType}.jpg`);
      const image2x = require(`@/assets/img/backgrounds/background-${this.backgroundType}@2x.jpg`);
      /* eslint-enable @typescript-eslint/no-var-requires */
      return `-webkit-image-set(url(${image1x}) 1x, url(${image2x}) 2x)`;
    } catch (error) {
      console.error('Missing background image!', error);
      return 'none';
    }
  }

  get tableType() {
    return {
      inline: 'InlineTable',
      list: 'ListTable',
      presentation: 'PresentationTable',
      text: 'TextTable',
      resizable: 'ResizableTable',
    };
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
    this.expand(this.isExpanded);
  }
}
