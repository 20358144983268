import httpClient from '@/app/shared/api/axios.config';
import { VersioningResponse, StudyVersioningResponse, VersioningItemForUpdate } from '@/app/shared/models/Versioning';
import { Study } from '@/app/shared/models/Study';

const ENDPOINT = '/versioning';

export default {
  async getVersioningItems(uuid: string) {
    const data: VersioningResponse = await httpClient.get(`${ENDPOINT}/${uuid}`);
    return data;
  },

  async getStudyVersions(uuid: string) {
    const data: StudyVersioningResponse = await httpClient.get(`${ENDPOINT}/${uuid}/versions`);
    return data;
  },

  async setStudyVersion(uuid: string): Promise<Study> {
    return await httpClient.post(`${ENDPOINT}/${uuid}`);
  },

  async restoreStudyVersion(uuid: string): Promise<Study> {
    return await httpClient.post(`${ENDPOINT}/${uuid}/restore`);
  },

  async updateStudyVersionName(uuid: string, payload: VersioningItemForUpdate): Promise<Study> {
    return await httpClient.post(`${ENDPOINT}/${uuid}/update`, { name: payload.name });
  },

  async updateStudyVersionComment(uuid: string, payload: VersioningItemForUpdate): Promise<Study> {
    return await httpClient.post(`${ENDPOINT}/${uuid}/update`, { comment: payload.comment });
  },

  async updateStudyVersionIsImportant(uuid: string, payload: VersioningItemForUpdate): Promise<Study> {
    return await httpClient.post(`${ENDPOINT}/${uuid}/update`, { is_important: payload.is_important });
  },

  async updateStudyVersionIsAuthority(uuid: string, payload: VersioningItemForUpdate): Promise<Study> {
    return await httpClient.post(`${ENDPOINT}/${uuid}/update`, { is_authority: payload.is_authority });
  },

  async updateStudyVersionIsPublic(uuid: string, payload: VersioningItemForUpdate): Promise<Study> {
    return await httpClient.post(`${ENDPOINT}/${uuid}/update`, { is_public: payload.is_public });
  },
};
