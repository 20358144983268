












































import { Component, Prop, Watch } from 'vue-property-decorator';
import NavigationPanelButton from '@/app/shared/components/NavigationPanelButton.vue';
import ExpandableSidebarRoute from '@/app/shared/components/mixins/ExpandableSidebarRoute.vue';
import api from '@/app/shared/api';
import { ExpandableSidebarOptions } from '@/app/shared/models/ExpandableSidebar';
import { TemplateGroup, TemplateStage, TemplateType } from '@/app/shared/models/Template';

@Component({
  components: {
    NavigationPanelButton,
  },
})
export default class TemplatesPanel extends ExpandableSidebarRoute {
  @Prop() moduleUuid: string;
  @Prop() workspaceItemUuid: string;
  @Prop() studyUuid: string;
  @Prop() stage: TemplateStage;
  tab: number = 0;
  templateGroups: TemplateGroup[] = [];
  expandableSidebarOptions: ExpandableSidebarOptions = {};

  @Watch('stage')
  onStageChange(stage: string) {
    this.setExpandableSidebarOptions(stage);
  }

  async created() {
    this.setExpandableSidebarOptions(this.stage);

    try {
      this.templateGroups = await api.templates.getTemplateGroups(
        this.workspaceItemUuid,
        this.moduleUuid,
        this.studyUuid,
        this.stage,
      );
      this.setActiveTabOrRedirect();
    } catch (error) {
      console.warn(error);
    }
  }

  async onTemplateClick(templateUuid: string, templateType: TemplateType) {
    try {
      const res = await api.templates.setTemplate(
        this.workspaceItemUuid,
        this.studyUuid,
        this.stage,
        templateUuid,
        templateType,
      );

      this.$router.push(`/checktree/${res.checktree}/${res.ws_item}/${res.stage}/${res.template}`);
    } catch (error) {
      console.warn(error);
    }
  }

  setExpandableSidebarOptions(stage: string) {
    let headingText;

    switch (stage) {
      case 'screening':
        headingText = 'Screening Templates';
        break;
      case 'scoping':
        headingText = 'Scoping Templates';
        break;
      case 'assessing':
        headingText = 'Assessing Templates';
        break;
    }

    this.expandableSidebarOptions = {
      headingText,
    };

    this.setOptions(this.expandableSidebarOptions);
  }

  async setActiveTabOrRedirect() {
    const activeTab = this.templateGroups.findIndex((templateGroup) => templateGroup.active);

    // In case no active tabs are found, redirect the user to target study workspace.
    if (activeTab === -1) {
      try {
        const study = await api.study.getStudy(this.studyUuid);
        if (study.workspace) this.$router.replace(`/workspace/${study.workspace.uuid}`);
      } catch (error) {
        console.warn(error);
      }

      return;
    }

    // Otherwise, set active tab implicitly to the first active template group.
    this.tab = activeTab;
  }
}
