


























































































































































































import { Component, Mixins, Watch, Vue } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import FormFieldSelect from '@/app/shared/components/form/field/FormFieldSelect.vue';
import FormFieldText from '@/app/shared/components/form/field/FormFieldText.vue';
import FormFieldFocus from '@/app/shared/components/form/field/mixins/FormFieldFocus.vue';
import { ValidationRule } from '@/app/shared/models/form/FormSchema';
import { Location } from '@/app/shared/models/form/field/FormFieldLocation';
import { FormFieldValue, FormFieldValueModel } from '@/app/shared/models/form/field';
import DialogService from '@/app/shared/utils/dialog.service';

@Component({
  components: {
    FormFieldSelect,
    FormFieldText,
  },
})
export default class FormFieldLocation extends Mixins(Vue, FormFieldFocus, BaseControlSkeleton) {
  value: FormFieldValue<Location>;
  deleted: string[] = [];
  locations: Location[] = [new Location()];
  control: {
    validations: ValidationRule[];
  };
  updateValue: (value: FormFieldValue<Location>) => void;

  optionsEndpointPayload = `{
  "model": "Facility",
  "context": {},
  "formContext": [
    {
      "key": "study_id",
      "value": "id"
    }
  ],
  "key": "id",
  "value": "name"
}`;

  @Watch('value', { deep: true })
  onValueChanged(value: FormFieldValue<Location>) {
    this.$set(this, 'locations', value.data);
  }

  @Watch('locations', { deep: true })
  onLocationsChange(value: Location[]) {
    this.updateValue(new FormFieldValue(value, FormFieldValueModel.LOCATION, this.deleted));
  }

  get isRequiredObjectName() {
    if (this.control.validations)
      return (
        this.control.validations.filter(
          (validation) =>
            validation.ruleType === 'customClosure' && validation.additionalValue === 'requiredObjectName',
        ).length > 0
      );

    return false;
  }

  created() {
    if (this.value) this.$set(this, 'locations', this.value.data);
  }

  addGroup(index: number) {
    this.locations.splice(index + 1, 0, new Location());
  }

  removeGroup(index: number) {
    const removeClosure = () => {
      const uuid = this.locations[index].uuid;
      if (uuid) this.deleted.push(uuid);
      this.locations.splice(index, 1);
    };

    // Skip showing confirmation dialog in test environment.
    if (process.env.NODE_ENV === 'test') {
      removeClosure();
      return;
    }

    DialogService.presentDialog(
      'Delete this item?',
      'You will not be able to recover it.',
      'Yes',
      'Cancel',
      removeClosure,
    );
  }

  focus() {
    const formField = this.$refs.formField as (Vue & { focus: () => void })[];
    if (formField[0]) formField[0].focus();
  }

  toggleDetails(location: Location, event: Event) {
    location.isDetailsHidden = !location.isDetailsHidden;
    if (event.target instanceof HTMLButtonElement) event.target.focus();
  }
}
