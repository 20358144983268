















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import PageHeader from '@/app/shared/components/PageHeader.vue';

@Component({
  components: {
    PageHeader,
  },
})
export default class PdfOutputPageHeader extends Vue {
  @Prop() title: string;

  @Emit('exclude-all')
  excludeAll(_value: boolean) {
    //
  }

  @Emit('reorder-items')
  reorderItems() {
    //
  }
}
