
import { Component, Prop, Watch } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import { namespace } from 'vuex-class';
import ExpandableSidebarForm from '@/app/shared/components/mixins/ExpandableSidebarForm.vue';
import userProfileFormList from '@/app/shared/components/form/list/user-profile';
import api from '@/app/shared/api';
import { FormList } from '@/app/shared/models/form/FormSchema';
import { User } from '@/app/shared/models/User';
import { UserPermission } from '../shared/enums/user-permission.enum';

const UserStore = namespace('User');

@Component
export default class UserProfileForm extends ExpandableSidebarForm {
  @Prop() declare formId: string;
  @Prop() userUuid: string;
  @Prop() organizationUuid: string;
  formData: any = null;
  formList: FormList[] = [];
  serverError: AxiosError = null;

  @UserStore.Getter
  userModel: User;

  @UserStore.Action
  public getUserData: () => Promise<void>;

  @Watch('formId')
  async onFormIdChange(formId: string) {
    switch (formId) {
      case 'user-profile':
        this.formData = await api.user.loadUserProfileForm();
        break;
      case 'edit-user-profile':
      case 'change-main-organization':
        this.formData = await api.user.loadEditUserProfileForm(this.userUuid, this.organizationUuid);
        break;
    }
  }

  @AsyncComputed()
  async isChangeMainOrganizationAllowed() {
    return await this.isAllowed(UserPermission.CHANGE_MAIN_ORGANIZATION_ACCESS);
  }

  @AsyncComputed()
  async hasAvatarInitials() {
    return await this.isAllowed(UserPermission.TOP_BAR_SHOW_AVATAR_INITIALS);
  }

  get formDefaultValues() {
    return {
      ...cloneDeep(this.formData),
      user_uuid: this.userUuid,
      current_organization_uuid: this.organizationUuid,
      has_avatar_initials: this.userUuid === this.userModel.uuid && this.hasAvatarInitials,
      is_change_main_organization_disabled: !this.isChangeMainOrganizationAllowed,
    };
  }

  get isFormShown() {
    return Boolean(this.formData);
  }

  get hasFormSubmitButton() {
    return true;
  }

  get backRoute() {
    switch (this.formId) {
      default:
      case 'user-profile':
        return this.userHome as never;
      case 'edit-user-profile':
      case 'change-main-organization':
        return `/organization/${this.organizationUuid}`;
    }
  }

  async created() {
    this.formList = userProfileFormList;

    if (!(await this.checkFormPermission())) return;

    try {
      switch (this.formId) {
        case 'user-profile':
          this.formData = await api.user.loadUserProfileForm();
          break;
        case 'edit-user-profile':
        case 'change-main-organization':
          this.formData = await api.user.loadEditUserProfileForm(this.userUuid, this.organizationUuid);
          break;
      }
    } catch (error) {
      console.warn(error);
    }
  }

  onFormFieldButton(event: { payload: { name: string; payload: any }; values: any }) {
    if (
      event.payload.name === 'form-field-button' &&
      event.payload.payload === 'change-main-organization' &&
      this.isChangeMainOrganizationAllowed
    ) {
      this.$router.push(`/change-main-organization/${this.userUuid}/${this.organizationUuid}`);
    }
  }

  async onSubmit(payload: any) {
    this.serverError = null;

    try {
      switch (this.formId) {
        case 'user-profile':
          await api.user.submitUserProfileForm(payload);
          await this.getUserData();
          break;
        case 'edit-user-profile':
          await api.user.submitEditUserProfileForm(this.userUuid, this.organizationUuid, payload);
          if (this.userUuid === this.userModel.uuid) await this.getUserData();
          break;
        case 'change-main-organization':
          await api.user.submitChangeMainOrganizationForm(this.userUuid, this.organizationUuid, payload);
          if (this.userUuid === this.userModel.uuid) await this.getUserData();
          break;
      }

      await this.redirectOnSuccess();
    } catch (error) {
      this.serverError = error as AxiosError;
    }
  }
}
