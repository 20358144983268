var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workflow-action-buttons"},[[_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'Admin Checktree',
          type: 'operation',
          approvers: [],
          module: {
            type: 'AdminChecktreeModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" Admin Checktree ")]),_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'Admin and User Checktree',
          type: 'operation',
          approvers: [],
          module: {
            type: 'AdminUserCheckTreeModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" Admin and User Checktree ")]),_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'Filter for User Checktree',
          type: 'operation',
          approvers: [],
          module: {
            type: 'FilterForUserChecktreeModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" Filter for User Checktree ")]),_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'UnitList',
          type: 'operation',
          approvers: [],
          module: {
            type: 'UnitListModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" UnitList ")]),_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'Cross-connect Matrix',
          type: 'operation',
          approvers: [],
          module: {
            type: 'CrossconnectMatrixModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" Cross-connect Matrix ")]),_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'Tagging Admin Matrix',
          type: 'operation',
          approvers: [],
          module: {
            type: 'TaggingAdminMatrixModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" Tagging Admin Matrix ")]),_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'Tagging User Matrix',
          type: 'operation',
          approvers: [],
          module: {
            type: 'TaggingUserMatrixModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" Tagging User Matrix ")]),_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'Formulate (Admin) Processor',
          type: 'operation',
          approvers: [],
          module: {
            type: 'ProcessorFormulateModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" Formulate (Admin) Processor ")]),_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'Define (Admin) Processor',
          type: 'operation',
          approvers: [],
          module: {
            type: 'ProcessorAdminDefineModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" Define (Admin) Processor ")]),_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'Define (User) Processor',
          type: 'operation',
          approvers: [],
          module: {
            type: 'ProcessorUserDefineModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" Define (User) Processor ")]),_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'Assess (User) Processor',
          type: 'operation',
          approvers: [],
          module: {
            type: 'ProcessorAssessModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" Assess (User) Processor ")]),_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'Presentation',
          type: 'operation',
          approvers: [],
          module: {
            type: 'PresentationModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" Presentation ")]),_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'Reporting Panel',
          type: 'operation',
          approvers: [],
          module: {
            type: 'ReportingPanelModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" Reporting Panel ")]),_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'Report',
          type: 'operation',
          approvers: [],
          module: {
            type: 'ReportModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" Report ")]),_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'Report (direct)',
          type: 'operation',
          approvers: [],
          module: {
            type: 'ReportDirectModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" Report (Direct) ")]),_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'PDF Output',
          type: 'operation',
          approvers: [],
          module: {
            type: 'PDFOutputModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" PDF Output ")]),_c('BaseButton',{attrs:{"icon":"add","type":"primary"},on:{"click":function($event){_vm.$parent.$refs.chart.add({
          id: +new Date(),
          x: 10,
          y: 10,
          name: 'How To Use',
          type: 'operation',
          approvers: [],
          module: {
            type: 'HowToUseModule',
            slug: 'slug-string',
          },
        })}}},[_vm._v(" How To Use ")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }