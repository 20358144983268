import httpClient from '@/app/shared/api/axios.config';
import { FileResponse } from '@/app/shared/models/File';

const FILE_END_POINT = '/file';

export default {
  async uploadFile(
    type: string,
    contents: string,
    contentType: string = '',
    name: string = '',
    description: string = '',
    fileName: string = '',
    fileSize: number = null,
  ): Promise<FileResponse> {
    return await httpClient.put(`${FILE_END_POINT}/${type}`, {
      contents,
      content_type: contentType,
      name,
      description,
      file_name: fileName,
      file_size: fileSize,
    });
  },
};
