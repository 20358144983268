import FormConfigDivider from '@/app/shared/components/form/config/FormConfigDivider.vue';
import FormFieldDivider from '@/app/shared/components/form/field/FormFieldDivider.vue';

export default {
  name: 'Divider Control',
  description: 'Simple divider with optional label',
  disableValidation: true,
  disableValue: true,

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldDivider,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigDivider,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    // Override common default value.
    isShowLabel: false,
  },
};
