






































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import WorkspacePageHeader from './components/WorkspacePageHeader.vue';
import PageFooter from '@/app/shared/components/PageFooter.vue';
import Versioning from '@/app/shared/components/Versioning.vue';
import WorkspaceItem from './components/WorkspaceItem.vue';
import WorkspaceLines from './components/WorkspaceLines.vue';
import LoaderService from '@/app/shared/utils/loader.service';
import UtilService from '@/app/shared/utils/util.service';
import api from '@/app/shared/api';
import { WorkspaceResponse } from '@/app/shared/models/workspace/WorkspaceResponse';
import { WorkspaceResponseItem } from '@/app/shared/models/workspace/WorkspaceResponseItem';
import { WorkspaceActiveButton } from '@/app/shared/types/workspace-active-button.type';
import { ModuleProperties } from '@/app/shared/models/Module';

const UiStore = namespace('UI');
const ModulePropertiesStore = namespace('ModuleProperties');
const VersioningStore = namespace('Versioning');

@Component({
  components: {
    PageFooter,
    WorkspaceItem,
    WorkspaceLines,
    WorkspacePageHeader,
    Versioning,
  },
})
export default class Workspace extends Vue {
  @Prop() uuid: string;
  items: WorkspaceResponseItem[] = [];
  loading = true;
  activeButton: WorkspaceActiveButton = 'routine';
  workspaceName = 'Workspace';
  defaultVersioningWidth = 0;

  @ModulePropertiesStore.Getter
  moduleProperties: ModuleProperties;

  @ModulePropertiesStore.Action
  getModuleProperties: () => Promise<void>;

  @UiStore.Getter
  isFooterVersioningActive: boolean;

  @UiStore.Mutation
  setFooterVersioningActive: (value: boolean) => void;

  @VersioningStore.Mutation
  setVersioningObject: (payload: { objectModel: string; objectUuid: string }) => void;

  @VersioningStore.Action
  saveVersion: (uuid: string) => Promise<void>;

  @Watch('uuid')
  onUuidChange(uuid: string) {
    this.getItems(uuid);
  }

  get workspaceItems() {
    return this.items.reduce((workspaceItems, item) => ({ ...workspaceItems, [item.slug]: item }), {});
  }

  get activeItems() {
    return this.items.reduce(
      (activeItems, item) => ({ ...activeItems, [item.slug]: item.state && item.state.some((state) => state.active) }),
      {},
    ) as { [key: string]: boolean };
  }

  created() {
    // Calculate default sidebar width.
    this.defaultVersioningWidth = UtilService.calculateSidebarWidth();

    if (this.uuid) this.getItems(this.uuid);
  }

  async getItems(uuid: string) {
    try {
      this.loading = true;
      LoaderService.disableHttpLoader();
      LoaderService.showLoader();

      await this.getModuleProperties();
      this.setVersioningObject({ objectModel: 'Study', objectUuid: this.moduleProperties.study_uuid });

      const data: WorkspaceResponse = await api.workspace.getByUuid(uuid);

      this.workspaceName = data.name;
      this.items = data.items;

      this.setActiveButtonFromData();
    } catch (error) {
      console.warn(error);
    } finally {
      this.loading = false;
      LoaderService.hideLoader();
      LoaderService.enableHttpLoader();
    }
  }

  setActiveButtonFromData() {
    const slugs: WorkspaceActiveButton[] = ['routine', 'accident', 'cumulative', 'ecosystem-services'];
    for (const slug of slugs) {
      const workspaceItem = this.items.find((item) => item.slug === slug);
      if (workspaceItem && workspaceItem.state && workspaceItem.state.find((s) => s.active == true)) {
        this.activeButton = slug;
      }
    }
  }

  setActiveButton(button: WorkspaceActiveButton) {
    if (!this.activeItems['39-TUM-impact characterization']) return;
    this.activeButton = button;
  }

  async saveStudyVersion() {
    if (!this.isFooterVersioningActive) this.setFooterVersioningActive(true);
    await this.saveVersion(this.uuid);
    this.$router.push({ name: 'StudiesDashboard' });
  }
}
