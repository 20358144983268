
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ErrorPage extends Vue {
  @Prop() type?: '403' | '404';
  @Prop() message?: string;

  get errorMessage() {
    switch (this.type) {
      case '403':
        return 'The access to the page you requested is forbidden.';
      case '404':
        return 'The page you requested was not found.';
      default:
        return this.message ?? 'An unknown error occurred.';
    }
  }
}
