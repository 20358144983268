


























































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import PageHeader from '@/app/shared/components/PageHeader.vue';
import { namespace } from 'vuex-class';
import { CalculationsDataTableColumn } from './table/DataTable.vue';
import UtilService from '@/app/shared/utils/util.service';

const UiStore = namespace('UI');

@Component({
  components: { PageHeader },
})
export default class CalculationsPageHeader extends Vue {
  @Prop() title: string;
  @Prop() icon: string;
  @Prop() tagsColumns: Array<CalculationsDataTableColumn & { checked: boolean }>;
  @Prop() filterByClass: string;

  columnsToInclude: Array<CalculationsDataTableColumn & { checked: boolean }> = [];
  isMenuVisible: boolean = false;

  @UiStore.Getter
  isLeftSidebarExpanded!: boolean;

  get xOffset() {
    return this.isLeftSidebarExpanded ? UtilService.calculateSidebarWidth() : 56;
  }

  @Emit('columns-selected')
  columnsSelected(_event: Array<CalculationsDataTableColumn & { checked: boolean }>) {
    // emitting event
  }

  @Emit('resume')
  resume() {
    // emitting event
  }

  @Watch('tagsColumns')
  onTagsColumnsUpdate() {
    if (this.tagsColumns.length > 0) {
      this.initializeColumnsToInclude();
    }
  }

  @Watch('stepTitle')
  onStepTitleChange(stepTitle: string) {
    // Set the meta title directly, since we are fetching this value asynchronously.
    if (stepTitle) document.title = `${stepTitle} - Envigo`;
  }

  initializeColumnsToInclude() {
    this.columnsToInclude = this.tagsColumns.slice().map((el) => {
      return { ...el };
    });
  }

  onMenuConfirm() {
    this.columnsSelected(this.columnsToInclude);
    this.isMenuVisible = false;
  }

  onMenuCancel() {
    this.isMenuVisible = false;
    this.initializeColumnsToInclude();
  }
}
