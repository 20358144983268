




























import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
import ProgressStatus from './ProgressStatus.vue';
import PageHeader from '@/app/shared/components/PageHeader.vue';
import { Study } from '@/app/shared/models/Study';

@Component({
  components: {
    PageHeader,
    ProgressStatus,
  },
})
export default class StudyReviewsPageHeader extends Vue {
  @Prop() study: Study;
  @Prop() isExpanded: boolean;
  @Model() isShown: boolean;

  @Emit('toggle-show')
  toggleShow(_isShown: boolean) {
    //
  }

  @Emit('toggle-expand')
  toggleExpand() {
    //
  }

  get studyTitle() {
    return this.study?.name;
  }

  get studyStatus() {
    return this.study?.status;
  }

  get hasEvents() {
    return this.study?.study_reviews?.some(
      (studyReview) => studyReview.events && studyReview.events.data && studyReview.events.data.length,
    );
  }
}
