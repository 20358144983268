import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { walkTreeData } from 'he-tree-vue';
import api from '@/app/shared/api';
import { ws } from '@/app/shared/ws/index';
import store from '@/app/shared/store';
import LoaderService from '@/app/shared/utils/loader.service';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import { SingleImpact } from '@/app/shared/models/reports/SingleImpact';
import { TEMPORARY_UUID_PREFIX } from '@/app/shared/models/reports/ChapterItem';
import { WSMessageCommand } from '@/app/shared/enums/ws-message-command.enum';
import { WSMessageResponse } from '@/app/shared/models/WSMessageResponse';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import { ChapterItem } from '@/app/shared/models/reports/ChapterItem';
import { Chapter } from '@/app/shared/models/reports/Chapter';
import { PdfHistoryParent } from '@/app/shared/models/pdf-history/PdfHistoryParent';
import { FileResource } from '@/app/shared/models/File';

export interface NewChapterItemPayload {
  type: ChapterItemType;
  uuid: string; // chapter item uuid from which add button is clicked
  position: 'before' | 'after';
}

export interface UpdateChapterItemPayload {
  type: ChapterItemType;
  uuid: string;
  propertyName: string;
  propertyValue: any;
  additionalProperties?: {
    name: string;
    value: any;
  }[];
}

export interface OnChapterItemsReorderPayload {
  dragNode: ChapterItem;
  afterNode: ChapterItem;
  isFirstChild: boolean;
  updatedTreeData: ChapterItem[];
}

export const WS_DEBOUNCE_VALUE = 1000;

const util = {
  findChapterItemByUuid(paragraphs: ChapterItem[], uuid: string, node: ChapterItem): ChapterItem {
    if (node) {
      if (node.uuid === uuid) {
        return node;
      } else if (node.children && node.children.length > 0) {
        let result = null;
        for (const childNode of node.children) {
          result = this.findChapterItemByUuid(paragraphs, uuid, childNode);
          if (result) return result;
        }
        return result;
      }
      return null;
    }

    // root level
    let result = null;
    for (const firstLevelNode of paragraphs) {
      result = this.findChapterItemByUuid(paragraphs, uuid, firstLevelNode);
      if (result) return result;
    }
    return result;
  },
  findChapterItemParentNode(paragraphs: ChapterItem[], uuid: string, node: ChapterItem): ChapterItem {
    if (node) {
      if (node.children && node.children.findIndex((el) => el.uuid === uuid) > -1) {
        return node;
      } else if (node.children && node.children.length > 0) {
        let result = null;
        for (const childNode of node.children) {
          result = this.findChapterItemParentNode(paragraphs, uuid, childNode);
          if (result) return result;
        }
        return result;
      }
      return null;
    }

    // root level
    let result: ChapterItem = null;
    for (const firstLevelNode of paragraphs) {
      result = this.findChapterItemParentNode(paragraphs, uuid, firstLevelNode);
      if (result) return result;
    }
    return null;
  },
  walkChapterTree(paragraphs: ChapterItem[], node: ChapterItem, callback: (el: ChapterItem) => void): void {
    if (node) {
      callback(node);
      if (node.children && node.children.length > 0) {
        for (const childNode of node.children) {
          this.walkChapterTree(paragraphs, childNode, callback);
        }
      }
    } else {
      // root level
      for (const firstLevelNode of paragraphs) {
        this.walkChapterTree(paragraphs, firstLevelNode, callback);
      }
    }
  },
  getItemOrderForChapter(currentChapter: Chapter, path: any): string {
    let value: string = `${currentChapter.order}.${(path[0] + 1).toString()}`;
    for (let index = 1; index < path.length; index++) {
      const el = path[index];
      value = value + '.' + (el + 1);
    }
    return value;
  },
};

@Module({ namespaced: true })
export class ReportsModule extends VuexModule {
  private isNavigatorCollapsed = false;
  private chaptersUuid: string = null;
  private chapters: Chapter[] = [];
  private currentChapter: Chapter = null;
  private currentChapterNumber: number = null;
  private pdfHistory: Array<PdfHistoryParent> = [];

  private singleImpact: SingleImpact = null;

  private paragraphs: ChapterItem[] = null;

  get navigatorCollapsed(): boolean {
    return this.isNavigatorCollapsed;
  }

  get getChapters(): Chapter[] {
    return this.chapters;
  }

  get getCurrentChapter(): Chapter {
    return this.currentChapter;
  }

  get getCurrentChapterNumber() {
    return this.currentChapterNumber;
  }

  get getPdfHistory(): Array<PdfHistoryParent> {
    return this.pdfHistory;
  }

  get getSingleImpact(): SingleImpact {
    return this.singleImpact;
  }

  get getParagraphs(): ChapterItem[] {
    return this.paragraphs;
  }

  get areAllParagraphsExpandedInContent(): boolean {
    let result = true;
    util.walkChapterTree(this.paragraphs, null, (el) => {
      if (!el.isExpandedInContent) {
        result = false;
      }
    });
    return result;
  }

  @Mutation
  public setNavigatorCollapsed(isNavigatorCollapsed: boolean) {
    this.isNavigatorCollapsed = isNavigatorCollapsed;
  }

  @Mutation
  public setChapters(payload: { chapters: Chapter[]; uuid: string }) {
    if (payload) {
      this.chapters = payload.chapters;
      this.chaptersUuid = payload.uuid;
    } else {
      this.chapters = null;
      this.chaptersUuid = null;
    }
  }

  @Mutation
  public setCurrentChapter(chapter: Chapter) {
    if (this.currentChapter && chapter && chapter.children) {
      util.walkChapterTree(chapter.children, null, (el) => {
        const currentElValue = util.findChapterItemByUuid(this.paragraphs, el.uuid, null);
        el.isExpandedInContent = currentElValue ? currentElValue.isExpandedInContent : false;
      });
    }
    this.currentChapter = chapter;
    this.paragraphs = this.currentChapter ? this.currentChapter.children : null;
    if (this.paragraphs && this.paragraphs.length > 0) {
      this.paragraphs[0].isCurrent = true;
    }
  }

  @Mutation
  public setCurrentChapterNumber(chapterNumber: number = null) {
    this.currentChapterNumber = chapterNumber;
  }

  @Mutation
  public setPdfHistory(history: Array<PdfHistoryParent>) {
    this.pdfHistory = history;
  }

  @Mutation
  public setSingleImpact(value: SingleImpact) {
    if (this.singleImpact && value) {
      util.walkChapterTree(value.paragraphs[0].children, null, (el) => {
        const currentElValue = util.findChapterItemByUuid(this.paragraphs, el.uuid, null);
        el.isExpandedInContent = currentElValue ? currentElValue.isExpandedInContent : false;
      });
    }
    this.singleImpact = value;
    this.paragraphs = this.singleImpact ? this.singleImpact.paragraphs[0].children : null;
    if (this.paragraphs && this.paragraphs.length > 0) {
      this.paragraphs[0].isCurrent = true;
    }
  }

  @Mutation
  public updateParagraphsAfterReorder(items: ChapterItem[]) {
    if (this.singleImpact) {
      this.paragraphs[1].children = items;
    } else {
      this.paragraphs = items;
      // if (this.paragraphs && this.paragraphs.length > 0) {
      //   this.paragraphs[0].isCurrent = true;
      // }
    }
  }

  @Mutation
  public enumerate() {
    //TODO: impact order
    const impactOrder = 1;

    let figureCount = 0;
    let tableCount = 0;
    const enumerateChildren = (node: { children: any }, parentNumeration: string = null) => {
      let headingCount = 0;
      for (const child of node.children) {
        if (child.type === ChapterItemType.HEADING) {
          headingCount++;
          child.numeration = parentNumeration
            ? `${parentNumeration}.${headingCount}`
            : this.currentChapter
            ? `${this.currentChapterNumber}.${headingCount}`
            : `${impactOrder}.${headingCount}`;
        } else if (child.type === ChapterItemType.IMAGE || child.type === ChapterItemType.CHART) {
          figureCount++;
          child.numeration = this.currentChapter
            ? `${this.currentChapterNumber}.${figureCount}`
            : `${impactOrder}.${figureCount}`;
        } else if (
          child.type === ChapterItemType.TABLE ||
          child.type === ChapterItemType.SPREADSHEET ||
          child.type === ChapterItemType.LIBRARY_CONTENT
        ) {
          tableCount++;
          child.numeration = this.currentChapter
            ? `${this.currentChapterNumber}.${tableCount}`
            : `${impactOrder}.${tableCount}`;
        }
        enumerateChildren(child, child.numeration);
      }
    };
    enumerateChildren({ children: this.paragraphs });
  }

  @Mutation
  public toggleFold(shouldFold: boolean): void {
    util.walkChapterTree(this.paragraphs, null, (el) => {
      el.$folded = shouldFold;
    });
    // if (shouldFold) {
    //   //   eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    //   //   @ts-ignore
    //   foldAll(this.currentChapter);
    // } else {
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    //   // @ts-ignore
    //   unfoldAll(this.currentChapter);
    // }
  }

  @Mutation
  toggleParagraphExpandMutation(chapterItemUuid: string): void {
    const foundItem = util.findChapterItemByUuid(this.paragraphs, chapterItemUuid, null);
    foundItem.isExpandedInContent = !foundItem.isExpandedInContent;
  }

  @Mutation
  expandAllInContentMutation(shouldExpand: boolean): void {
    util.walkChapterTree(this.paragraphs, null, (item) => {
      item.isExpandedInContent = shouldExpand;
    });
  }

  @Mutation
  toggleLockMutation(payload: { uuid: string; shouldLock: boolean }): void {
    const foundItem = util.findChapterItemByUuid(this.paragraphs, payload.uuid, null);
    if (payload.shouldLock) {
      foundItem.locked_by = { id: null, model: null, person: null, person_id: null, uuid: null };
      //TODO: ws message -> fill locked_by property on response
      ws.reports.toggleLock(WSMessageCommand.LOCK, payload.uuid, foundItem.type);
    } else {
      foundItem.locked_by = null;
      ws.reports.toggleLock(WSMessageCommand.UNLOCK, payload.uuid, foundItem.type);
    }
  }

  @Mutation
  updateChapterItemMutation(payload: UpdateChapterItemPayload): void {
    const foundItem = util.findChapterItemByUuid(this.paragraphs, payload.uuid, null);
    (foundItem as any)[payload.propertyName] = payload.propertyValue;
    payload.additionalProperties?.forEach((additionalProperty) => {
      (foundItem as any)[additionalProperty.name] = additionalProperty.value;
    });
  }

  @Mutation
  updateChapterItemLevelMutation(uuid: string): void {
    const updateNodeChildrenLevel = (node: ChapterItem) => {
      if (node.children && node.children.length > 0) {
        for (const childNode of node.children) {
          childNode.level = node.level + 1;
          updateNodeChildrenLevel(childNode);
        }
      }
    };

    let parentNode = util.findChapterItemParentNode(this.paragraphs, uuid, null);
    let foundItem: ChapterItem;
    let level = 1;
    if (parentNode) {
      // it means that parentNode isn't root chapter
      parentNode = parentNode as ChapterItem;
      level = parentNode.level + 1;
      foundItem = parentNode.children.find((el) => el.uuid === uuid);
    } else {
      foundItem = this.paragraphs.find((el) => el.uuid === uuid);
    }
    foundItem.level = level;
    updateNodeChildrenLevel(foundItem);
  }

  @Mutation
  toggleSidebarChapterItemFoldMutation(uuid: string): void {
    const item = util.findChapterItemByUuid(this.paragraphs, uuid, null);
    item.$folded = !item.$folded;
  }

  @Mutation
  addChapterItemMutation(payload: NewChapterItemPayload): void {
    const parentNode = util.findChapterItemParentNode(this.paragraphs, payload.uuid, null);
    const parentArray = parentNode ? parentNode.children : this.paragraphs;
    if (parentArray) {
      let newItemIndex = parentArray.findIndex((el) => el.uuid === payload.uuid);
      // SINGLE IMPACT EXCEPTION
      if (this.singleImpact && payload.uuid === this.paragraphs[1].uuid) {
        // single impact view AND second paragraph on page (ParagraphText)
        parentArray[newItemIndex].children.push(new ChapterItem(payload.type, parentArray[0].level + 1));
      } else {
        if (payload.position === 'after') {
          newItemIndex = newItemIndex + 1;
        }
        parentArray.splice(newItemIndex, 0, new ChapterItem(payload.type, parentArray[0].level));
      }
    }
  }

  @Mutation
  deleteChapterItemMutation(uuid: string): void {
    const parentNode = util.findChapterItemParentNode(this.paragraphs, uuid, null);
    const parentArray = parentNode ? parentNode.children : this.paragraphs;
    if (parentArray) {
      const index = parentArray.findIndex((el) => el.uuid === uuid);
      if (!uuid.startsWith(TEMPORARY_UUID_PREFIX)) {
        ws.reports.deleteParagraph(uuid, parentArray[index].type);
      }
      parentArray.splice(index, 1);
    }
  }

  @Mutation
  removeCurrentFlags() {
    walkTreeData(this.paragraphs, (node, _index, _parent, _path) => {
      node.isCurrent = false;
    });
  }

  @Mutation
  handleWebsocketMessageMutation(response: WSMessageResponse) {
    const uuid: string =
      response.command === WSMessageCommand.ADD ? response.params.temporaryUuid : response.params.uuid;
    const foundItem: ChapterItem = util.findChapterItemByUuid(this.paragraphs, uuid, null);
    if (foundItem) {
      if (response.command === WSMessageCommand.ADD) {
        // this.context.commit('updateItemUuid', { oldUuid: null, newUuid: response.params.uuid });
        // const temporaryUuid: string = response.params.temporaryUuid;
        const newUuid = response.params.uuid;
        const lock = response.lock;
        foundItem.uuid = newUuid;
        foundItem.locked_by = lock;
      } else if (response.command === WSMessageCommand.LOCK) {
        foundItem.locked_by = response.lock;
      } else if (response.command === WSMessageCommand.UNLOCK) {
        foundItem.locked_by = null;
      }
    }
  }

  @Action({ rawError: true })
  async initializeChapters(data: { uuid: string; currentChapterNumber: number }) {
    try {
      const chapters = await api.reports.getNavbarChapters(data.uuid);
      this.context.commit('setChapters', { chapters, uuid: data.uuid });
      if (chapters && chapters.length > 0 && data.currentChapterNumber != -1) {
        const currentChapter = { ...this.getChapters[data.currentChapterNumber - 1] };
        currentChapter.children = await api.reports.getChapterByUuid(currentChapter.uuid);
        util.walkChapterTree(currentChapter.children, null, (el) => {
          el.isExpandedInContent = false;
          el.numeration = '';
          el.$folded = false;
        });
        if (this.singleImpact) {
          this.context.commit('setSingleImpact', null);
        }
        this.context.commit('setCurrentChapter', currentChapter);
        this.context.commit('setCurrentChapterNumber', data.currentChapterNumber);
        this.context.commit('enumerate');
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  async initializeSingleImpact(payload: { uuid: string; studyUuid: string; fromMatrix: string }) {
    try {
      const data = await api.reports.getImpactAssesmentParagraphs(payload.uuid, payload.studyUuid, payload.fromMatrix);
      util.walkChapterTree(data.paragraphs[0].children, null, (el) => {
        el.isExpandedInContent = false;
        el.numeration = '';
        el.$folded = false;
      });
      if (this.currentChapter) {
        this.context.commit('setCurrentChapter', null);
        this.context.commit('setChapters', null);
      }
      this.context.commit('setSingleImpact', data);
      this.context.commit('enumerate');
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  async selectChapter(uuid: string): Promise<number> {
    try {
      const chapterIndex = this.getChapters.findIndex((el) => el.uuid === uuid);
      const currentChapter = { ...this.getChapters[chapterIndex] };
      currentChapter.children = await api.reports.getChapterByUuid(uuid);
      util.walkChapterTree(currentChapter.children, null, (el) => {
        el.isExpandedInContent = false;
        el.numeration = '';
        el.$folded = false;
      });
      this.context.commit('setCurrentChapter', currentChapter);
      this.context.commit('setCurrentChapterNumber', chapterIndex + 1);
      this.context.commit('enumerate');

      return chapterIndex;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  public toggleFoldItems(shouldFold: boolean): void {
    this.context.commit('toggleFold', shouldFold);
  }

  @Action({ rawError: true })
  toggleSidebarChapterItemFold(uuid: string): void {
    this.context.commit('toggleSidebarChapterItemFoldMutation', uuid);
  }

  @Action({ rawError: true })
  toggleParagraphExpand(chapterItemUuid: string) {
    this.context.commit('toggleParagraphExpandMutation', chapterItemUuid);
  }

  @Action({ rawError: true })
  expandAllInContent(shouldExpand: boolean) {
    this.context.commit('expandAllInContentMutation', shouldExpand);
  }

  @Action({ rawError: true })
  lockChapterItem(chapterItemUuid: string) {
    this.context.commit('toggleLockMutation', { uuid: chapterItemUuid, shouldLock: true });
  }

  @Action({ rawError: true })
  unlockChapterItem(chapterItemUuid: string) {
    if (chapterItemUuid.startsWith(TEMPORARY_UUID_PREFIX)) {
      this.context.commit('deleteChapterItemMutation', chapterItemUuid);
      this.context.commit('enumerate');
    } else {
      this.context.commit('toggleLockMutation', { uuid: chapterItemUuid, shouldLock: false });
    }
  }

  @Action({ rawError: true })
  addChapterItem(payload: NewChapterItemPayload) {
    this.context.commit('addChapterItemMutation', payload);
    this.context.commit('enumerate');
  }

  @Action({ rawError: true })
  deleteChapterItem(uuid: string) {
    this.context.commit('deleteChapterItemMutation', uuid);
    this.context.commit('enumerate');
  }

  @Action({ rawError: true })
  updateChapterItem(payload: UpdateChapterItemPayload): void {
    this.context.commit('updateChapterItemMutation', payload);
    if (payload.propertyName === 'files') {
      payload.propertyName = 'file_ids';
      payload.propertyValue = payload.propertyValue.reduce(
        (fileIds: number[], file: FileResource) => [...fileIds, file.id],
        [] as number[],
      );
    } else if (payload.propertyName === 'image') {
      payload.propertyName = 'file_id';
      payload.propertyValue = payload.propertyValue.id;
    }

    const parentNode = util.findChapterItemParentNode(this.paragraphs, payload.uuid, null);
    const parentArray = parentNode ? parentNode.children : this.paragraphs;
    if (parentArray) {
      const itemIndex = parentArray.findIndex((el) => el.uuid === payload.uuid);
      const foundItem: ChapterItem = parentArray[itemIndex];
      if (foundItem.uuid.startsWith(TEMPORARY_UUID_PREFIX)) {
        // should add item
        let afterUuid: string = null;
        const data: any = {};
        data[payload.propertyName] = payload.propertyValue;
        payload.additionalProperties?.forEach((additionalProperty) => {
          data[additionalProperty.name] = additionalProperty.value;
        });
        if (itemIndex > 0) {
          afterUuid = parentArray[itemIndex - 1].uuid;
          ws.reports.addParagraph(foundItem.uuid, afterUuid, payload.type, false, data);
        } else {
          //TODO: @ilija check this
          afterUuid = parentNode ? parentNode.uuid : null;
          ws.reports.addParagraph(foundItem.uuid, afterUuid, payload.type, true, data);
        }
        return;
      }

      // if (foundItem.hasOwnProperty(payload.propertyName)) {
      // if (foundItem.uuid.startsWith(TEMPORARY_UUID_PREFIX)) {
      //   // uuid not updated yet
      //   return;
      // }

      // update item
      ws.reports.updateParagraph(payload.type, payload.uuid, payload.propertyName, payload.propertyValue);
      payload.additionalProperties?.forEach((additionalProperty) => {
        ws.reports.updateParagraph(payload.type, payload.uuid, additionalProperty.name, additionalProperty.value);
      });
    }
    // }
  }

  @Action({ rawError: true })
  onChapterItemsReorder(payload: OnChapterItemsReorderPayload) {
    this.context.commit('updateParagraphsAfterReorder', payload.updatedTreeData);
    this.context.commit('updateChapterItemLevelMutation', payload.dragNode.uuid);
    this.context.commit('enumerate');

    let afterUuid: string = null;
    let isFirstChild: boolean = false;

    const parentNode = util.findChapterItemParentNode(this.paragraphs, payload.dragNode.uuid, null);
    const parentArray = parentNode ? parentNode.children : this.paragraphs;
    if (parentArray) {
      const itemIndex = parentArray.findIndex((el) => el.uuid === payload.dragNode.uuid);

      if (itemIndex > 0) {
        afterUuid = payload.afterNode.uuid;
      } else {
        afterUuid = parentNode ? parentNode.uuid : null;
      }
      isFirstChild = afterUuid && parentNode && afterUuid == parentNode.uuid ? true : false;
    }

    ws.reports.reorderParagraph(payload.dragNode.uuid, payload.dragNode.type, afterUuid, isFirstChild);
  }

  @Action({ rawError: true })
  findSidebarItem(searchText: string): string {
    let resultUuid: string = null;
    // walkTreeData(this.paragraphs, (node, index, parent, path) => {
    //   const item: ChapterItem = node as ChapterItem;
    //   const itemOrder = util.getItemOrderForChapter(this.currentChapter, path);
    //   const fullText = `${itemOrder} ${item.title}`;
    //   if (fullText.toLowerCase().includes(searchText)) {
    //     if (resultUuid == null) {
    //       resultUuid = item.uuid;
    //       item.isCurrent = true;
    //     }
    //     return;
    //   }
    // });
    util.walkChapterTree(this.paragraphs, null, (el) => {
      const fullText = `${el.numeration} ${el.title}`;
      if (fullText.toLowerCase().includes(searchText)) {
        if (resultUuid == null) {
          resultUuid = el.uuid;
          el.isCurrent = true;
        }
        return;
      }
    });
    return resultUuid;
  }

  @Action({ rawError: true })
  setCurrentSidebarItem(uuid: string) {
    this.context.commit('removeCurrentFlags');
    walkTreeData(this.paragraphs, (node, _index, _parent, _path) => {
      const item: ChapterItem = node as ChapterItem;
      if (item.uuid == uuid) {
        item.isCurrent = true;
        eventBus.$emit(EventType.RERENDER_REPORT_SIDEBAR);
        return;
      }
    });
  }

  @Action({ rawError: true })
  public handleWebsocketMessage(response: WSMessageResponse) {
    this.context.commit('handleWebsocketMessageMutation', response);
    // check if action is successful
    const uuid: string = response.params.uuid || response.data[0].uuid;
    const userId: number = response.params.uid;
    const currentUserId = store.getters['User/userId'];
    const foundParagraph: ChapterItem = util.findChapterItemByUuid(this.paragraphs, uuid, null);
    if (userId === currentUserId) {
      if (foundParagraph) {
        if (response.command === WSMessageCommand.ADD) {
          return !foundParagraph.uuid.startsWith(TEMPORARY_UUID_PREFIX);
        } else if (response.command === WSMessageCommand.LOCK) {
          return foundParagraph.locked_by && foundParagraph.locked_by.person_id === response.lock.person_id;
        } else if (response.command === WSMessageCommand.UNLOCK) {
          return !foundParagraph.locked_by;
        } else if (response.command === WSMessageCommand.DELETE) {
          return !foundParagraph;
        }
      } else {
        return response.command === WSMessageCommand.DELETE;
      }
    } else {
      LoaderService.disableHttpLoader();
      if (this.singleImpact) {
        this.context
          .dispatch('initializeSingleImpact', this.singleImpact.uuid)
          .finally(() => LoaderService.enableHttpLoader());
      } else if (this.currentChapter) {
        this.context
          .dispatch('initializeChapters', {
            uuid: this.chaptersUuid,
            currentChapterNumber: this.currentChapter.order,
          })
          .finally(() => LoaderService.enableHttpLoader());
      }
    }
    return true;
  }

  @Action({ rawError: true })
  excludeAllAction(value: boolean) {
    this.context.commit('excludeAll', value);
    this.context.commit('reNumerateItems');
  }

  @Mutation
  excludeAll(value: boolean) {
    for (let i = 0; i < this.chapters.length; i++) {
      const chapter = this.chapters[i];
      chapter.excludeFromPdf = value;
      chapter.localOrder = '';
      this.chapters.splice(i, 1, chapter);
    }
  }

  @Mutation
  reorderItems() {
    this.chapters = this.chapters.sort((a, b) => (Number(a.localOrder) > Number(b.localOrder) ? 1 : -1));
  }
  @Mutation
  reNumerateItems() {
    let localIndex = 0;
    for (let i = 0; i < this.chapters.length; i++) {
      if (!this.chapters[i].excludeFromPdf) {
        localIndex++;
        this.chapters[i].localOrder = localIndex.toString();
      } else {
        this.chapters[i].localOrder = '';
      }
      this.chapters.splice(i, 1, this.chapters[i]);
    }
  }

  // TODO Replace title with uuid when we see api spec
  @Mutation
  togglePdfSidebarExpand(title: string) {
    const index = this.pdfHistory.findIndex((e) => e.title == title);
    this.pdfHistory[index].expanded = !this.pdfHistory[index].expanded;
    this.pdfHistory.splice(index, 1, this.pdfHistory[index]);
  }

  @Mutation
  togglePdfSidebarExpandAll(value: boolean) {
    for (let index = 0; index < this.pdfHistory.length; index++) {
      this.pdfHistory[index].expanded = value;
      this.pdfHistory.splice(index, 1, this.pdfHistory[index]);
    }
  }

  @Action({ rawError: true })
  clearState() {
    this.context.commit('setChapters', null);
    this.context.commit('setCurrentChapter', null);
    this.context.commit('setSingleImpact', null);
  }
}
