import store from '../store';

export default class DialogService {
  static presentDialog(
    title: string,
    message: string,
    confirmLabel: string,
    cancelLabel: string,
    confirmCallback: (context?: any) => void = () => {
      /* */
    },
    cancelCallback: () => void = () => {
      /* */
    },
    checkboxLabel?: string,
  ) {
    store.dispatch('Dialog/show', {
      title,
      message,
      confirmLabel,
      cancelLabel,
      checkboxLabel,
      confirmCallback,
      cancelCallback,
    });
  }
}
