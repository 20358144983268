
import { Component, Vue } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { DashboardDataTableColumnType } from '../../enums/dashboard-data-table.enum';
import { DashboardDataTableColumn } from '../../models/DashboardDataTable';

@Component
export default class DashboardDynamicColumns extends Vue {
  dynamicColumns: DashboardDataTableColumn[] = [];
  tagsColumns: any[];

  @AsyncComputed()
  async userActionColumns(): Promise<DashboardDataTableColumn[]> {
    return [];
  }

  get actionColumns() {
    return ((this.userActionColumns as unknown) as DashboardDataTableColumn[]) || [];
  }

  initDynamicColumns() {
    this.dynamicColumns = this.tagsColumns.map((tagsColumn) => ({
      text: tagsColumn.name,
      value: tagsColumn.field,
      type: DashboardDataTableColumnType.SELECT,
      resizable: true,
      checked: true,
      transformValue: typeof tagsColumn.transformValue === 'function' ? tagsColumn.transformValue : null,
    }));
  }
}
