









































import { Component, Emit } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import NavbarCallout from '@/app/shared/components/NavbarCallout.vue';
import UserAvatar from '@/app/shared/components/UserAvatar.vue';
import { User } from '@/app/shared/models/User';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

const UserStore = namespace('User');

@Component({
  components: {
    UserAvatar,
  },
})
export default class UserCallout extends NavbarCallout {
  @Emit()
  logout() {
    //
  }

  @UserStore.State
  user: User;

  @UserStore.Getter
  userFullName: string;

  @UserStore.Getter
  userAvatar: string;

  @UserStore.Action
  public isAllowed: (permission: string | string[]) => Promise<boolean>;

  @AsyncComputed()
  async isUserProfileAllowed() {
    return await this.isAllowed(UserPermission.USER_PROFILE_ACCESS);
  }

  @AsyncComputed()
  async hasAvatarInitials() {
    return await this.isAllowed(UserPermission.TOP_BAR_SHOW_AVATAR_INITIALS);
  }
}
