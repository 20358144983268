export enum WorkspaceType {
  CHECKTREE = 'checktree',
  QUESTIONNAIRE = 'questionnnaire',
  MATRIX_CROSSCONNECT = 'matrix',
  MATRIX_TAGGING = 'matrix_tagging',
  IMPACT_INTERACTION = 'impact_interaction',
  PROCESSING = 'processing',
  PROCESSING_CHECKTREE = 'processing_checktree',
  TEMPLATES = 'templates',
  SUMMARY_REPORT = 'summary_report',
  FULL_REPORT = 'full_report',
  PDF_OUTPUT = 'pdf_output',
  PRESENTATION = 'presentation',
}
