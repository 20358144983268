export class Organization {
  uuid?: string;
  name: string;
  address: string;
  email: string;
  phone_number: string;
  website: string;
  logo: string;
  isDetailsHidden: boolean;

  constructor(
    name: string = '',
    address: string = '',
    email: string = '',
    phone_number: string = '',
    website: string = '',
    logo: string = null,
    isDetailsHidden: boolean = false,
  ) {
    this.name = name;
    this.address = address;
    this.email = email;
    this.phone_number = phone_number;
    this.website = website;
    this.logo = logo;
    this.isDetailsHidden = isDetailsHidden;
  }
}
