










import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import PageHeader from '@/app/shared/components/PageHeader.vue';

@Component({
  components: { PageHeader },
})
export default class PresentationsPageHeader extends Vue {
  @Prop() title: string;

  @Watch('title')
  onTitleChange(title: string) {
    // Set the meta title directly, since we are fetching this value asynchronously.
    if (title) document.title = `${title} - Envigo`;
  }
}
