




































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { FormRenderer as VueFormRenderer } from 'v-form-builder';
import { EVENT_CONSTANTS } from 'v-form-builder/src/configs/events';
import { FormSchema, SectionObject, ValidationRule } from '@/app/shared/models/form/FormSchema';
import { validationClosures } from '@/plugins/vue-form-builder';
import { AxiosError, AxiosResponse } from 'axios';
import { merge } from 'lodash';

@Component({
  components: {
    VueFormRenderer,
  },
})
export default class FormRenderer extends Vue {
  @Prop() schema: FormSchema;
  @Prop({ type: Boolean }) isHintHidden: boolean;
  @Prop() hintText: string;
  @Prop() serverError: AxiosError;
  @Prop() defaultValues: {};
  @Prop() width?: number;
  values = {};
  isErrorAlertShown = false;
  defaultErrorText: string = 'An entry is required or has an invalid value. Please correct and try again.';
  errorText: string = this.defaultErrorText;

  @Emit()
  change(_values: {}) {
    //
  }

  @Emit()
  submit(_values: {}) {
    //
  }

  @Emit()
  invalid() {
    //
  }

  @Emit('update:serverError')
  updateServerError(_serverError: AxiosError) {
    //
  }

  @Watch('defaultValues')
  onDefaultValuesChange(_values: any) {
    this.setDefaultValues();
  }

  @Watch('serverError')
  onServerErrorChange(serverError: AxiosError) {
    this.isErrorAlertShown = true;

    const serverResponse = serverError && (serverError.response as AxiosResponse);

    if (serverResponse) {
      switch (serverResponse.status) {
        case 422:
          if (serverResponse.data && serverResponse.data.detail && typeof serverResponse.data.detail === 'object') {
            serverResponse.data.detail.forEach((detail: { loc: string[]; msg: string; type: string }) => {
              if (detail.loc && detail.loc.length) {
                detail.loc.forEach((fieldName: string) => {
                  const errorMessage = detail.msg || 'Unknown error';
                  this.setValidationError(fieldName, errorMessage);
                });

                this.errorText = this.defaultErrorText;
                return;
              }
            });

            if (!serverResponse.data.detail.length) {
              console.warn('Unexpected structure of `detail` key:', serverResponse.data.detail);
            }
          } else if (serverResponse.data && serverResponse.data.detail) {
            this.errorText = serverResponse.data.detail;
            return;
          }
          break;
        default:
          if (serverResponse.data && serverResponse.data.detail) {
            this.errorText = serverResponse.data.detail;
            return;
          }
      }
    }

    this.errorText = this.defaultErrorText;
  }

  get isHintVisible() {
    if (this.isHintHidden || this.isErrorAlertShown) return false;
    if (this.hintText || this.hasRequiredFields) return true;
    return false;
  }

  // Returns the ID of the first field in the form.
  get firstFieldId() {
    const sortedSections: SectionObject[] = [];

    Object.values(this.schema.sections).forEach((sectionObject) => {
      sortedSections.push(sectionObject);
    });

    sortedSections.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });

    if (sortedSections.length && sortedSections[0].controls.length) return sortedSections[0].controls[0];
    return null;
  }

  get hasRequiredFields() {
    const controlIds = Object.keys(this.schema.controls);

    for (const controlId of controlIds) {
      const validations: ValidationRule[] = this.schema.controls[controlId].validations || [];
      for (const validation of validations) {
        if (validation.ruleType === 'required' || /^required/.test(validation.additionalValue)) return true;
      }
    }

    return false;
  }

  created() {
    // Disable built-in validation error alert in VueFormBuilder.
    //   Due to a bug in original code, alert will be shown even disabled via plugin options.
    this.$form.validationErrorShowAlert = false;

    this.$formEvent.$off(EVENT_CONSTANTS.RENDERER.VALIDATION_OK);
    this.$formEvent.$off(EVENT_CONSTANTS.RENDERER.VALIDATION_FAILED);
    this.$formEvent.$off(EVENT_CONSTANTS.RENDERER.RUN_VALIDATION);

    // Register event handlers for form validation events.
    this.$formEvent.$on(EVENT_CONSTANTS.RENDERER.VALIDATION_OK, this.onFormValidationOk);
    this.$formEvent.$on(EVENT_CONSTANTS.RENDERER.VALIDATION_FAILED, this.onFormValidationFailed);
    this.$formEvent.$on(EVENT_CONSTANTS.RENDERER.RUN_VALIDATION, this.onFormRunValidation);

    // Register event handler for button emitter.
    this.$formEvent.$on('form-field-button', this.onFormFieldButton);
  }

  mounted() {
    this.setDefaultValues();

    // Properly re-initialize custom closures instance property in VueFormBuilder.
    //   Due to a bug in original code, all custom closures are ignored when specified via plugin options.
    this.$form.Validation.customClosures = validationClosures;

    // Focus first field in the form.
    //   Do this asynchronously, as the field component may not be mounted yet.
    setTimeout(this.focusFirstField, 100);
  }

  beforeDestroy() {
    // Deregister event handlers for form validation events.
    //   Make sure to deregister all of them explicitly, due to bad naming practice with dots (.) in the name.
    //   If an event handler is unregistered, next instantiation of the component will simply add more handlers.
    this.$formEvent.$off(EVENT_CONSTANTS.RENDERER.VALIDATION_OK, this.onFormValidationOk);
    this.$formEvent.$off(EVENT_CONSTANTS.RENDERER.VALIDATION_FAILED, this.onFormValidationFailed);
    this.$formEvent.$off(EVENT_CONSTANTS.RENDERER.RUN_VALIDATION, this.onFormRunValidation);

    // Deregister event handler for button emitter.
    this.$formEvent.$off('form-field-button', this.onFormFieldButton);
  }

  setDefaultValues() {
    if (this.defaultValues) this.values = merge(this.values, this.defaultValues);
  }

  onFormValidationOk() {
    this.submit({
      ...this.values,
    });
  }

  onFormValidationFailed() {
    this.isErrorAlertShown = true;
    if (this.$refs.root) (this.$refs.root as HTMLElement).scrollIntoView();
    this.invalid();
  }

  onFormRunValidation() {
    this.isErrorAlertShown = false;
    this.updateServerError(null);
  }

  setValidationError(fieldName: string, errorMessage: string) {
    this.$set(this.$refs.form, 'validationErrors', {
      ...(this.$refs.form as Vue & { validationErrors: Record<string, string[]> }).validationErrors,
      [fieldName]: [errorMessage],
    });
  }

  onFormFieldButton(payload: { name: string; payload: {} }) {
    this.$emit(payload.name, {
      payload,
      values: {
        ...this.values,
      },
    });
  }

  onChange(changedFields: { [key: string]: any }) {
    this.change({ ...changedFields });
  }

  focusFirstField() {
    if (this.firstFieldId) {
      if (this.$form.fields && this.$form.fields.hasOwnProperty(this.firstFieldId)) {
        const firstField = this.$form.fields[this.firstFieldId];
        if (firstField.hasOwnProperty('focus') && typeof firstField.focus === 'function') {
          this.$nextTick(() => {
            firstField.focus();
          });
        }
      }
      return;
    }

    // Defer the focusing, in case the component is not ready yet.
    setTimeout(this.focusFirstField, 100);
  }

  runValidation() {
    (this.$refs.form as Vue & { runValidation: () => void }).runValidation();
  }
}
