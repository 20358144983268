









































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import VersioningContent from './versioning/VersioningContent.vue';

const UiModuleStore = namespace('UI');

@Component({
  components: {
    VersioningContent,
  },
})
export default class Versioning extends Vue {
  @Prop() defaultWidth: number;
  isCollapsed: boolean = false;
  readonly maxWidth = 648;
  readonly minWidth = 360;

  @UiModuleStore.Mutation
  setFooterVersioningActive!: (value: boolean) => void;

  mounted() {
    (this.$refs.resizeGrabber as Vue & { $el: HTMLSpanElement }).$el.addEventListener(
      'mousedown',
      this.initResize,
      false,
    );
  }

  beforeDestroy() {
    (this.$refs.resizeGrabber as Vue & { $el: HTMLSpanElement }).$el.removeEventListener('mousedown', this.initResize);
  }

  initResize() {
    window.addEventListener('mousemove', this.resize, false);
    window.addEventListener('mouseup', this.stopResize, false);
  }

  resize(e: any) {
    const element = this.$refs.versioningWrapper as HTMLDivElement;
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    let newWidth = vw - e.clientX;
    if (newWidth > this.maxWidth) {
      newWidth = this.maxWidth;
    }
    if (newWidth < this.minWidth) {
      newWidth = this.minWidth;
    }
    element.style.width = `${newWidth}px`;
  }

  stopResize() {
    window.removeEventListener('mousemove', this.resize, false);
    window.removeEventListener('mouseup', this.stopResize, false);
  }
}
