






















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import PresentationsBottomActionBar from './../PresentationsBottomActionBar.vue';

export type PresentationsStatisticsTableType = 'none' | 'row' | 'row_column';

@Component({
  components: { PresentationsBottomActionBar },
})
export default class PresentationsStatisticsTable extends Vue {
  @Prop() tableData: Array<Array<string>>;
  @Prop() sizeOption: 'half' | 'full';
  @Prop() type: PresentationsStatisticsTableType;
  @Prop() tableHeader?: string;

  style: any = {
    tableWrapper: {
      // width: '100%',
    },
    topBorder: {
      height: '8px',
      background: '#D3DADC',
      borderRadius: '2px 2px 0 0',
    },
    table: {
      border: '1px solid #D3DADC',
    },
    tableRow: {
      borderTop: '1px solid #D3DADC',
      height: '32px',
      display: 'flex',
    },
    tableColumn: {
      flex: '1 1 0',
      // flexShrink: '0',
      borderLeft: '1px solid #D3DADC',
      display: 'flex',
      alignItems: 'center',
      // padding: '0 0.75rem',
    },
    tableColumnTitleSpan: {
      fontSize: '11px',
      lineHeight: '14px',
      letterSpacing: '1px',
      fontWeight: '500',
      color: '#6F8195',
      textTransform: 'uppercase',
      padding: '0 0.75rem',
      width: '100%',
      textAlign: 'center',
    },
    tableColumnLabelSpan: {
      fontSize: '13px',
      lineHeight: '13px',
      letterSpacing: '0.25px',
      color: '#6F8195',
      fontStyle: 'italic',
      textAlign: 'left',
      // padding: '0 0.5rem',
    },
    tableColumnValueSpan: {
      fontSize: '12px',
      lineHeight: '12px',
      letterSpacing: '0',
      fontWeight: '500',
      color: '#222C37',
      padding: '0 0.75rem',
    },
  };

  // isNumberValue(value: any) {
  //   return !isNaN(value);
  // }
}
