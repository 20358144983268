import FormConfigComment from '@/app/shared/components/form/config/FormConfigComment.vue';
import FormFieldComment from '@/app/shared/components/form/field/FormFieldComment.vue';
import { CommentValue } from '@/app/shared/models/form/field/FormFieldComment';

export default {
  name: 'Comment Control',
  description: 'Optional comment input',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldComment,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigComment,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    isRemovable: true,
    isDisabled: false,
    isReadonly: false,
    helpText: '',
    isSmall: true,

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {CommentValue}
   */
  rendererDefaultData() {
    return new CommentValue();
  },
};
