
















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseSelect extends Vue {
  @Prop() value: string;
  @Prop() label: string;
  @Prop() items: Array<{ label: string; value: string | number }>;
  @Prop() disabled: boolean;

  @Emit()
  input(_value: boolean) {
    //
  }
}
