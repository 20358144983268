import isEmailRule from 'v-form-builder/src/libraries/validations/is-email';

type isEmailRule = (fieldValue: string) => boolean;

export default (fieldValue: string): boolean => {
  // Will be handled by the required validator.
  if (fieldValue === undefined || fieldValue === null || !fieldValue.length) return true;

  return isEmailRule(fieldValue.toLowerCase());
};
