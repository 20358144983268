









import CheckTreeItem from '@/app/shared/models/CheckTreeItem';
import { Component, Prop, Vue } from 'vue-property-decorator';
import FactorInput from './FactorInput.vue';

@Component({ components: { FactorInput } })
export default class CriteriaFactor extends Vue {
  @Prop() private factor: CheckTreeItem;
  @Prop() unitUuid: string;

  get label() {
    return this.factor.title;
  }
}
