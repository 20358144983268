import { LocalStorageKey } from './../enums/local-storage-key.enum';
/* eslint-disable @typescript-eslint/no-explicit-any */
export default class LocalStorage {
  static setItem(key: LocalStorageKey, payload: any) {
    localStorage.setItem(key, JSON.stringify(payload));
  }

  static getItem(key: LocalStorageKey) {
    return JSON.parse(localStorage.getItem(key));
  }

  static removeItem(key: LocalStorageKey) {
    localStorage.removeItem(key);
  }
}
