








import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CalculationsDataTableActionField extends Vue {
  @Prop() label: string;
  @Prop() icon: string;
  @Prop() isDisabled: boolean;

  @Emit()
  click() {
    //
  }
}
