var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center tracking-item-header",style:({
    '--height': _vm.isExpanded ? '56px' : '36px',
  })},[(_vm.isExpanded)?[_c('div',{staticClass:"d-flex grow tracking-item-header__toolbar"},[(_vm.isLocked && _vm.isUnlockAllowed)?_c('BaseButton',{attrs:{"type":"secondary","icon":"unlock"},on:{"click":_vm.unlock}},[_vm._v(" Unlock ")]):(_vm.objectModule)?_c('div',{staticClass:"flex-shrink-0 tracking-item-header__object-module"},[_vm._v(" "+_vm._s(_vm.objectModule)+" ")]):_c('BaseButton',{attrs:{"type":"secondary","icon":"collapse"},on:{"click":_vm.collapse}},[_vm._v(" Collapse ")])],1),_c('div',{staticClass:"d-flex align-center tracking-item-header__expanded-info"},[(_vm.isLocked)?_c('TrackingBadge',{attrs:{"type":"locked"}},[_vm._v(" Locked ")]):(_vm.isUpdated)?_c('TrackingBadge',{attrs:{"type":"updated"}},[_vm._v(" Updated ")]):_vm._e()],1)]:[_c('div',{staticClass:"d-flex flex-shrink-0 align-center tracking-item-header__user-avatars"},[_vm._l((_vm.trackingItemUsers.slice(0, _vm.maxUsers).reverse()),function(trackingItemUser){return _c('UserAvatar',{key:trackingItemUser.uuid,attrs:{"name":_vm.getUserName(trackingItemUser),"image":trackingItemUser.avatar,"avatar-class":"tracking-item-header__user-avatar","size":"small","is-disabled":""}})}),(_vm.plusMoreLabel)?_c('div',{staticClass:"d-flex align-center justify-center tracking-item-header__more-users"},[_vm._v(" "+_vm._s(_vm.plusMoreLabel)+" ")]):_vm._e()],2),_c('div',{staticClass:"d-flex flex-grow-1 flex-column justify-center tracking-item-header__metadata"},[_c('v-tooltip',{attrs:{"content-class":"ellipsis-text","left":"","nudge-left":"10","transition":"fade"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var ref_on = ref.on;
  var mouseenter = ref_on.mouseenter;
  var mouseleave = ref_on.mouseleave;
  var attrs = ref.attrs;
return [_c('div',_vm._b({staticClass:"tracking-item-header__user-name",attrs:{"data-cy":"user-name"},on:{"mouseenter":mouseenter,"mouseleave":mouseleave,"click":function($event){_vm.isExpanded = !_vm.isExpanded}}},'div',attrs,false),[_vm._v(" "+_vm._s(_vm.trackingItemUserNames)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.trackingItemUserNames))])])],1),_c('div',{staticClass:"d-flex align-center tracking-item-header__collapsed-info"},[(_vm.objectModule)?_c('div',{staticClass:"flex-shrink-0 tracking-item-header__object-module"},[_vm._v(" "+_vm._s(_vm.objectModule)+" ")]):_vm._e(),(_vm.isLocked)?_c('TrackingBadge',{attrs:{"type":"locked"}},[_vm._v(" Locked ")]):(_vm.isUpdated)?_c('TrackingBadge',{attrs:{"type":"updated"}},[_vm._v(" Updated ")]):_vm._e()],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }