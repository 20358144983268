






































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';
import Loader from '@/app/shared/components/Loader.vue';

@Component({
  components: { editor: Editor, Loader },
})
export default class TinyEditorModal extends Vue {
  @Prop() private value!: string;
  @Prop({ default: 'text' }) outputFormat: 'text' | 'html';

  content: string = '';
  initialized: boolean = false;
  active: boolean = false;

  created() {
    if (this.value) {
      this.content = this.value;
    }
  }

  mounted() {
    this.$nextTick(() => {
      this.active = true;
    });

    document.addEventListener('focusin', this.focusinTox, true);
  }

  beforeDestroy() {
    document.removeEventListener('focusin', this.focusinTox, true);
  }

  @Emit()
  onInit() {
    // just emiting event
  }

  @Emit()
  input(_value: string) {
    // just emiting event
  }

  @Emit()
  close() {
    // just emiting event
  }

  @Watch('value')
  onValuePropChange() {
    this.content = this.value;
  }

  @Watch('content')
  onContentChange() {
    this.input(this.content);
  }

  focusinTox(event: FocusEvent) {
    // Prevent TinyMCE editor modals from being denied focus.
    //   More info here: https://github.com/tinymce/tinymce/issues/782
    if (document.querySelector('.tox-dialog')) event.stopImmediatePropagation();
  }
}
