




































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import VersioningBadge from './VersioningBadge.vue';
import VersioningStar from './VersioningStar.vue';
import { VersioningItem } from '@/app/shared/models/Versioning';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

@Component({
  components: {
    VersioningBadge,
    VersioningStar,
  },
})
export default class VersioningItemHeader extends Vue {
  @Prop() versioningItem: VersioningItem;
  displayFormat: string = 'D MMM, YYYY';

  @Emit('toggle-expand')
  toggleExpand() {
    //
  }

  get isActive() {
    return this.versioningItem.is_active;
  }

  get isAuthority() {
    return this.versioningItem.is_authority;
  }

  get isPublic() {
    return this.versioningItem.is_public;
  }

  get isImportant() {
    return this.versioningItem.is_important;
  }

  get isExpanded() {
    return this.versioningItem.is_expanded;
  }

  get createdDate() {
    return dayjs
      .utc(this.versioningItem.created)
      .local()
      .format(this.displayFormat);
  }
}
