const requiredObjectTitle = (fieldValue: any): boolean => {
  if (Array.isArray(fieldValue.data)) {
    return fieldValue.data.every((item: any) => requiredObjectTitle(item));
  } else if (Array.isArray(fieldValue)) {
    return fieldValue.every((item: any) => requiredObjectTitle(item));
  } else if (typeof fieldValue === 'object' && !Array.isArray(fieldValue) && fieldValue !== null) {
    if (fieldValue.title !== undefined && fieldValue.title !== null && fieldValue.title.length) return true;
    return false;
  }

  return false;
};

export default requiredObjectTitle;
