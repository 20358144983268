




import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CommentingCommentContent extends Vue {
  @Prop() content: string;

  get commentContent() {
    let commentContent = this.content;

    const urlRegex = /https?:\/\/[^\s]*/g;
    const urlMatch = commentContent.match(urlRegex);

    if (urlMatch) {
      commentContent = commentContent.replaceAll(urlRegex, `<a href="${urlMatch}" target="_blank">${urlMatch}</a>`);
    }

    return commentContent;
  }
}
