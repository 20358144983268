






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ResizeObserver from 'resize-observer-polyfill';
import Loader from '@/app/shared/components/Loader.vue';
import TrackingBadge from './TrackingBadge.vue';
import TrackingBox from './TrackingBox.vue';
import TrackingHistory from './TrackingHistory.vue';
import TrackingTimestamp from './TrackingTimestamp.vue';
import { TrackingItem, TrackingMode, TrackingResponseItem } from '@/app/shared/models/Tracking';

const TrackingStore = namespace('Tracking');

@Component({
  components: {
    TrackingBadge,
    TrackingBox,
    TrackingHistory,
    TrackingTimestamp,
    Loader,
  },
})
export default class TrackingContent extends Vue {
  @Prop({ default: false, type: Boolean }) isCollapsedToolbar: boolean;
  isLoading: boolean = true;
  resizeObserver: ResizeObserver = null;

  @TrackingStore.State
  mode: 'feed' | 'history';

  @TrackingStore.State
  trackingItemFilter: string;

  @TrackingStore.State
  trackingItems: TrackingResponseItem[];

  @TrackingStore.Getter
  trackingItemFeed: TrackingItem[];

  @TrackingStore.Getter
  trackingItemHistory: Record<string, TrackingItem[]>;

  @TrackingStore.Getter
  isTrackingFeedMode: boolean;

  @TrackingStore.Getter
  isTrackingHistoryMode: boolean;

  @TrackingStore.Getter
  trackingItemFilters: any[];

  @TrackingStore.Getter
  dateItems: any[];

  @TrackingStore.Mutation
  setTrackingItemFilter: (trackingItemFilter: string) => void;

  @TrackingStore.Action
  switchMode: () => void;

  @TrackingStore.Action
  getTrackingItems: () => Promise<void>;

  @TrackingStore.Action
  unlockTrackingItem: (trackingItem: TrackingItem) => Promise<void>;

  @Watch('mode')
  onModeChange(mode: TrackingMode) {
    switch (mode) {
      case 'history':
        this.deregisterEventListeners();
        break;
      case 'feed':
      default:
        this.registerEventListeners();
    }
  }

  get filter() {
    return this.trackingItemFilter;
  }

  set filter(filter: string) {
    this.setTrackingItemFilter(filter);
  }

  get lockedCount() {
    const lockedTrackingItems = this.trackingItemFeed.filter((trackingItem) => trackingItem.is_locked);
    if (!lockedTrackingItems.length) return null;
    return `${lockedTrackingItems.length} locked`;
  }

  async mounted() {
    try {
      await this.getTrackingItems();
    } catch (error) {
      console.warn(error);
    } finally {
      this.isLoading = false;
    }

    this.registerEventListeners();
  }

  beforeDestroy() {
    this.deregisterEventListeners();
  }

  registerEventListeners() {
    window.addEventListener('scroll', this.setFeedPosition);
    this.resizeObserver = new ResizeObserver(this.setFeedHeight);
    this.resizeObserver.observe(document.querySelector('#report-contents'));
    this.$nextTick(() => {
      this.setFeedPosition();
    });
  }

  deregisterEventListeners() {
    this.resizeObserver.disconnect();
    window.removeEventListener('scroll', this.setFeedPosition);
  }

  setFeedHeight(entries?: ResizeObserverEntry[]) {
    if (!this.$refs.trackingItemsFeed) return;

    const reportContents =
      (entries && (entries[0].target as HTMLDivElement)) ||
      (document.querySelector('#report-contents') as HTMLDivElement);

    const trackingItemsFeed = this.$refs.trackingItemsFeed as HTMLDivElement;
    trackingItemsFeed.style.height = `${reportContents.scrollHeight}px`;
  }

  setFeedPosition() {
    if (!this.$refs.trackingItemsFeed) return;

    const trackingItemsFeed = this.$refs.trackingItemsFeed as HTMLDivElement;
    trackingItemsFeed.style.top = `${-document.scrollingElement.scrollTop - 38}px`;
  }

  jumpToDate(date: string) {
    const historyContainer = this.$refs.historyContainer as HTMLDivElement;
    if (!historyContainer) return;

    if (date === 'all') {
      historyContainer.scrollTop = historyContainer.scrollHeight;
      return;
    }

    const dateGroup = this.$refs[date] as HTMLDivElement[];
    if (!dateGroup || !dateGroup[0]) return;

    dateGroup[0].scrollIntoView();
  }
}
