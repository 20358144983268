import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import { wsInstance } from './../websocket.service';
import { WSMessageCommand } from './../../enums/ws-message-command.enum';
import { WSMessageHandler } from './../../enums/ws-message-handler.enum';
import { WSMessage } from './../../models/WSMessage';
import store from './../../store';

export default {
  addParagraph(
    temporaryUuid: string,
    afterUuid: string,
    paragraphType: ChapterItemType,
    asChild: boolean,
    data: any = {},
    route: string = null,
  ) {
    const paramsObj: any = {
      model: paragraphType,
      temporaryUuid,
      uid: store.getters['User/userId'],
      route,
      chapter_id: store.getters['Reports/getCurrentChapter'].id,
      study_uuid: store.getters['ModuleProperties/studyUuid'],
    };

    if (afterUuid) {
      paramsObj.after = afterUuid;
      paramsObj.as_child = asChild ? 1 : 0;
    }

    const message: WSMessage = {
      handler: WSMessageHandler.JOINED_LOCKER,
      command: WSMessageCommand.ADD,
      params: paramsObj,
      data: { ...data, type: paragraphType },
    };
    wsInstance.sendMessage(message);
  },
  toggleLock(
    command: WSMessageCommand.LOCK | WSMessageCommand.UNLOCK,
    uuid: string,
    paragraphType: ChapterItemType,
    route: string = null,
  ) {
    const message: WSMessage = {
      handler: WSMessageHandler.JOINED_LOCKER,
      command: command,
      params: {
        model: paragraphType,
        uuid: uuid,
        uid: store.getters['User/userId'],
        route: route,
      },
      data: {},
    };
    wsInstance.sendMessage(message);
  },

  updateParagraph(
    paragraphType: ChapterItemType,
    uuid: string,
    propertyName: string,
    propertyValue: string | boolean,
    route: string = null,
  ) {
    const dataObj: any = {
      uuid,
      type: paragraphType,
    };
    dataObj[propertyName] = propertyValue;
    const message: WSMessage = {
      handler: WSMessageHandler.JOINED_LOCKER,
      command: WSMessageCommand.UPDATE,
      params: {
        uid: store.getters['User/userId'],
        route: route,
      },
      data: [dataObj],
    };
    // this.toggleLock(WSMessageCommand.LOCK, uuid, paragraphType);
    wsInstance.sendMessage(message);
  },

  deleteParagraph(uuid: string, paragraphType: ChapterItemType, route: string = null) {
    const message: WSMessage = {
      handler: WSMessageHandler.JOINED_LOCKER,
      command: WSMessageCommand.DELETE,
      params: {
        uid: store.getters['User/userId'],
        route: route,
      },
      data: [{ uuid: uuid, type: paragraphType }],
    };
    wsInstance.sendMessage(message);
  },

  reorderParagraph(
    uuid: string,
    paragraphType: ChapterItemType,
    afterUuid: string,
    asChild: boolean,
    route: string = null,
  ) {
    const message: WSMessage = {
      handler: WSMessageHandler.JOINED_LOCKER,
      command: WSMessageCommand.REORDER,
      params: {
        model: paragraphType,
        uuid: uuid,
        after: afterUuid,
        as_child: asChild ? 1 : 0,
        uid: store.getters['User/userId'],
        route: route,
        chapter_id: store.getters['Reports/getCurrentChapter'].id,
        study_uuid: store.getters['ModuleProperties/studyUuid'],
      },
      data: {},
    };
    wsInstance.sendMessage(message);
  },
};
