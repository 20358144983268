













































import { Component, Prop, Vue } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import ResizeObserver from 'resize-observer-polyfill';
import VersioningBox from './VersioningBox.vue';
import Loader from '@/app/shared/components/Loader.vue';
import { OptionItem } from '@/app/shared/models/form/field/FormFieldSelect';
import { VersioningItem } from '@/app/shared/models/Versioning';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

const UserStore = namespace('User');
const VersioningStore = namespace('Versioning');

@Component({
  components: {
    VersioningBox,
    Loader,
  },
})
export default class VersioningContent extends Vue {
  @Prop({ default: false, type: Boolean }) isCollapsedToolbar: boolean;
  isAllExpanded: boolean = false;
  isLoading: boolean = true;
  resizeObserver: ResizeObserver = null;
  versioningUserFilters: OptionItem[] = [
    {
      value: 'internal',
      text: 'Internal',
    },
    {
      value: 'authority',
      text: 'Authorities',
    },
    {
      value: 'public',
      text: 'Public',
    },
  ];

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  @VersioningStore.State
  versioningUserFilter: string;

  @VersioningStore.Getter
  hasExpandedVersioningItem: boolean;

  @VersioningStore.Getter
  filteredVersioningItems: VersioningItem[];

  @VersioningStore.Action
  getVersioningItems: () => Promise<void>;

  @VersioningStore.Action
  changeUserFilter: (versioningUserFilter: string) => void;

  @VersioningStore.Action
  expandAll: () => void;

  @VersioningStore.Action
  collapseAll: () => void;

  @AsyncComputed()
  async isEditAllowed() {
    return await this.isAllowed(UserPermission.VERSIONING_BAR_EDIT_TITLE);
  }

  @AsyncComputed()
  async isToolbarAllowed() {
    return await this.isAllowed(UserPermission.VERSIONING_BAR_TOOLS);
  }

  @AsyncComputed()
  async isRestoreAllowed() {
    return await this.isAllowed(UserPermission.VERSIONING_BAR_RESTORE_BUTTON);
  }

  get userFilter() {
    return this.versioningUserFilters.findIndex((filter) => filter.value === this.versioningUserFilter);
  }

  set userFilter(userFilterIndex: number) {
    const { value } = this.versioningUserFilters[userFilterIndex];
    this.changeUserFilter(value);
  }

  async mounted() {
    try {
      await this.getVersioningItems();
    } catch (error) {
      console.warn(error);
    } finally {
      this.isLoading = false;
    }
  }

  toggleExpandAll() {
    if (this.hasExpandedVersioningItem) this.collapseAll();
    else this.expandAll();
  }
}
