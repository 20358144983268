import httpClient from '@/app/shared/api/axios.config';
import {
  Organization,
  OrganizationUnit,
  OrganizationUserTableRow,
  OrganizationTableRow,
  OrganizationUserTableDetails,
} from '@/app/shared/models/Organization';
import { FormFieldValue } from '@/app/shared/models/form/field';
import { MessageApiResponse } from '@/app/shared/models/ApiResponse';

const ORGANIZATION_ENDPOINT = '/organization';
const ORGANIZATIONS_ENDPOINT = '/organizations';

export default {
  async getOrganizations(): Promise<OrganizationTableRow[]> {
    return await httpClient.get(`${ORGANIZATIONS_ENDPOINT}`);
  },

  async getOrganization(uuid: string): Promise<Organization> {
    return await httpClient.get(`${ORGANIZATION_ENDPOINT}/${uuid}`);
  },

  async getOrganizationUsers(uuid: string): Promise<OrganizationUserTableRow[]> {
    return await httpClient.get(`${ORGANIZATION_ENDPOINT}/users/${uuid}`);
  },

  async getOrganizationUserDetails(organizationUuid: string, userUuid: string): Promise<OrganizationUserTableDetails> {
    return await httpClient.get(`${ORGANIZATION_ENDPOINT}/details/${organizationUuid}/${userUuid}`);
  },

  async submitOrganizationCreateForm(payload: {
    organization_type: string;
    organization: FormFieldValue<Organization>;
    organization_units: FormFieldValue<OrganizationUnit>;
  }): Promise<MessageApiResponse> {
    return await httpClient.put(`${ORGANIZATION_ENDPOINT}/create`, {
      organization_type: payload.organization_type,
      organization: payload.organization,
      organization_units: payload.organization_units,
    });
  },

  async loadOrganizationSetupForm(
    organizationUuid: string,
  ): Promise<{
    organization: FormFieldValue<Organization>;
    organization_units: FormFieldValue<OrganizationUnit>;
  }> {
    return await httpClient.get(`${ORGANIZATION_ENDPOINT}/setup/${organizationUuid}`);
  },

  async submitOrganizationSetupForm(
    organizationUuid: string,
    payload: {
      organization: FormFieldValue<Organization>;
      organization_units: FormFieldValue<OrganizationUnit>;
    },
  ): Promise<MessageApiResponse> {
    return await httpClient.post(`${ORGANIZATION_ENDPOINT}/setup/${organizationUuid}`, {
      organization: payload.organization,
      organization_units: payload.organization_units,
    });
  },

  async submitInviteNewUserForm(
    organizationUuid: string,
    payload: {
      role: string;
      email: string;
      organization_unit: string[];
    },
  ): Promise<void> {
    return await httpClient.post(`${ORGANIZATION_ENDPOINT}/invite-new-user/${organizationUuid}`, {
      role: payload.role,
      email: payload.email,
      organization_unit: payload.organization_unit,
    });
  },

  async loadSaasDetailsForm(
    organizationUuid: string,
  ): Promise<{
    active: boolean;
    subscription: number;
    studies: number;
    users: number;
    products: number[];
  }> {
    return await httpClient.get(`${ORGANIZATION_ENDPOINT}/saas-details/${organizationUuid}`);
  },

  async submitSaasDetailsForm(
    organizationUuid: string,
    payload: {
      active: boolean;
      subscription: number;
      studies: number;
      users: number;
      products: number[];
    },
  ): Promise<MessageApiResponse> {
    return await httpClient.post(`${ORGANIZATION_ENDPOINT}/saas-details/${organizationUuid}`, {
      active: payload.active,
      subscription: payload.subscription,
      studies: payload.studies,
      users: payload.users,
      products: payload.products,
    });
  },

  async loadAddStudyForm(
    organizationUuid: string,
    userUuid: string,
  ): Promise<{
    studies: string[];
  }> {
    return await httpClient.get(`${ORGANIZATION_ENDPOINT}/studies/${organizationUuid}/${userUuid}`);
  },

  async submitAddStudyForm(
    organizationUuid: string,
    userUuid: string,
    payload: {
      studies: string[];
    },
  ): Promise<MessageApiResponse> {
    return await httpClient.post(`${ORGANIZATION_ENDPOINT}/studies/${organizationUuid}/${userUuid}`, {
      studies: payload.studies,
    });
  },
};
