














import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CalculationsDataTableSignificanceField extends Vue {
  @Prop() color: string;
  @Prop() value: number;

  created() {
    // console.log(this.item);
  }

  get significanceColor() {
    return this.color || 'var(--v-gray-pale)';
  }

  @Emit('view-all')
  viewAll() {
    // just emiting event
  }
}
