<!-- he-tree-vue-pro is commercial software and protected by law. -->
<script>
import * as hp from 'helper-js';

export default {
  props: {
    crossTree: { type: [Boolean, Function], default: false },
    maxLevel: { type: [Number] },
    cloneWhenDrag: { type: [Boolean], default: false },
  },
  // components: {},
  data() {
    return {};
  },
  // computed: {},
  // watch: {},
  methods: {
    _Draggable_unfoldTargetNode(branchEl, store) {
      if (!branchEl) {
        return store;
      }
      const { targetTree } = store;
      // const path = targetTree.getPathByBranchEl(branchEl);
      // const node = targetTree.getNodeByPath(path);
      return new Promise((resolve) => {
        targetTree.$nextTick(() => {
          resolve();
        });
      });
    },
    _internal_hook_filterTargetTree(targetTree, store) {
      const { startTree } = store;
      if (
        !hp.resolveValueOrGettter(startTree.crossTree, [startTree, store]) ||
        !hp.resolveValueOrGettter(targetTree.crossTree, [targetTree, store])
      ) {
        return false;
      }
    },
    _internal_hook_isNodeDroppable({ droppableFinal, _node, path, store }) {
      if (this.maxLevel != null) {
        const { oneMoveStore, dragNode } = store;
        // get movingNodeLevelCount
        if (oneMoveStore._movingNodeLevelCount == null) {
          const walk = (node) => {
            let count = 1;
            if (node.children && node.children.length) {
              const childCountList = node.children.map((child) => walk(child));
              count += Math.max(...childCountList);
            }
            return count;
          };
          oneMoveStore._movingNodeLevelCount = walk(dragNode);
        }
        const movingNodeLevelCount = oneMoveStore._movingNodeLevelCount;
        if (movingNodeLevelCount + path.length > this.maxLevel) {
          console.log('he-tree: The maximum level limit reached.');
          return false;
        }
      }
      return droppableFinal;
    },
  },
  // created() {},
  mounted() {
    window.HE_TREE_VUE_PRO_LICENSE_NUMBER = this.$options.LICENSE_NUMBER;
  },
};
</script>
