


















































import { Component, Mixins, Watch } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import UserAvatar from '@/app/shared/components/UserAvatar.vue';
import FormFieldUpload from '@/app/shared/components/form/field/FormFieldUpload.vue';
import FormFieldLabel from '@/app/shared/components/form/field/mixins/FormFieldLabel.vue';
import FormFieldFocus from '@/app/shared/components/form/field/mixins/FormFieldFocus.vue';
import { UploadResult } from '@/app/shared/models/form/field/FormFieldUpload';

@Component({
  components: {
    UserAvatar,
  },
})
export default class FormFieldAvatar extends Mixins(
  FormFieldUpload,
  FormFieldLabel,
  FormFieldFocus,
  BaseControlSkeleton,
) {
  value: string;
  control: {
    avatarInitials: string;
  };
  uploadResults: UploadResult[] = [];
  updateValue: (value: string) => void;

  @Watch('value', { deep: true })
  onValueChanged(value: string) {
    const [firstUploadResult] = this.uploadResults;
    if (value && (!firstUploadResult || value !== firstUploadResult.uploadPreview))
      this.uploadResults = [new UploadResult(null, 'inline', value)];
  }

  @Watch('uploadResults', { deep: true })
  onUploadResultsChanged(value: UploadResult[]) {
    if (value && value.length) {
      const [firstValue] = value;
      this.updateValue(firstValue && firstValue.uploadPreview);
      return;
    }

    this.updateValue('');
  }

  get hasAvatarInitials() {
    if (this.control.avatarInitials) {
      const valueContainer = JSON.parse(JSON.stringify(this.$attrs['value-container'])); // NB: why?!?
      if (valueContainer.hasOwnProperty(this.control.avatarInitials))
        return this.$attrs['value-container'][this.control.avatarInitials as any];
    }

    return false;
  }

  created() {
    if (this.value) {
      this.uploadResults = [new UploadResult(null, 'inline', this.value)];
    }
  }

  focus() {
    if (this.$refs.browseButton) (this.$refs.browseButton as Vue & { $el: { focus: () => void } }).$el.focus();
  }
}
