




































































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import { isEqual, lowerCase } from 'lodash';
import { titleCase } from 'title-case';
import FormFieldLabel from '@/app/shared/components/form/field/mixins/FormFieldLabel.vue';
import FormFieldFocus from '@/app/shared/components/form/field/mixins/FormFieldFocus.vue';
import api from '@/app/shared/api';
import { CheckTree } from '@/app/shared/models/CheckTree';

// Silence Vue warnings about wrong value for "selectionType" prop in v-treeview.
//   We trick the component in giving us expected behavior when parent nodes are selected along with its children,
//   by supplying an "out-of-bounds" value.
//   More info here: https://github.com/vuetifyjs/vuetify/issues/9088
Vue.config.warnHandler = (msg, vm, trace) => {
  if (msg !== 'Invalid prop: custom validator check failed for prop "selectionType".') {
    const hasConsole = typeof console !== 'undefined';
    if (hasConsole && !Vue.config.silent) {
      console.error(`[Vue warn]: ${msg}${trace}`);
    }
  }
};

@Component
export default class FormFieldCheckTree extends Mixins(Vue, FormFieldLabel, FormFieldFocus, BaseControlSkeleton) {
  @Prop() testCheckTree: CheckTree;
  control!: {
    isDisabled: boolean;
    disabledOnValue: string;
  };
  value: string[];
  open: string[] = [];
  checkTree: CheckTree = null;
  items: any[] = [];
  checked: string[] = [];
  stopDefaultValueAssign = true;
  updateValue: (value: string[]) => void;

  get allItemIds() {
    return this.flattenItems(this.items);
  }

  get isAllSelected() {
    if (this.value === undefined || this.value === null || !this.items.length) return false;
    return isEqual(this.value.concat().sort(), this.allItemIds.concat().sort());
  }

  get itemIdsWithChildren() {
    return this.flattenItems(this.items, true);
  }

  get isExpanded() {
    if (!this.items.length) return false;
    return isEqual(this.open.concat().sort(), this.itemIdsWithChildren.concat().sort());
  }

  get isDisabled() {
    if (this.control.isDisabled) return true;
    if (this.control.disabledOnValue) {
      const valueContainer = JSON.parse(JSON.stringify(this.$attrs['value-container'])); // NB: why?!?
      return (
        valueContainer &&
        valueContainer.hasOwnProperty(this.control.disabledOnValue) &&
        Boolean(valueContainer[this.control.disabledOnValue as any])
      );
    }
    return false;
  }

  created() {
    if (this.value && this.value.length) console.warn('This field does not support pre-defined values!');
  }

  async mounted() {
    if (this.testCheckTree) {
      this.checkTree = this.testCheckTree;
      await this.initItems();
      return;
    }

    await this.$nextTick(async () => {
      await this.initCheckTree();
    });
  }

  async initCheckTree() {
    const valueContainer = (this.$attrs['value-container'] as unknown) as Record<string, any>;
    const checkTreeUuid = valueContainer && valueContainer.dtct_uuid; // NB: Hard-coded value of the expected key!

    if (!checkTreeUuid) {
      console.warn('This field requires `dtct_uuid` to be present in form values!', valueContainer);
      return;
    }

    try {
      this.checkTree = await api.checktree.getByUuid(checkTreeUuid);
      await this.initItems();
    } catch (error) {
      console.warn(error);
    }
  }

  async initItems() {
    if (!this.checkTree || !this.checkTree.children) return;
    this.checked = [];
    this.items = this.transformCheckTreeItems(this.checkTree.children);
    await this.updateValue(this.checked);
  }

  toggleExpandAll() {
    if (this.$refs.treeView)
      (this.$refs.treeView as Vue & { updateAll(val: boolean): void }).updateAll(!this.isExpanded);
  }

  transformCheckTreeItems(items: any[], prefix: string = '') {
    const result: any[] = [];

    let i = 1;

    items.forEach((item) => {
      let position = i.toString();
      if (prefix) position = `${prefix}.${i}`;

      const resultItem = {
        id: item.uuid,
        position,
        name: item.level === 1 ? titleCase(lowerCase(item.title)) : item.title, // NB: Why should we do this here‽
        level: item.level,
        helpText: item.comment,
        children: [] as any[],
      };

      if (Array.isArray(item.children) && item.children.length) {
        resultItem.children = this.transformCheckTreeItems(item.children, position);
      }

      result.push(resultItem);

      // Pre-select items that contain a specific flag.
      if (item.checked) this.checked.push(item.uuid);

      i++;
    });

    return result;
  }

  flattenItems(items: any[], onlyItemsWithChildren: boolean = false): string[] {
    const flat: string[] = [];

    items.forEach((item) => {
      if (!onlyItemsWithChildren) flat.push(item.id);
      if (Array.isArray(item.children) && item.children.length) {
        if (onlyItemsWithChildren) flat.push(item.id);
        flat.push(...this.flattenItems(item.children, onlyItemsWithChildren));
      }
    });

    return flat;
  }

  toggleSelectAll() {
    if (this.isAllSelected) this.updateValue([]);
    else this.updateValue(this.allItemIds);
  }

  focus() {
    if (this.$refs.expandButton) ((this.$refs.expandButton as Vue).$el as HTMLButtonElement).focus();
  }
}
