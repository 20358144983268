










import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const WorkflowStoreModule = namespace('Workflow');

@Component({
  components: {},
})
export default class WorkflowFileUpload extends Vue {
  currentFile: object = null;

  @WorkflowStoreModule.Mutation
  setCurrentFile!: (data: any) => void;

  selectFile(file: any) {
    file = file ? file : [];
    this.setCurrentFile(file);
  }
}
