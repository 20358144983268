








































import { UnitScore } from '@/app/shared/models/calculations/UnitScore';
import { UpdateScoreRangePayload } from '@/app/shared/store/modules/calculations.store-module';
import { Component, Vue, Prop } from 'vue-property-decorator';
import _debounce from 'lodash.debounce';
import { namespace } from 'vuex-class';
import LoaderService from '@/app/shared/utils/loader.service';
const CalculationsStore = namespace('Calculations');

@Component({ components: {} })
export default class ScoreRange extends Vue {
  @Prop() scoreRange: UnitScore;
  @Prop() unitUuid: string;
  @Prop() index: number;

  @CalculationsStore.Action
  addScoreRange: (afterScoreRangeIndex: number) => Promise<any>;

  @CalculationsStore.Action
  updateScoreRange: (payload: UpdateScoreRangePayload) => Promise<any>;

  @CalculationsStore.Action
  deleteScoreRange: (payload: { scoreRangeIndex: number; unitUuid: string }) => Promise<any>;

  addItem() {
    this.addScoreRange(this.index);
  }

  async deleteItem() {
    try {
      LoaderService.disableHttpLoader();
      await this.deleteScoreRange({ unitUuid: this.unitUuid, scoreRangeIndex: this.index });
    } catch (error) {
      console.error(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  onInput = _debounce(async (value: string | number, propertyName: string) => {
    try {
      LoaderService.disableHttpLoader();
      if (propertyName === 'score') {
        value = value ? Number(value) : 0;
      }
      await this.updateScoreRange({
        unitUuid: this.unitUuid,
        scoreRangeUuid: this.scoreRange.uuid,
        scoreRangeIndex: this.index,
        propertyName: propertyName,
        propertyValue: value,
      });
    } catch (error) {
      console.error(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }, 500);
}
