import { render, staticRenderFns } from "./StackedBarPlot.vue?vue&type=template&id=749d6075&scoped=true&"
import script from "./StackedBarPlot.vue?vue&type=script&lang=ts&"
export * from "./StackedBarPlot.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "749d6075",
  null
  
)

export default component.exports