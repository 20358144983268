import FormConfigOrganizationUnit from '@/app/shared/components/form/config/FormConfigOrganizationUnit.vue';
import FormFieldOrganizationUnit from '@/app/shared/components/form/field/FormFieldOrganizationUnit.vue';
import { OrganizationUnit } from '@/app/shared/models/form/field/FormFieldOrganizationUnit';
import { FormFieldValue, FormFieldValueModel } from '@/app/shared/models/form/field';

export default {
  name: 'Organization Unit Control',
  description: 'Complex input for organization unit info',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldOrganizationUnit,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigOrganizationUnit,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    helpText: '',
    isDisabled: false,
    isReadonly: false,

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {FormFieldValue<OrganizationUnit>}
   */
  rendererDefaultData(): FormFieldValue<OrganizationUnit> {
    return new FormFieldValue([new OrganizationUnit()], FormFieldValueModel.ORGANIZATION_UNIT);
  },
};
