













































import { Component, Emit, Mixins, Vue } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import FormFieldLabel from '@/app/shared/components/form/field/mixins/FormFieldLabel.vue';
import FormFieldFocus from '@/app/shared/components/form/field/mixins/FormFieldFocus.vue';
import FormFieldSubmit from '@/app/shared/components/form/field/mixins/FormFieldSubmit.vue';

@Component({})
export default class FormFieldText extends Mixins(
  Vue,
  FormFieldLabel,
  FormFieldFocus,
  FormFieldSubmit,
  BaseControlSkeleton,
) {
  @Emit()
  click(_event: MouseEvent) {
    //
  }
}
