import Vue from 'vue';
import BaseBadge from './BaseBadge.vue';
import BaseButton from './BaseButton.vue';
import BaseCallout from './BaseCallout.vue';
import BaseCheckbox from './BaseCheckbox.vue';
import BaseDropdown from './BaseDropdown.vue';
import BaseFilter from './BaseFilter.vue';
import BaseInput from './BaseInput.vue';
import BaseRadioButton from './BaseRadioButton.vue';
import BaseRadioButtonGroup from './BaseRadioButtonGroup.vue';
import BaseSelect from './BaseSelect.vue';
import BaseSwitch from './BaseSwitch.vue';
import BaseTextArea from './BaseTextArea.vue';
import BaseTextField from './BaseTextField.vue';
import BaseTextWithLinks from './BaseTextWithLinks.vue';
import BaseTooltip from './BaseTooltip.vue';

export default () => {
  Vue.component('BaseBadge', BaseBadge);
  Vue.component('BaseButton', BaseButton);
  Vue.component('BaseCallout', BaseCallout);
  Vue.component('BaseCheckbox', BaseCheckbox);
  Vue.component('BaseDropdown', BaseDropdown);
  Vue.component('BaseFilter', BaseFilter);
  Vue.component('BaseInput', BaseInput);
  Vue.component('BaseRadioButton', BaseRadioButton);
  Vue.component('BaseRadioButtonGroup', BaseRadioButtonGroup);
  Vue.component('BaseSelect', BaseSelect);
  Vue.component('BaseSwitch', BaseSwitch);
  Vue.component('BaseTextArea', BaseTextArea);
  Vue.component('BaseTextField', BaseTextField);
  Vue.component('BaseTextWithLinks', BaseTextWithLinks);
  Vue.component('BaseTooltip', BaseTooltip);
};
