import FormConfigAssessmentTeam from '@/app/shared/components/form/config/FormConfigAssessmentTeam.vue';
import FormFieldAssessmentTeam from '@/app/shared/components/form/field/FormFieldAssessmentTeam.vue';
import { AssessmentTeam } from '@/app/shared/models/form/field/FormFieldAssessmentTeam';
import { FormFieldValue, FormFieldValueModel } from '@/app/shared/models/form/field';

export default {
  name: 'Assessment Control',
  description: 'Complex input for assessment team info',

  /**
   * Control View Mapping
   */
  fieldComponent: FormFieldAssessmentTeam,

  /**
   * Control Configuration View Mapping
   */
  configComponent: FormConfigAssessmentTeam,

  /**
   * Control-Data Extend
   * Your specific data for your custom control
   */
  configData: {
    isDisabled: false,
    isReadonly: false,

    // Override common default value.
    isShowLabel: false,
  },

  /**
   * Default data of the field in Renderer
   * @returns {array}
   */
  rendererDefaultData(): FormFieldValue<AssessmentTeam> {
    return new FormFieldValue([new AssessmentTeam()], FormFieldValueModel.ASSESSMENT_TEAM);
  },
};
