var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"dataTable",staticClass:"dashboard-data-table",attrs:{"headers":_vm.tableHeaders,"items":_vm.data,"expanded":_vm.expanded,"height":_vm.height,"item-class":_vm.itemClass,"item-key":"uuid","hide-default-footer":"","disable-pagination":"","disable-sort":"","fixed-header":""},scopedSlots:_vm._u([_vm._l((_vm.columns),function(col){return {key:_vm.headerSlotName(col),fn:function(ref){
var header = ref.header;
return [_c('div',{key:col.value,staticClass:"d-flex"},[_c('span',[_vm._v(" "+_vm._s(header.text)+" ")]),(col.resizable)?_c('v-icon',{staticClass:"dashboard-data-table__resize-grabber"},[_vm._v("$resize-grabber")]):_vm._e()],1)]}}}),_vm._l((_vm.columns),function(col){return {key:_vm.itemSlotName(col),fn:function(ref){
var item = ref.item;
return [_c('DashboardDataTableField',{key:col.value,attrs:{"column":col,"item":item,"auto-expand":_vm.autoExpand,"status-config":_vm.statusConfig,"action-config":_vm.actionConfig,"is-edit-visible":_vm.isEditVisible,"is-item-editable":_vm.isItemEditable,"is-item-expandable":_vm.isItemExpandable},on:{"action-click":_vm.onActionClick,"edit":_vm.onEdit,"copy":_vm.onCopy,"expand":_vm.expand},scopedSlots:_vm._u([(_vm.$scopedSlots['prepend-title'])?{key:"prepend-title",fn:function(ref){
var item = ref.item;
var title = ref.title;
return [_vm._t("prepend-title",null,{"item":item,"title":title})]}}:null],null,true)})]}}}),{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('DashboardDataTableExpandedRow',{attrs:{"item":item,"details":_vm.details,"extra-pane":_vm.extraPane},on:{"action-click":_vm.onActionClick}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }