




























































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { delay, escapeRegExp } from 'lodash';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { namespace } from 'vuex-class';
import PdfOutputReportContent from './PdfOutputReportContent.vue';
import VersioningBadge from '@/app/shared/components/versioning/VersioningBadge.vue';
import VersioningStar from '@/app/shared/components/versioning/VersioningStar.vue';
import Sidebar from '@/app/shared/components/Sidebar.vue';
import api from '@/app/shared/api';
import LoaderService from '@/app/shared/utils/loader.service';
import { Report, TemplateReports, VersionReport, TemplateVersionReports } from '@/app/shared/models/Report';
import { StudyStage, StudyTemplate } from '@/app/shared/models/Study';
import { OptionItem } from '@/app/shared/models/form/field/FormFieldSelect';
import DialogService from '@/app/shared/utils/dialog.service';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const UserStore = namespace('User');

@Component({
  components: {
    PdfOutputReportContent,
    Sidebar,
    VersioningBadge,
    VersioningStar,
  },
})
export default class PdfOutputSidebar extends Vue {
  @Prop() studyUuid: string;
  @Prop() stage: StudyStage;
  @Prop() areVersioningToolsAllowed: boolean;
  @Prop() isDeleteButtonAllowed: boolean;
  public defaultSidebarWidth = 380;
  public resizeToggle = false;
  reportUserFilterTab: number = 0;
  reportUserFilters: OptionItem[] = [
    {
      value: 'internal',
      text: 'Internal',
    },
    {
      value: 'authority',
      text: 'Authorities',
    },
    {
      value: 'public',
      text: 'Public',
    },
  ];
  templateReports: TemplateReports = null;
  isLoading: boolean = true;
  expandedTemplates: number[] = [];
  expandedVersions: { [key: string]: number[] } = {
    'full-report': [],
    'summary-report': [],
    impacts: [],
  };
  expandedReports: { [key: string]: { [key: number]: number[] } } = {
    'full-report': {},
    'summary-report': {},
    impacts: {},
  };
  filterTags: string[] = [];
  refreshReportsIntervalId: number = null;
  displayFormat: string = 'D MMM, YYYY';

  @UserStore.Getter
  skipConfirmationDialog: () => boolean;

  @UserStore.Mutation
  setSkipConfirmationDialog: (value: boolean) => void;

  @Watch('filterTags')
  onFilterTagsChange() {
    if (this.areAllExpanded) return;
    this.toggleAllExpanded();
  }

  get isDevelopmentMode() {
    return process.env.NODE_ENV === 'development';
  }

  get reportUserFilter() {
    return this.reportUserFilters[this.reportUserFilterTab].value;
  }

  get areAllTemplatesExpanded() {
    return (
      this.expandedTemplates.length ===
      (this.templateVersionReports && Object.keys(this.templateVersionReports).map((_template, index) => index).length)
    );
  }

  get areAllVersionsExpanded() {
    return Object.keys(this.expandedVersions).every(
      (template) =>
        this.expandedVersions[template].length ===
        (this.templateVersionReports &&
          this.templateVersionReports[template as StudyTemplate].map((_version, index) => index).length),
    );
  }

  get areAllExpanded() {
    return this.areAllTemplatesExpanded && this.areAllVersionsExpanded;
  }

  get filteredTemplateReports() {
    return (
      this.templateReports &&
      (Object.fromEntries(
        Object.entries(this.templateReports).map(([template, reports]) => [
          template,
          reports.filter((report) => {
            switch (this.reportUserFilter) {
              case 'authority':
                return report.is_authority;
              case 'public':
                return report.is_public;
              case 'internal':
              default:
                return true;
            }
          }),
        ]),
      ) as TemplateReports)
    );
  }

  get templateVersionReports() {
    return (
      this.filteredTemplateReports &&
      (Object.fromEntries(
        Object.entries(this.filteredTemplateReports).map(([template, reports]) => [
          template,
          Object.values(
            reports.reduce(
              (versionReports, report) => ({
                ...versionReports,
                [report.version]: {
                  number: report.version,
                  name: report.version_name || `Study version No. ${report.version}`,
                  is_active: Boolean(report.version_is_active),
                  is_authority: Boolean(report.version_is_authority),
                  is_public: Boolean(report.version_is_public),
                  is_important: Boolean(report.version_is_important),
                  reports: [
                    ...((versionReports[report.version] && versionReports[report.version].reports) || []),
                    report,
                  ],
                },
              }),
              {} as {
                [key in number]: VersionReport;
              },
            ),
          ),
        ]),
      ) as TemplateVersionReports)
    );
  }

  get matchedTemplateVersionReports() {
    if (!this.filterTags.length) return this.templateVersionReports;
    return Object.fromEntries(
      Object.entries(this.templateVersionReports).map(([template, versionReports]) => [
        template,
        versionReports
          .filter((versionReport) =>
            this.filterTags.some(
              (tag) =>
                new RegExp(escapeRegExp(this.removeAccents(tag.trim())), 'i').test(
                  this.removeAccents(versionReport.name),
                ) ||
                versionReport.reports.some((report) =>
                  new RegExp(escapeRegExp(this.removeAccents(tag.trim())), 'i').test(this.removeAccents(report.name)),
                ),
            ),
          )
          .map((versionReport) => {
            const filteredReports = versionReport.reports.filter((report) =>
              this.filterTags.some((tag) =>
                new RegExp(escapeRegExp(this.removeAccents(tag.trim())), 'i').test(this.removeAccents(report.name)),
              ),
            );
            if (filteredReports.length) versionReport.reports = filteredReports;
            return versionReport;
          }),
      ]),
    ) as TemplateVersionReports;
  }

  async created() {
    await this.getReports();
    this.refreshReportsIntervalId = setInterval(this.refreshReports, 5000);
  }

  beforeDestroy() {
    if (this.refreshReportsIntervalId) {
      clearInterval(this.refreshReportsIntervalId);
      this.refreshReportsIntervalId = null;
    }
  }

  async getReports() {
    try {
      this.isLoading = true;
      LoaderService.disableHttpLoader();
      this.templateReports = await api.reports.getReports(this.studyUuid, this.stage);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
      LoaderService.hideLoader();
      delay(() => {
        this.isLoading = false;
      }, 1000);
    }
  }

  async refreshReports() {
    try {
      this.isLoading = true;
      LoaderService.disableHttpLoader();
      const templateReports = await api.reports.getReports(this.studyUuid, this.stage);
      Object.keys(templateReports).forEach((template) => {
        const reports = templateReports[template as StudyTemplate];
        reports.forEach((report) => {
          const originalReport = this.templateReports[template as StudyTemplate].find((r) => r.uuid === report.uuid);

          // If this is a new report, just add it.
          if (!originalReport) {
            this.templateReports[template as StudyTemplate].push(report);
            return;
          }

          // Otherwise, update its status if it differs.
          if (originalReport.status !== report.status) {
            this.$set(originalReport, 'status', report.status);
            if (report.status === 'completed') this.$set(originalReport, 'is_generated', true);
          }
        });
      });
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
      LoaderService.hideLoader();
      delay(() => {
        this.isLoading = false;
      }, 1000);
    }
  }

  public async refresh(template: StudyTemplate, version: number) {
    if (this.refreshReportsIntervalId) {
      clearInterval(this.refreshReportsIntervalId);
      this.refreshReportsIntervalId = null;
    }
    await this.refreshReports();
    this.expandTemplate(template);
    this.expandVersion(template, version);
    this.refreshReportsIntervalId = setInterval(this.refreshReports, 5000);
  }

  removeAccents(target: string) {
    return target.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  public emitResizeEvent() {
    this.resizeToggle = !this.resizeToggle;
  }

  collapseAllTemplates() {
    this.expandedTemplates = [];
  }

  expandAllTemplates() {
    this.expandedTemplates =
      this.templateVersionReports && Object.keys(this.templateVersionReports).map((_template, index) => index);
  }

  collapseAllVersions() {
    this.expandedVersions = {
      'full-report': [],
      'summary-report': [],
      impacts: [],
    };
  }

  expandAllVersions() {
    this.expandedVersions =
      this.templateVersionReports &&
      Object.fromEntries(
        Object.entries(this.templateVersionReports).map(([template, versionReports]) => [
          template,
          versionReports.map((_version, index) => index),
        ]),
      );
  }

  collapseAllReports() {
    this.expandedReports = {
      'full-report': {},
      'summary-report': {},
      impacts: {},
    };
  }

  toggleAllExpanded() {
    if (this.areAllExpanded) {
      this.collapseAllTemplates();
      this.collapseAllVersions();
    } else {
      this.expandAllTemplates();
      this.expandAllVersions();
    }

    this.collapseAllReports();
  }

  expandTemplate(template: StudyTemplate) {
    const templateIndex = Object.keys(this.templateVersionReports).findIndex((t) => t === template);
    if (!this.expandedTemplates.includes(templateIndex)) this.expandedTemplates.push(templateIndex);
  }

  expandVersion(template: StudyTemplate, version: number) {
    const versionIndex = this.templateVersionReports[template].findIndex((v) => v.number === version);
    if (!this.expandedVersions[template].includes(versionIndex)) this.expandedVersions[template].push(versionIndex);
  }

  expandReport(template: StudyTemplate, version: number, report: Report) {
    const versionReport = this.templateVersionReports[template].find((v) => v.number === version);
    const reportIndex = versionReport.reports.findIndex((r) => r.uuid === report.uuid);
    if (!this.expandedReports[template][version]) this.$set(this.expandedReports[template], version, []);
    if (!this.expandedReports[template][version].includes(reportIndex))
      this.expandedReports[template][version].push(reportIndex);
  }

  getTemplateIcon(template: string | number) {
    switch (template) {
      case 'full-report':
        return 'study-type';
      case 'summary-report':
        return 'summary-report';
      case 'impacts':
        return 'impacts';
    }
  }

  getTemplateTitle(template: string | number) {
    switch (template) {
      case 'full-report':
        return 'Report';
      case 'summary-report':
        return 'Summary';
      case 'impacts':
        return 'Impacts';
    }
  }

  getReportCreateDate(report: Report) {
    return dayjs
      .utc(report.created)
      .local()
      .format(this.displayFormat);
  }

  async downloadPdf(template: StudyTemplate, version: number, report: Report) {
    if (report.status !== 'completed') return;

    this.expandReport(template, version, report);

    try {
      this.$set(report, 'is_downloaded', false);
      this.$set(report, 'is_downloading', true);
      this.$set(report, 'download_progress', 0);

      LoaderService.disableHttpLoader();

      const res = await api.reports.downloadPdf(report.uuid, (progressEvent: ProgressEvent) => {
        this.$set(report, 'download_progress', Math.floor((progressEvent.loaded / progressEvent.total) * 100));
      });

      LoaderService.enableHttpLoader();

      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${report.name}.pdf`);
      link.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
      this.$set(report, 'is_downloading', false);
      this.$set(report, 'is_downloaded', true);
    }
  }

  async updateReportName(report: Report, name: string) {
    this.$set(report, 'name', name);
    await api.reports.updatePDFReportName(report.uuid, { name: report.name });
  }

  async updateReportComment(report: Report, comment: string) {
    this.$set(report, 'comment', comment);
    await api.reports.updatePDFReportComment(report.uuid, { comment: report.comment });
  }

  deleteReport(report: Report) {
    const deletePdf = async (uuid: string, template: StudyTemplate) => {
      try {
        await api.reports.deletePdf(uuid);
        this.templateReports[template].splice(
          this.templateReports[template].findIndex((r) => r.uuid === report.uuid),
          1,
        );
      } catch (error) {
        console.warn(error);
      }
    };

    if (!this.skipConfirmationDialog) {
      const confirmCallback = async (context: { checkboxValue: boolean }) => {
        this.setSkipConfirmationDialog(context.checkboxValue);
        deletePdf(report.uuid, report.template);
      };

      DialogService.presentDialog(
        'Delete this item?',
        'You will not be able to recover it.',
        'Yes',
        'Cancel',
        confirmCallback,
        () => {
          //
        },
        'Do not show me this again',
      );
      return;
    }

    deletePdf(report.uuid, report.template);
  }

  async toggleReportImportant(report: Report) {
    this.$set(report, 'is_important', !report.is_important);
    await api.reports.updatePDFReportIsImportant(report.uuid, { is_important: report.is_important });
  }

  async toggleReportAuthority(report: Report) {
    this.$set(report, 'is_authority', !report.is_authority);
    await api.reports.updatePDFReportIsAuthority(report.uuid, { is_authority: report.is_authority });
  }

  async toggleReportPublic(report: Report) {
    this.$set(report, 'is_public', !report.is_public);
    await api.reports.updatePDFReportIsPublic(report.uuid, { is_public: report.is_public });
  }
}
