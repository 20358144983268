import { initializeWebSocketConnection } from './app/shared/ws/websocket.service';
import registerBaseComponents from '@/app/shared/components/base-components';
import Vue from 'vue';
import App from './app.vue';
import store from './app/shared/store';
import vuetify from './plugins/vuetify';
import router from './router/router';
import './plugins/vue-browser-detect';
import './plugins/vue-form-builder';
import './plugins/vue-safe-html';
import './plugins/vue-async-computed';

Vue.config.productionTip = false;

// initializes websocket
initializeWebSocketConnection();

// register base components
registerBaseComponents();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
