import { ws } from './../../ws/index';
import { MatrixSelectType } from '@/app/shared/enums/matrix-select-type.enum';
import { MatrixType } from '@/app/shared/enums/matrix-type.enum';
import { WSMessageCommand } from '@/app/shared/enums/ws-message-command.enum';
import MatrixRelationField from '@/app/shared/models/MatrixRelationField';
import MatrixRelationMatrix from '@/app/shared/models/MatrixRelationMatrix';
import { WSMessageResponse } from '@/app/shared/models/WSMessageResponse';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import Vue from 'vue';

@Module({ namespaced: true })
class MatrixRelationsModule extends VuexModule {
  matrixData: MatrixRelationMatrix;
  flattenedMatrixRows: Array<MatrixRelationField> = [];
  flattenedMatrixColumns: Array<MatrixRelationField> = [];

  get matrixRelationsData(): MatrixRelationMatrix {
    return this.matrixData;
  }
  @Mutation
  private setMatrixRelationsData(data: MatrixRelationMatrix): void {
    if (!this.matrixData) {
      this.matrixData = data;
    } else {
      Vue.set(this.matrixData, 'id', data.id);
      Vue.set(this.matrixData, 'uuid', data.uuid);
      Vue.set(this.matrixData, 'name', data.name);
      Vue.set(this.matrixData, 'slug', data.slug);
      Vue.set(this.matrixData, 'items', data.items);
      Vue.set(this.matrixData, 'binding_word', data.binding_word);
      Vue.set(this.matrixData, 'axis_xs', data.axis_xs);
      Vue.set(this.matrixData, 'axis_ys', data.axis_ys);
      Vue.set(this.matrixData, 'user', data.user);
      Vue.set(this.matrixData, 'study', data.study);
      Vue.set(this.matrixData, 'matrix_type', data.matrix_type);
      Vue.set(this.matrixData, 'matrix', data.matrix);
      Vue.set(this.matrixData, 'axis_x', data.axis_x);
      Vue.set(this.matrixData, 'axis_y', data.axis_y);
    }
  }

  get flattenedRows(): Array<MatrixRelationField> {
    return this.flattenedMatrixRows;
  }
  @Mutation
  public setFlattenedMatrixRows(data: Array<MatrixRelationField>): void {
    this.flattenedMatrixRows = data;
  }
  get flattenedColumns(): Array<MatrixRelationField> {
    return this.flattenedMatrixColumns;
  }
  @Mutation
  public setFlattenedMatrixColumns(data: Array<MatrixRelationField>): void {
    this.flattenedMatrixColumns = data;
  }

  @Action({ rawError: true })
  private updateMatrixRelationsData(data: MatrixRelationMatrix): void {
    this.context.commit('setMatrixRelationsData', data);
  }

  @Action({ rawError: true })
  public flattenArrayElements(array: Array<MatrixRelationField>): Array<MatrixRelationField> {
    const flattendedArray: Array<MatrixRelationField> = [];
    let id = 0;
    const flattenRecursively = (array: Array<MatrixRelationField>, level: number, parentId: number = -1) => {
      for (const row of array) {
        row.level = level;
        row.id = id;
        if (parentId != -1) {
          row.parentId = parentId;
        }
        id++;
        row.isParent = row.children.length > 0;
        row.expanded = true;
        flattendedArray.push(row);
        if (row.children.length > 0) {
          flattenRecursively(row.children, level + 1, row.id);
        }
      }
    };

    flattenRecursively(array, 0);
    return flattendedArray;
  }

  @Action({ rawError: true })
  public initEmptyMatrix(): void {
    const matrixData = new MatrixRelationMatrix();
    matrixData.matrix = [];
    this.context.dispatch('updateMatrixRelationsData', matrixData);
  }
  @Action({ rawError: true })
  public fillMatrix(data: { matrixData: MatrixRelationMatrix; rowNumber: number; columnNumber: number }): void {
    for (let i = 0; i < data.rowNumber; i++) {
      data.matrixData.matrix.push([]);
      for (let j = 0; j < data.columnNumber; j++) {
        data.matrixData.matrix[i].push(false);
      }
    }
    this.context.dispatch('updateMatrixRelationsData', data.matrixData);
  }
  @Action
  public handleWebsocketMessage(response: WSMessageResponse) {
    // TODO: Handle parent flags
    const findItemRecursively = (array: Array<MatrixRelationField>, uuid: string): MatrixRelationField => {
      for (const item of array) {
        if (item.uuid == uuid) {
          return item;
        }
        if (item.children.length > 0) {
          const result = findItemRecursively(item.children, uuid);
          if (result) {
            return result;
          }
        }
      }
    };
    const rowItem = findItemRecursively(this.matrixData.axis_y.children, response.params.axis_y_item);
    const columnItem = findItemRecursively(this.matrixData.axis_x.children, response.params.axis_x_item);
    let itemValue = response.command == WSMessageCommand.CONNECT ? true : false;

    // check if field is already checked
    if (this.matrixData.matrix[rowItem.id][columnItem.id] != itemValue) {
      // console.log('nije isto');
      // item click recieves reverse itemValue
      itemValue = !itemValue;

      this.context.commit('itemClick', {
        value: itemValue,
        i: rowItem.id,
        j: columnItem.id,
        rowItem: rowItem,
        columnItem: columnItem,
        matrixUuid: response.params.uuid,
        isFromWebsocket: true,
      });
    } else {
      // console.log('isto');
    }
  }

  @Mutation
  public setCheckedItemsFromList() {
    const findItemRecursively = (array: Array<MatrixRelationField>, uuid: string): MatrixRelationField => {
      for (const item of array) {
        if (item.uuid == uuid) {
          return item;
        }
        if (item.children.length > 0) {
          const result = findItemRecursively(item.children, uuid);
          if (result) {
            return result;
          }
        }
      }
    };
    for (const item of this.matrixData.items) {
      const rowItem = findItemRecursively(this.matrixData.axis_y.children, item.y_item.uuid);
      const columnItem = findItemRecursively(this.matrixData.axis_x.children, item.x_item.uuid);
      // if wrong uuid is got from backend
      if (!rowItem || !columnItem) {
        // console.log('pogresan uuid', `x: ${item.x_item.uuid} y: ${item.y_item.uuid}`);
        continue;
      }

      this.matrixData.matrix[rowItem.id][columnItem.id] = item.checked;
    }
  }

  @Mutation
  public itemClick(data: {
    value: boolean;
    i: number;
    j: number;
    rowItem: MatrixRelationField;
    columnItem: MatrixRelationField;
    matrixUuid: string;
    isFromWebsocket: boolean;
  }): void {
    // for second type if click on column parent which behaves like a radio button ignore
    // console.log(data.columnItem);

    if (
      this.matrixData.matrix_type == MatrixType.SECOND &&
      data.columnItem.isParent &&
      data.columnItem.level == 0 &&
      data.columnItem.behave_as == MatrixSelectType.RADIO
    ) {
      return;
    }

    const setValueRecursively = (children: Array<MatrixRelationField>, isRow: boolean) => {
      for (const child of children) {
        if (isRow) {
          this.matrixData.matrix[child.id][data.j] = data.value;
        } else {
          this.matrixData.matrix[data.i][child.id] = data.value;
        }
        if (child.children.length > 0) {
          setValueRecursively(child.children, isRow);
        }
      }
    };
    const areAllChildItemsSelect = (children: Array<MatrixRelationField>, isRow: boolean) => {
      for (const child of children) {
        if (isRow) {
          if (this.matrixData.matrix[child.id][data.j] == false) {
            return false;
          }
        } else {
          if (this.matrixData.matrix[data.i][child.id] == false) {
            return false;
          }
        }
        if (child.children.length > 0) {
          if (areAllChildItemsSelect(child.children, isRow) == false) {
            return false;
          }
        }
      }
      return true;
    };
    // old value is passed so we need to turn in around
    data.value = !data.value;

    if (this.matrixData.matrix_type == MatrixType.SECOND && data.value) {
      // check to see if field is in a radio button or checkbox group
      const parent = this.matrixData.axis_x.children.find((c) => c.id == data.columnItem.parentId);
      if (parent && parent.behave_as == MatrixSelectType.RADIO) {
        // check if any of the children is checked
        for (const child of parent.children) {
          if (child.id != data.columnItem.id && this.matrixData.matrix[data.i][child.id]) {
            this.matrixData.matrix[data.i][child.id] = false;

            // send message via websocket
            if (!data.isFromWebsocket) {
              ws.matrix.updateMatrixItem(
                data.matrixUuid,
                data.rowItem.uuid,
                child.uuid,
                false,
                false,
                `/matrix/${data.matrixUuid}`,
                false,
              );
            }
          }
        }
      }
    }
    if (!(data.rowItem.isParent && data.rowItem.level == 0 && data.columnItem.isParent && data.columnItem.level == 0)) {
      // only set if both item aren't first level parents
      this.matrixData.matrix[data.i][data.j] = data.value;
    } else {
      // see if we need to deselect all i.e. if all children are selected
      const allRowChildredSelected = areAllChildItemsSelect(data.rowItem.children, true);
      const allColumnChildredSelected = areAllChildItemsSelect(data.columnItem.children, false);

      if (allRowChildredSelected && allColumnChildredSelected && !data.isFromWebsocket) {
        data.value = false;
      }
    }
    if (data.rowItem.isParent && data.rowItem.level == 0) {
      // select all elements in a row recursively
      setValueRecursively(data.rowItem.children, true);
    }
    if (data.columnItem.isParent && data.columnItem.level == 0) {
      // select all elements in a column recursively
      setValueRecursively(data.columnItem.children, false);
    }
    // send message via websocket
    if (!data.isFromWebsocket) {
      let y_children = false;
      if (data.rowItem.isParent && data.rowItem.level == 0) {
        y_children = true;
      }
      let x_children = false;
      if (data.columnItem.isParent && data.columnItem.level == 0) {
        x_children = true;
      }
      ws.matrix.updateMatrixItem(
        data.matrixUuid,
        data.rowItem.uuid,
        data.columnItem.uuid,
        y_children,
        x_children,
        `/matrix/${data.matrixUuid}`,
        data.value,
      );
    }
  }

  @Mutation
  public selectWholeRow(row: MatrixRelationField): void {
    let value = true;
    // if ALL items in a row are checked, set all to false
    if (this.matrixData.matrix[row.id].filter((item) => item === false).length === 0) {
      value = false;
    }
    for (let j = 0; j < this.matrixData.matrix[row.id].length; j++) {
      this.matrixData.matrix[row.id][j] = value;
    }
    // sent websocket messages
    for (const child of this.matrixData.axis_x.children) {
      ws.matrix.updateMatrixItem(
        this.matrixData.uuid,
        row.uuid,
        child.uuid,
        false,
        true,
        `/matrix/${this.matrixData.uuid}`,
        value,
      );
    }
  }
  @Mutation
  public selectWholeColumn(column: MatrixRelationField): void {
    let value = true;
    // if ALL items in a column are checked set all to false
    let atLeastOneChecked = false;
    let allChecked = true;
    for (let i = 0; i < this.matrixData.matrix.length; i++) {
      if (this.matrixData.matrix[i][column.id] === true) {
        atLeastOneChecked = true;
      } else {
        allChecked = false;
      }
    }
    if (atLeastOneChecked && allChecked) {
      value = false;
    }
    // check for radio button type in the second matrix type
    if (this.matrixData.matrix_type == MatrixType.SECOND && value) {
      // check to see if field is in a radio button or checkbox group and deselect of if radio so we only have 1 selected item
      const columnItem = this.flattenedMatrixColumns.find((c) => c.id == column.id);
      const parent = this.matrixData.axis_x.children.find((c) => c.id == columnItem.parentId);
      if (parent && parent.behave_as == MatrixSelectType.RADIO) {
        // check if any of the children is checked
        for (const child of parent.children) {
          if (child.id != columnItem.id) {
            for (let i = 0; i < this.matrixData.matrix.length; i++) {
              this.matrixData.matrix[i][child.id] = false;
            }
          }
        }
      }
    }
    for (let i = 0; i < this.matrixData.matrix.length; i++) {
      this.matrixData.matrix[i][column.id] = value;
    }

    // send message via websocket
    for (const child of this.matrixData.axis_y.children) {
      ws.matrix.updateMatrixItem(
        this.matrixData.uuid,
        child.uuid,
        column.uuid,
        true,
        false,
        `/matrix/${this.matrixData.uuid}`,
        value,
      );
    }
  }
}

export default MatrixRelationsModule;
