import { CheckTree, CheckTreeOptions } from '@/app/shared/models/CheckTree';
import httpClient from '../axios.config';

const END_POINT = '/checktree';

export default {
  async getAll() {
    const data: CheckTree[] = await httpClient.get(END_POINT + '/');
    return data;
  },

  async getByUuid(uuid: string) {
    const data: CheckTree = await httpClient.get(END_POINT + '/' + uuid);
    return data;
  },

  async getClassOptions() {
    const options: CheckTreeOptions[] = await httpClient.get(END_POINT + '/class/options');
    return options;
  },

  async updateChecktreeEnumaration(uuid: string, shouldEnumerate: boolean) {
    return await httpClient.post(END_POINT + '/' + uuid, null, {
      params: {
        data: JSON.stringify({ enumerate: shouldEnumerate ? 1 : 0 }),
      },
    });
  },

  async addSingleChecktree(data: any) {
    return await httpClient.put(`${END_POINT}`, data);
  },

  async importChecktree(data: any, file: string | Blob) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('file', file);
    return await httpClient.put(`${END_POINT}/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  async resume(uuid: string) {
    await httpClient.post(`${END_POINT}/${uuid}/resume`);
  },
};
