import { SingleImpact } from '@/app/shared/models/reports/SingleImpact';
import { ChapterItem } from '@/app/shared/models/reports/ChapterItem';
import { Chapter } from '@/app/shared/models/reports/Chapter';
import httpClient from '@/app/shared/api/axios.config';
import { AddImageResponse } from '@/app/shared/models/reports/AddImageResponse';
import { NewApiResponse } from '@/app/shared/models/ApiResponse';
import { PDFReportForUpdate } from '@/app/shared/models/Report';
import {
  ReportChapterItem,
  ReportChapters,
  ReportRenderJob,
  ReportType,
  TemplateReports,
} from '@/app/shared/models/Report';
import { StudyStage, StudyTemplate } from '@/app/shared/models/Study';

const CHECKTREE_END_POINT = '/checktree';
const CHAPTER_END_POINT = '/chapter';
const IMAGE_FROM_URL_END_POINT = '/image/from_url';
const TABLE_FROM_URL_END_POINT = '/table/from_url';
const FILE_END_POINT = '/file';
const IMPACT_ASSESSMENT_END_POINT = '/impact/assessment';
const IMPACT_ASSESSMENT_PARAGRAPHS_END_POINT = '/unit/paragraphs';
const REPORT_END_POINT = '/report';
const PDF_OUTPUT_END_POINT = '/pdf-output';

export default {
  async getNavbarChapters(uuid: string) {
    const data: Chapter[] = await httpClient.get(`${CHECKTREE_END_POINT}/${uuid}/navbar`);
    return data;
  },
  async getChapterByUuid(uuid: string) {
    const data: ChapterItem[] = await httpClient.get(`${CHAPTER_END_POINT}/${uuid}`);
    return data;
  },

  async addImageFromUrl(url: string): Promise<AddImageResponse> {
    const data: any = await httpClient.put(`${IMAGE_FROM_URL_END_POINT}`, null, {
      params: {
        url: url,
      },
    });
    return data.image;
  },

  async addTableFromUrl(url: string): Promise<any> {
    const res: { html: string; status: string } = await httpClient.put(`${TABLE_FROM_URL_END_POINT}`, null, {
      params: {
        url: url,
      },
    });
    return { data: { html: res.html }, status: res.status };
  },

  async uploadFile(file: string, fileType: string, uploadType: string) {
    const res: { id: number; status: 'success' | 'error'; uuid: string } = await httpClient.put(
      `${FILE_END_POINT}/${uploadType}`,
      {
        contents: file,
        content_type: fileType,
      },
    );
    return res;
  },

  async updateFile(file: string, uuid: string, fileType: string = null) {
    const res: { id: number; status: 'success' | 'error'; uuid: string } = await httpClient.post(
      `${FILE_END_POINT}/${uuid}`,
      {
        content_type: fileType,
        contents: file,
      },
    );
    return res;
  },

  async getImpactAssesment(matrixUuid: string) {
    const data: any = await httpClient.get(`${IMPACT_ASSESSMENT_END_POINT}/${matrixUuid}`);
    return data;
  },

  async getImpactAssesmentParagraphs(uuid: string, studyUuid: string, fromMatrix: string) {
    const data: SingleImpact = await httpClient.get(`${IMPACT_ASSESSMENT_PARAGRAPHS_END_POINT}/${uuid}`, {
      params: {
        study_uuid: studyUuid,
        from_matrix: fromMatrix,
      },
    });
    return data;
  },

  async resumeReport(url: string): Promise<NewApiResponse<void>> {
    return await httpClient.post(`${REPORT_END_POINT}/resume`, {
      full_path: url,
    });
  },

  async getReportChapters(studyUuid: string, stage: StudyStage, template: StudyTemplate): Promise<ReportChapters> {
    return await httpClient.get(`${PDF_OUTPUT_END_POINT}/chapters/${studyUuid}/${stage}/${template}`);
  },

  async getImpactChapters(studyUuid: string): Promise<ReportChapterItem[]> {
    return await httpClient.get(`${PDF_OUTPUT_END_POINT}/impacts/${studyUuid}`);
  },

  async getReports(studyUuid: string, stage: StudyStage): Promise<TemplateReports> {
    return await httpClient.get(`${PDF_OUTPUT_END_POINT}/reports/${studyUuid}/${stage}`);
  },

  async renderPdf(
    studyUuid: string,
    templateUuid: string | string[],
    chapterIds: string,
    stage: StudyStage,
    template: StudyTemplate,
    type: ReportType,
  ): Promise<ReportRenderJob> {
    return await httpClient.post(`${PDF_OUTPUT_END_POINT}/report/pdf`, {
      study_uuid: studyUuid,
      template_uuid: templateUuid,
      chapter_ids: chapterIds,
      stage,
      template,
      type,
    });
  },

  async downloadPdf(uuid: string, onDownloadProgress?: (progressEvent: ProgressEvent) => void): Promise<Blob> {
    return await httpClient.get(`${PDF_OUTPUT_END_POINT}/report/${uuid}`, {
      responseType: 'blob',
      onDownloadProgress,
    });
  },

  async deletePdf(uuid: string): Promise<void> {
    return await httpClient.delete(`${PDF_OUTPUT_END_POINT}/${uuid}`);
  },

  async updatePDFReportName(uuid: string, payload: PDFReportForUpdate) {
    return await httpClient.post(`${PDF_OUTPUT_END_POINT}/${uuid}/update`, { name: payload.name });
  },

  async updatePDFReportComment(uuid: string, payload: PDFReportForUpdate) {
    return await httpClient.post(`${PDF_OUTPUT_END_POINT}/${uuid}/update`, { comment: payload.comment });
  },

  async updatePDFReportIsImportant(uuid: string, payload: PDFReportForUpdate) {
    return await httpClient.post(`${PDF_OUTPUT_END_POINT}/${uuid}/update`, { is_important: payload.is_important });
  },

  async updatePDFReportIsAuthority(uuid: string, payload: PDFReportForUpdate) {
    return await httpClient.post(`${PDF_OUTPUT_END_POINT}/${uuid}/update`, { is_authority: payload.is_authority });
  },

  async updatePDFReportIsPublic(uuid: string, payload: PDFReportForUpdate) {
    return await httpClient.post(`${PDF_OUTPUT_END_POINT}/${uuid}/update`, { is_public: payload.is_public });
  },

  async renderDirectPdf(
    studyUuid: string,
    templateUuid: string,
    chapterIds: string,
    stage: string,
    template: string,
    type: string,
  ): Promise<Blob> {
    return await httpClient.post(
      `${PDF_OUTPUT_END_POINT}/direct/pdf`,
      {
        study_uuid: studyUuid,
        template_uuid: templateUuid,
        chapter_ids: chapterIds,
        stage,
        template,
        type,
      },
      {
        responseType: 'blob',
      },
    );
  },
};
