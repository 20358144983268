




















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { escapeRegExp } from 'lodash';
import Sidebar from '@/app/shared/components/Sidebar.vue';
import { CheckTree } from '@/app/shared/models/CheckTree';

@Component({
  components: {
    Sidebar,
  },
})
export default class CalculationsSidebar extends Vue {
  @Prop() filterChecktrees: CheckTree[];
  public resizeToggle = false;
  expandedFilters: number[] = [];
  filterTags: string[] = [];

  @Watch('filterTags')
  onFilterTagsChange() {
    if (this.areAllFiltersExpanded) return;
    this.toggleAllExpandedFilters();
  }

  get areAllFiltersExpanded() {
    return this.expandedFilters.length === this.filterChecktrees.length;
  }

  get sortedFilterChecktrees() {
    return this.filterChecktrees.sort((a, b) => a.name.localeCompare(b.name));
  }

  get matchedFilterChecktrees() {
    if (!this.filterTags.length) return this.sortedFilterChecktrees;
    return this.sortedFilterChecktrees.reduce(
      (matchedFilterChecktrees, filterChecktree) => [
        ...matchedFilterChecktrees,
        {
          ...filterChecktree,
          children: filterChecktree.children.filter((checktreeItem) =>
            this.filterTags.some((tag) =>
              new RegExp(escapeRegExp(this.removeAccents(tag.trim())), 'i').test(
                this.removeAccents(checktreeItem.title),
              ),
            ),
          ),
        },
      ],
      [],
    );
  }

  removeAccents(target: string) {
    return target.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  getFilterIcon(name: string) {
    switch (name.toLowerCase()) {
      case 'aspects':
        return 'aspects';
      case 'activities':
        return 'activities';
      case 'receptors':
      case 'new receptors':
        return 'receptors-24';
      case 'impacts':
        return 'impact-character';
      case 'phases':
        return 'impact-phase';
      default:
        return 'checktree-disabled';
    }
  }

  public emitResizeEvent() {
    this.resizeToggle = !this.resizeToggle;
  }

  isFilterChecktreeComplete(filterChecktree: CheckTree) {
    return filterChecktree.children.every((checktree) => checktree.checked);
  }

  isFilterChecktreePartial(filterChecktree: CheckTree) {
    return (
      filterChecktree.children.some((checktree) => checktree.checked) &&
      !filterChecktree.children.every((checktree) => checktree.checked)
    );
  }

  onFilterChecktreeChange(filterChecktree: CheckTree, isChecked: boolean) {
    filterChecktree.children.forEach((checktreeItem) => this.$set(checktreeItem, 'checked', isChecked));
  }

  toggleAllExpandedFilters() {
    if (this.areAllFiltersExpanded) this.expandedFilters = [];
    else this.expandedFilters = this.filterChecktrees.map((filterChecktree, index) => index);
  }

  expandFilterChecktree(filterIndex: number, toggleSidebar: () => void) {
    if (!this.expandedFilters.includes(filterIndex)) this.expandedFilters.push(filterIndex);
    toggleSidebar();

    this.$nextTick(() => {
      const filterChecktreeContainer = this.$refs.filterChecktreeContainer;
      const filterChecktrees = this.$refs.filterChecktree;
      if (filterChecktreeContainer && Array.isArray(filterChecktrees) && filterChecktrees[filterIndex]) {
        (filterChecktreeContainer as Vue).$el.parentElement.scrollTop = ((filterChecktrees[filterIndex] as Vue)
          .$el as HTMLDivElement).offsetTop;
      }
    });
  }

  blurActiveElement(event: MouseEvent) {
    // Do not blur if the element was activated via keyboard.
    if (event.detail < 1) return;
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement && typeof activeElement.blur === 'function') activeElement.blur();
  }
}
