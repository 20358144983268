var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column form-field-facility",class:{
    'form-field-facility--show-error': _vm.isRequiredObjectProjectName,
  }},[_vm._l((_vm.facilities),function(facility,index){return [_c('div',{key:("facility-" + index + "-0")},[_c('FormFieldSelect',{ref:"formField",refInFor:true,attrs:{"control":{
          uniqueId: ((_vm.control.uniqueId) + "_project"),
          name: ("project_" + index),
          label: 'New Project or Existing',
          placeholderText: 'Select',
          items: [
            {
              value: 'new',
              text: 'New Development',
            },
            {
              value: 'existing',
              text: 'Existing Facility or Activity',
            } ],
          isDisabled: _vm.control.isDisabled,
          isReadonly: _vm.control.isReadonly,
          validations: _vm.isRequiredObjectProjectName ? [{ ruleType: 'required' }] : null,
        }},model:{value:(facility.project),callback:function ($$v) {_vm.$set(facility, "project", $$v)},expression:"facility.project"}})],1),_c('div',{key:("facility-" + index + "-1"),staticClass:"mt-3"},[_c('FormFieldText',{attrs:{"control":{
          uniqueId: ((_vm.control.uniqueId) + "_name"),
          name: ("name_" + index),
          label: 'Development / Facility Name',
          placeholderText: 'Enter',
          inputType: 'text',
          isDisabled: _vm.control.isDisabled,
          isReadonly: _vm.control.isReadonly,
          validations: _vm.isRequiredObjectProjectName ? [{ ruleType: 'required' }] : null,
        }},model:{value:(facility.name),callback:function ($$v) {_vm.$set(facility, "name", $$v)},expression:"facility.name"}})],1),_c('div',{key:("facility-" + index + "-2"),staticClass:"d-flex flex-column form-field-facility__toggles"},[_c('div',{staticClass:"d-flex flex-grow-1 justify-end"},[(index > 0)?_c('BaseButton',{staticClass:"form-field-facility__delete-group-button",attrs:{"disabled":_vm.control.isDisabled || _vm.control.isReadonly,"type":"quin","icon":"delete"},on:{"click":function($event){return _vm.removeGroup(index)}}},[_vm._v(" Delete Group ")]):_vm._e()],1)]),(!_vm.control.isDisabled && !_vm.control.isReadonly)?_c('div',{key:("facility-" + index + "-3")},[_c('div',{staticClass:"d-flex align-center form-field-facility__add-group",class:{
          'form-field-facility__add-group--is-sm': _vm.$vuetify.breakpoint.smAndDown,
        }},[_c('v-icon',{on:{"click":function($event){return _vm.addGroup(index)}}},[_vm._v(" $add-v2 ")])],1)]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }