































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ResizeObserver from 'resize-observer-polyfill';
import TrackingItemActivity from './TrackingItemActivity.vue';
import TrackingItemHeader from './TrackingItemHeader.vue';
import { TrackingResponseItem, TrackingItem } from '@/app/shared/models/Tracking';
import { User } from '@/app/shared/models/User';

dayjs.extend(utc);

const TrackingStore = namespace('Tracking');
const ReportsStore = namespace('Reports');
const UserStore = namespace('User');

@Component({
  components: {
    TrackingItemActivity,
    TrackingItemHeader,
  },
})
export default class TrackingBox extends Vue {
  @Prop() trackingItem: TrackingItem;
  @Prop({ default: false, type: Boolean }) toolbarCollapsed: boolean;
  top: number = 0;
  resizeObserver: ResizeObserver = null;
  hasTrackingItem: boolean = false;

  @Emit()
  unlock(_trackingItem: TrackingItem) {
    //
  }

  @Watch('toolbarCollapsed')
  onToolbarCollapsedChange() {
    this.setVisibilityAndPosition();
  }

  @Watch('trackingItem', { deep: true })
  onIsExpandedChange(trackingItem: TrackingItem) {
    const reportContents = document.querySelector('#report-contents') as HTMLDivElement;
    if (!reportContents) return;

    let paddingBottom = '68px';

    if (!this.hasExpandedTrackingItem) {
      reportContents.style.paddingBottom = paddingBottom;
      return;
    }

    if (this.hasExpandedTrackingItem && !trackingItem.is_expanded) return;

    this.$nextTick(() => {
      const trackingItemHeight = (this.$refs.trackingBox as HTMLDivElement).offsetHeight;
      if (trackingItemHeight > 68) paddingBottom = `${trackingItemHeight}px`;
      reportContents.style.paddingBottom = paddingBottom;
    });
  }

  @UserStore.Getter
  userId: number;

  @UserStore.Getter
  userModel: User;

  @TrackingStore.State
  trackingItems: TrackingResponseItem[];

  @TrackingStore.Getter
  hasExpandedTrackingItem: boolean;

  @TrackingStore.Action
  toggleExpand: (payload: { trackingItem: TrackingItem; isExpanded: boolean }) => void;

  @ReportsStore.Getter
  navigatorCollapsed: boolean;

  get isShown() {
    if (this.isExpanded) return true;
    if (this.hasExpandedTrackingItem) return false;
    return this.hasTrackingItem;
  }

  get isExpanded() {
    return this.trackingItem.is_expanded;
  }

  set isExpanded(isExpanded: boolean) {
    this.toggleExpand({ trackingItem: this.trackingItem, isExpanded });
  }

  get isLocked() {
    return this.trackingItem.is_locked;
  }

  get trackingItemBackgroundColor() {
    if (this.isExpanded) return 'var(--v-blue-pale)';
    return 'var(--v-white)';
  }

  get absoluteOffset() {
    let toolbarHeight = 80;
    let navigatorHeight = 56;
    if (this.toolbarCollapsed) toolbarHeight = 32;
    if (this.navigatorCollapsed) navigatorHeight = 32;

    // NB: app bar height + toolbar (+header) height + navigator height + report contents top padding
    //   - tracking item "magic" offset
    return 48 + toolbarHeight + navigatorHeight + 96 - 78;
  }

  mounted() {
    this.registerEventListeners();
  }

  beforeDestroy() {
    this.deregisterEventListeners();
  }

  registerEventListeners() {
    this.setVisibilityAndPosition();
    this.resizeObserver = new ResizeObserver(this.setVisibilityAndPosition);
    this.resizeObserver.observe(document.querySelector('#report-contents'));
  }

  deregisterEventListeners() {
    this.resizeObserver.disconnect();
  }

  setVisibilityAndPosition() {
    const trackingItemElement = document.querySelector(
      `#${CSS.escape(`content/${this.trackingItem.object_item_uuid}`)}`,
    ) as HTMLDivElement;

    if (!trackingItemElement || trackingItemElement.offsetParent === null) {
      // if (this.isExpanded) this.isExpanded = false;
      this.hasTrackingItem = false;
      return;
    }

    this.top = this.getElementAbsoluteTopOffset(trackingItemElement);
    this.hasTrackingItem = true;
  }

  getElementAbsoluteTopOffset(elem: HTMLElement) {
    const box = elem.getBoundingClientRect();
    const body = document.body;
    const docEl = document.documentElement;
    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const clientTop = docEl.clientTop || body.clientTop || 0;
    const top = box.top + scrollTop - clientTop;

    return Math.round(top) - this.absoluteOffset;
  }
}
