



















































































































































































import { Component, Mixins, Watch, Vue } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import FormFieldText from '@/app/shared/components/form/field/FormFieldText.vue';
import FormFieldUploadInline from '@/app/shared/components/form/field/FormFieldUploadInline.vue';
import FormFieldFocus from '@/app/shared/components/form/field/mixins/FormFieldFocus.vue';
import { ValidationRule } from '@/app/shared/models/form/FormSchema';
import { Investor } from '@/app/shared/models/form/field/FormFieldInvestor';
import { FormFieldValue, FormFieldValueModel } from '@/app/shared/models/form/field';
import DialogService from '@/app/shared/utils/dialog.service';

@Component({
  components: {
    FormFieldText,
    FormFieldUploadInline,
  },
})
export default class FormFieldInvestor extends Mixins(Vue, FormFieldFocus, BaseControlSkeleton) {
  value: FormFieldValue<Investor>;
  deleted: string[] = [];
  investors: Investor[] = [new Investor()];
  control: {
    validations: ValidationRule[];
  };
  updateValue: (value: FormFieldValue<Investor>) => void;

  @Watch('value', { deep: true })
  onValueChanged(value: FormFieldValue<Investor>) {
    this.$set(this, 'investors', value.data);
  }

  @Watch('investors', { deep: true })
  onInvestorsChange(value: Investor[]) {
    this.updateValue(new FormFieldValue(value, FormFieldValueModel.INVESTOR, this.deleted));
  }

  get isRequiredObjectName() {
    if (this.control.validations)
      return (
        this.control.validations.filter(
          (validation) =>
            validation.ruleType === 'customClosure' && validation.additionalValue === 'requiredObjectName',
        ).length > 0
      );

    return false;
  }

  get isValidObjectEmail() {
    if (this.control.validations)
      return (
        this.control.validations.filter(
          (validation) => validation.ruleType === 'customClosure' && validation.additionalValue === 'validObjectEmail',
        ).length > 0
      );

    return false;
  }

  created() {
    if (this.value) this.$set(this, 'investors', this.value.data);
  }

  addGroup(index: number) {
    this.investors.splice(index + 1, 0, new Investor());
  }

  removeGroup(index: number) {
    const removeClosure = () => {
      const uuid = this.investors[index].uuid;
      if (uuid) this.deleted.push(uuid);
      this.investors.splice(index, 1);
    };

    // Skip showing confirmation dialog in test environment.
    if (process.env.NODE_ENV === 'test') {
      removeClosure();
      return;
    }

    DialogService.presentDialog(
      'Delete this item?',
      'You will not be able to recover it.',
      'Yes',
      'Cancel',
      removeClosure,
    );
  }

  focus() {
    const formField = this.$refs.formField as (Vue & { focus: () => void })[];
    if (formField[0]) formField[0].focus();
  }

  toggleDetails(investor: Investor, event: Event) {
    investor.isDetailsHidden = !investor.isDetailsHidden;
    if (event.target instanceof HTMLButtonElement) event.target.focus();
  }
}
