





























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Sidebar from '@/app/shared/components/Sidebar.vue';
import { Tree, Draggable, Fold } from 'he-tree-vue';
import ReportsSidebarItem from './ReportsSidebarItem.vue';
import { namespace } from 'vuex-class';
import { Chapter } from '@/app/shared/models/reports/Chapter';
import _debounce from 'lodash.debounce';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import UtilService from '@/app/shared/utils/util.service';
import { ChapterItem } from '@/app/shared/models/reports/ChapterItem';
import { OnChapterItemsReorderPayload } from '@/app/shared/store/modules/reports.store-module';
import { SingleImpact } from '@/app/shared/models/reports/SingleImpact';

const ReportsModuleStore = namespace('Reports');
@Component({
  components: {
    Sidebar,
    Tree: (Tree as any).mixPlugins([Draggable, Fold]),
    ReportsSidebarItem,
  },
})
export default class ReportsSidebar extends Vue {
  @Prop() singleImpactView: boolean;
  @Prop({ default: false, type: Boolean }) isEditModeAllowed: boolean;

  // dev only method
  // @ReportsModuleStore.Mutation
  // public init!: () => void;

  @ReportsModuleStore.Getter
  public getCurrentChapter: Chapter;

  @ReportsModuleStore.Getter
  public getSingleImpact: SingleImpact;

  @ReportsModuleStore.Getter
  public getParagraphs: ChapterItem[];

  @ReportsModuleStore.Action
  toggleFoldItems!: (shouldFold: boolean) => void;

  @ReportsModuleStore.Action
  findSidebarItem!: (searchText: string) => string;

  @ReportsModuleStore.Action
  onChapterItemsReorder!: (payload: OnChapterItemsReorderPayload) => void;

  public collapseChange: boolean = false;
  public defaultSidebarWidth = 0;
  public resizeToggle = false;

  get singleImpactHeaderParagraphs() {
    return this.getSingleImpact ? this.getParagraphs : null;
  }

  get checktreeParagraphs() {
    return this.getCurrentChapter ? this.getParagraphs : this.getParagraphs[1].children;
  }

  get chapterNumber() {
    return this.getCurrentChapter ? this.getCurrentChapter.order : 1;
  }

  created() {
    // this.init();
    // calculate default sidebar width
    this.defaultSidebarWidth = UtilService.calculateSidebarWidth();
  }

  public emitResizeEvent() {
    this.resizeToggle = !this.resizeToggle;
  }

  public onChange(event: { dragNode: ChapterItem; startPath: number[]; targetPath: number[] }) {
    const tree: Tree = this.$refs.treeEl as Tree;

    let nodeBefore: ChapterItem = null;
    let isFirstChild = false;

    const childPosition = event.targetPath[event.targetPath.length - 1];
    if (childPosition === 0) {
      // if node position should be first child of some node
      isFirstChild = true;
      if (event.targetPath.length > 1) {
        // node is not on first level
        nodeBefore = tree.getNodeParentByPath(event.targetPath) as ChapterItem;
      }
    } else {
      // if node is at second or higher position in children array
      const childBeforePath: number[] = [...event.targetPath];
      childBeforePath[childBeforePath.length - 1] = childPosition - 1;
      nodeBefore = tree.getNodeByPath(childBeforePath) as ChapterItem;
    }

    const updatedTreeData = tree.cloneTreeData() as ChapterItem[];

    this.onChapterItemsReorder({
      dragNode: event.dragNode,
      afterNode: nodeBefore,
      isFirstChild,
      updatedTreeData,
    });
  }

  public toggleFoldChapterItems(foldAll: boolean) {
    this.toggleFoldItems(foldAll);
    this.collapseChangeEvent();
  }

  collapseChangeEvent() {
    this.collapseChange = !this.collapseChange;
  }

  get areAllCollapsed() {
    this.collapseChange;
    for (const item of this.getParagraphs) {
      if (!item.$folded) {
        return false;
      }
    }
    return true;
  }

  findItem = _debounce(async (event: any) => {
    const searchText = event.target.value.toLowerCase();
    if (searchText == '') {
      return;
    }
    const resultUuid = await this.findSidebarItem(searchText);
    if (resultUuid) {
      const sidebarElement = document.getElementById(resultUuid);
      if (sidebarElement) {
        sidebarElement.scrollIntoView();
      }
      const contentElement = document.getElementById(`content/${resultUuid}`);
      if (contentElement) {
        contentElement.scrollIntoView({ behavior: 'smooth' });
      }
      eventBus.$emit(EventType.RERENDER_REPORT_SIDEBAR);
    }
  }, 500);

  waitForScrollToEnd(): Promise<void> {
    return new Promise((resolve) => {
      let scrollTimeout: any;
      addEventListener('scroll', () => {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(function() {
          resolve();
        }, 100);
      });
    });
  }
}
