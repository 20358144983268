var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"right":"","disabled":!_vm.showPopover || _vm.node.type === _vm.ChapterItemType.TEXT},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center justify-space-between reports-item-wrapper",on:{"click":_vm.scrollToParagraph}},'div',attrs,false),on),[(_vm.node.isCurrent)?_c('div',{staticClass:"current-paragraph-indicator"}):_vm._e(),_c('div',{staticClass:"d-flex align-center title-wrapper"},[(_vm.isEditModeAllowed && !_vm.isFooterCommentingActive && _vm.node.apply_synclocker)?_c('v-icon',{staticClass:"drag-wrapper",attrs:{"small":""}},[_vm._v("$drag")]):_c('div',{staticClass:"no-drag-wrapper"}),(_vm.node.type === _vm.ChapterItemType.SINGLE_IMPACT_HEADER)?_c('span',{staticClass:"title title-overflow second-level-title",class:[{ 'current-title': _vm.node.isCurrent }],attrs:{"id":_vm.node.uuid}},[_vm._v(_vm._s(_vm.node.custom_title || _vm.node.title))]):_vm._e(),(_vm.node.type === _vm.ChapterItemType.HEADING)?_c('span',{staticClass:"title title-overflow second-level-title",class:[{ 'current-title': _vm.node.isCurrent }],attrs:{"id":_vm.node.uuid}},[_vm._v(_vm._s(_vm.node.numeration)+" "+_vm._s(_vm.node.title))]):_vm._e(),(_vm.node.type === _vm.ChapterItemType.IMAGE || _vm.node.type === _vm.ChapterItemType.CHART)?_c('span',{staticClass:"title-figure title-overflow",class:{ 'current-title': _vm.node.isCurrent },attrs:{"id":_vm.node.uuid}},[_c('span',{staticClass:"title-figure-numeration"},[_vm._v("FIGURE "+_vm._s(_vm.node.numeration))]),_c('span',{staticClass:"italic-font"},[_vm._v(_vm._s(_vm.node.title))])]):_vm._e(),(_vm.node.type === _vm.ChapterItemType.TEXT)?_c('span',{staticClass:"title-figure title-overflow",class:{ 'current-title': _vm.node.isCurrent },attrs:{"id":_vm.node.uuid}},[_c('span',{staticClass:"title-figure-numeration"},[_vm._v("TEXT ")])]):_vm._e(),(
            _vm.node.type === _vm.ChapterItemType.TABLE ||
              _vm.node.type === _vm.ChapterItemType.SPREADSHEET ||
              _vm.node.type === _vm.ChapterItemType.LIBRARY_CONTENT
          )?_c('span',{staticClass:"title-figure title-overflow",class:{ 'current-title': _vm.node.isCurrent },attrs:{"id":_vm.node.uuid}},[_c('span',{staticClass:"title-figure-numeration"},[_vm._v("TABLE "+_vm._s(_vm.node.numeration))]),_c('span',{staticClass:"italic-font"},[_vm._v(_vm._s(_vm.node.title))])]):_vm._e()],1),(_vm.node && _vm.node.children && _vm.node.children.length > 0)?_c('BaseButton',{staticClass:"btn-expand",attrs:{"type":"icon-light","disabled":!_vm.node.children || _vm.node.children.length === 0},on:{"click":_vm.toggleFold}},[_c('v-icon',{class:{ folded: !_vm.node.children || _vm.node.children.length === 0 || _vm.node.$folded }},[_vm._v("$expand")])],1):_vm._e()],1)]}}])},[_c('span',[(_vm.node.type === _vm.ChapterItemType.SINGLE_IMPACT_HEADER)?_c('span',{staticClass:"title-popover"},[_vm._v(_vm._s(_vm.node.custom_title || _vm.node.title))]):_vm._e(),(_vm.node.type === _vm.ChapterItemType.HEADING)?_c('span',{staticClass:"title-popover"},[_vm._v(_vm._s(_vm.node.numeration)+" "+_vm._s(_vm.node.title))]):_vm._e(),(_vm.node.type === _vm.ChapterItemType.IMAGE || _vm.node.type === _vm.ChapterItemType.CHART)?_c('span',{staticClass:"title-figure-popover"},[_c('span',{staticClass:"title-figure-numeration"},[_vm._v("FIGURE "+_vm._s(_vm.node.numeration)+" ")]),_c('span',{staticClass:"italic-font"},[_vm._v(_vm._s(_vm.node.title))])]):_vm._e(),(
        _vm.node.type === _vm.ChapterItemType.TABLE ||
          _vm.node.type === _vm.ChapterItemType.SPREADSHEET ||
          _vm.node.type === _vm.ChapterItemType.LIBRARY_CONTENT
      )?_c('span',{staticClass:"title-figure-popover"},[_c('span',{staticClass:"title-figure-numeration"},[_vm._v("TABLE "+_vm._s(_vm.node.numeration)+" ")]),_c('span',{staticClass:"italic-font"},[_vm._v(_vm._s(_vm.node.title))])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }