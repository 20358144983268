
































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AsyncComputed from 'vue-async-computed-decorator';
import { namespace } from 'vuex-class';
import { cloneDeep } from 'lodash';
import PdfOutputSidebar from './components/PdfOutput/PdfOutputSidebar.vue';
import PdfOutputPageHeader from './components/PdfOutput/PdfOutputPageHeader.vue';
import ReportChaptersTable from '@/app/shared/components/table/ReportChaptersTable.vue';
import PageFooter from '@/app/shared/components/PageFooter.vue';
import Library from '@/app/shared/components/Library.vue';
import api from '@/app/shared/api';
import LoaderService from '@/app/shared/utils/loader.service';
import UtilService from '@/app/shared/utils/util.service';
import { StudyStage, StudyTemplate } from '@/app/shared/models/Study';
import { ModuleProperties } from '@/app/shared/models/Module';
import { ReportChapterItem, ReportChapters, ReportType } from '@/app/shared/models/Report';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

const LoaderStore = namespace('Loader');
const ModulePropertiesStore = namespace('ModuleProperties');
const UiModuleStore = namespace('UI');
const UserStore = namespace('User');

@Component({
  components: {
    PageFooter,
    PdfOutputSidebar,
    Library,
    PdfOutputPageHeader,
    ReportChaptersTable,
  },
})
export default class PdfOutput extends Vue {
  @Prop() studyUuid: string;
  @Prop() stage: StudyStage;
  isLoaded: boolean = false;
  defaultLibraryWidth: number = 0;
  tab: number = 0;
  templates: {
    id: StudyTemplate;
    icon: string;
    iconDisabled: string;
    name: string;
  }[] = [
    {
      id: 'full-report',
      icon: 'full-report',
      iconDisabled: 'full-report-disabled',
      name: 'Report',
    },
    {
      id: 'summary-report',
      icon: 'summary-report-workspace',
      iconDisabled: 'summary-report-workspace-disabled',
      name: 'Summary',
    },
    {
      id: 'impacts',
      icon: 'single-impact-assessment',
      iconDisabled: 'single-impact-assessment-disabled',
      name: 'Impacts',
    },
  ];
  reportChapters: ReportChapters = null;
  items: ReportChapterItem[] = [];

  @LoaderStore.Getter
  isLoaderVisible: boolean;

  @ModulePropertiesStore.Getter
  moduleProperties: ModuleProperties;

  @UiModuleStore.Getter
  isFooterLibraryActive: boolean;

  @UiModuleStore.Getter
  isLeftSidebarExpanded: boolean;

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  @Watch('template')
  async onTemplateChange() {
    await this.getReportChapters();
  }

  @AsyncComputed()
  async isFinalPDFButtonAllowed() {
    return await this.isAllowed(UserPermission.PDF_OUTPUT_FINAL_PDF_BUTTON);
  }

  @AsyncComputed()
  async areVersioningToolsAllowed() {
    return await this.isAllowed(UserPermission.PDF_OUTPUT_VERSIONING_TOOLS);
  }

  @AsyncComputed()
  async isDeleteButtonAllowed() {
    return await this.isAllowed(UserPermission.PDF_OUTPUT_DELETE_BUTTON);
  }

  get headerTitle() {
    return this.moduleProperties ? this.moduleProperties?.name || '' : '';
  }

  get sidebarXOffset() {
    return `${this.isLeftSidebarExpanded ? 380 : 56}px`;
  }

  get template() {
    return this.templates[this.tab].id;
  }

  get templateUuid() {
    return this.reportChapters && this.reportChapters.uuid;
  }

  get impactUuids() {
    return (
      this.reportChapters &&
      !this.templateUuid &&
      (this.items?.reduce((impactUuids, impact) => [...impactUuids, impact.uuid], []) as string[])
    );
  }

  get isPreliminaryPDFButtonDisabled() {
    return (!this.templateUuid && (!this.impactUuids || !this.impactUuids.length)) || !this.chapterIds;
  }

  get isFinalPDFButtonDisabled() {
    return (
      !this.isFinalPDFButtonAllowed ||
      (!this.templateUuid && (!this.impactUuids || !this.impactUuids.length)) ||
      !this.chapterIds
    );
  }

  get tableColumns() {
    return [
      {
        text: 'Numeration',
        width: 128,
        numericValue: 'localOrder',
      },
      {
        text: 'Chapter',
        width: 610,
        value: 'title',
      },
      {
        text: 'Exclude',
        width: 128,
        checkboxType: 'exclude',
        checkboxValue: 'excludeFromPdf',
      },
      {
        text: 'Create PDF',
        width: 128,
        actionType: 'render-pdf',
        actionIcon: 'create-pdf',
      },
    ];
  }

  async created() {
    try {
      LoaderService.disableHttpLoader();
      LoaderService.showLoader();
      this.defaultLibraryWidth = UtilService.calculateSidebarWidth();
      await this.getReportChapters();
    } catch (error) {
      console.warn(error);
    } finally {
      this.isLoaded = true;
      LoaderService.hideLoader();
      LoaderService.enableHttpLoader();
    }
  }

  async getReportChapters() {
    try {
      switch (this.template) {
        case 'full-report':
        case 'summary-report':
          this.reportChapters = await api.reports.getReportChapters(this.studyUuid, this.stage, this.template);
          break;

        // TODO: dev only
        case 'impacts': {
          const impactChapters = await api.reports.getImpactChapters(this.studyUuid);
          this.reportChapters = {
            uuid: '',
            children: impactChapters,
          };
          break;
        }
      }
      this.initializeItems(this.reportChapters);
    } catch (error) {
      console.warn(error);
    }
  }

  initializeItems(reportChapters: ReportChapters) {
    this.items = [];

    reportChapters.children.forEach((reportChapter, index) => {
      this.items.push({
        uuid: reportChapter.uuid,
        title: reportChapter.title,
        order: (index + 1).toString(),
        localOrder: (index + 1).toString(),
        excludeFromPdf: false,
      });
    });
  }

  refreshSidebar(template: StudyTemplate, version: number) {
    const sidebar = this.$refs.sidebar as Vue & { refresh: (template: StudyTemplate, version: number) => void };
    if (sidebar) sidebar.refresh(template, version);
  }

  excludeChange(item: ReportChapterItem) {
    item.localOrder = '';
    this.reNumerateItems();
  }

  excludeAll(value: boolean) {
    for (let i = 0; i < this.items.length; i++) {
      const chapter = cloneDeep(this.items[i]);

      chapter.excludeFromPdf = value;
      chapter.localOrder = '';

      this.items.splice(i, 1, chapter);
    }

    this.reNumerateItems();
  }

  reNumerateItems() {
    let localIndex = 0;

    for (let i = 0; i < this.items.length; i++) {
      const item = cloneDeep(this.items[i]);

      if (!item.excludeFromPdf) {
        localIndex++;
        item.localOrder = localIndex.toString();
      } else {
        item.localOrder = '';
      }

      this.items.splice(i, 1, item);
    }
  }

  reorderItems() {
    this.items = cloneDeep(this.items).sort((a, b) => (Number(a.localOrder) > Number(b.localOrder) ? 1 : -1));
  }

  disallowNonNumericInput(event: KeyboardEvent) {
    if (/\d/.test(event.key)) return;
    event.preventDefault();
  }

  get hasExcludedChapters() {
    return this.items && this.items.some((item) => item.excludeFromPdf);
  }

  get chapterIds() {
    const result: string[] = [];
    const sortedItems = cloneDeep(this.items).sort((a, b) => (Number(a.localOrder) > Number(b.localOrder) ? 1 : -1));

    sortedItems.forEach((item) => {
      if (item.excludeFromPdf || !item.localOrder.length) return;
      result.push(`${item.order}=${item.localOrder}`);
    });

    return result.join('+');
  }

  async renderPdf(templateUuid: string | string[], type: ReportType) {
    let chapterIds = this.chapterIds;

    if (type === 'single') chapterIds = this.items.find((item) => item.uuid === templateUuid).localOrder;

    try {
      const renderJob = await api.reports.renderPdf(
        this.studyUuid,
        templateUuid,
        chapterIds,
        this.stage,
        this.template,
        type,
      );
      this.refreshSidebar(this.template, renderJob.version);
    } catch (error) {
      console.warn(error);
    }
  }

  onActionClick(type: string, item: ReportChapterItem) {
    if (type !== 'render-pdf') return;
    this.renderPdf(item.uuid, 'single');
  }

  onCheckboxClick(type: string, item: ReportChapterItem) {
    if (type !== 'exclude') return;
    this.excludeChange(item);
  }
}
