var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VuetifySelect',{ref:"formField",staticClass:"form-field-select",class:_vm.control.additionalFieldClass,attrs:{"value":_vm.value,"id":_vm.control.uniqueId,"name":_vm.control.name || _vm.control.uniqueId,"items":_vm.options,"multiple":_vm.control.isMultiple,"placeholder":_vm.control.placeholderText,"hint":_vm.control.subLabel,"disabled":_vm.isDisabled,"readonly":_vm.control.isReadonly,"dense":_vm.control.isSmall,"menu-props":{
    contentClass: ("form-field-select-content " + (_vm.control.name || _vm.control.uniqueId) + "-menu"),
    light: true,
    maxWidth: _vm.maxWidth,
    offsetY: true,
  },"data-cy":_vm.control.name || _vm.control.uniqueId,"hide-details":"auto","flat":"","full-width":"","light":"","single-line":"","solo":"","persistent-hint":""},on:{"input":_vm.updateValue},scopedSlots:_vm._u([(_vm.control.label)?{key:"prepend",fn:function(){return [_c('label',{attrs:{"for":_vm.control.uniqueId}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.control.helpText)?_c('v-icon',{staticClass:"form-field-label__help-icon",attrs:{"aria-label":_vm.control.helpText},on:{"click":function($event){return _vm.showHelpTooltip(_vm.control.helpText, $event)}}},[_vm._v(" $help ")]):_vm._e()]},proxy:true}:null,(_vm.control.isMultiple)?{key:"selection",fn:function(ref){
  var item = ref.item;
  var parent = ref.parent;
return [_c('v-chip',_vm._g({attrs:{"data-cy":("chip-" + (_vm.control.name || _vm.control.uniqueId)),"close-icon":"$close","close":"","small":""}},{
        'click:close': function () { return parent.onChipInput(item); },
      }),[_vm._v(" "+_vm._s(item.text)+" ")])]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }