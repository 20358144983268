export enum PresentationsSidebarOptionGroupEnum {
  TABLE_LEVEL_OF_DETAIL = 'table_level_of_detail',
  TABLE_LEVEL_OF_DETAIL_SIMPLE = 'table_level_of_detail_simple',
  TABLE_SIZE = 'table_size',
  TABLE_STYLE = 'table_style',
  TABLE_SORT = 'table_sort',
  TABLE_ENUMERATE = 'table_enumerate',
  CHARTS_NORMALIZATION = 'charts_normal',
  CHARTS_GROUPED_FOR = 'charts_grouped_for',
  CHARTS_STACKED_FOR = 'charts_stacked_for',
  CHARTS_STYLE = 'charts_style',
  STATS_ROW = 'stats_row',
  STATS_COLUMN = 'stats_col',
  STATS_STYLE = 'stats_style',
  STATS_SIZE = 'stats_size',
}
