













































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Loader from '@/app/shared/components/Loader.vue';
import { namespace } from 'vuex-class';
import { FormulateData } from '@/app/shared/models/calculations/FormulateData';
import Unit from '@/app/shared/models/calculations/Unit';
const CalculationsStore = namespace('Calculations');

@Component({
  components: { editor: Loader },
})
export default class SignificanceModal extends Vue {
  @Prop() unit: Unit;

  initialized: boolean = false;

  @CalculationsStore.Getter
  getFormulateData: FormulateData;

  @CalculationsStore.Action
  initializeFormulateData: (unitUuid: string) => Promise<any>;

  created() {
    // initialize formulate data
    // this.initializeUI();
    setTimeout(() => {
      this.initialized = true;
    }, 500);
  }

  get title() {
    return this.unit.method_name;
  }

  get impactTitle() {
    return this.unit.title;
  }

  get alternatives() {
    return this.unit.alternatives || [];
  }

  @Emit()
  close() {
    // just emiting event
  }
}
