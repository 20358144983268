


































































































































































































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { debounce } from 'lodash';
import ResizeObserver from 'resize-observer-polyfill';
import PageFooter from '@/app/shared/components/PageFooter.vue';
import ResizableTableCells from '@/app/shared/components/mixins/ResizableTableCells.vue';
import MatrixRelationsFieldItemFirstType from './components/MatrixRelationsFieldItemFirstType.vue';
import MatrixRelationsFieldItemSecondType from './components/MatrixRelationsFieldItemSecondType.vue';
import MatrixRelationsRowHeaderFirstType from './components/MatrixRelationsRowHeaderFirstType.vue';
import MatrixRelationsRowHeaderSecondType from './components/MatrixRelationsRowHeaderSecondType.vue';
import MatrixRelationsPageHeader from './components/MatrixRelationsPageHeader.vue';
import MatrixRelationsFilterSidebar from './components/MatrixRelationsFilterSidebar.vue';
import DialogService from '@/app/shared/utils/dialog.service';
import { TooltipOptions } from '@/app/shared/store/modules/ui.store-module';
import api from '@/app/shared/api';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import { MatrixType } from '@/app/shared/enums/matrix-type.enum';
import MatrixRelationField from '@/app/shared/models/MatrixRelationField';
import MatrixRelationMatrix from '@/app/shared/models/MatrixRelationMatrix';
import { MatrixAxis } from '@/app/shared/models/MatrixAxis';
import { WSMessageResponse } from '@/app/shared/models/WSMessageResponse';
import { WebSocketMessageStatus } from '@/app/shared/enums/WebsocketMessageStatus.enum';
import { ModuleProperties } from '@/app/shared/models/Module';
import { UserPermission } from '@/app/shared/enums/user-permission.enum';

const LoaderModuleStore = namespace('Loader');
const MatrixRelationsStore = namespace('MatrixRelations');
const ModulePropertiesStore = namespace('ModuleProperties');
const UiStore = namespace('UI');
const UserStore = namespace('User');

@Component({
  components: {
    MatrixRelationsFieldItemFirstType,
    MatrixRelationsFieldItemSecondType,
    MatrixRelationsRowHeaderFirstType,
    MatrixRelationsRowHeaderSecondType,
    MatrixRelationsFilterSidebar,
    MatrixRelationsPageHeader,
    PageFooter,
  },
})
export default class MatrixRelations extends ResizableTableCells {
  @MatrixRelationsStore.Getter
  public matrixRelationsData: MatrixRelationMatrix;
  @MatrixRelationsStore.Getter
  public flattenedRows: Array<MatrixRelationField>;
  @MatrixRelationsStore.Getter
  public flattenedColumns: Array<MatrixRelationField>;
  @MatrixRelationsStore.Mutation
  public setMatrixRelationsData!: (data: MatrixRelationMatrix) => void;
  @MatrixRelationsStore.Mutation
  public setFlattenedMatrixRows!: (data: Array<MatrixRelationField>) => void;
  @MatrixRelationsStore.Mutation
  public setFlattenedMatrixColumns!: (data: Array<MatrixRelationField>) => void;
  @MatrixRelationsStore.Action
  public initEmptyMatrix: () => void;
  @MatrixRelationsStore.Action
  public fillMatrix: (data: { matrixData: MatrixRelationMatrix; rowNumber: number; columnNumber: number }) => void;
  @MatrixRelationsStore.Action
  public flattenArrayElements: (array: Array<MatrixRelationField>) => Array<MatrixRelationField>;
  @MatrixRelationsStore.Mutation
  public selectWholeColumn!: (column: MatrixRelationField) => void;
  @MatrixRelationsStore.Action
  public handleWebsocketMessage!: (response: WSMessageResponse) => void;
  @LoaderModuleStore.Getter
  isLoaderVisible: boolean;
  @MatrixRelationsStore.Mutation
  public setCheckedItemsFromList!: () => void;

  @ModulePropertiesStore.Getter
  moduleProperties: ModuleProperties;

  @ModulePropertiesStore.Action
  getModuleProperties: () => Promise<void>;

  @UiStore.Action
  showCallout!: (payload: TooltipOptions) => void;
  @UiStore.Action
  hideCallout!: () => void;

  @UserStore.Action
  isAllowed: (permission: string | string[]) => Promise<boolean>;

  @UserStore.Getter
  skipConfirmationDialog: () => boolean;

  @UserStore.Mutation
  setSkipConfirmationDialog: (value: boolean) => void;

  // because you can't access MatrixType directly in a template
  public matrixType = MatrixType;

  public rerender = false;
  public isCursorVisible = false;
  public cursorXWidth = 0;
  public cursorXTop = 0;
  public cursorXLeft = 0;
  public cursorYTop = 0;
  public cursorYHeight = 0;
  public cursorYLeft = 0;
  public loader = true;
  public theadTextHeight: number = 166;

  itemWidth: number = 0;
  itemHeight: number = 0;
  rowWidth: number = 0;
  isSidebarHidden: boolean = false;

  get pageTitle() {
    return this.moduleProperties ? this.moduleProperties?.name || this.matrixRelationsData?.name || '' : '';
  }

  get getMatrixType() {
    return this.matrixRelationsData ? this.matrixRelationsData.matrix_type : MatrixType.FIRST;
  }

  get isCursorSupported() {
    return this.getVisibleColumns?.length + this.getVisibleRows?.length <= 100;
  }

  get resizeDirection() {
    return 'vertical';
  }

  onColumnResize(event: MouseEvent) {
    this.currentCell = (this.$refs.tableHeaderColumn as HTMLTableCellElement[])[0];
    this.onCellResize(event);
  }

  async created() {
    // listen for ws messages
    eventBus.$on(EventType.MATRIX_RECIEVE_MSG, (response: WSMessageResponse) => {
      if (response.status == WebSocketMessageStatus.SUCCESS) {
        this.handleWebsocketMessage(response);
        this.rerenderItems();
      } else {
        // TODO: Handle error, show modal
      }
    });

    await this.getModuleProperties();

    const data: MatrixRelationMatrix = await api.matrix.getByUuid(this.$route.params.uuid);

    let matrixPermission;

    // Check if the user has enough permissions to access this type of matrix.
    switch (data.matrix_type) {
      case MatrixType.SECOND:
        if (this.moduleProperties.module_type === 'TaggingUserMatrixModule')
          matrixPermission = UserPermission.TAGGING_USER_MATRIX_ACCESS;
        else matrixPermission = UserPermission.TAGGING_ADMIN_MATRIX_ACCESS;
        break;
      case MatrixType.FIRST:
      default:
        matrixPermission = UserPermission.CROSS_CONNECT_MATRIX_ACCESS;
        break;
    }

    if (!(await this.isAllowed(matrixPermission))) {
      this.$router.replace('/error/403').catch(() => {
        // Dummy error handler for avoiding duplicated navigation logs.
      });
      return;
    }

    // Hide sidebar for now for all cross-connect matrices (#127).
    if (data.matrix_type == MatrixType.FIRST) {
      this.isSidebarHidden = true;

      if (data.axis_ys.length > 0) {
        const matrix = new MatrixAxis();
        matrix.children = data.axis_ys.map((item) => {
          item.title = item.name;
          return item;
        });
        data.axis_y = matrix;
      }
    }
    if (data.matrix_type == MatrixType.SECOND) {
      const matrix = new MatrixAxis();
      matrix.children = data.axis_xs.map((item) => {
        item.title = item.name;
        return item;
      });
      data.axis_x = matrix;
    }
    data.matrix = [];

    this.setMatrixRelationsData(data);

    const flattenedColumns = await this.flattenArrayElements(this.matrixRelationsData.axis_x.children);
    const flattenedRows = await this.flattenArrayElements(this.matrixRelationsData.axis_y.children);
    // set only first parent to expanded for both axis
    for (let i = 1; i < flattenedColumns.length; i++) {
      if (flattenedColumns[i].level == 0) {
        this.toogleExpand(flattenedColumns[i]);
      }
    }
    for (let i = 1; i < flattenedRows.length; i++) {
      if (flattenedRows[i].level == 0) {
        this.toogleExpand(flattenedRows[i]);
      }
    }

    this.setFlattenedMatrixRows(flattenedRows);
    this.setFlattenedMatrixColumns(flattenedColumns);

    this.fillMatrix({
      matrixData: this.matrixRelationsData,
      rowNumber: this.flattenedRows.length,
      columnNumber: this.flattenedColumns.length,
    });
    this.setCheckedItemsFromList();

    this.loader = false;

    setTimeout(() => {
      const thead: any = this.$refs.tableHead;
      const observer = new ResizeObserver(
        debounce((entries: any[]) => {
          entries.forEach((entry) => {
            const cr = entry.contentRect;
            const headerHeight = cr.height;
            this.theadTextHeight = headerHeight - 36;
          });
        }, 50),
      );
      observer.observe(thead);
    }, 500);

    setTimeout(() => {
      const tbody: any = this.$refs.tableBody;
      const observer = new ResizeObserver(
        debounce((entries: any[]) => {
          entries.forEach((entry) => {
            const tbody = entry.contentRect;
            const table: any = this.$refs.table;
            const thead: any = this.$refs.tableHead;
            const spacer: any = this.$refs.tableRowSpacer;
            if (!table || !thead || !spacer) return;
            const spacerHeight = table.offsetHeight - thead.offsetHeight - tbody.height;
            if (spacerHeight > 0) spacer.style.height = `${spacerHeight}px`;
            else spacer.style.height = '0';
          });
        }, 50),
      );
      observer.observe(tbody);
    }, 500);
  }

  get getVisibleRows() {
    return this.flattenedRows.filter((r) => r.expanded);
  }

  get getVisibleColumns() {
    return this.flattenedColumns.filter((r) => r.expanded);
  }

  get wholeColumnSelected() {
    return (columnId: number) => {
      let allChecked = true;
      for (let i = 0; i < this.matrixRelationsData.matrix.length; i++) {
        if (this.matrixRelationsData.matrix[i][columnId] !== true) {
          allChecked = false;
        }
      }
      return allChecked;
    };
  }

  get getNumberOfFirstLevelParents() {
    return (row: MatrixRelationField, column: MatrixRelationField) => {
      if (row.level != 0 && column.level != 0) {
        return 0;
      }
      if (column.level == 0 && column.isParent && (!row.isParent || row.level != 0)) {
        return 1;
      }
      if (row.level == 0 && row.isParent && (!column.isParent || column.level != 0)) {
        return 1;
      }
      if (row.isParent && column.isParent && row.level == 0 && column.level == 0) {
        return 2;
      }
      return 0;
    };
  }

  get isAllExpanded() {
    if (this.getVisibleColumns.length === 0 && this.getVisibleRows.length === 0) {
      return false;
    }
    // eslint-disable-next-line prefer-const
    let result = { isAllExpanded: true };
    const isExpandedRecursively = (field: MatrixRelationField, result: { isAllExpanded: boolean }) => {
      if (!field.expanded) {
        result.isAllExpanded = false;
        return;
      }

      if (field.children && field.children.length > 0) {
        for (const childField of field.children) {
          if (!result.isAllExpanded) {
            break;
          }
          isExpandedRecursively(childField, result);
        }
      }
    };
    for (const col of this.getVisibleColumns) {
      if (!result.isAllExpanded) {
        break;
      }
      isExpandedRecursively(col, result);
    }
    for (const row of this.getVisibleRows) {
      if (!result.isAllExpanded) {
        break;
      }
      isExpandedRecursively(row, result);
    }
    return result.isAllExpanded;
  }

  setShowCallout(columnId: string, title: string) {
    const rect = document.getElementById(columnId).getBoundingClientRect();
    if (rect.x <= window.innerWidth / 2) {
      // callout goes right
      this.showCallout({
        text: title,
        top: rect.top + 8,
        left: rect.right - 20,
        position: 'right',
      });
    } else {
      // callout goes left
      this.showCallout({
        text: title,
        top: rect.top + 8,
        right: window.innerWidth - rect.left - 22,
        position: 'left',
      });
    }
  }

  toogleExpand(column: MatrixRelationField, shouldExpand: boolean = null): void {
    const toogleExpandRecursively = (children: Array<MatrixRelationField>, value: boolean) => {
      for (const child of children) {
        child.expanded = value;
        if (child.children.length > 0) {
          toogleExpandRecursively(child.children, value);
        }
      }
    };

    if (column.children.length > 0) {
      const value = shouldExpand !== null ? shouldExpand : !column.children[0].expanded;
      toogleExpandRecursively(column.children, value);
    }
  }

  rerenderItems() {
    this.rerender = !this.rerender;
  }

  toggleExpandAll(shouldExpand: boolean) {
    for (const col of this.getVisibleColumns) {
      this.toogleExpand(col, shouldExpand);
    }
    for (const row of this.getVisibleRows) {
      this.toogleExpand(row, shouldExpand);
    }
  }

  calculateRowItemWidths() {
    const cells = document.getElementsByClassName('MatrixTable__BodyColumn--RowCell');
    this.itemWidth = ((cells[0] as never) as HTMLTableCellElement).offsetWidth;
    this.itemHeight = ((cells[0] as never) as HTMLTableCellElement).offsetHeight;

    const rows = document.getElementsByClassName('MatrixTable__BodyColumn--RowLabel');
    this.rowWidth = ((rows[0] as never) as HTMLTableRowElement).offsetWidth;

    this.cursorXLeft = this.rowWidth;
    // this.cursorYTop = 0;
  }

  showCursor(rowId: number, columnId: number) {
    if (!this.isCursorSupported) return;

    // If cursor is not yet visible, turn it on and calculate first row/item widths.
    //   Do this only for the first time, in order to improve overall cursor performance.
    //   More info here: https://gitlab.com/eon-plus/envigo/v2/app/-/issues/110
    if (!this.isCursorVisible) {
      this.isCursorVisible = true;
      this.calculateRowItemWidths();
    }

    const numOfVisibleRowsBeforeElement = this.getVisibleRows.filter((r) => r.id < rowId).length;
    const numOfVisibleColumnsBeforeElement = this.getVisibleColumns.filter((c) => c.id < columnId).length;

    this.cursorXWidth = numOfVisibleColumnsBeforeElement * this.itemWidth + this.itemWidth / 2;
    this.cursorXTop = numOfVisibleRowsBeforeElement * this.itemHeight + this.itemHeight / 2;
    this.cursorYHeight = numOfVisibleRowsBeforeElement * this.itemHeight + this.itemHeight / 2 + 1;
    this.cursorYLeft = this.rowWidth + numOfVisibleColumnsBeforeElement * this.itemWidth + this.itemWidth / 2;
  }

  hideCursor() {
    if (this.isCursorVisible) this.isCursorVisible = false;
  }

  selectColumn(column: MatrixRelationField) {
    if (!this.skipConfirmationDialog) {
      const confirmCallback = (context: { checkboxValue: boolean }) => {
        this.setSkipConfirmationDialog(context.checkboxValue);
        this.selectWholeColumn(column);
        this.rerenderItems();
      };

      DialogService.presentDialog(
        `Are you sure you want to select / deselect all?`,
        'This action will immediately update the existing list of items in the following modules.',
        'Yes',
        'Cancel',
        confirmCallback,
        () => {
          //
        },
        'Do not show me this again',
      );
      return;
    }

    this.selectWholeColumn(column);
    this.rerenderItems();
  }

  async onResume() {
    await api.matrix.resume(this.$route.params.uuid).then(() => {
      this.$router.go(-1);
    });
    return;
  }
}
