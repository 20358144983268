













import { TooltipOptions } from '@/app/shared/store/modules/ui.store-module';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const UiStore = namespace('UI');
@Component({
  components: {},
})
export default class BaseCallout extends Vue {
  @UiStore.Getter
  public calloutOptions!: TooltipOptions;
  get options() {
    return this.calloutOptions;
  }
}
