// import strength from './password-policy/password-strength';

export default (fieldValue: string): boolean => {
  // Will be handled by the required validator.
  if (fieldValue === undefined || fieldValue === null || !fieldValue.length) return true;

  // Uses 8 or more characters.
  if (fieldValue.length < 8) return false;

  // Uses at least 2 uppercase letters.
  if (!/[A-Z].*[A-Z]/.test(fieldValue)) return false;

  // Uses at least 2 numbers.
  if (!/\d.*\d/.test(fieldValue)) return false;

  // Uses at least 2 special characters.
  if (!/[^a-zA-Z0-9].*[^a-zA-Z0-9]/.test(fieldValue)) return false;

  // Uses at least 2 non-letter characters.
  if (!/[^a-zA-Z].*[^a-zA-Z]/.test(fieldValue)) return false;

  // NB: Disabled strength check based on password entropy for now, due to conflicts with other policy rules.
  // // Has strength value of more than 0.7.
  // if (strength(fieldValue) <= 0.7) return false;

  return true;
};
