










































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import { namespace } from 'vuex-class';
import { PresentationsTabType } from '@/app/shared/types/presentations.types';
import { PresentationsChartsType } from '@/app/shared/types/presentations.types';
import { CheckTree, CheckTreeSelection } from '@/app/shared/models/CheckTree';
import SimpleBarPlot from './SimpleBarPlot.vue';
import GroupedBarPlot from './GroupedBarPlot.vue';
import StackedBarPlot from './StackedBarPlot.vue';
import StepLinePlot from './StepLinePlot.vue';
import SimplePieChart from './SimplePieChart.vue';
import MultiplesPieCharts from './MultiplesPieCharts.vue';
import PresentationsBottomActionBar from './../PresentationsBottomActionBar.vue';
import api from '@/app/shared/api';
import { PresentationsSidebarOption } from '@/app/shared/models/presentations/PresentationsSidebarOption';
import { PRESENTATIONS_SIDEBAR_OPTIONS } from '@/app/shared/store/modules/presentations.constants';
import { PresentationsSidebarOptionGroupEnum } from '@/app/shared/enums/presentations-sidebar-option-group.enum';
import { PresentationsSidebarOptionItemEnum } from '@/app/shared/enums/presentations-sidebar-option-item.enum';
import { PresentationsSidebarOptionSelection } from '@/app/shared/models/presentations/PresentationsSidebarOption';
import {
  ChartDataResponse,
  ChartDataset,
  ChartDatasetItem,
  PieChartData,
} from '@/app/shared/models/presentations/Charts';
import { ModuleChecktree, ModuleProperties } from '@/app/shared/models/Module';
import { LibraryParagraph } from '@/app/shared/models/presentations/LibraryParagraph';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import { Study } from '@/app/shared/models/Study';

const LibraryStore = namespace('Library');
const ModulePropertiesStore = namespace('ModuleProperties');
const PresentationsStore = namespace('Presentations');

@Component({
  components: {
    SimpleBarPlot,
    GroupedBarPlot,
    StackedBarPlot,
    StepLinePlot,
    SimplePieChart,
    MultiplesPieCharts,
    PresentationsBottomActionBar,
  },
})
export default class PresentationsCharts extends Vue {
  chartTypes: Array<{ title: string; type: PresentationsChartsType; icon: string }> = [];
  chartData: ChartDataResponse = null;
  chartFigureTitle: string = null;
  titleInEditMode: boolean = false;
  chartBase64Images: string[] = [];

  style: any = {
    titleWrapper: {
      display: 'flex',
      marginBottom: '38px',
    },
    tableTitleNumeration: {
      height: '32px',
      fontSize: '24px',
      lineHeight: '32px',
      display: 'flex',
      alignItems: 'center',
    },
    numeration: {
      marginRight: '0.75rem',
      position: 'relative',
      fontWeight: '500',
    },
    tableTitle: {
      flex: 1,
      position: 'relative',
      textAlign: 'left',
    },
    tableTitleSpan: { fontWeight: '500', color: '#222c37' },
  };

  @LibraryStore.State
  study: Study;

  @ModulePropertiesStore.Getter
  moduleProperties: ModuleProperties;

  @PresentationsStore.State
  readonly moduleChecktrees: ModuleChecktree[];

  @PresentationsStore.State
  readonly sidebarChecktrees: CheckTree[];

  @PresentationsStore.State
  readonly activeChartType: PresentationsChartsType;

  @PresentationsStore.State
  readonly activeChartTitle: string;

  @PresentationsStore.Getter
  readonly sidebarOptions: PresentationsSidebarOption[];

  @PresentationsStore.Mutation
  setActiveChartType: (type: PresentationsChartsType) => void;

  @PresentationsStore.Mutation
  clearSidebarSelectionMutation: () => void;

  @PresentationsStore.Mutation
  updateSidebarOptions: (selectedOptions: PresentationsSidebarOption[]) => void;

  @PresentationsStore.Action
  addLibraryParagraphRaw: (value: LibraryParagraph) => Promise<void>;

  @PresentationsStore.State
  activeTab: PresentationsTabType;

  @PresentationsStore.State
  typesOfMeans: any;

  @Watch('sidebarChecktrees', { deep: true })
  @Watch('sidebarOptions', { deep: true })
  onSidebarChange() {
    if (!this.activeChartType || !this.isActiveChartTypeEnabled) return;
    this.getChartData();
  }

  @Watch('activeChartTitle')
  onActiveChartTitleChange(activeChartTitle: string) {
    this.chartFigureTitle = activeChartTitle;
  }

  get checktreeSelection() {
    return this.sidebarChecktrees.reduce(
      (checktreeSelection, checktree) =>
        [
          ...checktreeSelection,
          {
            id: checktree.id,
            uuid: checktree.uuid,
            children: checktree.children?.map((checktreeItem) => ({
              id: checktreeItem.id,
              uuid: checktreeItem.uuid,
              checked: checktreeItem.checked,
            })),
          },
        ] as CheckTreeSelection[],
      [],
    );
  }

  get options() {
    return this.sidebarOptions.reduce((options, option) => {
      let value: string = option.value;

      // Map certain option values to checktree UUIDs.
      switch (value) {
        case PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_ALTERNATIVES:
        case PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_ALTERNATIVES:
          value = this.alternativesChecktree.uuid;
          break;
        case PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_DEV_PHASES:
        case PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_DEV_PHASES:
          value = this.developmentPhasesChecktree.uuid;
          break;
        case PresentationsSidebarOptionItemEnum.CHARTS_GROUPED_FOR_RECEPTORS:
        case PresentationsSidebarOptionItemEnum.CHARTS_STACKED_FOR_RECEPTORS:
          value = this.receptorsChecktree.uuid;
          break;
      }

      return {
        ...options,
        [option.type]: value,
      };
    }, {} as PresentationsSidebarOptionSelection);
  }

  get simpleBarPlotBars() {
    return (this.chartData?.data as ChartDatasetItem[]) || [];
  }

  get groupedBarPlotBars() {
    return (this.chartData?.data as ChartDataset[]) || [];
  }

  get groupedBarPlotLabels() {
    return this.chartData?.labels || [];
  }

  get stackedBarPlotBars() {
    return (this.chartData?.data as ChartDataset[]) || [];
  }

  get stackedBarPlotLabels() {
    return this.chartData?.labels || [];
  }

  get multiplePieChartsData() {
    return (this.chartData?.data as PieChartData[]) || [];
  }

  get isActiveChartTypeEnabled() {
    return !this.disabledChartTypes.includes(this.activeChartType);
  }

  get isOneOrMoreChecktreeItemsSelected() {
    return this.sidebarChecktrees?.some(
      (checktree) =>
        checktree.uuid !== this.methodApproachChecktree?.uuid && checktree.children.some((child) => child.checked),
    );
  }

  get isOneOrMoreChecktreeItemsSelectedInAll() {
    return (
      this.sidebarChecktrees?.filter(
        (checktree) =>
          checktree.uuid !== this.methodApproachChecktree?.uuid && checktree.children.some((child) => child.checked),
      )?.length === 3
    );
  }

  get alternativesChecktree() {
    return this.findChecktree('1e081f51-f039-4861-9a6b-2d166ff8cdf9');
  }

  get developmentPhasesChecktree() {
    return this.findChecktree('0ce1618d-79c8-4a42-93da-78d91c6b5c5f');
  }

  get methodApproachChecktree() {
    return this.findChecktree('5633869e-71a9-4d93-911c-571210595604');
  }

  get receptorsChecktree() {
    return this.findChecktree('5354bf82-804c-490f-8457-b3b93d299f17');
  }

  get isMethodApproachSelected() {
    return this.methodApproachChecktree?.children.some((child) => child.checked);
  }

  get hasNoGroupedForOptionSelected() {
    return (
      this.findOption(PresentationsSidebarOptionGroupEnum.CHARTS_GROUPED_FOR)?.value ===
      PresentationsSidebarOptionItemEnum.NONE
    );
  }

  get hasNoStackedForOptionSelected() {
    return (
      this.findOption(PresentationsSidebarOptionGroupEnum.CHARTS_STACKED_FOR)?.value ===
      PresentationsSidebarOptionItemEnum.NONE
    );
  }

  get hasPerNumberOfSelectedCategoriesOptionSelected() {
    return (
      this.findOption(PresentationsSidebarOptionGroupEnum.CHARTS_NORMALIZATION)?.value ===
      PresentationsSidebarOptionItemEnum.CHARTS_NORMAL_PER_NUMBER_OF_SELECTED_CATEGORIES
    );
  }

  get disabledChartTypes(): PresentationsChartsType[] {
    const result: PresentationsChartsType[] = [];

    // BAR - SIMPLE
    if (
      !this.isOneOrMoreChecktreeItemsSelected ||
      !this.isMethodApproachSelected ||
      !this.hasNoGroupedForOptionSelected ||
      !this.hasNoStackedForOptionSelected
    )
      result.push('bar-simple');

    // BAR - GROUPED
    if (
      !this.isOneOrMoreChecktreeItemsSelectedInAll ||
      !this.isMethodApproachSelected ||
      this.hasNoGroupedForOptionSelected ||
      !this.hasNoStackedForOptionSelected
    )
      result.push('bar-grouped');

    // BAR - GROUPED AND STACKED
    if (
      !this.isOneOrMoreChecktreeItemsSelectedInAll ||
      !this.isMethodApproachSelected ||
      this.hasNoGroupedForOptionSelected ||
      this.hasNoStackedForOptionSelected
    )
      result.push('bar-stacked');

    // PIE - SINGLE
    if (
      !this.isOneOrMoreChecktreeItemsSelected ||
      !this.isMethodApproachSelected ||
      !this.hasPerNumberOfSelectedCategoriesOptionSelected ||
      !this.hasNoGroupedForOptionSelected ||
      !this.hasNoStackedForOptionSelected
    )
      result.push('pie');

    // PIE - MULTIPLE
    if (
      !this.isOneOrMoreChecktreeItemsSelectedInAll ||
      !this.isMethodApproachSelected ||
      !this.hasPerNumberOfSelectedCategoriesOptionSelected ||
      this.hasNoGroupedForOptionSelected ||
      !this.hasNoStackedForOptionSelected
    )
      result.push('pie-multiple');

    // LINE  - MULTIPLE
    if (
      !this.isOneOrMoreChecktreeItemsSelectedInAll ||
      !this.isMethodApproachSelected ||
      this.hasNoGroupedForOptionSelected ||
      !this.hasNoStackedForOptionSelected
    )
      result.push('line');

    return result;
  }

  created() {
    this.initializeChartTypes();
  }

  findChecktree(uuid: string): CheckTree {
    const moduleChecktree = this.moduleChecktrees?.find(
      (moduleChecktree) =>
        (moduleChecktree.source_uuid && moduleChecktree.source_uuid === uuid) || moduleChecktree.uuid === uuid,
    );
    return this.sidebarChecktrees?.find((checktree) => checktree.uuid === moduleChecktree?.uuid);
  }

  findOption(type: PresentationsSidebarOptionGroupEnum): PresentationsSidebarOption {
    return this.sidebarOptions?.find((option) => option.type === type);
  }

  initializeChartTitle() {
    switch (this.activeChartType) {
      case 'bar-simple':
        this.chartFigureTitle = 'Impact evaluation scores in selected category';
        break;
      case 'bar-grouped':
        this.chartFigureTitle = 'Impact evaluation scores divided in groups';
        break;
      case 'bar-stacked':
        this.chartFigureTitle = 'Impact evaluation scores divided in groups, and with stacked bars';
        break;
      case 'pie':
        this.chartFigureTitle = 'Impact evaluation scores distribution for selected category';
        break;
      case 'pie-multiple':
        this.chartFigureTitle = 'Impact evaluation scores distribution for different categories';
        break;
      case 'line':
        this.chartFigureTitle = 'Comparison of impact scores between different categories';
        break;
      default:
        this.chartFigureTitle = '';
    }
  }

  async changeActiveChartType(chartType: PresentationsChartsType) {
    this.setActiveChartType(chartType);
    this.initializeChartTitle();
    this.getChartData();
  }

  async getChartData() {
    try {
      this.chartData = await api.presentations.getChartData({
        checktreeSelection: this.checktreeSelection,
        chartType: this.activeChartType,
        options: this.options,
        module: this.moduleProperties,
      });
    } catch (error) {
      console.warn(error);
    }
  }

  onTitleWrapperClick(_event: any) {
    this.titleInEditMode = true;
    const chartTitleWrapper = this.$refs.chartTitle as HTMLElement;
    setTimeout(() => {
      chartTitleWrapper.querySelector('input').focus();
    }, 0);
  }

  onChartUpdate(base64Images: string[]) {
    this.chartBase64Images = base64Images;
  }

  initializeChartTypes() {
    if (!this.typesOfMeans || !this.typesOfMeans[this.activeTab]) return;
    this.typesOfMeans[this.activeTab].forEach((item: any, index: any) => {
      this.chartTypes.push({ title: item, type: item, icon: item });

      // @todo This should be done through the API, but from now on...
      if (this.chartTypes[index].type == 'bar-simple') this.chartTypes[index].title = 'simple bar plot';
      if (this.chartTypes[index].type == 'bar-grouped') this.chartTypes[index].title = 'grouped bar plot';
      if (this.chartTypes[index].type == 'bar-stacked') this.chartTypes[index].title = 'stacked & grouped';
      if (this.chartTypes[index].type == 'line') this.chartTypes[index].title = 'step line plot';
      if (this.chartTypes[index].type == 'pie') this.chartTypes[index].title = 'simple pie chart';
      if (this.chartTypes[index].type == 'pie-multiple') this.chartTypes[index].title = 'multiples pie charts';
    });
  }

  async addToLibrary() {
    try {
      const uploadedFileIds = [];

      for (let i = 0; i < this.chartBase64Images.length; i++) {
        const chartBase64Image = this.chartBase64Images[i];
        const uploadedFileResponse = await api.file.uploadFile('chart', chartBase64Image, 'image/png');
        if (uploadedFileResponse.status !== 'success') throw new Error('Upload failed');
        uploadedFileIds.push(uploadedFileResponse.id);
      }

      const libraryParagraph = new LibraryParagraph(ChapterItemType.LIBRARY_ENVIGO_CHART);
      libraryParagraph.title = this.chartFigureTitle;
      libraryParagraph.itemFileIds = uploadedFileIds;
      libraryParagraph.itemPayload = {
        checktreeSelection: this.checktreeSelection,
        chartType: this.activeChartType,
        options: this.options,
        module: this.moduleProperties,
      };
      libraryParagraph.itemData = this.chartData;

      await this.addLibraryParagraphRaw(libraryParagraph);
    } catch (error) {
      console.warn(error);
    }
  }

  clear() {
    this.chartBase64Images = [];
    this.setActiveChartType(null);
    this.initializeChartTitle();
    this.clearSidebarSelectionMutation();
    this.updateSidebarOptions(cloneDeep(PRESENTATIONS_SIDEBAR_OPTIONS.charts));
  }
}
