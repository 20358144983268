















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseRadioButtonGroup extends Vue {
  @Prop() options: Array<{ label: string; value: any; disabled?: boolean }>;
  @Prop() value: { label: string; value: any };
  @Prop() deselectEnabled?: boolean;
}
