


















import { Component, Emit, Mixins, Vue, Watch } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import RoleButton from '@/app/shared/components/form/field/components/RoleButton.vue';
import FormFieldFocus from '@/app/shared/components/form/field/mixins/FormFieldFocus.vue';
import FormFieldSubmit from '@/app/shared/components/form/field/mixins/FormFieldSubmit.vue';

@Component({
  components: {
    RoleButton,
  },
})
export default class FormFieldRole extends Mixins(Vue, FormFieldFocus, FormFieldSubmit, BaseControlSkeleton) {
  value: string;
  userRole: string = null;

  @Emit()
  change(_value: string) {
    //
  }

  @Watch('userRole')
  onUserRoleChanged(value: string) {
    if (this.value !== value) {
      this.change(value);
    }
  }

  created() {
    this.userRole = this.value || null;
  }

  getRoleIcon(value: string) {
    switch (value) {
      case 'expert':
      case 'participant':
      case 'reviewer':
        return value;
      default:
        return 'team';
    }
  }

  focus() {
    const [firstButton] = this.$refs.buttons as (Vue & { focus: () => void })[];
    if (firstButton) firstButton.focus();
  }
}
