import { PresentationsModule } from './modules/presentations.store-module';
import { CalculationsModule } from './modules/calculations.store-module';
import ChecklistsModule from '@/app/shared/store/modules/checklists.store-module';
import CommentsModule from '@/app/shared/store/modules/comments.store-module';
import TrackingModule from '@/app/shared/store/modules/tracking.store-module';
import VersioningModule from '@/app/shared/store/modules/versioning.store-module';
import LoaderModule from '@/app/shared/store/modules/loader.store-module';
import UserModule from '@/app/shared/store/modules/user.store-module';
import MatrixRelationsModule from '@/app/shared/store/modules/matrix-relations.store-module';
import ModulePropertiesModule from '@/app/shared/store/modules/module-properties.store-module';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import createMutationsSharer from 'vuex-shared-mutations';
import DialogModule from './modules/dialog.store-module';
import UiModule from './modules/ui.store-module';
import { ReportsModule } from '@/app/shared/store/modules/reports.store-module';
import { LibraryModule } from '@/app/shared/store/modules/library.store-module';
import { WorkspaceModule } from '@/app/shared/store/modules/workspace.store-module';
import { WorkflowModule } from '@/app/shared/store/modules/workflow.store-module';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    User: UserModule,
    Checklists: ChecklistsModule,
    Comments: CommentsModule,
    Tracking: TrackingModule,
    Versioning: VersioningModule,
    MatrixRelations: MatrixRelationsModule,
    ModuleProperties: ModulePropertiesModule,
    Loader: LoaderModule,
    Dialog: DialogModule,
    UI: UiModule,
    Reports: ReportsModule,
    Library: LibraryModule,
    Workspace: WorkspaceModule,
    Workflow: WorkflowModule,
    Calculations: CalculationsModule,
    Presentations: PresentationsModule,
  },

  plugins: [
    // Persist user access token state in the browser local storage.
    createPersistedState({ key: 'store', paths: ['User.accessToken'] }),

    // Register the mutation sharer plugin so persistent states across different tabs would be synced.
    createMutationsSharer({ predicate: ['User/setAccessToken'] }),
  ],
});

export default store;
