import { Study, StudyTableDetails, StudyTableRow } from './../../models/Study';
import httpClient from '@/app/shared/api/axios.config';
import { NewApiResponse } from '../../models/NewApiResponse';
import { TaskApiResponse } from '../../models/TaskApiResponse';
import { Authority } from '../../models/form/field/FormFieldAuthority';
import { AssessmentTeam } from '../../models/form/field/FormFieldAssessmentTeam';
import { Assessor } from '../../models/form/field/FormFieldAssessor';
import { Event } from '../../models/form/field/FormFieldEvent';
import { Facility } from '../../models/form/field/FormFieldFacility';
import { Investor } from '../../models/form/field/FormFieldInvestor';
import { Location } from '../../models/form/field/FormFieldLocation';
import { FormFieldValue, FormFieldValueModel } from '../../models/form/field';

const STUDY_ENDPOINT = '/study';

const transformRelatedObjects = (study: Study): Study => {
  if (study.facilities_objects && study.facilities_objects.length) {
    study.facilities = new FormFieldValue<Facility>(study.facilities_objects, FormFieldValueModel.FACILITY);
    delete study.facilities_objects;
  }

  if (study.locations_objects && study.locations_objects.length) {
    study.locations_objects.forEach((location) => {
      if (!location.facility_id) location.facility_id = [];
      if (location.facilities) {
        location.facilities.forEach((facility) => {
          location.facility_id.push(facility.facility_id);
        });
        delete location.facilities;
      }
    });
    study.locations = new FormFieldValue<Location>(study.locations_objects, FormFieldValueModel.LOCATION);
    delete study.locations_objects;
  }

  if (study.assessments_objects && study.assessments_objects.length) {
    study.assessments_objects.forEach((assessment) => {
      if (!assessment.user_id) assessment.user_id = [];
      if (assessment.persons) {
        assessment.persons.forEach((person) => {
          assessment.user_id.push(person.person_id);
        });
        delete assessment.persons;
      }
    });
    study.assessments = new FormFieldValue<AssessmentTeam>(
      study.assessments_objects,
      FormFieldValueModel.ASSESSMENT_TEAM,
    );
    delete study.assessments_objects;
  }

  if (study.study_review_objects && study.study_review_objects.length) {
    study.study_review_objects.forEach((studyReview) => {
      // TODO: dev only
      studyReview.events.forEach((studyEvent) => {
        if (studyEvent.user.email.startsWith('admin')) studyEvent.is_admin = true;
      });

      if (!study.study_reviews) study.study_reviews = [];
      study.study_reviews.push({
        ...studyReview,
        events: new FormFieldValue<Event>(studyReview.events, FormFieldValueModel.EVENT),
      });
    });
    delete study.study_review_objects;
  }

  if (!study.related_objects) return study;

  study.related_objects.forEach((relatedObject) => {
    switch (relatedObject.type) {
      case 'Assessor': {
        if (!study.assessors)
          study.assessors = new FormFieldValue<Assessor>([relatedObject], FormFieldValueModel.ASSESSOR);
        else study.assessors.data.push(relatedObject);

        break;
      }
      case 'Authority': {
        if (!study.authorities)
          study.authorities = new FormFieldValue<Authority>([relatedObject], FormFieldValueModel.AUTHORITY);
        else study.authorities.data.push(relatedObject);

        break;
      }
      case 'Investor': {
        if (!study.investors)
          study.investors = new FormFieldValue<Investor>([relatedObject], FormFieldValueModel.INVESTOR);
        else study.investors.data.push(relatedObject);

        break;
      }
    }
  });

  delete study.related_objects;

  return study;
};

export default {
  async createStudy(organizationUuid: string): Promise<Study> {
    const res: NewApiResponse<Study> = await httpClient.put(`${STUDY_ENDPOINT}/`, {
      organization_uuid: organizationUuid,
    });
    if (res.status !== 'success') throw new Error(res.status);
    return transformRelatedObjects(res.data);
  },

  async saveStudy(uuid: string, data: any): Promise<Study> {
    const res: NewApiResponse<Study> = await httpClient.post(`${STUDY_ENDPOINT}/${uuid}`, data);
    if (res.status !== 'success') throw new Error(res.status);
    return transformRelatedObjects(res.data);
  },

  async copyStudy(uuid: string): Promise<TaskApiResponse> {
    return await httpClient.post(`${STUDY_ENDPOINT}/${uuid}/copy`);
  },

  async getStudy(uuid: string): Promise<Study> {
    const res: Study = await httpClient.get(`${STUDY_ENDPOINT}/${uuid}`);
    return transformRelatedObjects(res);
  },

  async getStudies(): Promise<Study[]> {
    const data: Study[] = await httpClient.get(`${STUDY_ENDPOINT}/`);
    return data;
  },

  async getStudyTableRows(): Promise<StudyTableRow[]> {
    return await httpClient.get('/studies');
  },

  async getStudyTableDetails(studyUuid: string): Promise<StudyTableDetails> {
    return await httpClient.get(`/studies/${studyUuid}`);
  },

  async buildStudy(uuid: string): Promise<TaskApiResponse> {
    return await httpClient.post(`${STUDY_ENDPOINT}/${uuid}/build`);
  },

  async saveStudyEvents(uuid: string, data: any): Promise<boolean> {
    const res: NewApiResponse<any> = await httpClient.post(`${STUDY_ENDPOINT}/${uuid}/events`, data);
    if (res.status !== 'success') throw new Error(res.status);
    return true;
  },
};
