







































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import UserAvatar from '@/app/shared/components/UserAvatar.vue';
// import weekday from 'dayjs/plugin/weekday';
import { TrackingActivity, TrackingActivityType } from '@/app/shared/models/Tracking';
import { User } from '@/app/shared/models/User';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
// dayjs.extend(weekday);

const UserStore = namespace('User');

@Component({
  components: {
    UserAvatar,
  },
})
export default class TrackingItemActivity extends Vue {
  @Prop() activity: TrackingActivity[];
  dateFormat = 'YYYY-MM-DD';
  timeFormat = 'HH:mm';

  @UserStore.Getter
  userUuid: string;

  get activityHistoryByDates() {
    return this.activity
      .sort((a, b) => {
        const aDayObj = dayjs.utc(a.created);
        const bDayObj = dayjs.utc(b.created);
        if (aDayObj > bDayObj) return -1;
        if (aDayObj < bDayObj) return 1;
        return 0;
      })
      .reduce((activityHistoryByDates, activity) => {
        const date = dayjs
          .utc(activity.created)
          .local()
          .format(this.dateFormat);
        return {
          ...activityHistoryByDates,
          [date]: [...((activityHistoryByDates[date as never] as TrackingActivity[]) || []), activity],
        };
      }, {} as Record<string, TrackingActivity[]>);
  }

  getDateLabel(dateTimestamp: string) {
    const now = dayjs();
    const date = dayjs(dateTimestamp, this.dateFormat);
    if (now.year() > date.year()) return date.format('D MMMM YYYY');
    return date.format('dddd, D MMMM');
    // if (now.diff(date, 'day') > 1) return date.format('dddd, D MMMM');
    // if (date.isYesterday()) return 'Yesterday';
    // return 'Today';
  }

  getTimeLabel(timestamp: string) {
    return dayjs
      .utc(timestamp)
      .local()
      .format(this.timeFormat);
  }

  getTypeLabel(type: TrackingActivityType) {
    switch (type) {
      case 'add':
        return 'Added';
      case 'assess':
        return 'Assessed';
      case 'check':
        return 'Checked';
      case 'edit':
        return 'Edited';
      case 'reorder':
        return 'Reordered';
      case 'resume':
        return 'Resumed';
    }
  }

  getUserName(user: User) {
    return user.uuid === this.userUuid ? 'You' : `${user.first_name} ${user.last_name}`;
  }
}
