



















import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RoleButton extends Vue {
  @Model('select') readonly value!: string;
  @Prop() modelValue: string;
  @Prop() icon: string;
  @Prop() label: string;

  @Emit()
  select(_value: string) {
    //
  }

  @Emit()
  submit() {
    //
  }

  get isSelected() {
    return this.value === this.modelValue;
  }

  onClick() {
    this.select(this.modelValue);
  }

  focus() {
    if (this.$refs.button) (this.$refs.button as Vue & { $el: HTMLElement & { focus: () => void } }).$el.focus();
  }
}
