// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/backgrounds/background-landing.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/img/backgrounds/background-landing@2x.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".landing-page{z-index:0;position:relative;height:calc(100vh - var(--app-bar-height) - var(--footer-height));background-image:-webkit-image-set(url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 1x,url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 2x);background-image:image-set(url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 1x,url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 2x);background-repeat:no-repeat;background-position:50%;background-size:cover;color:var(--v-white)}.landing-page__overlay{z-index:-1;background:linear-gradient(0deg,var(--v-green-approve-status-solid),var(--v-blue-envigo));width:100%;position:absolute;top:0;bottom:0;opacity:.8}.landing-page__fixed-height{flex-basis:88px}.landing-page__skip-checkbox .box-inner{border-color:var(--v-white)!important}.landing-page__skip-checkbox.checked .box-inner{background-color:var(--v-white)!important}.landing-page__skip-checkbox.checked .v-icon #Selection-Controls\\/1\\.-Checkboxes\\/1\\.-Selected\\/A\\.-Enabled{fill:var(--v-blue-primary)!important}.landing-page__skip-checkbox .checkbox-label{color:var(--v-white)!important;font-size:14px;line-height:18px;letter-spacing:.25px}", ""]);
// Exports
module.exports = exports;
