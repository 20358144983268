




























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import InlineTable from '@/app/shared/components/table/InlineTable.vue';
import ListTable from '@/app/shared/components/table/ListTable.vue';
import PresentationTable from '@/app/shared/components/table/PresentationTable.vue';
import ResizableTable from '@/app/shared/components/table/ResizableTable.vue';
import TextTable from '@/app/shared/components/table/TextTable.vue';
import { Table } from '@/app/shared/models/Table';

@Component({
  components: {
    InlineTable,
    ListTable,
    PresentationTable,
    ResizableTable,
    TextTable,
  },
})
export default class DashboardDataTableExpandedRow extends Vue {
  @Prop() item: any;
  @Prop() details: Record<string, Table[][]>;
  @Prop() extraPane: Record<string, Table>;
  currentPane: number = 0;

  @Emit('action-click')
  onActionClick(_type: string, _item: any) {
    //
  }

  get panes() {
    if (!this.details || !this.details[this.item.uuid]) return [];
    return this.details[this.item.uuid];
  }

  get extraTable() {
    if (!this.extraPane || !this.extraPane[this.item.uuid]) return null;
    return this.extraPane[this.item.uuid];
  }

  get tableType() {
    return {
      inline: 'InlineTable',
      list: 'ListTable',
      presentation: 'PresentationTable',
      text: 'TextTable',
      resizable: 'ResizableTable',
    };
  }

  get isFirstPane() {
    return this.currentPane - 1 < 0;
  }

  get isLastPane() {
    return this.currentPane + 1 === this.panes.length;
  }

  next() {
    this.currentPane = this.currentPane + 1 === this.panes.length ? 0 : this.currentPane + 1;
  }

  prev() {
    this.currentPane = this.currentPane - 1 < 0 ? this.panes.length - 1 : this.currentPane - 1;
  }

  collapse() {
    this.item.is_expanded = false;
  }
}
