var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reports-wrapper",class:{
    'navigator-expanded': !_vm.singleImpactView && !_vm.navigatorCollapsed,
    'navigator-collapsed': !_vm.singleImpactView && _vm.navigatorCollapsed,
    'single-impact-view': _vm.singleImpactView,
  }},[(!_vm.singleImpactView && _vm.getChapters && _vm.getChapters.length > 0 && _vm.getCurrentChapter)?_c('ReportsNavigator',{attrs:{"htu":_vm.htu,"active-chapter":_vm.getCurrentChapter,"chapters":_vm.getChapters,"visited-chapters":_vm.visitedChapterUuids},on:{"toggle-collapse":_vm.toggleNavigatorCollapse}}):_vm._e(),(_vm.isPageHeaderVisible)?_c('ReportsPageHeader',{attrs:{"htu":_vm.htu,"title":_vm.pageHeaderTitle,"is-all-expanded":_vm.areAllParagraphsExpandedInContent,"is-edit-mode-allowed":_vm.isEditModeAllowed},on:{"toggle-expand-all":_vm.toggleExpandAll,"resume":_vm.onResume,"downloadChapter":_vm.onDownloadChapter,"downloadAll":_vm.onDownloadAll}}):_vm._e(),(_vm.getCurrentChapter || _vm.getSingleImpact)?_c('ReportsSidebar',{attrs:{"singleImpactView":_vm.singleImpactView,"is-edit-mode-allowed":_vm.isEditModeAllowed}}):_vm._e(),_c('div',{staticClass:"content-wrapper"},[(!_vm.isLoading)?_c('div',{staticClass:"content",attrs:{"id":"report-contents"}},[_c('div',{staticClass:"paper-bg"},[(_vm.singleImpactView)?[_c('SingleImpactHeader',{attrs:{"single-impact":_vm.getSingleImpact,"item":_vm.paragraphs[0],"is-edit-mode-allowed":_vm.isEditModeAllowed}}),_vm._l((_vm.singleImpactParagraphs),function(item,index){return _c('ChapterItemComponent',{key:item.id,attrs:{"item":item,"index":index,"locked":item.locked_by ? true : false,"is-edit-mode-allowed":_vm.isEditModeAllowed,"is-delete-button-allowed":_vm.isDeleteButtonAllowed}})})]:_vm._l((_vm.paragraphs),function(item,index){return _c('ChapterItemComponent',{key:item.id,attrs:{"item":item,"index":index,"locked":item.locked_by ? true : false,"is-edit-mode-allowed":_vm.isEditModeAllowed,"is-delete-button-allowed":_vm.isDeleteButtonAllowed}})})],2),(_vm.singleImpactView)?_c('SingleImpactNavigationBar'):_vm._e()],1):_vm._e()]),(!_vm.htu)?_c('Library',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFooterLibraryActive),expression:"isFooterLibraryActive"}],class:[
      { 'library-top-navigator-expanded': !_vm.navigatorCollapsed && !_vm.singleImpactView },
      { 'library-top-navigator-collapsed': _vm.navigatorCollapsed && !_vm.singleImpactView },
      { 'library-top-navigator-expanded-single-impact': _vm.singleImpactView } ],attrs:{"study-uuid":_vm.studyUuid,"default-width":_vm.defaultLibraryWidth}}):_vm._e(),(_vm.isFooterCommentingActive && !_vm.htu)?_c('Commenting',{ref:"commenting",class:[
      { 'commenting-top-navigator-expanded': !_vm.navigatorCollapsed },
      { 'commenting-top-navigator-collapsed': _vm.navigatorCollapsed } ],attrs:{"default-width":_vm.defaultCommentingWidth}}):_vm._e(),(_vm.isFooterTrackingActive && !_vm.htu)?_c('Tracking',{ref:"tracking",class:[
      { 'tracking-top-navigator-expanded': !_vm.navigatorCollapsed },
      { 'tracking-top-navigator-collapsed': _vm.navigatorCollapsed } ],attrs:{"default-width":_vm.defaultTrackingWidth}}):_vm._e(),_c('PageFooter')],1)}
var staticRenderFns = []

export { render, staticRenderFns }