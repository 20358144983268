import { render, staticRenderFns } from "./PageFooter.vue?vue&type=template&id=3d2c61d8&scoped=true&"
import script from "./PageFooter.vue?vue&type=script&lang=ts&"
export * from "./PageFooter.vue?vue&type=script&lang=ts&"
import style0 from "./PageFooter.vue?vue&type=style&index=0&id=3d2c61d8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d2c61d8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VFooter,VIcon})
