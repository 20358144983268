import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import api from '@/app/shared/api';
import { UnitAlternativePhase } from '@/app/shared/models/calculations/UnitAlternative';
import { AssessData } from '@/app/shared/models/calculations/AssessData';
import { UnitScore } from '@/app/shared/models/calculations/UnitScore';
import { UnitFactor } from '@/app/shared/models/calculations/UnitFactor';
import { FormulateData } from '@/app/shared/models/calculations/FormulateData';
import { UnitList } from '@/app/shared/models/calculations/UnitList';
import CheckTreeItem from '@/app/shared/models/CheckTreeItem';
import { ModuleProperties } from '@/app/shared/models/Module';
import { CheckTree } from '@/app/shared/models/CheckTree';

export interface ToggleGroupedTagsSelectPayload {
  unitUuid: string;
  groupedTagsKey: string;
  checktreeItemUuid: string;
  value: boolean;
}

export interface UpdateFactorPayload {
  unitUuid: string;
  factorUuid: string;
  weight: number;
}

export interface UpdateFormulaPayload {
  unitUuid: string;
  formulaUuid: string;
  expression: string;
  default?: boolean;
}

export interface UpdateScoreRangePayload {
  unitUuid: string;
  scoreRangeUuid: string;
  scoreRangeIndex: number;
  propertyName: string;
  propertyValue: string | number;
}

export interface ToggleConnectCriteriaForPhasePayload {
  unitUuid: string;
  checktreeItemId: number;
  phase: UnitAlternativePhase;
  type: 'connect' | 'disconnect';
  module: ModuleProperties;
}

export interface PasteAssessmentSelectionPayload {
  unitUuid: string;
  phase: UnitAlternativePhase;
  selection: CheckTreeItem[];
  module: ModuleProperties;
}

export type CheckTreeItemWithFactor = CheckTreeItem & { factor?: UnitFactor };

const util = {
  walkTree: function(checktree: any, node: CheckTreeItemWithFactor, callback: (el: CheckTreeItemWithFactor) => void) {
    if (node) {
      callback(node);
      if (node.children && node.children.length > 0) {
        for (const childNode of node.children) {
          this.walkTree(checktree, childNode, callback);
        }
      }
    } else {
      // root level
      for (const firstLevelNode of checktree.children) {
        this.walkTree(checktree, firstLevelNode, callback);
      }
    }
  },
  findFactorByUuid(checktree: any, uuid: string, node: CheckTreeItemWithFactor): CheckTreeItemWithFactor {
    if (node) {
      if (node.factor.uuid === uuid) {
        return node;
      } else if (node.children && node.children.length > 0) {
        let result = null;
        for (const childNode of node.children) {
          result = this.findFactorByUuid(checktree, uuid, childNode);
          if (result) return result;
        }
        return result;
      }
      return null;
    }

    // root level
    let result = null;
    for (const firstLevelNode of checktree.children) {
      result = this.findFactorByUuid(checktree, uuid, firstLevelNode);
      if (result) return result;
    }
    return result;
  },
  findFactorById(checktree: any, id: number, node: CheckTreeItemWithFactor): CheckTreeItemWithFactor {
    if (node) {
      if (node.id === id) {
        return node;
      } else if (node.children && node.children.length > 0) {
        let result = null;
        for (const childNode of node.children) {
          result = this.findFactorById(checktree, id, childNode);
          if (result) return result;
        }
        return result;
      }
      return null;
    }

    // root level
    let result = null;
    for (const firstLevelNode of checktree.children) {
      result = this.findFactorById(checktree, id, firstLevelNode);
      if (result) return result;
    }
    return result;
  },
};

@Module({ namespaced: true })
export class CalculationsModule extends VuexModule {
  private unitList: UnitList = null;
  private formulateData: FormulateData = null;

  private assessData: AssessData = null;

  get getUnitList() {
    return this.unitList;
  }

  get getFormulateData() {
    return this.formulateData;
  }

  get getAssessData() {
    return this.assessData;
  }

  @Mutation
  public setUnitList(value: UnitList) {
    this.unitList = value;
  }

  @Mutation
  public setFormulateData(value: FormulateData) {
    util.walkTree(value.unit.checktrees[0], null, (item) => {
      item.factor = value.factors.find((el) => el.checktree_item_id === item.id);
    });
    this.formulateData = value;
  }

  @Mutation
  public setAssessData(value: AssessData) {
    this.assessData = value;
  }

  @Mutation
  updateFactorMutation(payload: UpdateFactorPayload) {
    const foundFactor = util.findFactorByUuid(this.formulateData.unit.checktrees[0], payload.factorUuid, null);
    // foundFactor.factor.weight = payload.weight === 0 ? null : payload.weight;
    foundFactor.factor.weight = payload.weight;
  }

  @Mutation
  updateFormulaMutation(payload: UpdateFormulaPayload) {
    const formula = this.formulateData.formulas.find((el) => el.uuid === payload.formulaUuid);
    formula.expression = payload.expression;
    formula.default = payload.default;
  }

  @Mutation
  addScoreRangeMutation(afterScoreRangeIndex: number) {
    if (afterScoreRangeIndex > -1) {
      this.formulateData.scores.splice(afterScoreRangeIndex + 1, 0, new UnitScore());
    } else {
      this.formulateData.scores.push(new UnitScore());
    }
  }

  @Mutation
  updateScoreRangeMutation(payload: { scoreRangeIndex: number; propertyName: string; propertyValue: string | number }) {
    const scoreRange = this.formulateData.scores[payload.scoreRangeIndex];
    if (Object.keys(scoreRange).indexOf(payload.propertyName) > -1) {
      (scoreRange as any)[payload.propertyName] = payload.propertyValue;
    }
  }

  @Mutation
  deleteScoreRangeMutation(scoreRangeIndex: number) {
    this.formulateData.scores.splice(scoreRangeIndex, 1);
  }

  @Mutation
  toggleConnectCriteriaForPhaseMutation(payload: ToggleConnectCriteriaForPhasePayload) {
    // console.log('--------- toggle connect mutation');
    // console.log(this.unitList);
    let alternativePhase: UnitAlternativePhase = null;
    for (const alternative of this.assessData.alternatives) {
      const phase = alternative.phases.find((el) => el.id === payload.phase.id);
      if (phase) alternativePhase = phase;
    }
    if (!alternativePhase) {
      return;
    }
    if (!alternativePhase.selections) {
      alternativePhase.selections = [];
    }

    if (payload.type === 'connect') {
      const checktreeItem = util.findFactorById(
        this.assessData.method.unit.checktrees[0],
        payload.checktreeItemId,
        null,
      );
      if (!checktreeItem) return;

      alternativePhase.selections.push(checktreeItem);
    } else {
      alternativePhase.selections.splice(
        alternativePhase.selections.findIndex((el) => el.id === payload.checktreeItemId),
        1,
      );
    }
  }

  @Mutation
  updateSignificanceValueAndColorMutation(payload: {
    unitUuid: string;
    phaseUuid: string;
    value: number;
    color: string;
  }) {
    let unitAlternative;

    for (const alternative of this.assessData.alternatives) {
      const phaseIndex = alternative.phases.findIndex((el) => el.uuid === payload.phaseUuid);
      if (phaseIndex > -1) {
        unitAlternative = alternative;
        alternative.phases[phaseIndex].score = payload.value;
        alternative.phases[phaseIndex].color = payload.color;
        break;
      }
    }

    const unit = this.unitList.children.find((el) => el.uuid === payload.unitUuid);
    if (unit && unit.alternatives) {
      let alternativeFound = false;

      for (const alternative of unit.alternatives) {
        const phase = alternative.phases.find((el) => el.uuid === payload.phaseUuid);
        if (phase) {
          alternativeFound = true;
          phase.score = payload.value;
          phase.color = payload.color;
          break;
        }
      }

      // In case unit alternative was not found in the list of existing alternatives (i.e. it's a new one), make sure
      //   to add it explicitly.
      //   More info here: https://gitlab.com/eon-plus/envigo/v2/app/-/issues/126
      if (!alternativeFound) {
        unit.alternatives.push(unitAlternative);
      }
    }

    // In case there are no alternatives present at all in the unit list, make sure to add it explicitly.
    //   More info here: https://gitlab.com/eon-plus/envigo/v2/app/-/issues/126
    else if (unitAlternative) {
      unit.alternatives = [unitAlternative];
    }
  }

  @Mutation
  toggleSelectWithinUnitGroupedTagsMutation(payload: ToggleGroupedTagsSelectPayload) {
    const unit = this.unitList.children.find((el) => el.uuid === payload.unitUuid);
    const tagsChecktree = unit.grouped_tags[payload.groupedTagsKey];
    if (tagsChecktree.behave_as === 'radio') {
      tagsChecktree.all_items.forEach((el) => {
        el.checked = false;
      });
    }
    const tagsChecktreeItem = tagsChecktree.all_items.find((el) => el.uuid === payload.checktreeItemUuid);
    tagsChecktreeItem.checked = payload.value;
  }

  @Mutation
  pasteAssessmentSelectionMutation(payload: PasteAssessmentSelectionPayload) {
    let phase = null;
    for (const alternative of this.assessData.alternatives) {
      phase = alternative.phases.find((el) => el.uuid === payload.phase.uuid);
      if (phase) break;
    }
    if (!phase) return;

    // phase.selections.length = 0;
    phase.selections = [...payload.selection];

    // console.log(phase);
  }

  @Action({ rawError: true })
  async initializeUnitList(payload: { unitListId: number; module: ModuleProperties; filterChecktrees: CheckTree[] }) {
    const res = await api.calculations.getUnitList(payload.unitListId, payload.module, payload.filterChecktrees);
    this.context.commit('setUnitList', res);
  }

  @Action({ rawError: true })
  async initializeFormulateData(unitUuid: string) {
    const res = await api.calculations.getFormulateValues(unitUuid);
    this.context.commit('setFormulateData', res);
  }

  @Action({ rawError: true })
  async initializeAssessData(payload: any) {
    const res = await api.calculations.getAssessValues(payload);
    this.context.commit('setAssessData', res);
  }

  @Action({ rawError: true })
  async toggleSelectWithinUnitGroupedTags(payload: ToggleGroupedTagsSelectPayload) {
    if (payload.value) {
      await api.calculations.connectOptionWithinGroupedTags(
        this.unitList.id,
        payload.unitUuid,
        payload.checktreeItemUuid,
      );
    } else {
      await api.calculations.disconnectOptionWithinGroupedTags(
        this.unitList.id,
        payload.unitUuid,
        payload.checktreeItemUuid,
      );
    }
    this.context.commit('toggleSelectWithinUnitGroupedTagsMutation', payload);
  }

  @Action({ rawError: true })
  async updateFactor(payload: UpdateFactorPayload) {
    await api.calculations.updateFactor(payload.unitUuid, payload.factorUuid, payload.weight);
    this.context.commit('updateFactorMutation', payload);
  }

  @Action({ rawError: true })
  async updateFormula(payload: UpdateFormulaPayload) {
    await api.calculations.updateFormula(payload.unitUuid, payload.formulaUuid, payload.expression, payload.default);
    this.context.commit('updateFormulaMutation', payload);
  }

  @Action({ rawError: true })
  addScoreRange(afterScoreRangeIndex: number) {
    this.context.commit('addScoreRangeMutation', afterScoreRangeIndex);
  }

  @Action({ rawError: true })
  async updateScoreRange(payload: UpdateScoreRangePayload) {
    const scoreRange = this.formulateData.scores[payload.scoreRangeIndex];
    if (scoreRange.uuid) {
      await api.calculations.updateScoreRange(
        payload.unitUuid,
        payload.scoreRangeUuid,
        payload.propertyName,
        payload.propertyValue,
      );
    } else {
      const res = await api.calculations.addScoreRange(payload.unitUuid, payload.propertyName, payload.propertyValue);
      this.context.commit('updateScoreRangeMutation', {
        scoreRangeIndex: payload.scoreRangeIndex,
        propertyName: 'uuid',
        propertyValue: res.uuid,
      });
      this.context.commit('updateScoreRangeMutation', {
        scoreRangeIndex: payload.scoreRangeIndex,
        propertyName: 'id',
        propertyValue: res.id,
      });
    }
    this.context.commit('updateScoreRangeMutation', payload);
  }

  @Action({ rawError: true })
  async deleteScoreRange(payload: { scoreRangeIndex: number; unitUuid: string }) {
    const scoreRange = this.formulateData.scores[payload.scoreRangeIndex];
    if (scoreRange.uuid) {
      await api.calculations.deleteScoreRange(
        payload.unitUuid,
        this.formulateData.scores[payload.scoreRangeIndex].uuid,
      );
    }
    this.context.commit('deleteScoreRangeMutation', payload.scoreRangeIndex);
  }

  @Action({ rawError: true })
  async toggleConnectCriteriaForPhase(payload: ToggleConnectCriteriaForPhasePayload) {
    if (payload.type === 'connect') {
      await api.calculations.connectCriteriaForPhase(payload.unitUuid, payload.phase.id, payload.checktreeItemId);
    } else {
      await api.calculations.disconnectCriteriaForPhase(payload.unitUuid, payload.phase.id, payload.checktreeItemId);
    }
    this.context.commit('toggleConnectCriteriaForPhaseMutation', payload);

    if (payload.type === 'connect') {
      try {
        const significanceResponse = await api.calculations.calculateScore(
          payload.unitUuid,
          payload.phase.uuid,
          payload.module.alternatives,
        );
        if (!significanceResponse) return;
        this.context.commit('updateSignificanceValueAndColorMutation', {
          unitUuid: payload.unitUuid,
          phaseUuid: payload.phase.uuid,
          value: significanceResponse.score,
          color: significanceResponse.color,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  @Action({ rawError: true })
  async pasteAssessmentSelection(payload: PasteAssessmentSelectionPayload) {
    // clearing current selection
    await api.calculations.clearPhaseSelection(payload.unitUuid, payload.phase.id);

    // setting new selection
    const promises = [];
    for (const item of payload.selection) {
      promises.push(api.calculations.connectCriteriaForPhase(payload.unitUuid, payload.phase.id, item.id));
    }
    await Promise.all(promises);

    // updating phase score
    const significanceResponse = await api.calculations.calculateScore(
      payload.unitUuid,
      payload.phase.uuid,
      payload.module.alternatives,
    );
    if (!significanceResponse) return;
    this.context.commit('updateSignificanceValueAndColorMutation', {
      unitUuid: payload.unitUuid,
      phaseUuid: payload.phase.uuid,
      value: significanceResponse.score,
      color: significanceResponse.color,
    });
    this.context.commit('pasteAssessmentSelectionMutation', payload);
  }

  @Action({ rawError: true })
  clearState() {
    this.context.commit('setUnitList', null);
  }
}
