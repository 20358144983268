
















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { PresentationsSidebarOption } from '@/app/shared/models/presentations/PresentationsSidebarOption';
import { namespace } from 'vuex-class';
import { PresentationsSidebarOptionItemEnum } from '@/app/shared/enums/presentations-sidebar-option-item.enum';
const PresentationsStore = namespace('Presentations');

@Component({
  components: {},
})
export default class PresentationsSidebarOptionItem extends Vue {
  @Prop() option: PresentationsSidebarOption;
  @Prop() foundItemTitle: string;

  radioGroupValue: PresentationsSidebarOptionItemEnum = null;

  // isExpanded: boolean = true;

  created() {
    this.radioGroupValue = this.option.value;
  }

  @PresentationsStore.Action
  updateOptionValue: (payload: PresentationsSidebarOption) => Promise<any>;

  @Watch('foundItemTitle')
  onFoundItemTitleChange() {
    if (!this.foundItemTitle) {
      return;
    }
    const hasSearchedItem = this.option.items.findIndex((el) => el.label.toLowerCase() === this.foundItemTitle) > -1;
    this.toggleExpand(hasSearchedItem);
  }

  @Watch('radioGroupValue')
  onOptionValueChange(newVal: PresentationsSidebarOptionItemEnum, oldVal: PresentationsSidebarOptionItemEnum) {
    // console.log(this.option.label, ' --> newVal: ', newVal, ', oldVal: ', oldVal);
    if (oldVal || oldVal === null) {
      const clonedOption = { ...this.option };
      clonedOption.value = this.radioGroupValue;
      this.updateOptionValue(clonedOption);
    }
  }

  @Watch('option')
  onSidebarOptionChange(_newVal: PresentationsSidebarOption, _oldVal: PresentationsSidebarOption) {
    // console.log(this.option.label, ' -> sidebarOptionChange: ', this.option.value);
    if (this.option.value !== this.radioGroupValue) {
      this.radioGroupValue = this.option.value;
    }
  }

  @Emit('toggleExpand')
  toggleExpand(_shouldExpand: boolean) {
    // emiting event
  }
}
