










































































import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import ChapterItemCommentHighlight from './mixins/ChapterItemCommentHighlight.vue';
import { ChapterItem } from '@/app/shared/models/reports/ChapterItem';
import { ChapterItemType } from '@/app/shared/enums/chapter-item-type.enum';
import { ChapterItemMode } from '@/app/shared/types/chapter-item-mode.type';
import { SheetToHtmlParser } from '@/app/shared/utils/sheet-to-html-parser';
import _debounce from 'lodash.debounce';
import { UpdateChapterItemPayload, WS_DEBOUNCE_VALUE } from '@/app/shared/store/modules/reports.store-module';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const SpreadsheetModule = require('x-data-spreadsheet');
const Spreadsheet = SpreadsheetModule.default;

@Component({
  components: {},
})
export default class SpreadsheetChapterItem extends ChapterItemCommentHighlight {
  @Prop() item: ChapterItem;
  @Prop() mode: ChapterItemMode;
  @Prop() isDeleteButtonAllowed: boolean;

  ChapterItemType: ChapterItemType;

  spreadsheetHTMLElId: string = null;
  spreadsheet: any = null;

  @Watch('mode')
  onModeChanged() {
    if (this.mode === 'edit') {
      this.initializeSpreadsheet();
    }
  }

  @Watch('item', { deep: true })
  onItemChange() {
    if (this.item.isExpandedInContent) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 200);
    }
  }

  @Emit('switchMode')
  switchMode(_newMode: ChapterItemMode) {
    // just emiting event
  }

  @Emit('deleteChapterItem')
  deleteChapterItem() {
    // just emiting event
  }

  @Emit('update')
  updateChapterItem(_payload: UpdateChapterItemPayload) {
    // just emiting event
  }

  @Emit('toggleExpand')
  toggleExpand() {
    // just emiting event
  }

  created() {
    this.spreadsheetHTMLElId = 'x-spreadsheet-' + this.item.uuid;
  }

  get isExpanded() {
    return this.item && this.item.isExpandedInContent;
  }

  mounted() {
    this.initializeSpreadsheet();
  }

  initializeSpreadsheet() {
    if (this.spreadsheet) {
      document.querySelector('#' + this.spreadsheetHTMLElId).innerHTML = '';
    }

    const spreadsheetHeight = 442;
    const spreadsheetWidth = 936 - (this.item.level - 1) * 32;

    const initialData: any = this.item.selection || {};
    this.spreadsheet = new Spreadsheet('#' + this.spreadsheetHTMLElId, {
      view: {
        height: () => spreadsheetHeight,
        width: () => spreadsheetWidth,
      },
    })
      .loadData(initialData)
      .change(
        _debounce((data: any) => {
          const parsedHTMLOutput = SheetToHtmlParser.parse(data);
          this.updateChapterItem({
            uuid: this.item.uuid,
            type: ChapterItemType.SPREADSHEET,
            propertyName: 'selection',
            propertyValue: data,
            additionalProperties: [
              {
                name: 'html',
                value: parsedHTMLOutput,
              },
            ],
          });
          // this.updateChapterItem({
          //   uuid: this.item.uuid,
          //   type: ChapterItemType.SPREADSHEET,
          //   propertyName: 'html',
          //   propertyValue: parsedHTMLOutput,
          // });
        }, WS_DEBOUNCE_VALUE),
      );
  }

  onTitleInput = _debounce((event: string) => {
    this.updateChapterItem({
      uuid: this.item.uuid,
      type: ChapterItemType.SPREADSHEET,
      propertyName: 'title',
      propertyValue: event,
    });
  }, WS_DEBOUNCE_VALUE);

  onLandscapeValueChange(value: boolean) {
    this.updateChapterItem({
      uuid: this.item.uuid,
      type: ChapterItemType.SPREADSHEET,
      propertyName: 'landscape',
      propertyValue: value,
    });
  }
}
