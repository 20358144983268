









































import { Component, Prop, Vue } from 'vue-property-decorator';
import FormRenderer from '@/app/shared/components/form/FormRenderer.vue';
import resetPasswordFormSchema from '@/app/shared/components/form/schema/reset-password.json';
import api from '@/app/shared/api';

@Component({
  components: {
    FormRenderer,
  },
})
export default class Recover extends Vue {
  @Prop({ required: true }) token!: string;
  isReset: boolean = false;
  isValidToken: boolean = false;

  get resetPasswordFormSchema() {
    return resetPasswordFormSchema;
  }

  async created() {
    try {
      // Validate token.
      await api.auth.validatePasswordRecoverToken(this.token);
      this.isValidToken = true;
    } catch (error) {
      // @TODO Display backend validation messages on the form.
    }
  }

  async resetPassword(values: { password: string }) {
    // Update password.
    try {
      await api.auth.updatePassword(values.password, this.token);
      this.isReset = true;
    } catch (error) {
      // @TODO Handle backend exceptions & validation messages.
    }
  }

  goToForgotPassword() {
    this.$router.replace('/login/recover');
  }

  goToLogin() {
    this.$router.replace('/login');
  }
}
