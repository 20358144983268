












































import { Component, Emit, Mixins, Vue } from 'vue-property-decorator';
import { BaseControlSkeleton } from 'v-form-builder';
import FormFieldLabel from '@/app/shared/components/form/field/mixins/FormFieldLabel.vue';
import FormFieldFocus from '@/app/shared/components/form/field/mixins/FormFieldFocus.vue';

@Component({})
export default class FormFieldTextarea extends Mixins(Vue, FormFieldLabel, FormFieldFocus, BaseControlSkeleton) {
  @Emit()
  click(_event: MouseEvent) {
    //
  }
}
