const requiredObjectResponsibilityUserId = (fieldValue: any): boolean => {
  if (Array.isArray(fieldValue.data)) {
    return fieldValue.data.every((item: any) => requiredObjectResponsibilityUserId(item));
  } else if (Array.isArray(fieldValue)) {
    return fieldValue.every((item: any) => requiredObjectResponsibilityUserId(item));
  } else if (typeof fieldValue === 'object' && !Array.isArray(fieldValue) && fieldValue !== null) {
    if (
      fieldValue.responsibility !== undefined &&
      fieldValue.responsibility !== null &&
      fieldValue.responsibility.length &&
      fieldValue.user_id !== undefined &&
      fieldValue.user_id !== null &&
      fieldValue.user_id.length
    )
      return true;
    return false;
  }

  return false;
};

export default requiredObjectResponsibilityUserId;
