import { User } from '../../User';

export class Event {
  id?: number;
  uuid?: string;
  name: string;
  date: string;
  user?: User;

  constructor(name: string = '', date: string = null) {
    this.name = name;
    this.date = date;
  }
}
