




























import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Navbar from '@/app/shared/components/Navbar.vue';
import Loader from '@/app/shared/components/Loader.vue';
import ConfirmDialog from '@/app/shared/components/ConfirmDialog.vue';
import Sidebar from '@/app/shared/components/Sidebar.vue';
import { TooltipOptions } from './app/shared/store/modules/ui.store-module';
import { UserPermission } from './app/shared/enums/user-permission.enum';

const LoaderStore = namespace('Loader');
const ModulePropertiesStore = namespace('ModuleProperties');
const UserStore = namespace('User');
const UiStore = namespace('UI');

@Component({
  components: { Navbar, Loader, ConfirmDialog, Sidebar },
})
export default class App extends Vue {
  @LoaderStore.Getter
  public isLoaderVisible!: () => boolean;

  @ModulePropertiesStore.Action('init')
  public initModulePropertiesStore: () => void;

  @UserStore.State
  public isLoginInteractive: boolean;

  @UserStore.Getter
  public isAuthenticated!: () => boolean;

  @UserStore.Action('init')
  public initUserStore: () => Promise<void>;

  @UserStore.Action
  public isAllowed: (permission: string | string[]) => Promise<boolean>;

  @UiStore.Getter
  public isTinyEditorVisible!: () => boolean;

  @UiStore.Getter
  public tooltipOptions!: TooltipOptions;

  @UiStore.Getter
  public isTooltipVisible!: boolean;

  @UiStore.Getter
  public isCalloutVisible!: boolean;

  @UiStore.Action
  public hideTooltip!: () => void;

  @Watch('isAuthenticated')
  async onIsAuthenticatedChange(isAuthenticated: boolean) {
    if (isAuthenticated && !this.isLoginInteractive) {
      let targetRoute = '/error/404';

      // Redirect user to the correct landing screen depending on their permissions.
      if (await this.isAllowed(UserPermission.ADMIN_LANDING_ACCESS)) targetRoute = '/';
      else if (await this.isAllowed(UserPermission.LANDING_ACCESS)) targetRoute = '/landing';

      this.$router.replace(targetRoute).catch(() => {
        // Dummy error handler for avoiding duplicated navigation logs.
      });
    } else {
      this.$router.replace('/login').catch(() => {
        // Dummy error handler for avoiding duplicated navigation logs.
      });
    }
  }

  get shouldShowToolbar() {
    const pagesWhereToolbarIsVisible = ['AdminCheckTree', 'UserCheckTree', 'MatrixRelations'];
    return this.isAuthenticated && pagesWhereToolbarIsVisible.indexOf(this.$route.name) > -1;
  }

  async created() {
    // Initialize the user store.
    await this.initUserStore();

    // Initialize module properties store.
    this.initModulePropertiesStore();
  }
}
