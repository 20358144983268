























import { Component, Prop, Vue } from 'vue-property-decorator';
import ImpactTable from '@/app/shared/components/ImpactTable.vue';
import Unit from '@/app/shared/models/calculations/Unit';

@Component({ components: { ImpactTable } })
export default class PresentationsScopingTableUnit extends Vue {
  @Prop() unit: Unit;
  @Prop() viewMode: 'list' | 'comments' | 'details' | 'details_and_comments';

  style = {
    // '.impact-table': { margin: '0' },
    tableRow: {
      position: 'relative',
      paddingLeft: '6px',
      width: '100%',
      borderBottom: '1px solid #d3dadc',
    },
    impactTitle: {
      padding: '8px 14px',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.25px',
      color: '#222C37',
      fontWeight: '500',
      textAlign: 'left',
    },
    impactDetails: {
      padding: '0 14px 0 26px',
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: '0.25px',
      textAlign: 'left',
      color: '#222C37',
      paddingBottom: '12px',
    },
    marker: {
      position: 'absolute',
      left: '0',
      top: '0',
      bottom: '0',
      background: 'palegreen',
      width: '6px',
    },
  };

  get alternativesRow(): string {
    return this.unit.grouped_tags.alternatives.children
      .filter((el) => el.checked)
      .map((el) => el.title)
      .join(' | ');
  }

  get phasesRow(): string {
    return this.unit.grouped_tags['development-phases'].children
      .filter((el) => el.checked)
      .map((el) => el.title)
      .join(' | ');
  }

  get signCol(): string {
    return this.unit.grouped_tags.sign.children
      .filter((el) => el.checked)
      .map((el) => el.title)
      .join(' | ');
  }

  get typeCol(): string {
    return this.unit.grouped_tags.type.children
      .filter((el) => el.checked)
      .map((el) => el.title)
      .join(' | ');
  }

  get classCol(): string {
    return this.unit.grouped_tags['method-approach'].children
      .filter((el) => el.checked)
      .map((el) => el.title)
      .join(' | ');
  }
}
