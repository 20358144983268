














import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const UserStore = namespace('User');
const ChecklistsStore = namespace('Checklists');

@Component
export default class PageHeader extends Vue {
  @Prop({ default: 'white' }) color: string;

  @UserStore.Getter
  public isAuthenticated: boolean;

  @ChecklistsStore.Action
  public lockAllItems!: (shouldLock: boolean) => void;

  @ChecklistsStore.Action
  public selectAllItems!: (shouldSelect: boolean) => void;

  @ChecklistsStore.Action
  public enumerateChecktreeItems!: (shouldEnumerate: boolean) => void;

  @ChecklistsStore.Action
  public toggleFoldItems!: (shouldFold: boolean) => void;

  expandAllItems() {
    this.$store.dispatch('Loader/show');
    this.toggleFoldItems(false);
    setTimeout(() => {
      this.$store.dispatch('Loader/hide');
    }, 1000);
  }
}
