










































import { Chapter } from '@/app/shared/models/reports/Chapter';
import UtilService from '@/app/shared/utils/util.service';
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const ReportsStore = namespace('Reports');
const UiStore = namespace('UI');

@Component({
  components: {},
})
export default class ReportsNavigator extends Vue {
  public collapsed: boolean = false;
  @Prop() htu: boolean;
  @Prop() activeChapter: Chapter;
  @Prop() chapters: Array<Chapter>;
  @Prop() visitedChapters: Array<string>;

  @UiStore.Getter
  isLeftSidebarExpanded!: boolean;

  @ReportsStore.Action
  selectChapter!: (uuid: string) => Promise<number>;

  get xOffset() {
    return this.isLeftSidebarExpanded ? UtilService.calculateSidebarWidth() : 56;
  }

  isChapterVisited(uuid: string) {
    return this.activeChapter.uuid !== uuid && this.visitedChapters.indexOf(uuid) > -1;
  }

  async selectChapterAction(uuid: string) {
    const chapterIndex = await this.selectChapter(uuid);
    const name = !this.htu ? 'Reports' : 'How To Use';

    this.$router.replace({
      name: name,
      params: {
        studyUuid: this.$route.params.studyUuid,
        uuid: this.$route.params.uuid,
        chapter: (chapterIndex + 1).toString(),
      },
    });
  }

  @Emit('toggle-collapse')
  public collapse(): boolean {
    this.collapsed = !this.collapsed;
    return this.collapsed;
  }
}
