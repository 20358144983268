import { Vue } from 'vue-property-decorator';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import { eventBus, EventType } from '@/app/shared/event-bus/eventBus';
import DialogService from '@/app/shared/utils/dialog.service';
import api from '@/app/shared/api';
import { Comment, CommentingMode, CommentReply, DraftComment } from '@/app/shared/models/Comment';
// import { UserRole } from '../../enums/user-role.enum';
import LoaderService from '@/app/shared/utils/loader.service';
import store from '..';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(weekday);

const dateFormat = 'YYYY-MM-DD';

@Module({ namespaced: true })
class CommentsModule extends VuexModule {
  objectModel: string;
  objectUuid: string;
  mode: CommentingMode = 'feed';
  comments: Comment[] = [];
  draftComment: DraftComment = null;
  commentFilter = 'all';
  commentUserFilter: 'internal' | 'authority' | 'public' = 'public';
  commentDateFilter = 'all';

  get isCommentFeedMode() {
    return this.mode === 'feed';
  }

  get isCommentHistoryMode() {
    return this.mode === 'history';
  }

  get filteredComments() {
    return this.comments.filter((comment) => {
      switch (this.commentFilter) {
        case 'user':
          return comment.user.id === store.getters['User/userId'];
        case 'important':
          return comment.is_important;
        case 'new':
          return comment.is_new;
        case 'unseen':
          return comment.is_unseen;
        case 'resolved':
          return comment.is_resolved;
        case 'all':
        default:
          return true;
      }
    });
    // TODO: Make sure to turn this on once the user roles are implemented!
    //.filter((comment) => comment.user.role === this.commentUserFilter);
  }

  get commentHistory() {
    if (!this.filteredComments.length) return [];
    return this.filteredComments
      .sort((a, b) => {
        const aDayObj = dayjs.utc(a.created);
        const bDayObj = dayjs.utc(b.created);
        if (aDayObj < bDayObj) return -1;
        if (aDayObj > bDayObj) return 1;
        return 0;
      })
      .reduce((commentHistory, comment) => {
        const date = dayjs
          .utc(comment.created)
          .local()
          .format(dateFormat);
        return { ...commentHistory, [date]: [...((commentHistory[date as never] as Comment[]) || []), comment] };
      }, {});
  }

  get hasDraftComment() {
    return !!this.draftComment;
  }

  get hasExpandedComment() {
    return this.filteredComments.some((comment) => comment.is_expanded);
  }

  get commentFilters() {
    return [
      {
        text: 'All',
        value: 'all',
      },
      {
        text: 'Only yours',
        value: 'user',
      },
      {
        text: 'Important',
        value: 'important',
      },
      {
        text: 'New',
        value: 'new',
      },
      {
        text: 'Unseen',
        value: 'unseen',
      },
      ...(this.context.getters.isCommentHistoryMode
        ? [
            {
              text: 'Resolved',
              value: 'resolved',
            },
          ]
        : []),
    ];
  }

  get commentHistoryLastWeekDate() {
    return Object.keys(this.commentHistory).find(
      (date) =>
        dayjs(date, dateFormat) >=
          dayjs()
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .day(1)
            .subtract(1, 'week') &&
        dayjs(date, dateFormat) <
          dayjs()
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .day(1),
    );
  }

  get commentHistoryLastMonthDate() {
    return Object.keys(this.commentHistory).find(
      (date) =>
        dayjs(date, dateFormat) >=
          dayjs()
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .date(1)
            .subtract(1, 'month') &&
        dayjs(date, dateFormat) <
          dayjs()
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .date(1),
    );
  }

  get dateItems() {
    return [
      {
        text: 'Last week',
        value: this.commentHistoryLastWeekDate,
        disabled: !this.commentHistoryLastWeekDate,
      },
      {
        text: 'Last month',
        value: this.commentHistoryLastMonthDate,
        disabled: !this.commentHistoryLastMonthDate,
      },
      {
        text: 'The very beginning',
        value: 'all',
      },
    ];
  }

  @Mutation
  private setCommentObject(payload: { objectModel: string; objectUuid: string }) {
    this.objectModel = payload.objectModel;
    this.objectUuid = payload.objectUuid;
    this.mode = 'feed';
  }

  @Mutation
  private setMode(mode: CommentingMode) {
    this.mode = mode;
  }

  @Mutation
  private setComments(comments: Comment[]) {
    this.comments = comments;
  }

  @Mutation
  private setDraftComment(draftComment: DraftComment) {
    this.draftComment = draftComment;
  }

  @Mutation
  private setCommentFilter(commentFilter: string) {
    this.commentFilter = commentFilter;
  }

  @Mutation
  private setCommentUserFilter(commentUserFilter: 'internal' | 'authority' | 'public') {
    this.commentUserFilter = commentUserFilter;
  }

  @Mutation
  private setCommentDateFilter(commentDateFilter: string) {
    this.commentDateFilter = commentDateFilter;
  }

  @Action({ rawError: true })
  public expandComment(uuid: string) {
    const comment = this.comments.find((comment) => comment.uuid === uuid);
    if (!comment) return;
    this.context.dispatch('toggleExpand', { comment, isExpanded: true });
  }

  @Action({ rawError: true })
  public switchMode() {
    switch (this.mode) {
      case 'history':
        this.context.commit('setMode', 'feed');
        break;
      case 'feed':
      default:
        this.context.commit('setMode', 'history');
    }

    this.context.dispatch('collapseAll');
  }

  @Action({ rawError: true })
  async getComments(): Promise<void> {
    if (!this.commentUserFilter) {
      console.warn('Invalid comment user filter!', this.commentUserFilter);
      return;
    }

    try {
      LoaderService.disableHttpLoader();
      const comments = await api.comments.getComments(this.objectModel, this.objectUuid, this.commentUserFilter);
      this.context.commit('setComments', comments);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  @Action({ rawError: true })
  async changeUserFilter(userFilter: string): Promise<void> {
    this.context.commit('setCommentUserFilter', userFilter);
    this.context.dispatch('getComments');
  }

  @Action({ rawError: true })
  public initDraftComment(payload: {
    uuid: string;
    objectItemUuid?: string;
    objectItemType?: string;
    objectItemKey?: string;
  }): void {
    this.context.dispatch('collapseAll');
    this.context.commit('setDraftComment', {
      uuid: payload.uuid,
      date: dayjs().toISOString(),
      content: '',
      is_expanded: true,
      user: store.getters['User/userModel'],
      object_model: this.objectModel,
      object_uuid: this.objectUuid,
      object_item_uuid: payload.objectItemUuid,
      object_item_type: payload.objectItemType,
      object_item_key: payload.objectItemKey,
    });
  }

  @Action({ rawError: true })
  async addDraftComment(payload: DraftComment): Promise<void> {
    try {
      LoaderService.disableHttpLoader();

      const comment = await api.comments.createComment({
        content: payload.content,
        object_model: payload.object_model,
        object_uuid: payload.object_uuid,
        object_item_uuid: payload.object_item_uuid,
        object_item_type: payload.object_item_type,
        object_item_key: payload.object_item_key,
      });

      // Implicitly put own comment in the expanded state.
      comment.is_expanded = true;

      this.comments.push(comment);
      this.context.commit('setDraftComment', null);
      eventBus.$emit(EventType.COMMENT_DRAFT_ADD, comment);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  @Action({ rawError: true })
  async addCommentReply(payload: { commentUuid: string; content: string }): Promise<void> {
    try {
      LoaderService.disableHttpLoader();

      const commentReply = await api.comments.createCommentReply({
        commentUuid: payload.commentUuid,
        content: payload.content,
      });
      const comment = this.comments.find((comment) => comment.uuid === payload.commentUuid);
      if (!comment.hasOwnProperty('replies')) Vue.set(comment, 'replies', []);
      comment.replies.push(commentReply);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  @Action({ rawError: true })
  async editCommentReply(payload: { commentReply: CommentReply; content: string }): Promise<void> {
    try {
      LoaderService.disableHttpLoader();

      const commentReply = await api.comments.updateCommentReply({
        uuid: payload.commentReply.uuid,
        content: payload.content,
      });
      const comment = this.comments.find((comment) => comment.uuid === payload.commentReply.comment_uuid);
      const commentReplyIndex = comment.replies.findIndex((reply) => reply.uuid === commentReply.uuid);
      comment.replies.splice(commentReplyIndex, 1, commentReply);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  @Action({ rawError: true })
  async deleteCommentReply(commentReply: CommentReply): Promise<void> {
    const deleteReplyClosure = async () => {
      try {
        LoaderService.disableHttpLoader();

        await api.comments.deleteCommentReply(commentReply.uuid);
        const comment = this.comments.find((comment) => comment.uuid === commentReply.comment_uuid);
        const commentReplyIndex = comment.replies.findIndex((reply) => reply.uuid === commentReply.uuid);
        comment.replies.splice(commentReplyIndex, 1);
      } catch (error) {
        console.warn(error);
      } finally {
        LoaderService.enableHttpLoader();
      }
    };

    DialogService.presentDialog(
      'Delete this reply?',
      'You will not be able to recover it.',
      'Yes',
      'Cancel',
      deleteReplyClosure,
    );
  }

  @Action({ rawError: true })
  public cancelDraftComment(): void {
    this.context.commit('setDraftComment', null);
    eventBus.$emit(EventType.COMMENT_DRAFT_CANCEL);
  }

  @Action({ rawError: true })
  deleteComment(comment: Comment) {
    const deleteClosure = async () => {
      try {
        LoaderService.disableHttpLoader();

        await api.comments.deleteComment(comment.uuid);
        const commentIndex = this.comments.findIndex((c) => c.uuid === comment.uuid);
        this.comments.splice(commentIndex, 1);

        // Raise the delete event so the associated comment highlight can be removed by the corresponding component.
        eventBus.$emit(EventType.COMMENT_DELETE, comment);
      } catch (error) {
        console.warn(error);
      } finally {
        LoaderService.enableHttpLoader();
      }
    };

    DialogService.presentDialog(
      'Delete this comment?',
      'You will not be able to recover it.',
      'Yes',
      'Cancel',
      deleteClosure,
    );
  }

  @Action({ rawError: true })
  async toggleExpand(payload: { comment: Comment; isExpanded: boolean }) {
    if (payload.isExpanded) {
      this.comments.forEach((comment) => {
        if (comment.uuid === payload.comment.uuid) comment.is_expanded = true;
        else comment.is_expanded = false;
      });

      // Set comment as seen by current user if:
      // - comment has `is_unseen` flag set
      // - current user is NOT the comment creator
      if (payload.comment.is_unseen && payload.comment.user.id !== store.getters['User/userId']) {
        try {
          LoaderService.disableHttpLoader();

          await api.comments.updateComment({
            uuid: payload.comment.uuid,
            is_unseen: false,
          });
          payload.comment.is_unseen = false;
        } catch (error) {
          console.warn(error);
        } finally {
          LoaderService.enableHttpLoader();
        }
      }
    } else payload.comment.is_expanded = false;
  }

  @Action({ rawError: true })
  collapseAll() {
    this.comments.forEach((comment) => {
      comment.is_expanded = false;
    });
  }

  @Action({ rawError: true })
  async toggleImportantComment(payload: Comment) {
    try {
      LoaderService.disableHttpLoader();

      const comment = await api.comments.updateComment({
        uuid: payload.uuid,
        is_important: !payload.is_important,
      });
      const commentIndex = this.comments.findIndex((c) => c.uuid === comment.uuid);
      Vue.set(this.comments[commentIndex], 'is_important', comment.is_important);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  @Action({ rawError: true })
  async editComment(payload: { comment: Comment; content: string }) {
    try {
      LoaderService.disableHttpLoader();

      const comment = await api.comments.updateComment({
        uuid: payload.comment.uuid,
        content: payload.content,
      });
      const commentIndex = this.comments.findIndex((c) => c.uuid === comment.uuid);
      Vue.set(this.comments[commentIndex], 'content', comment.content);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  @Action({ rawError: true })
  async resolveComment(payload: Comment) {
    try {
      LoaderService.disableHttpLoader();

      const comment = await api.comments.updateComment({
        uuid: payload.uuid,
        is_resolved: true,
      });
      const commentIndex = this.comments.findIndex((c) => c.uuid === comment.uuid);

      // Immediately collapse the comment in feed mode, so it goes away.
      if (this.isCommentFeedMode) Vue.set(this.comments[commentIndex], 'is_expanded', false);

      Vue.set(this.comments[commentIndex], 'is_resolved', comment.is_resolved);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  @Action({ rawError: true })
  async restoreComment(payload: Comment) {
    try {
      LoaderService.disableHttpLoader();

      const comment = await api.comments.updateComment({
        uuid: payload.uuid,
        is_resolved: false,
      });
      const commentIndex = this.comments.findIndex((c) => c.uuid === comment.uuid);
      Vue.set(this.comments[commentIndex], 'is_resolved', comment.is_resolved);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  @Action({ rawError: true })
  async linkComments(payload: { comment: Comment; uuid: string }) {
    if (payload.comment.linked && payload.comment.linked.includes(payload.uuid)) return;

    try {
      LoaderService.disableHttpLoader();

      await api.comments.linkComment({ sourceUuid: payload.comment.uuid, targetUuid: payload.uuid });
      if (!payload.comment.hasOwnProperty('linked')) Vue.set(payload.comment, 'linked', []);
      payload.comment.linked.push(payload.uuid);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }

  @Action({ rawError: true })
  async unlinkComments(payload: { comment: Comment; uuid: string }) {
    if (!payload.comment.linked) return;

    const linkIndex = payload.comment.linked.indexOf(payload.uuid);
    if (linkIndex === -1) return;

    try {
      LoaderService.disableHttpLoader();

      await api.comments.removeLinkedComment({ sourceUuid: payload.comment.uuid, targetUuid: payload.uuid });
      payload.comment.linked.splice(linkIndex, 1);
    } catch (error) {
      console.warn(error);
    } finally {
      LoaderService.enableHttpLoader();
    }
  }
}

export default CommentsModule;
