

















import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CheckTree } from '@/app/shared/models/CheckTree';
import PresentationsImpactsRow from './PresentationsImpactsRow.vue';
import { SingleImpact } from '@/app/shared/models/reports/SingleImpact';
const PresentationsStore = namespace('Presentations');

@Component({
  components: { PresentationsImpactsRow },
})
export default class PresentationsImpacts extends Vue {
  @PresentationsStore.State
  sidebarChecktrees: CheckTree[];

  @PresentationsStore.State
  impactsSelection: SingleImpact[];

  get impacts() {
    return this.impactsSelection || [];
  }

  get selectAllValue() {
    return this.impacts.every((el) => el.library_id);
  }

  @PresentationsStore.Action
  toggleAllSingleImpactCheck: (shouldCheck: boolean) => Promise<any>;
}
